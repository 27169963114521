enum EntitlementEnum {
  REPORT_GENERAL_LEDGER = 'general.ledger',
  DATA_SHARING_IMPORT_EXPORT = 'data.sharing.import.export',
  PRODUCTION_YIELD_DATA = 'production.yield.data',
  ACCOUNTS_PLAYABLE = 'accounts.payable',
  GENERIC_MATERIAL_CONSUMPTION = 'generic.material.consumption',
  INVOICES = 'invoices',
  ELETRONIC_INVOICES_XML_IMPORT = 'electronic.invoices.xml.import',
  LANDING_PAGE = 'landing.page',
}

export default EntitlementEnum;
