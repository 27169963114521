import { useDrawerForm } from '@refinedev/antd';
import { HttpError, useCreate } from '@refinedev/core';
import { FormProps } from 'antd';
import { useWatch } from 'antd/es/form/Form';
import { FormInstance } from 'antd/lib/form/Form';
import { useTracking } from 'common/utils';
import dayjs from 'dayjs';
import { useEffect, useMemo, useState } from 'react';
import { useOrganization } from 'src/organization';
import { useBankAccountCreateDrawer } from 'src/resources/bank-account/use-bank-account-create-drawer.hook';

import { InvoiceDetailModel } from '../invoice.model';
import { InvoicePaymentFormModel } from './invoice-payment-form.model';

const usePurchaseInvoiceDrawer = <T>(
  action: 'edit' | 'create',
  key?: string,
) => {
  const purchaseInvoiceDrawer = useDrawerForm<InvoiceDetailModel, HttpError, T>(
    {
      action,
      resource: 'purchase-invoice',
      redirect: false,
      warnWhenUnsavedChanges: false,
      syncWithLocation: {
        key: key ?? action,
      },
      queryOptions: {
        cacheTime: 0,
      },
    },
  );

  return {
    purchaseInvoiceDrawer,
  };
};

const useInvoicePaymentForm = (
  formProps: FormProps,
  formInstance: FormInstance,
  onClose: () => void,
  refetchTable: () => void,
) => {
  const { track } = useTracking();
  const { currentOrganizationId } = useOrganization();
  const [isDisabledSave, setIsDisabledSave] = useState(false);
  const { mutate, isLoading } = useCreate();

  const formValues = useWatch([], formProps?.form);

  const initialValues: InvoicePaymentFormModel = useMemo(() => {
    return {
      postingDate: dayjs(),
      postingTime: dayjs(),
      id: formProps?.initialValues?.id,
      farm: formProps?.initialValues?.farm,
      paymentAccount: undefined,
      paymentType: '',
      amount: formProps?.initialValues?.outstanding,
    };
  }, [formProps?.initialValues]);

  const paidTotal = useMemo(() => {
    return (
      formProps?.initialValues?.total - formProps?.initialValues?.outstanding
    );
  }, [formProps?.initialValues?.total, formProps?.initialValues?.outstanding]);

  const outstandingTotal = useMemo(() => {
    return formProps?.initialValues?.outstanding ?? 0;
  }, [formProps?.initialValues?.outstanding]);

  useEffect(() => {
    formInstance
      ?.validateFields({ validateOnly: true })
      .then(() => {
        setIsDisabledSave(false);
      })
      .catch(() => {
        setIsDisabledSave(true);
      });
  }, [formValues, formInstance, setIsDisabledSave]);

  const handleOnFinish = (values: InvoicePaymentFormModel) => {
    mutate(
      {
        resource: `purchase-invoice/${values.id}/payment`,
        meta: {
          organizationId: currentOrganizationId,
        },
        values: {
          ...values,
          paymentMethod: 'Bank',
          dueDate: dayjs(values.postingDate).format('YYYY-MM-DD'),
          postingDate: dayjs(values.postingDate).format('YYYY-MM-DD'),
          postingTime: dayjs(values.postingTime).format('HH:mm:ss'),
        },
      },
      {
        onSuccess: () => {
          track('End Invoice Payment');
          refetchTable();
          onClose();
        },
      },
    );
  };

  const { createBankAccountDrawer, handleOpenBankAccountDrawer } =
    useBankAccountCreateDrawer();

  return {
    initialValues,
    paidTotal,
    outstandingTotal,
    isDisabledSave,
    isLoading,
    handleOnFinish,
    createBankAccountDrawer,
    handleOpenBankAccountDrawer,
  };
};

export { useInvoicePaymentForm, usePurchaseInvoiceDrawer };
