const DEFAULT_SUMMARY_PAGE_SIZE = 21;
const FILTERS_PAGE_SIZE = 100;

const SelectItemsMovementsTestId = {
  SELECT_ITEMS_MOVEMENTS_ITEM_BUTTON: 'SELECT_ITEMS_MOVEMENTS_ITEM_BUTTON',
  SELECT_ITEMS_MOVEMENTS_MOVEMENTS_BUTTON:
    'SELECT_ITEMS_MOVEMENTS_MOVEMENTS_BUTTON',
};

export {
  DEFAULT_SUMMARY_PAGE_SIZE,
  FILTERS_PAGE_SIZE,
  SelectItemsMovementsTestId,
};
