import { useCreate } from '@refinedev/core';
import { DrawerProps, FormInstance, FormProps, Grid } from 'antd';
import { useWatch } from 'antd/es/form/Form';
import { ButtonType } from 'antd/lib/button';
import { StockEntryEnum } from 'common/constants';
import { useTracking } from 'common/utils';
import dayjs from 'dayjs';
import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { StockTransferModel } from './stock-transfer.model';
import { useStockTransferStore } from './stock-transfer.store';

const useStockTransfer = (
  formProps: FormProps,
  drawerProps: DrawerProps,
  formInstance: FormInstance,
  onClose: () => void,
) => {
  const { track } = useTracking();
  const breakpoint = Grid.useBreakpoint();
  const { i18n } = useTranslation();
  const userLanguage = i18n.language.toLocaleLowerCase();

  const { showItems, setShowItems, disableSave, setDisableSave } =
    useStockTransferStore();

  const { mutate: mutateTransferBetweenFarms } =
    useCreate<StockTransferModel>();

  const formValues = useWatch([], formInstance);

  const formInitialValues = useMemo(
    () => ({
      postingDate: dayjs().locale(userLanguage),
      postingTime: dayjs().locale(userLanguage),
    }),
    [userLanguage],
  );

  const detailTabClassname = useMemo<ButtonType | undefined>(
    () => (showItems ? undefined : 'primary'),
    [showItems],
  );
  const itemsTabClassname = useMemo<ButtonType | undefined>(
    () => (showItems ? 'primary' : undefined),
    [showItems],
  );
  const disableItemsTab = useMemo(
    () =>
      !formValues?.postingDate ||
      !formValues?.postingTime ||
      !formValues?.sourceWarehouse ||
      !formValues?.targetWarehouse,
    [
      formValues?.targetWarehouse,
      formValues?.sourceWarehouse,
      formValues?.postingDate,
      formValues?.postingTime,
    ],
  );

  const drawerWidth = useMemo(
    () => (breakpoint.sm ? '400px' : '100%'),
    [breakpoint.sm],
  );

  useEffect(() => {
    formInstance.resetFields(['sourceWarehouse', 'items']);
    if (!formValues?.transferBetweenFarms) {
      formInstance.resetFields(['targetWarehouse']);
    }
  }, [formInstance, formValues?.sourceFarm, formValues?.transferBetweenFarms]);

  useEffect(() => {
    formInstance.resetFields([
      'sourceFarm',
      'targetFarm',
      'sourceWarehouse',
      'targetWarehouse',
      'items',
    ]);
  }, [formInstance, formValues?.transferBetweenFarms]);

  useEffect(() => {
    formInstance.resetFields(['targetWarehouse']);
  }, [formInstance, formValues?.targetFarm]);

  useEffect(() => {
    if (!drawerProps.open) {
      setDisableSave(true);
      setShowItems(false);
      formProps.form?.resetFields();
    }
  }, [drawerProps.open, formProps.form, setDisableSave, setShowItems]);

  useEffect(() => {
    formInstance
      .validateFields({ validateOnly: true })
      .then(() => {
        setDisableSave(false);
      })
      .catch((error: { errorFields: [{ name: string }] }) => {
        const hasFieldsError = error?.errorFields?.length > 0;
        setDisableSave(!hasFieldsError);
      });
  }, [formInstance, setDisableSave, formValues]);

  const handleOnFinish = (values: StockTransferModel) => {
    track('End Stock Transfer');
    const postingDate = dayjs(values.postingDate).format('YYYY-MM-DD');
    const postingTime = dayjs(values.postingTime).format('HH:mm:ss');
    const items = values.items.map((item) => ({
      qty: item.qty,
      itemCode: item.itemCode,
      sourceWarehouse: values.sourceWarehouse,
      targetWarehouse: values.targetWarehouse,
    }));
    const farm = values.sourceFarm;
    const stockEntryType = StockEntryEnum.MATERIAL_TRANSFER;

    const data = {
      items,
      stockEntryType,
      farm,
      postingDate,
      postingTime,
    };
    if (formValues?.transferBetweenFarms) {
      mutateTransferBetweenFarms({
        values: {
          ...data,
          sourceFarm: values.sourceFarm,
          targetFarm: values.targetFarm,
        },
        resource: 'stock-entry/transfer-between-farms',
      });
      onClose();
    } else if (formProps.onFinish) {
      return formProps.onFinish(data);
    }
  };

  return {
    disableSave,
    showItems,
    detailTabClassname,
    itemsTabClassname,
    disableItemsTab,
    drawerWidth,
    formInitialValues,
    itemsAmount: formValues?.items?.length ?? 0,
    setShowItems,
    handleOnFinish,
  };
};

export { useStockTransfer as default, useStockTransfer };
