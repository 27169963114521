import { create } from 'zustand';

type InvoiceFormState = {
  disableSave: boolean;
  showItems: boolean;
};

type InvoiceFormActions = {
  setDisableSave: (value: boolean) => void;
  setShowItems: (value: boolean) => void;
};

const useInvoiceFormStore = create<InvoiceFormState & InvoiceFormActions>(
  (set) => ({
    disableSave: false,
    setDisableSave: (value: boolean) => set(() => ({ disableSave: value })),
    showItems: false,
    setShowItems: (value: boolean) => set(() => ({ showItems: value })),
  }),
);

export { useInvoiceFormStore };
