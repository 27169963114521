import { AliasToken, OverrideToken } from 'antd/es/theme/interface';

import { BorderRadius } from './border-radius';
import {
  BlueColors,
  GreenColors,
  NeutralColors,
  RedColors,
  YellowColors,
} from './colors';
import { Shadows } from './shadows';

const token: Partial<AliasToken> = {
  colorPrimary: GreenColors[60],
  colorPrimaryBg: GreenColors[10],
  colorInfo: GreenColors[60],
  colorWarning: YellowColors[30],
  colorError: RedColors[50],
  colorSuccess: GreenColors[50],
  colorSuccessBg: GreenColors[10],
  borderRadius: BorderRadius.Default,
  borderRadiusSM: BorderRadius.Small,
  borderRadiusXS: BorderRadius.XSmall,
  colorLink: BlueColors[60],
  colorLinkHover: BlueColors[50],
  colorLinkActive: BlueColors[50],
  wireframe: false,
  colorTextBase: NeutralColors[100],
  colorSplit: NeutralColors[20],
};

const components: OverrideToken = {
  Tag: {
    colorInfoBg: GreenColors[10],
  },
  Table: {
    colorBorderSecondary: NeutralColors[10],
    colorFillAlter: NeutralColors[10],
    colorIcon: NeutralColors[80],
    colorIconHover: NeutralColors[100],
  },
  Form: {
    verticalLabelPadding: 0,
  },
  Card: {
    colorBorderSecondary: NeutralColors[20],
    boxShadowTertiary: Shadows.Elevation1,
  },
  Tabs: {
    colorPrimary: BlueColors[60],
    colorPrimaryHover: BlueColors[50],
  },
  Switch: {
    colorPrimary: BlueColors[60],
    colorPrimaryHover: BlueColors[50],
  },
  Notification: {
    colorSuccess: NeutralColors[0],
    colorText: NeutralColors[0],
    colorTextHeading: NeutralColors[0],
    colorIcon: NeutralColors[0],
    colorIconHover: NeutralColors[0],
    borderRadiusLG: 4,
    borderRadiusSM: 4,
  },
};

const theme = { components, token };

export { theme as default, theme };
