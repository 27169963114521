import { useWatch } from 'antd/es/form/Form';
import useFormInstance from 'antd/es/form/hooks/useFormInstance';
import { valueType } from 'antd/es/statistic/utils';
import { getCurrencyValue } from 'common/utils/currency.util';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useReferenceValueStore } from 'src/financial/reference-value/reference-value.store';
import { useFarm } from 'src/organization';

import { ObjectiveItemModel, ObjectiveModel } from '../objective-form.model';

interface ObjectiveFormItemsProps {
  objectiveType: 'productivity' | 'cost';
  farm?: string;
}

const useObjectiveFormItems = ({
  objectiveType,
  farm,
}: ObjectiveFormItemsProps) => {
  const { i18n } = useTranslation();
  const { getCurrencyByFarm } = useFarm();
  const { finalProduct } = useReferenceValueStore();
  const [updateAllValue, setUpdateAllValue] = useState<number | null>();
  const form = useFormInstance();
  const formValues = useWatch<ObjectiveModel>([]);

  const getUpdateAllValuePlaceholder = () => {
    if (objectiveType === 'productivity') {
      return `0 ${finalProduct?.uom ?? ''}`;
    }

    return getCurrencyValue(
      0,
      getCurrencyByFarm(farm ?? ''),
      i18n.language,
    ).toString();
  };

  const handleOnChangeUpdateAllValue = (value: valueType | null) => {
    setUpdateAllValue(value as number);
  };

  const handleFilterSearchObjectives = (value: string) => {
    form.setFieldsValue({
      items: [
        ...formValues.items.map((item: ObjectiveItemModel) => ({
          ...item,
          [objectiveType === 'productivity' ? 'showProductivity' : 'showCost']:
            item.projectName.toLowerCase().includes(value.toLowerCase()),
        })),
      ],
    });
  };

  const handleUpdateAllObjectiveValues = () => {
    const updatedObjectiveValues = formValues.items.map(
      (item: ObjectiveItemModel) => ({
        ...item,
        [objectiveType]: updateAllValue,
      }),
    );

    form.setFieldsValue({
      items: updatedObjectiveValues,
    });
  };

  return {
    setUpdateAllValue,
    handleOnChangeUpdateAllValue,
    handleFilterSearchObjectives,
    handleUpdateAllObjectiveValues,
    getUpdateAllValuePlaceholder,
    uom: finalProduct?.uom ?? '',
  };
};

export { useObjectiveFormItems };
