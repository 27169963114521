import styled from 'styled-components';

const Day = styled.span`
  font-size: 24px;
`;

const Date = styled.p`
  margin: 0;
  font-size: 16px;
`;

export { Date, Day };
