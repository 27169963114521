import { Alert, Layout } from 'antd';
import { Content } from 'antd/es/layout/layout';
import styled from 'styled-components';
import { BlueColors, MediaQuery, NeutralColors } from 'theme';

const LayoutDiv = styled(Layout)`
  height: 100vh;

  .fms-layout {
    background-color: ${NeutralColors[0]};
  }
`;

const MainContainer = styled(Content)`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  max-height: 100%;
  overflow-x: hidden;
`;

const ContentDiv = styled.div`
  position: relative;
  padding: 16px;
  margin-bottom: 52px;

  @media (min-width: ${MediaQuery.screenSizesCss.desktop}) {
    padding: 24px 56px;
    margin-bottom: 20px;
  }
`;

const AlertMaintenance = styled(Alert)`
  border-radius: 0;
  background-color: ${BlueColors[10]};
  border-color: ${BlueColors[10]};

  svg {
    fill: ${BlueColors[60]};
  }

  .fms-alert-description {
    color: ${NeutralColors[60]};
  }
`;

export { AlertMaintenance, ContentDiv, LayoutDiv, MainContainer };
