import { FormItemProps as FormItemPropsAntd } from 'antd';
import { ReactNode } from 'react';

import * as Styled from './form-item.style';

interface FormItemProps extends FormItemPropsAntd {
  icon?: ReactNode;
}

export const FormItem = ({ icon, label, ...props }: FormItemProps) => {
  return (
    <Styled.FormItem
      label={
        label || icon ? (
          <>
            {icon}
            {label}
          </>
        ) : undefined
      }
      {...props}
    />
  );
};
