import { FormattedDate } from 'common/components';

import * as Styled from './date-column.style';

interface DateColumnProps {
  date: string;
  purchaseNumber?: string;
}

const DateColumn = ({ date, purchaseNumber }: DateColumnProps) => {
  return (
    <Styled.Container>
      {purchaseNumber && <Styled.Paragraph>#{purchaseNumber}</Styled.Paragraph>}
      <FormattedDate date={date} />
    </Styled.Container>
  );
};

export { DateColumn };
