import { Form } from 'antd';
import styled from 'styled-components';
import { NeutralColors } from 'theme';

interface BoxProps {
  show: boolean;
}

const Box = styled.div<BoxProps>`
  display: ${(props) => (props.show ? 'block' : 'none')};
  margin-top: 24px;
  padding: 16px;
  border-radius: 8px;
  border: 1px solid ${NeutralColors[20]};
`;

const ContainerDates = styled.div`
  display: flex;
  gap: 16px;
`;

const FormItem = styled(Form.Item)`
  width: 100%;
  margin-bottom: 6px;

  .fms-picker {
    width: 100%;
  }
`;

export { Box, ContainerDates, FormItem };
