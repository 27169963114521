import { useTranslation } from 'react-i18next';

export interface CurrencyValueProps {
  value: number;
  currency?: string;
}

export const CurrencyValue = ({
  value,
  currency = 'BRL',
}: CurrencyValueProps) => {
  const { i18n } = useTranslation();

  if (value === undefined || value === null) {
    return null;
  }

  return (
    <>{value.toLocaleString(i18n.language, { style: 'currency', currency })}</>
  );
};
