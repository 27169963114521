import { Table } from 'antd';
import { CurrencyValue } from 'common/components';

import { ItemStockComparisonModel } from '../migration.model';
import { FmsBalanceQtyColumn } from './fms-balance-qty-column/fms-balance-qty-column.component';
import * as Styled from './migration-items.style';

interface MigrationItemsProps {
  items: ItemStockComparisonModel[];
  warehouse: string;
  refetch: () => void;
}

const MigrationItems = ({ items, warehouse, refetch }: MigrationItemsProps) => {
  return (
    <Styled.Container>
      <Styled.Table
        dataSource={items}
        showHeader={false}
        pagination={false}
        rowKey="itemCode"
      >
        <Table.Column dataIndex="item" />
        <Table.Column
          width={140}
          dataIndex="legacyBalanceQty"
          className="blue-column"
          render={(
            _,
            { legacyBalanceQty, itemUom }: ItemStockComparisonModel,
          ) => `${legacyBalanceQty?.toFixed(0)} ${itemUom}`}
        />
        <Table.Column
          width={120}
          dataIndex="legacyUnitValue"
          render={(legacyUnitValue) => (
            <CurrencyValue value={legacyUnitValue} />
          )}
        />
        <Table.Column
          width={120}
          dataIndex="fmsBalanceValue"
          render={(fmsBalanceValue) => (
            <CurrencyValue value={fmsBalanceValue} />
          )}
        />
        <Table.Column
          width={200}
          dataIndex="fmsBalanceQty"
          className="blue-column"
          render={(_, item: ItemStockComparisonModel) => (
            <FmsBalanceQtyColumn
              item={item}
              warehouse={warehouse}
              refetch={refetch}
            />
          )}
        />
        <Table.Column
          width={120}
          dataIndex="fmsUnitValue"
          render={(fmsUnitValue) => <CurrencyValue value={fmsUnitValue} />}
        />
        <Table.Column
          width={120}
          dataIndex="fmsBalanceValue"
          render={(fmsBalanceValue) => (
            <CurrencyValue value={fmsBalanceValue} />
          )}
        />
        <Table.Column width={120} />
      </Styled.Table>
    </Styled.Container>
  );
};

export { MigrationItems };
