import { Tooltip } from 'antd';
import { Modal } from 'common/components';
import { ROUTES } from 'common/constants';
import { ElectronicInvoiceIcon } from 'common/icons';
import { useNavigateWithOrgAndFarm } from 'common/utils';
import dayjs from 'dayjs';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ElectronicInvoiceContainer } from 'src/electronic-invoice';

import * as StyledTable from '../purchase-list.style';
import { PurchaseElectronicInvoicesTestId } from '../purchase-test-id.constants';
import * as Styled from './electronic-invoices-modal.style';

interface ElectronicInvoicesModalProps {
  supplier: string;
  date: string;
  purchaseOrderId: string;
}

const ElectronicInvoicesModal = ({
  date,
  supplier,
  purchaseOrderId,
}: ElectronicInvoicesModalProps) => {
  const [blockImport, setBlockImport] = useState(true);
  const navigate = useNavigateWithOrgAndFarm();
  const [idsElectronicInvoice, setIdsElectronicInvoice] = useState<string[]>(
    [],
  );
  const [openModal, setOpenModal] = useState<boolean>(false);
  const { electronicInvoiceFms1032ShowInvoiceButton } = useFlags();
  const { t } = useTranslation();

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleGoToElectronicInvoiceLink = () => {
    navigate(ROUTES.ELECTRONIC_INVOICE_LINK, undefined, undefined, undefined, {
      idsElectronicInvoice,
      purchaseOrderId,
    });
  };

  const renderHeader = () => (
    <Styled.Title level={4}>
      {t('electronic_invoice.modal.title')}
      <span>{` / ${supplier} - ${dayjs(date).format('DD/MM/YYYY')}`}</span>
    </Styled.Title>
  );

  if (electronicInvoiceFms1032ShowInvoiceButton) {
    return (
      <>
        <Modal
          header={renderHeader()}
          isDefaultFooter
          open={openModal}
          onCancel={handleCloseModal}
          okText={t('purchase.table.import_all')}
          okButtonProps={{
            disabled: idsElectronicInvoice.length === 0 || blockImport,
          }}
          onOk={handleGoToElectronicInvoiceLink}
          width={800}
        >
          <ElectronicInvoiceContainer
            referenceType="Purchase Order"
            referenceId={purchaseOrderId}
            setIdsElectronicInvoice={setIdsElectronicInvoice}
            setBlockImport={setBlockImport}
          />
        </Modal>
        <Tooltip title={t('purchase.table.see_invoices')}>
          <StyledTable.RoundButton
            icon={<ElectronicInvoiceIcon />}
            onClick={handleOpenModal}
            data-testid={
              PurchaseElectronicInvoicesTestId.ELECTRONIC_INVOICES_BUTTON
            }
          />
        </Tooltip>
      </>
    );
  }

  return null;
};

export { ElectronicInvoicesModal };
