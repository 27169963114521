import { Tooltip } from 'antd';
import { ExcelTable, ExcelTableProps } from 'common/components';
import { AlertTriangleIcon } from 'common/icons';
import { useMemo } from 'react';

import { DataImportPreviewModel } from '../data-import.model';
import * as Styled from './data-import-table.style';

interface DataImportTableProps {
  dataImportPreview?: DataImportPreviewModel;
}

export const DataImportTable = ({
  dataImportPreview,
}: DataImportTableProps) => {
  const generateDataTable = (
    data?: DataImportPreviewModel,
  ): ExcelTableProps => {
    if (!data) {
      return {
        data: [],
      };
    }

    const firstColumns = data.columns.map((column) => ({
      data: column,
    }));

    const firstLine = {
      columns: firstColumns,
    };

    const renderErrorLine = (errorMessage?: string) => {
      if (!errorMessage) {
        return null;
      }
      const errorMessageString = errorMessage.startsWith('[')
        ? errorMessage.slice(1, -1)
        : errorMessage;

      const dataArray: { message: string } = JSON.parse(
        JSON.parse(errorMessageString),
      );

      if (!dataArray) {
        return null;
      }

      return <span dangerouslySetInnerHTML={{ __html: dataArray.message }} />;
    };

    const lines = data.data?.map((line) => ({
      columns: line.columns.map((column) => {
        if (column.warning) {
          return {
            data: (
              <Tooltip title={column.warning}>
                <Styled.ColumnError>
                  {column.value}
                  <AlertTriangleIcon />
                </Styled.ColumnError>
              </Tooltip>
            ),
            hasError: true,
          };
        }
        return {
          data: <div>{column.value}</div>,
        };
      }),
      hasError:
        line.columns.some((column) => !!column.warning) ||
        line.success === false,
      errorMessage:
        line.success === false ? renderErrorLine(line.errorMessage) : undefined,
    }));

    return {
      data: [firstLine, ...lines],
    };
  };

  const excelTableProps = useMemo(
    () => generateDataTable(dataImportPreview),
    [dataImportPreview],
  );

  if (!dataImportPreview) {
    return null;
  }

  return <ExcelTable {...excelTableProps} />;
};
