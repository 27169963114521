import { Button, Dropdown } from 'antd';
import { BarcodeIcon, BoxIcon, ChevronBottomIcon } from 'common/icons';
import { useTranslation } from 'react-i18next';
import {
  InvoiceFormContainer,
  usePurchaseInvoiceDrawer,
} from 'src/orders-and-payments/invoice';
import { InvoiceFormModel } from 'src/orders-and-payments/invoice/invoice.model';
import {
  PurchaseCreateContainer,
  PurchaseRequestModel,
  usePurchaseDrawer,
} from 'src/orders-and-payments/purchase';

import * as Styled from './accounts-payable-create-button.style';

const AccountsPayableCreateButton = () => {
  const { t } = useTranslation();
  const { purchaseDrawer } = usePurchaseDrawer<PurchaseRequestModel>(
    'create',
    'create',
  );
  const { purchaseInvoiceDrawer } =
    usePurchaseInvoiceDrawer<InvoiceFormModel>('create');

  const renderLabel = (
    title: string,
    description: string,
    additionalDescription: string,
  ) => {
    return (
      <Styled.Container>
        <Styled.Title>{title}</Styled.Title>
        <Styled.Description>{description}</Styled.Description>
        <Styled.Description>{additionalDescription}</Styled.Description>
      </Styled.Container>
    );
  };

  const handleOpenCreatePurchase = () => {
    purchaseDrawer.show();
  };

  const handleOpenCreateInvoice = () => {
    purchaseInvoiceDrawer.show();
  };

  return (
    <>
      <PurchaseCreateContainer drawerForm={purchaseDrawer} />
      <InvoiceFormContainer drawerForm={purchaseInvoiceDrawer} />
      <Dropdown
        menu={{
          items: [
            {
              label: renderLabel(
                t('purchase.title'),
                t('orders_and_payments.buttons.purchase_description'),
                t(
                  'orders_and_payments.buttons.purchase_additional_description',
                ),
              ),
              key: '1',
              icon: <BoxIcon />,
              onClick: handleOpenCreatePurchase,
            },
            {
              type: 'divider',
              key: '2',
            },
            {
              label: renderLabel(
                t('invoice.title'),
                t('orders_and_payments.buttons.invoice_description'),
                t('orders_and_payments.buttons.invoice_additional_description'),
              ),
              key: '3',
              icon: <BarcodeIcon />,
              onClick: handleOpenCreateInvoice,
            },
          ],
        }}
      >
        <Button type="primary" icon={<ChevronBottomIcon />} iconPosition="end">
          {t('common.create')}
        </Button>
      </Dropdown>
    </>
  );
};

export { AccountsPayableCreateButton };
