import { Tag as TagAntd } from 'antd';
import styled from 'styled-components';
import { NeutralColors, YellowColors } from 'theme';

const Tag = styled(TagAntd)`
  background-color: ${NeutralColors[0]};
  border-color: ${YellowColors[30]};
  color: ${YellowColors[30]};
`;

export { Tag };
