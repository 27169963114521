import 'dayjs/locale/pt-br';

import { Space, Typography } from 'antd';
import { getCurrencyValue } from 'common/utils/currency.util';
import { useTranslation } from 'react-i18next';
import { BlueColors, NeutralColors, RedColors } from 'theme';

import { InvoiceReceiptTableModel } from '../invoice-receipt-table.model';
import * as Styled from './amount-column.style';

interface AmountColumnProps {
  data: InvoiceReceiptTableModel;
}

export const AmountColumn = ({ data }: AmountColumnProps) => {
  const { i18n } = useTranslation();

  const getColor = () => {
    if (data.isCancelled) {
      return NeutralColors[60];
    } else if (data.type === 'payment') {
      return RedColors[60];
    } else if (data.type === 'receipt') {
      return BlueColors[60];
    } else {
      return NeutralColors[60];
    }
  };

  return (
    <Space align="center">
      <Styled.CustomArrowIcon
        arrowUp={data.type !== 'receipt'}
        fillColor={getColor()}
        hidden={data.type === 'invoice'}
      />
      <Typography.Text
        strong={data.type !== 'invoice'}
        style={{
          color: getColor(),
        }}
      >
        {data.type === 'invoice' || data.type === 'payment'
          ? getCurrencyValue(data.amount, data.currency, i18n.language)
          : `${data.amount} ${data.uom}`}
      </Typography.Text>
    </Space>
  );
};

export default AmountColumn;
