import * as Styled from './colored-value.style';

interface ColoredValueProps {
  value: number;
  stockUom: string;
  greenOnPositive?: boolean;
}

const ColoredValue = ({
  value,
  stockUom,
  greenOnPositive = true,
}: ColoredValueProps) => {
  return (
    <Styled.Value
      isNegative={value < 0}
      greenOnPositive={greenOnPositive}
    >{`${value.toFixed(0)} ${stockUom}`}</Styled.Value>
  );
};

export { ColoredValue };
