import { useList } from '@refinedev/core';
import { useWatch } from 'antd/es/form/Form';
import useFormInstance from 'antd/es/form/hooks/useFormInstance';
import { useEffect } from 'react';

const useInsertProductionItem = (isEdit?: boolean) => {
  const form = useFormInstance();
  const crop = useWatch(['crop']);
  const itemsForm = useWatch(['items']);

  const { data, isFetching, refetch } = useList({
    resource: 'item/product-final/search',
    filters: [
      {
        field: 'cropId',
        operator: 'eq',
        value: crop,
      },
    ],
    queryOptions: {
      enabled: !!crop && !isEdit,
    },
  });

  useEffect(() => {
    if (data && !isEdit) {
      form.setFieldValue('items', data.data);
    }
  }, [data]);

  return {
    crop,
    data,
    refetch,
    itemsForm,
    isFetching,
  };
};

export { useInsertProductionItem };
