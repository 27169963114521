import { CrudFilters, CrudSort } from '@refinedev/core';

const convertFiltersToQueryString = (
  filters?: CrudFilters,
): string | undefined => {
  if (!filters || filters.length === 0) {
    return undefined;
  }
  const groupedFilters: Record<
    string,
    { field: string; operator: string; values: string[] }
  > = {};

  filters.forEach(({ field, operator, value }: any) => {
    const key = `${field}-${operator}`;
    if (!groupedFilters[key]) {
      groupedFilters[key] = { field, operator, values: [] };
    }
    if (value !== undefined) {
      groupedFilters[key].values.push(value);
    }
  });

  const queryString = Object.values(groupedFilters)
    .map((filter, index) => {
      const { field, operator, values } = filter;
      const queryParts = [
        `filters[${index}][field]=${encodeURIComponent(field)}`,
        `filters[${index}][operator]=${encodeURIComponent(operator)}`,
      ];
      values.forEach((value, valueIndex) => {
        queryParts.push(
          `filters[${index}][value][${valueIndex}]=${encodeURIComponent(
            value,
          )}`,
        );
      });
      return queryParts.join('&');
    })
    .join('&');

  return queryString;
};

const convertSortersToQueryString = (
  sorters?: CrudSort[],
): string | undefined => {
  if (!sorters || sorters.length === 0) {
    return undefined;
  }

  const queryString = sorters
    .map((filter, index) => {
      const { field, order } = filter;
      const queryParts = [
        `sorters[${index}][field]=${encodeURIComponent(field)}`,
        `sorters[${index}][order]=${encodeURIComponent(order)}`,
      ];
      return queryParts.join('&');
    })
    .join('&');

  return queryString;
};

export { convertFiltersToQueryString, convertSortersToQueryString };
