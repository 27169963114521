import { Button } from 'antd';
import { OutboxIcon, SprayIcon, TillageIcon } from 'common/icons';
import styled from 'styled-components';
import { NeutralColors, RedColors, YellowColors } from 'theme';

const OutboxIconContainer = styled(OutboxIcon)`
  fill: ${NeutralColors[60]};
  height: 20px;
`;

const SprayIconContainer = styled(SprayIcon)`
  fill: ${RedColors[60]};
  height: 20px;
`;

const LandPreparationIconContainer = styled(TillageIcon)`
  fill: ${YellowColors[60]};
  height: 20px;
`;

const CreateButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    display: inline-block;
  }
`;

const ViewButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    display: inline-block;
  }
`;

export {
  CreateButton,
  LandPreparationIconContainer,
  OutboxIconContainer,
  SprayIconContainer,
  ViewButton,
};
