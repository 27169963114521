import { ItemsIcon } from 'common/icons';

import * as Styled from './items-column.style';

interface ItemsColumnProps {
  itemsQty: number;
}

const ItemsColumn = ({ itemsQty }: ItemsColumnProps) => {
  return (
    <Styled.Container>
      <ItemsIcon />
      {itemsQty}
    </Styled.Container>
  );
};

export { ItemsColumn };
