import { UseDrawerFormReturnType } from '@refinedev/antd';
import { useUpdate } from '@refinedev/core';
import { Grid, Skeleton, Spin } from 'antd';
import { CurrencyValue, DrawerWithButtons } from 'common/components';
import { ROUTES } from 'common/constants';
import { ArchiveIcon } from 'common/icons';
import { useNavigateWithOrgAndFarm } from 'common/utils';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { ElectronicInvoiceEnum } from '../electronic-invoice.enum';
import { ElectronicInvoiceModel } from '../electronic-invoice.model';
import { generateExternalIdMask } from '../electronic-invoice.util';
import * as Styled from './electronic-invoice-details.style';
import { InvoiceDetails } from './invoice-details/invoice-details.component';
import { InvoiceItems } from './invoice-items/invoice-items.component';

interface ElectronicInvoiceDetailsProps {
  drawerForm: UseDrawerFormReturnType;
  referenceId?: string;
}

const ElectronicInvoiceDetails = ({
  drawerForm,
  referenceId,
}: ElectronicInvoiceDetailsProps) => {
  const { t } = useTranslation();
  const navigate = useNavigateWithOrgAndFarm();
  const breakpoint = Grid.useBreakpoint();
  const isFetching: boolean = drawerForm.queryResult?.isFetching ?? false;

  const { mutate, isLoading: isLoadingArchived } = useUpdate();

  const electronicInvoice: ElectronicInvoiceModel | undefined = useMemo(
    () => drawerForm.formProps.initialValues as ElectronicInvoiceModel,
    [drawerForm.formProps.initialValues],
  );

  const handleArchiveElectronicInvoice = () => {
    mutate({
      resource: 'electronic-invoice',
      values: {
        status: ElectronicInvoiceEnum.ARCHIVED,
      },
      id: electronicInvoice.id,
    });
  };

  const handleGoToElectronicInvoiceLink = () => {
    navigate(ROUTES.ELECTRONIC_INVOICE_LINK, undefined, undefined, undefined, {
      idsElectronicInvoice: [electronicInvoice.id],
      purchaseOrderId: referenceId,
    });
  };

  return (
    <DrawerWithButtons
      {...drawerForm.drawerProps}
      width={breakpoint.sm ? '400px' : '100%'}
      moreButtons={
        isFetching
          ? []
          : [
              <Styled.TotalBox key="total">
                {t('electronic_invoice.details.total')}
                <span>
                  <CurrencyValue value={electronicInvoice?.totalValue} />
                </span>
              </Styled.TotalBox>,
            ]
      }
      title={
        !isFetching && generateExternalIdMask(electronicInvoice?.externalId)
      }
      buttonSaveProps={{ onClick: handleGoToElectronicInvoiceLink }}
      textSave={t('common.import') ?? ''}
      backButton={
        electronicInvoice?.status !== ElectronicInvoiceEnum.ARCHIVED ? (
          <Styled.ArchivedButton onClick={handleArchiveElectronicInvoice}>
            {isLoadingArchived ? <Spin size="small" /> : <ArchiveIcon />}
          </Styled.ArchivedButton>
        ) : (
          <div />
        )
      }
    >
      {isFetching || !electronicInvoice ? (
        <Skeleton active />
      ) : (
        <div>
          <InvoiceDetails invoice={electronicInvoice} />
          {electronicInvoice.items?.map((item) => (
            <InvoiceItems key={item.itemCode} item={item} />
          ))}
        </div>
      )}
    </DrawerWithButtons>
  );
};

export { ElectronicInvoiceDetails };
