import styled from 'styled-components';
import { NeutralColors } from 'theme';

const BoxPayed = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  border: 1px solid ${NeutralColors[20]};
  margin-block: 16px;
  min-height: 70px;
  border-radius: 5px;
  padding-inline: 10px;
  div {
    margin-block: 4px;
    span {
      font-weight: bold;
    }
  }
`;

export { BoxPayed };
