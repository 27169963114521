import { captureException } from '@sentry/react';
import { USER_OPTIONS } from 'common/constants';
import { isProduction } from 'common/utils';
import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import HttpBackend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';

const languageStored = localStorage.getItem('i18nextLng') ?? null;

i18n
  .use(HttpBackend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    lng: languageStored ?? USER_OPTIONS.LanguagesEnum.PtBr,
    fallbackLng: USER_OPTIONS.LanguagesEnum.PtBr,
    interpolation: {
      escapeValue: false,
    },
    backend: {
      loadPath: '/i18n/{{lng}}.json',
    },
    debug: !isProduction,
  })
  .catch((error) => {
    captureException(error, {
      extra: {
        fileName: 'i18n.config.ts',
        method: 'fail on i18next init',
      },
    });
  });

export { languageStored };
