import { Button, Progress as ProgressAntd, Typography } from 'antd';
import styled from 'styled-components';
import { NeutralColors } from 'theme';

const Container = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${NeutralColors[10]};
  height: calc(100vh - 56px);
  overflow: auto;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 56px;
  background-color: ${NeutralColors[0]};
  padding: 0 36px;
`;

const Flex = styled.div`
  align-items: center;
  display: flex;
`;

const Title = styled(Typography.Title)`
  margin: 0 !important;
`;

const BackButton = styled(Button)`
  margin-right: 16px;
  border-radius: 36px;
`;

const ContainerAmount = styled.div`
  position: relative;
  margin-right: 24px;
`;

const BoxAmount = styled.div`
  position: absolute;
  left: 12px;
  top: -8px;
  z-index: 1;
  background-color: ${NeutralColors[0]};
  color: ${NeutralColors[50]};
  font-size: 12px;
  border: 1px solid ${NeutralColors[50]};
  border-radius: 8px;
  padding: 0 4px;
`;

const TotalText = styled.p`
  margin: 0 16px;
  color: ${NeutralColors[100]};

  span {
    margin-right: 8px;
    color: ${NeutralColors[60]};
  }
`;

const Progress = styled(ProgressAntd)`
  width: 80px;
  margin: 0 8px;
`;

const StepText = styled.p`
  margin: 0;
`;

const BodyElectronicInvoice = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
  max-width: 1340px;
  margin: auto;
  padding: 36px;
`;

export {
  BackButton,
  BodyElectronicInvoice,
  BoxAmount,
  Container,
  ContainerAmount,
  Flex,
  Header,
  Progress,
  StepText,
  Title,
  TotalText,
};
