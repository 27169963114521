import { Collapse } from 'antd';
import styled from 'styled-components';
import { NeutralColors } from 'theme';

const CollapseItem = styled(Collapse)`
  border: none;
  border-radius: 0px;
  background-color: transparent;

  .fms-collapse-item,
  .fms-collapse-content,
  .fms-collapse-content-box,
  .fms-collapse-header {
    border: none;
    border-radius: 0px !important;
  }

  .fms-collapse-item {
    margin-bottom: 16px;
    background-color: ${NeutralColors[10]};
  }
`;

export { CollapseItem };
