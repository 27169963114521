import { Typography } from 'antd';
import { ROUTES } from 'common/constants';
import { EmptyStateImage } from 'common/images';
import { useNavigateWithOrgAndFarm } from 'common/utils';
import dayjs from 'dayjs';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useTranslation } from 'react-i18next';

import * as Styled from './maintenance.style';

const MaintenanceContainer = () => {
  const { t } = useTranslation();
  const { blockFmsMaintenance } = useFlags<{ blockFmsMaintenance: string }>();
  const navigate = useNavigateWithOrgAndFarm();

  if (!blockFmsMaintenance) {
    navigate(ROUTES.ORGANIZATION);
  }

  return (
    <Styled.Container>
      <Styled.CentralizedContainer>
        <EmptyStateImage />
        <Typography.Title level={3}>{t('maintenance.title')}</Typography.Title>
        <Styled.Subtitle>
          {t('maintenance.subtitle', {
            time: dayjs(blockFmsMaintenance).format('HH:mm'),
          })}
        </Styled.Subtitle>
      </Styled.CentralizedContainer>
    </Styled.Container>
  );
};

export { MaintenanceContainer };
