import { Drawer as DrawerAntd, Tag as TagAntd } from 'antd';
import styled from 'styled-components';
import { NeutralColors } from 'theme';

interface TagProps {
  textColor: string;
  isLink?: boolean;
}

const Drawer = styled(DrawerAntd)`
  .fms-drawer-header-title {
    flex-direction: row-reverse;
  }

  .fms-drawer-close {
    margin-right: -10px;
  }

  .fms-drawer-body {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
`;

const Tag = styled(TagAntd)<TagProps>`
  border-color: ${(props) => props.textColor};
  color: ${(props) => props.textColor};
  background-color: ${NeutralColors[0]};
  display: flex;
  align-items: center;
  width: max-content;
  cursor: ${(props) => (props.isLink ? 'pointer' : 'auto')};

  svg {
    margin-right: 8px;
  }
`;

export { Drawer, Tag };
