import { InputNumber } from 'antd';
import FormItem from 'antd/es/form/FormItem';
import styled from 'styled-components';
import { NeutralColors } from 'theme';

interface CustomFormItemProps {
  show?: boolean;
}

const CustomFormItem = styled(FormItem)<CustomFormItemProps>`
  display: ${({ show }) => (show ? 'block' : 'none')};
  background-color: ${NeutralColors[0]};
  border: 1px solid ${NeutralColors[20]};
  border-radius: 12px;
  padding: 16px;
  height: min-content;
  width: 100%;
  margin-bottom: 0;
`;

const LabelWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 8px;
`;

const CustomInputNumber = styled(InputNumber)`
  width: 100%;
`;

export { CustomFormItem, CustomInputNumber, LabelWrapper };
