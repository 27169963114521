import { useOne } from '@refinedev/core';
import { Drawer, Skeleton, Table, Typography } from 'antd';
import {
  BuildOutlinedIcon,
  CalendarIcon,
  CloseIcon,
  FarmIcon,
  FarmStorageIcon,
  GaugeIcon,
  ItemsIcon,
  MoneyOffIcon,
  WarehouseBlackWhiteIcon,
} from 'common/icons';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  DashboardRequest,
  FreeRegistrationReport,
} from 'src/dashboard/dashboard.interface';
import { DateColumn } from 'src/orders-and-payments/purchase/date-column/date-column.component';
import { useOrganization } from 'src/organization';
import { YellowColors } from 'theme';

import * as StyledDashboard from '../../../dashboard.style';
import * as Styled from './free-registration-modal.style';

interface FreeRegistrationModalProps {
  farm?: string;
  amountFreeRegistrations: number;
}

const FreeRegistrationModal = ({
  amountFreeRegistrations,
  farm,
}: FreeRegistrationModalProps) => {
  const [openDrawer, setOpenDrawer] = useState(false);
  const { currentOrganizationId } = useOrganization();
  const { t } = useTranslation();
  const { landingFixPopup } = useFlags();

  const { data, isFetching } = useOne<DashboardRequest<FreeRegistrationReport>>(
    {
      resource: 'report',
      id: '',
      meta: {
        organizationId: currentOrganizationId,
        params: {
          reportName: landingFixPopup,
          farm,
        },
      },
      queryOptions: {
        enabled: openDrawer,
      },
    },
  );

  const handleCloseDrawer = () => {
    setOpenDrawer(false);
  };

  const handleOpenDrawer = () => {
    if (amountFreeRegistrations > 0) {
      setOpenDrawer(true);
    }
  };

  return (
    <>
      <StyledDashboard.Tag
        backgroundColor={YellowColors[10]}
        textColor={YellowColors[50]}
        margin
        onClick={handleOpenDrawer}
        style={{ cursor: amountFreeRegistrations > 0 ? 'pointer' : 'default' }}
        data-testid="free-registration-tag"
      >
        <GaugeIcon style={{ marginRight: '5px' }} />
        {`${amountFreeRegistrations} ${t('dashboard.no_cost')}`}
      </StyledDashboard.Tag>
      <Drawer
        width={'100%'}
        styles={{ body: { padding: '24px 48px' } }}
        open={openDrawer}
        onClose={handleCloseDrawer}
        closable={false}
        data-testid="drawer-free-registration"
      >
        <Styled.Header>
          <Typography.Title level={2}>
            {t('dashboard.free_registration.title')}
          </Typography.Title>
          <Styled.CloseIcon
            onClick={handleCloseDrawer}
            data-testid="close-free-registration"
          >
            <CloseIcon />
          </Styled.CloseIcon>
        </Styled.Header>
        <Styled.Text>
          <Styled.IconBox>
            <MoneyOffIcon />
          </Styled.IconBox>
          {t('dashboard.free_registration.description1')}
        </Styled.Text>
        <Styled.Text>
          <Styled.IconBox>
            <BuildOutlinedIcon />
          </Styled.IconBox>
          {t('dashboard.free_registration.description2')}
        </Styled.Text>
        {isFetching ? (
          <Skeleton active />
        ) : (
          <Table
            dataSource={data?.data.result?.map((item) => ({
              key: item.item,
              ...item,
            }))}
            pagination={false}
          >
            <Table.Column
              title={t('common.business.farm')}
              render={(item) => (
                <Styled.ContainerTd>
                  <Styled.BoxIcon>
                    <FarmIcon />
                  </Styled.BoxIcon>
                  {item.farm}
                </Styled.ContainerTd>
              )}
            />
            <Table.Column
              title={t('common.business.warehouse')}
              render={(item) => (
                <Styled.ContainerTd>
                  <Styled.BoxIcon>
                    <WarehouseBlackWhiteIcon />
                  </Styled.BoxIcon>
                  {item.warehouse}
                </Styled.ContainerTd>
              )}
            />
            <Table.Column
              title={t('common.business.item')}
              render={(item) => (
                <Styled.ContainerTd>
                  <Styled.BoxIcon>
                    <ItemsIcon />
                  </Styled.BoxIcon>
                  {item.item}
                </Styled.ContainerTd>
              )}
            />
            <Table.Column
              title={t('common.amount')}
              render={(item) => (
                <Styled.ContainerTd>
                  <Styled.BoxIcon>
                    <FarmStorageIcon />
                  </Styled.BoxIcon>
                  {`${item.quantityWithoutCost} ${item.uom}`}
                </Styled.ContainerTd>
              )}
            />
            <Table.Column
              title={t('common.date')}
              render={(item) => (
                <Styled.ContainerTd>
                  <Styled.BoxIcon>
                    <CalendarIcon />
                  </Styled.BoxIcon>
                  <DateColumn date={item.correctionEntryShouldBeMadeOn} />
                </Styled.ContainerTd>
              )}
            />
          </Table>
        )}
      </Drawer>
    </>
  );
};

export { FreeRegistrationModal };
