import { Button } from 'antd';
import styled from 'styled-components';

const CreateButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    display: inline-block;
  }
`;

const ViewButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    display: inline-block;
    width: 18px;
  }
`;

export { CreateButton, ViewButton };
