import {
  Button,
  Progress as ProgressAntd,
  Space as AntdSpace,
  Table as AntdTable,
} from 'antd';
import { ChevronBottomIcon, ChevronTopIcon } from 'common/icons';
import styled from 'styled-components';

import { PurchaseModel } from './purchase.model';

const Progress = styled(ProgressAntd)`
  min-width: 60px;
`;

const Table = styled(AntdTable<PurchaseModel>)`
  .fms-table-expanded-row {
    .fms-table-cell {
      background-color: unset !important;
    }
  }

  .fms-table-row-expand-icon-cell {
    padding: 0 !important;
  }
`;

const Space = styled(AntdSpace)`
  .fms-space-item {
    width: 36px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

const CustomChevronTopIcon = styled(ChevronTopIcon)`
  display: block;
`;

const CustomChevronBottomIcon = styled(ChevronBottomIcon)`
  display: block;
`;

const CreateButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    display: inline-block;
  }
`;

const RoundButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 16px;

  svg {
    display: inline-block;
  }
`;

export {
  CreateButton,
  CustomChevronBottomIcon,
  CustomChevronTopIcon,
  Progress,
  RoundButton,
  Space,
  Table,
};
