import { add, track as amplitudeTrack } from '@amplitude/analytics-browser';
import { EnrichmentPlugin } from '@amplitude/analytics-types';
import { useCallback, useMemo } from 'react';
import { useAuth } from 'src/auth';
import { useFarm, useOrganization } from 'src/organization';

type TrackingHookProps = {
  track: (
    action: string,
    additionalProperties?: Record<string, string>,
  ) => void;
};

const useTracking = (): TrackingHookProps => {
  const { currentOrganization, currentWorkspaceId } = useOrganization();
  const { user } = useAuth();
  const { currentFarm } = useFarm();

  const defaultProperties = useMemo(() => {
    return {
      organizationId: currentOrganization?.id ?? null,
      organizationName: currentOrganization?.name ?? null,
      workspaceId: currentWorkspaceId ?? null,
      workspaceName: currentOrganization?.workspace_name ?? null,
      farmId: currentFarm?.externalId ?? null,
      farmName: currentFarm?.name ?? null,
      jobTitle: user?.job_title ?? null,
    };
  }, [
    currentFarm?.externalId,
    currentFarm?.name,
    currentOrganization?.id,
    currentOrganization?.name,
    currentOrganization?.workspace_name,
    currentWorkspaceId,
    user?.job_title,
  ]);

  const enrichPropertiesPlugin = (): EnrichmentPlugin => {
    return {
      execute: async (event) => {
        event.event_properties = {
          ...event.event_properties,
          ...defaultProperties,
        };
        return event;
      },
    };
  };

  add(enrichPropertiesPlugin());

  const track = useCallback(
    (action: string, additionalProperties: Record<string, string> = {}) => {
      const actionProperties: Record<string, string | null> = {
        ...additionalProperties,
      };

      amplitudeTrack(action, actionProperties);
    },
    [],
  );

  return {
    track,
  };
};

export { useTracking as default, useTracking };
