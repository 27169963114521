import { FormProps } from 'antd';
import { FormItem, SelectWithClear } from 'common/components';
import { SearchIcon } from 'common/icons';
import {
  ListFiltersModel,
  RefineTableFiltersEnum,
} from 'common/refine-table-filters';

import { RefineTableFiltersItemTestId } from '../refine-table-filters.constants';
import { useRefineTableFiltersStore } from '../refine-table-filters.store';
import { RefineTableFiltersSelect } from '../refine-table-filters-select/refine-table-filters-select.component';
import * as Styled from './refine-table-filters-item.style';

interface RefineTableFiltersItemProps {
  filter: ListFiltersModel;
  searchFormProps?: FormProps;
  onClickClearIfEmpty?: (fieldId: string) => void;
  isHidden?: boolean;
  storeName?: string;
}

export const RefineTableFiltersItem = ({
  filter,
  searchFormProps,
  onClickClearIfEmpty,
  isHidden,
  storeName,
}: RefineTableFiltersItemProps) => {
  useRefineTableFiltersStore(storeName);
  const handleOnClickClearIfEmpty = () => {
    if (onClickClearIfEmpty) {
      onClickClearIfEmpty(filter.field);
    }
  };

  switch (filter.type) {
    case RefineTableFiltersEnum.DATE:
      return (
        <FormItem
          label={filter.label}
          icon={filter.icon}
          name={filter.field}
          hidden={isHidden}
        >
          <Styled.InputDate
            format={'DD/MM/YYYY'}
            suffixIcon={null}
            allowClear={false}
            clearIcon={<SearchIcon />}
          />
        </FormItem>
      );
    case RefineTableFiltersEnum.MULTIPLE_SELECT:
    case RefineTableFiltersEnum.SELECT:
      if (filter.options || !filter.resource) {
        return (
          <FormItem
            icon={filter.icon}
            label={filter.label}
            name={filter.field}
            hidden={isHidden}
          >
            <SelectWithClear
              formProps={searchFormProps}
              isSendFormAfterClear
              name={filter.field}
              onClickClearIfEmpty={handleOnClickClearIfEmpty}
              data-testid={
                RefineTableFiltersItemTestId.REFINE_TABLE_FILTERS_ITEM_SELECT
              }
              onChange={(value) => {
                if (
                  filter.type !== RefineTableFiltersEnum.MULTIPLE_SELECT &&
                  value?.length > 1
                ) {
                  value.shift();
                }
              }}
              mode="multiple"
              options={filter.options ?? []}
            />
          </FormItem>
        );
      }
      return (
        <RefineTableFiltersSelect
          filter={filter}
          isMultiple={filter.type === RefineTableFiltersEnum.MULTIPLE_SELECT}
          searchFormProps={searchFormProps}
          onClickClearIfEmpty={handleOnClickClearIfEmpty}
          isHidden={isHidden}
        />
      );
    case RefineTableFiltersEnum.INPUT:
    default:
      return (
        <FormItem
          label={filter.label}
          icon={filter.icon}
          name={filter.field}
          hidden={isHidden}
        >
          <Styled.Input
            data-testid={
              RefineTableFiltersItemTestId.REFINE_TABLE_FILTERS_ITEM_INPUT
            }
          />
        </FormItem>
      );
  }
};
