import * as Styled from './square-skeleton.style';

interface SquareSkeletonProps {
  width?: string;
  height?: string;
  borderRadius?: string;
}

const SquareSkeleton = ({
  width,
  height,
  borderRadius,
}: SquareSkeletonProps) => (
  <Styled.SquareSkeleton
    data-testid="square-skeleton-id"
    style={{
      width: width ?? '280px',
      height: height ?? '50px',
      borderRadius: borderRadius ?? '8px',
    }}
  />
);

export { SquareSkeleton, type SquareSkeletonProps };
