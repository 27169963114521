import {
  IDataContextProvider,
  IDataMultipleContextProvider,
  IRefineOptions,
} from '@refinedev/core/dist/interfaces';

import { apiUrlOptions } from './api.config';
import { refineDataProvider } from './refine-data.provider';
import { refineSimpleRestDataProvider } from './refine-simple-rest-data.provider';

interface RefineConfigProps {
  dataProvider: IDataContextProvider | IDataMultipleContextProvider;
  options: IRefineOptions;
}

const refineConfig: RefineConfigProps = {
  dataProvider: {
    default: refineDataProvider(`${apiUrlOptions.fms}/v1`),
    defaultV2: refineDataProvider(`${apiUrlOptions.fms}/v2`),
    simpleRest: refineSimpleRestDataProvider(`${apiUrlOptions.fms}/v1`),
    platformV2: refineSimpleRestDataProvider(`${apiUrlOptions.base}/v2`),
    platformV3: refineSimpleRestDataProvider(`${apiUrlOptions.base}/v3`),
  },
  options: {
    syncWithLocation: true,
    warnWhenUnsavedChanges: false,
    disableTelemetry: true,
  },
};

export { refineConfig as default, refineConfig };
