import { Card, Form } from 'antd';
import styled from 'styled-components';
import { GreenColors } from 'theme';

interface BoxProps {
  show: boolean;
}

const Box = styled(Card)<BoxProps>`
  display: ${(props) => (props.show ? 'block' : 'none')};
  border-radius: 8px;
  margin-top: 24px;

  .fms-card-body {
    padding: 16px;
  }

  .fms-picker {
    width: 100%;
  }
`;

const DashedBox = styled.div`
  display: flex;
  width: 100%;
  padding: 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  border-radius: 12px;
  border: 1px dashed ${GreenColors[60]};
`;

const SwitchWrapper = styled(Form.Item)`
  width: 100%;
  margin: 0;

  .fms-form-item {
    margin-bottom: 0;
  }

  .fms-form-item-control-input-content {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 16px;
  }
`;

const FormItem = styled(Form.Item)`
  width: 100%;
  margin: 0;

  .fms-picker {
    width: 100%;
  }
`;

export { Box, DashedBox, FormItem, SwitchWrapper };
