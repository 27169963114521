import { ButtonProps, DrawerProps, Form, FormProps } from 'antd';
import { DrawerWithButtons } from 'common/components';
import { useTranslation } from 'react-i18next';

import { useOpeningStock } from './opening-stock.hook';
import { useOpeningStockStore } from './opening-stock.store';
import * as Styled from './opening-stock.style';
import { OpeningStockDetails } from './opening-stock-details/opening-stock-details.component';
import { OpeningStockItems } from './opening-stock-items/opening-stock-items.component';

interface OpeningStockContainerProps {
  drawerProps: DrawerProps;
  formProps: FormProps;
  onClose: () => void;
  onOpenStock: ButtonProps;
  warehouseId?: string;
  farmId?: string;
}

export const OpeningStockContainer = ({
  drawerProps,
  formProps,
  onClose,
  onOpenStock,
  warehouseId,
  farmId,
}: OpeningStockContainerProps) => {
  const { t } = useTranslation();

  const {
    showItems,
    detailTabClassname,
    itemsTabClassname,
    disableItemsTab,
    drawerWidth,
    formInitialValues,
    farmData,
    itemsAmount,
    setShowItems,
    handleOnFinish,
  } = useOpeningStock(formProps, drawerProps, warehouseId, farmId);

  const { disableSave } = useOpeningStockStore();

  return (
    <DrawerWithButtons
      {...drawerProps}
      width={drawerWidth}
      zIndex={1001}
      title={t('opening_stock.title', { target: warehouseId ?? farmId })}
      buttonSaveProps={{
        ...onOpenStock,
        onClick: showItems ? onOpenStock.onClick : () => setShowItems(true),
      }}
      disabledSave={showItems ? disableSave : disableItemsTab}
      onClose={onClose}
      tagTrackClose="Opening Stock"
      onBack={showItems ? () => setShowItems(false) : undefined}
      textSave={!showItems ? t('buttons.nextStep') ?? '' : undefined}
    >
      <Styled.BoxButtons data-testid="opening-stock-buttons-wrapper">
        <Styled.ButtonSelected
          onClick={() => setShowItems(false)}
          type={detailTabClassname}
        >
          {t('opening_stock.details.title')}
        </Styled.ButtonSelected>
        <Styled.ButtonSelected
          onClick={() => setShowItems(true)}
          type={itemsTabClassname}
          disabled={disableItemsTab}
        >
          {t('opening_stock.items.title')}
          <Styled.BoxAmount isSelected={showItems}>
            {itemsAmount}
          </Styled.BoxAmount>
        </Styled.ButtonSelected>
      </Styled.BoxButtons>
      <Form
        layout="vertical"
        {...formProps}
        onFinish={handleOnFinish}
        data-testid="opening-stock-form"
        initialValues={formInitialValues}
      >
        <OpeningStockDetails farmId={farmId} />
        <OpeningStockItems currency={farmData?.data?.currency} />
      </Form>
    </DrawerWithButtons>
  );
};
