import { NeutralColors } from 'theme';

import { LegendData } from '../charts.model';

interface ChartHorizontalBarLabelProps {
  legendData?: LegendData;
  isLastBar: boolean;
  isFirstBar: boolean;
  y: number;
  height: number;
}

const ChartHorizontalBarLabel = ({
  legendData,
  isLastBar,
  isFirstBar,
  y,
  height,
}: ChartHorizontalBarLabelProps) => {
  return (
    <g>
      <style>
        {`
            text {
              font-family: 'Noto Sans';
              font-size: 12px;
              fill: ${NeutralColors[100]};
            }
            text tspan {
              font-family: 'Noto Sans';
              font-size: 12px;
              fill: ${NeutralColors[60]};
            }
            .line-info {
              font-family: 'Noto Sans';
              font-size: 12px;
              fill: ${NeutralColors[60]};
            }
          `}
      </style>
      {isFirstBar && (
        <text dy={y - 4}>
          {legendData?.label} <tspan>{legendData?.moreLabel}</tspan>
        </text>
      )}

      {isLastBar && (
        <text dy={y + height + 13} className="line-info">
          {legendData?.lineInfo}
        </text>
      )}
    </g>
  );
};

export { ChartHorizontalBarLabel, ChartHorizontalBarLabel as default };
