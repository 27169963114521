import { Layout } from 'antd';
import dayjs from 'dayjs';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import { Outlet } from 'react-router-dom';
import { MediaQuery } from 'theme';

import Header from './header/header.component';
import SiderNavbarComponent from './sider-navbar/sider-navbar.component';
import * as Styled from './structure-template.style';

const StructureTemplate = () => {
  const { t } = useTranslation();
  const isDesktop = useMediaQuery({
    minWidth: MediaQuery.screenSizesCss.desktop,
  });
  const { plannedMaintenance } = useFlags<{ plannedMaintenance: string }>();

  const [startDate, endDate] = plannedMaintenance?.split(' ') ?? [];

  return (
    <Styled.LayoutDiv>
      {isDesktop && <Header />}
      <Layout>
        {isDesktop && <SiderNavbarComponent />}
        <Styled.MainContainer>
          {startDate && endDate && (
            <Styled.AlertMaintenance
              message={t('maintenance.planned_maintenance_title', {
                startDate: dayjs(startDate).format('DD/MM/YYYY HH:mm'),
                endDate: dayjs(endDate).format('DD/MM/YYYY HH:mm'),
              })}
              description={t('maintenance.planned_maintenance_subtitle')}
              type="info"
              showIcon
              closable
            />
          )}
          <Styled.ContentDiv>
            <Outlet />
          </Styled.ContentDiv>
        </Styled.MainContainer>
      </Layout>
    </Styled.LayoutDiv>
  );
};

export { StructureTemplate as default, StructureTemplate };
