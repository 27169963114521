import { Card as CardAntd, Radio } from 'antd';
import styled from 'styled-components';

const Card = styled(CardAntd)`
  display: flex;
  flex-wrap: wrap;
  padding: 8px;
`;

const ContainerButtons = styled(Radio.Group)`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 16px;
`;

export { Card, ContainerButtons };
