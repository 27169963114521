import { Typography } from 'antd';
import { EmptyStateOrganizationImage } from 'common/images';
import { useTranslation } from 'react-i18next';

import * as Styled from './no-organization.style';

const NoOrganization = () => {
  const { t } = useTranslation();

  return (
    <Styled.NoOrganizationWrapper>
      <Typography.Title level={4}>
        {t('organizations.no_organization_access')}
      </Typography.Title>
      <EmptyStateOrganizationImage />
    </Styled.NoOrganizationWrapper>
  );
};

export { NoOrganization as default, NoOrganization };
