import { ResultItem } from 'src/financial/financial-overview/financial-overview.model';
import { create } from 'zustand';

import { ProjectModel } from './objective-form.model';

type ObjectiveFormState = {
  projectByName: Record<string, ProjectModel>;
  currentValuesByName: Record<string, { cost: number; productivity: number }>;
};

type ObjectiveFormActions = {
  setProjectByName: (projects: ProjectModel[]) => void;
  setCurrentValuesByName: (values: ResultItem[]) => void;
};

const useObjectiveFormStore = create<ObjectiveFormState & ObjectiveFormActions>(
  (set) => ({
    projectByName: {},
    setProjectByName: (projects) =>
      set(() => ({
        projectByName: projects.reduce((acc, project) => {
          acc[project.projectName] = project;
          return acc;
        }, {} as Record<string, ProjectModel>),
      })),
    currentValuesByName: {},
    setCurrentValuesByName: (values) =>
      set(() => ({
        currentValuesByName: values.reduce((acc, value) => {
          acc[value.field] = {
            cost: value.totalPerHectare,
            productivity: value.productionTarget.totalProducedPerHectare,
          };
          return acc;
        }, {} as Record<string, { cost: number; productivity: number }>),
      })),
  }),
);

export { useObjectiveFormStore };
