import { UseDrawerFormReturnType } from '@refinedev/antd';
import { BaseKey, HttpError, useDelete } from '@refinedev/core';
import { FormProps, Grid } from 'antd';
import { useTracking } from 'common/utils';
import {
  Dispatch,
  MouseEvent,
  SetStateAction,
  useEffect,
  useState,
} from 'react';
import { useItemCategoryCreateDrawer } from 'src/resources/item-category/use-item-category-create-drawer.hook';

import { ItemModel } from '../item.model';
import { ItemTypeEnum } from '../item-type.enum';
import { ChooseItemGroup } from './choose-item-group/choose-item-group.component';
import ItemForm from './item-form/item-form.component';
import { SearchItem } from './search-item/search-item.component';

interface UseItemDrawerProps {
  useDrawerFormValue: UseDrawerFormReturnType<ItemModel, HttpError, ItemModel>;
  setResourceCreate: Dispatch<SetStateAction<string>>;
}

const useItemDrawer = ({
  useDrawerFormValue,
  setResourceCreate,
}: UseItemDrawerProps) => {
  const [disabledFields, setDisabledFields] = useState<string[]>([]);
  const [currentStep, setCurrentStep] = useState<number>(0);
  const [hideFields, setHideFields] = useState<string[]>(['crop']);
  const breakpoint = Grid.useBreakpoint();
  const {
    mutate: mutateDelete,
    isSuccess: isSuccessDelete,
    isLoading: isLoadingDelete,
  } = useDelete();

  const { track } = useTracking();

  useEffect(() => {
    if (isSuccessDelete && drawerProps?.onClose) {
      drawerProps.onClose({} as MouseEvent);
    }
  }, [isSuccessDelete]);

  const handleGoToNextStep = () => {
    setCurrentStep((step) => step + 1);
  };

  const {
    formProps,
    drawerProps,
    saveButtonProps,
    id,
    formLoading,
    close,
    mutationResult,
  } = useDrawerFormValue ?? {};

  const handleOnClickDelete = (id?: BaseKey) => {
    if (id) {
      track('Delete Item');
      mutateDelete({
        resource: 'item',
        id,
      });
    }
  };

  const handleOnFinish = (values: ItemModel, formProps: FormProps) => {
    formProps.onFinish?.({
      allowNegativeStock: values.allowNegativeStock,
      isStockItem: values.isStockItem,
      category: values.category,
      code: values.code,
      defaults: values.defaults,
      description: values.description,
      name: values.name,
      source: values.source,
      uom: values.uom,
      valuationMethod: values.valuationMethod,
      cropId: values.cropId,
      valueReference: values.valueReference,
      id: formProps.form?.getFieldValue('selectItem') ?? (id as string),
    });
  };

  const { createItemCategoryDrawer, handleOpenItemCategoryDrawer } =
    useItemCategoryCreateDrawer();

  const steps = [
    {
      component: (
        <ChooseItemGroup
          formProps={formProps}
          key="choose_item_group"
          setResourceCreate={setResourceCreate}
          setHideFields={setHideFields}
          setDisabledFields={setDisabledFields}
          handleGoToNextStep={handleGoToNextStep}
        />
      ),
      showOn: Object.values(ItemTypeEnum).map((itemType) => itemType),
    },
    {
      component: (
        <SearchItem
          formProps={formProps}
          key="search_item"
          setDisabledFields={setDisabledFields}
          handleGoToNextStep={handleGoToNextStep}
        />
      ),
      showOn: Object.values(ItemTypeEnum)
        .filter((value) => value !== ItemTypeEnum.FINISHED_GOODS)
        .map((itemType) => itemType),
    },
    {
      component: (
        <ItemForm
          hideFields={hideFields}
          formProps={formProps}
          key="item_form"
          disabledFields={disabledFields}
          handleOpenItemCategoryDrawer={handleOpenItemCategoryDrawer}
        />
      ),
      showOn: Object.values(ItemTypeEnum).map((itemType) => itemType),
    },
  ];

  useEffect(() => {
    if (!drawerProps.open) {
      formProps.form.resetFields();
      setCurrentStep(0);
    }
  }, [drawerProps.open, formProps.form]);

  return {
    id,
    breakpoint,
    currentStep,
    drawerProps,
    formProps,
    saveButtonProps,
    formLoading,
    close,
    mutationResult,
    setCurrentStep,
    steps,
    isLoadingDelete,
    handleOnClickDelete,
    handleOnFinish,
    createItemCategoryDrawer,
    handleOpenItemCategoryDrawer,
  };
};

export { useItemDrawer };
