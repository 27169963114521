import { Typography } from 'antd';
import { FC } from 'react';

import * as Styled from './item-description.style';

const ItemDescription: FC<{ name: string; description: string }> = ({
  name,
  description,
}) => {
  return (
    <Styled.ItemDescription>
      <Typography.Text strong>{name}</Typography.Text>
      <Typography.Text type="secondary">{description}</Typography.Text>
    </Styled.ItemDescription>
  );
};

export { ItemDescription as default, ItemDescription };
