import { Grid, Skeleton } from 'antd';
import { CloseIcon, CropwiseIcon } from 'common/icons';
import { useTranslation } from 'react-i18next';
import { useFarm, useOrganization } from 'src/organization';
import { PurpleColors } from 'theme';

import { TypeColumnTestId } from '../../../material-consumption/material-consumption.constants';
import { MovementTag } from '../movement-tag/movement-tag.component';
import { MovementsModel } from '../movements.model';
import MovementsDetailsDrawerCardComponent from './card/details-drawer-card.component';
import { useMovementsDetailsDrawer } from './details-drawer.hook';
import * as Styled from './details-drawer.style';

interface DetailsDrawerProps {
  isOpen: boolean;
  onClose: () => void;
  voucherNo: string;
}

const MovementsDetailsDrawer = ({
  onClose,
  isOpen,
  voucherNo,
}: DetailsDrawerProps) => {
  const { farms } = useFarm();
  const urlProtector: string = import.meta.env.VITE_PROTECTOR_URL ?? '';
  const { currentOrganizationId } = useOrganization();
  const { movementsDetailsData, isLoadingMovementsDetails } =
    useMovementsDetailsDrawer({ voucherNo });

  const breakpoint = Grid.useBreakpoint();
  const { t } = useTranslation();

  const renderTitle = (movement?: MovementsModel) => {
    const selectedFarm = farms.find(
      (currentFarm) => currentFarm.name === movement?.farm,
    );

    const urlTimeline = `${urlProtector}/company/${currentOrganizationId}/property/${selectedFarm?.externalId}/timeline?selected=${movement?.locationId}&start=${movement?.postingDate}`;

    const handleOnClick = () => {
      if (movement?.locationId) {
        window.open(urlTimeline, '_blank');
      }
    };

    if (movement?.externalId2) {
      return (
        <Styled.Tag
          data-testid={TypeColumnTestId.TYPE_COLUMN_CREATED_PROTECTOR}
          textColor={PurpleColors[50]}
          onClick={handleOnClick}
          isLink={!!movement?.locationId}
        >
          <CropwiseIcon />
          {t('material_consumption.type.register_application', {
            externalId: movement.externalId2,
          })}
        </Styled.Tag>
      );
    }

    return <MovementTag movementType={movement?.movementView} />;
  };

  return (
    <Styled.Drawer
      open={isOpen}
      onClose={onClose}
      zIndex={1001}
      closeIcon={<CloseIcon />}
      width={breakpoint.sm ? '400px' : '100%'}
      destroyOnClose
      title={
        isLoadingMovementsDetails ? (
          <Skeleton.Input active />
        ) : (
          renderTitle(movementsDetailsData?.data[0])
        )
      }
    >
      {isLoadingMovementsDetails ? (
        <Skeleton active />
      ) : (
        movementsDetailsData?.data.map((movement) => (
          <MovementsDetailsDrawerCardComponent
            key={movement.itemCode}
            movement={movement}
          />
        ))
      )}
    </Styled.Drawer>
  );
};

export default MovementsDetailsDrawer;
