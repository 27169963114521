import {
  Card as AntdCard,
  Divider as AntdDivider,
  Row as AntdRow,
  Typography,
} from 'antd';
import styled from 'styled-components';
import { GreenColors, RedColors } from 'theme';

interface CardProps {
  transferType?: 'transfer_in' | 'transfer_out';
}

const Card = styled(AntdCard)<CardProps>`
  .fms-card-head {
    border: none;
    margin-bottom: 0;
    padding: 0 16px;
  }

  .fms-card-body {
    padding: 0 16px 16px 16px;
  }

  border: ${({ transferType }) => {
    switch (transferType) {
      case 'transfer_in':
        return `1px solid ${GreenColors[60]}`;
      case 'transfer_out':
        return `1px solid ${RedColors[60]}`;
      default:
        return 'default';
    }
  }};
`;

const Divider = styled(AntdDivider)`
  margin: 12px 0 0 0 !important;
`;

const Paragraph = styled(Typography.Paragraph)`
  margin: 0 !important;
`;

const Row = styled(AntdRow)`
  margin-bottom: 8px;
`;

export { Card, Divider, Paragraph, Row };
