import { useInvalidate } from '@refinedev/core';

const useInvalidateTableData = () => {
  const invalidate = useInvalidate();

  const refetchTableAndInvalidate = () => {
    invalidate({
      resource: 'payment-entry',
      invalidates: ['resourceAll'],
      invalidationOptions: {
        cancelRefetch: true,
      },
    });
    invalidate({
      resource: 'purchase-order',
      invalidates: ['resourceAll'],
      invalidationOptions: {
        cancelRefetch: true,
      },
    });
    invalidate({
      resource: 'purchase-invoice',
      invalidates: ['resourceAll'],
      invalidationOptions: {
        cancelRefetch: true,
      },
    });
    invalidate({
      resource: 'purchase-invoice/ids',
      invalidates: ['resourceAll'],
      invalidationOptions: {
        cancelRefetch: true,
      },
    });
    invalidate({
      resource: 'purchase-receipt/ids',
      invalidates: ['resourceAll'],
      invalidationOptions: {
        cancelRefetch: true,
      },
    });
    invalidate({
      resource: 'purchase-receipt',
      invalidates: ['resourceAll'],
      invalidationOptions: {
        cancelRefetch: true,
      },
    });
    invalidate({
      resource: 'report/accounts-payable',
      invalidates: ['resourceAll'],
      invalidationOptions: {
        cancelRefetch: true,
      },
    });
  };

  return {
    refetchTableAndInvalidate,
  };
};

export { useInvalidateTableData };
