import { CrudFilters } from '@refinedev/core';
import { generateFilter } from '@refinedev/simple-rest';

const customGenerateFilter = (filters?: CrudFilters) => {
  return Object.entries(generateFilter(filters)).reduce((acc, [key, value]) => {
    return {
      ...acc,
      [key]: Array.isArray(value) && value.length === 1 ? value[0] : value,
    };
  }, {});
};

export { customGenerateFilter };
