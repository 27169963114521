import { Typography } from 'antd';
import { ChevronIcon, SearchIcon } from 'common/icons';
import { PropsWithChildren, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import { useNavigate } from 'react-router-dom';
import { MediaQuery } from 'theme';

import * as Styled from './search-page-template.style';

interface SearchPageTemplateType {
  title: string;
  onBack?: () => void;
  hideBackButton?: boolean;
  onSearch?: (value: string) => void;
}

const SearchPageTemplate = ({
  title,
  onBack,
  hideBackButton,
  onSearch,
  children,
}: PropsWithChildren<SearchPageTemplateType>) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const isDesktop = useMediaQuery({
    minWidth: MediaQuery.screenSizesCss.desktop,
  });
  const [searchVisible, setSearchVisible] = useState(false);

  useEffect(() => {
    isDesktop ? setSearchVisible(true) : setSearchVisible(false);
  }, [isDesktop]);

  const handleReturnButton = () => {
    if (!isDesktop && searchVisible) {
      setSearchVisible(() => !searchVisible);
    } else {
      onBack ? onBack() : navigate(-1);
    }
  };

  const handleSearchVisible = useCallback(() => {
    setSearchVisible((current) => !current);
  }, []);

  return (
    <>
      <Styled.Header>
        {!hideBackButton && (
          <Styled.ReturnButton
            shape="circle"
            size="large"
            data-testid="header-back-button"
            icon={<ChevronIcon />}
            onClick={handleReturnButton}
          />
        )}
        <Typography.Title level={5}>{title}</Typography.Title>
        {!isDesktop && (
          <Styled.SearchButton
            shape="circle"
            size="large"
            icon={<SearchIcon />}
            data-testid="header-search-button"
            onClick={handleSearchVisible}
          />
        )}
        {searchVisible && (
          <Styled.Search
            prefix={<SearchIcon />}
            type="link"
            size="large"
            allowClear
            data-testid="header-search-input"
            placeholder={`${t('common.search')}`}
            onChange={(e) => onSearch?.(e.target.value)} //TODO: Debounce
          />
        )}
      </Styled.Header>
      <Styled.Body>{children}</Styled.Body>
    </>
  );
};

export { SearchPageTemplate as default, SearchPageTemplate };
