export const InvoicePaymentFormTestId = {
  Container: 'invoice-payment-form-container',
  SKELETON: 'invoice-payment-form-skeleton',
};

export const InvoicePaymentFormDateTestId = {
  INVOICE_PAYMENT_FORM_DATE_CONTAINER: 'invoice-payment-form-date-container',
  INVOICE_PAYMENT_FORM_DATE_DATE_PICKER:
    'invoice-payment-form-date-date-picker',
  INVOICE_PAYMENT_FORM_DATE_TIME_PICKER:
    'invoice-payment-form-date-time-picker',
};

export const InvoicePaymentFormInfoTestId = {
  INVOICE_PAYMENT_FORM_INFO_CONTAINER: 'invoice-payment-form-info-container',
  INVOICE_PAYMENT_FORM_INFO_ACCOUNT: 'invoice-payment-form-info-account',
};

export const InvoicePaymentFormDetailTestId = {
  INVOICE_PAYMENT_FORM_DETAIL_CONTAINER:
    'invoice-payment-form-detail-container',
  INVOICE_PAYMENT_FORM_DETAIL_OUTSTANDING_TOTAL:
    'invoice-payment-form-detail-outstanding-total',
  INVOICE_PAYMENT_FORM_DETAIL_PAID_TOTAL:
    'invoice-payment-form-detail-paid-total',
  INVOICE_PAYMENT_FORM_DETAIL_PAID_AMOUNT:
    'invoice-payment-form-detail-paid-amount',
};
