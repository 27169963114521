import { CollapseIcon, ExpandIcon } from 'common/icons';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import * as Styled from './menu-trigger.style';

interface MenuTriggerProps {
  collapsed: boolean;
}

const MenuTrigger: FC<MenuTriggerProps> = ({ collapsed }) => {
  const { t } = useTranslation();

  return (
    <>
      {collapsed && (
        <Styled.Trigger>
          <ExpandIcon />
          <span>{t('sider.expand_trigger_button')}</span>
        </Styled.Trigger>
      )}
      {!collapsed && (
        <Styled.Trigger>
          <CollapseIcon />
          <span>{t('sider.collapse_trigger_button')}</span>
        </Styled.Trigger>
      )}
    </>
  );
};

export { MenuTrigger as default, MenuTrigger };
