import { FormListFieldData, InputNumber, Skeleton } from 'antd';
import {
  InputMoney,
  SelectTag,
  SelectWithComplementaryLabel,
} from 'common/components';
import { DeleteIcon, WarehouseIcon } from 'common/icons';
import { getCurrencySymbol } from 'common/utils';
import { useTranslation } from 'react-i18next';

import { MaterialConsumptionFormItemTestId } from '../../material-consumption-form.constants';
import { useMaterialConsumptionFormItem } from './material-consumption-form-item.hook';
import * as Styled from './material-consumption-form-item.style';

interface MaterialConsumptionFormItemProps {
  materialConsumptionRequest: FormListFieldData;
  removeItem: () => void;
  handleOpenItemDrawer: () => void;
  index: number;
}

const MaterialConsumptionFormItem = ({
  materialConsumptionRequest,
  index,
  removeItem,
  handleOpenItemDrawer,
}: MaterialConsumptionFormItemProps) => {
  const { t } = useTranslation();
  const {
    isFetchingItem,
    isFetchingStockSummary,
    qty,
    maxQty,
    isOpen,
    item,
    dataStockSummary,
    sourceWarehouse,
    currency,
    optionWarehouse,
    setIsOpen,
    itemSelectProps,
    warehouseSelectProps,
    isFifo,
  } = useMaterialConsumptionFormItem(index);

  if (!item || !dataStockSummary) {
    return (
      <Styled.Box
        data-testid={
          MaterialConsumptionFormItemTestId.MATERIAL_CONSUMPTION_FORM_ITEM_SEARCH_ITEM
        }
      >
        <Styled.TrashButtonAbsolute
          data-testid={
            MaterialConsumptionFormItemTestId.MATERIAL_CONSUMPTION_FORM_ITEM_DELETE
          }
          onClick={removeItem}
        >
          <DeleteIcon />
        </Styled.TrashButtonAbsolute>
        <Styled.FormItem
          label={t('material_consumption_form.items.name_item')}
          name={[materialConsumptionRequest.name, 'itemCode']}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <SelectWithComplementaryLabel
            {...itemSelectProps}
            placeholder={t('common.business.item_placeholder')}
            buttonProps={{
              onClick: handleOpenItemDrawer,
              children: t('common.create_new'),
            }}
          />
        </Styled.FormItem>
        {isFetchingItem && isFetchingStockSummary && <Skeleton active />}
      </Styled.Box>
    );
  }

  return (
    <Styled.Box
      data-testid={
        MaterialConsumptionFormItemTestId.MATERIAL_CONSUMPTION_FORM_ITEM_DETAILS
      }
    >
      <Styled.ContainerTitle isOpen={isOpen} onClick={() => setIsOpen(!isOpen)}>
        <Styled.Flex>
          <Styled.Chevron isOpen={isOpen} />
          <strong>{item.name}</strong>
        </Styled.Flex>
        <Styled.ContainerCenter>
          <strong>{`${qty ?? item?.qty ?? 0} ${item.stockUom}`}</strong>
          <Styled.TrashButton
            data-testid={
              MaterialConsumptionFormItemTestId.MATERIAL_CONSUMPTION_FORM_ITEM_DELETE
            }
            onClick={removeItem}
          >
            <DeleteIcon />
          </Styled.TrashButton>
        </Styled.ContainerCenter>
      </Styled.ContainerTitle>
      {isOpen && (
        <>
          <Styled.FormItem
            data-testid={
              MaterialConsumptionFormItemTestId.MATERIAL_CONSUMPTION_FORM_ITEM_SHOW
            }
            label={t('material_consumption_form.items.amount')}
            name={[materialConsumptionRequest.name, 'qty']}
            rules={[
              {
                required: true,
                max: item.allowNegativeStock ? undefined : maxQty,
                min: 0.01,
                type: 'number',
                message:
                  t('material_consumption_form.items.error_message_qty') ?? '',
              },
            ]}
          >
            <InputNumber addonAfter={item.stockUom} decimalSeparator="," />
          </Styled.FormItem>
          {!isFifo && (
            <Styled.ContainerValues>
              <Styled.FormItemValue
                label={t('material_consumption_form.items.unit_value')}
                name={[materialConsumptionRequest.name, 'basicRate']}
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <InputMoney
                  addonBefore={getCurrencySymbol(currency)}
                  maxLength={12}
                  disabled
                />
              </Styled.FormItemValue>
            </Styled.ContainerValues>
          )}
          <SelectTag
            {...warehouseSelectProps}
            options={optionWarehouse}
            Icon={WarehouseIcon}
            isOpenSelect
            labelSelect={t('common.business.warehouse') ?? ''}
            nameSelect={[materialConsumptionRequest.name, 'sourceWarehouse']}
            rules={[
              {
                required: true,
                message:
                  t(
                    'material_consumption_form.items.error_message_warehouse',
                  ) ?? '',
              },
            ]}
          >
            {sourceWarehouse ?? ''}
          </SelectTag>
        </>
      )}
    </Styled.Box>
  );
};

export { MaterialConsumptionFormItem };
