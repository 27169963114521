import { useSelect } from '@refinedev/antd';
import { CrudFilters } from '@refinedev/core';
import { FormProps } from 'antd/es/form/Form';
import { useMemo } from 'react';
import { useOrganization } from 'src/organization';

import { ItemTypeEnum } from '../../item-type.enum';

const useItemForm = (formProps?: FormProps) => {
  const { currentOrganizationId } = useOrganization();

  const metadata = useMemo(
    () => ({
      organizationId: currentOrganizationId,
    }),
    [currentOrganizationId],
  );
  const itemType = formProps?.form?.getFieldValue('itemType');

  const handleFiltersItemType = (): CrudFilters => {
    switch (itemType) {
      case ItemTypeEnum.CROP_PROTECTION:
        return [
          {
            field: 'isCatalogCP',
            operator: 'eq',
            value: true,
          },
        ];
      case ItemTypeEnum.FERTILIZER:
        return [
          {
            field: 'isCatalogFertilizer',
            operator: 'eq',
            value: true,
          },
        ];
      case ItemTypeEnum.SEED:
        return [
          {
            field: 'isCatalogSeed',
            operator: 'eq',
            value: true,
          },
        ];
      case ItemTypeEnum.FINISHED_GOODS:
        return [];
      default:
        return [
          {
            field: 'isCatalogGeneric',
            operator: 'eq',
            value: true,
          },
        ];
    }
  };

  const { selectProps: categorySelectProps } = useSelect({
    resource: 'item-category',
    onSearch: (value) => [
      {
        field: 'name',
        operator: 'eq',
        value: value,
      },
    ],
    queryOptions: {
      enabled: !!metadata.organizationId,
    },
    filters: handleFiltersItemType(),
    meta: {
      ...metadata,
    },
  });

  const { selectProps: uomSelectProps } = useSelect({
    resource: 'unit-of-measure',
    optionLabel: 'name',
    pagination: {
      pageSize: 999,
    },
    filters: handleFiltersItemType(),
    queryOptions: {
      enabled: !!metadata.organizationId,
    },
    meta: {
      ...metadata,
      fieldTranslate: 'name',
    },
  });
  const { selectProps: cropProps } = useSelect({
    resource: 'crop',
    optionLabel: 'name',
    optionValue:
      itemType === ItemTypeEnum.FINISHED_GOODS ? 'name' : 'externalId',
    onSearch: (value) => [
      {
        field: 'name',
        operator: 'eq',
        value: value,
      },
    ],
    queryOptions: {
      enabled: !!metadata.organizationId,
    },
    meta: {
      ...metadata,
      fieldTranslate: 'name',
    },
  });

  return {
    categorySelectProps,
    uomSelectProps,
    cropProps,
  };
};

export { useItemForm };
