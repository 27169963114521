import { createSearchParams, useSearchParams } from 'react-router-dom';

const useLinkWithOrgAndFarm = () => {
  const [searchParams] = useSearchParams();
  const organizationId = searchParams.get('organizationId') ?? '';
  const farmId = searchParams.get('farmId') ?? '';

  const goToLink = (
    pathname: string,
    target = '_self',
    org?: string | null,
    farm?: string | null,
    otherParams?: Record<string, string>,
  ) => {
    const urlParams = {
      organizationId: '',
      farmId: '',
      ...otherParams,
    };

    if (org !== null) {
      urlParams.organizationId = org ?? organizationId;
    }

    if (farm !== null) {
      urlParams.farmId = farm ?? farmId;
    }

    window.open(
      `${window.location.origin}${pathname}?${createSearchParams(
        urlParams,
      ).toString()}`,
      target,
    );
  };

  return {
    goToLink,
  };
};

export { useLinkWithOrgAndFarm };
