import { Form } from 'antd';

import { ItemCategory } from '../../item-category.model';
import { ItemCategoryFormFarmContainer } from './farm/item-category-form-farm.container';
import useItemCategoryFormFarms from './item-category-form-farms.hook';
import * as Styled from './item-category-form-farms.style';

interface ItemCategoryFormItemsProps {
  newItemCategory?: ItemCategory | null;
  show: boolean;
}

const ItemCategoryFormFarms = ({
  newItemCategory,
  show,
}: ItemCategoryFormItemsProps) => {
  const {
    warehouseSelectGroupByFarm,
    warehouseListLoading,
    expenseAccountSelectGroupByFarm,
    expenseAccountListLoading,
    costCenterSelectGroupByFarm,
    costCenterListLoading,
  } = useItemCategoryFormFarms();

  return (
    <Styled.Container show={show}>
      <Form.List name="defaultSettings">
        {(farms) => (
          <>
            {farms.map((farm, index) => (
              <ItemCategoryFormFarmContainer
                key={farm.key}
                defaultSetting={farm}
                newDefaultSetting={
                  newItemCategory?.defaultSettings[index] ?? null
                }
                warehouseSelectOptions={
                  warehouseSelectGroupByFarm[
                    newItemCategory?.defaultSettings[index]?.farm ?? ''
                  ]
                }
                expenseAccountSelectOptions={
                  expenseAccountSelectGroupByFarm[
                    newItemCategory?.defaultSettings[index]?.farm ?? ''
                  ]
                }
                costCenterSelectOptions={
                  costCenterSelectGroupByFarm[
                    newItemCategory?.defaultSettings[index]?.farm ?? ''
                  ]
                }
                warehouseListLoading={warehouseListLoading}
                expenseAccountListLoading={expenseAccountListLoading}
                costCenterListLoading={costCenterListLoading}
              />
            ))}
          </>
        )}
      </Form.List>
    </Styled.Container>
  );
};

export { ItemCategoryFormFarms as default, ItemCategoryFormFarms };
