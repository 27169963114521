import { QueryObserverResult } from '@tanstack/react-query';
import {
  Breadcrumb,
  Button,
  DrawerProps,
  Popconfirm,
  PopconfirmProps,
  Skeleton,
} from 'antd';
import { ButtonProps } from 'antd/lib/button';
import { useRedirect } from 'common/hooks';
import { CloseIcon } from 'common/icons';
import { useTracking } from 'common/utils';
import {
  KeyboardEvent,
  MouseEvent,
  ReactNode,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';

import { DrawerWithButtonsTestId } from './drawer-with-buttons.constants';
import * as Styled from './drawer-with-buttons.style';

export interface DrawerWithButtonsProps extends DrawerProps {
  buttonSaveProps?: ButtonProps & { enableReturnRouter?: boolean };
  disabledSave?: boolean;
  textSave?: string;
  popConfirmSave?: PopconfirmProps;
  isLoading?: boolean;
  isLoadingChildren?: boolean;
  tagTrackClose?: string;
  onBack?: () => void;
  textOnBack?: string;
  moreButtons?: ReactNode[];
  hideSave?: boolean;
  backButton?: ReactNode;
  dataResult?: QueryObserverResult;
  hasDataResult?: boolean;
  breadcrumbList?: string[];
}

export const DrawerWithButtons = ({
  onClose,
  buttonSaveProps,
  disabledSave,
  textSave,
  popConfirmSave,
  isLoadingChildren,
  children,
  isLoading,
  tagTrackClose,
  moreButtons,
  onBack,
  backButton,
  hasDataResult,
  dataResult,
  hideSave = false,
  ...props
}: DrawerWithButtonsProps) => {
  const { track } = useTracking();
  const { t } = useTranslation();
  const [popConfirmOpen, setPopConfirmOpen] = useState(false);
  const { returnRouter } = useRedirect();

  const handleOnClose = (e: MouseEvent | KeyboardEvent) => {
    returnRouter();
    if (tagTrackClose) {
      track(`Close Drawer ${tagTrackClose}`);
    }
    if (onClose) {
      setPopConfirmOpen(false);
      onClose(e);
    }
  };

  const handleOnClickSave = () => {
    if (buttonSaveProps?.enableReturnRouter && !hasDataResult) {
      returnRouter();
    }
    if (buttonSaveProps?.onClick) {
      const onClick = buttonSaveProps?.onClick as () => void;
      onClick();
    }
  };

  useEffect(() => {
    if (hasDataResult && dataResult) {
      returnRouter();
    }
  }, [hasDataResult, dataResult]);

  const saveButton = useMemo(() => {
    return (
      <Styled.SaveButton
        {...buttonSaveProps}
        disabled={disabledSave || buttonSaveProps?.disabled}
        type="primary"
        data-testid={DrawerWithButtonsTestId.DRAWER_WITH_BUTTONS_SAVE}
        loading={isLoading || buttonSaveProps?.loading}
        onClick={
          popConfirmSave ? () => setPopConfirmOpen(true) : handleOnClickSave
        }
      >
        {textSave ?? t('drawer.save')}
      </Styled.SaveButton>
    );
  }, [buttonSaveProps, disabledSave, isLoading, popConfirmSave, textSave, t]);

  return (
    <Styled.Drawer
      onClose={handleOnClose}
      zIndex={1001}
      closeIcon={<CloseIcon />}
      push={false}
      {...props}
      title={
        <>
          {props?.breadcrumbList?.length && (
            <Breadcrumb
              items={[
                ...(props.breadcrumbList?.map((breadcrumb) => ({
                  title: breadcrumb,
                })) ?? []),
                {
                  title: '', // empty to have the last breadcrumb grey
                },
              ]}
            />
          )}
          {props.title}
        </>
      }
    >
      <Styled.ContainerChildren>
        {isLoadingChildren ? <Skeleton active /> : children}
      </Styled.ContainerChildren>
      <Styled.ContainerButtons>
        <div>{moreButtons?.map((button) => button)}</div>
        <Styled.ContainerSaveButton>
          {onBack ? (
            <Button onClick={onBack}>{t('buttons.previousStep')}</Button>
          ) : (
            backButton || (
              <Button
                onClick={handleOnClose}
                data-testid={DrawerWithButtonsTestId.DRAWER_WITH_BUTTONS_CANCEL}
              >
                {t('drawer.back')}
              </Button>
            )
          )}
          {popConfirmSave && !hideSave ? (
            <Popconfirm
              {...popConfirmSave}
              open={popConfirmOpen}
              onCancel={() => {
                setPopConfirmOpen(false);
                popConfirmSave.onCancel && popConfirmSave.onCancel();
              }}
              onConfirm={handleOnClickSave}
              disabled={disabledSave}
            >
              {saveButton}
            </Popconfirm>
          ) : (
            !hideSave && saveButton
          )}
        </Styled.ContainerSaveButton>
      </Styled.ContainerButtons>
    </Styled.Drawer>
  );
};
