import { useDrawerForm } from '@refinedev/antd';
import { HttpError } from '@refinedev/core';
import { useTracking } from 'common/utils';

import { BankAccountRequestModel } from './bank-account.model';

const useBankAccountCreateDrawer = (syncWithLocationKey?: string) => {
  const { track } = useTracking();

  const supplierCreateDrawer = useDrawerForm<
    BankAccountRequestModel,
    HttpError,
    BankAccountRequestModel
  >({
    action: 'create',
    resource: 'account/custom',
    redirect: false,
    syncWithLocation: syncWithLocationKey
      ? { key: syncWithLocationKey }
      : false,
  });

  const handleOpenBankAccountDrawer = () => {
    track('Start Bank Account Creation');
    supplierCreateDrawer.show();
  };

  const handleCloseBankAccountDrawer = () => {
    track('End Bank Account Creation');
    supplierCreateDrawer.close();
  };

  return {
    createBankAccountDrawer: {
      ...supplierCreateDrawer,
      close: handleCloseBankAccountDrawer,
    },
    handleOpenBankAccountDrawer,
  };
};

export { useBankAccountCreateDrawer };
