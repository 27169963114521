import {
  BlueColors,
  OrangeColors,
  PinkColors,
  PurpleColors,
  TealColors,
  YellowColors,
} from 'theme';

const CHART_COLORS = [
  BlueColors[60],
  PurpleColors[70],
  TealColors[30],
  OrangeColors[40],
  PurpleColors[70],
  PurpleColors[50],
  PinkColors[30],
  BlueColors[70],
  YellowColors[50],
  TealColors[50],
  YellowColors[30],
];

export { CHART_COLORS };
