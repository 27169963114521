import { BaseRecord, CreateResponse, useCreate } from '@refinedev/core';
import { FormProps } from 'antd';
import { useWatch } from 'antd/es/form/Form';
import { useTracking } from 'common/utils';
import dayjs from 'dayjs';
import { FormInstance } from 'rc-field-form';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useFarm, useOrganization } from 'src/organization';
import { ItemDetailPurchaseModel } from 'src/resources/item';

import { useInvalidateTableData } from '../use-invalidate-table-data.hook';
import {
  ItemPurchaseReceiveModel,
  PurchaseReceiveModel,
  UpdateItemRateModel,
} from './purchase-receive.model';

export const usePurchaseReceive = (
  formProps: FormProps,
  formInstance: FormInstance,
  onClose: () => void,
) => {
  const { track } = useTracking();
  const { currentFarmId } = useFarm();
  const { currentOrganizationId } = useOrganization();
  const [isDisabledSave, setIsDisabledSave] = useState(false);
  const { mutate, isLoading } = useCreate();
  const { getCurrencyByFarm } = useFarm();

  const { refetchTableAndInvalidate } = useInvalidateTableData();

  const formValues = useWatch([], formInstance);
  const items = formValues?.items ?? [];
  const purchaseOrderId = formProps?.initialValues?.id;

  const initialValues: PurchaseReceiveModel = useMemo(() => {
    return {
      postingDate: dayjs(),
      postingTime: dayjs(),
      supplier: formProps?.initialValues?.supplier,
      farm: formProps?.initialValues?.farmName,
      date: dayjs(),
      currency: getCurrencyByFarm(formProps?.initialValues?.farmName) ?? '',
      items: formProps?.initialValues?.items.map(
        (item: ItemDetailPurchaseModel) => ({
          id: item.id,
          itemCode: item.itemCode,
          name: item.itemName,
          receivedQty: item.qty - item.receivedQty,
          totalQty: item.qty,
          warehouse: item.warehouse,
          costCenter: item.costCenter,
          purchaseOrder: formProps?.initialValues?.id,
          purchaseOrderItem: item.name,
          stockUom: item.stockUom,
          rate: item.rate,
          outstandingQty: item.qty - item.receivedQty,
          lockedQty: item.lockedQty,
          lastRate: item.rate,
          isHidden: item.qty - item.receivedQty === 0,
          idx: item.idx,
        }),
      ),
    };
  }, [formProps?.initialValues]);

  const handleMutateReceive = useCallback(
    (values: PurchaseReceiveModel) => {
      mutate(
        {
          resource: 'purchase-receipt',
          meta: {
            organizationId: currentOrganizationId,
            farmId: currentFarmId,
          },
          values: {
            ...values,
            items: values.items
              .filter(
                (item: ItemPurchaseReceiveModel) =>
                  item.outstandingQty > 0 && !item.isHidden,
              )
              .map((item: ItemPurchaseReceiveModel) => ({
                ...item,
              })),
            postingDate: dayjs(values.postingDate).format('YYYY-MM-DD'),
            postingTime: dayjs(values.postingTime).format('HH:mm:ss'),
          },
        },
        {
          onSuccess: () => {
            refetchTableAndInvalidate();
            onClose();
          },
        },
      );
    },
    [
      currentFarmId,
      currentOrganizationId,
      mutate,
      onClose,
      refetchTableAndInvalidate,
    ],
  );

  const getUpdatedItem = (
    items: ItemPurchaseReceiveModel[],
    responseItem: ItemDetailPurchaseModel,
  ) => {
    return items.find(
      (updatedItem: ItemPurchaseReceiveModel) =>
        (updatedItem.idx === responseItem.idx &&
          updatedItem.itemCode === responseItem.itemCode) ||
        (updatedItem.receivedQty > 0 &&
          updatedItem.itemCode === responseItem.itemCode),
    );
  };

  const handleUpdateItemsOnSuccess = useCallback(
    (response: CreateResponse<BaseRecord>, values: PurchaseReceiveModel) => {
      if (response?.data) {
        const updatedItems = response.data.items.map(
          (responseItem: ItemDetailPurchaseModel) => {
            const updatedItem = getUpdatedItem(
              items as ItemPurchaseReceiveModel[],
              responseItem,
            );

            return {
              receivedQty: updatedItem?.receivedQty ?? responseItem.receivedQty,
              itemCode: responseItem.itemCode,
              warehouse: responseItem.warehouse,
              costCenter: responseItem.costCenter,
              rate: responseItem.rate,
              purchaseOrderItem: responseItem.name,
              purchaseOrder: response.data.id,
              outstandingQty: responseItem.qty - responseItem.receivedQty,
              isHidden: updatedItem?.isHidden ?? false,
            };
          },
        );

        handleMutateReceive({
          ...values,
          items: updatedItems,
        });
      }
    },
    [items, handleMutateReceive],
  );

  const handleEditUnitValue = useCallback(
    (values: PurchaseReceiveModel) => {
      mutate(
        {
          resource: `purchase-order/${purchaseOrderId}/update-items`,
          values: [
            ...values.items.reduce(
              (acc: UpdateItemRateModel[], item: ItemPurchaseReceiveModel) => {
                if (
                  item.lockedQty === item.totalQty ||
                  item.lockedQty === 0 ||
                  item.rate === item.lastRate
                ) {
                  acc.push({
                    item_code: item.itemCode,
                    qty: item.totalQty,
                    rate: item.rate,
                    warehouse: item.warehouse,
                    idx: item.idx,
                  });
                } else if (
                  item.lockedQty > 0 &&
                  item.lockedQty < item.totalQty
                ) {
                  acc.push({
                    item_code: item.itemCode,
                    qty: item.lockedQty,
                    rate: item.lastRate,
                    warehouse: item.warehouse,
                    idx: item.idx,
                  });
                  acc.push({
                    item_code: item.itemCode,
                    qty: item.totalQty - item.lockedQty,
                    rate: item.rate,
                    warehouse: item.warehouse,
                  });
                }

                return acc;
              },
              [],
            ),
          ],
        },
        {
          onSuccess: (response) => {
            handleUpdateItemsOnSuccess(response, values);
          },
        },
      );
    },
    [items, mutate, purchaseOrderId],
  );

  const hasEditedUnitValue = useMemo(() => {
    return items.some(
      (item: ItemPurchaseReceiveModel) => item.rate !== item.lastRate,
    );
  }, [items]);

  const handleOnFinish = useCallback(
    (values: PurchaseReceiveModel) => {
      track('End Purchase Receipt');
      if (hasEditedUnitValue) {
        handleEditUnitValue(values);
      } else {
        handleMutateReceive(values);
      }
    },
    [handleMutateReceive, handleEditUnitValue],
  );

  const showUnitValueAlert = useMemo(() => {
    if (formProps?.initialValues?.percentagePaid === 100) return false;
    const currentAmount = formValues?.items?.reduce(
      (acc: number, item: ItemDetailPurchaseModel) =>
        acc + item.receivedQty * item.rate,
      0,
    );
    return (
      currentAmount < formProps?.initialValues?.paidAmount && hasEditedUnitValue
    );
  }, [formValues, formProps?.initialValues, hasEditedUnitValue]);

  useEffect(() => {
    if (showUnitValueAlert) {
      setIsDisabledSave(true);
    } else {
      formInstance
        ?.validateFields({ validateOnly: true })
        .then(() => {
          setIsDisabledSave(false);
        })
        .catch((error) => {
          if (!error.outOfDate) {
            setIsDisabledSave(true);
          }
        });
    }
  }, [formValues, formInstance, showUnitValueAlert]);

  const handleUndoUnitValueEdit = useCallback(() => {
    formInstance?.setFieldsValue({
      items: formValues?.items.map((item: ItemPurchaseReceiveModel) => ({
        ...item,
        rate: item.lastRate,
      })),
    });
  }, [formInstance, formValues?.items]);

  useEffect(() => {
    formInstance
      ?.validateFields({ validateOnly: true })
      .then(() => {
        setIsDisabledSave(false);
      })
      .catch((error) => {
        if (!error.outOfDate) {
          setIsDisabledSave(true);
        }
      });
  }, [formValues, formInstance, setIsDisabledSave]);

  return {
    initialValues,
    isDisabledSave,
    isLoading,
    handleOnFinish,
    showUnitValueAlert,
    handleUndoUnitValueEdit,
    hasEditedUnitValue,
  };
};
