import { CalendarDatum, ResponsiveCalendar } from '@nivo/calendar';
import { Skeleton } from 'antd';
import { ArrowRightIcon, CalendarIcon } from 'common/icons';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { BlueColors, NeutralColors } from 'theme';

import * as StyledDashboard from '../../dashboard.style';
import { useConsumptionCalendar } from './consumption-calendar.hook';
import * as Styled from './consumption-calendar.style';
import { FreeRegistrationModal } from './free-registration-modal/free-registration-modal.component';

interface ConsumptionCalendarProps {
  farm?: string;
}

const ConsumptionCalendar = ({ farm }: ConsumptionCalendarProps) => {
  const { t } = useTranslation();
  const {
    toDate,
    fromDate,
    requestNoCostData,
    requestCalendarData,
    isFetching,
    handleGoToConsumption,
    amountYears,
  } = useConsumptionCalendar(farm);

  return (
    <Styled.BorderedCalendarContainer data-testid="consumption-calendar">
      <div>
        <StyledDashboard.BoxTitle onClick={handleGoToConsumption}>
          <CalendarIcon />
          <span>{t('dashboard.material_consumption')}</span>
          <ArrowRightIcon />
        </StyledDashboard.BoxTitle>
      </div>

      {isFetching ? (
        <Skeleton active />
      ) : (
        <>
          <Styled.FlexContainer>
            <Styled.WidgetContainer amountYears={amountYears}>
              <ResponsiveCalendar
                data={
                  (requestCalendarData?.data?.result as CalendarDatum[]) || []
                }
                from={fromDate}
                to={toDate}
                emptyColor={`${NeutralColors[20]}`}
                colors={[
                  `${BlueColors[20]}`,
                  `${BlueColors[30]}`,
                  `${BlueColors[50]}`,
                  `${BlueColors[70]}`,
                ]}
                minValue={0}
                maxValue={5}
                monthBorderColor={`${NeutralColors[0]}`}
                monthSpacing={20}
                dayBorderWidth={2}
                dayBorderColor={`${NeutralColors[0]}`}
                yearSpacing={40}
                margin={{ left: -580, top: 20 }}
                tooltip={({ day, value }) => (
                  <Styled.TooltipDay>
                    <strong>{dayjs(day).format('DD/MM/YYYY')}</strong>
                    <br />
                    {value
                      ? `${value} ${t('dashboard.material_consumption')}`
                      : t('dashboard.no_consumption')}
                  </Styled.TooltipDay>
                )}
              />
            </Styled.WidgetContainer>
          </Styled.FlexContainer>
          <Styled.LegendContainer>
            <FreeRegistrationModal
              farm={farm}
              amountFreeRegistrations={requestNoCostData?.data?.result[0].value}
            />
            <Styled.LegendText>
              {t('dashboard.calendar_legend')}
            </Styled.LegendText>
          </Styled.LegendContainer>
        </>
      )}
    </Styled.BorderedCalendarContainer>
  );
};
export { ConsumptionCalendar };
