import { useDrawerForm } from '@refinedev/antd';
import { BaseKey, BaseRecord, HttpError } from '@refinedev/core';
import { Space, Table } from 'antd';
import { useWatch } from 'antd/es/form/Form';
import { EmptyState, FormattedDate } from 'common/components';
import {
  AddIcon,
  CropIcon,
  DateIcon,
  EmptyProductionColoredIcon,
  EyeOutlinedIcon,
  FarmIcon,
  UpdateIcon,
} from 'common/icons';
import { EmptyFilterImage } from 'common/images';
import {
  ListFiltersModel,
  RefineTableFilters,
  RefineTableFiltersEnum,
  useRefineTableFilters,
} from 'common/refine-table-filters';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { CropZoneColumn } from 'src/material-consumption/crop-zone-column/crop-zone-column.component';
import {
  MaterialConsumptionModel,
  MaterialConsumptionRequestModel,
} from 'src/material-consumption/material-consumption.model';
import { MaterialConsumptionEnum } from 'src/material-consumption/status.enum';
import { StatusColumn } from 'src/material-consumption/status-column/status-column.component';
import { useFarm } from 'src/organization';

import { FinancialEnum } from '../financial.enum';
import { FinancialTitle } from '../financial-title.component';
import ReferenceValueButton from '../reference-value/button/reference-value-button.component';
import ReferenceValueForm from '../reference-value/form/reference-value-form.component';
import { useReferenceValue } from '../reference-value/reference-value.hook';
import { useReferenceValueStore } from '../reference-value/reference-value.store';
import ReferenceValueToggle from '../reference-value/toggle/reference-value-toggle.component';
import * as Styled from './financial-production.style';
import { InsertProduction } from './insert-production/insert-production.component';

const FinancialProductionContainer = () => {
  const { t } = useTranslation();
  const { financialFms1168AddReferenceValue } = useFlags();
  const { finalProduct } = useReferenceValueStore();
  const { currentFarm } = useFarm();

  const listFilters: ListFiltersModel[] = useMemo(
    () => [
      {
        label: t('material_consumption.table.farm'),
        field: 'farm',
        type: RefineTableFiltersEnum.SELECT,
        initialValue: currentFarm?.name,
        resource: 'farm',
        show: true,
        icon: <FarmIcon />,
      },
      {
        label: t('common.business.season'),
        optionLabel: 'seasonName',
        optionValue: 'seasonId',
        field: 'seasonId',
        type: RefineTableFiltersEnum.SELECT,
        resource: 'season',
        parentsField: ['farm'],
        show: true,
        icon: <DateIcon />,
      },
      {
        label: t('common.business.field'),
        field: 'project',
        type: RefineTableFiltersEnum.SELECT,
        resource: 'project',
        icon: <CropIcon />,
        optionLabel: 'projectName',
        parentsField: ['farm', 'seasonId'],
        show: true,
      },
      {
        label: t('material_consumption.table.status'),
        field: 'status',
        type: RefineTableFiltersEnum.MULTIPLE_SELECT,
        show: false,
        icon: <UpdateIcon />,
        options: [
          {
            label: t('material_consumption.status.cancelled'),
            value: MaterialConsumptionEnum.CANCELLED,
          },
          {
            label: t('material_consumption.status.confirmed'),
            value: MaterialConsumptionEnum.CONFIRMED,
          },
          {
            label: t('material_consumption.status.draft'),
            value: MaterialConsumptionEnum.DRAFT,
          },
        ],
      },
    ],
    [t],
  );

  const { tableProps, searchFormProps } =
    useRefineTableFilters<MaterialConsumptionModel>({
      listFilters,
      resource: 'stock-entry',
      storeName: 'financial',
      filters: {
        permanent: [
          {
            field: 'stockEntryType',
            operator: 'eq',
            value: 'Manufacture',
          },
        ],
      },
    });

  const drawerForm = useDrawerForm<
    BaseRecord,
    HttpError,
    MaterialConsumptionRequestModel
  >({
    action: 'create',
    resource: 'stock-entry',
    redirect: false,
    syncWithLocation: {
      key: 'create',
    },
  });

  const editDrawer = useDrawerForm<
    BaseRecord,
    HttpError,
    MaterialConsumptionRequestModel
  >({
    action: 'edit',
    resource: 'stock-entry',
    redirect: false,
    syncWithLocation: {
      key: 'edit',
    },
  });

  const handleOpenInsertProduction = () => {
    drawerForm?.show();
  };

  const handleOnClickEditMaterialConsumption = (id: BaseKey) => {
    editDrawer.show(id);
  };

  const farmsFilter = useWatch('farm', searchFormProps.form);
  const seasonFilter = useWatch('seasonId', searchFormProps.form);
  const projectFilter = useWatch('project', searchFormProps.form);

  const {
    handleOnReferenceValueClick,
    referenceValueDrawerProps,
    finalProductRefetch,
  } = useReferenceValue({
    farm: farmsFilter,
    seasonId: seasonFilter,
  });

  return (
    <>
      <ReferenceValueForm
        drawerProps={referenceValueDrawerProps.drawerProps}
        formProps={referenceValueDrawerProps.formProps}
        saveButtonProps={referenceValueDrawerProps.saveButtonProps}
        formLoading={referenceValueDrawerProps.formLoading}
        close={referenceValueDrawerProps.close}
        finalProductRefetch={finalProductRefetch}
        farm={farmsFilter}
        season={seasonFilter}
      />
      <InsertProduction
        drawerForm={drawerForm}
        farm={farmsFilter}
        seasonId={seasonFilter}
        project={projectFilter}
      />
      <InsertProduction drawerForm={editDrawer} />
      <FinancialTitle
        selectedTab={FinancialEnum.PRODUCTION}
        buttons={[
          <>
            <Styled.ButtonInsertProduction
              key="button_insert"
              icon={<AddIcon />}
              onClick={handleOpenInsertProduction}
              size="large"
            >
              {t('financial_production.empty_production.button')}
            </Styled.ButtonInsertProduction>
            {financialFms1168AddReferenceValue && (
              <>
                <ReferenceValueButton
                  onClick={handleOnReferenceValueClick}
                  seasonId={seasonFilter}
                  farm={farmsFilter}
                  finalProduct={finalProduct}
                />
                <ReferenceValueToggle />
              </>
            )}
          </>,
        ]}
      />
      <RefineTableFilters
        searchFormProps={searchFormProps}
        initialListFilters={listFilters}
      />
      {!farmsFilter || !seasonFilter ? (
        <EmptyState
          image={<EmptyFilterImage />}
          title={t('report_general_ledger.empty_state.title')}
          description={`${t('report_general_ledger.empty_state.description')}`}
        />
      ) : (
        <Table
          {...tableProps}
          locale={{
            emptyText: (
              <EmptyState
                title={t('financial_production.empty_production.title')}
                image={<EmptyProductionColoredIcon />}
                marginTop="0"
                description={
                  t('financial_production.empty_production.description') || ''
                }
              >
                <Styled.ButtonInsertPrimary
                  icon={<AddIcon />}
                  onClick={handleOpenInsertProduction}
                  type="primary"
                >
                  {t('financial_production.empty_production.button')}
                </Styled.ButtonInsertPrimary>
              </EmptyState>
            ),
          }}
        >
          <Table.Column
            title={t('common.date')}
            dataIndex="postingDate"
            render={(postingDate) => <FormattedDate date={postingDate} />}
          />
          <Table.Column title={t('common.business.farm')} dataIndex="farm" />
          <Table.Column
            title={t('common.business.crop_zone')}
            dataIndex="cropZone"
            render={(cropZone) => <CropZoneColumn value={cropZone} />}
          />
          <Table.Column
            title={t('common.status')}
            dataIndex="docstatus"
            render={(docstatus: number) => (
              <StatusColumn statusId={docstatus} />
            )}
          />
          <Table.Column
            title={t('material_consumption.table.actions')}
            render={(_, { id }: MaterialConsumptionModel) => (
              <Space>
                <Styled.ButtonView
                  icon={<EyeOutlinedIcon />}
                  onClick={() => handleOnClickEditMaterialConsumption(id)}
                />
              </Space>
            )}
          />
        </Table>
      )}
    </>
  );
};

export { FinancialProductionContainer };
