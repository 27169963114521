import { Typography } from 'antd';
import { ROUTES } from 'common/constants';
import { ChevronRightIcon } from 'common/icons';
import { NoPermissionImage } from 'common/images';
import { useNavigateWithOrgAndFarm } from 'common/utils';
import { useTranslation } from 'react-i18next';

import * as Styled from './no-permission.style';

const NoPermission = () => {
  const { t } = useTranslation();
  const navigate = useNavigateWithOrgAndFarm();

  const handleGoToOrganization = () => {
    navigate(ROUTES.FARM);
  };

  return (
    <Styled.Container>
      <NoPermissionImage />
      <Styled.Title level={3}>{t('no_permission.title')}</Styled.Title>
      <Typography.Paragraph>
        {t('no_permission.description')}
      </Typography.Paragraph>
      <Styled.ButtonCropwise onClick={handleGoToOrganization}>
        {t('no_permission.button_text')}
        <ChevronRightIcon />
      </Styled.ButtonCropwise>
    </Styled.Container>
  );
};

export { NoPermission };
