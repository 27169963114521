import { QueryClient } from '@tanstack/react-query';

const MINUTE = 1000 * 60;

const QUERY_CLIENT_CONFIG = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
      cacheTime: 10 * MINUTE,
      staleTime: 1 * MINUTE,
    },
    mutations: {
      retry: false,
    },
  },
});

export { QUERY_CLIENT_CONFIG as default, QUERY_CLIENT_CONFIG };
