import { generateExternalIdMask } from '../electronic-invoice.util';
import * as Styled from './electronic-invoice-number-column.style';

interface ElectronicInvoiceNumberColumnProps {
  externalId: string;
}

const ElectronicInvoiceNumberColumn = ({
  externalId,
}: ElectronicInvoiceNumberColumnProps) => {
  return (
    <Styled.Container>{generateExternalIdMask(externalId)}</Styled.Container>
  );
};

export { ElectronicInvoiceNumberColumn };
