import { useSelect } from '@refinedev/antd';
import { useWatch } from 'antd/es/form/Form';
import useFormInstance from 'antd/es/form/hooks/useFormInstance';
import { FarmModel } from 'common/models';
import { useEffect, useMemo } from 'react';
import { SeasonModel } from 'src/financial/financial.model';
import { useOrganization } from 'src/organization';

const useInsertProductionDetail = () => {
  const { currentOrganizationId } = useOrganization();
  const form = useFormInstance();
  const farm = useWatch(['farm']);
  const seasonId = useWatch(['seasonId']);

  const metadata = useMemo(
    () => ({
      organizationId: currentOrganizationId,
    }),
    [currentOrganizationId],
  );

  const { selectProps: farmSelectProps } = useSelect<FarmModel>({
    resource: 'farm',
    onSearch: (value) => [
      {
        field: 'name',
        operator: 'eq',
        value: value,
      },
    ],
    meta: metadata,
  });

  const { selectProps: seasonSelectProps, queryResult: seasonQueryResult } =
    useSelect<SeasonModel>({
      optionLabel: 'seasonName',
      optionValue: 'seasonId',
      resource: 'season',
      filters: [
        {
          field: 'farm',
          operator: 'eq',
          value: farm,
        },
      ],
      onSearch: (value) => [
        {
          field: 'name',
          operator: 'eq',
          value: value,
        },
      ],
      meta: metadata,
      queryOptions: {
        enabled: !!farm,
      },
    });

  const { selectProps: projectSelectProps } = useSelect({
    optionLabel: 'projectName',
    resource: 'project',
    filters: [
      {
        field: 'seasonId',
        operator: 'eq',
        value: seasonId,
      },
      {
        field: 'farm',
        operator: 'eq',
        value: farm,
      },
    ],
    onSearch: (value) => [
      {
        field: 'name',
        operator: 'eq',
        value: value,
      },
    ],
    queryOptions: {
      enabled: !!seasonId,
    },
  });

  useEffect(() => {
    if (seasonId) {
      const selectedSeason = seasonQueryResult.data?.data.find(
        (season) => season.seasonId === seasonId,
      );

      if (selectedSeason) {
        form.setFieldValue('crop', selectedSeason.crop);
      }
    }
  }, [seasonId, seasonQueryResult]);

  return {
    farmSelectProps,
    projectSelectProps,
    seasonSelectProps,
  };
};

export { useInsertProductionDetail };
