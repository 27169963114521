import { Skeleton } from 'antd';
import { ArrowRightIcon, ItemsIcon, StockIcon } from 'common/icons';
import { useTranslation } from 'react-i18next';
import { NeutralColors, RedColors } from 'theme';

import * as Styled from '../../../dashboard.style';
import { useWarehouseStockBox } from './warehouse-stock-box.hook';

interface WarehouseStockBoxProps {
  farm?: string;
}

const WarehouseStockBox = ({ farm }: WarehouseStockBoxProps) => {
  const { t } = useTranslation();
  const { handleGoToWarehouse, value, isFetching } = useWarehouseStockBox(farm);

  return (
    <Styled.BorderedContainer data-testid="warehouse-stock-box">
      <Styled.BoxTitle onClick={handleGoToWarehouse}>
        <StockIcon />
        <span>{t('dashboard.stock')}</span>
        <ArrowRightIcon />
      </Styled.BoxTitle>
      {isFetching ? (
        <Skeleton active />
      ) : (
        <Styled.VerticalBox>
          <Styled.Tag
            backgroundColor={value ? RedColors[20] : NeutralColors[10]}
            textColor={value ? RedColors[70] : NeutralColors[60]}
          >
            {t('dashboard.negative_stock')}
          </Styled.Tag>
          <Styled.Box>
            <Styled.StyleTagOutlined>
              <ItemsIcon />
            </Styled.StyleTagOutlined>
            <Styled.BigText textColor={!value ? NeutralColors[60] : undefined}>
              {value
                ? t('dashboard.amount_items', {
                    amount: value,
                  })
                : t('dashboard.all_positive')}
            </Styled.BigText>
          </Styled.Box>
        </Styled.VerticalBox>
      )}
    </Styled.BorderedContainer>
  );
};

export { WarehouseStockBox };
