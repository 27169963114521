import { ResponsiveBar } from '@nivo/bar';
import { Button } from 'antd';
import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { NeutralColors } from 'theme';

import ChartCustomTooltipRef from '../chart-custom-tooltip-ref/chart-custom-tooltip-ref.component';
import ChartHorizontalGroupedBar from '../chart-horizontal-grouped-bar/chart-horizontal-grouped-bar.component';
import { CHART_COLORS } from '../charts.constant';
import { ChartBarDataModel } from '../charts.model';
import { useChartHorizontalBar } from './chart-horizontal-bar.hook';
import * as Styled from './chart-horizontal-bar.style';

const DEFAULT_AMOUNT = 30;

interface ChartHorizontalBarProps {
  data: ChartBarDataModel[];
  currencySymbol?: string;
  customUom?: string;
}

const ChartHorizontalBar = ({
  currencySymbol,
  data,
  customUom,
}: ChartHorizontalBarProps) => {
  const { t } = useTranslation();
  const groupedTooltipRef = useRef(null);

  const {
    valueFormat,
    showAll,
    setShowAll,
    dataFiltered,
    dataBar,
    keysFiltered,
    detailedDataPerLabel,
    colorPerLabel,
    markersPerLabel,
    legendDataPerGroup,
    maxValuePerGroup,
    scaleWidth,
    setScaleWidth,
  } = useChartHorizontalBar({
    data,
    currencySymbol,
    customUom,
  });

  return (
    <>
      <Styled.Container>
        <Styled.HorizontalBarWrapper amountItems={dataFiltered.length}>
          <ResponsiveBar
            data={dataBar.slice().reverse()}
            enableGridX
            colors={CHART_COLORS}
            layout="horizontal"
            keys={keysFiltered}
            indexBy="id"
            padding={0.6}
            innerPadding={10}
            enableLabel={false}
            borderWidth={1}
            borderColor={NeutralColors[0]}
            margin={{ left: 7 }}
            axisLeft={null}
            gridYValues={0}
            valueFormat={valueFormat}
            maxValue={Math.max(...Object.values(maxValuePerGroup)) * 1.1}
            theme={{
              grid: {
                line: {
                  stroke: NeutralColors[10],
                },
              },
            }}
            barComponent={(props) => {
              return (
                <ChartHorizontalGroupedBar
                  barProps={props}
                  detailedData={detailedDataPerLabel}
                  colorPerLabel={colorPerLabel}
                  markersPerLabel={markersPerLabel}
                  tooltipRef={groupedTooltipRef}
                  valueFormat={valueFormat}
                  keysFiltered={keysFiltered}
                  legendDataPerGroup={legendDataPerGroup}
                  scaleWidth={scaleWidth}
                  setScaleWidth={setScaleWidth}
                />
              );
            }}
            groupMode="grouped"
          />
        </Styled.HorizontalBarWrapper>
      </Styled.Container>
      {!showAll && data.length > DEFAULT_AMOUNT && (
        <Styled.ContainerButton>
          <Button type="primary" onClick={() => setShowAll(true)}>
            {t('common.see_all')}
          </Button>
        </Styled.ContainerButton>
      )}
      <ChartCustomTooltipRef ref={groupedTooltipRef} />
    </>
  );
};

export { ChartHorizontalBar };
