import { ShoppingCartSuccessColoredIcon } from 'common/icons';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { PurchaseDetailModel } from 'src/orders-and-payments/purchase';

import * as Styled from './electronic-invoice-purchase-order.style';

interface ElectronicInvoicePurchaseOrderProps {
  purchaseOrder?: PurchaseDetailModel;
}

const ElectronicInvoicePurchaseOrder = ({
  purchaseOrder,
}: ElectronicInvoicePurchaseOrderProps) => {
  const { t } = useTranslation();

  if (!purchaseOrder) {
    return null;
  }

  return (
    <Styled.ContainerQuestion>
      <ShoppingCartSuccessColoredIcon />
      <Styled.BoxSuccess>
        <Styled.Text>
          {t('electronic_invoice.purchase_order.relation_purchase')}
          <span>
            {` ${dayjs(purchaseOrder.transactionDate).format('DD/MM/YYYY')} ${t(
              'common.of',
            )} ${purchaseOrder.supplier}`}
          </span>
        </Styled.Text>
      </Styled.BoxSuccess>
    </Styled.ContainerQuestion>
  );
};

export { ElectronicInvoicePurchaseOrder };
