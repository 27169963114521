import {
  AUTH_CLIENT_DATA_CONFIG,
  PLATFORM_DATA_CONFIG,
} from 'common/constants';
import { UserDataModel } from 'common/models';

import { api } from '../api.config';
import { AuthGrantTypeEnum } from './auth.constant';

async function getAccessTokenService(accessCode: string) {
  const authData = {
    grant_type: AuthGrantTypeEnum.AuthorizationCode,
    client_id: PLATFORM_DATA_CONFIG.CLIENT_ID,
    redirect_uri: AUTH_CLIENT_DATA_CONFIG.REDIRECT_URI,
    code: accessCode,
  };
  const response = await api.post('/oauth/token', authData);
  return response.data;
}

async function getNewAccessTokenService(refreshToken: string) {
  const authData = {
    grant_type: AuthGrantTypeEnum.RefreshToken,
    client_id: PLATFORM_DATA_CONFIG.CLIENT_ID,
    redirect_uri: AUTH_CLIENT_DATA_CONFIG.REDIRECT_URI,
    refresh_token: refreshToken,
  };

  const response = await api.post('/oauth/token', authData);

  return response.data;
}

async function getUser(): Promise<UserDataModel> {
  const response = await api.get('/v2/accounts/me');

  return response.data;
}

export { getAccessTokenService, getNewAccessTokenService, getUser };
