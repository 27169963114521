import { Table } from 'antd';
import { CurrencyValue } from 'common/components';
import { FarmIcon, PurchaseIcon, SupplierIcon, UpdateIcon } from 'common/icons';
import {
  ListFiltersModel,
  RefineTableFilters,
  RefineTableFiltersEnum,
  useRefineTableFilters,
} from 'common/refine-table-filters';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useFarm } from 'src/organization';

import {
  InvoiceFormContainer,
  InvoicePaymentForm,
  usePurchaseInvoiceDrawer,
} from '../invoice';
import { InvoiceFormModel } from '../invoice/invoice.model';
import { InvoicePaymentFormModel } from '../invoice/payment-form/invoice-payment-form.model';
import { OrdersAndPaymentsEnum } from '../orders-and-payments.enum';
import { OrdersAndPaymentsTitle } from '../orders-and-payments-title.component';
import {
  PurchaseCreateContainer,
  PurchasePayment,
  usePurchaseDrawer,
} from '../purchase';
import { PurchaseRequestModel } from '../purchase/purchase.model';
import { PurchasePaymentRequestModel } from '../purchase/purchase-payment/purchase-payment.model';
import {
  AccountsPayableEnum,
  AccountsPayableStatusEnum,
} from './accounts-payable.enum';
import { AccountsPayableModel } from './accounts-payable.model';
import { AccountsPayableActions } from './accounts-payable-actions/accounts-payable-actions.component';
import { AccountsPayableCreateButton } from './accounts-payable-create-button/accounts-payable-create-button.component';
import { AccountsPayableDueDate } from './accounts-payable-due-date/accounts-payable-due-date.component';
import { AccountsPayableStatus } from './accounts-payable-status/accounts-payable-status.component';

const AccountsPayableContainer = () => {
  const { t } = useTranslation();
  const { currentFarm } = useFarm();

  const { purchaseInvoiceDrawer: paymentInvoiceDrawer } =
    usePurchaseInvoiceDrawer<InvoicePaymentFormModel>('edit', 'payment');
  const { purchaseInvoiceDrawer: viewInvoiceDrawer } =
    usePurchaseInvoiceDrawer<InvoiceFormModel>('edit');
  const { purchaseDrawer: paymentPurchaseDrawer } =
    usePurchaseDrawer<PurchasePaymentRequestModel>('edit');
  const { purchaseDrawer: viewDrawer } =
    usePurchaseDrawer<PurchaseRequestModel>('edit');

  const listFilters: ListFiltersModel[] = useMemo(
    () => [
      {
        label: t('common.business.farm'),
        field: 'farm',
        type: RefineTableFiltersEnum.SELECT,
        resource: 'farm',
        show: true,
        icon: <FarmIcon />,
        initialValue: currentFarm?.name,
      },
      {
        label: t('common.business.supplier'),
        field: 'supplier',
        type: RefineTableFiltersEnum.SELECT,
        resource: 'supplier',
        show: true,
        icon: <SupplierIcon />,
        optionLabel: 'projectName',
      },
      {
        label: t('common.type'),
        field: 'type',
        type: RefineTableFiltersEnum.SELECT,
        show: true,
        icon: <PurchaseIcon />,
        options: [
          {
            label: t('purchase.title'),
            value: AccountsPayableEnum.PURCHASE_ORDER,
          },
          {
            label: t('invoice.title'),
            value: AccountsPayableEnum.PURCHASE_INVOICE,
          },
        ],
      },
      {
        label: t('material_consumption.table.status'),
        field: 'status',
        type: RefineTableFiltersEnum.SELECT,
        show: false,
        icon: <UpdateIcon />,
        options: Object.values(AccountsPayableStatusEnum).map((value) => ({
          label: t(`accounts_payable.status.${value}`),
          value,
        })),
      },
    ],
    [t],
  );

  const { tableProps, searchFormProps, tableQueryResult } =
    useRefineTableFilters<AccountsPayableModel>({
      listFilters,
      resource: 'report/accounts-payable',
    });

  return (
    <>
      <InvoiceFormContainer drawerForm={viewInvoiceDrawer} />
      <PurchasePayment drawerForm={paymentPurchaseDrawer} />
      <PurchaseCreateContainer drawerForm={viewDrawer} isReadonly />
      <InvoicePaymentForm
        drawerForm={paymentInvoiceDrawer}
        refetchTable={tableQueryResult.refetch}
      />
      <OrdersAndPaymentsTitle
        selectedTab={OrdersAndPaymentsEnum.ACCOUNTS_PAYABLE}
        buttons={[
          <AccountsPayableCreateButton key="accounts-payable-create-button" />,
        ]}
      />
      <RefineTableFilters
        searchFormProps={searchFormProps}
        initialListFilters={listFilters}
      />
      <Table
        {...tableProps}
        pagination={{
          ...tableProps.pagination,
          showSizeChanger: true,
          pageSizeOptions: [10, 20, 50, 100],
        }}
      >
        <Table.Column
          dataIndex="dueDate"
          title={t('accounts_payable.table.due_date')}
          render={(_, accountsPayable: AccountsPayableModel) => (
            <AccountsPayableDueDate accountsPayable={accountsPayable} />
          )}
        />
        <Table.Column dataIndex="farm" title={t('common.business.farm')} />
        <Table.Column
          dataIndex="supplier"
          title={t('common.business.supplier')}
        />
        <Table.Column
          dataIndex="status"
          title={t('common.status')}
          render={(_, { status }: AccountsPayableModel) => (
            <AccountsPayableStatus status={status} />
          )}
        />
        <Table.Column
          dataIndex="paidAmount"
          title={t('accounts_payable.table.paid')}
          render={(_, { paidAmount }: AccountsPayableModel) => (
            <CurrencyValue value={paidAmount} />
          )}
        />
        <Table.Column
          dataIndex="totalAmount"
          title={t('accounts_payable.table.total')}
          render={(_, { totalAmount }: AccountsPayableModel) => (
            <CurrencyValue value={totalAmount} />
          )}
        />
        <Table.Column
          title={t('common.actions')}
          render={(_, accountsPayable: AccountsPayableModel) => (
            <AccountsPayableActions
              accountsPayable={accountsPayable}
              paymentInvoiceDrawer={paymentInvoiceDrawer}
              paymentPurchaseDrawer={paymentPurchaseDrawer}
              viewDrawer={viewDrawer}
              viewInvoiceDrawer={viewInvoiceDrawer}
            />
          )}
        />
      </Table>
    </>
  );
};

export { AccountsPayableContainer };
