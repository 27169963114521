import { Button as ButtonAntd } from 'antd';
import styled from 'styled-components';
import { NeutralColors } from 'theme';

interface ButtonProps {
  selected: boolean;
}

const Button = styled(ButtonAntd)<ButtonProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ selected }) =>
    selected ? `${NeutralColors[0]}` : `${NeutralColors[20]}`};

  &:hover {
    background-color: ${({ selected }) =>
      selected ? `${NeutralColors[10]}` : `${NeutralColors[20]}`};
  }

  svg {
    display: inline-block;
    width: 20px;
    height: 20px;
  }
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  height: 40px;
  padding: 4px;
  background-color: ${NeutralColors[20]};
  border-radius: 12px;
`;

export { Button, Wrapper };
