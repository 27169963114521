import { useCreate, useOne } from '@refinedev/core';
import { MenuProps } from 'antd';
import { ROUTES } from 'common/constants';
import { useNavigateWithOrgAndFarm } from 'common/utils';
import { downloadFile } from 'common/utils/download.util';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import api from 'src/api.config';
import { useOrganization } from 'src/organization';

import { ExportFields } from '../data-import.constant';
import { DocTypeEnum, FileTypeEnum } from '../data-import.enum';
import {
  DataImportDetailsModel,
  DataImportPreviewModel,
} from '../data-import.model';

export const useDataImportStart = () => {
  const { i18n, t } = useTranslation();
  const navigate = useNavigateWithOrgAndFarm();
  const [dataImportDetails, setDataImportDetails] = useState<
    DataImportDetailsModel | undefined
  >();
  const [isDownload, setIsDownload] = useState<boolean>(false);
  const [nameFile, setNameFile] = useState<string>('');
  const [fileType, setFileType] = useState<FileTypeEnum>();
  const [docType, setDocType] = useState<DocTypeEnum>();
  const { currentOrganizationId } = useOrganization();
  const userLanguage = i18n.language.toLocaleLowerCase();
  const [isLoading, setIsLoading] = useState(false);

  const itemsDropdown: MenuProps['items'] = [
    {
      label: 'CSV',
      key: FileTypeEnum.CSV,
    },
    {
      label: 'Excel',
      key: FileTypeEnum.XLSX,
    },
  ];

  const metadata = useMemo(
    () => ({
      organizationId: currentOrganizationId,
    }),
    [currentOrganizationId],
  );

  const handleOnChangeSelect = (value: DocTypeEnum) => {
    setDocType(value);
  };

  const handleDownloadMenuClick: MenuProps['onClick'] = (e) => {
    const item = itemsDropdown.find((dropdown) => dropdown?.key === e.key);
    if (item) {
      setFileType(item.key as FileTypeEnum);

      if (dataCsv) {
        remove();
        refetch();
      }
      setIsDownload(true);
    }
  };

  const { data: dataPreview, isFetching: isFetchingPreview } =
    useOne<DataImportPreviewModel>({
      resource: 'data-import/preview',
      id: dataImportDetails?.name,
      meta: {
        params: {
          ...metadata,
        },
      },
      queryOptions: {
        enabled: !!dataImportDetails,
      },
    });

  const dataImportPreview = useMemo(
    () => dataImportDetails && dataPreview?.data,
    [dataPreview, dataImportDetails],
  );

  const {
    refetch,
    data: dataCsv,
    remove,
    isFetching,
  } = useOne({
    resource: 'export',
    id: isDownload && docType ? docType : undefined,
    meta: {
      params: {
        exportRecords: '5_records',
        fileType,
        language: userLanguage,
        exportFields: docType ? ExportFields[docType] : [],
        ...metadata,
      },
      responseType: 'arraybuffer',
    },
    queryOptions: {
      enabled: isDownload && !!docType,
    },
  });

  const {
    data: dataStartImport,
    isLoading: isFetchingStartImport,
    mutate,
  } = useCreate();

  useEffect(() => {
    if (dataStartImport && dataImportDetails) {
      navigate(
        `${ROUTES.DATA_IMPORT_DETAILS_ROOT}/${encodeURIComponent(
          dataImportDetails.name,
        )}`,
      );
    }
  }, [dataStartImport]);

  const sendFile = async (e: any) => {
    setNameFile(e.file.name);
    setIsLoading(true);
    const formData = new FormData();
    formData.append('file', e.file);
    formData.append('referenceDoctype', docType ?? '');

    const response = await api
      .post<DataImportDetailsModel>('/v1/data-import/file', formData, {
        params: { ...metadata },
      })
      .finally(() => setIsLoading(false));

    setDataImportDetails(response.data);
  };

  useEffect(() => {
    if (dataCsv) {
      downloadFile(
        dataCsv.data as Blob,
        `${t(`data_import.doc_type.${docType}`)}.${
          fileType === FileTypeEnum.CSV ? 'csv' : 'xlsx'
        }`,
      );
    }
    setIsDownload(false);
  }, [dataCsv]);

  const amountErrors = useMemo(
    () =>
      !dataPreview?.data ||
      dataPreview.data.data
        .flatMap((line) => line.columns.flatMap((column) => !!column.warning))
        .filter((error) => !!error).length,
    [dataPreview],
  );

  const clearInput = () => {
    setDataImportDetails(undefined);
    setNameFile('');
  };

  const startImport = () => {
    mutate({
      values: {},
      resource: `data-import/start-import/${dataImportDetails?.name}`,
      meta: {
        params: {
          ...metadata,
        },
      },
    });
  };

  return {
    amountErrors,
    dataImportDetails,
    dataImportPreview,
    docType,
    fileType,
    isFetching,
    isFetchingStartImport,
    isLoading: isLoading || isFetchingPreview,
    itemsDropdown,
    nameFile,
    clearInput,
    handleOnChangeSelect,
    handleDownloadMenuClick,
    sendFile,
    startImport,
  };
};
