import { InputNumber } from 'antd';
import { AlertWithButton } from 'common/components';
import styled from 'styled-components';
import { NeutralColors } from 'theme';

const Box = styled.div`
  display: flex;
  margin: 24px 0px;
  width: 100%;
  flex-direction: column;
  padding: 16px;
  border-radius: 8px;
  border: 1px solid ${NeutralColors[20]};
`;

const ContainerFlex = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 16px;
`;

const AdjustedOn = styled.p`
  display: flex;
  align-items: center;

  svg {
    margin-right: 8px;
  }
`;

const Alert = styled(AlertWithButton)`
  margin-bottom: 24px;
`;

const InputQty = styled(InputNumber)`
  width: 100%;
`;

export { AdjustedOn, Alert, Box, ContainerFlex, InputQty };
