const createOrganization = (callback_uri: string, workspace_id?: string) => {
  return `${
    import.meta.env.VITE_FARM_SETTINGS_URL ?? ''
  }/app/deep-actions/organization/?workspace_id=${workspace_id}&callback_uri=${callback_uri}`;
};

const createFarm = (callback_uri: string, organization_id?: string) => {
  return `${
    import.meta.env.VITE_FARM_SETTINGS_URL ?? ''
  }/app/deep-actions/create-property?org_id=${organization_id}&callback_uri=${callback_uri}`;
};

export { createFarm, createOrganization };
