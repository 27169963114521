import styled from 'styled-components';
import { NeutralColors } from 'theme';

const Tooltip = styled.div`
  background-color: ${NeutralColors[100]};
  color: ${NeutralColors[0]};
  min-width: 32px;
  min-height: 32px;
  padding: 6px 8px;
  word-wrap: break-word;
  border-radius: 6px;
  box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.08),
    0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 9px 28px 8px rgba(0, 0, 0, 0.05);

  &::before {
    position: absolute;
    z-index: 1;
    display: block;
    pointer-events: none;
    overflow: hidden;
    transform: translate(-50%, 99%) rotate(180deg);
    bottom: 0;
    left: 50%;
    width: 16px;
    height: 8px;
    background: ${NeutralColors[100]};
    clip-path: polygon(
      1.6568542494923806px 100%,
      50% 1.6568542494923806px,
      14.34314575050762px 100%,
      1.6568542494923806px 100%
    );
    clip-path: path(
      'M 0 8 A 4 4 0 0 0 2.82842712474619 6.82842712474619 L 6.585786437626905 3.0710678118654755 A 2 2 0 0 1 9.414213562373096 3.0710678118654755 L 13.17157287525381 6.82842712474619 A 4 4 0 0 0 16 8 Z'
    );
    content: '';
  }
`;

const Legends = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px 20px;
  grid-auto-flow: row;
`;

const DonutWrapper = styled.div`
  width: 100%;
  aspect-ratio: 1/1;
  margin-bottom: 20px;
`;

export { DonutWrapper, Legends, Tooltip };
