import { Typography } from 'antd';
import styled from 'styled-components';
import { NeutralColors } from 'theme';

const Title = styled(Typography.Title)`
  span {
    font-weight: 300;
    color: ${NeutralColors[70]};
  }
`;

export { Title };
