import { useOne } from '@refinedev/core';
import { FormListFieldData, InputNumber, Select, Skeleton } from 'antd';
import { DeleteIcon } from 'common/icons';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useOrganization } from 'src/organization';
import { ItemModel } from 'src/resources/item';

import useStockTransferItems from './stock-transfer-item.hook';
import * as Styled from './stock-transfer-item.style';

interface StockTransferItemContainerProps {
  item: FormListFieldData;
  removeItem: () => void;
  amountItems: number;
  index: number;
}

const StockTransferItemContainer = ({
  item,
  removeItem,
  amountItems,
  index,
}: StockTransferItemContainerProps) => {
  const { currentOrganizationId } = useOrganization();
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(true);

  const { stockItemSelectProps, stockItems, isLoading, itemCode, itemQty } =
    useStockTransferItems(item);

  const { data, isFetching } = useOne<ItemModel>({
    resource: 'item',
    id: itemCode,
    meta: {
      organizationId: currentOrganizationId,
    },
  });

  const itemData = data?.data;

  const stockItem = useMemo(() => {
    return stockItems.find((stockItem) => stockItem.itemCode === itemCode);
  }, [stockItems, itemCode]);

  useEffect(() => {
    setIsOpen(amountItems <= index + 1);
  }, [amountItems, index]);

  if (!itemData) {
    return (
      <Styled.Box data-testid="stock-transfer-item-wrapper">
        <Styled.FormItem
          label={t('stock_transfer.items.name_item')}
          name={[item.name, 'itemCode']}
          data-testid="stock-transfer-item-code"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select
            {...stockItemSelectProps}
            loading={isLoading}
            placeholder={t('common.business.item_placeholder')}
          />
        </Styled.FormItem>
        {isFetching && <Skeleton active />}
      </Styled.Box>
    );
  }

  return (
    <Styled.Box data-testid="stock-transfer-items-wrapper">
      <Styled.ContainerTitle isOpen={isOpen} onClick={() => setIsOpen(!isOpen)}>
        <Styled.Flex>
          <Styled.Chevron isOpen={isOpen} />
          <strong>{itemData.name}</strong>
        </Styled.Flex>
        <Styled.ContainerCenter>
          <strong>{`${itemQty ?? 0} ${itemData.uom}`}</strong>
          <Styled.TrashButton onClick={removeItem}>
            <DeleteIcon />
          </Styled.TrashButton>
        </Styled.ContainerCenter>
      </Styled.ContainerTitle>
      {isOpen && (
        <Styled.FormItem
          label={t('stock_transfer.items.amount')}
          name={[item.name, 'qty']}
          data-testid="stock-transfer-qty"
          rules={[
            {
              required: true,
            },
            () => ({
              validator(_, value) {
                if (isNaN(value)) {
                  return Promise.reject(t('stock_transfer.items.value_nan'));
                }
                if (value <= 0) {
                  return Promise.reject(t('stock_transfer.items.value_zero'));
                }
                if (stockItem?.actualQty && value > stockItem?.actualQty) {
                  return Promise.reject(
                    t('stock_transfer.items.value_bigger_than_stock'),
                  );
                }

                return Promise.resolve();
              },
            }),
          ]}
          extra={t('stock_transfer.items.in_stock', {
            amount: stockItem?.actualQty,
            uom: itemData.uom,
          })}
        >
          <InputNumber addonAfter={itemData.uom} decimalSeparator={','} />
        </Styled.FormItem>
      )}
    </Styled.Box>
  );
};

export { StockTransferItemContainer as default, StockTransferItemContainer };
