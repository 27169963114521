import { LDFlagSet } from 'launchdarkly-react-client-sdk';

import { FeatureItemKeys, featureListData } from './feature-list.data';
import { ItemDescription } from './item-description.component';
import { FeatureItemModel, MenuItemModel } from './menu-item.model';

const getMenuItem = (
  t: (key: string) => string,
  callbackClickFunction: (route?: string) => void,
  featureKey: FeatureItemKeys,
  isMobile: boolean,
  featureFlags: LDFlagSet,
  permissions: string[],
  entitlements: string[],
): MenuItemModel => {
  const feature = featureListData[featureKey];

  const children = feature.children?.length
    ? feature.children
        ?.filter((value) =>
          filterFeatureItems(
            value,
            featureFlags,
            isMobile,
            permissions,
            entitlements,
          ),
        )
        .map((child) => ({
          label: renderLabel(t(child.title), t(child.description), isMobile),
          key: child.key,
          icon: child.icon,
          onClick: () => {
            callbackClickFunction(child.navigate_route);
          },
        }))
    : undefined;
  return {
    label: renderLabel(t(feature.title), t(feature.description), isMobile),
    key: feature.key,
    icon: feature.icon,
    onClick: () => {
      callbackClickFunction(feature.navigate_route);
    },
    children:
      !isMobile && !!feature.children?.length
        ? [
            {
              key: feature.key,
              label: t(feature.title),
              type: 'group',
              children,
            },
          ]
        : children,
  };
};

const filterFeatureItems = (
  value: FeatureItemModel,
  featureFlags: LDFlagSet,
  isMobile: boolean,
  permissions: string[],
  entitlements: string[],
) =>
  !featureFlags[value.feature_flag_disable ?? ''] &&
  !(isMobile && !!value.hide_on_mobile) &&
  !(!isMobile && !!value.hide_on_desktop) &&
  (!value.permission || permissions?.includes(value.permission ?? '')) &&
  (!value.entitlement || entitlements?.includes(value.entitlement ?? ''));

const renderLabel = (label: string, description: string, isMobile: boolean) =>
  isMobile ? <ItemDescription name={label} description={description} /> : label;

export { filterFeatureItems, getMenuItem };
