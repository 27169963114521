import { Dropdown, Typography } from 'antd';
import { AddIcon, EditIcon } from 'common/icons';
import { CurrencyCode } from 'common/utils';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import ChartTitle from '../../../common/charts/chart-title/chart-title.component';
import CustomValue from './custom-value/info-card-custom-value.component';
import * as Styled from './info-card.style';

interface InfoCardProps {
  icon: ReactNode;
  name: string;
  value: number;
  disableKpi?: boolean;
  showHelp?: boolean;
  helpDescription?: string;
  onHelpClick?: () => void;
  currency?: CurrencyCode;
  customUom?: string;
  toggleCustomUom?: boolean;
  helpDropdownOptions?: {
    title: string;
    onClick: () => void;
  }[];
  objectivePercentage?: number;
  objectivePercentageDescription?: string;
  objectiveValue?: number;
  objectiveOnEdit?: () => void;
  objectiveEmptyDescription?: string;
}

const InfoCard = ({
  icon,
  name,
  value,
  showHelp,
  helpDescription,
  onHelpClick,
  currency,
  toggleCustomUom,
  customUom,
  helpDropdownOptions,
  objectivePercentage,
  objectivePercentageDescription,
  objectiveValue,
  objectiveOnEdit,
  objectiveEmptyDescription,
  disableKpi = false,
}: InfoCardProps) => {
  const { t } = useTranslation();

  if (showHelp) {
    return (
      <Styled.EmptyWrapper bordered={false} showHelp>
        <Styled.TextWrapper>
          <ChartTitle icon={icon} text={name} />
          <Typography.Text>
            {helpDescription}{' '}
            {onHelpClick && (
              <Typography.Link onClick={onHelpClick}>
                {t('common.more_info')}
              </Typography.Link>
            )}
          </Typography.Text>
        </Styled.TextWrapper>
        {helpDropdownOptions && (
          <Dropdown
            menu={{
              items: helpDropdownOptions?.map((option) => ({
                key: option.title,
                onClick: option.onClick,
                label: option.title,
              })),
            }}
            placement="bottomRight"
          >
            <Styled.Button shape="circle" icon={<AddIcon />} />
          </Dropdown>
        )}
      </Styled.EmptyWrapper>
    );
  }

  return (
    <Styled.Wrapper bordered={false}>
      <Styled.TextWrapper>
        <ChartTitle icon={icon} text={name} />
        <CustomValue
          bold
          value={value}
          currency={currency}
          customUom={customUom}
          toggleCustomUom={toggleCustomUom}
        />
      </Styled.TextWrapper>
      {!disableKpi && (
        <>
          <Styled.Divider type="vertical" />
          <Styled.ObjectiveWrapper>
            {objectivePercentage && objectiveValue ? (
              <Styled.TextWrapper>
                <Typography.Text type="secondary">
                  {t(`${objectivePercentageDescription}`, {
                    percentage: (objectivePercentage * 100).toFixed(2),
                  })}
                </Typography.Text>
                <CustomValue
                  value={objectiveValue}
                  currency={currency}
                  customUom={customUom}
                  toggleCustomUom={toggleCustomUom}
                />
              </Styled.TextWrapper>
            ) : (
              <Typography.Text type="secondary">
                {objectiveEmptyDescription ?? t('financial.no_objective')}
              </Typography.Text>
            )}
            {objectiveOnEdit && (
              <Styled.Button
                type="text"
                icon={objectivePercentage ? <EditIcon /> : <AddIcon />}
                onClick={objectiveOnEdit}
              />
            )}
          </Styled.ObjectiveWrapper>
        </>
      )}
    </Styled.Wrapper>
  );
};

export { InfoCard as default, InfoCard, type InfoCardProps };
