import { Form } from 'antd';
import { AddIcon } from 'common/icons';
import { useTranslation } from 'react-i18next';

import { useMaterialConsumptionFormStore } from '../material-consumption-form.store';
import { MaterialConsumptionFormItem } from './material-consumption-form-item/material-consumption-form-item.component';
import * as Styled from './material-consumption-form-items.style';

interface MaterialConsumptionFormItemsProps {
  handleOpenItemDrawer: () => void;
}

const MaterialConsumptionFormItems = ({
  handleOpenItemDrawer,
}: MaterialConsumptionFormItemsProps) => {
  const { t } = useTranslation();

  const { showItems } = useMaterialConsumptionFormStore();

  return (
    <Styled.Container isShow={showItems}>
      <Form.List
        name="items"
        rules={[
          {
            validator: (_, names) => {
              if (!names || names.length < 1) {
                return Promise.reject(
                  t(
                    'material_consumption_form.items.error_message_empty_items',
                  ),
                );
              }
              return Promise.resolve();
            },
          },
        ]}
      >
        {(items, { add, remove }) => (
          <>
            {items.map((item, index) => (
              <MaterialConsumptionFormItem
                key={item.key}
                removeItem={() => remove(index)}
                materialConsumptionRequest={item}
                index={index}
                handleOpenItemDrawer={handleOpenItemDrawer}
              />
            ))}
            <Styled.ContainerButton>
              <Styled.FormItem>
                <Styled.ButtonAddItem
                  type="dashed"
                  onClick={() => {
                    add();
                  }}
                  block
                  icon={<AddIcon />}
                >
                  {t('buttons.add_new_item')}
                </Styled.ButtonAddItem>
              </Styled.FormItem>
            </Styled.ContainerButton>
          </>
        )}
      </Form.List>
    </Styled.Container>
  );
};

export { MaterialConsumptionFormItems };
