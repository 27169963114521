import { Alert, Button } from 'antd';
import styled from 'styled-components';
import { BlueColors, NeutralColors, RedColors } from 'theme';

const ContainerFlex = styled.div`
  display: flex;
  gap: 16px;
  margin-bottom: 16px;
`;

const CardIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${NeutralColors[20]};
  padding: 4px;
  width: 32px;
  height: 32px;
  border-radius: 4px;
  margin: 16px auto;
`;

const DownloadButton = styled(Button)`
  display: flex;
  align-items: center;
`;

const BoxErrors = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  background-color: ${RedColors[50]};
  width: 24px;
  height: 24px;
  font-weight: bold;
  border-radius: 24px;
`;

const ButtonClear = styled(Button)`
  display: flex;
  align-items: center;
  border: 1px solid ${RedColors[50]};
  color: ${RedColors[50]};

  svg {
    width: 20px;
  }
`;

const ContainerInput = styled(ContainerFlex)`
  max-width: 400px;
  margin-bottom: 36px;
  align-items: center;
`;

const AlertMessage = styled(Alert)`
  background-color: ${BlueColors[10]};
  border-color: ${BlueColors[30]};
  margin-bottom: 16px;

  div {
    div:first-child {
      font-weight: bold;
    }
  }
`;

export {
  AlertMessage,
  BoxErrors,
  ButtonClear,
  CardIcon,
  ContainerFlex,
  ContainerInput,
  DownloadButton,
};
