import { Button as ButtonAntd } from 'antd';
import styled from 'styled-components';
import { NeutralColors } from 'theme';

interface ButtonProps {
  isSelected?: boolean;
}

const Button = styled(ButtonAntd)<ButtonProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 24px 0px;
  height: auto;
  width: calc(50% - 8px);
  -webkit-box-shadow: 0px 3px 14px -4px rgba(0, 0, 0, 0.32);
  -moz-box-shadow: 0px 3px 14px -4px rgba(0, 0, 0, 0.32);
  box-shadow: 0px 3px 14px -4px rgba(0, 0, 0, 0.32);
  border: none;

  svg {
    fill: ${(props) =>
      props.isSelected ? NeutralColors[0] : NeutralColors[70]};
  }

  span {
    color: ${(props) =>
      props.isSelected ? NeutralColors[0] : NeutralColors[70]};
  }
`;

export { Button };
