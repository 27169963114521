import { track } from '@amplitude/analytics-browser';
import { BaseKey, useList, useUpdate } from '@refinedev/core';
import { FormInstance } from 'antd/lib/form/Form';
import { useEffect, useMemo, useState } from 'react';
import { ResultItem } from 'src/financial/financial-overview/financial-overview.model';
import { useOrganization } from 'src/organization';

import { ObjectiveModel, ProjectModel } from './objective-form.model';
import { useObjectiveFormStore } from './objective-form.store';

interface ObjectiveFormProps {
  isOpen: boolean;
  close: () => void;
  form?: FormInstance;
  farm?: string;
  season?: string;
  resultItemList?: ResultItem[];
  openKey?: BaseKey;
  refetchChart: () => void;
}

const useObjectiveForm = ({
  close,
  isOpen,
  form,
  farm,
  season,
  resultItemList,
  openKey,
  refetchChart,
}: ObjectiveFormProps) => {
  const [activeKey, setActiveKey] = useState('cost');
  const { mutate } = useUpdate();
  const { setProjectByName, setCurrentValuesByName } = useObjectiveFormStore();
  const { currentOrganizationId } = useOrganization();
  const metadata = useMemo(
    () => ({
      organizationId: currentOrganizationId,
    }),
    [currentOrganizationId],
  );

  useEffect(() => {
    setActiveKey(openKey as string);
  }, [openKey]);

  const { data: projectData, refetch: refetchProject } = useList<ProjectModel>({
    resource: 'project',
    filters: [
      {
        field: 'farm',
        operator: 'eq',
        value: farm,
      },
      {
        field: 'seasonId',
        operator: 'eq',
        value: season,
      },
    ],
    pagination: {
      pageSize: 10000,
    },
    meta: metadata,
    queryOptions: {
      enabled: !!farm && !!season,
    },
  });

  useEffect(() => {
    setProjectByName(projectData?.data ?? []);
    setCurrentValuesByName(resultItemList ?? []);
    form?.setFieldsValue({
      items:
        projectData?.data?.map((project) => ({
          projectId: project.id,
          projectName: project.projectName,
          cost: project.estimatedCosting,
          productivity: project.estimatedProduction,
          showProductivity: true,
          showCost: true,
        })) ?? [],
    });
  }, [
    isOpen,
    projectData,
    setProjectByName,
    setCurrentValuesByName,
    resultItemList,
    form,
  ]);

  const handleOnClose = () => {
    form?.resetFields();
    close();
  };

  const handleOnFinish = (values: ObjectiveModel) => {
    mutate(
      {
        resource: 'project',
        id: 'estimated-values',
        values: [
          ...values.items.map((item) => ({
            projectId: item.projectId,
            estimatedCosting: item.cost,
            estimatedProduction: item.productivity,
          })),
        ],
      },
      {
        onSuccess: () => {
          handleOnClose();
          refetchChart();
          refetchProject();
          track('Objective - Update estimated value');
        },
      },
    );
  };

  return {
    activeKey,
    setActiveKey,
    handleOnClose,
    handleOnFinish,
  };
};

export { useObjectiveForm };
