import { HttpError } from '@refinedev/core';
import { captureException } from '@sentry/react';
import axios from 'axios';
import {
  AUTH_CLIENT_DATA_CONFIG,
  PLATFORM_DATA_CONFIG,
} from 'common/constants';
import {
  clearLocalData,
  getLocalData,
  StorageKeyOptionsEnum,
} from 'common/utils';
import { t } from 'i18next';

enum ContentTypeEnum {
  FormUrlEncoded = 'application/x-www-form-urlencoded',
}

enum ErrorCodeEnum {
  BadRequest = 400,
  Unauthorized = 401,
  Forbidden = 403,
  NotFound = 404,
  Conflict = 409,
  SessionExpired = 440,
  InternalServerError = 500,
}

const apiUrlOptions = {
  fms: import.meta.env.VITE_FMS_API_URL ?? '',
  base: import.meta.env.VITE_BASE_API_URL ?? '',
};

const baseApiConfig = {
  baseUrl: apiUrlOptions.base,
  headers: {
    Authorization: `Basic ${AUTH_CLIENT_DATA_CONFIG.CLIENT_AUTHORIZATION_TOKEN}`,
  },
};

const api = axios.create({
  baseURL: apiUrlOptions.fms,
  headers: {
    'Content-Type': ContentTypeEnum.FormUrlEncoded,
  },
});

// Add a request interceptor
api.interceptors.request.use(
  function (config) {
    const isAuth = config.url?.includes('/oauth');
    const isAccount = config.url?.includes('/v2/accounts');
    const isOrganization = config.url?.includes('/v2/orgs');

    if (isAuth) {
      config.headers.Authorization = baseApiConfig.headers.Authorization;
      config.baseURL = baseApiConfig.baseUrl;
    } else {
      const accessData = getLocalData(StorageKeyOptionsEnum.AccessData) ?? '';
      const tokenType = accessData?.token_type ?? '';

      if (isAccount || isOrganization) {
        config.baseURL = baseApiConfig.baseUrl;
      }

      if (tokenType && accessData) {
        const capitalizedTokenType =
          tokenType.charAt(0).toUpperCase() + tokenType.slice(1);

        config.headers.Authorization = `${capitalizedTokenType} ${accessData.access_token}`;
      }
    }

    return config;
  },
  function (error) {
    captureException(error, {
      extra: {
        file: 'api.config.ts',
        method: 'api (axios) interceptors request',
      },
    });

    return Promise.reject(error);
  },
);

// Add a response interceptor
api.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
  },
  function (error) {
    if (error?.response?.status === ErrorCodeEnum.Forbidden) {
      clearLocalData(StorageKeyOptionsEnum.AccessData);

      window.location.href = `${PLATFORM_DATA_CONFIG.ACCOUNTS_URI}/exit?continue=${AUTH_CLIENT_DATA_CONFIG.REDIRECT_URI}`;
    }

    captureException(error, {
      extra: {
        file: 'api.config.ts',
        method: 'api (axios) interceptors response',
      },
    });

    let message;

    switch (error?.response?.status) {
      case ErrorCodeEnum.NotFound:
        message = t('error.notFound');
        break;
      case ErrorCodeEnum.InternalServerError:
        message = t('error.internalServerError');
        break;
      default:
        break;
    }

    const refineError: HttpError = {
      ...error,
      statusCode: error?.response?.status,
      message: message ?? error?.response?.data?.message,
    };

    return Promise.reject(refineError);
  },
);

export { api, apiUrlOptions, api as default };
