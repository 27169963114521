import { Dropdown, Menu } from 'antd';
import { APP_VERSION } from 'common/constants';
import { AddIcon } from 'common/icons';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { FC, Key, MouseEvent, ReactNode, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useQuickActionList } from '../quick-action-list.hooks';
import { MenuItemModel } from './menu-item/menu-item.model';
import { MenuTrigger } from './menu-trigger/menu-trigger.component';
import { useSiderNavbar } from './sider-navbar.hook';
import * as Styled from './sider-navbar.style';

function getItem(
  label: ReactNode,
  key: Key,
  icon?: ReactNode,
  children?: MenuItemModel[],
  onClick?: (e: MouseEvent<HTMLElement>) => void,
): MenuItemModel {
  return {
    key,
    icon,
    label,
    children,
    onClick,
  } as MenuItemModel;
}

const SiderNavbarComponent: FC = () => {
  const { quickActions } = useQuickActionList();
  const { handleFeatureItems, current, entitlements } = useSiderNavbar({
    isMobile: false,
  });
  const [collapsed, setCollapsed] = useState<boolean>(true);
  const { t } = useTranslation();
  const featureFlags = useFlags();

  const dropdownOffset: [number, number] = collapsed ? [60, 40] : [270, 40];
  const quickActionsClassName: string = collapsed ? '' : 'full-width';

  return (
    <Styled.Sider
      theme="light"
      collapsible
      collapsed={collapsed}
      onCollapse={(value) => setCollapsed(value)}
      collapsedWidth={64}
      width={280}
      trigger={<MenuTrigger collapsed={collapsed} />}
    >
      <Dropdown
        menu={{
          items: quickActions
            .filter(
              (value) =>
                !featureFlags[value.feature_flag_disable ?? ''] &&
                (value.entitlement === undefined ||
                  entitlements.includes(value.entitlement)),
            )
            .map((action, index) =>
              getItem(
                action.description,
                index,
                action.icon,
                undefined,
                action.onClick,
              ),
            ),
        }}
        align={{
          offset: dropdownOffset,
          useCssBottom: true,
        }}
        placement="topRight"
        trigger={['click']}
      >
        <Styled.QuickActionsButton
          type="default"
          shape={'default'}
          size="large"
          icon={<AddIcon />}
          className={quickActionsClassName}
        >
          {!collapsed && t('sider.quick_actions')}
        </Styled.QuickActionsButton>
      </Dropdown>
      <Menu
        selectedKeys={[current as string]}
        mode="inline"
        items={handleFeatureItems()}
      />
      {APP_VERSION && !collapsed && (
        <Styled.Version>v{APP_VERSION}</Styled.Version>
      )}
    </Styled.Sider>
  );
};

export { SiderNavbarComponent as default, SiderNavbarComponent };
