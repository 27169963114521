import { ROUTES } from 'common/constants';
import {
  FinancialProductionIcon,
  InvoiceIcon,
  ItemsIcon,
  MaterialConsumptionIcon,
  PurchaseIcon,
} from 'common/icons';
import { useNavigateWithOrgAndFarm, useTracking } from 'common/utils';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import EntitlementEnum from 'src/organization/entitlements.enum';

export const useQuickActionList = (onClose?: () => void) => {
  const { t } = useTranslation();
  const navigate = useNavigateWithOrgAndFarm();
  const { track } = useTracking();

  const handleOnClick = useCallback(
    (
      feature: string,
      otherParams: Record<string, string>,
      tagTrack?: string,
    ) => {
      if (tagTrack) {
        track(`Quick action`, { 'Action Complement': tagTrack });
      }
      if (onClose) {
        onClose();
      }
      navigate(feature, undefined, undefined, otherParams);
    },
    [navigate, onClose, track],
  );

  const quickActions = useMemo(
    () => [
      {
        icon: <ItemsIcon />,
        description: t('quick_action.create_item'),
        onClick: () =>
          handleOnClick(ROUTES.ITEM, { 'create[open]': 'true' }, 'Create Item'),
        feature_flag_disable: 'itemCreateDisableQuickActionAccess',
      },
      {
        icon: <PurchaseIcon />,
        description: t('quick_action.create_purchase'),
        onClick: () =>
          handleOnClick(
            ROUTES.PURCHASE,
            { 'create[open]': 'true' },
            'Create Purchase',
          ),
        feature_flag_disable: 'purchaseCreateDisableQuickActionAccess',
      },
      {
        icon: <InvoiceIcon />,
        description: t('quick_action.create_invoice'),
        onClick: () =>
          handleOnClick(
            ROUTES.INVOICE,
            { 'create[open]': 'true' },
            'Create Invoice',
          ),
        feature_flag_disable: 'invoiceCreateDisableQuickActionAccess',
        entitlement: EntitlementEnum.INVOICES,
      },
      {
        icon: <MaterialConsumptionIcon />,
        description: t('quick_action.create_material_consumption'),
        onClick: () =>
          handleOnClick(
            ROUTES.MATERIAL_CONSUMPTION,
            { 'create[open]': 'true' },
            'Material Consumption',
          ),
        feature_flag_disable:
          'materialConsumptionCreateDisableQuickActionAccess',
      },
      {
        icon: <FinancialProductionIcon />,
        description: t('quick_action.add_productivity'),
        onClick: () =>
          handleOnClick(
            ROUTES.FINANCIAL,
            { 'create[open]': 'true' },
            'Add Productivity',
          ),
        feature_flag_disable: 'productivityCreateDisableQuickActionAccess',
        entitlement: EntitlementEnum.PRODUCTION_YIELD_DATA,
      },
    ],
    [handleOnClick, t],
  );

  return {
    quickActions,
  };
};
