import { Tooltip } from 'antd';
import { BankAccountModel } from 'src/resources/bank-account/bank-account.model';

import * as Styled from './against-column.style';

interface AgainstColumnProps {
  value: string;
  bankAccountHashmap: Map<string, BankAccountModel>;
}

export const AgainstColumn = ({
  value,
  bankAccountHashmap,
}: AgainstColumnProps) => {
  return (
    <Tooltip title={bankAccountHashmap.get(value)?.name ?? value}>
      <Styled.Container>
        {bankAccountHashmap.get(value)?.name ?? value}
      </Styled.Container>
    </Tooltip>
  );
};
