import { SearchPageTemplate, SquareSkeleton } from 'common/components';
import { ROUTES } from 'common/constants';
import { useNavigateWithOrgAndFarm } from 'common/utils';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { OrganizationCard } from './card/organization-card.component';
import { OrganizationEmptyStateComponent } from './empty-state/organization-empty-state.component';
import { Organization } from './organization.model';
import { useOrganization } from './organization.provider';

const OrganizationContainer = () => {
  const { t } = useTranslation();
  const {
    organizations,
    currentOrganizationId,
    setCurrentOrganizationId,
    isLoading,
  } = useOrganization();
  const [filteredOrganizations, setFilteredOrganizations] = useState<
    Organization[]
  >([]);
  const navigate = useNavigateWithOrgAndFarm();
  const hasOrganizations = organizations.length > 0;
  const showEmptyState = !isLoading && !hasOrganizations;

  const handleOnSearch = useCallback(
    (value: string) => {
      const filteredOrganizations = organizations.filter((organization) =>
        organization.name.toLowerCase().includes(value.toLowerCase()),
      );
      setFilteredOrganizations(filteredOrganizations);
    },
    [organizations],
  );

  useEffect(() => {
    if (organizations.length) {
      setFilteredOrganizations(organizations);
    }
  }, [organizations, setFilteredOrganizations]);

  useEffect(() => {
    if (currentOrganizationId) {
      navigate(ROUTES.FARM, currentOrganizationId, null);
    }
  }, [currentOrganizationId, navigate]);

  useEffect(() => {
    if (organizations && organizations.length === 1) {
      setCurrentOrganizationId(organizations[0].id);
    }
  }, [organizations, setCurrentOrganizationId]);

  return (
    <SearchPageTemplate
      title={t('organizations.title')}
      hideBackButton
      onSearch={handleOnSearch}
    >
      {isLoading && <SquareSkeleton width="300px" height="65px" />}
      {hasOrganizations &&
        filteredOrganizations.map((organization) => (
          <OrganizationCard
            key={organization.id}
            organization={organization}
            onClick={() => {
              setCurrentOrganizationId(organization.id);
            }}
          />
        ))}
      {showEmptyState && <OrganizationEmptyStateComponent />}
    </SearchPageTemplate>
  );
};

export { OrganizationContainer as default, OrganizationContainer };
