import { Menu } from 'antd';
import { FC } from 'react';

import { MigrationButton } from '../header/migration-button/migration-button.component';
import { useSiderNavbar } from './sider-navbar.hook';
import * as Styled from './sider-navbar-mobile.style';

interface SiderNavbarMobileComponentType {
  isClosed: boolean;
  onFeatureClick: () => void;
}

const SiderNavbarMobileComponent: FC<SiderNavbarMobileComponentType> = ({
  isClosed,
  onFeatureClick,
}) => {
  const { handleFeatureItems, current } = useSiderNavbar({
    isMobile: true,
    onClickMenu: onFeatureClick,
  });

  return (
    <Styled.DrawerLayout open={!isClosed} width="100vw" closable={false}>
      <MigrationButton isMobile />
      <Menu
        selectedKeys={[current as string]}
        mode="inline"
        items={handleFeatureItems()}
      />
    </Styled.DrawerLayout>
  );
};

export { SiderNavbarMobileComponent as default, SiderNavbarMobileComponent };
