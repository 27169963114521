import { Card as CardAntd, Tag as TagAntd } from 'antd';
import styled from 'styled-components';
import { GreenColors, NeutralColors } from 'theme';

const Card = styled(CardAntd)``;

const Tag = styled(TagAntd)`
  display: flex;
  align-items: center;
  background-color: ${GreenColors[60]};
  color: ${NeutralColors[0]};
  font-weight: bold;
  width: max-content;
  margin-bottom: 16px;

  svg {
    width: 20px;
    fill: ${NeutralColors[0]};
    margin-right: 8px;
  }
`;

export { Card, Tag };
