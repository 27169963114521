import { DatePicker, Form, Select, TimePicker } from 'antd';
import { AutoSingleSelect } from 'common/components';
import { useTranslation } from 'react-i18next';

import { useInsertProductionDetail } from './insert-production-detail.hook';
import * as Styled from './insert-production-detail.style';

const InsertProductionDetail = () => {
  const { t } = useTranslation();
  const { farmSelectProps, projectSelectProps, seasonSelectProps } =
    useInsertProductionDetail();

  return (
    <>
      <Styled.ContainerFlex>
        <Form.Item name="stockEntryType" hidden />
        <Form.Item name="crop" hidden />
        <Form.Item
          name="postingDate"
          label={t('common.date')}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <DatePicker format={'DD/MM/YYYY'} />
        </Form.Item>
        <Form.Item
          name="postingTime"
          label={t('common.hour')}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <TimePicker />
        </Form.Item>
      </Styled.ContainerFlex>

      <Form.Item
        name="farm"
        label={t('common.business.farm')}
        rules={[
          {
            required: true,
          },
        ]}
      >
        <AutoSingleSelect
          {...farmSelectProps}
          placeholder={t('common.business.farm_placeholder')}
        />
      </Form.Item>
      <Form.Item
        label={t('common.business.season')}
        name="seasonId"
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Select
          {...seasonSelectProps}
          placeholder={t('common.business.season_placeholder')}
        />
      </Form.Item>
      <Form.Item
        label={t('common.business.field')}
        name="project"
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Select
          {...projectSelectProps}
          placeholder={t('common.business.field_placeholder')}
        />
      </Form.Item>
    </>
  );
};

export { InsertProductionDetail };
