import { getCurrencyValue } from 'common/utils/currency.util';
import { useTranslation } from 'react-i18next';

import * as Styled from './box-payed.style';

interface BoxPayedProps {
  grandTotal: number;
  percentBilled: number;
  isReadonly?: boolean;
  showItems: boolean;
  currency?: string;
}

const BoxPayed = ({
  grandTotal,
  percentBilled,
  currency,
  isReadonly,
  showItems,
}: BoxPayedProps) => {
  const { t, i18n } = useTranslation();

  if (!isReadonly || showItems || typeof grandTotal !== 'number' || !currency) {
    return null;
  }

  return (
    <Styled.BoxPayed>
      <div>{t('purchase_create.details.paid')}</div>
      <div>
        <span>
          {`${getCurrencyValue(
            (grandTotal / 100) * percentBilled,
            currency,
            i18n.language,
          )} 
            `}
        </span>
        {` de 
          ${
            grandTotal && getCurrencyValue(grandTotal, currency, i18n.language)
          }`}
      </div>
    </Styled.BoxPayed>
  );
};

export { BoxPayed };
