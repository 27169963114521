enum DefaultSettingValidationEnum {
  FARM_NOT_IN_ORGANIZATION = 'FARM_NOT_IN_ORGANIZATION',
  MISSING_FARM = 'MISSING_FARM',
  MISSING_WAREHOUSE = 'MISSING_WAREHOUSE',
  MISSING_BUYING_COST_CENTER = 'MISSING_BUYING_COST_CENTER',
  MISSING_EXPENSE_ACCOUNT = 'MISSING_EXPENSE_ACCOUNT',
}

const ItemCategoryFormDetailsTestId = {
  ITEM_CATEGORY_FORM_DETAILS_NAME: 'item-category-form-details-name',
  ITEM_CATEGORY_FORM_DETAILS_IS_GROUP: 'item-category-form-details-is-group',
  ITEM_CATEGORY_FORM_DETAILS_PARENT: 'item-category-form-details-parent',
};

const ItemCategoryFormFarmTestId = {
  ITEM_CATEGORY_FORM_FARM: 'item-category-form-farm',
  ITEM_CATEGORY_FORM_FARM_WAREHOUSE: 'item-category-form-farm-warehouse',
  ITEM_CATEGORY_FORM_FARM_COST_CENTER: 'item-category-form-farm-cost-center',
  ITEM_CATEGORY_FORM_FARM_ACCOUNT: 'item-category-form-farm-account',
};

const ALL_ITEM_GROUPS = 'All Item Groups';

export {
  ALL_ITEM_GROUPS,
  DefaultSettingValidationEnum,
  ItemCategoryFormDetailsTestId,
  ItemCategoryFormFarmTestId,
};
