import styled from 'styled-components';
import { BlueColors, NeutralColors } from 'theme';

const QuickActionItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-grow: 1;
  width: 30%;
  margin-bottom: 16px;
  text-align: center;

  .ant-typography {
    color: ${NeutralColors[100]};
  }

  svg {
    height: 24px;
    width: 24px;
  }
`;

const QuickActionIcon = styled.div`
  width: 48px;
  height: 48px;
  border-radius: 50px;
  background-color: ${BlueColors[10]};
  color: ${BlueColors[60]};
  align-items: center;
  justify-content: center;
  display: flex;
  font-size: 18px;

  svg {
    fill: currentColor;
  }
`;

export { QuickActionIcon, QuickActionItem };
