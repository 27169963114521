import { Typography } from 'antd';
import styled from 'styled-components';
import { NeutralColors } from 'theme';

const Container = styled.div`
  margin-left: auto;
`;

const ContainerQuestion = styled.div`
  display: flex;
  max-width: 80%;
  margin-right: auto;
  gap: 8px;

  svg {
    .fill-color-svg {
      fill: ${NeutralColors[0]};
    }

    .stroke-color-svg {
      stroke: ${NeutralColors[0]};
    }
  }
`;

const BoxSuccess = styled.div`
  background-color: ${NeutralColors[0]};
  border-radius: 0 12px 12px 12px;
  width: 560px;
  padding: 20px;
`;

const Text = styled(Typography.Text)`
  margin: 0;
  color: ${NeutralColors[60]};

  span {
    color: ${NeutralColors[100]};
  }
`;

export { BoxSuccess, Container, ContainerQuestion, Text };
