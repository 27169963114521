import {
  ButtonProps,
  Card,
  DrawerProps,
  Form,
  FormProps,
  Input,
  Select,
  Switch,
} from 'antd';
import { DrawerWithButtons } from 'common/components';
import { DrawerStyles } from 'common/styles';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { useWarehouseCreate } from './warehouse-create.hook';
import * as Styled from './warehouse-create.style';

interface WarehouseCreateProps {
  drawerProps: DrawerProps;
  formProps: FormProps;
  onClose: () => void;
  onWarehouseCreate?: ButtonProps;
  onWarehouseEdit?: ButtonProps;
}

const WarehouseCreateContainer = ({
  drawerProps,
  formProps,
  onClose,
  onWarehouseCreate,
  onWarehouseEdit,
}: WarehouseCreateProps) => {
  const { t } = useTranslation();

  const {
    disableSave,
    drawerWidth,
    drawerTitle,
    isEditMode,
    farmSelectProps,
    handleFormValuesChange,
    handleOnFinish,
    setIsEditMode,
  } = useWarehouseCreate(formProps, drawerProps);

  useEffect(() => {
    if (onWarehouseEdit) {
      setIsEditMode(true);
    }
  }, [onWarehouseEdit, setIsEditMode]);

  return (
    <DrawerWithButtons
      {...drawerProps}
      width={drawerWidth}
      zIndex={1001}
      title={t(drawerTitle)}
      buttonSaveProps={onWarehouseCreate ?? onWarehouseEdit}
      disabledSave={disableSave}
      onClose={onClose}
      tagTrackClose="Warehouse Edition"
    >
      <Form
        layout="vertical"
        {...formProps}
        onValuesChange={handleFormValuesChange}
        onFinish={handleOnFinish}
        data-testid="warehouse-create-form"
      >
        <Card>
          <DrawerStyles.FormItem
            label={t('stock.create.farm')}
            name={['farm']}
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Select
              {...farmSelectProps}
              placeholder={t('common.business.farm_placeholder')}
            />
          </DrawerStyles.FormItem>
          <DrawerStyles.FormItem
            label={t('stock.create.warehouse')}
            name={['name']}
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input
              placeholder={t('common.business.warehouse_placeholder') ?? ''}
            />
          </DrawerStyles.FormItem>
        </Card>
        {isEditMode && (
          <Styled.DisabledContainer>
            {t('stock.create.disable_warehouse')}
            <Form.Item valuePropName="checked" name={['disabled']}>
              <Switch
                checkedChildren={t('buttons.yes')}
                unCheckedChildren={t('buttons.no')}
              />
            </Form.Item>
          </Styled.DisabledContainer>
        )}
      </Form>
    </DrawerWithButtons>
  );
};

export { WarehouseCreateContainer as default, WarehouseCreateContainer };
