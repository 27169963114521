import { Form, Typography } from 'antd';
import { BlueColors } from 'src/theme/colors';
import styled from 'styled-components';
import { GreenColors, NeutralColors, YellowColors } from 'theme';

interface ContainerQuestionProps {
  isQuestion?: boolean;
}

const ContainerQuestion = styled.div<ContainerQuestionProps>`
  margin-top: 16px;
  display: flex;
  gap: 8px;

  .fill-color-svg {
    fill: ${(props) => (props.isQuestion ? BlueColors[20] : NeutralColors[0])};
  }
  .stroke-color-svg {
    stroke: ${(props) =>
      props.isQuestion ? BlueColors[20] : NeutralColors[0]};
  }
`;

const BoxQuestion = styled.div<ContainerQuestionProps>`
  min-width: ${(props) => (props.isQuestion ? '700px' : '560px')};
  min-height: ${(props) => (props.isQuestion ? '96px' : '64px')};
  background-color: ${(props) =>
    props.isQuestion ? BlueColors[10] : NeutralColors[0]};
  border: 1px solid
    ${(props) => (props.isQuestion ? BlueColors[20] : NeutralColors[0])};
  border-radius: 0 12px 12px 12px;
  padding: 20px;
  transition: 1s;
  cursor: pointer;
`;

const TitleQuestion = styled(Typography.Title)`
  margin-bottom: 4px !important;
`;

const Question = styled(Typography.Paragraph)`
  margin: 0 !important;
  display: flex;
  align-items: center;
  color: ${NeutralColors[100]};

  span {
    margin-left: 2px;
    color: ${NeutralColors[60]};
  }

  svg {
    margin-left: 4px;

    path {
      fill: ${YellowColors[50]};
    }
  }
`;

const ContainerOptions = styled.div`
  margin-top: 16px;
  margin-bottom: 60px;
  width: 100%;
  margin-left: auto;
`;

const FormItemSubmit = styled(Form.Item)`
  position: fixed;
  right: 48px;
  bottom: 24px;

  button {
    height: 56px;
    border-radius: 12px 0 12px 12px;
  }
`;

const BoxFinished = styled.div`
  background-color: ${GreenColors[10]};
  border: 1px solid ${GreenColors[20]};
  border-radius: 0 12px 12px 12px;
  width: fit-content;
  padding: 20px;
`;

export {
  BoxFinished,
  BoxQuestion,
  ContainerOptions,
  ContainerQuestion,
  FormItemSubmit,
  Question,
  TitleQuestion,
};
