import { track } from '@amplitude/analytics-browser';
import { Tooltip } from 'antd';
import { DollarIcon, FinishedGoodsIcon } from 'common/icons';
import { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { useReferenceValueStore } from '../reference-value.store';
import { ReferenceValueToggleTestId } from './reference-value-toggle.constant';
import * as Styled from './reference-value-toggle.style';

const ReferenceValueToggle: FC = () => {
  const { toggleReferenceValue, setToggleReferenceValue, finalProduct } =
    useReferenceValueStore();
  const { t } = useTranslation();

  const handleOnClickPrice = () => {
    track('Reference Value - View By Price');
    setToggleReferenceValue(false);
  };

  const handleOnClickProduct = () => {
    track('Reference Value - View By Product');
    setToggleReferenceValue(true);
  };

  useEffect(() => {
    if (!finalProduct) {
      setToggleReferenceValue(false);
    }
  }, [setToggleReferenceValue, finalProduct]);

  return (
    <Styled.Wrapper>
      <Tooltip
        placement="bottom"
        title={t('financial.reference_value.toggle.tooltip_price')}
      >
        <Styled.Button
          selected={!toggleReferenceValue}
          type="text"
          icon={<DollarIcon />}
          onClick={handleOnClickPrice}
          data-testid={ReferenceValueToggleTestId.TOGGLE_REFERENCE_VALUE_PRICE}
        />
      </Tooltip>
      <Tooltip
        placement="bottom"
        title={t('financial.reference_value.toggle.tooltip_product')}
      >
        <Styled.Button
          selected={toggleReferenceValue}
          type="text"
          icon={<FinishedGoodsIcon />}
          onClick={handleOnClickProduct}
          data-testid={
            ReferenceValueToggleTestId.TOGGLE_REFERENCE_VALUE_PRODUCT
          }
          disabled={!finalProduct}
        />
      </Tooltip>
    </Styled.Wrapper>
  );
};

export { ReferenceValueToggle as default, ReferenceValueToggle };
