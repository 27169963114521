import { GetOneResponse } from '@refinedev/core';
import { Skeleton, Typography } from 'antd';
import { ChartHorizontalBar } from 'common/charts';
import { FieldIcon } from 'common/icons';
import { forwardRef, Ref } from 'react';
import { useTranslation } from 'react-i18next';
import { FinalProduct } from 'src/financial/reference-value/reference-value.model';

import { GraphicShowEnum } from '../financial-overview.enum';
import { DashboardField } from '../financial-overview.model';
import { useCropCostChart } from './crop-cost-chart.hook';
import * as Styled from './crop-cost-chart.style';

interface CropCostChartProps {
  data?: GetOneResponse<DashboardField>;
  isFetching: boolean;
  categoryOrder: string[];
  currency: string;
  toggleReferenceValue: boolean;
  finalProduct?: FinalProduct;
  isEmpty?: boolean;
  entitlements: string[];
}

const CropCostChart = forwardRef<HTMLElement, CropCostChartProps>(
  (
    {
      data,
      isFetching,
      currency,
      toggleReferenceValue,
      finalProduct,
      categoryOrder,
      isEmpty,
      entitlements,
    },
    ref,
  ) => {
    const { t } = useTranslation();

    const {
      handleOnChangeSelect,
      typeGraphic,
      dataBar,
      currencySymbol,
      customUom,
    } = useCropCostChart({
      currency,
      toggleReferenceValue,
      finalProduct,
      data,
      categoryOrder,
      isFetching,
      entitlements,
    });

    return (
      <Styled.Card bordered={false}>
        <Styled.ContainerFlex>
          <Styled.Title level={5} ref={ref as Ref<HTMLDivElement>}>
            <FieldIcon />
            {t('financial.cost_and_potential_revenue_by_field')}
          </Styled.Title>
          <Styled.Select
            onChange={handleOnChangeSelect}
            value={typeGraphic}
            options={Object.values(GraphicShowEnum).map((value) => ({
              label: t(`financial.${value}`),
              value,
            }))}
          />
        </Styled.ContainerFlex>

        {isFetching && <Skeleton active />}

        {isEmpty && !isFetching && (
          <Styled.EmptyContainer>
            <Styled.EmptyImage />
            <Styled.EmptyTextWrapper>
              <Typography.Title level={5}>
                {t('financial.no_data.field_chart.title')}
              </Typography.Title>
              <Typography.Text>
                {t('financial.no_data.field_chart.description_1')}
              </Typography.Text>
              <Typography.Text>
                {t('financial.no_data.field_chart.description_2')}
              </Typography.Text>
            </Styled.EmptyTextWrapper>
          </Styled.EmptyContainer>
        )}

        {!isFetching && dataBar && !isEmpty && (
          <ChartHorizontalBar
            data={dataBar}
            currencySymbol={currencySymbol}
            customUom={customUom}
          />
        )}
      </Styled.Card>
    );
  },
);

CropCostChart.displayName = 'CropCostChart';

export { CropCostChart };
