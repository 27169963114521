import { useSelect } from '@refinedev/antd';
import { useWatch } from 'antd/es/form/Form';
import useFormInstance from 'antd/es/form/hooks/useFormInstance';
import { FarmModel } from 'common/models';
import { useCallback, useEffect, useMemo } from 'react';
import { SeasonModel } from 'src/financial/financial.model';
import { useFarm, useOrganization } from 'src/organization';

const useInvoiceFormDetails = () => {
  const { currentOrganizationId, isLoading: isOrganizationLoading } =
    useOrganization();
  const { getCurrencyByFarm } = useFarm();
  const formInstance = useFormInstance();
  const postingDate = useWatch('postingDate');
  const dueDate = useWatch('dueDate');
  const farm = useWatch('farm');
  const isCropZone = useWatch('isCropZone');
  const isPaid = useWatch('isPaid');
  const seasonId = useWatch('seasonId');
  const allData = useWatch([]);

  const metadata = useMemo(
    () => ({
      organizationId: currentOrganizationId,
    }),
    [currentOrganizationId],
  );

  const { selectProps: selectPropsAccount } = useSelect({
    resource: 'account',
    optionLabel: 'name',
    optionValue: 'id',
    onSearch: (value) => [
      {
        field: 'name',
        operator: 'eq',
        value: value,
      },
    ],
    filters: [
      {
        field: 'farm',
        operator: 'eq',
        value: farm,
      },
      {
        field: 'accountType',
        operator: 'eq',
        value: ['Bank', 'Cash'],
      },
      {
        field: 'reportType',
        operator: 'eq',
        value: 'Balance Sheet',
      },
      {
        field: 'isGroup',
        operator: 'eq',
        value: 0,
      },
      {
        field: 'isDisabled',
        operator: 'eq',
        value: 0,
      },
    ],
    queryOptions: {
      enabled: !!metadata.organizationId && isPaid && !!farm,
    },
  });

  const loadSelectList = useMemo(
    () => Boolean(!!currentOrganizationId && !isOrganizationLoading),
    [currentOrganizationId, isOrganizationLoading],
  );

  useEffect(() => {
    formInstance?.validateFields(['postingDate', 'dueDate']).then(
      () => undefined,
      () => undefined,
    );
  }, [postingDate, dueDate, formInstance]);

  const { selectProps: farmSelectProps, queryResult: farmQueryResult } =
    useSelect<FarmModel>({
      resource: 'farm',
      optionValue: 'id',
      optionLabel: 'id',
      onSearch: (value) => [
        {
          field: 'name',
          operator: 'eq',
          value: value,
        },
      ],
      meta: metadata,
      queryOptions: {
        enabled: loadSelectList,
      },
    });

  const { selectProps: supplierSelectProps, queryResult: supplierQueryResult } =
    useSelect({
      resource: 'supplier',
      optionValue: 'id',
      optionLabel: 'id',
      onSearch: (value) => [
        {
          field: 'name',
          operator: 'eq',
          value: value,
        },
      ],
      meta: metadata,
      queryOptions: {
        enabled: loadSelectList,
      },
      pagination: {
        pageSize: 50,
      },
    });

  const { selectProps: seasonSelectProps, queryResult: seasonQueryResult } =
    useSelect<SeasonModel>({
      resource: 'season',
      optionValue: 'seasonId',
      optionLabel: 'seasonName',
      filters: [
        {
          field: 'farm',
          operator: 'eq',
          value: farm,
        },
      ],
      onSearch: (value) => [
        {
          field: 'name',
          operator: 'eq',
          value: value,
        },
      ],
      meta: metadata,
      queryOptions: {
        enabled: loadSelectList && !!farm,
      },
    });

  const { selectProps: projectSelectProps, queryResult: projectQueryResult } =
    useSelect({
      resource: 'project',
      optionValue: 'id',
      optionLabel: 'projectName',
      filters: [
        {
          field: 'farm',
          operator: 'eq',
          value: farm,
        },
        {
          field: 'costCenter',
          operator: 'eq',
          value: allData?.costCenter,
        },
        {
          field: 'seasonId',
          operator: 'eq',
          value: seasonId,
        },
      ],
      onSearch: (value) => [
        {
          field: 'name',
          operator: 'eq',
          value: value,
        },
      ],
      meta: metadata,
      queryOptions: {
        enabled: loadSelectList && !!farm,
      },
    });

  useEffect(() => {
    if (farm) {
      formInstance?.setFieldValue('currency', getCurrencyByFarm(farm));
    }
  }, [farm, formInstance]);

  useEffect(() => {
    if (seasonId) {
      const selectedSeason = seasonQueryResult.data?.data.find(
        (season) => season.seasonId === seasonId,
      );
      if (selectedSeason) {
        formInstance.setFieldValue('costCenter', selectedSeason.costCenter);
      }
    }
  }, [formInstance, seasonId, seasonQueryResult?.data?.data]);

  const onChangeFarm = useCallback(() => {
    formInstance.setFieldsValue({
      costCenter: undefined,
      project: undefined,
      seasonId: undefined,
      items: undefined,
    });
  }, [formInstance]);

  return {
    farm,
    farmSelectProps,
    seasonSelectProps,
    supplierSelectProps,
    projectSelectProps,
    selectPropsAccount,
    onChangeFarm,
    dueDate,
    isCropZone,
    isPaid,
    isLoadingFarm: farmQueryResult?.isLoading,
    isLoadingSupplier: supplierQueryResult?.isLoading,
    isLoadingProject: projectQueryResult?.isLoading,
    isLoadingSeason: seasonQueryResult?.isLoading,
  };
};

export { useInvoiceFormDetails as default, useInvoiceFormDetails };
