import { InputNumber, Typography } from 'antd';
import styled from 'styled-components';
import { NeutralColors } from 'theme';

const ContainerEmpty = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  svg {
    width: 124px;
    margin: -24px;
  }
`;

const EmptyDescription = styled(Typography.Paragraph)`
  text-align: center;
  width: 70%;
  color: ${NeutralColors[60]};
`;

const InputItem = styled(InputNumber)`
  width: 100%;
`;

export { ContainerEmpty, EmptyDescription, InputItem };
