import { ROUTES } from 'common/constants';
import { Navigate, useLocation } from 'react-router-dom';
import { useAuth } from 'src/auth';

function AccessGuardian({ children }: { children: JSX.Element }) {
  const auth = useAuth();
  const location = useLocation();

  if (!auth.user) {
    return (
      <Navigate
        to={ROUTES.AUTHENTICATE + location.search}
        state={{ from: location }}
        replace
      />
    );
  }

  return children;
}

export { AccessGuardian, AccessGuardian as default };
