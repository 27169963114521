import { Button } from 'antd';
import styled from 'styled-components';

const DeleteButton = styled(Button)`
  display: flex;
  align-items: center;

  svg {
    width: 16px;
  }
`;

export { DeleteButton };
