import { useTable } from '@refinedev/core';
import { CardButton } from 'common/components';
import { ROUTES } from 'common/constants';
import { DataImportIcon, XlsxIcon } from 'common/icons';
import { useNavigateWithOrgAndFarm } from 'common/utils';
import dayjs from 'dayjs';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useOrganization } from 'src/organization';

import { DataImportButtonResourceTestId } from '../data-import.constant';
import { DocTypeEnum } from '../data-import.enum';
import * as Styled from './button-resource.style';

interface ButtonResourceProps {
  docType: DocTypeEnum;
}

export const ButtonResource = ({ docType }: ButtonResourceProps) => {
  const { t, i18n } = useTranslation();
  const userLanguage = i18n.language.toLocaleLowerCase();
  const navigate = useNavigateWithOrgAndFarm();
  const { currentOrganizationId } = useOrganization();
  const metadata = useMemo(
    () => ({
      organizationId: currentOrganizationId,
    }),
    [currentOrganizationId],
  );

  const { tableQueryResult } = useTable({
    resource: `data-import/${docType}`,
    syncWithLocation: true,
    queryOptions: {
      enabled: !!currentOrganizationId && !!docType,
    },
    meta: metadata,
    filters: {
      initial: [
        {
          field: 'status',
          operator: 'eq',
          value: ['Success', 'Partial Success'],
        },
      ],
    },
  });

  const creation = tableQueryResult.data?.data[0]?.creation;

  const handleGoToList = () => {
    navigate(`${ROUTES.DATA_IMPORT_LIST_ROOT}/${encodeURIComponent(docType)}`);
  };

  if (!tableQueryResult || !tableQueryResult.data?.data?.length) {
    return null;
  }

  return (
    <CardButton
      title={t(`data_import.doc_type.${docType}`) ?? ''}
      data-testid={
        DataImportButtonResourceTestId.DATA_IMPORT_BUTTON_RESOURCE_CONTAINER
      }
      description={
        creation
          ? dayjs(tableQueryResult.data?.data[0].creation)
              .locale(userLanguage)
              .format('DD/MM/YYYY')
          : ''
      }
      icon={<XlsxIcon />}
      status={
        <Styled.StatusBox>
          <Styled.StatusIcon />
          {t('data_import.imported_data.imported')}
        </Styled.StatusBox>
      }
      onClick={handleGoToList}
      button={
        <Styled.CardDataBase>
          <DataImportIcon />
          {tableQueryResult.data?.total}
        </Styled.CardDataBase>
      }
    />
  );
};
