import { Form } from 'antd';
import styled from 'styled-components';
import { BlueColors, GreenColors, NeutralColors } from 'theme';

interface ContainerProps {
  show: boolean;
}

interface BlueBoxProps {
  isBlue?: boolean;
}

const Container = styled.div<ContainerProps>`
  display: ${(props) => (props.show ? 'flex' : 'none')};
  margin-top: 24px;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
`;

const Box = styled.div`
  display: flex;
  width: 100%;
  padding: 30px 16px;
  border-radius: 8px;
  border: 1px solid ${NeutralColors[20]};
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
`;

const GreenBox = styled(Box)`
  border-radius: 12px;
  border: 1px solid ${GreenColors[60]};
`;

const BlueBox = styled(Box)<BlueBoxProps>`
  border: 1px solid
    ${(props) => (props.isBlue ? BlueColors[20] : NeutralColors[20])};
  background-color: ${(props) =>
    props.isBlue ? BlueColors[10] : NeutralColors[0]};
`;

const DashedBox = styled(Box)`
  border-radius: 12px;
  border: 1px dashed ${GreenColors[60]};
`;

const FormItem = styled(Form.Item)`
  width: 100%;
  margin: 0;

  .fms-picker {
    width: 100%;
  }
`;

const SwitchWrapper = styled(Form.Item)`
  width: 100%;
  margin: 0;

  .fms-form-item {
    margin-bottom: 0;
  }

  .fms-form-item-control-input-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 16px;
  }

  .fms-picker {
    width: 100%;
  }
`;

const ContainerDates = styled.div`
  display: flex;
  gap: 16px;
`;

export {
  BlueBox,
  Box,
  Container,
  ContainerDates,
  DashedBox,
  FormItem,
  GreenBox,
  SwitchWrapper,
};
