import { HttpError, useList, useOne } from '@refinedev/core';
import { DefaultOptionType } from 'antd/es/select';
import {
  BankAccountIcon,
  CostCenterIcon,
  CropIcon,
  DateIcon,
  FarmIcon,
  GroupByIcon,
} from 'common/icons';
import { FarmModel } from 'common/models';
import {
  ListFiltersModel,
  RefineTableFiltersEnum,
  useRefineTableFilters,
} from 'common/refine-table-filters';
import dayjs, { Dayjs } from 'dayjs';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useFarm, useOrganization } from 'src/organization';
import { BankAccountModel } from 'src/resources/bank-account/bank-account.model';

import { GroupByEnum } from './group-by.enum';
import { generateParamsFilter } from './report-general-ledger.domain';
import { ReportGeneralLedgerModel } from './report-general-ledger.model';

interface FilterFormModel {
  farm: string;
  name: string;
  category: string;
  fromDate: Dayjs;
}

const useReportGeneralLedger = () => {
  const [isDownload, setIsDownload] = useState<boolean>(false);
  const { currentFarm, isLoading: isFarmLoading } = useFarm();
  const [hasFilteredFarm, setHasFilteredFarm] = useState<boolean>(false);
  const { t } = useTranslation();
  const { currentOrganizationId, isLoading: isOrganizationLoading } =
    useOrganization();

  const metadata = useMemo(
    () => ({
      organizationId: currentOrganizationId,
    }),
    [currentOrganizationId],
  );

  const isDependenciesLoaded = useMemo(
    () =>
      (!!currentFarm || !!currentOrganizationId) &&
      !isOrganizationLoading &&
      !isFarmLoading,
    [currentFarm, currentOrganizationId, isFarmLoading, isOrganizationLoading],
  );

  const optionsGroupBy: DefaultOptionType[] = useMemo(
    () => [
      {
        label: t('report_general_ledger.group_by.voucher'),
        value: GroupByEnum.VOUCHER,
      },
      {
        label: t('report_general_ledger.group_by.voucher_consolidated'),
        value: GroupByEnum.VOUCHER_CONSOLIDATED,
      },
      {
        label: t('report_general_ledger.group_by.account'),
        value: GroupByEnum.ACCOUNT,
      },
      {
        label: t('report_general_ledger.group_by.party'),
        value: GroupByEnum.PARTY,
      },
    ],
    [],
  );

  const listFilters: ListFiltersModel[] = useMemo(
    () => [
      {
        label: t('purchase.farm'),
        field: 'farm',
        type: RefineTableFiltersEnum.SELECT,
        resource: 'farm',
        show: true,
        icon: <FarmIcon />,
      },
      {
        label: t('report_general_ledger.filter.start'),
        field: 'fromDate',
        type: RefineTableFiltersEnum.DATE,
        initialValue: dayjs().subtract(1, 'month'),
        show: true,
        icon: <DateIcon />,
      },
      {
        label: t('report_general_ledger.filter.end'),
        field: 'toDate',
        type: RefineTableFiltersEnum.DATE,
        initialValue: dayjs(),
        show: true,
        icon: <DateIcon />,
      },
      {
        label: t('report_general_ledger.filter.cost_center'),
        field: 'costCenter',
        type: RefineTableFiltersEnum.SELECT,
        resource: 'cost-center',
        parentsField: ['farm'],
        icon: <CostCenterIcon />,
      },
      {
        label: t('report_general_ledger.filter.group_by'),
        field: 'groupBy',
        type: RefineTableFiltersEnum.SELECT,
        initialValue: GroupByEnum.VOUCHER,
        options: optionsGroupBy,
        icon: <GroupByIcon />,
        show: true,
      },
      {
        label: t('report_general_ledger.filter.field'),
        field: 'project',
        type: RefineTableFiltersEnum.SELECT,
        resource: 'crop-cycle',
        parentsField: ['farm'],
        icon: <CropIcon />,
        optionLabel: 'projectName',
      },
      {
        label: t('report_general_ledger.filter.bank_account'),
        field: 'account',
        type: RefineTableFiltersEnum.MULTIPLE_SELECT,
        resource: 'account',
        parentsField: ['farm'],
        icon: <BankAccountIcon />,
        optionLabel: 'name',
        filters: [
          {
            field: 'accountType',
            operator: 'eq',
            value: ['Bank', 'Cash'],
          },
          {
            field: 'reportType',
            operator: 'eq',
            value: 'Balance Sheet',
          },
          {
            field: 'isGroup',
            operator: 'eq',
            value: 0,
          },
          {
            field: 'isDisabled',
            operator: 'eq',
            value: 0,
          },
        ],
      },
    ],
    [currentFarm, optionsGroupBy],
  );

  const { tableProps, searchFormProps, tableQueryResult } =
    useRefineTableFilters<ReportGeneralLedgerModel, HttpError, FilterFormModel>(
      {
        listFilters,
        resource: 'report/general-ledger/pre-view',
        errorNotification: false,
        queryOptions: {
          enabled: hasFilteredFarm,
          retry: false,
        },
      },
    );

  const {
    data: dataCsv,
    refetch,
    remove,
    isFetching,
  } = useOne({
    resource: 'report/export',
    id: 'xlsx',
    meta: {
      params: {
        reportName: 'General Ledger',
        ...generateParamsFilter(listFilters, searchFormProps),
        ...metadata,
      },
      responseType: 'arraybuffer',
    },
    queryOptions: {
      enabled: isDownload,
    },
  });

  const { data: farmData } = useOne<FarmModel>({
    resource: 'farm',
    id: searchFormProps.form?.getFieldValue('farm'),
    meta: {
      organizationId: currentOrganizationId,
    },
    queryOptions: {
      enabled:
        !!searchFormProps.form?.getFieldValue('farm') &&
        !!currentOrganizationId,
    },
  });

  useEffect(() => {
    if (currentFarm && searchFormProps) {
      searchFormProps.form?.setFieldsValue({
        farm: currentFarm.name,
      });
      searchFormProps.form?.submit();
    }
  }, [currentFarm, searchFormProps.form?.setFieldValue]);

  useEffect(() => {
    if (dataCsv) {
      const farm = searchFormProps.form?.getFieldValue('farm');
      const fromDate = (
        searchFormProps.form?.getFieldValue('fromDate') as Dayjs
      )?.format('YYYY-MM-DD');
      const url = window.URL.createObjectURL(new Blob([dataCsv.data as Blob]));

      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${farm} - ${fromDate}.xlsx`);
      document.body.appendChild(link);
      link.click();

      window.URL.revokeObjectURL(url);
      if (document?.body?.removeChild) {
        document.body.removeChild(link);
      }
      setIsDownload(false);
    }
  }, [dataCsv]);

  useEffect(() => {
    setHasFilteredFarm(!!searchFormProps.form?.getFieldValue('farm'));
  }, [searchFormProps]);

  const { data: bankAccountList } = useList<BankAccountModel>({
    resource: 'account',
    filters: [
      {
        field: 'farm',
        operator: 'eq',
        value: currentFarm?.id,
      },
      {
        field: 'isGroup',
        operator: 'eq',
        value: 0,
      },
      {
        field: 'isDisabled',
        operator: 'eq',
        value: 0,
      },
    ],
    pagination: {
      pageSize: 500,
    },
    queryOptions: {
      enabled: !!metadata.organizationId && !!currentFarm,
    },
  });

  const bankAccountHashmap = useMemo(() => {
    const hashmap = new Map<string, BankAccountModel>();
    bankAccountList?.data?.forEach((item) => {
      hashmap.set(item.id, item);
    });
    return hashmap;
  }, [bankAccountList]);

  const isLoadingData = useMemo(
    () =>
      (tableQueryResult.isLoading || tableQueryResult.isFetching) &&
      hasFilteredFarm,
    [tableQueryResult.isFetching, tableQueryResult.isLoading, hasFilteredFarm],
  );

  const isEmpty = useMemo(
    () =>
      (!isLoadingData &&
        isDependenciesLoaded &&
        (!tableQueryResult.data || tableQueryResult.data?.data.length === 0)) ??
      false,
    [isLoadingData, isDependenciesLoaded, tableQueryResult],
  );

  const hasData = useMemo(
    () => !isLoadingData && !isEmpty,
    [isEmpty, isLoadingData],
  );

  const handleOnClickDownload = () => {
    if (dataCsv) {
      remove();
      refetch();
    }
    setIsDownload(true);
  };

  return {
    tableProps,
    searchFormProps,
    isLoadingData,
    isEmpty,
    hasData,
    farmData,
    handleOnClickDownload,
    listFilters,
    isFetching,
    bankAccountHashmap,
  };
};

export { FilterFormModel, useReportGeneralLedger };
