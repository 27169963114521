import { RefineProps } from '@refinedev/core';
import api, { apiUrlOptions } from 'src/api.config';

enum ActionRbacEnum {
  ACCESS_BALANCE = 'balance:access',
  FINANCIAL_BALANCE_WRITE = 'financial_balance:write',
  FINANCIAL_BALANCE_READ = 'financial_balance:read',
  STOCK_BALANCE_WRITE = 'stock_balance:write',
  STOCK_BALANCE_READ = 'stock_balance:read',
}

interface OperationType {
  resource: string;
  action: string;
}

interface VerifyResult {
  account_id: string;
  allowed_operations: OperationType[];
  denied_operations: OperationType[];
}

const authProvider: RefineProps['authProvider'] = {
  login: async () => ({
    success: true,
  }),
  register: async () => ({
    success: true,
  }),
  logout: async () => ({
    success: true,
  }),
  onError: async (error) => ({ error }),
  check: async () => ({ authenticated: true }),
  getPermissions: async (params) => {
    const crn = params?.meta?.crn;
    const url = `${apiUrlOptions.base}/v2/auth/verify`;

    if (!crn) {
      return undefined;
    }

    const { data } = await api.post<VerifyResult>(
      url,
      {
        operations: Object.values(ActionRbacEnum).map((value) => ({
          action: value,
          resource: crn,
        })),
      },
      {
        headers: {
          'Content-Type': 'application/json',
        },
      },
    );

    return data?.allowed_operations?.map((operation) => operation?.action);
  },
};

export { ActionRbacEnum, authProvider as default };
