import { Tag as TagAntd } from 'antd';
import styled from 'styled-components';

interface TagProps {
  backgroundColor: string;
  textColor: string;
  borderColor: string;
}

const Tag = styled(TagAntd)<TagProps>`
  background-color: ${({ backgroundColor }) => backgroundColor} !important;
  color: ${({ textColor }) => textColor} !important;
  border-color: ${({ borderColor }) => borderColor} !important;
`;

export { Tag };
