import { Button } from 'antd';
import { ROUTES } from 'common/constants';
import { HelpCenterIcon } from 'common/icons';
import { useNavigateWithOrgAndFarm } from 'common/utils';
import { FC } from 'react';

const HelpCenterButton: FC = () => {
  const navigate = useNavigateWithOrgAndFarm();

  return (
    <Button
      type="text"
      icon={<HelpCenterIcon />}
      onClick={() => navigate(ROUTES.HELP_CENTER)}
      data-testid="help-center-button"
    />
  );
};

export { HelpCenterButton as default, HelpCenterButton };
