import styled from 'styled-components';
import { NeutralColors } from 'theme';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 16px;
  border: 1px solid ${NeutralColors[20]};
  border-radius: 8px;
  margin-bottom: 16px;
`;

const Text = styled.p`
  font-size: 14px;
  margin: 0;

  color: ${NeutralColors[100]};
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  span {
    width: 20px;
    margin: 0 4px;
    color: ${NeutralColors[60]};
  }
`;

const ContainerText = styled.div`
  display: flex;
  align-items: center;

  svg {
    fill: ${NeutralColors[60]} !important;
    width: 14px;
  }
`;

export { Container, ContainerText, Text };
