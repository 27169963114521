import { InfoIcon } from 'common/icons';
import { FC } from 'react';

import * as Styled from './column-title-with-tooltip.style';

interface ColumnTitleWithTooltipProps {
  title: string;
  tooltip: string;
}

const ColumnTitleWithTooltip: FC<ColumnTitleWithTooltipProps> = ({
  title,
  tooltip,
}) => (
  <Styled.TooltipWrapper title={tooltip}>
    {title} <InfoIcon />
  </Styled.TooltipWrapper>
);

export { ColumnTitleWithTooltip, ColumnTitleWithTooltip as default };
