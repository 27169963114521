import { Typography } from 'antd';
import { PriceChangeIcon } from 'common/icons';
import { getCurrencyValue } from 'common/utils/currency.util';
import { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useFarm } from 'src/organization';

import { FinalProduct } from '../reference-value.model';
import * as Styled from './reference-value-button.style';

interface ReferenceValueButtonProps {
  onClick: () => void;
  seasonId?: string;
  farm?: string;
  finalProduct?: FinalProduct;
}

const ReferenceValueButton = forwardRef<HTMLElement, ReferenceValueButtonProps>(
  ({ onClick, seasonId, farm, finalProduct }, ref) => {
    const { i18n, t } = useTranslation();
    const { getCurrencyByFarm } = useFarm();

    const currency = getCurrencyByFarm(farm ?? '');

    return (
      <Styled.Button
        onClick={() => {
          onClick();
        }}
        color="primary"
        empty={!(finalProduct?.valueReference && seasonId)}
        size="large"
        ref={ref}
      >
        <PriceChangeIcon />
        <Typography.Text type="secondary">
          {t('financial.reference_value.button')}&nbsp;
        </Typography.Text>
        <Typography.Title level={5}>
          {getCurrencyValue(
            finalProduct?.valueReference ?? 0,
            currency,
            i18n.language,
          )}
        </Typography.Title>
        {finalProduct?.valueReference && seasonId ? (
          <Styled.EditIcon />
        ) : (
          <Styled.AddIcon />
        )}
      </Styled.Button>
    );
  },
);

ReferenceValueButton.displayName = 'ReferenceValueButton';

export { ReferenceValueButton as default, ReferenceValueButton };
