import { Typography } from 'antd';
import { useWatch } from 'antd/es/form/Form';
import { ClockIcon, DollarIcon } from 'common/icons';
import { getCurrencySymbol } from 'common/utils';
import { getCurrencyValue } from 'common/utils/currency.util';
import { useTranslation } from 'react-i18next';
import { useFarm } from 'src/organization';

import { InvoicePaymentFormDetailTestId } from '../invoice-payment-form.constants';
import * as Styled from './invoice-payment-form-detail.style';

interface InvoicePaymentFormDetailProps {
  paidTotal: number;
  outstandingTotal: number;
}

const InvoicePaymentFormDetail = ({
  paidTotal,
  outstandingTotal,
}: InvoicePaymentFormDetailProps) => {
  const { t, i18n } = useTranslation();
  const { getCurrencyByFarm } = useFarm();
  const farm = useWatch(['farm']);

  const currency = getCurrencyByFarm(farm);

  return (
    <Styled.Container
      data-testid={
        InvoicePaymentFormDetailTestId.INVOICE_PAYMENT_FORM_DETAIL_CONTAINER
      }
    >
      <Styled.AmountWrapper>
        <Styled.AmountValue>
          <Typography.Text
            type="secondary"
            data-testid={
              InvoicePaymentFormDetailTestId.INVOICE_PAYMENT_FORM_DETAIL_OUTSTANDING_TOTAL
            }
          >
            {t('invoice.payment.form.outstanding_total')}
          </Typography.Text>
          <Styled.Space>
            <Styled.IconWrapper>
              <ClockIcon />
            </Styled.IconWrapper>
            {getCurrencyValue(outstandingTotal, currency, i18n.language)}
          </Styled.Space>
        </Styled.AmountValue>
        <Styled.AmountValue>
          <Typography.Text
            type="secondary"
            data-testid={
              InvoicePaymentFormDetailTestId.INVOICE_PAYMENT_FORM_DETAIL_PAID_TOTAL
            }
          >
            {t('invoice.payment.form.paid_total')}
          </Typography.Text>
          <Styled.Space>
            <Styled.IconWrapper>
              <DollarIcon />
            </Styled.IconWrapper>
            {getCurrencyValue(paidTotal, currency, i18n.language)}
          </Styled.Space>
        </Styled.AmountValue>
      </Styled.AmountWrapper>
      <Styled.FormItem
        name={['amount']}
        label={t('invoice.payment.form.paid_amount')}
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Styled.InputNumber
          prefix={getCurrencySymbol(currency)}
          data-testid={
            InvoicePaymentFormDetailTestId.INVOICE_PAYMENT_FORM_DETAIL_PAID_AMOUNT
          }
        />
      </Styled.FormItem>
    </Styled.Container>
  );
};

export { InvoicePaymentFormDetail as default, InvoicePaymentFormDetail };
