import { useOne } from '@refinedev/core';
import { ROUTES } from 'common/constants';
import { useNavigateWithOrgAndFarm } from 'common/utils';
import { useFlags } from 'launchdarkly-react-client-sdk';
import {
  DashboardRequest,
  PurchaseInvoiceReport,
} from 'src/dashboard/dashboard.interface';
import { AccountsPayableEnum } from 'src/orders-and-payments/accounts-payable/accounts-payable.enum';
import { useOrganization } from 'src/organization';

const usePurchaseInvoiceBox = (farm?: string) => {
  const { currentOrganizationId } = useOrganization();
  const navigate = useNavigateWithOrgAndFarm();
  const { landingPurchaseInvoice } = useFlags();
  const { data, isFetching } = useOne<DashboardRequest<PurchaseInvoiceReport>>({
    resource: 'report',
    id: '',
    meta: {
      organizationId: currentOrganizationId,
      params: {
        reportName: landingPurchaseInvoice,
        farm,
      },
    },
  });

  const overdueTotal = data?.data?.result?.[0]?.overduetotal;
  const overdueSevenDays = data?.data?.result?.[0]?.overduesevendays;

  const handleGoToPurchaseInvoice = () => {
    navigate(
      ROUTES.ACCOUNTS_PAYABLE,
      undefined,
      undefined,
      undefined,
      undefined,
      [
        {
          field: 'farms',
          operator: 'eq',
          value: farm,
        },
        {
          field: 'type',
          operator: 'eq',
          value: AccountsPayableEnum.PURCHASE_INVOICE,
        },
      ],
    );
  };

  return {
    overdueTotal,
    overdueSevenDays,
    handleGoToPurchaseInvoice,
    isFetching,
  };
};

export { usePurchaseInvoiceBox };
