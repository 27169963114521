import { Button, Typography } from 'antd';
import styled from 'styled-components';
import { NeutralColors } from 'theme';

const Header = styled.div`
  display: flex;
  justify-content: space-between;
`;

const CloseIcon = styled(Button)`
  border: none;
`;

const Text = styled(Typography.Text)`
  align-items: center;
  display: flex;
  margin-bottom: 16px;
`;

const IconBox = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${NeutralColors[10]};
  border-radius: 4px;
  width: 18px;
  height: 18px;
  margin-right: 4px;

  svg {
    fill: ${NeutralColors[60]};
    width: 14px;
  }
`;

const ContainerTd = styled.div`
  display: flex;
  align-items: center;
`;

const BoxIcon = styled.div`
  background-color: ${NeutralColors[10]};
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 4px;
  width: 18px;
  height: 18px;

  svg {
    fill: ${NeutralColors[60]};
    width: 14px;
  }
`;

export { BoxIcon, CloseIcon, ContainerTd, Header, IconBox, Text };
