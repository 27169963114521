import { MovementType } from './movements.enum';
import { MovementsModel } from './movements.model';

const getMovementTransferType = (
  movement: MovementsModel,
): 'transfer_in' | 'transfer_out' | undefined => {
  if (movement.movement === MovementType.TRANSFER) {
    if (movement.quantity > 0) {
      return 'transfer_in';
    }

    if (movement.quantity < 0) {
      return 'transfer_out';
    }
  }

  return undefined;
};

export { getMovementTransferType };
