import { CloseOutlined, PlusOutlined } from '@ant-design/icons';
import { Input as InputAntd } from 'antd';
import styled from 'styled-components';
import { NeutralColors } from 'theme';

const ContainerHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
  align-items: center;
`;

const Container = styled.div`
  margin-bottom: 16px;
`;

const Input = styled(InputAntd)`
  max-width: 240px;
`;

const IconPlus = styled(PlusOutlined)`
  color: ${NeutralColors[40]};
`;

const IconClose = styled(CloseOutlined)`
  color: ${NeutralColors[40]};
`;

export { Container, ContainerHeader, IconClose, IconPlus, Input };
