import { BarItemProps } from '@nivo/bar';
import React, { Dispatch, FC, SetStateAction, useEffect } from 'react';

import { ChartCustomTooltipRefProps } from '../chart-custom-tooltip-ref/chart-custom-tooltip-ref.component';
import ChartHorizontalBarLabel from '../chart-horizontal-bar-label/chart-horizontal-bar-label.component';
import { CHART_COLORS } from '../charts.constant';
import {
  ColorPerLabel,
  DetailedData,
  LegendDataPerGroup,
  MarkersPerLabel,
} from '../charts.model';
import { useChartHorizontalGroupedBar } from './chart-horizontal-grouped-bar.hook';

interface ChartHorizontalGroupedBarProps {
  barProps: BarItemProps<{ id: string }>;
  detailedData: Record<string, DetailedData>;
  colorPerLabel: ColorPerLabel;
  markersPerLabel: MarkersPerLabel;
  tooltipRef: React.RefObject<ChartCustomTooltipRefProps>;
  valueFormat: (value: number) => string;
  keysFiltered: string[];
  legendDataPerGroup: LegendDataPerGroup;
  scaleWidth: number;
  setScaleWidth: Dispatch<SetStateAction<number>>;
}

const ChartHorizontalGroupedBar: FC<ChartHorizontalGroupedBarProps> = ({
  barProps,
  detailedData,
  colorPerLabel,
  markersPerLabel,
  tooltipRef,
  valueFormat,
  keysFiltered,
  legendDataPerGroup,
  scaleWidth,
  setScaleWidth,
}) => {
  const { x, y, width, data, height } = barProps.bar;
  const groupKey = data.id.toString().slice(0, -5); // 5 is the length of the appendix => 'Total'
  const barKey = barProps.bar.key.slice(groupKey.length + 6); // 6 is the length of the Apendix plus dot => 'Total.'
  const selectedDataByLabel = detailedData[barKey];
  const selectedData = selectedDataByLabel[groupKey];
  const selectedColorByLabel = colorPerLabel[barKey];
  const selectedColor = selectedColorByLabel[groupKey];
  const selectedMarkersByLabel = markersPerLabel[barKey];
  const selectedMarkers = selectedMarkersByLabel[groupKey];
  const totalValue = data.value || 1;
  let previousEndCoordinate = 0;
  const legendData = legendDataPerGroup[barKey];
  const isLastBar =
    keysFiltered.indexOf(data.id as string) === keysFiltered.length - 1;
  const isFirstBar = keysFiltered.indexOf(data.id as string) === 0;
  const widthScalePercent = width / totalValue;

  useEffect(() => {
    if (
      !scaleWidth &&
      Object.values(selectedData).find((value) => value > 0) &&
      width > 0
    ) {
      setScaleWidth(
        (prev) =>
          prev < widthScalePercent && prev > 0 ? prev : widthScalePercent, // always preserve the lowest value to avoid the bar to be bigger than the container
      );
    }
  }, [widthScalePercent, setScaleWidth, scaleWidth, selectedData]);

  const {
    onMouseHoverHandler,
    onMouseLeaveHandler,
    onTouchEndHandler,
    onTouchStartHandler,
    getMarkerIcon,
  } = useChartHorizontalGroupedBar({ tooltipRef, valueFormat });

  return (
    <g>
      {Object.keys(selectedData).map((key, index) => {
        const value = selectedData[key];
        const color = selectedColor[key];

        const yValue = y + height / 2 - 5;
        const currentValueWidthInPX = scaleWidth * value;
        const heightValue = 9;
        const xValue = x + previousEndCoordinate;
        const barColor = color.color ?? CHART_COLORS[index];
        previousEndCoordinate += currentValueWidthInPX;

        return (
          <g
            key={key}
            onMouseEnter={(event) => {
              onMouseHoverHandler(event, 'enter', value, barColor, key);
            }}
            onMouseMove={(event) => {
              onMouseHoverHandler(event, 'move', value, barColor, key);
            }}
            onMouseLeave={onMouseLeaveHandler}
            onTouchStart={(event) => {
              onTouchStartHandler(event, 'enter', value, barColor, key);
            }}
            onTouchMove={(event) => {
              onTouchStartHandler(event, 'move', value, barColor, key);
            }}
            onTouchEnd={onTouchEndHandler}
          >
            <rect
              data-testid="chart-horizontal-grouped-bar"
              style={{ zIndex: 1000 + index }}
              key={key}
              x={xValue}
              y={yValue}
              width={currentValueWidthInPX - 1}
              height={heightValue}
              fill={barColor}
            />
            {color.dashed && (
              <>
                <defs>
                  <pattern
                    id="stripePattern"
                    width="10"
                    height="10"
                    patternUnits="userSpaceOnUse"
                    patternTransform="skewX(-30)"
                  >
                    <rect width="2.5" height="10" fill="white" />
                  </pattern>
                </defs>
                <rect
                  width={currentValueWidthInPX}
                  height={heightValue}
                  x={xValue}
                  y={yValue}
                  fill="url(#stripePattern)"
                />
              </>
            )}
          </g>
        );
      })}
      {selectedMarkers &&
        Object.keys(selectedMarkers).map((markerKey) => {
          const marker = selectedMarkers[markerKey];
          const markerXValue = scaleWidth * marker.value;
          const marketYValue = y + height / 2 - 14;
          return (
            <g
              key={markerKey}
              transform={`translate(${markerXValue - 16}, ${marketYValue})`}
              onMouseEnter={(event) => {
                onMouseHoverHandler(
                  event,
                  'enter',
                  marker.labelValue ?? marker.value,
                  '',
                  markerKey,
                );
              }}
              onMouseMove={(event) => {
                onMouseHoverHandler(
                  event,
                  'move',
                  marker.labelValue ?? marker.value,
                  '',
                  markerKey,
                );
              }}
              onMouseLeave={onMouseLeaveHandler}
              onTouchStart={(event) => {
                onTouchStartHandler(
                  event,
                  'enter',
                  marker.labelValue ?? marker.value,
                  '',
                  markerKey,
                );
              }}
              onTouchMove={(event) => {
                onTouchStartHandler(
                  event,
                  'move',
                  marker.labelValue ?? marker.value,
                  '',
                  markerKey,
                );
              }}
              onTouchEnd={onTouchEndHandler}
            >
              {getMarkerIcon(marker.type)}
            </g>
          );
        })}
      <ChartHorizontalBarLabel
        legendData={legendData}
        isLastBar={isLastBar}
        isFirstBar={isFirstBar}
        y={y}
        height={height}
      />
    </g>
  );
};

export default ChartHorizontalGroupedBar;
