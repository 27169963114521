import * as Styled from './chart-legend.style';

interface ChartLegendProps {
  label: string;
  value: string;
  color: string;
  percentage: string;
  onClick: () => void;
}

const ChartLegend = ({
  label,
  value,
  color,
  percentage,
  onClick,
}: ChartLegendProps) => (
  <Styled.Wrapper color={color} onClick={onClick}>
    <Styled.Percentage color={color}>{percentage}</Styled.Percentage>
    <Styled.Value>{value}</Styled.Value>
    <Styled.Label>{label}</Styled.Label>
  </Styled.Wrapper>
);

export { ChartLegend, type ChartLegendProps, ChartLegend as default };
