import { useDrawerForm } from '@refinedev/antd';
import { BaseKey, HttpError } from '@refinedev/core';
import { BankAccountIcon, FarmIcon, TitleIcon } from 'common/icons';
import {
  ListFiltersModel,
  RefineTableFiltersEnum,
  useRefineTableFilters,
} from 'common/refine-table-filters';
import { useTracking } from 'common/utils';
import { MouseEvent, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useFarm } from 'src/organization';

import {
  BankAccountModel,
  BankAccountRequestModel,
} from './bank-account.model';
import { useBankAccountCreateDrawer } from './use-bank-account-create-drawer.hook';

const useBankAccountList = () => {
  const { track } = useTracking();
  const { t } = useTranslation();
  const { currentFarm } = useFarm();

  const listFilters: ListFiltersModel[] = useMemo(
    () => [
      {
        label: t('bank_account.table.farm'),
        field: 'farm',
        type: RefineTableFiltersEnum.SELECT,
        icon: <FarmIcon />,
        resource: 'farm',
        initialValue: currentFarm?.name,
        show: true,
      },
      {
        label: t('bank_account.table.name'),
        field: 'name',
        icon: <TitleIcon />,
        type: RefineTableFiltersEnum.INPUT,
        show: true,
      },
      {
        label: t('bank_account.table.bank'),
        field: 'bankName',
        icon: <BankAccountIcon />,
        resource: 'account/bank',
        optionLabel: 'bankName',
        optionValue: 'bankName',
        type: RefineTableFiltersEnum.SELECT,
        show: true,
      },
    ],
    [],
  );

  const { tableProps, searchFormProps, tableQueryResult } =
    useRefineTableFilters<BankAccountModel, HttpError>({
      resource: 'account',
      listFilters,
      filters: {
        initial: [
          {
            field: 'accountType',
            operator: 'eq',
            value: ['Bank', 'Cash'],
          },
          {
            field: 'reportType',
            operator: 'eq',
            value: 'Balance Sheet',
          },
          {
            field: 'isGroup',
            operator: 'eq',
            value: 0,
          },
          {
            field: 'isDisabled',
            operator: 'eq',
            value: 0,
          },
        ],
      },
    });

  const editDrawer = useDrawerForm<
    BankAccountRequestModel,
    HttpError,
    BankAccountRequestModel
  >({
    action: 'edit',
    resource: 'account',
    redirect: false,
    syncWithLocation: {
      key: 'edit',
    },
  });

  const { createBankAccountDrawer, handleOpenBankAccountDrawer } =
    useBankAccountCreateDrawer('create');

  const handleOnClickEditBankAccount = (id: BaseKey) => {
    if (id) {
      track('Edit Invoice');
      editDrawer.show(id);
    }
  };

  const onRow = (data: BankAccountModel) => ({
    onClick: (event: MouseEvent<HTMLElement>) => {
      const target = event.target as HTMLElement;
      if (target.tagName !== 'TD') {
        event.stopPropagation();
        return;
      }

      handleOnClickEditBankAccount(data.id);
    },
  });

  return {
    listFilters,
    onRow,
    editDrawer,
    handleOnClickEditBankAccount,
    createBankAccountDrawer,
    handleOpenBankAccountDrawer,
    tableProps,
    searchFormProps,
    tableQueryResult,
  };
};

export { useBankAccountList as default, useBankAccountList };
