import { UseDrawerFormReturnType } from '@refinedev/antd';
import { HttpError } from '@refinedev/core';
import { Button, Form, Popconfirm } from 'antd';
import { DrawerFormSteps, DrawerWithButtons } from 'common/components';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { ItemCategoryFormContainer } from 'src/resources/item-category/form';

import { ItemModel } from '../item.model';
import { ItemTypeEnum } from '../item-type.enum';
import { useItemDrawer } from './item-drawer.hook';
import ItemForm from './item-form/item-form.component';

interface ItemDrawerProps {
  useDrawerForm: UseDrawerFormReturnType<ItemModel, HttpError, ItemModel>;
  setResourceCreate: Dispatch<SetStateAction<string>>;
  isEdit?: boolean;
  breadcrumbList?: string[];
}

const ItemDrawer = ({
  useDrawerForm,
  setResourceCreate,
  isEdit,
  breadcrumbList,
}: ItemDrawerProps) => {
  const { itemFms666ItemType } = useFlags();
  const { t } = useTranslation();

  const {
    id,
    breakpoint,
    drawerProps,
    mutationResult,
    formProps,
    formLoading,
    saveButtonProps,
    close,
    currentStep,
    steps,
    setCurrentStep,
    handleOnClickDelete,
    isLoadingDelete,
    handleOnFinish,
    createItemCategoryDrawer,
    handleOpenItemCategoryDrawer,
  } = useItemDrawer({
    useDrawerFormValue: useDrawerForm,
    setResourceCreate,
  });

  return (
    <>
      <DrawerFormSteps
        {...drawerProps}
        dataResult={mutationResult?.data?.data}
        hasDataResult
        title={t('item.titles.create')}
        isLoading={formLoading}
        width={breakpoint.sm ? '400px' : '100%'}
        currentStep={currentStep}
        setCurrentStep={setCurrentStep}
        onClose={() => {
          close();
        }}
        tagTrackClose="Item Creation"
        formProps={{
          ...formProps,
          layout: 'vertical',
          initialValues: {
            isActive: true,
            ...formProps.initialValues,
            allowNegativeStock:
              formProps.initialValues?.allowNegativeStock ?? true,
          },
          onFinish: (value) => handleOnFinish(value, formProps),
        }}
        steps={
          itemFms666ItemType
            ? steps
                .filter((step) =>
                  step.showOn.includes(
                    formProps.form.getFieldValue('itemType') ??
                      ItemTypeEnum.SEED,
                  ),
                )
                .map((value) => value.component)
            : [<ItemForm key="item_form" />]
        }
        breadcrumbList={breadcrumbList}
      >
        <ItemCategoryFormContainer
          itemCategoryDrawerForm={createItemCategoryDrawer}
          breadcrumbList={[...(breadcrumbList ?? []), t('item.breadcrumb')]}
        />
      </DrawerFormSteps>

      {isEdit && (
        <DrawerWithButtons
          hideSave
          moreButtons={[
            <Popconfirm
              key={'item-disable-confirmation'}
              title={t('item.disable.title')}
              description={t('item.disable.description')}
              onConfirm={() => handleOnClickDelete(id)}
              okText={t('buttons.yes')}
              cancelText={t('buttons.no')}
            >
              <Button danger loading={isLoadingDelete}>
                {t('buttons.disable')}
              </Button>
            </Popconfirm>,
          ]}
          title={t('item.titles.edit')}
          isLoadingChildren={formLoading}
          {...drawerProps}
          width={breakpoint.sm ? '400px' : '100%'}
          buttonSaveProps={saveButtonProps}
          onClose={() => {
            formProps.form.resetFields();
            close();
          }}
          tagTrackClose="Item Edition"
        >
          <Form
            {...formProps}
            layout="vertical"
            initialValues={{
              isActive: true,
              ...formProps.initialValues,
              allowNegativeStock:
                formProps.initialValues?.allowNegativeStock ?? true,
            }}
            onFinish={(value) => handleOnFinish(value, formProps)}
          >
            <ItemForm
              isEdit
              handleOpenItemCategoryDrawer={handleOpenItemCategoryDrawer}
            />
          </Form>
        </DrawerWithButtons>
      )}
    </>
  );
};

export { ItemDrawer };
