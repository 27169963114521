import { PageTitle } from 'common/components';
import { TabsProps } from 'common/components/page-title/page-title.component';
import { ROUTES } from 'common/constants';
import { useNavigateWithOrgAndFarm } from 'common/utils';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { ReactNode, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useOrganization } from 'src/organization';
import EntitlementEnum from 'src/organization/entitlements.enum';

import { FinancialEnum } from './financial.enum';

interface ResourcesTitleProps {
  buttons?: ReactNode;
  selectedTab: FinancialEnum;
}

const FinancialTitle = ({ buttons, selectedTab }: ResourcesTitleProps) => {
  const { financialProductionDisableMenuAccess } = useFlags();
  const { entitlements } = useOrganization();
  const { t } = useTranslation();
  const navigate = useNavigateWithOrgAndFarm();

  const handleGoToTab = useCallback(
    (route: string) => {
      navigate(route);
    },
    [navigate],
  );

  const tabs: TabsProps[] = useMemo(
    () => [
      {
        key: FinancialEnum.OVERVIEW,
        label: t('financial.overview.title') ?? '',
        onClick: () => handleGoToTab(ROUTES.FINANCIAL),
      },
      ...(!financialProductionDisableMenuAccess &&
      entitlements?.includes(EntitlementEnum.PRODUCTION_YIELD_DATA)
        ? [
            {
              id: FinancialEnum.PRODUCTION,
              key: FinancialEnum.PRODUCTION,
              label: t('financial.production') ?? '',
              onClick: () => handleGoToTab(ROUTES.FINANCIAL_PRODUCTION),
            },
          ]
        : []),
    ],
    [financialProductionDisableMenuAccess, handleGoToTab, t],
  );

  return (
    <PageTitle
      title={t('financial.title')}
      tabs={tabs}
      tabsDefaultActiveKey={selectedTab}
      buttons={buttons}
    />
  );
};

export { FinancialTitle };
