import { Layout } from 'antd';
import { Content } from 'antd/es/layout/layout';
import { useCallback, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { Outlet } from 'react-router-dom';
import { MediaQuery } from 'theme';

import FooterComponent from './footer/footer.component';
import SiderNavbarMobileComponent from './sider-navbar/sider-navbar-mobile.component';

const StructureTemplateMobile = () => {
  const isDesktop = useMediaQuery({ minWidth: MediaQuery.screenSizes.desktop });
  const [showNavbar, setShowNavbar] = useState<boolean>(false);

  const handleMenuClick = useCallback(() => {
    setShowNavbar((current) => !current);
  }, []);

  const handleHomeClick = useCallback(() => {
    setShowNavbar(false);
  }, []);

  const onFeatureClick = useCallback(() => {
    setShowNavbar(false);
  }, []);

  return (
    <Layout>
      <Layout>
        <Content>
          <Outlet />
        </Content>
        {!isDesktop && (
          <SiderNavbarMobileComponent
            isClosed={!showNavbar}
            onFeatureClick={onFeatureClick}
          />
        )}
      </Layout>
      {!isDesktop && (
        <FooterComponent
          isNavbarOpen={showNavbar}
          onMenuClick={handleMenuClick}
          onHomeClick={handleHomeClick}
        />
      )}
    </Layout>
  );
};

export { StructureTemplateMobile as default, StructureTemplateMobile };
