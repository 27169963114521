import styled from 'styled-components';

const DisabledContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  margin-top: 16px;
  margin-left: 24px;

  .fms-form-item {
    margin-bottom: 0;
  }
`;

export { DisabledContainer };
