import { Form } from 'antd';
import styled from 'styled-components';
import { NeutralColors } from 'theme';

const FormItem = styled(Form.Item)`
  background-color: ${NeutralColors[10]};
  border-radius: 12px;
  padding: 0px 12px;
  margin-bottom: 8px !important;

  .fms-col {
    flex: none !important;
    padding: 0px;
    max-width: calc(100vw - 172px) !important;
  }

  label {
    font-weight: 600;

    &::after {
      display: flex !important;
    }
  }

  svg {
    width: 20px;
    margin-right: 4px;
  }
`;

export { FormItem };
