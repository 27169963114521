import { Alert, Button as AntdButton } from 'antd';
import styled from 'styled-components';
import { BlueColors, NeutralColors } from 'theme';

interface BoxAmountProps {
  isSelected?: boolean;
}

const BoxButtons = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 16px;
  border-radius: 8px;
  background-color: ${NeutralColors[10]};
`;

const ButtonSelected = styled(AntdButton)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 48%;
  height: 40px;
`;

const BoxAmount = styled.div<BoxAmountProps>`
  border-radius: 4px;
  border: 1px solid
    ${(props) => (props.isSelected ? NeutralColors[0] : `${NeutralColors[70]}`)};
  padding: 0px 8px;
  margin-left: 8px;
`;

const DeleteInfoAlert = styled(Alert)`
  border-color: ${BlueColors[30]};
  background-color: ${BlueColors[10]};
  display: flex;
  align-items: center;
  margin-top: 25px;
  font-size: 16px;
  gap: 13px;
  border-radius: 5px;
`;

const Button = styled(AntdButton)`
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    display: inline-block;
    width: 18px;
  }
`;

export { BoxAmount, BoxButtons, Button, ButtonSelected, DeleteInfoAlert };
