import { Skeleton, Table } from 'antd';
import { CurrencyValue, EmptyState, PageTitle } from 'common/components';
import { DownloadIcon } from 'common/icons';
import { EmptyFilterImage } from 'common/images';
import { RefineTableFilters } from 'common/refine-table-filters';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

import { AgainstColumn } from './against-column/against-column.component';
import { useReportGeneralLedger } from './report-general-ledger.hook';
import { ReportGeneralLedgerModel } from './report-general-ledger.model';
import * as Styled from './report-general-ledger-list.style';
import {
  AccountTranslation,
  TranslationColumn,
  VoucherTypeTranslation,
} from './translation-column/translation-column.component';

const ReportGeneralLedgerContainer = () => {
  const { t } = useTranslation();

  const {
    searchFormProps,
    listFilters,
    tableProps,
    handleOnClickDownload,
    hasData,
    isFetching,
    isLoadingData,
    isEmpty,
    farmData,
    bankAccountHashmap,
  } = useReportGeneralLedger();

  return (
    <>
      <PageTitle
        title={t('report_general_ledger.title')}
        subTitle={t('report_general_ledger.sub_title') ?? ''}
        buttons={
          <Styled.ButtonDownload
            disabled={!hasData}
            type="primary"
            icon={<DownloadIcon />}
            onClick={handleOnClickDownload}
            loading={isFetching}
          >
            {t('report_general_ledger.download')}
          </Styled.ButtonDownload>
        }
      />
      <RefineTableFilters
        searchFormProps={searchFormProps}
        initialListFilters={listFilters}
      />
      {isLoadingData && <Skeleton active />}
      {isEmpty && (
        <EmptyState
          image={<EmptyFilterImage />}
          title={t('report_general_ledger.empty_state.title')}
          description={`${t('report_general_ledger.empty_state.description')}`}
        />
      )}
      {hasData && (
        <Table
          {...tableProps}
          pagination={false}
          rowKey="id"
          loading={isLoadingData}
        >
          <Table.Column
            dataIndex="postingDate"
            title={t('report_general_ledger.table.date')}
            render={(_, { postingDate }: ReportGeneralLedgerModel) =>
              postingDate && dayjs(postingDate).format('DD/MM/YYYY')
            }
            width={20}
          />
          <Table.Column
            dataIndex="account"
            title={t('report_general_ledger.table.account')}
            width={'25%'}
            render={(value) => (
              <TranslationColumn
                value={bankAccountHashmap.get(value)?.name ?? value}
                enumType={AccountTranslation}
              />
            )}
          />
          <Table.Column
            dataIndex="debit"
            title={t('report_general_ledger.table.debit')}
            width={160}
            render={(value) => (
              <CurrencyValue currency={farmData?.data.currency} value={value} />
            )}
          />
          <Table.Column
            dataIndex="credit"
            title={t('report_general_ledger.table.credit')}
            width={160}
            render={(value) => (
              <CurrencyValue currency={farmData?.data.currency} value={value} />
            )}
          />
          <Table.Column
            dataIndex="voucherType"
            title={t('report_general_ledger.table.voucher_type')}
            width={160}
            render={(value) => (
              <TranslationColumn
                value={value}
                enumType={VoucherTypeTranslation}
              />
            )}
          />
          <Table.Column
            dataIndex="against"
            title={t('report_general_ledger.table.against')}
            width={160}
            render={(value) => (
              <AgainstColumn
                value={value}
                bankAccountHashmap={bankAccountHashmap}
              />
            )}
          />
          <Table.Column
            dataIndex="project"
            title={t('report_general_ledger.table.field')}
            width={160}
          />
          <Table.Column
            dataIndex="costCenter"
            title={t('report_general_ledger.table.cost_center')}
            width={160}
          />
        </Table>
      )}
    </>
  );
};

export {
  ReportGeneralLedgerContainer as default,
  ReportGeneralLedgerContainer,
};
