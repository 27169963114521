import { Form, Input, Select, Switch } from 'antd';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { ItemCategoryFormDetailsTestId } from 'src/resources/item-category/item-category.constant';

import { useItemCategoryFormDetails } from './item-category-form-details.hook';
import * as Styled from './item-category-form-details.style';

interface ItemCategoryFormDetailsProps {
  show: boolean;
}

const ItemCategoryFormDetails: FC<ItemCategoryFormDetailsProps> = ({
  show,
}) => {
  const { t } = useTranslation();
  const { itemCategorySelectProps, isLoading } = useItemCategoryFormDetails();

  return (
    <Styled.Box show={show}>
      <Styled.FormItem
        label={t('item_category.form.details.name')}
        name={['name']}
        data-testid={
          ItemCategoryFormDetailsTestId.ITEM_CATEGORY_FORM_DETAILS_NAME
        }
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Input />
      </Styled.FormItem>
      <Styled.SwitchWrapper>
        {t('item_category.form.details.is_group')}
        <Form.Item
          name={['isGroup']}
          valuePropName={'checked'}
          data-testid={
            ItemCategoryFormDetailsTestId.ITEM_CATEGORY_FORM_DETAILS_IS_GROUP
          }
        >
          <Switch
            checkedChildren={t('buttons.yes')}
            unCheckedChildren={t('buttons.no')}
          />
        </Form.Item>
      </Styled.SwitchWrapper>
      <Styled.FormItem
        label={t('item_category.form.details.parent')}
        name={['parentId']}
        data-testid={
          ItemCategoryFormDetailsTestId.ITEM_CATEGORY_FORM_DETAILS_PARENT
        }
      >
        <Select {...itemCategorySelectProps} loading={isLoading} />
      </Styled.FormItem>
    </Styled.Box>
  );
};

export { ItemCategoryFormDetails as default, ItemCategoryFormDetails };
