import { CrudFilters, CrudSort } from '@refinedev/core';
import {
  createSearchParams,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';

import {
  convertFiltersToQueryString,
  convertSortersToQueryString,
} from './url.util';

const useNavigateWithOrgAndFarm = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const organizationId = searchParams.get('organizationId') ?? '';
  const farmId = searchParams.get('farmId') ?? '';

  return (
    pathname: string,
    org?: string | null,
    farm?: string | null,
    otherParams?: Record<string, string>,
    state?: any,
    filters?: CrudFilters,
    sorters?: CrudSort[],
  ) => {
    const urlParams = {
      organizationId: '',
      farmId: '',
      ...otherParams,
    };

    if (org !== null) {
      urlParams.organizationId = org ?? organizationId;
    }

    if (farm !== null) {
      urlParams.farmId = farm ?? farmId;
    }

    const filtersParamsUrl = convertFiltersToQueryString(filters);
    const sortersParamsUrl = convertSortersToQueryString(sorters);

    const searchParams = [
      filtersParamsUrl,
      sortersParamsUrl,
      createSearchParams(urlParams).toString(),
    ]
      .filter((url) => !!url)
      .join('&');

    navigate(
      {
        pathname,
        search: searchParams,
      },
      { state },
    );
  };
};

export { useNavigateWithOrgAndFarm };
