enum IsStockItemEnum {
  NO,
  YES,
}

enum IsGroupWarehouseEnum {
  NO,
  YES,
}

enum IsWarehouseDisabledEnum {
  NO,
  YES,
}

export { IsGroupWarehouseEnum, IsStockItemEnum, IsWarehouseDisabledEnum };
