import { InputHTMLAttributes, ReactNode, useRef } from 'react';

import * as Styled from './selectable-input.style';

interface SelectableInputProps extends InputHTMLAttributes<HTMLInputElement> {
  icon?: ReactNode;
  children?: ReactNode;
}

const SelectableInput = ({
  icon,
  title,
  children,
  checked,
  ...props
}: SelectableInputProps) => {
  const checkboxRef = useRef<HTMLInputElement>(null);

  const handleOnClick = () => {
    if (checkboxRef && checkboxRef?.current?.click) {
      checkboxRef?.current.click();
    }
  };

  return (
    <>
      <Styled.Input
        type="checkbox"
        id="checkbox-button"
        ref={checkboxRef}
        checked={checked}
        {...props}
      />
      <Styled.Label
        onClick={handleOnClick}
        type={checked ? 'primary' : 'default'}
      >
        {children}
      </Styled.Label>
    </>
  );
};

export { SelectableInput };
