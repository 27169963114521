import { GetOneResponse } from '@refinedev/core/dist/interfaces';
import { QueryObserverResult } from '@tanstack/react-query';
import {
  ButtonProps,
  DrawerProps,
  Form,
  FormInstance,
  FormProps,
  Grid,
  Popconfirm,
} from 'antd';
import { DrawerWithButtons } from 'common/components';
import { usePermissionsBalance } from 'common/hooks/use-permissions-balance.hook';
import { DeleteIcon } from 'common/icons';
import { ActionRbacEnum } from 'common/utils/auth-provider.util';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ItemDrawer } from 'src/resources/item';

import { MaterialConsumptionRequestModel } from '../material-consumption.model';
import { MaterialConsumptionFormTestId } from './material-consumption-form.constants';
import { useMaterialConsumptionForm } from './material-consumption-form.hook';
import { useMaterialConsumptionFormStore } from './material-consumption-form.store';
import * as Styled from './material-consumption-form.style';
import { MaterialConsumptionFormDetail } from './material-consumption-form-detail/material-consumption-form-detail.component';
import { MaterialConsumptionFormItems } from './material-consumption-form-items/material-consumption-form-items.component';
import { MaterialConsumptionHeader } from './material-consumption-header/material-consumption-header.component';

interface MaterialConsumptionFormProps {
  drawerProps: DrawerProps;
  formProps: FormProps;
  formInstance: FormInstance;
  onCreate: ButtonProps;
  onClose: () => void;
  queryResult?: QueryObserverResult<
    GetOneResponse<MaterialConsumptionRequestModel>
  >;
}

const MaterialConsumptionForm = ({
  drawerProps,
  formProps,
  formInstance,
  onCreate,
  onClose,
  queryResult,
}: MaterialConsumptionFormProps) => {
  const { t } = useTranslation();
  const { permissions } = usePermissionsBalance();
  const breakpoint = Grid.useBreakpoint();
  const {
    handleOnClose,
    handleOnFinish,
    handleOnDelete,
    disableItemsTab,
    initialValues,
    hasExternalId,
    isCanceled,
    createItemDrawer,
    setResourceCreateItem,
    handleOpenItemDrawer,
  } = useMaterialConsumptionForm(
    formProps,
    formInstance,
    onClose,
    drawerProps?.open,
  );
  const { showItems, setShowItems, disableSave } =
    useMaterialConsumptionFormStore();

  const isFormEnabled = useMemo(() => !queryResult, [queryResult]);

  const isLoading = useMemo(() => {
    return queryResult?.isLoading || queryResult?.isFetching;
  }, [queryResult?.isLoading, queryResult?.isFetching]);

  return (
    <DrawerWithButtons
      {...drawerProps}
      width={breakpoint.sm ? '400px' : '100%'}
      title={t('material_consumption_form.title')}
      onClose={handleOnClose}
      tagTrackClose="Material Consumption"
      isLoading={isLoading}
      isLoadingChildren={isLoading}
      destroyOnClose
      buttonSaveProps={{
        ...onCreate,
        onClick: showItems ? onCreate.onClick : () => setShowItems(true),
      }}
      disabledSave={showItems ? disableSave || !isFormEnabled : disableItemsTab}
      onBack={showItems ? () => setShowItems(false) : undefined}
      textSave={!showItems ? t('buttons.nextStep') ?? '' : undefined}
      moreButtons={
        initialValues.farm &&
        permissions?.includes(ActionRbacEnum.STOCK_BALANCE_WRITE) &&
        permissions?.includes(ActionRbacEnum.FINANCIAL_BALANCE_WRITE)
          ? [
              <Popconfirm
                key="button_delete"
                title={t('material_consumption.table.delete_title')}
                description={t(
                  hasExternalId
                    ? 'material_consumption.table.delete_description_external'
                    : 'material_consumption.table.delete_description',
                )}
                onConfirm={() => handleOnDelete(initialValues.id)}
                okText={t('buttons.yes')}
                cancelText={t('buttons.no')}
                disabled={isCanceled}
              >
                <Styled.DeleteButton
                  danger
                  icon={<DeleteIcon />}
                  disabled={isCanceled}
                  data-testid="delete-material-consumption"
                >
                  {t('buttons.cancel')}
                </Styled.DeleteButton>
              </Popconfirm>,
            ]
          : []
      }
    >
      <ItemDrawer
        useDrawerForm={createItemDrawer}
        setResourceCreate={setResourceCreateItem}
        breadcrumbList={[t('material_consumption_form.breadcrumb') ?? '']}
      />
      <Form
        {...(isFormEnabled ? formProps : {})}
        data-testid={MaterialConsumptionFormTestId.MATERIAL_CONSUMPTION_FORM}
        layout="vertical"
        initialValues={initialValues}
        onFinish={handleOnFinish}
        disabled={!isFormEnabled}
      >
        <MaterialConsumptionHeader />
        <MaterialConsumptionFormDetail
          drawerOpen={drawerProps?.open ?? false}
        />
        <MaterialConsumptionFormItems
          handleOpenItemDrawer={handleOpenItemDrawer}
        />
      </Form>
    </DrawerWithButtons>
  );
};

export { MaterialConsumptionForm };
