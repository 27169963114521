import { PlusOutlined } from '@ant-design/icons';
import { Button, Form, Switch } from 'antd';
import { useTranslation } from 'react-i18next';

import { PurchaseCreateItemTestId } from '../../purchase-test-id.constants';
import { usePurchaseCreateStore } from '../purchase-create.store';
import { PurchaseCreateItem } from './purchase-create-item/purchase-create-item.component';
import * as Styled from './purchase-create-items.style';

interface PurchaseCreateItemsProps {
  isReadonly?: boolean;
  handleOpenItemDrawer: () => void;
}

export const PurchaseCreateItems = ({
  isReadonly,
  handleOpenItemDrawer,
}: PurchaseCreateItemsProps) => {
  const { t } = useTranslation();

  const { showItems } = usePurchaseCreateStore();

  return (
    <>
      <Styled.GreenBox isShow={showItems && !isReadonly}>
        <Styled.SwitchBox>
          <span>{t('purchase_create.items.is_received')}</span>
          <Styled.SwitchForm name={['isReceived']} valuePropName={'checked'}>
            <Switch
              data-testid={
                PurchaseCreateItemTestId.PURCHASE_CREATE_ISPAID_SWITCHBOX
              }
              checkedChildren={t('buttons.yes')}
              unCheckedChildren={t('buttons.no')}
            />
          </Styled.SwitchForm>
        </Styled.SwitchBox>
      </Styled.GreenBox>
      <Styled.Container isShow={showItems}>
        <Form.List
          name="items"
          rules={[
            {
              validator: (_, names) => {
                if (!names || names.length < 1) {
                  return Promise.reject(
                    new Error(
                      t('invoice.form.items.error_message_empty_items') ?? '',
                    ),
                  );
                }
                return Promise.resolve();
              },
            },
          ]}
        >
          {(items, { add, remove }) => (
            <>
              {items.map((item, index) => (
                <PurchaseCreateItem
                  itemFieldData={item}
                  key={item.key}
                  removeItem={() => remove(index)}
                  index={index}
                  amountItems={items.length}
                  isReadonly={isReadonly}
                  handleOpenItemDrawer={handleOpenItemDrawer}
                />
              ))}
              {!isReadonly && (
                <Styled.ContainerButton>
                  <Styled.FormItem>
                    <Button
                      type="dashed"
                      onClick={() => {
                        add();
                      }}
                      block
                      data-testid={
                        PurchaseCreateItemTestId.PURCHASE_CREATE_ITEMS_BUTTON
                      }
                      icon={<PlusOutlined />}
                    >
                      {t('purchase_create.items.insert_item')}
                    </Button>
                  </Styled.FormItem>
                </Styled.ContainerButton>
              )}
            </>
          )}
        </Form.List>
      </Styled.Container>
    </>
  );
};
