import { Typography } from 'antd';
import styled from 'styled-components';
import { NeutralColors } from 'theme';

interface ItemProps {
  width: string;
}

const Container = styled.div`
  position: relative;
  width: 40%;
  background-color: ${NeutralColors[10]};
  border-radius: 12px;
  padding: 16px;
  margin-bottom: 16px;
  border: 1px solid ${NeutralColors[20]};
`;

const ContainerTitle = styled.div`
  display: flex;
  align-items: center;
  padding: 8px 0;

  svg {
    fill: ${NeutralColors[70]} !important;
    margin-right: 4px;
  }
`;

const TextTitle = styled(Typography.Paragraph)`
  margin: 0 !important;
  color: ${NeutralColors[70]};
`;

const ContainerItems = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const LineItem = styled.div`
  display: flex;
  gap: 8px;
`;

const Item = styled(Typography.Paragraph)<ItemProps>`
  display: flex;
  align-items: center;
  margin: 0 !important;
  padding: 4px;
  border: 1px solid ${NeutralColors[20]};
  color: ${NeutralColors[70]};
  height: 52px;
  width: ${(props) => props.width};
  border-radius: 8px;

  overflow: hidden;
  text-overflow: ellipsis;
`;

const Arrow = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  border: 1px solid ${NeutralColors[20]};
  border-radius: 16px;
  right: -20px;
  position: absolute;
  top: 50%;
  background-color: ${NeutralColors[10]};

  svg {
    fill: ${NeutralColors[20]};
  }
`;

export {
  Arrow,
  Container,
  ContainerItems,
  ContainerTitle,
  Item,
  LineItem,
  TextTitle,
};
