import { useOne } from '@refinedev/core';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useTranslation } from 'react-i18next';
import {
  DashboardRequest,
  SavedTimeChartReport,
  SavedTimeReport,
} from 'src/dashboard/dashboard.interface';
import { useOrganization } from 'src/organization';

const useTimeSaving = (farm?: string) => {
  const { t } = useTranslation();
  const { currentOrganizationId } = useOrganization();
  const { landingChartSavedLines, landingSavedLines } = useFlags();
  const { data: dataChart, isFetching: isFetchingChart } = useOne<
    DashboardRequest<SavedTimeChartReport>
  >({
    resource: 'report',
    id: '',
    meta: {
      organizationId: currentOrganizationId,
      params: {
        reportName: landingChartSavedLines,
        farm,
      },
    },
  });

  const { data: dataReport, isFetching: isFetchingReport } = useOne<
    DashboardRequest<SavedTimeReport>
  >({
    resource: 'report',
    id: '',
    meta: {
      organizationId: currentOrganizationId,
      params: {
        reportName: landingSavedLines,
        farm,
      },
    },
  });

  const valuesChart = dataChart?.data?.result;
  const amountLines = dataReport?.data?.result?.[0].value;
  const hasChart = amountLines && amountLines !== null && amountLines > 0;

  const line1Chart = valuesChart?.map((item) => ({
    x: item.monthYear,
    y: item.totalSpreadsheetRows,
  }));

  const line2Chart = valuesChart?.map((item) => ({
    x: item.monthYear,
    y: item.totalRecords,
  }));

  const dataChartLine = [
    {
      id: t('dashboard.releases'),
      data: line2Chart,
    },
    {
      id: t('dashboard.spreadsheet_rows'),
      data: line1Chart,
    },
  ];

  return {
    dataChartLine,
    amountLines,
    hasChart,
    isFetching: isFetchingChart || isFetchingReport,
  };
};

export { useTimeSaving };
