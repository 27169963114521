import { FormListFieldData, InputNumber, Skeleton } from 'antd';
import { InputMoney, SelectWithButton } from 'common/components';
import { DeleteIcon } from 'common/icons';
import { getCurrencySymbol } from 'common/utils';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import useInvoiceFormItem from './invoice-form-item.hook';
import * as Styled from './invoice-form-item.style';

interface InvoiceFormItemProps {
  item: FormListFieldData;
  removeItem: () => void;
  index: number;
  amountItems: number;
  farm?: string;
  handleOpenItemDrawer: () => void;
}

const InvoiceFormItem: FC<InvoiceFormItemProps> = ({
  item,
  removeItem,
  index,
  amountItems,
  handleOpenItemDrawer,
}) => {
  const { t } = useTranslation();
  const {
    currency,
    isOpen,
    setIsOpen,
    itemSelectProps,
    isLoadingItemSelect,
    isFetchingItemData,
    itemData,
    itemRate,
    itemQuantity,
  } = useInvoiceFormItem({
    item,
    amountItems,
    index,
  });

  if (!itemData?.data) {
    return (
      <Styled.Box>
        <Styled.FormItem
          label={t('invoice.form.items.item_name')}
          name={[item.name, 'itemCode']}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <SelectWithButton
            {...itemSelectProps}
            loading={isLoadingItemSelect}
            placeholder={t('common.business.item_placeholder')}
            buttonProps={{
              onClick: handleOpenItemDrawer,
              children: t('common.create_new'),
            }}
          />
        </Styled.FormItem>
        {isFetchingItemData && <Skeleton active />}
      </Styled.Box>
    );
  }

  return (
    <Styled.Box>
      <Styled.ContainerTitle isOpen={isOpen} onClick={() => setIsOpen(!isOpen)}>
        <Styled.Flex>
          <Styled.Chevron isOpen={isOpen} />
          <strong>{itemData.data.name}</strong>
        </Styled.Flex>
        <Styled.ContainerCenter>
          <strong>{`${itemQuantity ?? 0} ${itemData.data?.uom}`}</strong>
          <Styled.TrashButton onClick={removeItem}>
            <DeleteIcon />
          </Styled.TrashButton>
        </Styled.ContainerCenter>
      </Styled.ContainerTitle>
      {isOpen && (
        <>
          <Styled.FormItem
            label={t('invoice.form.items.amount')}
            name={[item.name, 'qty']}
            rules={[
              {
                required: true,
              },
            ]}
          >
            <InputNumber
              addonAfter={itemData.data?.uom}
              decimalSeparator={','}
            />
          </Styled.FormItem>
          <Styled.ContainerValues>
            <Styled.FormItemValue
              label={t('invoice.form.items.unit_value')}
              name={[item.name, 'rate']}
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <InputMoney
                addonBefore={getCurrencySymbol(currency)}
                maxLength={12}
              />
            </Styled.FormItemValue>
            <Styled.FormItemValue label={t('invoice.form.items.total_value')}>
              <InputMoney
                disabled
                addonBefore={getCurrencySymbol(currency)}
                value={(itemQuantity ?? 0) * (itemRate ?? 0)}
              />
            </Styled.FormItemValue>
          </Styled.ContainerValues>
        </>
      )}
    </Styled.Box>
  );
};

export { InvoiceFormItem as default, InvoiceFormItem };
