enum AgOperationsTypeEnum {
  'CP_TREATMENT' = 'CP_TREATMENT',
  'HARVESTING' = 'HARVESTING',
  'IRRIGATION' = 'IRRIGATION',
  'LAND_PREPARATION' = 'LAND_PREPARATION',
  'MAINTENANCE' = 'MAINTENANCE',
  'PLANTING' = 'PLANTING',
  'SAMPLING' = 'SAMPLING',
  'SCOUTING' = 'SCOUTING',
  'SPRAYING' = 'SPRAYING',
  'TRANSPORTATION' = 'TRANSPORTATION',
  'TRAPS_SCOUTING' = 'TRAPS_SCOUTING',
  'DECREASE' = 'DECREASE',
}

export { AgOperationsTypeEnum };
