import { CollapseProps as CollapsePropsAntd } from 'antd';

import * as Styled from './collapse.style';

type CollapseProps = CollapsePropsAntd;

const Collapse = ({ ...props }: CollapseProps) => {
  return <Styled.CollapseItem {...props} />;
};

export { Collapse };
