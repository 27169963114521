import { FormListFieldData } from 'antd';
import { useWatch } from 'antd/es/form/Form';
import { getCurrencyValue } from 'common/utils/currency.util';
import { useTranslation } from 'react-i18next';
import { useReferenceValueStore } from 'src/financial/reference-value/reference-value.store';
import { useFarm } from 'src/organization';

import { useObjectiveFormStore } from '../../objective-form.store';

interface ObjectiveFormItemProps {
  item: FormListFieldData;
  objectiveType: 'productivity' | 'cost';
  farm?: string;
}

const useObjectiveFormItem = ({
  item,
  objectiveType,
  farm,
}: ObjectiveFormItemProps) => {
  const { i18n } = useTranslation();
  const { getCurrencyByFarm } = useFarm();
  const { projectByName, currentValuesByName } = useObjectiveFormStore();
  const { finalProduct } = useReferenceValueStore();
  const projectName = useWatch(['items', item.name, 'projectName']);
  const objectiveValue = useWatch(['items', item.name, objectiveType]);
  const isFiltered = useWatch([
    'items',
    item.name,
    objectiveType === 'productivity' ? 'showProductivity' : 'showCost',
  ]);
  const project = projectByName[projectName];
  const currentValuesByType = currentValuesByName[projectName];
  const currentValue = currentValuesByType?.[objectiveType] ?? 0;

  const formatExtraValue = (value: number) => {
    if (objectiveType === 'productivity') {
      return `${value.toFixed(2)} ${finalProduct?.uom ?? ''}`;
    }

    return getCurrencyValue(
      value,
      getCurrencyByFarm(farm ?? ''),
      i18n.language,
    );
  };

  return {
    isFiltered,
    project,
    currentValue,
    objectiveValue,
    formatExtraValue,
    uom: finalProduct?.uom ?? '',
  };
};

export { useObjectiveFormItem };
