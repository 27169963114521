import { Button, Drawer } from 'antd';
import { BaseButtonProps } from 'antd/es/button/button';
import { Footer } from 'antd/es/layout/layout';
import styled from 'styled-components';
import { GreenColors, NeutralColors } from 'theme';

interface HomeButtonType extends BaseButtonProps {
  active?: boolean;
}

interface MenuButtonType {
  active?: boolean;
}

const FooterLayout = styled(Footer)`
  position: fixed;
  bottom: 0;
  left: 0;
  height: 52px;
  border-top: 1px solid ${NeutralColors[20]};
  padding: 0;
  display: grid;
  grid-template-columns: 2fr 1fr 2fr;
  justify-items: center;
  z-index: 1001;
  width: 100vw;

  .fms-btn-text {
    border-radius: 0 !important;
    width: 100%;
    height: 100%;
    padding: 0 !important;
    text-align: center;
    font-size: 10px !important;

    &,
    &:hover,
    &:active {
      background-color: transparent !important;
    }

    svg {
      margin: 0 auto;
      display: block;
      height: 24px;
      width: 24px;
      fill: currentColor;
    }
  }
`;

const HomeButton = styled(Button)<HomeButtonType>`
  color: ${({ active }) =>
    active ? GreenColors[50] : NeutralColors[80]} !important;
  display: flex;
  flex-direction: column;
  gap: 0;
`;

const QuickActionsButton = styled(Button)`
  height: 52px !important;
  width: 52px;
  transform: translateY(-11px);
  box-shadow: none;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${NeutralColors[0]} !important;

  svg {
    height: 24px;
    width: 24px;
    fill: currentColor;
  }
`;

const MenuButton = styled(Button)<MenuButtonType>`
  color: ${({ active }) =>
    active ? GreenColors[50] : NeutralColors[80]} !important;

  display: flex;
  flex-direction: column;
  gap: 0;
`;

const QuickActionsDrawer = styled(Drawer)`
  display: flex;
  border-radius: 12px 12px 0 0 !important;

  .fms-drawer-header {
    border: none;
  }

  .fms-drawer-body {
    display: flex;
    flex-wrap: wrap;
    padding: 12px 12px 60px 12px;
  }
`;

export {
  FooterLayout,
  HomeButton,
  MenuButton,
  QuickActionsButton,
  QuickActionsDrawer,
};
