import { Typography } from 'antd';
import { ChevronRightIcon } from 'common/icons';
import { NoPermissionImage } from 'common/images';
import { useTranslation } from 'react-i18next';

import * as Styled from './no-permission-balance.style';

const NoPermissionBalance = () => {
  const { t } = useTranslation();

  const handleGoToCropwise = () => {
    window.location.href = import.meta.env.VITE_ACCOUNTS_URL ?? '';
  };

  return (
    <Styled.Container>
      <NoPermissionImage />
      <Styled.Title level={3}>{t('no_permission_balance.title')}</Styled.Title>
      <Typography.Paragraph>
        {t('no_permission_balance.description')}
      </Typography.Paragraph>
      <Styled.ButtonCropwise onClick={handleGoToCropwise}>
        {t('no_permission_balance.button_text')}
        <ChevronRightIcon />
      </Styled.ButtonCropwise>
    </Styled.Container>
  );
};

export { NoPermissionBalance };
