import { Form, InputNumber, Skeleton } from 'antd';
import { NoItemsFoundImage } from 'common/images';
import { getCurrencySymbol } from 'common/utils';
import dayjs from 'dayjs';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import NoFinalProduct from 'src/financial/no-final-product/no-final-product.component';
import { useFarm } from 'src/organization';

import { useReferenceValueFormItem } from './reference-value-form-item.hook';
import * as Styled from './reference-value-form-item.style';

interface ReferenceValueFormItemProps {
  farm?: string;
  isOpenDrawer?: boolean;
}

const ReferenceValueFormItem: FC<ReferenceValueFormItemProps> = ({
  farm,
  isOpenDrawer,
}) => {
  const { t } = useTranslation();
  const { getCurrencyByFarm } = useFarm();

  const { finalProduct, cropId, finalProductIsFetching, refetchFinalProduct } =
    useReferenceValueFormItem({
      isOpenDrawer,
    });

  if (finalProductIsFetching) {
    return (
      <Styled.ContainerEmpty>
        <Skeleton active />
      </Styled.ContainerEmpty>
    );
  }

  if (!cropId && !finalProductIsFetching) {
    return (
      <Styled.ContainerEmpty>
        <NoItemsFoundImage />
        <Styled.EmptyDescription>
          {t('financial_production.insert_production.select_season')}
        </Styled.EmptyDescription>
      </Styled.ContainerEmpty>
    );
  }

  return finalProduct ? (
    <>
      <Form.Item
        label={finalProduct.itemName}
        name="referenceValue"
        rules={[
          {
            required: true,
            message: t(
              'financial.reference_value.form.reference_value_required',
            ) as string,
          },
        ]}
        extra={t('financial.reference_value.form.last_updade_in', {
          date: dayjs(finalProduct.updateAt).format('DD/MM/YYYY HH:mm'),
        })}
      >
        <InputNumber
          precision={2}
          decimalSeparator=","
          addonBefore={getCurrencySymbol(getCurrencyByFarm(farm ?? ''))}
        />
      </Form.Item>
      <Form.Item name="productId" hidden={true} />
    </>
  ) : (
    <NoFinalProduct refetchFinalProduct={refetchFinalProduct} />
  );
};

export { ReferenceValueFormItem as default, ReferenceValueFormItem };
