import { useSelect } from '@refinedev/antd';
import { useMemo } from 'react';
import { useOrganization } from 'src/organization';

import { IsStockItemEnum } from '../../../warehouse-list/warehouse-list.constant';

const useOpeningStockItems = () => {
  const { currentOrganizationId, isLoading: isOrganizationLoading } =
    useOrganization();

  const metadata = useMemo(
    () => ({
      organizationId: currentOrganizationId,
    }),
    [currentOrganizationId],
  );

  const loadItemsList = useMemo(
    () => !!currentOrganizationId && !isOrganizationLoading,
    [currentOrganizationId, isOrganizationLoading],
  );

  const { selectProps: itemSelectProps } = useSelect({
    resource: 'item',
    optionLabel: 'name',
    onSearch: (value) => [
      {
        field: 'name',
        operator: 'eq',
        value: value,
      },
    ],
    filters: [
      {
        field: 'isStockItem',
        operator: 'eq',
        value: IsStockItemEnum.YES,
      },
    ],
    meta: metadata,
    queryOptions: {
      enabled: loadItemsList,
    },
  });

  return {
    itemSelectProps,
  };
};

export { useOpeningStockItems as default, useOpeningStockItems };
