enum AccountsPayableStatusEnum {
  PAID = '1',
  PARTIALLY_PAID = '2',
  TO_PAY = '3',
  OVERDUE = '4',
}

enum AccountsPayableEnum {
  PURCHASE_ORDER = 'Purchase Order',
  PURCHASE_INVOICE = 'Purchase Invoice',
}

export { AccountsPayableEnum, AccountsPayableStatusEnum };
