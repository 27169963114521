import { Typography } from 'antd';
import styled from 'styled-components';

const Value = styled(Typography)<{ bold?: boolean }>`
  font-size: 20px;
  font-weight: ${({ bold }) => (bold ? 600 : 400)};
  white-space: nowrap;
`;

export { Value };
