interface ChartDataModel {
  isAdjust?: boolean;
  id: string;
  value: number;
}

interface ChartBarLineDataModel {
  label: string;
  value: number;
  color?: string;
  dashed?: boolean;
}

interface ChartBarLineMarkerDataModel {
  label: string;
  value: number;
  type: MarkerType;
  labelValue?: number;
}

interface ChartBarGroupDataModel {
  markers?: ChartBarLineMarkerDataModel[];
  values?: ChartBarLineDataModel[];
}

interface ChartBarDataModel {
  label: string;
  moreLabel?: string;
  lineInfo?: string;
  groups: Record<string, ChartBarGroupDataModel>;
}

interface DetailedData {
  [key: string]: Record<string, number>;
}

interface ColorPerLabel {
  [groupKey: string]: {
    [barKey: string]: {
      [labelKey: string]: {
        color?: string;
        dashed?: boolean;
      };
    };
  };
}

enum MarkerType {
  DIAMOND = 'diamond',
  DIAMOND_OUTLINE = 'diamond-outline',
  HOURGLASS = 'hourglass',
}

interface MarkersPerLabel {
  [groupKey: string]: {
    [barKey: string]: {
      [markerKey: string]: {
        type: MarkerType;
        value: number;
        labelValue?: number;
      };
    };
  };
}

interface LegendData {
  label: string;
  moreLabel: string;
  lineInfo: string;
}

interface LegendDataPerGroup {
  [groupKey: string]: LegendData;
}

export {
  ChartBarDataModel,
  ChartBarLineDataModel,
  ChartDataModel,
  ColorPerLabel,
  DetailedData,
  LegendData,
  LegendDataPerGroup,
  MarkersPerLabel,
  MarkerType,
};
