import { Table } from 'antd';
import Column from 'antd/es/table/Column';
import { useTranslation } from 'react-i18next';
import { GreenColors, RedColors } from 'theme';

import * as Styled from './example-children.style';

const ExampleChildren = () => {
  const { t } = useTranslation();

  const dataSource = [
    {
      key: '1',
      date: t('header.migration.example.table.day', { numberDay: 1 }),
      inOut: t('header.migration.example.table.in'),
      protector: 100,
      balance: 100,
    },
    {
      key: '2',
      date: t('header.migration.example.table.day', { numberDay: 2 }),
      inOut: t('header.migration.example.table.out'),
      protector: -200,
      balance: -200,
    },
    {
      key: '3',
      date: t('header.migration.example.table.day', { numberDay: 3 }),
      inOut: t('header.migration.example.table.in'),
      protector: 100,
      balance: 100,
    },
    {
      key: '4',
      inOut: t('common.total'),
      protector: '100',
      balance: '0',
    },
  ];

  return (
    <div>
      <Styled.Ul>
        <li>{t('header.migration.example.topic_1')}</li>
        <li>{t('header.migration.example.topic_2')}</li>
        <li>{t('header.migration.example.topic_3')}</li>
      </Styled.Ul>
      <Table dataSource={dataSource} pagination={false}>
        <Column
          title={t('header.migration.example.table.date')}
          render={({ date }) => <Styled.Paragraph>{date}</Styled.Paragraph>}
        />
        <Column
          render={({ inOut }) => <Styled.Paragraph>{inOut}</Styled.Paragraph>}
        />
        <Column
          title="Protector"
          render={({ protector }) => (
            <Styled.Paragraph
              color={protector < 0 ? RedColors[60] : GreenColors[60]}
            >
              {protector} L
            </Styled.Paragraph>
          )}
        />
        <Column
          title={t('header.migration.example.table.amount_balance')}
          render={({ balance }) => (
            <Styled.Paragraph
              color={balance < 0 ? RedColors[60] : GreenColors[60]}
            >
              {balance} L
            </Styled.Paragraph>
          )}
        />
      </Table>
    </div>
  );
};

export { ExampleChildren };
