import { Form } from 'antd';
import { useWatch } from 'antd/es/form/Form';
import useFormInstance from 'antd/es/form/hooks/useFormInstance';
import { AllSelectIcon, CheckIcon, WalletBlueColoredIcon } from 'common/icons';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ElectronicInvoiceStepEnum } from 'src/electronic-invoice/electronic-invoice.enum';
import { ElectronicInvoicesWithOrderRequest } from 'src/electronic-invoice/electronic-invoice.model';
import { PurchaseModel } from 'src/purchase/purchase.model';

import * as Styled from './electronic-invoice-received-paid.style';
import { ReceivedPaidSelect } from './received-paid-select/received-paid-select.component';
import { ReceivedPaidWarehouse } from './received-paid-warehouse/received-paid-warehouse.component';

interface ElectronicInvoiceReceivedPaidProps {
  currentStep: ElectronicInvoiceStepEnum;
  setCurrentStep: (x: ElectronicInvoiceStepEnum) => void;
  setIsLoading: (b: boolean) => void;
  isLoading: boolean;
  purchaseOrder?: PurchaseModel;
}

const ElectronicInvoiceReceivedPaid = ({
  currentStep,
  isLoading,
  purchaseOrder,
  setCurrentStep,
  setIsLoading,
}: ElectronicInvoiceReceivedPaidProps) => {
  const { t } = useTranslation();
  const allData = useWatch<ElectronicInvoicesWithOrderRequest>([]);
  const warehouse = useWatch(['warehouse']);
  const form = useFormInstance();

  const handleSelectAllPaid = () => {
    allData.electronicInvoices.forEach((_, index) => {
      form.setFieldValue(['electronicInvoices', index, 'isPaid'], true);
    });
  };

  const amountPaid = useMemo(
    () =>
      allData?.electronicInvoices?.filter(
        (electronicInvoice) => electronicInvoice.isPaid,
      )?.length ?? 0,
    [allData],
  );

  const amountReceived = useMemo(
    () =>
      allData?.electronicInvoices?.filter(
        (electronicInvoice) => electronicInvoice.isReceived,
      )?.length ?? 0,
    [allData],
  );

  const isCurrentStep = useMemo(
    () => currentStep === ElectronicInvoiceStepEnum.PAID_RECEIVED && !isLoading,
    [currentStep, isLoading],
  );

  const handleGoToConfirm = () => {
    setIsLoading(true);

    setTimeout(() => {
      setIsLoading(false);
      setCurrentStep(ElectronicInvoiceStepEnum.CONFIRM);
    }, 1000);
  };

  const handleReturnCurrentStep = () => {
    setCurrentStep(ElectronicInvoiceStepEnum.PAID_RECEIVED);
  };

  return (
    <>
      <Styled.ContainerQuestion isCurrentStep={isCurrentStep}>
        <WalletBlueColoredIcon />
        <Styled.BoxQuestion
          isCurrentStep={isCurrentStep}
          onClick={handleReturnCurrentStep}
        >
          {isCurrentStep ? (
            <Styled.TitleQuestion level={4}>
              {t('electronic_invoice.received_paid.question')}
            </Styled.TitleQuestion>
          ) : (
            <div>
              {t('electronic_invoice.received_paid.finished_text', {
                amountPaid,
                amountReceived,
              })}
            </div>
          )}
        </Styled.BoxQuestion>
      </Styled.ContainerQuestion>
      <Styled.ContainerShow show={isCurrentStep}>
        <Styled.ContainerOptions>
          <ReceivedPaidWarehouse purchaseOrder={purchaseOrder} />
          <Form.List name="electronicInvoices">
            {(electronicInvoices) => (
              <>
                {electronicInvoices.map((electronicInvoice) => (
                  <ReceivedPaidSelect
                    key={electronicInvoice.key}
                    itemFieldData={electronicInvoice}
                  />
                ))}
              </>
            )}
          </Form.List>
        </Styled.ContainerOptions>
        <Styled.ContainerButtons>
          <Styled.Button type="dashed" onClick={handleSelectAllPaid}>
            <AllSelectIcon />
            {t('electronic_invoice.received_paid.select_all')}
          </Styled.Button>
          <Styled.Button
            type="primary"
            onClick={handleGoToConfirm}
            disabled={!warehouse}
          >
            {t('buttons.confirm')}
            <CheckIcon />
          </Styled.Button>
        </Styled.ContainerButtons>
      </Styled.ContainerShow>
    </>
  );
};

export { ElectronicInvoiceReceivedPaid };
