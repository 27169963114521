import { FormListFieldData } from 'antd';
import { useWatch } from 'antd/es/form/Form';
import { CurrencyValue } from 'common/components';
import { SelectableInput } from 'common/components/selectable-input';
import { ElectronicInvoiceIcon } from 'common/icons';
import { useTracking } from 'common/utils';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { ElectronicInvoicesRequest } from 'src/electronic-invoice/electronic-invoice.model';
import { generateExternalIdMask } from 'src/electronic-invoice/electronic-invoice.util';

import * as Styled from './received-paid-select.style';

interface ReceivedPaidSelectProps {
  itemFieldData: FormListFieldData;
}

const ReceivedPaidSelect = ({ itemFieldData }: ReceivedPaidSelectProps) => {
  const { track } = useTracking();
  const { t } = useTranslation();
  const electronicInvoice: ElectronicInvoicesRequest = useWatch([
    'electronicInvoices',
    itemFieldData.name,
  ]);

  if (!electronicInvoice) {
    return null;
  }

  const handleOnClickIsPaid = () => {
    track('Electronic Invoice - Click Paid Button');
  };

  const handleOnClickIsReceived = () => {
    track('Electronic Invoice - Click Received Button');
  };

  return (
    <Styled.Container>
      <Styled.Text>
        <ElectronicInvoiceIcon />
        <div>
          {dayjs(
            `${electronicInvoice.issuedDate}T${electronicInvoice.issuedDateTime}`,
          ).format('D MMM YYYY')}
        </div>
        <CurrencyValue value={electronicInvoice.totalValue} />
        <span>{generateExternalIdMask(electronicInvoice.externalId)}</span>
      </Styled.Text>
      <Styled.ContainerButtons>
        <Styled.FormItem
          name={[itemFieldData.name, 'isPaid']}
          valuePropName={'checked'}
        >
          <SelectableInput onClick={handleOnClickIsPaid}>
            {t('electronic_invoice.received_paid.paid')}
          </SelectableInput>
        </Styled.FormItem>
        <Styled.FormItem
          name={[itemFieldData.name, 'isReceived']}
          valuePropName={'checked'}
        >
          <SelectableInput onClick={handleOnClickIsReceived}>
            {t('electronic_invoice.received_paid.received')}
          </SelectableInput>
        </Styled.FormItem>
      </Styled.ContainerButtons>
    </Styled.Container>
  );
};

export { ReceivedPaidSelect };
