import { DeleteOutlined } from '@ant-design/icons';
import { UseDrawerFormReturnType } from '@refinedev/antd';
import { BaseRecord, HttpError } from '@refinedev/core';
import { Button, Divider, Form, Grid, Popconfirm, Skeleton } from 'antd';
import { DrawerWithButtons } from 'common/components';
import { useTranslation } from 'react-i18next';
import { MaterialConsumptionRequestModel } from 'src/material-consumption/material-consumption.model';

import { useInsertProduction } from './insert-production.hook';
import { InsertProductionDetail } from './insert-production-detail/insert-production-detail.component';
import { InsertProductionItem } from './insert-production-item/insert-production-item.component';

interface InsertProductionProps {
  drawerForm: UseDrawerFormReturnType<
    BaseRecord,
    HttpError,
    MaterialConsumptionRequestModel
  >;
  farm?: string[];
  seasonId?: string;
  project?: string;
}

const InsertProduction = ({
  drawerForm,
  farm,
  seasonId,
  project,
}: InsertProductionProps) => {
  const { t } = useTranslation();
  const breakpoint = Grid.useBreakpoint();
  const {
    initialValues,
    isCanceled,
    isEdit,
    isLoading,
    handleOnDelete,
    onFinish,
  } = useInsertProduction(drawerForm, farm, seasonId, project);

  return (
    <DrawerWithButtons
      {...drawerForm.drawerProps}
      buttonSaveProps={drawerForm.saveButtonProps}
      width={breakpoint.sm ? '400px' : '100%'}
      title={t('financial_production.empty_production.title')}
      onClose={drawerForm.close}
      isLoading={drawerForm.formLoading}
      disabledSave={isEdit}
      moreButtons={
        isEdit
          ? [
              <Popconfirm
                key="button_delete"
                title={t('financial_production.confirm_delete.title')}
                description={t(
                  'financial_production.confirm_delete.description',
                )}
                onConfirm={() => handleOnDelete(initialValues.id)}
                okText={t('buttons.yes')}
                cancelText={t('buttons.no')}
                disabled={isCanceled}
              >
                <Button danger icon={<DeleteOutlined />} disabled={isCanceled}>
                  {t('buttons.cancel')}
                </Button>
              </Popconfirm>,
            ]
          : []
      }
    >
      {drawerForm.formLoading || isLoading ? (
        <Skeleton active />
      ) : (
        <Form
          {...(!isEdit ? drawerForm.formProps : {})}
          disabled={isEdit}
          layout="vertical"
          initialValues={initialValues}
          onFinish={onFinish}
        >
          <InsertProductionDetail />
          <Divider />
          <InsertProductionItem isEdit={isEdit} />
        </Form>
      )}
    </DrawerWithButtons>
  );
};

export { InsertProduction };
