import { Button, Typography } from 'antd';
import styled from 'styled-components';
import { NeutralColors } from 'theme';

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const Title = styled(Typography.Text)`
  color: ${NeutralColors[100]};
`;

const Description = styled(Typography.Text)`
  color: ${NeutralColors[60]};
`;

const ButtonCreate = styled(Button)``;

export { ButtonCreate, Container, Description, Title };
