import { Form, InputNumber, Tag as TagAntd } from 'antd';
import { ChevronLeftIcon } from 'common/icons';
import styled from 'styled-components';
import { NeutralColors } from 'theme';

interface IsOpenProps {
  isOpen: boolean;
}

const Flex = styled.div`
  display: flex;
`;

const Box = styled.div`
  position: relative;
  margin-top: 24px;
  padding: 16px;
  border-radius: 8px;
  border: 1px solid ${NeutralColors[20]};
`;

const ContainerCenter = styled(Flex)`
  justify-content: space-between;
  align-items: center;
`;

const ContainerTitle = styled(ContainerCenter)<IsOpenProps>`
  margin-bottom: ${(props) => (props.isOpen ? '12px' : '0px')};
`;

const TrashButton = styled.div`
  width: 28px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  border: 1px solid ${NeutralColors[10]};
  cursor: pointer;
  margin-left: 8px;

  svg {
    width: 16px;
  }
`;

const TrashButtonAbsolute = styled(TrashButton)`
  position: absolute;
  top: 6px;
  right: 12px;
  z-index: 1;
`;

const InputFull = styled(InputNumber)`
  width: 100%;
`;

const Chevron = styled(ChevronLeftIcon)<IsOpenProps>`
  transform: ${(props) => (props.isOpen ? 'rotate(-90deg)' : 'rotate(90deg)')};
  cursor: pointer;
  margin-right: 8px;
`;

const Tag = styled(TagAntd)`
  margin-left: 8px;
`;

const TagContainer = styled.div`
  display: flex;
  align-items: center;
  margin: 8px 0;

  svg {
    width: 24px;
  }
`;

const FormItem = styled(Form.Item)`
  margin-bottom: 16px;
  width: 100%;
`;

export {
  Box,
  Chevron,
  ContainerCenter,
  ContainerTitle,
  Flex,
  FormItem,
  InputFull,
  Tag,
  TagContainer,
  TrashButton,
  TrashButtonAbsolute,
};
