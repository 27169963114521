import {
  DatePicker as DatePickerAntd,
  Form,
  Space as SpaceAntd,
  TimePicker as TimePickerAntd,
} from 'antd';
import styled from 'styled-components';
import { BlueColors } from 'theme';

const IconWrapper = styled.div`
  width: 22px;
  height: 22px;
  border-radius: 4px;
  padding: 2px;
  background-color: ${BlueColors[10]};
  color: ${BlueColors[60]};
  display: inline-flex;
  align-items: center;
  justify-content: center;
`;

const FormItem = styled(Form.Item)`
  margin: 0px;
  width: 250px;
`;

const DatePicker = styled(DatePickerAntd)`
  width: 92px;
  margin: 0px 4px;
  padding: 4px 6px;
`;

const TimePicker = styled(TimePickerAntd)`
  width: 76px;
  margin: 0px 4px;
  padding: 4px 6px;
`;

const Space = styled(SpaceAntd)`
  .fms-space-item {
    display: inline-flex;
  }
  width: 100%;
`;

export { DatePicker, FormItem, IconWrapper, Space, TimePicker };
