import { Tooltip } from 'antd';
import styled from 'styled-components';

const TooltipWrapper = styled(Tooltip)`
  cursor: pointer;

  svg {
    vertical-align: middle;
    height: 20px;
  }
`;

export { TooltipWrapper };
