import { Button as ButtonAntd, Typography } from 'antd';
import styled from 'styled-components';
import { BlueColors, NeutralColors } from 'theme';

interface ContainerShowProps {
  show: boolean;
}

interface BoxQuestionProps {
  isCurrentStep?: boolean;
}

const ContainerQuestion = styled.div<BoxQuestionProps>`
  transition: 1s;
  margin-top: 16px;
  display: flex;
  gap: 8px;

  svg {
    width: 32px;
    height: 32px;

    .fill-color-svg {
      fill: ${(props) =>
        props.isCurrentStep ? BlueColors[20] : NeutralColors[0]};
    }

    .stroke-color-svg {
      stroke: ${(props) =>
        props.isCurrentStep ? BlueColors[20] : NeutralColors[0]};
    }
  }
`;

const BoxQuestion = styled.div<BoxQuestionProps>`
  transition: 1s;
  border-radius: 0 12px 12px 12px;
  padding: 20px;
  background-color: ${(props) =>
    props.isCurrentStep ? BlueColors[10] : NeutralColors[0]};
  border: 1px solid
    ${(props) => (props.isCurrentStep ? BlueColors[20] : NeutralColors[0])};
  min-width: ${(props) => (props.isCurrentStep ? '700px' : '560px')};
  min-height: ${(props) => (props.isCurrentStep ? '74px' : '64px')};
  cursor: pointer;
`;

const TitleQuestion = styled(Typography.Title)`
  margin-bottom: 4px !important;
`;

const ContainerOptions = styled.div`
  width: 100%;
  max-width: 720px;
  margin-top: 16px;
  margin-bottom: 60px;
  margin-left: auto;
`;

const ContainerButtons = styled.div`
  display: flex;
  gap: 16px;
  position: fixed;
  right: 48px;
  bottom: 24px;
`;

const Button = styled(ButtonAntd)`
  display: flex;
  align-items: center;
  gap: 8px;
  height: 56px;
  border-radius: 12px 0 12px 12px;

  svg {
    fill: ${(props) =>
      props.type === 'primary' ? NeutralColors[0] : NeutralColors[80]};
  }
`;

const ContainerShow = styled.div<ContainerShowProps>`
  display: ${(props) => (props.show ? 'block' : 'none')};
`;

export {
  BoxQuestion,
  Button,
  ContainerButtons,
  ContainerOptions,
  ContainerQuestion,
  ContainerShow,
  TitleQuestion,
};
