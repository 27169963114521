import { Typography } from 'antd';
import { Header } from 'antd/es/layout/layout';
import { SelectWithButton } from 'common/components';
import { BalanceLogo } from 'common/logo';
import styled from 'styled-components';
import { NeutralColors } from 'theme';

const HeaderLayout = styled(Header)`
  background-color: ${NeutralColors[0]};
  display: flex;
  width: 100%;
  padding: 8px 12px;
  height: 56px;
  align-items: center;
  justify-content: space-between;
  border-bottom: solid 1px ${NeutralColors[20]};

  #lottie {
    display: flex;
  }
`;

const Logo = styled(BalanceLogo)`
  width: 120px;
  margin-right: 32px;
  margin-left: 20px;
`;

const OrganizationsWrapper = styled.div`
  flex-grow: 1;
  display: inline-flex;
  gap: 8px;
`;

const DropdownButton = styled.div`
  min-width: 150px;
  height: 44px;
  display: flex;
  flex-direction: column;
  padding: 0 8px;
  width: fit-content;
  cursor: pointer;

  svg {
    color: ${NeutralColors[80]};
  }
`;

const DropdownDescription = styled(Typography.Text)`
  color: ${NeutralColors[60]};
  font-size: 12px;
  margin-bottom: 0;
`;

const DropdownSelected = styled(Typography.Text)`
  font-size: 14px;
  margin-bottom: 0;
  white-space: nowrap;
`;

const DropdownArrow = styled.div`
  display: flex;
`;

const OptionsWrapper = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;
`;

const ProfileWrapper = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
`;

const Select = styled(SelectWithButton)`
  .fms-select-selector {
    border: none !important;
    padding: 0 16px 0 0 !important;
    background-color: transparent !important;
    box-shadow: none !important;
  }

  span {
    font-weight: 600 !important;
  }
`;

export {
  DropdownArrow,
  DropdownButton,
  DropdownDescription,
  DropdownSelected,
  HeaderLayout,
  Logo,
  OptionsWrapper,
  OrganizationsWrapper,
  ProfileWrapper,
  Select,
};
