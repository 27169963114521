interface ColorsType {
  0?: string;
  10: string;
  20: string;
  30: string;
  40: string;
  50: string;
  60: string;
  70: string;
  80: string;
  90: string;
  100: string;
}

const BlueColors: ColorsType = {
  10: '#EAF6FF',
  20: '#C6E6FF',
  30: '#82CFFF',
  40: '#31B4F2',
  50: '#0092E4',
  60: '#0071CD',
  70: '#005693',
  80: '#003B69',
  90: '#002747',
  100: '#00172D',
};

const GreenColors: ColorsType = {
  10: '#DFFBE8',
  20: '#C3EAD1',
  30: '#90D6AA',
  40: '#5EBB7F',
  50: '#19A04B',
  60: '#14803C',
  70: '#0C612C',
  80: '#06431C',
  90: '#032D10',
  100: '#011B07',
};

const RedColors: ColorsType = {
  10: '#FFF1F1',
  20: '#FFD9D9',
  30: '#FFB3B3',
  40: '#F98989',
  50: '#EB4B4B',
  60: '#CF3537',
  70: '#9C2628',
  80: '#70191A',
  90: '#4C0E0F',
  100: '#300606',
};

const OrangeColors: ColorsType = {
  10: '#FFF1ED',
  20: '#FFDACE',
  30: '#FFB59D',
  40: '#FF785A',
  50: '#EE5B3A',
  60: '#C1462B',
  70: '#92331E',
  80: '#682213',
  90: '#48150A',
  100: '#2D0A04',
};

const YellowColors: ColorsType = {
  10: '#FFF3DD',
  20: '#FFE4AE',
  30: '#F0C355',
  40: '#E99921',
  50: '#C17E19',
  60: '#9A6412',
  70: '#744A0B',
  80: '#523305',
  90: '#372102',
  100: '#221201',
};

const TealColors: ColorsType = {
  10: '#E0F9F7',
  20: '#A9EFE8',
  30: '#5FD2C8',
  40: '#46BBB0',
  50: '#2B9C92',
  60: '#217C74',
  70: '#165C56',
  80: '#0D413C',
  90: '#062B27',
  100: '#021917',
};

const PurpleColors: ColorsType = {
  10: '#F6F2FE',
  20: '#E7DDFC',
  30: '#D2BDF9',
  40: '#BA99F6',
  50: '#BA99F6',
  60: '#8354D6',
  70: '#643AAA',
  80: '#462779',
  90: '#2E1954',
  100: '#1C0D35',
};

const PinkColors: ColorsType = {
  10: '#FFF1F3',
  20: '#FFD8DF',
  30: '#FFB2C1',
  40: '#FF7D96',
  50: '#E85D78',
  60: '#BB485D',
  70: '#8D3545',
  80: '#65232F',
  90: '#45161E',
  100: '#2B0A10',
};

const NeutralColors: ColorsType = {
  0: '#FFFFFF',
  10: '#F3F4F6',
  20: '#DFE2E7',
  30: '#C2C7D0',
  40: '#A3A9B9',
  50: '#868CA2',
  60: '#696F88',
  70: '#4D5165',
  80: '#363948',
  90: '#232630',
  100: '#14151C',
};

const CropwiseGreen = '#73DC78';
const SyngentaNavy = '#00004B';

export {
  BlueColors,
  ColorsType,
  CropwiseGreen,
  GreenColors,
  NeutralColors,
  OrangeColors,
  PinkColors,
  PurpleColors,
  RedColors,
  SyngentaNavy,
  TealColors,
  YellowColors,
};
