import { ArrowRightIcon, UpdateIcon } from 'common/icons';
import { useTranslation } from 'react-i18next';
import { ElectronicInvoicesRequest } from 'src/electronic-invoice/electronic-invoice.model';

import * as Styled from './electronic-invoice-details.style';

interface ElectronicInvoiceDetailsProps {
  electronicInvoice: ElectronicInvoicesRequest;
}

const ElectronicInvoiceDetails = ({
  electronicInvoice,
}: ElectronicInvoiceDetailsProps) => {
  const { t } = useTranslation();

  return (
    <Styled.Container>
      <Styled.Arrow>
        <ArrowRightIcon />
      </Styled.Arrow>
      <Styled.ContainerTitle>
        <UpdateIcon />
        <Styled.TextTitle>
          {t('electronic_invoice.items.details.title')}
        </Styled.TextTitle>
      </Styled.ContainerTitle>
      <Styled.ContainerItems>
        {electronicInvoice.items?.map((item) => (
          <Styled.LineItem key={item.itemCode}>
            <Styled.Item width="75%">{item.itemName}</Styled.Item>
            <Styled.Item width="25%">{`${item.qty} ${item.uom}`}</Styled.Item>
          </Styled.LineItem>
        ))}
      </Styled.ContainerItems>
    </Styled.Container>
  );
};

export { ElectronicInvoiceDetails };
