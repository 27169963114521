import { Form, Select, Typography } from 'antd';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { useReferenceValueFormDetail } from './reference-value-form-detail.hook';

interface ReferenceValueFormDetailProps {
  farm?: string;
  season?: string;
}

const ReferenceValueFormDetail: FC<ReferenceValueFormDetailProps> = ({
  farm,
  season,
}) => {
  const { t } = useTranslation();

  const { seasonSelectProps } = useReferenceValueFormDetail(farm, season);

  return (
    <>
      <Typography.Text>
        {t('item.fields.value_reference_description')}
      </Typography.Text>
      <Typography.Paragraph type="secondary">
        {t('item.fields.value_reference_subdescription')}
      </Typography.Paragraph>
      <Form.Item
        name="seasonId"
        label={t('financial.reference_value.form.season')}
      >
        <Select
          {...seasonSelectProps}
          placeholder={t('common.business.season_placeholder')}
        />
      </Form.Item>
      <Form.Item name="cropId" hidden />
    </>
  );
};

export { ReferenceValueFormDetail as default, ReferenceValueFormDetail };
