import { Form, FormProps } from 'antd';
import {
  Dispatch,
  KeyboardEvent,
  MouseEvent,
  ReactNode,
  SetStateAction,
  useEffect,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';

import {
  DrawerWithButtons,
  DrawerWithButtonsProps,
} from '../drawer-with-buttons';

interface DrawerFormStepsProps extends DrawerWithButtonsProps {
  steps: ReactNode[];
  formProps: FormProps;
  isDisabledNextButton?: boolean;
  currentStep?: number;
  setCurrentStep?: Dispatch<SetStateAction<number>>;
  dataResult?: any;
  hasDataResult?: boolean;
  breadcrumbList?: string[];
  children?: ReactNode;
}

const DrawerFormSteps = ({
  formProps,
  dataResult,
  hasDataResult,
  isDisabledNextButton,
  onClose,
  steps,
  currentStep: currentStepParent,
  setCurrentStep: setCurrentStepParent,
  children,
  ...props
}: DrawerFormStepsProps) => {
  const [currentStep, setCurrentStep] = useState<number>(
    currentStepParent && setCurrentStepParent ? currentStepParent : 0,
  );
  const [disabledSave, setDisabledSave] = useState<boolean>(true);
  const { t } = useTranslation();
  const formValues = Form.useWatch([], formProps.form);

  useEffect(() => {
    if (currentStepParent && currentStepParent !== currentStep) {
      setCurrentStep(currentStepParent);
    }
  }, [currentStepParent]);

  useEffect(() => {
    if (setCurrentStepParent && currentStepParent !== currentStep) {
      setCurrentStepParent(currentStep);
    }
  }, [currentStep]);

  useEffect(() => {
    if (isDisabledNextButton) {
      formProps.form
        ?.validateFields({ validateOnly: true })
        .then(() => {
          setDisabledSave(false);
        })
        .catch(() => {
          setDisabledSave(true);
        });
    } else {
      setDisabledSave(false);
    }
  }, [formValues]);

  useEffect(() => {
    if (props.open) {
      setCurrentStep(0);
    }
  }, [props.open]);

  if (!steps?.length || !steps[currentStep]) {
    return <DrawerWithButtons {...props} />;
  }

  const isLastStep = steps.length === currentStep + 1;
  const isFistStep = currentStep === 0;

  const handleOnBackStep = () => {
    if (!isFistStep) {
      setCurrentStep((current) => current - 1);
    }
  };

  const handleOnNextStep = () => {
    if (!isLastStep) {
      formProps.form
        ?.validateFields({ validateOnly: true })
        .then(() => {
          setCurrentStep((current) => current + 1);
        })
        .catch(() => {
          formProps.form?.submit();
        });
    } else {
      formProps.form?.submit();
    }
  };

  const handleOnClose = (e: MouseEvent | KeyboardEvent) => {
    onClose?.(e);
  };

  return (
    <DrawerWithButtons
      {...props}
      onClose={handleOnClose}
      textSave={!isLastStep ? t('buttons.nextStep') ?? '' : undefined}
      onBack={!isFistStep ? handleOnBackStep : undefined}
      buttonSaveProps={{
        onClick: handleOnNextStep,
        enableReturnRouter: isLastStep,
      }}
      dataResult={dataResult}
      hasDataResult={hasDataResult}
      disabledSave={disabledSave}
      breadcrumbList={props.breadcrumbList}
    >
      {children}
      <Form {...formProps}>{steps[currentStep]}</Form>
    </DrawerWithButtons>
  );
};

export { DrawerFormSteps };
