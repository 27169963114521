import { useTranslation } from 'react-i18next';

import { MovementType } from '../movements.enum';
import * as Styled from './movement-tag.style';

interface MovementTagProps {
  movementType?: MovementType;
  quantity?: number;
}

const MovementTag = ({ movementType, quantity }: MovementTagProps) => {
  const { t } = useTranslation();
  if (!movementType) {
    return null;
  }

  switch (movementType) {
    case MovementType.STOCK_ADJUST:
      return (
        <Styled.DefaultTag>
          <Styled.Title level={5}>
            {t(`stock.movements.tag.${movementType}`)}
          </Styled.Title>
        </Styled.DefaultTag>
      );
    case MovementType.RECEIPT:
      return (
        <Styled.PositiveTag>
          <Styled.Title level={5} type="success">
            {t(`stock.movements.tag.${movementType}`)}
          </Styled.Title>
        </Styled.PositiveTag>
      );
    case MovementType.TRANSFER:
      if (quantity && quantity < 0) {
        return (
          <Styled.NegativeTag>
            <Styled.Title level={5} type="danger">
              {t(`stock.movements.tag.${movementType}`)}
            </Styled.Title>
          </Styled.NegativeTag>
        );
      } else if (quantity && quantity > 0) {
        return (
          <Styled.PositiveTag>
            <Styled.Title level={5} type="success">
              {t(`stock.movements.tag.${movementType}`)}
            </Styled.Title>
          </Styled.PositiveTag>
        );
      }
      return (
        <Styled.DefaultTag>
          <Styled.Title level={5}>
            {t(`stock.movements.tag.${movementType}`)}
          </Styled.Title>
        </Styled.DefaultTag>
      );
    default:
      return (
        <Styled.NegativeTag>
          <Styled.Title level={5} type="danger">
            {t(`stock.movements.tag.${movementType}`)}
          </Styled.Title>
        </Styled.NegativeTag>
      );
  }
};

export { MovementTag };
