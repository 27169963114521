import { UseDrawerFormReturnType } from '@refinedev/antd';
import { BaseKey, BaseRecord, HttpError, useDelete } from '@refinedev/core';
import { useRedirect } from 'common/hooks';
import dayjs from 'dayjs';
import { useEffect, useMemo } from 'react';
import { StockEntryManufactureRequest } from 'src/financial/financial.model';
import {
  MaterialConsumptionItemRequestModel,
  MaterialConsumptionRequestModel,
} from 'src/material-consumption/material-consumption.model';

const useInsertProduction = (
  drawerForm: UseDrawerFormReturnType<
    BaseRecord,
    HttpError,
    MaterialConsumptionRequestModel
  >,
  farm?: string,
  seasonId?: string,
  project?: string,
) => {
  const { mutate: mutateDelete, isLoading } = useDelete();
  const { initialValuesState } = useRedirect<StockEntryManufactureRequest>();

  const initialValues: Partial<MaterialConsumptionRequestModel> | undefined =
    useMemo(() => {
      if (initialValuesState) {
        return {
          ...initialValuesState,
          postingDate: dayjs(initialValuesState?.postingDate),
          postingTime: dayjs(initialValuesState?.postingTime),
        };
      }
      const { cropZone, postingDate, postingTime, items } =
        drawerForm.formProps.initialValues ?? {};
      return {
        ...drawerForm.formProps.initialValues,
        stockEntryType: 'Manufacture',
        project: cropZone as string,
        postingDate: postingDate ? dayjs(postingDate) : dayjs(),
        postingTime: postingTime
          ? dayjs(`${postingDate}T${postingTime}`)
          : dayjs(),
        items: items?.map((item: MaterialConsumptionItemRequestModel) => ({
          ...item,
          qty: item.qtyPerDeclaredArea ?? 0,
        })),
      };
    }, [drawerForm.formProps, initialValuesState]);

  const isEdit = !!initialValues?.id;

  useEffect(() => {
    if (!isEdit) {
      if (farm?.length) {
        drawerForm?.form?.setFieldValue('farm', farm);
      } else {
        drawerForm?.form?.setFieldValue('farm', undefined);
      }
    }
  }, [farm, isEdit]);

  useEffect(() => {
    if (!isEdit) {
      if (seasonId?.length) {
        drawerForm?.form?.setFieldValue('seasonId', seasonId);
      } else {
        drawerForm?.form?.setFieldValue('seasonId', undefined);
      }
    }
  }, [seasonId, isEdit]);

  useEffect(() => {
    if (!isEdit) {
      if (project?.length) {
        drawerForm?.form?.setFieldValue('project', project);
      } else {
        drawerForm?.form?.setFieldValue('project', undefined);
      }
    }
  }, [project, isEdit]);

  const onFinish = (values: MaterialConsumptionRequestModel) => {
    if (drawerForm?.formProps?.onFinish) {
      drawerForm?.formProps?.onFinish({
        ...values,
        postingDate: dayjs(values.postingDate).format('YYYY-MM-DD'),
        postingTime: dayjs(values.postingTime).format('HH:mm:ss'),
      });
    }
  };

  const isCanceled = useMemo(
    () => drawerForm.formProps?.initialValues?.docstatus === 2,
    [drawerForm.formProps?.initialValues?.docstatus],
  );

  const handleOnDelete = (id?: BaseKey) => {
    if (id) {
      mutateDelete({
        resource: 'stock-entry',
        id,
      });
    }
  };

  return {
    initialValues,
    isCanceled,
    isEdit,
    isLoading: isLoading || drawerForm.formLoading,
    handleOnDelete,
    onFinish,
  };
};

export { useInsertProduction };
