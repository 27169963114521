import { Card as CardAntd, Select as SelectAntd, Typography } from 'antd';
import { FieldChartEmptyIcon } from 'common/icons';
import styled from 'styled-components';

const Card = styled(CardAntd)`
  width: 100%;
`;

const Title = styled(Typography.Title)`
  display: flex;
  align-items: center;
  margin-bottom: 24px !important;

  svg {
    margin-right: 8px;
  }
`;

const ContainerFlex = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  h5 {
    margin-bottom: 0 !important;
  }
`;

const Select = styled(SelectAntd)`
  display: flex;
  align-items: center;
  width: 200px;
`;

const EmptyContainer = styled.div`
  margin-top: 24px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 16px;
  height: 100%;
`;

const EmptyImage = styled(FieldChartEmptyIcon)``;

const EmptyTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export {
  Card,
  ContainerFlex,
  EmptyContainer,
  EmptyImage,
  EmptyTextWrapper,
  Select,
  Title,
};
