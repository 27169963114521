import { setUserId } from '@amplitude/analytics-browser';
import * as Sentry from '@sentry/react';
import { Buffer } from 'buffer';
import {
  AUTH_CLIENT_DATA_CONFIG,
  PLATFORM_DATA_CONFIG,
  ROUTES,
} from 'common/constants';
import { UserDataModel } from 'common/models';
import {
  clearLocalData,
  getLocalData,
  hasSmartlookKey,
  isProduction,
  setLocalData,
  StorageKeyOptionsEnum,
  useTracking,
} from 'common/utils';
import { useLDClient } from 'launchdarkly-react-client-sdk';
import {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import { Location } from 'react-router-dom';
import Smartlook from 'smartlook-client';
import { SENTRY_KEY } from 'src/sentry.config';

import { AccessDataModel } from './auth.model';
import { getAccessTokenService, getUser } from './auth.service';

interface AuthContextType {
  user: UserDataModel | null;
  permissions: string[];
  setPermissions: (s: string[]) => void;
  signin: (location: Location, callback: VoidFunction) => void;
  signout: () => void;
}

const AuthContext = createContext<AuthContextType>({
  user: null,
  permissions: [],
  setPermissions: () => ({}),
  signin: () => ({}),
  signout: () => ({}),
});

function getQueryParams(location: Partial<Location>) {
  const params = new URLSearchParams(location.search);
  return Object.fromEntries(params.entries());
}

function useAuth() {
  return useContext(AuthContext);
}

const AuthProvider = ({ children }: PropsWithChildren) => {
  const [user, setUser] = useState<UserDataModel | null>(null);
  const [permissions, setPermissions] = useState<string[]>([]);
  const { track } = useTracking();
  const ldClient = useLDClient();

  const handleUserSignedIn = useCallback(
    (userData: UserDataModel, callback: () => void) => {
      setUser(userData);
      setUserId(userData.id);
      if (isProduction && hasSmartlookKey) {
        Smartlook.identify(userData.id, {
          email: userData.email,
        });
      }
      if (SENTRY_KEY) {
        Sentry.setUser({
          user: {
            id: userData.id,
            email: userData.email,
          },
        });
      }
      if (ldClient) {
        ldClient.identify({
          kind: 'user',
          key: userData.id,
          email: userData.email,
          role: userData.role,
          country: userData.country_code,
        });
      }
      callback();
    },
    [ldClient],
  );

  const handleRetrieveAccessToken = useCallback(
    async (code: string, callback: () => void) => {
      const accessToken = await getAccessTokenService(code);

      if (accessToken) {
        setLocalData(StorageKeyOptionsEnum.AccessData, accessToken);
        track('Sign In');
        callback();
      }
    },
    [track],
  );

  const signin = useCallback(
    async (location: Location, callback: VoidFunction) => {
      const params = getQueryParams(location);
      const pathname = location.state?.from?.pathname || ROUTES.ROOT;
      const search = location.state?.from?.search || '';
      const { code } = params;
      const accessData: AccessDataModel = getLocalData(
        StorageKeyOptionsEnum.AccessData,
      );
      const accessToken = accessData ? accessData.access_token : null;

      const needAccessToken = code && !accessToken;

      if (!accessToken && !needAccessToken) {
        window.location.href = `${
          PLATFORM_DATA_CONFIG.ACCOUNTS_URI
        }/oauth/authorize?response_type=code&client_id=${
          PLATFORM_DATA_CONFIG.CLIENT_ID
        }&redirect_uri=${
          AUTH_CLIENT_DATA_CONFIG.REDIRECT_URI
        }&state=${Buffer.from(pathname + search).toString('base64')}`;

        return;
      }

      if (needAccessToken) {
        await handleRetrieveAccessToken(code, callback);
      }

      if (accessToken) {
        const userData: UserDataModel = await getUser();

        if (userData) {
          handleUserSignedIn(userData, callback);
        }
      }
    },
    [handleRetrieveAccessToken, handleUserSignedIn],
  );

  const signout = useCallback(() => {
    clearLocalData(StorageKeyOptionsEnum.AccessData);
    track('Sign Out');

    window.location.href = `${PLATFORM_DATA_CONFIG.ACCOUNTS_URI}/exit?continue=${AUTH_CLIENT_DATA_CONFIG.REDIRECT_URI}`;
  }, [track]);

  const value: AuthContextType = useMemo(
    () => ({ user, permissions, setPermissions, signin, signout }),
    [user, permissions, setPermissions, signin, signout],
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export { AuthContext, AuthProvider, AuthProvider as default, useAuth };
