import { Form, Tag as TagAntd } from 'antd';
import { ChevronRightIcon } from 'common/icons';
import styled from 'styled-components';
import { NeutralColors } from 'theme';

interface IsOpenProps {
  isOpen: boolean;
}

const Flex = styled.div`
  display: flex;
`;

const Box = styled.div`
  position: relative;
  margin-top: 24px;
  padding: 16px;
  border-radius: 8px;
  border: 1px solid ${NeutralColors[20]};
`;

const ContainerCenter = styled(Flex)`
  justify-content: space-between;
  align-items: center;
`;

const ContainerValues = styled(Flex)`
  justify-content: space-between;
  align-items: flex-start;
`;

const ContainerTitle = styled(ContainerCenter)<IsOpenProps>`
  margin-bottom: ${(props) => (props.isOpen ? '16px' : '0px')};
`;

const TrashButton = styled.div`
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  border: 1px solid ${NeutralColors[10]};
  cursor: pointer;
  margin-left: 8px;

  svg {
    width: 18px;
    fill: ${NeutralColors[70]};
  }
`;

const TrashButtonAbsolute = styled(TrashButton)`
  position: absolute;
  top: 8px;
  right: 16px;
  z-index: 1;

  svg {
    width: 18px;
    fill: ${NeutralColors[70]};
  }
`;

const FormItem = styled(Form.Item)`
  margin-bottom: 16px;
`;

const FormItemValue = styled(FormItem)`
  max-width: 48%;
`;

const Chevron = styled(ChevronRightIcon)<IsOpenProps>`
  transform: ${(props) => (props.isOpen ? 'rotate(-90deg)' : 'rotate(90deg)')};
  cursor: pointer;
  margin-right: 8px;
`;

const Tag = styled(TagAntd)`
  margin-left: 8px;
`;

const TagContainer = styled.div`
  display: flex;
  align-items: center;
  margin: 8px 0;
`;

export {
  Box,
  Chevron,
  ContainerCenter,
  ContainerTitle,
  ContainerValues,
  Flex,
  FormItem,
  FormItemValue,
  Tag,
  TagContainer,
  TrashButton,
  TrashButtonAbsolute,
};
