import { BaseKey } from '@refinedev/core';
import {
  ButtonProps,
  DrawerProps,
  Form,
  FormProps,
  Grid,
  Select,
  Typography,
} from 'antd';
import { DrawerWithButtons } from 'common/components';
import { useTranslation } from 'react-i18next';

import useCostCenterEdit from './cost-center-edit.hook';
import * as Styled from './cost-center-edit.style';

interface CostCenterEditProps {
  drawerProps: DrawerProps;
  formProps: FormProps;
  onClose: () => void;
  onCreate: ButtonProps;
  editId?: BaseKey;
}

const CostCenterEdit = ({
  drawerProps,
  formProps,
  onClose,
  onCreate,
  editId,
}: CostCenterEditProps) => {
  const breakpoint = Grid.useBreakpoint();
  const { t } = useTranslation();

  const { costCenterSelectProps, handleOnFinish } = useCostCenterEdit({
    formProps,
    editId,
  });

  return (
    <DrawerWithButtons
      {...drawerProps}
      width={breakpoint.sm ? '400px' : '100%'}
      zIndex={1001}
      title={t('settings.drawer.title')}
      buttonSaveProps={onCreate}
      onClose={onClose}
      tagTrackClose="Cost Center Edition"
    >
      <Styled.Card>
        <Form layout="vertical" {...formProps} onFinish={handleOnFinish}>
          {editId && <Typography.Title level={4}>{editId}</Typography.Title>}
          <Form.Item
            label={t('settings.cost_center')}
            name={['costCenter']}
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Select {...costCenterSelectProps} />
          </Form.Item>
        </Form>
      </Styled.Card>
    </DrawerWithButtons>
  );
};

export { CostCenterEdit, CostCenterEdit as default };
