import { usePermissionsBalance } from 'common/hooks/use-permissions-balance.hook';
import { useNavigateWithOrgAndFarm } from 'common/utils';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { useOrganization } from 'src/organization';

import {
  FeatureItemKeys,
  featureListData,
  FinancialItemKeys,
  OrdersAndPaymentsItemKeys,
  ResourcesItemKeys,
} from './menu-item/feature-list.data';
import { filterFeatureItems, getMenuItem } from './menu-item/menu-item.utils';
import { getFeatureKeyFromPathname } from './sider.navbar.util';

interface UseSiderNavbarProps {
  onClickMenu?: () => void;
  isMobile: boolean;
}

export const useSiderNavbar = ({
  onClickMenu,
  isMobile = false,
}: UseSiderNavbarProps) => {
  const location = useLocation();
  const [current, setCurrent] = useState<
    | FeatureItemKeys
    | ResourcesItemKeys
    | FinancialItemKeys
    | OrdersAndPaymentsItemKeys
    | undefined
  >(getFeatureKeyFromPathname(location.pathname));
  const { t } = useTranslation();
  const featureFlags = useFlags();
  const navigate = useNavigateWithOrgAndFarm();
  const { permissions } = usePermissionsBalance();
  const { entitlements } = useOrganization();

  const onMenuItemClick = useCallback(
    (route: string | undefined) => {
      route && navigate(route);
      !!onClickMenu && onClickMenu();
    },
    [navigate, onClickMenu],
  );

  const handleFeatureItems = useCallback(() => {
    const ListMenu = Object.values(FeatureItemKeys)
      .filter((value) => {
        return filterFeatureItems(
          featureListData[value],
          featureFlags,
          isMobile,
          permissions,
          entitlements,
        );
      })
      .map((value: string) =>
        getMenuItem(
          t,
          onMenuItemClick,
          value as FeatureItemKeys,
          isMobile,
          featureFlags,
          permissions,
          entitlements,
        ),
      );

    return [...ListMenu];
  }, [featureFlags, isMobile, permissions, t, onMenuItemClick]);

  useEffect(() => {
    setCurrent(getFeatureKeyFromPathname(location.pathname));
  }, [location.pathname]);

  return {
    entitlements,
    handleFeatureItems,
    current,
  };
};
