import { usePermissions } from '@refinedev/core';
import { ActionRbacEnum } from 'common/utils/auth-provider.util';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useEffect, useState } from 'react';
import { useAuth } from 'src/auth';
import { useOrganization } from 'src/organization';
import { Organization } from 'src/organization/organization.model';

const usePermissionsBalance = () => {
  const { permissionsFms1013EnabledPermission } = useFlags();
  const { currentOrganization } = useOrganization();
  const { permissions: permissionsState, setPermissions } = useAuth();
  const [mounted, setMounted] = useState(false);
  const [prevOrganization, setPrevOrganization] = useState<Organization | null>(
    null,
  );

  const { data, refetch } = usePermissions<string[]>({
    params: {
      meta: {
        crn: currentOrganization?.crn,
      },
      enabled: !!currentOrganization?.crn && permissionsState?.length === 0,
    },
  });

  useEffect(() => {
    if (mounted && currentOrganization?.id !== prevOrganization?.id) {
      refetch();
    }
    setPrevOrganization(currentOrganization);
  }, [currentOrganization, mounted]);

  useEffect(() => {
    setMounted(true);
  }, []);

  useEffect(() => {
    if (data?.length) {
      setPermissions(data);
    }
  }, [data]);

  if (permissionsFms1013EnabledPermission) {
    return {
      permissions: permissionsState,
    };
  }

  return {
    permissions: Object.values(ActionRbacEnum),
  };
};

export { usePermissionsBalance };
