export enum CropZoneColumnTestId {
  CROP_ZONE_COLUMN_CONTAINER = 'crop-zone-column-container',
}

export enum StatusColumnTestId {
  STATUS_COLUMN_CONFIRMED = 'status-column-confirmed',
  STATUS_COLUMN_DRAFT = 'status-column-draft',
  STATUS_COLUMN_CANCELLED = 'status-column-cancelled',
}

export enum TypeColumnTestId {
  TYPE_COLUMN_CREATED_FMS = 'type-column-created-fms',
  TYPE_COLUMN_CREATED_PROTECTOR = 'type-column-created-protector',
}
