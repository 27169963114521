import { useSelect } from '@refinedev/antd';
import { useList, useOne } from '@refinedev/core';
import { useWatch } from 'antd/es/form/Form';
import useFormInstance from 'antd/es/form/hooks/useFormInstance';
import { OptionTypeModel } from 'common/components';
import { useEffect, useMemo, useState } from 'react';
import { useOrganization } from 'src/organization';
import { ItemBasicRate, ItemDetailPurchaseModel } from 'src/resources/item';
import { ItemCategory } from 'src/resources/item-category/item-category.model';
import { StockItemModel } from 'src/stock/stock-summary/stock-summary.model';
import {
  IsGroupWarehouseEnum,
  IsStockItemEnum,
} from 'src/stock/warehouse-list/warehouse-list.constant';

const useMaterialConsumptionFormItem = (index: number) => {
  const [isOpen, setIsOpen] = useState(true);
  const { currentOrganizationId } = useOrganization();
  const metadata = useMemo(
    () => ({
      organizationId: currentOrganizationId,
    }),
    [currentOrganizationId],
  );
  const form = useFormInstance();

  const farm = useWatch(['farm']);
  const itemCode = useWatch(['items', index, 'itemCode']);
  const sourceWarehouse = useWatch(['items', index, 'sourceWarehouse']);
  const basicRate = useWatch(['items', index, 'basicRate']);
  const qty = useWatch(['items', index, 'qty']);
  const allData = useWatch([]);

  const { queryResult: itemQueryResult, selectProps: itemSelectProps } =
    useSelect({
      resource: 'item/stock',
      onSearch: (value) => [
        {
          field: 'name',
          operator: 'eq',
          value,
        },
      ],
      meta: {
        metadata,
        farm: farm,
      },
      queryOptions: {
        enabled: !!farm,
      },
      filters: [
        {
          field: 'isStockItem',
          operator: 'eq',
          value: 1,
        },
      ],
    });

  const itemsOptions: OptionTypeModel[] = useMemo(
    () =>
      itemQueryResult?.data?.data?.map((item) => ({
        label: item.name,
        value: item.id,
        complementaryLabel: `${item.actualQtyStock ?? 0} ${item.stockUom} | ${
          item.category
        }`,
      })) ?? [],
    [itemQueryResult],
  );

  const { selectProps: warehouseSelectProps } = useSelect({
    resource: 'warehouse',
    optionLabel: 'name',
    onSearch: (value) => [
      {
        field: 'name',
        operator: 'eq',
        value,
      },
    ],
    meta: {
      metadata,
      farm: farm,
    },
    filters: [
      {
        field: 'isGroup',
        operator: 'eq',
        value: IsGroupWarehouseEnum.NO,
      },
    ],
    queryOptions: {
      enabled: !!farm,
    },
  });

  const { data: dataItem, isFetching: isFetchingItem } =
    useOne<ItemDetailPurchaseModel>({
      resource: 'item',
      id: itemCode,
      meta: {
        organizationId: currentOrganizationId,
        params: {
          farmId: farm,
        },
      },
      queryOptions: {
        enabled: !!itemCode,
      },
    });

  const { data: dataStockSummary, isFetching: isFetchingStockSummary } =
    useList<StockItemModel>({
      resource: 'stock-summary',
      filters: [
        {
          field: 'itemCode',
          operator: 'eq',
          value: itemCode,
        },
        {
          field: 'isStockItem',
          operator: 'eq',
          value: IsStockItemEnum.YES,
        },
      ],
      meta: {
        organizationId: currentOrganizationId,
      },
      queryOptions: {
        enabled: !!itemCode,
      },
    });

  const { data: itemCategoryData } = useOne<ItemCategory>({
    id: dataItem?.data.category,
    resource: 'item-category',
    meta: {
      organizationId: currentOrganizationId,
    },
    queryOptions: {
      enabled: !!dataItem,
    },
  });

  const { data: basicRateResult } = useOne<ItemBasicRate>({
    id: itemCode,
    resource: 'stock-entry/item',
    meta: {
      organizationId: currentOrganizationId,
      params: {
        farm,
        warehouse: sourceWarehouse,
      },
    },
    queryOptions: {
      enabled: !!sourceWarehouse && !!farm && !!itemCode,
    },
  });

  useEffect(() => {
    const items = form?.getFieldValue('items');
    if (items?.[index]) {
      if (basicRate) {
        items[index].basicRate = basicRate;
        form?.setFieldValue('items', items);
      } else if (basicRateResult) {
        items[index].basicRate = basicRateResult.data.basicRate;
        form?.setFieldValue('items', items);
      } else if (dataItem?.data?.lastPurchaseRate) {
        items[index].basicRate = dataItem.data.lastPurchaseRate;
        form?.setFieldValue('items', items);
      }
    }
  }, [basicRateResult, form, index]);

  useEffect(() => {
    if (itemCategoryData) {
      const defaultSettings = itemCategoryData.data?.defaultSettings?.find(
        (settings) => settings.farm === farm,
      );
      if (defaultSettings) {
        const items = form?.getFieldValue('items');
        items[index].sourceWarehouse = defaultSettings.warehouse;
        items[index].expenseAccount = defaultSettings.expenseAccount;
        form?.setFieldValue('items', items);
      }
    }
  }, [itemCategoryData, farm, form, index]);

  const item = dataItem?.data;

  const optionWarehouse: OptionTypeModel[] = useMemo(
    () =>
      warehouseSelectProps?.options?.map((option) => ({
        label: option.label,
        value: option.value,
        complementaryLabel: `${
          dataStockSummary?.data?.find(
            (stock) => stock.warehouse === option.value,
          )?.actualQty ?? 0
        }`,
      })) ?? [],
    [dataStockSummary, warehouseSelectProps],
  );

  const maxQty: number =
    dataStockSummary?.data.find((stock) => stock.warehouse === sourceWarehouse)
      ?.actualQty ?? 0;

  return {
    allData: {
      ...allData,
      postingDate: allData?.postingDate?.format('YYYY-MM-DD'),
    },
    isFetchingItem,
    isFetchingStockSummary,
    qty,
    maxQty,
    isOpen,
    item,
    currency: allData?.currency,
    isFifo: item?.valuationMethod === 'FIFO',
    sourceWarehouse,
    optionWarehouse,
    dataStockSummary,
    warehouseSelectProps,
    itemSelectProps: {
      ...itemSelectProps,
      options: itemsOptions,
    },
    setIsOpen,
  };
};

export { useMaterialConsumptionFormItem };
