import { Button, Modal as ModalAntd, ModalProps as ModalPropsAntd } from 'antd';
import { ReactNode, useMemo } from 'react';

import { ModalTestId } from './modal.constant';
import * as Styled from './modal.styled';

interface ModalProps extends ModalPropsAntd {
  onOk?: () => void;
  onCancel?: () => void;
  disableOkButton?: boolean;
  isLoading?: boolean;
  isDefaultFooter?: boolean;
  header?: ReactNode;
}

export const Modal = ({
  children,
  okText,
  disableOkButton,
  onOk,
  onCancel,
  cancelText,
  isLoading,
  isDefaultFooter,
  header,
  ...props
}: ModalProps) => {
  const renderFooter = useMemo(() => {
    return (
      <Styled.ContainerButton>
        {cancelText && onCancel && (
          <Button
            danger
            onClick={() => onCancel()}
            data-testid={ModalTestId.MODAL_TEST_ID_CANCEL}
          >
            {cancelText}
          </Button>
        )}
        {okText && onOk && (
          <Button
            disabled={disableOkButton}
            type="primary"
            onClick={() => onOk()}
            data-testid={ModalTestId.MODAL_TEST_ID_OK}
            loading={isLoading}
          >
            {okText}
          </Button>
        )}
      </Styled.ContainerButton>
    );
  }, [disableOkButton, cancelText, onCancel, okText, onOk, isLoading]);

  return (
    <ModalAntd
      {...props}
      okText={okText}
      cancelText={cancelText}
      onCancel={onCancel ? () => onCancel() : undefined}
      onOk={onOk}
      footer={isDefaultFooter ? props.footer : renderFooter}
      style={{ transform: 'translateZ(0)' }}
    >
      <Styled.ContainerChildren isShowHeader={!!header}>
        {header}
        {children}
      </Styled.ContainerChildren>
    </ModalAntd>
  );
};
