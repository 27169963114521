import { HttpError, useApiUrl, useCustom, useMany } from '@refinedev/core';
import { useCallback, useMemo } from 'react';

import { useOrganization } from '../../organization';
import { getListIdItemCategory } from './item-category.domain';
import {
  ItemCategoryTreeNode,
  ItemCategoryValidation,
} from './item-category.model';

const useItemCategoryTreeNodes = () => {
  const apiUrl = useApiUrl();
  const { currentOrganizationId, isLoading: isOrganizationLoading } =
    useOrganization();
  const metadata = useMemo(
    () => ({
      organizationId: currentOrganizationId,
    }),
    [currentOrganizationId],
  );

  const isDependenciesLoaded = useMemo(
    () => !!currentOrganizationId && !isOrganizationLoading,
    [currentOrganizationId, isOrganizationLoading],
  );

  const {
    data: itemCategoryTreeNodes,
    isFetching: isFetchingTreeNodes,
    isLoading: isLoadingTreeNodes,
    refetch,
  } = useCustom<ItemCategoryTreeNode, HttpError, ItemCategoryTreeNode>({
    method: 'get',
    url: `${apiUrl}/item-category/tree-nodes`,
    meta: metadata,
    queryOptions: {
      enabled: isDependenciesLoaded,
    },
  });

  const handleDataNode = useCallback(
    (data: ItemCategoryTreeNode): ItemCategoryTreeNode => {
      const treeDataNode: ItemCategoryTreeNode = {
        id: data?.id ?? '',
        name: data?.name ?? '',
        isGroup: data?.isGroup ?? false,
      };
      if (data?.children && data.children.length > 0) {
        treeDataNode.children = data.children.reduce((acc, child) => {
          acc.push(handleDataNode(child));
          return acc;
        }, [] as ItemCategoryTreeNode[]);
      }
      return treeDataNode;
    },
    [],
  );

  const treeData: ItemCategoryTreeNode[] = useMemo(() => {
    if (!itemCategoryTreeNodes?.data) {
      return [];
    }
    return handleDataNode(itemCategoryTreeNodes.data).children ?? [];
  }, [itemCategoryTreeNodes?.data, handleDataNode]);

  const listIdsItemCategory = useMemo(
    () => getListIdItemCategory(treeData),
    [treeData],
  );

  const { data: listValidate, isFetching: isFetchingValidate } =
    useMany<ItemCategoryValidation>({
      ids: listIdsItemCategory,
      resource: 'item-category/validate',
      queryOptions: {
        enabled: !!currentOrganizationId && !!treeData?.length,
      },
      meta: metadata,
    });

  return {
    data: treeData,
    isLoading: isFetchingTreeNodes || isLoadingTreeNodes,
    refetch,
    listValidate: listValidate?.data,
    isFetchingValidate,
  };
};

export { useItemCategoryTreeNodes };
