import { FarmIcon, SendIcon, SupplierIcon } from 'common/icons';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { ElectronicInvoiceModel } from 'src/electronic-invoice/electronic-invoice.model';

import * as Styled from './invoice-details.style';

interface InvoiceDetailsProps {
  invoice: ElectronicInvoiceModel;
}

const InvoiceDetails = ({ invoice }: InvoiceDetailsProps) => {
  const { t } = useTranslation();

  if (!invoice) {
    return null;
  }

  const { issuedDate, issuedDateTime, targetAddrStreet, sourceFantasyName } =
    invoice;

  return (
    <Styled.Container>
      {issuedDate && issuedDateTime && (
        <Styled.ContainerText>
          <SendIcon />
          <Styled.Text>
            <span>{t('electronic_invoice.details.issued_on')}</span>
            {dayjs(`${issuedDate} ${issuedDateTime}`).format('DD MMM YYYY')}
          </Styled.Text>
        </Styled.ContainerText>
      )}
      {sourceFantasyName && (
        <Styled.ContainerText>
          <SupplierIcon />
          <Styled.Text>
            <span>{t('common.business.supplier')}</span>
            {sourceFantasyName}
          </Styled.Text>
        </Styled.ContainerText>
      )}
      {targetAddrStreet && (
        <Styled.ContainerText>
          <FarmIcon />
          <Styled.Text>
            <span>{t('common.business.farm')}</span>
            {targetAddrStreet}
          </Styled.Text>
        </Styled.ContainerText>
      )}
    </Styled.Container>
  );
};

export { InvoiceDetails };
