import { Form, FormProps, Typography } from 'antd';
import { FormGroupSelectableButton } from 'common/components/form-group-selectable-button/form-group-selectable-button.component';
import {
  CropProtectionIcon,
  FertilizerIcon,
  FinishedGoodsIcon,
  GenericIcon,
  VarietyIcon,
} from 'common/icons';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { ItemTypeEnum } from 'src/resources/item/item-type.enum';

import * as Styled from './choose-item-group.style';

const hideFields: Record<string, string[]> = {
  [ItemTypeEnum.CROP_PROTECTION]: ['crop', 'valueReference'],
  [ItemTypeEnum.FERTILIZER]: ['crop', 'valueReference'],
  [ItemTypeEnum.GENERIC]: ['crop', 'valueReference'],
  [ItemTypeEnum.FINISHED_GOODS]: [
    'isStockItem',
    'allowNegativeStock',
    'source',
    'category',
  ],
  [ItemTypeEnum.SEED]: ['valueReference'],
};

interface ChooseItemGroupProps {
  formProps: FormProps;
  setResourceCreate: Dispatch<SetStateAction<string>>;
  setHideFields: Dispatch<SetStateAction<string[]>>;
  setDisabledFields: Dispatch<SetStateAction<string[]>>;
  handleGoToNextStep: () => void;
}

const ChooseItemGroup = ({
  formProps,
  setResourceCreate,
  setHideFields,
  setDisabledFields,
  handleGoToNextStep,
}: ChooseItemGroupProps) => {
  const { t } = useTranslation();
  const { itemFms1130ShowFinishedGoods } = useFlags();

  const handleOnChange = (value: string) => {
    formProps.form?.setFieldsValue({
      selectItem: '',
    });
    setDisabledFields([]);
    setResourceCreate(
      value === ItemTypeEnum.FINISHED_GOODS
        ? 'item/product-final'
        : `item/${value}`,
    );
    setHideFields(hideFields[value] ?? []);
  };

  const getIcon = (value: ItemTypeEnum) => {
    switch (value) {
      case ItemTypeEnum.CROP_PROTECTION:
        return <CropProtectionIcon />;
      case ItemTypeEnum.FERTILIZER:
        return <FertilizerIcon />;
      case ItemTypeEnum.GENERIC:
        return <GenericIcon />;
      case ItemTypeEnum.FINISHED_GOODS:
        return <FinishedGoodsIcon />;
      case ItemTypeEnum.SEED:
      default:
        return <VarietyIcon />;
    }
  };

  return (
    <Styled.Card size="small">
      <Typography.Title level={5}>
        {t('item.create.choose_group')}
      </Typography.Title>
      <Form.Item
        name={['itemType']}
        rules={[
          {
            required: true,
          },
        ]}
      >
        <FormGroupSelectableButton
          onClick={handleGoToNextStep}
          list={Object.values(ItemTypeEnum)
            .filter(
              (value) =>
                value !== ItemTypeEnum.FINISHED_GOODS ||
                itemFms1130ShowFinishedGoods,
            )
            .map((value) => ({
              icon: getIcon(value),
              title: t(`item.types.${value}`),
              value,
            }))}
          onChange={handleOnChange}
        />
      </Form.Item>
    </Styled.Card>
  );
};

export { ChooseItemGroup };
