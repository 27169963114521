import { WarehouseBlackWhiteIcon } from 'common/icons';

import * as Styled from './warehouse-column.style';

interface WarehouseColumnProps {
  warehouse: string;
}

const WarehouseColumn = ({ warehouse }: WarehouseColumnProps) => {
  return (
    <Styled.Container>
      <WarehouseBlackWhiteIcon />
      {warehouse}
    </Styled.Container>
  );
};

export { WarehouseColumn };
