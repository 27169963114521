import { FormProps } from 'antd';
import { ListFiltersModel } from 'common/refine-table-filters';

export const generateParamsFilter = (
  listFilters: ListFiltersModel[],
  searchFormProps: FormProps,
) =>
  listFilters.reduce(
    (acc, filter) => ({
      ...acc,
      [filter.field]: searchFormProps.form?.getFieldValue(filter.field),
    }),
    {},
  );
