import { BaseKey, useDelete } from '@refinedev/core';
import { DrawerProps, FormInstance, FormProps } from 'antd';
import { useWatch } from 'antd/es/form/Form';
import { ButtonType } from 'antd/lib/button';
import { useRedirect } from 'common/hooks';
import { useTracking } from 'common/utils';
import dayjs from 'dayjs';
import { useEffect, useMemo } from 'react';
import { useCreateItemDrawer } from 'src/resources/item/use-item-create-drawer.hook';
import { useSupplierCreateDrawer } from 'src/resources/supplier/use-supplier-create-drawer.hook';

import { InvoiceFormModel, InvoiceItemModel } from '../invoice.model';
import { StatusEnum } from '../status/invoice-status.contant';
import { useInvoiceFormStore } from './invoice-form.store';

const useInvoiceForm = (
  formProps: FormProps,
  drawerProps: DrawerProps,
  formInstance: FormInstance,
  onClose: () => void,
) => {
  const { initialValuesState } = useRedirect<InvoiceFormModel>();
  const { track } = useTracking();
  const { setDisableSave, showItems, setShowItems } = useInvoiceFormStore();
  const formValues = useWatch([], formInstance);

  const detailTabClassname = useMemo<ButtonType | undefined>(
    () => (showItems ? undefined : 'primary'),
    [showItems],
  );

  const itemsTabClassname = useMemo<ButtonType | undefined>(
    () => (showItems ? 'primary' : undefined),
    [showItems],
  );

  const initialValues: InvoiceFormModel = useMemo(() => {
    if (initialValuesState) {
      return {
        ...initialValuesState,
        postingDate: dayjs(initialValuesState.postingDate) ?? dayjs(),
        dueDate: dayjs(initialValuesState.dueDate) ?? dayjs(),
      };
    }
    return {
      id: formProps.initialValues?.id as string,
      supplier: formProps.initialValues?.supplier as string,
      paymentMethod: formProps.initialValues?.paymentMethod as string,
      isPaid: formProps.initialValues?.status === StatusEnum.Paid,
      postingDate: dayjs(formProps.initialValues?.postingDate) ?? dayjs(),
      dueDate: dayjs(formProps.initialValues?.dueDate) ?? dayjs(),
      farm: formProps.initialValues?.farm as string,
      costCenter: formProps.initialValues?.items?.[0]?.costCenter as string,
      project: formProps.initialValues?.project as string,
      currency: formProps.initialValues?.currency as string,
      seasonId: formProps.initialValues?.seasonId as string,
      updateStock: formProps.initialValues?.updateStock as boolean,
      description: formProps.initialValues?.description as string,
      items: formProps.initialValues?.items?.map((item: InvoiceItemModel) => ({
        itemCode: item.itemCode,
        qty: item.qty,
        rate: item.rate,
        warehouse: item.warehouse,
      })),
    };
  }, [formProps, initialValuesState]);

  const isPaidPartial = useMemo(
    () =>
      formProps.initialValues?.outstanding > 0 &&
      formProps.initialValues?.outstanding < formProps.initialValues?.total,
    [formProps.initialValues?.outstanding, formProps.initialValues?.total],
  );

  useEffect(() => {
    if (!drawerProps.open) {
      setShowItems(false);
      formProps.form?.resetFields();
    }
  }, [drawerProps.open, formProps.form, setShowItems]);

  const disableItemsTab = useMemo(
    () =>
      !formValues?.postingDate ||
      !formValues?.dueDate ||
      !formValues?.farm ||
      !formValues?.supplier ||
      !formValues?.costCenter ||
      (formValues?.isCropZone && !formValues?.project),
    [formValues],
  );

  useEffect(() => {
    formInstance
      .validateFields({ validateOnly: true })
      .then(() => {
        setDisableSave(false);
      })
      .catch(() => {
        setDisableSave(true);
      });
  }, [formValues, formInstance, setDisableSave]);

  const handleOnClose = () => {
    track('Cancel Invoice');
    onClose();
  };

  const { mutate: mutateDelete } = useDelete();

  const handleOnDelete = (id?: BaseKey) => {
    if (id) {
      track('Delete Invoice');
      mutateDelete({
        resource: 'purchase-invoice',
        id,
      });
    }
  };

  const handleOnFinish = (values: InvoiceFormModel) => {
    track('End Invoice');
    if (formProps.onFinish) {
      formProps.onFinish({
        ...values,
        postingDate: dayjs(values.postingDate).format('YYYY-MM-DD'),
        postingTime: dayjs(values.postingDate).format('HH:mm:ss'),
        dueDate: dayjs(values.dueDate).format('YYYY-MM-DD'),
        items: values.items?.map((item) => ({
          ...item,
          amount: item.qty * item.rate,
          project: values.project,
          costCenter: values.costCenter,
        })),
      });
    }
  };

  const isCancelled = formProps.initialValues?.status === StatusEnum.Cancelled;

  const {
    createItemDrawer,
    handleOpenItemDrawer,
    setResourceCreate: setResourceCreateItem,
  } = useCreateItemDrawer();

  const { createSupplierDrawer, handleOpenSupplierDrawer } =
    useSupplierCreateDrawer();

  return {
    disableItemsTab,
    detailTabClassname,
    itemsTabClassname,
    isPaidPartial,
    initialValues,
    itemsAmount: formValues?.items?.length ?? 0,
    handleOnFinish,
    handleOnClose,
    handleOnDelete,
    isCancelled,
    createItemDrawer,
    handleOpenItemDrawer,
    setResourceCreateItem,
    createSupplierDrawer,
    handleOpenSupplierDrawer,
  };
};

export { useInvoiceForm as default, useInvoiceForm };
