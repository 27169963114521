import { Button as ButtonAntd, Typography } from 'antd';
import styled from 'styled-components';
import { BlueColors, NeutralColors } from 'theme';

const Container = styled.div`
  margin: 36px auto;
  width: 90%;
`;

const ContainerFlex = styled.div`
  display: flex;
`;

const Box = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 8px;
  padding: 24px;
  border-radius: 0 12px 12px 12px;
  border: 1px solid ${BlueColors[20]};
  background-color: ${BlueColors[10]};
  width: 100%;
`;

const FinishImg = styled.img`
  width: 360px;
  min-height: 100px;
  margin-bottom: -100px;
`;

const Text = styled(Typography.Title)`
  color: ${BlueColors[70]} !important;
`;

const ContainerButtons = styled.div`
  display: flex;
  gap: 16px;
  position: fixed;
  right: 48px;
  bottom: 24px;
`;

const Button = styled(ButtonAntd)`
  display: flex;
  align-items: center;
  gap: 8px;
  height: 56px;
  border-radius: 12px 0 12px 12px;

  svg {
    fill: ${(props) =>
      props.type === 'primary' ? NeutralColors[0] : NeutralColors[80]};
  }
`;

export {
  Box,
  Button,
  Container,
  ContainerButtons,
  ContainerFlex,
  FinishImg,
  Text,
};
