import { SaveButtonProps } from '@refinedev/antd';
import { Divider, DrawerProps, Form, FormProps, Grid } from 'antd';
import { DrawerWithButtons } from 'common/components';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import ReferenceValueFormDetail from './detail/reference-value-form-detail.component';
import ReferenceValueFormItem from './item/reference-value-form-item.component';
import { useReferenceValueForm } from './reference-value-form.hook';

interface ReferenceValueFormProps {
  drawerProps: DrawerProps;
  formProps: FormProps;
  formLoading?: boolean;
  saveButtonProps: SaveButtonProps;
  close: () => void;
  finalProductRefetch: () => void;
  chartRefetch?: () => void;
  farm?: string;
  season?: string;
}

const ReferenceValueForm: FC<ReferenceValueFormProps> = ({
  drawerProps,
  formProps,
  formLoading,
  saveButtonProps,
  close,
  finalProductRefetch,
  chartRefetch,
  farm,
  season,
}) => {
  const { t } = useTranslation();
  const breakpoint = Grid.useBreakpoint();

  const { handleOnFinish, handleOnClose } = useReferenceValueForm({
    close,
    form: formProps.form,
    finalProductRefetch,
    chartRefetch,
  });

  return (
    <DrawerWithButtons
      {...drawerProps}
      buttonSaveProps={saveButtonProps}
      width={breakpoint.sm ? '400px' : '100%'}
      title={t('financial.reference_value.form.title')}
      onClose={handleOnClose}
      isLoading={formLoading}
      destroyOnClose
    >
      <Form {...formProps} layout="vertical" onFinish={handleOnFinish}>
        <ReferenceValueFormDetail farm={farm} season={season} />
        <Divider />
        <ReferenceValueFormItem farm={farm} isOpenDrawer={drawerProps.open} />
      </Form>
    </DrawerWithButtons>
  );
};

export { ReferenceValueForm as default, ReferenceValueForm };
