enum AuthResponseEnum {
  Code = 'code',
}

enum AuthGrantTypeEnum {
  AuthorizationCode = 'authorization_code',
  Password = 'password',
  RefreshToken = 'refresh_token',
}

enum AuthErrorResponsesEnum {
  InvalidGrant = 'invalid_grant',
}

export { AuthErrorResponsesEnum, AuthGrantTypeEnum, AuthResponseEnum };
