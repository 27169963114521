import { Form } from 'antd';
import styled from 'styled-components';
import { NeutralColors } from 'theme';

interface BoxProps {
  show: boolean;
}

const Box = styled.div<BoxProps>`
  display: ${(props) => (props.show ? 'block' : 'none')};
  margin-top: 24px;
  padding: 16px;
  border-radius: 8px;
  border: 1px solid ${NeutralColors[20]};
`;

const ContainerDates = styled.div`
  display: flex;
  gap: 16px;
`;

const SwitchWrapper = styled(Form.Item)`
  width: 100%;
  margin-bottom: 6px;
  margin-top: 16px;

  .fms-form-item {
    margin-bottom: 0;
  }

  .fms-form-item-control-input-content {
    display: flex;
    align-items: center;
    gap: 16px;
  }

  .fms-picker {
    width: 100%;
  }
`;

const FormItem = styled(Form.Item)`
  width: 100%;
  margin-bottom: 6px;
  margin-top: 12px;

  .fms-picker {
    width: 100%;
  }
`;

export { Box, ContainerDates, FormItem, SwitchWrapper };
