import {
  Form,
  FormProps,
  Input,
  Select,
  Switch,
  Tooltip,
  Typography,
} from 'antd';
import { SelectWithButton } from 'common/components';
import { InfoIcon } from 'common/icons';
import { getCurrencySymbol } from 'common/utils';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useFarm } from 'src/organization';

import { ItemSource } from '../../item.model';
import { useItemForm } from './item-form.hook';
import * as Styled from './item-form.style';

interface ItemFormProps {
  isEdit?: boolean;
  disabledFields?: string[];
  hideFields?: string[];
  formProps?: FormProps;
  handleOpenItemCategoryDrawer?: () => void;
}
const ItemForm: FC<ItemFormProps> = ({
  isEdit,
  disabledFields,
  hideFields,
  formProps,
  handleOpenItemCategoryDrawer,
}) => {
  const { t } = useTranslation();
  const { currentFarm } = useFarm();
  const { cropProps, categorySelectProps, uomSelectProps } =
    useItemForm(formProps);

  return (
    <>
      <Form.Item
        label={t('item.fields.name')}
        name={['name']}
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Input
          disabled={!!disabledFields?.find((item) => item === 'name') || isEdit}
        />
      </Form.Item>
      <Form.Item label={t('item.fields.description')} name={['description']}>
        <Input disabled={isEdit} />
      </Form.Item>
      {!hideFields?.find((item) => item === 'crop') && !isEdit && (
        <Form.Item
          rules={[
            {
              required: true,
            },
          ]}
          label={t('item.fields.crop_selection')}
          name={['cropId']}
        >
          <Select {...cropProps} disabled={isEdit} />
        </Form.Item>
      )}
      {!hideFields?.find((item) => item === 'category') && !isEdit && (
        <Form.Item
          label={t('item.fields.category')}
          name={['category']}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <SelectWithButton
            {...categorySelectProps}
            allowClear
            disabled={
              !!disabledFields?.find((item) => item === 'category') || isEdit
            }
            buttonProps={{
              children: t('item_category.its_new'),
              onClick: handleOpenItemCategoryDrawer,
            }}
          />
        </Form.Item>
      )}
      <Form.Item
        label={t('item.fields.uom')}
        name={['uom']}
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Select
          {...uomSelectProps}
          onSearch={undefined}
          allowClear
          filterOption={true}
          optionFilterProp="label"
          disabled={!!disabledFields?.find((item) => item === 'uom') || isEdit}
        />
      </Form.Item>
      {!hideFields?.find((item) => item === 'source') && (
        <Form.Item label={t('item.fields.source')} name={['source']}>
          <Select
            options={[
              {
                label: t('item.fields.source_manufacture'),
                value: ItemSource.MANUFACTURE,
              },
              {
                label: t('item.fields.source_purchase'),
                value: ItemSource.PURCHASE,
              },
            ]}
            disabled={isEdit}
            allowClear
          />
        </Form.Item>
      )}

      {!hideFields?.find((item) => item === 'allowNegativeStock') && (
        <Styled.SwitchContainer style={{ display: 'none' }}>
          {t('item.fields.allow_negative_stock')}
          <Form.Item valuePropName="checked" name={['allowNegativeStock']}>
            <Switch
              checkedChildren={t('buttons.yes')}
              unCheckedChildren={t('buttons.no')}
            />
          </Form.Item>
        </Styled.SwitchContainer>
      )}

      {!hideFields?.find((item) => item === 'isStockItem') && (
        <Styled.SwitchContainer>
          {t('item.fields.maintain_stock')}
          <Tooltip title={t('item.fields.tooltip_stock_item')}>
            <InfoIcon />
          </Tooltip>
          <Form.Item
            valuePropName="checked"
            name={['isStockItem']}
            initialValue={true}
          >
            <Switch
              disabled={isEdit}
              checkedChildren={t('buttons.yes')}
              unCheckedChildren={t('buttons.no')}
            />
          </Form.Item>
        </Styled.SwitchContainer>
      )}

      {!hideFields?.find((item) => item === 'valueReference') && !isEdit && (
        <>
          <Typography.Text>
            {t('item.fields.value_reference_description')}
          </Typography.Text>
          <Typography.Paragraph type="secondary">
            {t('item.fields.value_reference_subdescription')}
          </Typography.Paragraph>
          <Form.Item
            label={t('item.fields.value_reference')}
            name={['valueReference']}
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Styled.InputMoney
              addonBefore={getCurrencySymbol(currentFarm?.currency)}
              disabled={isEdit}
              maxLength={12}
            />
          </Form.Item>
        </>
      )}
    </>
  );
};

export { ItemForm as default, ItemForm };
