import {
  useDrawerForm,
  UseDrawerFormReturnType,
  useTableReturnType,
} from '@refinedev/antd';
import { BaseKey, HttpError } from '@refinedev/core';
import { ROUTES } from 'common/constants';
import { CropIcon, FarmIcon, ItemsIcon, UpdateIcon } from 'common/icons';
import {
  ListFiltersModel,
  RefineTableFiltersEnum,
  useRefineTableFilters,
} from 'common/refine-table-filters';
import { useNavigateWithOrgAndFarm, useTracking } from 'common/utils';
import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { AgOperationsTypeEnum } from 'src/ag-operation-create/ag-operation.enum';
import { useFarm, useOrganization } from 'src/organization';

import {
  MaterialConsumptionModel,
  MaterialConsumptionRequestModel,
} from './material-consumption.model';
import { MaterialConsumptionEnum } from './status.enum';

interface UseMaterialConsumptionListReturn {
  tableReturn: useTableReturnType<MaterialConsumptionModel, HttpError>;
  createDrawer: UseDrawerFormReturnType<MaterialConsumptionRequestModel>;
  editDrawer: UseDrawerFormReturnType<MaterialConsumptionRequestModel>;
  listFilters: ListFiltersModel[];
  handleOnClickCreateMaterialConsumption: () => void;
  handleOnClickCreateAgOperation: (operationType: AgOperationsTypeEnum) => void;
  handleOnClickEditMaterialConsumption: (id: BaseKey) => void;
  entitlements: string[];
}

const useMaterialConsumptionList = (): UseMaterialConsumptionListReturn => {
  const { track } = useTracking();
  const { currentFarm } = useFarm();
  const { t } = useTranslation();
  const navigate = useNavigateWithOrgAndFarm();
  const { entitlements } = useOrganization();

  const listFilters: ListFiltersModel[] = useMemo(
    () => [
      {
        label: t('material_consumption.table.farm'),
        field: 'farm',
        type: RefineTableFiltersEnum.MULTIPLE_SELECT,
        resource: 'farm',
        show: true,
        icon: <FarmIcon />,
      },
      {
        label: t('material_consumption.table.crop_zone'),
        field: 'cropZone',
        type: RefineTableFiltersEnum.MULTIPLE_SELECT,
        resource: 'crop-cycle',
        show: false,
        icon: <CropIcon />,
        optionLabel: 'projectName',
      },
      {
        label: t('material_consumption.table.status'),
        field: 'status',
        type: RefineTableFiltersEnum.MULTIPLE_SELECT,
        show: true,
        initialValue: MaterialConsumptionEnum.CONFIRMED,
        icon: <UpdateIcon />,
        options: [
          {
            label: t('material_consumption.status.cancelled'),
            value: MaterialConsumptionEnum.CANCELLED,
          },
          {
            label: t('material_consumption.status.confirmed'),
            value: MaterialConsumptionEnum.CONFIRMED,
          },
          {
            label: t('material_consumption.status.draft'),
            value: MaterialConsumptionEnum.DRAFT,
          },
        ],
      },
      {
        label: t('material_consumption.filter.item'),
        field: 'itemCode',
        optionLabel: 'name',
        type: RefineTableFiltersEnum.SELECT,
        resource: 'item',
        icon: <ItemsIcon />,
        optionComplementaryLabel: 'category',
        show: false,
      },
    ],
    [t],
  );

  const tableReturn = useRefineTableFilters<
    MaterialConsumptionModel,
    HttpError
  >({
    listFilters,
    resource: 'stock-entry',
  });

  const createDrawer = useDrawerForm<MaterialConsumptionRequestModel>({
    action: 'create',
    resource: 'stock-entry',
    redirect: false,
    syncWithLocation: {
      key: 'create',
    },
  });

  const editDrawer = useDrawerForm<MaterialConsumptionRequestModel>({
    action: 'edit',
    resource: 'stock-entry',
    redirect: false,
    syncWithLocation: {
      key: 'edit',
    },
  });

  const handleOnClickCreateMaterialConsumption = () => {
    track('Start Material Consumption');
    createDrawer.show();
  };

  const handleOnClickCreateAgOperation = (
    operationType: AgOperationsTypeEnum,
  ) => {
    navigate(ROUTES.AG_OPERATION_CREATE, undefined, undefined, {
      operationType,
    });
  };

  const handleOnClickEditMaterialConsumption = (id: BaseKey) => {
    track('Edit Material Consumption');
    editDrawer.show(id);
  };

  useEffect(() => {
    if (currentFarm && tableReturn.searchFormProps) {
      tableReturn.searchFormProps.form?.setFieldsValue({
        farm: [currentFarm.name],
      });
      tableReturn.searchFormProps.form?.submit();
    }
  }, [currentFarm, tableReturn.searchFormProps.form?.setFieldValue]);

  return {
    entitlements,
    tableReturn,
    createDrawer,
    editDrawer,
    listFilters,
    handleOnClickCreateMaterialConsumption,
    handleOnClickEditMaterialConsumption,
    handleOnClickCreateAgOperation,
  };
};

export { useMaterialConsumptionList };
