import { InitCWElements } from '@cw-elements/config';
import { PLATFORM_DATA_CONFIG } from 'common/constants';

const CW_ELEMENTS_ENV = import.meta.env.VITE_CW_ENV ?? '';
const CW_ELEMENTS_URL = import.meta.env.VITE_CW_ELEMENTS_URL ?? '';

const CW_ELEMENTS_CONFIG: InitCWElements = {
  environment: CW_ELEMENTS_ENV,
  currentAppId: PLATFORM_DATA_CONFIG.APP_ID,
};

export {
  CW_ELEMENTS_CONFIG,
  CW_ELEMENTS_ENV,
  CW_ELEMENTS_URL,
  CW_ELEMENTS_CONFIG as default,
};
