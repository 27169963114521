import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { BlueColors, GreenColors, PurpleColors, RedColors } from 'theme';

import { AccountsPayableStatusEnum } from '../accounts-payable.enum';
import * as Styled from './accounts-payable-status.style';

interface AccountsPayableStatusProps {
  status: AccountsPayableStatusEnum;
}

const AccountsPayableStatus = ({ status }: AccountsPayableStatusProps) => {
  const { t } = useTranslation();

  const textColor = useMemo(() => {
    switch (`${status}`) {
      case AccountsPayableStatusEnum.OVERDUE:
        return RedColors[60];
      case AccountsPayableStatusEnum.TO_PAY:
        return PurpleColors[60];
      case AccountsPayableStatusEnum.PAID:
        return GreenColors[60];
      case AccountsPayableStatusEnum.PARTIALLY_PAID:
      default:
        return BlueColors[60];
    }
  }, [status]);

  const backgroundColor = useMemo(() => {
    switch (`${status}`) {
      case AccountsPayableStatusEnum.OVERDUE:
        return RedColors[10];
      case AccountsPayableStatusEnum.TO_PAY:
        return PurpleColors[10];
      case AccountsPayableStatusEnum.PAID:
        return GreenColors[10];
      case AccountsPayableStatusEnum.PARTIALLY_PAID:
      default:
        return BlueColors[10];
    }
  }, [status]);

  const borderColor = useMemo(() => {
    switch (`${status}`) {
      case AccountsPayableStatusEnum.OVERDUE:
        return RedColors[30];
      case AccountsPayableStatusEnum.TO_PAY:
        return PurpleColors[30];
      case AccountsPayableStatusEnum.PAID:
        return GreenColors[30];
      case AccountsPayableStatusEnum.PARTIALLY_PAID:
      default:
        return BlueColors[30];
    }
  }, [status]);

  return (
    <Styled.Tag
      textColor={textColor}
      backgroundColor={backgroundColor}
      borderColor={borderColor}
      data-testid="accounts-payable-status-tag"
    >
      {t(`accounts_payable.status.${status}`)}
    </Styled.Tag>
  );
};

export { AccountsPayableStatus };
