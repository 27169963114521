import styled from 'styled-components';
import { NeutralColors } from 'theme';

interface ParagraphProps {
  color?: string;
}

const Ul = styled.ul`
  padding-left: 16px;
`;

const Paragraph = styled.p<ParagraphProps>`
  margin: 0;
  color: ${(props) => props.color ?? NeutralColors[80]};
`;

export { Paragraph, Ul };
