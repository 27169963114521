import { Typography } from 'antd';
import styled from 'styled-components';

const WidgetContainer = styled.div`
  width: 100%;
  height: 160px;
`;

const ContainerText = styled.div`
  display: flex;
  flex-direction: column;
`;

const AmountLines = styled(Typography.Text)`
  margin-top: -4px !important;

  span {
    font-size: 30px;
    font-weight: 600;
  }
`;

export { AmountLines, ContainerText, WidgetContainer };
