interface SimpleItemModel {
  id: string;
  name: string;
  code: string;
  category: string;
}

interface ItemModel extends SimpleItemModel {
  description: string;
  uom: string;
  source: string;
  allowNegativeStock: boolean;
  actualQtyStock?: number;
  isStockItem: boolean;
  valuationMethod: string;
  defaults: ItemDefaults[];
  itemType?: string;
  cropId?: string;
  stockUom?: string;
  valueReference?: number;
}

interface ItemDetailPurchaseModel extends ItemModel {
  warehouse: string;
  qty: number;
  stockUom: string;
  costCenter: string;
  itemCode: string;
  receivedQty: number;
  rate: number;
  itemGroup: string;
  expenseAccount: string;
  lastPurchaseRate: number;
  poDetailReceipt: string;
  purchaseOrder: string;
  itemName: string;
  lockedQty: number;
  totalQtyOrder: number;
  idx: number;
}

interface ItemDefaults {
  farm: string;
  defaultBuyingCostCenter: string;
  defaultExpenseAccount: string;
  defaultWarehouse: string;
}

interface ItemBasicRate {
  actualQty: number;
  basicRate: number;
}

enum ItemSource {
  PURCHASE = 'PURCHASE',
  MATERIAL_TRANSFER = 'MATERIAL_TRANSFER',
  MATERIAL_ISSUE = 'MATERIAL_ISSUE',
  MANUFACTURE = 'MANUFACTURE',
  CUSTOMER_PROVIDED = 'CUSTOMER_PROVIDED',
}

enum ItemValuationMethod {
  FIFO = 'FIFO',
  MOVING_AVERAGE = 'MOVING_AVERAGE',
  LIFO = 'LIFO',
}

interface CatalogModel {
  name: string;
  id: string;
  category: string;
  stockUom: string;
}

export {
  CatalogModel,
  ItemBasicRate,
  ItemDetailPurchaseModel,
  ItemModel,
  ItemSource,
  ItemValuationMethod,
  SimpleItemModel,
};
