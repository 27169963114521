import { FormListFieldData, SelectProps } from 'antd';
import { SelectTag } from 'common/components';
import {
  ReceiveColoredIcon,
  WalletColoredIcon,
  WarehouseIcon,
} from 'common/icons';
import { useTranslation } from 'react-i18next';
import { ItemCategoryFormFarmTestId } from 'src/resources/item-category/item-category.constant';

import { ItemCategoryDefaultSetting } from '../../../item-category.model';
import * as Styled from './item-category-form-farm.style';

interface ItemCategoryFormItemContainerProps {
  defaultSetting: FormListFieldData;
  newDefaultSetting?: ItemCategoryDefaultSetting | null;
  warehouseSelectOptions?: SelectProps['options'];
  expenseAccountSelectOptions?: SelectProps['options'];
  costCenterSelectOptions?: SelectProps['options'];
  warehouseListLoading?: boolean;
  expenseAccountListLoading?: boolean;
  costCenterListLoading?: boolean;
}

const ItemCategoryFormFarmContainer = ({
  defaultSetting,
  newDefaultSetting,
  warehouseSelectOptions,
  expenseAccountSelectOptions,
  costCenterSelectOptions,
  warehouseListLoading,
  expenseAccountListLoading,
  costCenterListLoading,
}: ItemCategoryFormItemContainerProps) => {
  const { t } = useTranslation();

  return (
    <Styled.Box>
      <Styled.ContainerTitle>
        <Styled.Flex>
          <strong
            data-testid={ItemCategoryFormFarmTestId.ITEM_CATEGORY_FORM_FARM}
          >
            {newDefaultSetting?.farm}
          </strong>
        </Styled.Flex>
      </Styled.ContainerTitle>
      <SelectTag
        options={warehouseSelectOptions}
        loading={warehouseListLoading}
        Icon={WarehouseIcon}
        isOpenSelect
        labelSelect={t('item_category.form.farms.warehouse') ?? ''}
        nameSelect={[defaultSetting.name, 'warehouse']}
        errorMessage={
          t('item_category.form.farms.error_message_warehouse') ?? ''
        }
        data-testid={
          ItemCategoryFormFarmTestId.ITEM_CATEGORY_FORM_FARM_WAREHOUSE
        }
      >
        {newDefaultSetting?.warehouse ?? ''}
      </SelectTag>
      <SelectTag
        options={costCenterSelectOptions}
        loading={costCenterListLoading}
        Icon={WalletColoredIcon}
        isOpenSelect
        labelSelect={t('item_category.form.farms.cost_center') ?? ''}
        nameSelect={[defaultSetting.name, 'buyingCostCenter']}
        errorMessage={
          t('item_category.form.farms.error_message_cost_center') ?? ''
        }
        data-testid={
          ItemCategoryFormFarmTestId.ITEM_CATEGORY_FORM_FARM_COST_CENTER
        }
      >
        {newDefaultSetting?.buyingCostCenter ?? ''}
      </SelectTag>
      <SelectTag
        options={expenseAccountSelectOptions}
        loading={expenseAccountListLoading}
        Icon={ReceiveColoredIcon}
        isOpenSelect
        labelSelect={t('item_category.form.farms.expense_account') ?? ''}
        nameSelect={[defaultSetting.name, 'expenseAccount']}
        errorMessage={
          t('item_category.form.farms.error_message_expense_account') ?? ''
        }
        data-testid={ItemCategoryFormFarmTestId.ITEM_CATEGORY_FORM_FARM_ACCOUNT}
      >
        {newDefaultSetting?.expenseAccount ?? ''}
      </SelectTag>
    </Styled.Box>
  );
};

export {
  ItemCategoryFormFarmContainer as default,
  ItemCategoryFormFarmContainer,
};
