import { Form, FormListFieldData } from 'antd';
import { useWatch } from 'antd/es/form/Form';
import { CurrencyValue } from 'common/components';
import {
  ChevronBottomIcon,
  ChevronTopIcon,
  ElectronicInvoiceIcon,
} from 'common/icons';
import dayjs from 'dayjs';
import { useState } from 'react';
import { ElectronicInvoicesRequest } from 'src/electronic-invoice/electronic-invoice.model';
import { generateExternalIdMask } from 'src/electronic-invoice/electronic-invoice.util';
import { PurchaseDetailModel } from 'src/orders-and-payments/purchase';

import { ElectronicInvoiceDetails } from './electronic-invoice-details/electronic-invoice-details.component';
import * as Styled from './electronic-invoice-item.style';
import { ItemLink } from './item-link/item-link.component';

interface ElectronicInvoiceItemProps {
  itemFieldData: FormListFieldData;
  purchaseOrder?: PurchaseDetailModel;
  openCreateItem: () => void;
}

const ElectronicInvoiceItem = ({
  itemFieldData,
  purchaseOrder,
  openCreateItem,
}: ElectronicInvoiceItemProps) => {
  const [open, setOpen] = useState(true);
  const [isEditing, setIsEditing] = useState(false);
  const electronicInvoice: ElectronicInvoicesRequest = useWatch([
    'electronicInvoices',
    itemFieldData.name,
  ]);

  const handleOnClickCollapse = () => {
    setOpen(!open);
  };

  if (!electronicInvoice) {
    return null;
  }

  return (
    <Styled.ContainerFlex>
      {open && (
        <ElectronicInvoiceDetails electronicInvoice={electronicInvoice} />
      )}
      <Styled.Container>
        <Styled.Header>
          <Styled.CollapseButton
            onClick={handleOnClickCollapse}
            disabled={isEditing}
          >
            {open ? <ChevronTopIcon /> : <ChevronBottomIcon />}
          </Styled.CollapseButton>
          <span>
            <ElectronicInvoiceIcon />
          </span>
          <div>{generateExternalIdMask(electronicInvoice.externalId)}</div>
          <CurrencyValue value={electronicInvoice.totalValue} />
          <span>
            {dayjs(
              `${electronicInvoice.issuedDate}T${electronicInvoice.issuedDateTime}`,
            ).format('D MMM YYYY')}
          </span>
        </Styled.Header>
        <Styled.ContainerItems>
          {open && (
            <Form.List name={[itemFieldData.name, 'items']}>
              {(items) => (
                <>
                  {items.map((item) => (
                    <ItemLink
                      key={item.key}
                      itemFieldData={item}
                      fatherItemFieldData={itemFieldData}
                      isEditing={isEditing}
                      setIsEditing={setIsEditing}
                      openCreateItem={openCreateItem}
                      purchaseOrder={purchaseOrder}
                    />
                  ))}
                </>
              )}
            </Form.List>
          )}
        </Styled.ContainerItems>
      </Styled.Container>
    </Styled.ContainerFlex>
  );
};

export { ElectronicInvoiceItem };
