import { useSimpleList } from '@refinedev/antd';
import { HttpError } from '@refinedev/core';
import { Badge, Button, Popover, Typography } from 'antd';
import { ROUTES } from 'common/constants';
import { AlertTriangleIcon, SettingsIcon } from 'common/icons';
import { FarmModel } from 'common/models';
import { useNavigateWithOrgAndFarm } from 'common/utils';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useOrganization } from 'src/organization';

import * as Styled from './settings-button.style';

const SettingsButtonContainer = () => {
  const { t } = useTranslation();
  const navigate = useNavigateWithOrgAndFarm();
  const { currentOrganizationId, isLoading } = useOrganization();
  const [farmsMissingCostCenter, setFarmsMissingCostCenter] = useState<
    FarmModel[]
  >([]);

  const metadata = useMemo(
    () => ({
      organizationId: currentOrganizationId,
    }),
    [currentOrganizationId],
  );

  const needToSetCostCenter = useMemo(
    () => farmsMissingCostCenter.length > 0,
    [farmsMissingCostCenter.length],
  );

  const enableRequest = useMemo(
    () => !!(metadata.organizationId ?? !isLoading),
    [isLoading, metadata.organizationId],
  );

  const { listProps } = useSimpleList<FarmModel, HttpError>({
    resource: 'farm',
    pagination: {
      pageSize: 100,
    },
    queryOptions: {
      enabled: enableRequest,
    },
    meta: metadata,
  });

  const handleSettingsClick = useCallback(
    () => navigate(ROUTES.SETTINGS),
    [navigate],
  );

  useEffect(() => {
    if (listProps.dataSource) {
      const farmsWithoutCostCenter = listProps?.dataSource?.filter(
        (farm) => farm.costCenter === null || farm.costCenter === '',
      );

      setFarmsMissingCostCenter(farmsWithoutCostCenter);
    }
  }, [listProps.dataSource]);

  if (needToSetCostCenter) {
    return (
      <Popover
        placement="bottomRight"
        arrow={{ pointAtCenter: true }}
        content={
          <Styled.ContentWrapper>
            <AlertTriangleIcon />
            <Typography.Title level={5}>
              {t('settings.popover.description')}
            </Typography.Title>
            <Styled.WarningButton type="primary" onClick={handleSettingsClick}>
              {t('settings.popover.button')}
            </Styled.WarningButton>
          </Styled.ContentWrapper>
        }
      >
        <Badge
          size="small"
          offset={[2, 6]}
          count={farmsMissingCostCenter.length}
        >
          <Styled.WarningButton
            type="primary"
            icon={<SettingsIcon />}
            onClick={handleSettingsClick}
          />
        </Badge>
      </Popover>
    );
  }

  return (
    <Button type="text" icon={<SettingsIcon />} onClick={handleSettingsClick} />
  );
};

export { SettingsButtonContainer as default, SettingsButtonContainer };
