import { MarkerType } from 'common/charts/charts.model';
import { GreenColors } from 'theme';

import { GraphicShowEnum } from '../financial-overview.enum';
import { ResultItem } from '../financial-overview.model';

const validateTypeGraphic = (typeGraphic: GraphicShowEnum) =>
  typeGraphic === GraphicShowEnum.TOTAL_AMOUNT_SPENT;

const getLineInfoLabel = (typeGraphic: GraphicShowEnum) =>
  validateTypeGraphic(typeGraphic)
    ? 'financial.total_cost_label'
    : 'financial.total_cost_label_ha';

const getLineInfoValue = (typeGraphic: GraphicShowEnum, item: ResultItem) =>
  validateTypeGraphic(typeGraphic) ? item.total : item.totalPerHectare;

const getLineObjectivePercentage = (
  t: (key: string, data?: Record<string, unknown>) => string,
  typeGraphic: GraphicShowEnum,
  item: ResultItem,
) => {
  if (!item.productionTarget?.budgetTarget) {
    return '';
  }

  const percentage =
    typeGraphic === GraphicShowEnum.TOTAL_AMOUNT_SPENT
      ? (item.total / item.productionTarget?.budgetTarget) * 100
      : (item.totalPerHectare / item.productionTarget?.budgetTargetPerHectare) *
        100;

  if (percentage > 100) {
    return t('financial.objective_percentage_line', {
      percentage: 100,
    });
  } else if (percentage > 80) {
    return t('financial.objective_percentage_line', {
      percentage: percentage.toFixed(2),
    });
  } else {
    return '';
  }
};

const validatePotentialProductivity = (item: ResultItem) => {
  return item.productionTarget?.totalProduced > 0;
};

const validateExcpectedOrPotentialProduction = (item: ResultItem) => {
  return (
    item.productionTarget?.expectedProduction > 0 ||
    item.productionTarget?.totalProduced > 0
  );
};

const validateMarker = (item: ResultItem) => {
  return item.productionTarget?.budgetTarget > 0;
};

const getTemporaryGrossMargin = (item: ResultItem) => {
  return item.productionTarget?.temporaryGrossMargin < 0
    ? 0
    : item.productionTarget?.temporaryGrossMargin;
};

const getAreaFactor = (typeGraphic: GraphicShowEnum, item: ResultItem) => {
  return validateTypeGraphic(typeGraphic) ? 1 : item.declaredArea;
};

const getPotentialRevenueValue = (
  typeGraphic: GraphicShowEnum,
  item: ResultItem,
) => {
  const totalProduced = validateTypeGraphic(typeGraphic)
    ? item.productionTarget?.totalProduced
    : item.productionTarget?.totalProducedPerHectare;

  return totalProduced;
};

const getPotentialProfitMarkerValue = (
  typeGraphic: GraphicShowEnum,
  item: ResultItem,
) => {
  const temporaryGrossMargin = getTemporaryGrossMargin(item);

  const totalProduced = validateTypeGraphic(typeGraphic)
    ? item.productionTarget?.totalProduced
    : item.productionTarget?.totalProducedPerHectare;

  const areaFactor = getAreaFactor(typeGraphic, item);

  return totalProduced - temporaryGrossMargin / areaFactor;
};

const getExpectedRevenueValue = (
  typeGraphic: GraphicShowEnum,
  item: ResultItem,
) => {
  const expectedProduction = validateTypeGraphic(typeGraphic)
    ? item.productionTarget?.expectedProduction
    : item.productionTarget?.expectedProductionPerHectare;

  return expectedProduction;
};

const getExpectedProfitMarkerValue = (
  typeGraphic: GraphicShowEnum,
  item: ResultItem,
) => {
  const totalCost = validateTypeGraphic(typeGraphic)
    ? item.valuesFinal.map((value) => value.total).reduce((a, b) => a + b, 0)
    : item.valuesFinal
        .map((value) => value.totalPerHectare)
        .reduce((a, b) => a + b, 0);

  const expectedProduction = validateTypeGraphic(typeGraphic)
    ? item.productionTarget?.expectedProduction
    : item.productionTarget?.expectedProductionPerHectare;

  return expectedProduction < totalCost ? expectedProduction : totalCost;
};

const getPotentialProfitValue = (
  typeGraphic: GraphicShowEnum,
  item: ResultItem,
) => {
  const temporaryGrossMargin = getTemporaryGrossMargin(item);

  const areaFactor = getAreaFactor(typeGraphic, item);

  return temporaryGrossMargin / areaFactor;
};

const getExpectedProfitValue = (
  typeGraphic: GraphicShowEnum,
  item: ResultItem,
) => {
  const totalCost = validateTypeGraphic(typeGraphic)
    ? item.valuesFinal.map((value) => value.total).reduce((a, b) => a + b, 0)
    : item.valuesFinal
        .map((value) => value.totalPerHectare)
        .reduce((a, b) => a + b, 0);

  const expectedProduction = validateTypeGraphic(typeGraphic)
    ? item.productionTarget?.expectedProduction
    : item.productionTarget?.expectedProductionPerHectare;

  return expectedProduction > totalCost ? expectedProduction - totalCost : 0;
};

const getBudgetTargetValue = (
  typeGraphic: GraphicShowEnum,
  item: ResultItem,
) => {
  return validateTypeGraphic(typeGraphic)
    ? item.productionTarget?.budgetTarget
    : item.productionTarget?.budgetTargetPerHectare;
};

const getAreaValue = (
  typeGraphic: GraphicShowEnum,
  item: ResultItem,
  category: string,
) => {
  const foundValue = item.valuesFinal.find((value) => value.name === category);
  return validateTypeGraphic(typeGraphic)
    ? foundValue?.total ?? 0
    : foundValue?.totalPerHectare ?? 0;
};

const getProductivityColor = (item: ResultItem) => {
  return validatePotentialProductivity(item)
    ? GreenColors[50]
    : GreenColors[30];
};

const getProductivityValue = (
  typeGraphic: GraphicShowEnum,
  item: ResultItem,
) => {
  return validatePotentialProductivity(item)
    ? getPotentialRevenueValue(typeGraphic, item)
    : getExpectedRevenueValue(typeGraphic, item);
};

const getProductivityMarkerValue = (
  typeGraphic: GraphicShowEnum,
  item: ResultItem,
) => {
  return validatePotentialProductivity(item)
    ? getPotentialProfitMarkerValue(typeGraphic, item)
    : getExpectedProfitMarkerValue(typeGraphic, item);
};

const getProductivityMarkerLabelValue = (
  typeGraphic: GraphicShowEnum,
  item: ResultItem,
) => {
  return validatePotentialProductivity(item)
    ? getPotentialProfitValue(typeGraphic, item)
    : getExpectedProfitValue(typeGraphic, item);
};

const getProductivityMarkerType = (item: ResultItem) => {
  return validatePotentialProductivity(item)
    ? MarkerType.DIAMOND
    : MarkerType.DIAMOND_OUTLINE;
};

const getProductivityLabel = (item: ResultItem) => {
  return validatePotentialProductivity(item)
    ? 'financial.potential_revenue'
    : 'financial.expected_revenue';
};

const getProductivityMarkerLabel = (item: ResultItem) => {
  return validatePotentialProductivity(item)
    ? 'financial.potential_profit'
    : 'financial.expected_profit';
};

export {
  getAreaValue,
  getBudgetTargetValue,
  getExpectedProfitMarkerValue,
  getExpectedProfitValue,
  getExpectedRevenueValue,
  getLineInfoLabel,
  getLineInfoValue,
  getLineObjectivePercentage,
  getPotentialProfitMarkerValue,
  getPotentialProfitValue,
  getPotentialRevenueValue,
  getProductivityColor,
  getProductivityLabel,
  getProductivityMarkerLabel,
  getProductivityMarkerLabelValue,
  getProductivityMarkerType,
  getProductivityMarkerValue,
  getProductivityValue,
  validateExcpectedOrPotentialProduction,
  validateMarker,
  validatePotentialProductivity,
};
