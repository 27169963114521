import { Card as CardAntd } from 'antd';
import styled from 'styled-components';
import { NeutralColors } from 'theme';

const ContainerButton = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
`;

const Card = styled(CardAntd)`
  width: 250px;
  padding: 4px;
  cursor: pointer;
`;

const CardIcon = styled.div`
  border: 1px solid ${NeutralColors[30]};
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;

  svg {
    fill: ${NeutralColors[80]};
    max-width: 20px;
  }
`;

const Title = styled.h4`
  margin: 0px;
`;

const Description = styled.p`
  min-height: 22px;
`;

export { Card, CardIcon, ContainerButton, Description, Title };
