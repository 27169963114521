const RefineTableFiltersItemTestId = {
  REFINE_TABLE_FILTERS_ITEM_SELECT: 'refine-table-filters-item-select',
  REFINE_TABLE_FILTERS_ITEM_INPUT: 'refine-table-filters-item-input',
};

const RefineTableFiltersSelectTestId = {
  REFINE_TABLE_FILTERS_SELECT: 'refine-table-filters-select',
};

export { RefineTableFiltersItemTestId, RefineTableFiltersSelectTestId };
