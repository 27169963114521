import { HttpError, useList } from '@refinedev/core';
import { DrawerProps, FormInstance, FormProps } from 'antd';
import { ButtonType } from 'antd/lib/button';
import { FarmModel } from 'common/models';
import { useTracking } from 'common/utils';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { useOrganization } from '../../../organization';
import { ALL_ITEM_GROUPS } from '../item-category.constant';
import {
  ItemCategory,
  ItemCategoryDefaultSetting,
} from '../item-category.model';

const useItemCategoryForm = (
  formProps: FormProps,
  drawerProps: DrawerProps,
  formInstance: FormInstance,
) => {
  const { track } = useTracking();
  const [disableSave, setDisableSave] = useState<boolean>(true);
  const [showFarms, setShowFarms] = useState<boolean>(false);
  const [newItemCategory, setNewItemCategory] = useState<ItemCategory | null>(
    null,
  );
  const { currentOrganizationId } = useOrganization();

  const metadata = useMemo(
    () => ({
      organizationId: currentOrganizationId,
    }),
    [currentOrganizationId],
  );

  const { data: farmListData } = useList<FarmModel, HttpError>({
    resource: 'farm',
    pagination: {
      pageSize: 500,
    },
    meta: metadata,
    queryOptions: {
      enabled: !!currentOrganizationId && drawerProps.open,
    },
  });

  const formInitialValues: Partial<ItemCategory> | undefined = useMemo(() => {
    const editValues = formProps.initialValues as ItemCategory;
    const parentId =
      formProps.initialValues?.parentId === ALL_ITEM_GROUPS
        ? ''
        : formProps.initialValues?.parentId;

    if (farmListData?.data) {
      const itemCategoryDefaultSetting: ItemCategoryDefaultSetting[] =
        farmListData.data.map((farm) => {
          const defaultSettingEdit = editValues?.defaultSettings?.find(
            (settings) => settings.farm === farm.id,
          );
          return {
            ...defaultSettingEdit,
            farm: farm.id,
          };
        });
      return {
        isGroup: false,
        ...formProps.initialValues,
        parentId,
        defaultSettings: itemCategoryDefaultSetting,
      };
    }
  }, [farmListData?.data, formProps.initialValues]);

  const detailTabClassname = useMemo<ButtonType | undefined>(
    () => (showFarms ? undefined : 'primary'),
    [showFarms],
  );

  const disableFarmsTab = useMemo(
    () => !newItemCategory?.name,
    [newItemCategory?.name],
  );

  const farmsTabClassname = useMemo<ButtonType | undefined>(
    () => (showFarms ? 'primary' : undefined),
    [showFarms],
  );

  useEffect(() => {
    if (!drawerProps.open) {
      setDisableSave(true);
      setShowFarms(false);
      setNewItemCategory({
        defaultSettings: formInitialValues?.defaultSettings ?? [],
        isGroup: formInitialValues?.isGroup ?? false,
        name: '',
        parent: '',
      });
      formProps.form?.resetFields();
    }
  }, [
    drawerProps.open,
    formInitialValues?.defaultSettings,
    formInitialValues?.isGroup,
    formProps.form,
  ]);

  const shouldDisableSave = useCallback((): boolean => {
    if (!newItemCategory) return true;

    return !newItemCategory.name;
  }, [newItemCategory]);

  useEffect(() => {
    formInstance
      .validateFields({ validateOnly: true })
      .then(() => {
        setDisableSave(shouldDisableSave());
      })
      .catch(() => {
        setDisableSave(true);
      });
  }, [shouldDisableSave, formInstance]);

  useEffect(() => {
    if (formInitialValues) {
      setNewItemCategory({
        defaultSettings: formInitialValues.defaultSettings ?? [],
        isGroup: formInitialValues.isGroup ?? false,
        name: formInitialValues.name ?? '',
        parent: formInitialValues.parent ?? '',
      });
    }
  }, [formInitialValues]);

  const handleFormValuesChange = (_: string, allValues: ItemCategory) => {
    setNewItemCategory(allValues);
  };

  const handleOnFinish = (values: ItemCategory) => {
    track('End Item Category');
    if (formProps.onFinish) {
      return formProps.onFinish({
        ...values,
        defaultSettings: values.defaultSettings?.filter(
          (defaultSetting) =>
            !!defaultSetting.warehouse ||
            !!defaultSetting.buyingCostCenter ||
            !!defaultSetting.expenseAccount,
        ),
      });
    }
  };

  return {
    newItemCategory,
    formInitialValues,
    disableSave,
    showFarms,
    detailTabClassname,
    disableFarmsTab,
    farmsTabClassname,
    setShowFarms,
    handleFormValuesChange,
    handleOnFinish,
  };
};

export { useItemCategoryForm as default, useItemCategoryForm };
