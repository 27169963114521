import { Button, Form } from 'antd';
import styled from 'styled-components';

interface ContainerProps {
  isShow: boolean;
}

const FormItem = styled(Form.Item)`
  margin-top: 24px;
`;

const Container = styled.div<ContainerProps>`
  display: ${(props) => (props.isShow ? 'block' : 'none')};
  position: relative;
  height: 100%;
  padding-bottom: 48px;
`;

const ContainerButton = styled.div`
  position: fixed;
  bottom: 48px;
  width: calc(100% - 48px);
  max-width: 352px;
`;

const ButtonAddItem = styled(Button)`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export { ButtonAddItem, Container, ContainerButton, FormItem };
