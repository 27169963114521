import { useDrawerForm } from '@refinedev/antd';
import { HttpError } from '@refinedev/core';
import { useTracking } from 'common/utils';
import { useState } from 'react';

import { ItemModel } from './item.model';

const useCreateItemDrawer = (
  syncWithLocationKey?: string,
  onSuccessCallback?: () => void,
) => {
  const [resourceCreate, setResourceCreate] = useState('item');
  const { track } = useTracking();

  const itemDrawer = useDrawerForm<ItemModel, HttpError, ItemModel>({
    action: 'create',
    resource: resourceCreate,
    redirect: false,
    onMutationSuccess: () => {
      onSuccessCallback && onSuccessCallback();
    },
    dataProviderName:
      resourceCreate === 'item/product-final' ? 'default' : 'defaultV2',
    syncWithLocation: syncWithLocationKey
      ? { key: syncWithLocationKey }
      : false,
  });

  const handleOpenItemDrawer = () => {
    track('Start Item Creation');
    itemDrawer.show();
  };

  const handleCloseItemDrawer = () => {
    track('End Item Creation');
    itemDrawer.close();
  };

  return {
    createItemDrawer: {
      ...itemDrawer,
      close: handleCloseItemDrawer,
    },
    setResourceCreate,
    handleOpenItemDrawer,
  };
};

export { useCreateItemDrawer };
