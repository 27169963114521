import { Space, Tooltip, Typography } from 'antd';
import { CurrencyValue } from 'common/components';
import {
  FieldColoredIcon,
  ReceiveColoredIcon,
  WalletColoredIcon,
  WarehouseIcon,
} from 'common/icons';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

import { MovementType } from '../../movements.enum';
import { MovementsModel } from '../../movements.model';
import { getMovementTransferType } from '../../movements.util';
import * as Styled from './details-drawer-card.style';

interface DetailsDrawerCardProps {
  movement: MovementsModel;
}

const MovementsDetailsDrawerCardComponent = ({
  movement,
}: DetailsDrawerCardProps) => {
  const { t, i18n } = useTranslation();

  return (
    <Styled.Card
      transferType={getMovementTransferType(movement)}
      title={
        <Typography.Text ellipsis={{ tooltip: { title: movement.itemName } }}>
          {movement.itemName}
        </Typography.Text>
      }
      extra={
        <>
          <Typography.Text>
            {dayjs(movement.postingDate).locale(i18n.language).format('MMM')}
          </Typography.Text>
          <Typography.Text style={{ fontSize: 20 }}>
            {` ${dayjs(movement.postingDate).format('D')}, `}
          </Typography.Text>
          <Typography.Text>
            {dayjs(movement.postingDate).format('YYYY')}
          </Typography.Text>
        </>
      }
    >
      {movement.warehouse && (
        <Space align={'center'}>
          <Tooltip title={t('stock.movements.drawer.warehouse')}>
            <WarehouseIcon />
          </Tooltip>
          <Styled.Paragraph>
            <Typography.Text>
              {t(
                `stock.movements.drawer.${
                  movement.quantity < 0 ? 'withdrawn_from' : 'received_on'
                }`,
              )}
            </Typography.Text>
            <Typography.Text strong>{movement.warehouse}</Typography.Text>
          </Styled.Paragraph>
        </Space>
      )}
      {movement.costCenter && (
        <Space align={'center'}>
          <Tooltip title={t('stock.movements.drawer.cost_center')}>
            <WalletColoredIcon />
          </Tooltip>
          <Styled.Paragraph>
            <Typography.Text>
              {t('stock.movements.drawer.charged_to')}
            </Typography.Text>
            <Typography.Text strong>{movement.costCenter}</Typography.Text>
          </Styled.Paragraph>
        </Space>
      )}
      {movement.expenseAccount && (
        <Space align={'center'}>
          <Tooltip title={t('stock.movements.drawer.expense_account')}>
            <ReceiveColoredIcon />
          </Tooltip>
          <Styled.Paragraph>
            <Typography.Text>
              {t('stock.movements.drawer.added_to')}
            </Typography.Text>
            <Typography.Text strong>{movement.expenseAccount}</Typography.Text>
          </Styled.Paragraph>
        </Space>
      )}
      {movement.locationName && (
        <Space align={'center'}>
          <Tooltip title={t('stock.movements.drawer.location')}>
            <FieldColoredIcon />
          </Tooltip>
          <Styled.Paragraph>
            <Typography.Text>{t('stock.movements.drawer.in')}</Typography.Text>
            <Typography.Text strong>{movement.locationName}</Typography.Text>
          </Styled.Paragraph>
        </Space>
      )}
      {movement.description && (
        <>
          <Styled.Divider orientation="left" orientationMargin={0}>
            <Typography.Title level={5}>
              {t('stock.movements.drawer.description')}
            </Typography.Title>
          </Styled.Divider>
          <Typography.Paragraph>{movement.description}</Typography.Paragraph>
        </>
      )}
      <Styled.Divider orientation="left" orientationMargin={0}>
        <Typography.Title level={5}>
          {t('stock.movements.drawer.movement')}
        </Typography.Title>
      </Styled.Divider>

      {movement.movement === MovementType.STOCK_ADJUST ? (
        <>
          <Styled.Row justify="space-between">
            <Typography.Text>
              {t('stock.movements.drawer.previous_qty')}
            </Typography.Text>
            <Typography.Text>{`${movement.lastQty} ${movement.stockUom}`}</Typography.Text>
          </Styled.Row>
          <Styled.Row justify="space-between">
            <Typography.Text>
              {t('stock.movements.drawer.qty_after_adjustment')}
            </Typography.Text>
            <Typography.Text
              type={movement.actualQty < 0 ? 'danger' : undefined}
            >{`${movement.balanceQty} ${movement.stockUom}`}</Typography.Text>
          </Styled.Row>
        </>
      ) : (
        <>
          <Styled.Row justify="space-between">
            <Typography.Text strong>
              {t('stock.movements.drawer.quantity')}
            </Typography.Text>
            <Typography.Text
              strong
              type={movement.quantity < 0 ? 'danger' : undefined}
            >{`${movement.quantity} ${movement.stockUom}`}</Typography.Text>
          </Styled.Row>
          <Styled.Row justify="space-between">
            <Typography.Text>
              {t('stock.movements.drawer.balance')}
            </Typography.Text>
            <Typography.Text
              type={movement.balanceQty < 0 ? 'danger' : undefined}
            >{`${movement.balanceQty} ${movement.stockUom}`}</Typography.Text>
          </Styled.Row>
          <Styled.Row justify="space-between">
            <Typography.Text>
              {t('stock.movements.drawer.actual_qty')}
            </Typography.Text>
            <Typography.Text
              type={movement.actualQty < 0 ? 'danger' : undefined}
            >{`${movement.actualQty} ${movement.stockUom}`}</Typography.Text>
          </Styled.Row>
        </>
      )}

      <Styled.Divider orientation="left" orientationMargin={0}>
        <Typography.Title level={5}>
          {t('stock.movements.drawer.value')}
        </Typography.Title>
      </Styled.Divider>
      <Styled.Row justify="space-between">
        <Typography.Text strong>
          {t('stock.movements.drawer.value_rate')}
        </Typography.Text>
        <Typography.Text
          strong
          type={movement.valuationRate < 0 ? 'danger' : undefined}
        >
          <CurrencyValue
            value={movement.valuationRate}
            currency={movement.currency}
          />
        </Typography.Text>
      </Styled.Row>
      <Styled.Row justify="space-between">
        <Typography.Text>
          {t('stock.movements.drawer.amount_value')}
        </Typography.Text>
        <Typography.Text
          type={movement.amountMovement < 0 ? 'danger' : undefined}
        >
          <CurrencyValue
            value={movement.amountMovement}
            currency={movement.currency}
          />
        </Typography.Text>
      </Styled.Row>
      <Styled.Row justify="space-between">
        <Typography.Text>
          {t('stock.movements.drawer.balance_value')}
        </Typography.Text>
        <Typography.Text
          type={movement.balanceValue < 0 ? 'danger' : undefined}
        >
          <CurrencyValue
            value={movement.balanceValue}
            currency={movement.currency}
          />
        </Typography.Text>
      </Styled.Row>
    </Styled.Card>
  );
};

export {
  MovementsDetailsDrawerCardComponent as default,
  MovementsDetailsDrawerCardComponent,
};
