import styled from 'styled-components';
import { NeutralColors } from 'theme';

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const Paragraph = styled.p`
  margin: 0;
  color: ${NeutralColors[50]};
`;

export { Container, Paragraph };
