import { InputMoney as InputMoneyComponent } from 'common/components';
import styled from 'styled-components';

const SwitchContainer = styled.div`
  display: flex;
  align-items: center;

  & ~ & {
    margin-top: 8px;
  }

  svg {
    margin-left: 8px;
  }

  .fms-form-item {
    margin-left: 16px;
    margin-bottom: 0;
  }
`;

const InputMoney = styled(InputMoneyComponent)`
  width: 100%;
`;

export { InputMoney, SwitchContainer };
