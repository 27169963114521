import { Form } from 'antd';
import { SelectWithButton } from 'common/components';
import styled from 'styled-components';

interface FormItemProps {
  width?: string;
}

const LineLink = styled.div`
  height: 52px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 4px 16px;
  gap: 12px;
  box-shadow: 0px 2px 8px 0px #00000026;
  border-radius: 8px;
`;

const Select = styled(SelectWithButton)`
  svg {
    display: none !important;
  }

  .fms-select-selector {
    border: none !important;
    background-color: transparent !important;
    outline: none;
  }
`;

const FormItem = styled(Form.Item)<FormItemProps>`
  margin: 0;
  width: ${(props) => props.width};
`;

export { FormItem, LineLink, Select };
