import { useDrawerForm } from '@refinedev/antd';
import { HttpError } from '@refinedev/core';
import { useTracking } from 'common/utils';

import { SupplierRequestModel } from './supplier.model';

const useSupplierCreateDrawer = (syncWithLocationKey?: string) => {
  const { track } = useTracking();

  const supplierCreateDrawer = useDrawerForm<
    SupplierRequestModel,
    HttpError,
    SupplierRequestModel
  >({
    action: 'create',
    resource: 'supplier',
    redirect: false,
    syncWithLocation: syncWithLocationKey
      ? { key: syncWithLocationKey }
      : false,
  });

  const handleOpenSupplierDrawer = () => {
    track('Start Supplier Creation');
    supplierCreateDrawer.show();
  };

  const handleCloseSupplierDrawer = () => {
    track('End Supplier Creation');
    supplierCreateDrawer.close();
  };

  return {
    createSupplierDrawer: {
      ...supplierCreateDrawer,
      close: handleCloseSupplierDrawer,
    },
    handleOpenSupplierDrawer,
  };
};

export { useSupplierCreateDrawer };
