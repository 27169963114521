import { Button } from 'antd';
import styled from 'styled-components';
import { NeutralColors } from 'theme';

interface BoxAmountProps {
  isSelected: boolean;
}

const BoxButtons = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 16px;
  border-radius: 8px;
  background-color: ${NeutralColors[10]};
`;

const ButtonSelected = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 48%;
  height: 40px;
`;

const BoxAmount = styled.div<BoxAmountProps>`
  border-radius: 4px;
  border: 1px solid
    ${(props) => (props.isSelected ? NeutralColors[0] : `${NeutralColors[70]}`)};
  padding: 0 8px;
  margin-left: 8px;
`;

const EditPopConfirmText = styled.div`
  width: 300px;
  text-align: justify;
`;

export { BoxAmount, BoxButtons, ButtonSelected, EditPopConfirmText };
