import { Button as ButtonAntD } from 'antd';
import styled from 'styled-components';
import { NeutralColors, YellowColors } from 'theme';

const WarningButton = styled(ButtonAntD)`
  background-color: ${YellowColors[40]};
  color: ${NeutralColors[0]};

  &:hover {
    background-color: ${YellowColors[50]} !important;
    color: ${NeutralColors[0]} !important;
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: center;
  text-align: center;
  max-width: 320px;
  padding: 16px;

  h5 {
    font-weight: 400 !important;
  }

  svg {
    width: 40px;
    height: 40px;
    color: ${YellowColors[40]};
  }
`;

export { ContentWrapper, WarningButton };
