import { CropZoneColumnTestId } from '../material-consumption.constants';
import * as Styled from './crop-zone-column.style';

interface CropZoneColumnProps {
  value?: string;
}

const CropZoneColumn = ({ value }: CropZoneColumnProps) => {
  if (!value) {
    return null;
  }

  return (
    <Styled.Tag data-testid={CropZoneColumnTestId.CROP_ZONE_COLUMN_CONTAINER}>
      {value}
    </Styled.Tag>
  );
};

export { CropZoneColumn };
