import { PageTitle } from 'common/components';
import { ROUTES } from 'common/constants';
import { useNavigateWithOrgAndFarm } from 'common/utils';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { useOrganization } from 'src/organization';
import EntitlementEnum from 'src/organization/entitlements.enum';

import { OrdersAndPaymentsEnum } from './orders-and-payments.enum';

interface OrdersAndPaymentsTitleProps {
  buttons?: ReactNode;
  selectedTab: OrdersAndPaymentsEnum;
}

const OrdersAndPaymentsTitle = ({
  buttons,
  selectedTab,
}: OrdersAndPaymentsTitleProps) => {
  const { t } = useTranslation();
  const navigate = useNavigateWithOrgAndFarm();
  const { entitlements } = useOrganization();

  const handleGoToTab = (route: string) => {
    navigate(route);
  };

  const tabs = [
    {
      key: OrdersAndPaymentsEnum.ACCOUNTS_PAYABLE,
      label: t('accounts_payable.title') ?? '',
      onClick: () => handleGoToTab(ROUTES.ACCOUNTS_PAYABLE),
      entitlement: EntitlementEnum.ACCOUNTS_PLAYABLE,
    },
    {
      key: OrdersAndPaymentsEnum.PURCHASE,
      label: t('purchase.title') ?? '',
      onClick: () => handleGoToTab(ROUTES.PURCHASE),
    },
    {
      key: OrdersAndPaymentsEnum.INVOICE,
      label: t('invoice.title') ?? '',
      onClick: () => handleGoToTab(ROUTES.INVOICE),
      entitlement: EntitlementEnum.INVOICES,
    },
  ];

  return (
    <PageTitle
      title={t('orders_and_payments.title')}
      tabs={tabs.filter(
        (tab) => !tab.entitlement || entitlements?.includes(tab.entitlement),
      )}
      tabsDefaultActiveKey={selectedTab}
      buttons={buttons}
    />
  );
};

export { OrdersAndPaymentsTitle };
