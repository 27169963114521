import { Form } from 'antd';
import styled from 'styled-components';
import { GreenColors } from 'theme';

interface ContainerProps {
  isShow: boolean;
}

const FormItem = styled(Form.Item)`
  margin-top: 24px;
`;

const Container = styled.div<ContainerProps>`
  display: ${(props) => (props.isShow ? 'block' : 'none')};
  position: relative;
  height: 100%;
  padding-bottom: 48px;
`;

const ContainerButton = styled.div`
  position: fixed;
  bottom: 48px;
  width: calc(100% - 48px);
  max-width: 352px;
`;

const GreenBox = styled.div<ContainerProps>`
  display: ${(props) => (props.isShow ? 'block' : 'none')};
  padding: 16px;
  margin-top: 24px;
  border-radius: 12px;
  border: 1px solid ${GreenColors[60]};
`;

const SwitchBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
`;

const SwitchForm = styled(Form.Item)`
  margin: 0;
`;

export {
  Container,
  ContainerButton,
  FormItem,
  GreenBox,
  SwitchBox,
  SwitchForm,
};
