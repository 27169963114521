import { BaseKey, useDelete } from '@refinedev/core';
import { FormInstance, FormProps } from 'antd';
import { useWatch } from 'antd/es/form/Form';
import { StockEntryEnum } from 'common/constants';
import { useTracking } from 'common/utils';
import dayjs from 'dayjs';
import { useEffect, useMemo } from 'react';
import { useCreateItemDrawer } from 'src/resources/item/use-item-create-drawer.hook';

import {
  MaterialConsumptionItemRequestModel,
  MaterialConsumptionRequestModel,
} from '../material-consumption.model';
import { useMaterialConsumptionFormStore } from './material-consumption-form.store';

const useMaterialConsumptionForm = (
  formProps: FormProps<MaterialConsumptionRequestModel>,
  formInstance: FormInstance,
  onClose: () => void,
  drawerOpen?: boolean,
) => {
  const { track } = useTracking();
  const { mutate: mutateDelete, isSuccess: isSuccessDelete } = useDelete();
  const { setDisableSave, setShowItems, setPersistState, persistState } =
    useMaterialConsumptionFormStore();

  const formValues = useWatch([], formInstance);

  useEffect(() => {
    if (isSuccessDelete) {
      onClose();
    }
  }, [isSuccessDelete]);

  useEffect(() => {
    setShowItems(false);
    formProps?.form?.resetFields();
  }, [drawerOpen, formProps?.form, setShowItems]);

  const initialValues: MaterialConsumptionRequestModel = useMemo(() => {
    if (persistState) {
      return {
        ...persistState,
        postingDate: dayjs(persistState.postingDate),
      };
    }
    return {
      id: formProps.initialValues?.id,
      farm: formProps.initialValues?.farm as string,
      isCropZone: !!formProps.initialValues?.cropZone,
      project: formProps.initialValues?.cropZone as string,
      seasonId: formProps.initialValues?.seasonId as string,
      postingDate: dayjs(formProps.initialValues?.postingDate) ?? dayjs(),
      stockEntryType: formProps.initialValues?.stockEntryType as string,
      costCenter: formProps.initialValues?.items?.[0]?.costCenter as string,
      description: formProps.initialValues?.description,
      items: formProps.initialValues?.items?.map(
        (item: MaterialConsumptionItemRequestModel) => ({
          itemCode: item.itemCode,
          sourceWarehouse: item.sourceWarehouse,
          basicRate: item.basicRate,
          expenseAccount: item.expenseAccount,
          qty: item.qty,
        }),
      ),
    };
  }, [formProps.initialValues, persistState]);

  const hasExternalId = useMemo(
    () => !!formProps.initialValues?.externalId2,
    [formProps.initialValues?.externalId2],
  );

  const isCanceled = useMemo(
    () => formProps.initialValues?.docstatus === 2,
    [formProps.initialValues?.docstatus],
  );

  useEffect(() => {
    formInstance
      .validateFields({ validateOnly: true })
      .then(() => {
        setDisableSave(false);
      })
      .catch(() => {
        setDisableSave(true);
      });
  }, [formValues, formInstance, setDisableSave]);

  const handleOnFinish = (values: MaterialConsumptionRequestModel) => {
    track('End Material Consumption');
    if (formProps.onFinish && formProps.form) {
      return formProps.onFinish({
        ...values,
        stockEntryType: StockEntryEnum.MATERIAL_CONSUMPTION,
        postingDate: dayjs(values.postingDate).format('YYYY-MM-DD'),
        items: values?.items.map((item) => ({
          itemCode: item.itemCode,
          sourceWarehouse: item.sourceWarehouse,
          basicRate: item.basicRate,
          costCenter: values.costCenter,
          expenseAccount: item.expenseAccount,
          allowZeroValuationRate: 1,
          qty: item.qty,
        })),
      });
    }
  };

  const disableItemsTab = useMemo(
    () =>
      !formValues?.postingDate ||
      !formValues?.farm ||
      !formValues?.costCenter ||
      !formValues?.seasonId ||
      (formValues?.isCropZone && !formValues?.project),
    [
      formValues?.postingDate,
      formValues?.farm,
      formValues?.costCenter,
      formValues?.seasonId,
      formValues?.isCropZone,
      formValues?.project,
    ],
  );

  const handleOnDelete = (id?: BaseKey) => {
    const externalId = formProps.initialValues?.externalId;
    const operationType = formProps.initialValues?.operationType;

    if (operationType && externalId) {
      track('Delete Material Consumption (AgOps)');
      mutateDelete({
        dataProviderName: 'platformV3',
        resource: 'operations/ag',
        id: externalId,
      });
    } else if (id) {
      track('Delete Material Consumption');
      mutateDelete({
        resource: 'stock-entry',
        id,
      });
    }
  };

  const handleOnClose = () => {
    track('Close Material Consumption');
    onClose();
    setPersistState(null);
  };

  const { createItemDrawer, handleOpenItemDrawer, setResourceCreate } =
    useCreateItemDrawer();

  return {
    handleOnClose,
    handleOnFinish,
    handleOnDelete,
    disableItemsTab,
    initialValues,
    hasExternalId,
    isCanceled,
    createItemDrawer,
    handleOpenItemDrawer,
    setResourceCreateItem: setResourceCreate,
  };
};

export { useMaterialConsumptionForm };
