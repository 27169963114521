import { FormattedDate } from 'common/components';
import { BarcodeIcon, BoxIcon } from 'common/icons';

import { AccountsPayableEnum } from '../accounts-payable.enum';
import { AccountsPayableModel } from '../accounts-payable.model';
import * as Styled from './accounts-payable-due-date.style';

interface AccountsPayableDueDateProps {
  accountsPayable: AccountsPayableModel;
}

const AccountsPayableDueDate = ({
  accountsPayable,
}: AccountsPayableDueDateProps) => {
  return (
    <Styled.Container>
      <Styled.BoxIcon>
        {accountsPayable.type === AccountsPayableEnum.PURCHASE_INVOICE ? (
          <BarcodeIcon data-testid="barcode-icon" />
        ) : (
          <BoxIcon data-testid="box-icon" />
        )}
      </Styled.BoxIcon>

      <Styled.ContainerText>
        <Styled.ExternalId>{accountsPayable.externalId}</Styled.ExternalId>
        <Styled.Date>
          <FormattedDate date={accountsPayable.dueDate} />
        </Styled.Date>
      </Styled.ContainerText>
    </Styled.Container>
  );
};

export { AccountsPayableDueDate };
