export enum MaterialConsumptionFormTestId {
  MATERIAL_CONSUMPTION_FORM = 'type-column-form-fms',
}

export enum MaterialConsumptionFormItemTestId {
  MATERIAL_CONSUMPTION_FORM_ITEM_SEARCH_ITEM = 'material-consumption-form-item-search-item',
  MATERIAL_CONSUMPTION_FORM_ITEM_DETAILS = 'material-consumption-form-item-details',
  MATERIAL_CONSUMPTION_FORM_ITEM_DELETE = 'material-consumption-form-item-delete',
  MATERIAL_CONSUMPTION_FORM_ITEM_SHOW = 'material-consumption-form-item-show',
}
