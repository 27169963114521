import { Form, Typography } from 'antd';
import styled from 'styled-components';
import { NeutralColors } from 'theme';

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  background-color: ${NeutralColors[0]};
  border-radius: 12px 0 12px 12px;
  padding: 16px;
  margin-bottom: 16px;
`;

const Text = styled(Typography.Paragraph)`
  margin: 0 !important;
  display: flex;
  align-items: center;
  gap: 16px;
  color: ${NeutralColors[100]};

  span {
    color: ${NeutralColors[50]};
  }
`;

const ContainerButtons = styled.div`
  display: flex;
  gap: 8px;
`;

const FormItem = styled(Form.Item)`
  margin: 0 !important;
`;

export { Container, ContainerButtons, FormItem, Text };
