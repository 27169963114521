import { useDelete, useList, useMany, useOne } from '@refinedev/core';
import dayjs from 'dayjs';
import { Key, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useOrganization } from 'src/organization';

import { InvoiceDetailModel, InvoiceModel } from '../../invoice/invoice.model';
import {
  PurchaseDetailModel,
  PurchasePaymentModel,
  PurchaseReceiptModel,
} from '../purchase.model';
import { useInvalidateTableData } from '../use-invalidate-table-data.hook';
import { InvoiceReceiptTableModel } from './invoice-receipt-table.model';

const useInvoiceReceiptTable = (purchaseId: string) => {
  const { t } = useTranslation();
  const { currentOrganizationId } = useOrganization();
  const [invoiceReceiptData, setInvoiceReceiptData] = useState<
    InvoiceReceiptTableModel[]
  >([]);
  const [isInvoiceReceiptProcessed, setIsInvoiceReceiptProcessed] =
    useState(false);
  const { mutate: mutateDelete } = useDelete();

  const { refetchTableAndInvalidate } = useInvalidateTableData();

  const metadata = useMemo(
    () => ({
      organizationId: currentOrganizationId,
    }),
    [currentOrganizationId],
  );

  const { data: purchaseDetail, isFetched: isFetchedPurchaseDetail } =
    useOne<PurchaseDetailModel>({
      resource: 'purchase-order',
      id: purchaseId,
      meta: metadata,
    });

  const { data: invoiceList, isFetched: isFetchedInvoiceList } =
    useList<InvoiceModel>({
      resource: 'purchase-invoice',
      filters: [
        {
          field: 'purchaseOrderId',
          operator: 'eq',
          value: purchaseId,
        },
      ],
      pagination: {
        current: 1,
        pageSize: 1000,
      },
      sorters: [
        {
          field: 'posting_date',
          order: 'desc',
        },
      ],
      meta: metadata,
    });

  const { data: paymentList, isFetched: isFetchedPaymentList } =
    useList<PurchasePaymentModel>({
      resource: 'payment-entry',
      filters: [
        {
          field: 'purchaseOrderId',
          operator: 'eq',
          value: purchaseId,
        },
      ],
      pagination: {
        current: 1,
        pageSize: 1000,
      },
      sorters: [
        {
          field: 'posting_date',
          order: 'desc',
        },
      ],
      meta: metadata,
    });

  const { data: receiptList, isFetched: isFetchedReceiptList } =
    useList<PurchaseReceiptModel>({
      resource: 'purchase-receipt',
      filters: [
        {
          field: 'purchaseOrderId',
          operator: 'eq',
          value: purchaseId,
        },
      ],
      pagination: {
        current: 1,
        pageSize: 1000,
      },
      sorters: [
        {
          field: 'posting_date',
          order: 'desc',
        },
      ],
      meta: metadata,
    });

  const { data: invoiceDetailsList, isFetched: isFetchedInvoiceDetailsList } =
    useMany<InvoiceDetailModel>({
      resource: 'purchase-invoice/ids',
      ids: invoiceList?.data?.map((invoice) => invoice.id) || [],
      queryOptions: {
        enabled: !!invoiceList?.data.length,
      },
      meta: metadata,
    });

  const { data: receiptDetailsList, isFetched: isFetchedReceiptDetailsList } =
    useMany<PurchaseReceiptModel>({
      resource: 'purchase-receipt/ids',
      ids: receiptList?.data?.map((receipt) => receipt.id) || [],
      queryOptions: {
        enabled: !!receiptList?.data.length,
      },
      meta: metadata,
    });

  const filterItems: { text: string; value: string }[] = useMemo(() => {
    return (
      purchaseDetail?.data.items.map((item) => ({
        text: item.name,
        value: item.itemCode,
      })) || []
    );
  }, [purchaseDetail]);

  useEffect(() => {
    setIsInvoiceReceiptProcessed(false);
    const invoiceReceiptArray: InvoiceReceiptTableModel[] = [];

    if (invoiceDetailsList?.data) {
      invoiceDetailsList.data.forEach((invoice) => {
        invoiceReceiptArray.push({
          id: invoice.id,
          viewId: invoice.id.replace('ACC-PINV-', ''),
          date: dayjs(`${invoice.postingDate} ${invoice.postingTime}`),
          amount: invoice.total,
          type: 'invoice',
          currency: invoice.currency,
          itemName: invoice.items.map((item) => item.itemName).join(', '),
          itemCodes: new Set(invoice.items.map((item) => item.itemCode)),
          originalId: invoice.id,
          isCancelled: invoice.status === 'Cancelled',
          account: invoice.account,
          updateBy: invoice.updatedByUserName,
        });
      });
    }

    if (paymentList?.data) {
      paymentList.data.forEach((payment) => {
        invoiceReceiptArray.push({
          id: payment.id,
          viewId: payment.id.replace('ACC-PAY-', ''),
          date: dayjs(`${payment.postingDate}`),
          amount: payment.allocatedAmount,
          type: 'payment',
          currency: payment.currency,
          itemName: '',
          itemCodes: new Set(),
          originalId: payment.id,
          isCancelled: payment.status === 'Cancelled',
          account: payment.paymentEntryReferenceReferenceDoctype,
          updateBy: payment.updatedByUserName,
        });
      });
    }

    if (receiptDetailsList?.data) {
      receiptDetailsList.data.forEach((receipt) => {
        const itemsCodes: Set<string> = new Set(
          receipt.items.map((item) => item.itemCode),
        );
        receipt.items.forEach((item, index) => {
          invoiceReceiptArray.push({
            id: `${receipt.id}:${index}`,
            viewId: receipt.id.replace('MAT-PRE-', ''),
            date: dayjs(`${receipt.postingDate} ${receipt.postingTime}`),
            amount: item.qty,
            type: 'receipt',
            currency: receipt.currency,
            uom: item.stockUom,
            itemName: item.itemName,
            itemCodes: itemsCodes,
            originalId: receipt.id,
            isCancelled: receipt.status === 'Cancelled',
            updateBy: receipt.updatedByUserName,
          });
        });
      });
    }

    setIsInvoiceReceiptProcessed(true);
    setInvoiceReceiptData(invoiceReceiptArray);
  }, [
    invoiceDetailsList?.data,
    invoiceList?.data,
    receiptList?.data,
    receiptDetailsList?.data,
    paymentList?.data,
  ]);

  const isLoading =
    !isFetchedPurchaseDetail ||
    !isFetchedInvoiceList ||
    !isFetchedReceiptList ||
    (!isFetchedPaymentList && !!paymentList?.data.length) ||
    (!isFetchedInvoiceDetailsList && !!invoiceList?.data.length) ||
    (!isFetchedReceiptDetailsList && !!receiptList?.data.length) ||
    !isInvoiceReceiptProcessed;

  const onFilterStatus = (
    value: boolean | Key,
    record: InvoiceReceiptTableModel,
  ) => {
    switch (value) {
      case 'invoice':
        return record.type === 'invoice' && !record.isCancelled;
      case 'receipt':
        return record.type === 'receipt' && !record.isCancelled;
      case 'invoice_cancelled':
        return record.type === 'invoice' && record.isCancelled;
      case 'receipt_cancelled':
        return record.type === 'receipt' && record.isCancelled;
      case 'payment':
        return record.type === 'payment' && !record.isCancelled;
      case 'payment_cancelled':
        return record.type === 'payment' && record.isCancelled;
      default:
        return false;
    }
  };

  const filterItemsStatus = [
    {
      text: t('purchase.invoice_receipt.status.invoice'),
      value: 'invoice',
    },
    {
      text: t('purchase.invoice_receipt.status.receipt'),
      value: 'receipt',
    },
    {
      text: t('purchase.invoice_receipt.status.invoice_cancelled'),
      value: 'invoice_cancelled',
    },
    {
      text: t('purchase.invoice_receipt.status.receipt_cancelled'),
      value: 'receipt_cancelled',
    },
    {
      text: t('purchase.invoice_receipt.status.payment'),
      value: 'payment',
    },
    {
      text: t('purchase.invoice_receipt.status.payment_cancelled'),
      value: 'payment_cancelled',
    },
  ];

  const handleOnClickDelete = (originalId: string, type: string) => {
    let resource = '';
    switch (type) {
      case 'invoice':
        resource = 'purchase-invoice';
        break;
      case 'receipt':
        resource = 'purchase-receipt';
        break;
      case 'payment':
        resource = 'payment-entry';
        break;
      default:
        break;
    }
    mutateDelete(
      {
        resource,
        id: originalId,
        meta: metadata,
        successNotification: () => {
          return {
            message: t(`purchase.invoice_receipt.table.delete_${type}_success`),
            type: 'success',
          };
        },
      },
      {
        onSuccess: () => {
          refetchTableAndInvalidate();
        },
      },
    );
  };

  return {
    invoiceReceiptData,
    isLoading,
    filterItems,
    filterItemsStatus,
    onFilterStatus,
    handleOnClickDelete,
  };
};

export { useInvoiceReceiptTable as default, useInvoiceReceiptTable };
