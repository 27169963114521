import { FC, ReactNode } from 'react';

import * as Styled from './quick-action.style';

interface QuickActionProps {
  icon: ReactNode;
  description: string;
  onClick: () => void;
}

const QuickAction: FC<QuickActionProps> = ({ icon, description, onClick }) => {
  return (
    <Styled.QuickActionItem onClick={onClick}>
      <Styled.QuickActionIcon>{icon}</Styled.QuickActionIcon>
      {description}
    </Styled.QuickActionItem>
  );
};

export { QuickAction };
