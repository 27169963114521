import { MoreOutlined, PlusOutlined } from '@ant-design/icons';
import { useDrawerForm, useTable } from '@refinedev/antd';
import { Button, Dropdown, Skeleton, Space, Table } from 'antd';
import { PageTitle } from 'common/components';
import { ROUTES } from 'common/constants';
import { usePermissionsBalance } from 'common/hooks/use-permissions-balance.hook';
import { ChevronBottomIcon, ChevronTopIcon } from 'common/icons';
import { useNavigateWithOrgAndFarm, useTracking } from 'common/utils';
import { ActionRbacEnum } from 'common/utils/auth-provider.util';
import { FC, Key, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useFarm, useOrganization } from 'src/organization';

import { OpeningStockModel } from '../opening-stock';
import { StockTransferContainer } from '../stock-transfer';
import { WarehouseCreateContainer } from '../warehouse-create/warehouse-create.container';
import { WarehouseListCardsContainer } from './cards/warehouse-list-cards.container';
import {
  IsGroupWarehouseEnum,
  IsWarehouseDisabledEnum,
} from './warehouse-list.constant';
import {
  GroupedWarehouseModel,
  WarehouseModel,
  WarehouseRecordModel,
} from './warehouse-list.model';
import * as Styled from './warehouse-list.style';
import { groupByFarm, moveToFirst } from './warehouse-list.util';

const WarehouseListContainer: FC = () => {
  const { t } = useTranslation();
  const { track } = useTracking();
  const { currentOrganizationId, isLoading: isOrganizationLoading } =
    useOrganization();
  const { currentFarm, isLoading: isFarmLoading } = useFarm();
  const navigate = useNavigateWithOrgAndFarm();
  const [expandedKeys, setExpandedKeys] = useState<readonly Key[]>([]);
  const [showDisabledWarehouses, setShowDisabledWarehouses] = useState<number>(
    IsWarehouseDisabledEnum.NO,
  );
  const { permissions } = usePermissionsBalance();

  const isDependenciesLoaded = useMemo(
    () =>
      (!!currentFarm || !!currentOrganizationId) &&
      !isOrganizationLoading &&
      !isFarmLoading,
    [currentFarm, currentOrganizationId, isOrganizationLoading, isFarmLoading],
  );

  const { tableQueryResult: warehousesList, setFilters } =
    useTable<WarehouseModel>({
      resource: 'warehouse',
      filters: {
        permanent: [
          {
            field: 'isGroup',
            operator: 'eq',
            value: IsGroupWarehouseEnum.NO,
          },
        ],
      },
      queryOptions: {
        enabled: isDependenciesLoaded,
      },
      meta: {
        organizationId: currentOrganizationId,
      },
      pagination: {
        pageSize: 100,
      },
    });

  const warehouses = useMemo<GroupedWarehouseModel | null>(() => {
    const warehouseData = warehousesList.data?.data;

    if (warehouseData) {
      const groupedData = groupByFarm(warehouseData);

      if (currentFarm?.name) {
        return moveToFirst(groupedData, currentFarm.name);
      } else {
        return groupedData;
      }
    } else {
      return null;
    }
  }, [currentFarm, warehousesList]);

  const isLoadingData = useMemo(
    () =>
      (warehousesList.isFetching || warehousesList.isLoading) && !warehouses,
    [warehouses, warehousesList.isFetching, warehousesList.isLoading],
  );

  const {
    drawerProps: stockTransferDrawerProps,
    formProps: stockTransferFormProps,
    form: stockTransferFormInstance,
    saveButtonProps: stockTransferSaveButtonProps,
    close: stockTransferClose,
    show: stockTransferShow,
  } = useDrawerForm<OpeningStockModel>({
    action: 'create',
    resource: 'stock-entry',
    redirect: false,
    syncWithLocation: {
      key: 'transfer',
    },
  });

  const {
    drawerProps: warehouseCreateDrawerProps,
    formProps: warehouseCreateFormProps,
    saveButtonProps: warehouseCreateSaveButtonProps,
    close: warehouseCreateClose,
    show: warehouseCreateShow,
  } = useDrawerForm<WarehouseModel>({
    action: 'create',
    resource: 'warehouse',
    redirect: false,
    syncWithLocation: {
      key: 'create',
    },
  });

  const goToFarmStock = useCallback(
    (farmName: string) => {
      navigate(
        `${ROUTES.FARM_STOCK_SUMMARY_ROOT}/${encodeURIComponent(farmName)}`,
      );
    },
    [navigate],
  );

  const handleOpeningStockTransfer = useCallback(() => {
    track('Start Stock Transfer');
    stockTransferShow();
  }, [stockTransferShow, track]);

  const handleCloseStockTransfer = useCallback(() => {
    stockTransferClose();
  }, [stockTransferClose]);

  const handleRenderExpandable = useCallback(
    (record: { farmName: string }) => (
      <WarehouseListCardsContainer
        warehouses={warehouses as GroupedWarehouseModel}
        farm={record.farmName}
      />
    ),
    [warehouses],
  );

  const handleRenderExpandIcon = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ({ expanded, onExpand, record }: any) => {
      return expanded ? (
        <ChevronTopIcon onClick={(e: unknown) => onExpand(record, e)} />
      ) : (
        <ChevronBottomIcon onClick={(e: unknown) => onExpand(record, e)} />
      );
    },
    [],
  );

  const handleRenderActions = useCallback(
    (_: unknown, record: WarehouseRecordModel) => {
      const showButtons = expandedKeys.includes(record.farmName);

      return (
        showButtons && (
          <Space>
            <Button
              type="link"
              onClick={() => {
                track('Go To Stock Summary', {
                  'Action Complement': 'Farm Row Action Button',
                });
                goToFarmStock(record.farmName);
              }}
            >
              {t('stock.see_farm_stock')}
            </Button>
          </Space>
        )
      );
    },
    [expandedKeys, goToFarmStock, t, track],
  );

  const handleWarehouseCreate = () => {
    track('Start Warehouse Creation');
    warehouseCreateShow();
  };

  const handleCloseWarehouseCreate = () => {
    warehouseCreateClose();
  };

  const handleOnCellClick = (record: WarehouseRecordModel) => ({
    onClick: () => {
      track('Go To Stock Summary', { 'Action Complement': 'Farm Row' });
      goToFarmStock(record.farmName);
    },
  });

  const moreButtonItems = useMemo(
    () =>
      showDisabledWarehouses === IsWarehouseDisabledEnum.NO
        ? [
            {
              label: t('stock.title_more.see_disabled_warehouses'),
              key: '1',
              onClick: () => {
                track('Stock Title More Action', {
                  'Action Complement': 'See disabled warehouses',
                });
                setShowDisabledWarehouses(IsGroupWarehouseEnum.YES);
                setFilters([
                  {
                    field: 'disabled',
                    operator: 'eq',
                    value: IsWarehouseDisabledEnum.YES,
                  },
                ]);
              },
            },
          ]
        : [
            {
              label: t('stock.title_more.see_enabled_warehouses'),
              key: '0',
              onClick: () => {
                track('Stock Title More Action', {
                  'Action Complement': 'See enabled warehouses',
                });
                setShowDisabledWarehouses(IsGroupWarehouseEnum.NO);
                setFilters([
                  {
                    field: 'disabled',
                    operator: 'eq',
                    value: IsWarehouseDisabledEnum.NO,
                  },
                ]);
              },
            },
          ],
    [setFilters, showDisabledWarehouses, t, track],
  );

  return (
    <Styled.WarehouseListWrapper>
      <PageTitle
        title={t('stock.title')}
        buttons={
          <>
            <Button onClick={() => navigate(ROUTES.STOCK_SUMMARY)} type="link">
              {t('stock.see_stock_summary')}
            </Button>
            {permissions?.includes(ActionRbacEnum.STOCK_BALANCE_WRITE) && (
              <>
                <Button
                  onClick={handleOpeningStockTransfer}
                  type="primary"
                  ghost
                >
                  {t('stock.transfer_button')}
                </Button>
                <Button
                  onClick={handleWarehouseCreate}
                  icon={<PlusOutlined rev={undefined} />}
                  type="primary"
                >
                  {t('stock.create_button')}
                </Button>
              </>
            )}
            <Dropdown menu={{ items: moreButtonItems }} trigger={['click']}>
              <Styled.TitleMoreButton
                type="text"
                icon={<MoreOutlined rev={undefined} />}
              />
            </Dropdown>
          </>
        }
      />
      {isLoadingData && <Skeleton active />}
      {warehouses && (
        <Table
          dataSource={Object.keys(warehouses).map((farmName) => ({
            farmName,
          }))}
          rowKey="farmName"
          expandable={{
            expandedRowRender: handleRenderExpandable,
            expandIcon: handleRenderExpandIcon,
            onExpandedRowsChange: (key: readonly Key[]) => {
              setExpandedKeys(key);
            },
          }}
          size="middle"
          pagination={false}
          loading={isLoadingData}
        >
          <Table.Column
            title={t('stock.table.farm')}
            dataIndex="farmName"
            key="farmName"
            onCell={handleOnCellClick}
          />
          <Table.Column
            width={220}
            align="center"
            title={t('stock.table.actions')}
            render={handleRenderActions}
          />
        </Table>
      )}
      <StockTransferContainer
        drawerProps={stockTransferDrawerProps}
        formProps={stockTransferFormProps}
        formInstance={stockTransferFormInstance}
        onClose={handleCloseStockTransfer}
        onStockTransfer={stockTransferSaveButtonProps}
      />
      <WarehouseCreateContainer
        drawerProps={warehouseCreateDrawerProps}
        formProps={warehouseCreateFormProps}
        onClose={handleCloseWarehouseCreate}
        onWarehouseCreate={warehouseCreateSaveButtonProps}
      />
    </Styled.WarehouseListWrapper>
  );
};

export { WarehouseListContainer as default, WarehouseListContainer };
