import { Form, Tag as TagAntd } from 'antd';
import { ChevronIcon } from 'common/icons';
import styled from 'styled-components';
import { NeutralColors, RedColors, YellowColors } from 'theme';

import { SelectWithComplementaryLabel } from '../select-with-complementary-label';

interface ContainerProps {
  isOpenSelect?: boolean;
  hidden?: boolean;
}

interface SelectProps {
  isError?: boolean;
}

interface LabelProps {
  hidden?: boolean;
}

const Container = styled.div<ContainerProps>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  margin: ${(props) => (props.hidden ? '0px' : '8px 0px')};
  cursor: ${(props) => (props.isOpenSelect ? 'pointer' : '')};
`;

const Label = styled.p<LabelProps>`
  display: ${(props) => (props.hidden ? 'none' : 'block')};
  margin: 16px 0px 0px 0px;
`;

const Tag = styled(TagAntd)`
  margin-left: 8px;
`;

const FormItem = styled(Form.Item)`
  margin: 0px;
  width: 100%;

  .fms-select-outlined {
    display: flex;
    align-items: center;
  }
`;

const Chevron = styled(ChevronIcon)`
  height: 16px;
  margin-top: 2px;
  margin-bottom: -4px;
  transform: rotate(-90deg);
`;

const ContainerError = styled.div`
  display: flex;
  align-items: center;

  svg {
    width: 18px;
    fill: ${YellowColors[40]} !important;
    margin-right: 8px;
  }
`;

const Select = styled(SelectWithComplementaryLabel)<SelectProps>`
  span {
    display: flex !important;
    align-items: center;
    font-size: 12px !important;
    height: 22px;
  }

  .fms-select-selector {
    height: 22px !important;
    border-radius: 8px;
    background-color: ${(props) =>
      props.isError ? RedColors[10] : NeutralColors[10]} !important;
    border: 1px solid
      ${(props) => (props.isError ? RedColors[30] : NeutralColors[20])} !important;
  }

  input {
    height: 22px !important;
  }
`;

const TextRequired = styled.span`
  color: ${RedColors[50]} !important;
`;

export {
  Chevron,
  Container,
  ContainerError,
  FormItem,
  Label,
  Select,
  Tag,
  TextRequired,
};
