import styled from 'styled-components';
import { GreenColors, NeutralColors, RedColors } from 'theme';

interface ValueProps {
  isNegative?: boolean;
  greenOnPositive?: boolean;
}

const Value = styled.span<ValueProps>`
  color: ${(props) => {
    if (props.isNegative) {
      return RedColors[60];
    } else if (props.greenOnPositive) {
      return GreenColors[60];
    } else {
      return NeutralColors[100];
    }
  }};
`;

export { Value };
