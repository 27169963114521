import { Typography } from 'antd';
import { NoItemsFoundImage } from 'common/images';
import { FC, ReactElement, ReactNode, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import * as Styled from './empty-state.style';

interface EmptyStateType {
  title: string;
  image?: ReactNode;
  description?: string | string[];
  children?: ReactElement;
  marginTop?: string;
}

const EmptyState: FC<EmptyStateType> = ({
  image = <NoItemsFoundImage />,
  title,
  description,
  children,
  marginTop = '50px',
}) => {
  const { t } = useTranslation();
  const handleMultipleDescriptions = useCallback(
    (item: string, index: number) => (
      <Typography.Text key={`desc-${index}`}>{t(item)}</Typography.Text>
    ),
    [t],
  );
  const hasMultipleDescriptions = Array.isArray(description);

  return (
    <Styled.EmptyStateWrapper marginTop={marginTop}>
      {image}
      <Typography.Title level={3}>{t(title)}</Typography.Title>
      {description && (
        <Styled.DescriptionWrapper>
          {hasMultipleDescriptions ? (
            description.map(handleMultipleDescriptions)
          ) : (
            <Typography.Text>{t(description)}</Typography.Text>
          )}
        </Styled.DescriptionWrapper>
      )}
      {children && <Styled.ChildrenWrapper>{children}</Styled.ChildrenWrapper>}
    </Styled.EmptyStateWrapper>
  );
};

export { EmptyState as default, EmptyState, EmptyStateType };
