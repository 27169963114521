import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  align-items: center;

  svg {
    min-width: 32px;
    margin-right: 8px;
  }
`;

export { Container };
