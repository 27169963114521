import { Button } from 'antd';
import styled from 'styled-components';
import { NeutralColors } from 'theme';

const ContainerFlex = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 16px;
`;

const Container = styled.div`
  background-color: ${NeutralColors[0]};
  border-radius: 12px 0 12px 12px;
  padding: 8px 8px;
  margin-bottom: 16px;
  width: 58%;
  margin-left: auto;
`;

const ContainerItems = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  padding: 8px 16px;
  gap: 16px;
  color: ${NeutralColors[100]};

  span {
    color: ${NeutralColors[50]};
  }
`;

const CollapseButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  padding: 0;
  border-radius: 16px;
`;

export { CollapseButton, Container, ContainerFlex, ContainerItems, Header };
