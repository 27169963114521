import { Types } from '@amplitude/analytics-browser';
import { APP_VERSION } from 'common/constants';

import { isProduction } from '../environment.util';

const getLogLevel = () => {
  if (isProduction) {
    return Types.LogLevel.None;
  }

  return Types.LogLevel.Warn;
};

const TRACKING_KEY = import.meta.env.VITE_AMPLITUDE_KEY;
const TRACKING_OPTIONS = {
  appVersion: APP_VERSION,
  logLevel: getLogLevel(),
};

export { TRACKING_KEY, TRACKING_OPTIONS };
