import styled from 'styled-components';
import { BlueColors } from 'theme';

const Container = styled.div`
  display: flex;
  gap: 8px;
`;

const BoxLoading = styled.div`
  background-color: ${BlueColors[10]};
  border: 1px solid ${BlueColors[20]};
  border-radius: 0 12px 12px 12px;

  img {
    width: 48px;
  }
`;

export { BoxLoading, Container };
