import styled from 'styled-components';
import { YellowColors } from 'theme';

const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;

  svg {
    fill: ${YellowColors[40]} !important;
  }
`;

export { Container };
