import { Skeleton } from 'antd';
import { Buffer } from 'buffer';
import { ROUTES } from 'common/constants';
import { useEffect } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';

import { useAuth } from './auth.provider';

function AuthContainer() {
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { signin } = useAuth();

  useEffect(() => {
    signin(location, () => {
      const from = location.state?.from?.pathname || ROUTES.ROOT;
      const code = searchParams.get('code');
      const state = searchParams.get('state');

      if (state) {
        navigate(Buffer.from(state, 'base64').toString('ascii'), {
          replace: true,
        });
      } else if (code) {
        navigate(from, { replace: true });
      } else {
        navigate(from + location.search, { replace: true });
      }
    });
  }, [signin, location, navigate, searchParams]);

  return <Skeleton active />;
}

export { AuthContainer, AuthContainer as default };
