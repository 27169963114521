import { AlertTriangleIcon } from 'common/icons';
import { useTranslation } from 'react-i18next';

import * as Styled from './cost-center-column.style';

interface CostCenterColumnProps {
  costCenter: string;
  onClick: () => void;
}

const CostCenterColumn = ({ costCenter, onClick }: CostCenterColumnProps) => {
  const { t } = useTranslation();

  if (!costCenter) {
    return (
      <Styled.EmptyCostCenter onClick={onClick}>
        <AlertTriangleIcon /> <span>{t('settings.empty_cost_center')}</span>
      </Styled.EmptyCostCenter>
    );
  }

  return <span>{costCenter}</span>;
};

export { CostCenterColumn, CostCenterColumn as default };
