/* eslint-disable @typescript-eslint/no-explicit-any */
import { BaseRecord, GetListResponse } from '@refinedev/core';
import { QueryObserverResult } from '@tanstack/react-query';
import { SelectProps } from 'rc-select';

import { OptionTypeModel } from './select-with-complementary-label.model';

export const convertSelectPropsToOptionType = (
  selectProps: SelectProps,
  queryResult: QueryObserverResult<GetListResponse<BaseRecord>>,
  optionMoreLabel?: string,
  optionValue = 'id',
): OptionTypeModel[] => {
  const { data: dataOptions } = queryResult ?? { data: { data: [] } };

  if (!optionMoreLabel || !dataOptions?.data?.length) {
    return selectProps?.options ?? [];
  }

  return (
    selectProps?.options?.map((option) => {
      const findOption = dataOptions.data.find(
        (dataOption: any) => dataOption[optionValue] === option.value,
      );

      if (!findOption) {
        return option;
      }
      return {
        ...option,
        complementaryLabel: findOption[optionMoreLabel],
      };
    }) ?? []
  );
};
