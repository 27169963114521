import { create } from 'zustand';

type StockTransferState = {
  disableSave: boolean;
  showItems: boolean;
};

type StockTransferActions = {
  setDisableSave: (value: boolean) => void;
  setShowItems: (value: boolean) => void;
};

const useStockTransferStore = create<StockTransferState & StockTransferActions>(
  (set) => ({
    disableSave: false,
    setDisableSave: (value: boolean) => set(() => ({ disableSave: value })),
    showItems: false,
    setShowItems: (value: boolean) => set(() => ({ showItems: value })),
  }),
);

export { useStockTransferStore };
