import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  ChartBarDataModel,
  ColorPerLabel,
  DetailedData,
  LegendDataPerGroup,
  MarkersPerLabel,
  MarkerType,
} from '../charts.model';

const DEFAULT_AMOUNT = 30;

interface ChartHorizontalBarProps {
  data: ChartBarDataModel[];
  currencySymbol?: string;
  customUom?: string;
}

const useChartHorizontalBar = ({
  data,
  currencySymbol,
  customUom,
}: ChartHorizontalBarProps) => {
  const [showAll, setShowAll] = useState<boolean>(false);
  const { i18n } = useTranslation();
  const [detailedDataPerLabel, setDetailedDataPerLabel] = useState<
    Record<string, DetailedData>
  >({});
  const [colorPerLabel, setColorPerLabel] = useState<ColorPerLabel>({});
  const [markersPerLabel, setMarkersPerLabel] = useState<MarkersPerLabel>({});
  const [groupedKeys, setGroupedKeys] = useState<string[]>([]);
  const [maxValuePerGroup, setMaxValuePerGroup] = useState<
    Record<string, number>
  >({});
  const [legendDataPerGroup, setLegendDataPerGroup] =
    useState<LegendDataPerGroup>({});
  const [scaleWidth, setScaleWidth] = useState<number>(0);

  const dataFiltered = useMemo(
    () => data.filter((_, index) => index < DEFAULT_AMOUNT || showAll),
    [data, showAll],
  );

  const calculateValues = (current: ChartBarDataModel) => {
    return Object.keys(current.groups).reduce((acc, key) => {
      acc[key] =
        current.groups[key].values?.reduce((acc, value) => {
          return {
            ...acc,
            [value.label]: value.value,
          };
        }, {} as Record<string, number>) ?? {};
      return acc;
    }, {} as Record<string, Record<string, number>>);
  };

  const calculateColorByLabel = (current: ChartBarDataModel) => {
    return Object.keys(current.groups).reduce((acc, key) => {
      acc[key] =
        current.groups[key].values?.reduce((acc, value) => {
          return {
            ...acc,
            [value.label]: {
              color: value.color,
              dashed: value.dashed,
            },
          };
        }, {} as Record<string, { color?: string; dashed?: boolean }>) ?? {};
      return acc;
    }, {} as Record<string, Record<string, { color?: string; dashed?: boolean }>>);
  };

  const calculateMaxValuePerGroup = (
    totalPerGroup: Record<string, number>,
    current: ChartBarDataModel,
  ) => {
    const maxValuePerGroup = Math.max(...Object.values(totalPerGroup));
    const maxValuePerGroupWithMarker = Math.max(
      maxValuePerGroup,
      ...Object.values(current.groups).map((group) =>
        group.markers
          ? Math.max(...group.markers.map((marker) => marker.value))
          : 0,
      ),
    );

    return maxValuePerGroupWithMarker;
  };

  const calculateMarkersPerLabel = (current: ChartBarDataModel) => {
    return Object.keys(current.groups).reduce((acc, key) => {
      acc[key] =
        current.groups[key].markers?.reduce((acc, value) => {
          return {
            ...acc,
            [value.label]: {
              type: value.type,
              value: value.value,
              labelValue: value.labelValue,
            },
          };
        }, {} as Record<string, { type: MarkerType; value: number }>) ?? {};
      return acc;
    }, {} as Record<string, Record<string, { type: MarkerType; value: number }>>);
  };

  const calculateTotalPerGroup = (
    values: Record<string, Record<string, number>>,
  ) => {
    return Object.keys(values).reduce((acc, key) => {
      acc[`${key}Total`] = Object.values(values[key]).reduce(
        (acc, value) => acc + value,
        0,
      );
      return acc;
    }, {} as Record<string, number>);
  };

  const compensateZeroValues = (
    values: { id: string; [key: string]: string | number }[],
    maxValue: number,
  ) => {
    const compensateValue = maxValue > 0 ? maxValue * 0.1 : 1;
    return values.map((value) => {
      return {
        ...Object.keys(value).reduce((acc, key) => {
          return key === 'id'
            ? {
                ...acc,
                id: value.id,
              }
            : {
                ...acc,
                [key]: value[key] === 0 ? compensateValue : value[key],
              };
        }, {} as { id: string; [key: string]: string | number }),
      };
    });
  };

  const dataBar = useMemo(
    () =>
      dataFiltered.map((current) => {
        const values = calculateValues(current);
        const colorByLabel = calculateColorByLabel(current);

        const totalPerGroup = calculateTotalPerGroup(values);
        const maxValuePerGroupWithMarker = calculateMaxValuePerGroup(
          totalPerGroup,
          current,
        );

        setMaxValuePerGroup((prev) => ({
          ...prev,
          [current.label]: maxValuePerGroupWithMarker,
        }));
        setLegendDataPerGroup((prev) => ({
          ...prev,
          [current.label]: {
            label: current.label ?? '',
            moreLabel: current.moreLabel ?? '',
            lineInfo: current.lineInfo ?? '',
          },
        }));

        setGroupedKeys((prev) => [...prev, ...Object.keys(totalPerGroup)]);
        setDetailedDataPerLabel((prev) => ({
          ...prev,
          [current.label]: values,
        }));
        setColorPerLabel((prev) => ({
          ...prev,
          [current.label]: colorByLabel,
        }));
        setMarkersPerLabel((prev) => ({
          ...prev,
          [current.label]: calculateMarkersPerLabel(current),
        }));
        setScaleWidth(0); // Reset scaleWidth to calculate the new value
        return {
          ...totalPerGroup,
          id: current.label,
        };
      }),
    [dataFiltered],
  );

  const valueFormat = useCallback(
    (value: number) => {
      const formatedValue = currencySymbol
        ? `${currencySymbol} ${value.toLocaleString(i18n.language, {
            maximumFractionDigits: 2,
          })}`
        : `${value.toLocaleString(i18n.language, {
            maximumFractionDigits: 2,
          })}`;
      return customUom ? `${formatedValue}${customUom}` : formatedValue;
    },
    [currencySymbol, customUom, i18n.language],
  );

  const keysFiltered = useMemo(
    () =>
      groupedKeys.filter((item, index, self) => {
        return self.indexOf(item) === index;
      }),
    [groupedKeys],
  );

  return {
    scaleWidth,
    setScaleWidth,
    showAll,
    setShowAll,
    dataFiltered,
    dataBar: compensateZeroValues(
      dataBar,
      Math.max(...Object.values(maxValuePerGroup)),
    ),
    keysFiltered,
    detailedDataPerLabel,
    colorPerLabel,
    markersPerLabel,
    valueFormat,
    legendDataPerGroup,
    maxValuePerGroup,
  };
};

export { useChartHorizontalBar };
