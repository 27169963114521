import { FormattedDate } from 'common/components';
import { PLATFORM_DATA_CONFIG } from 'common/constants';
import { BalanceIcon, ProtectorIcon } from 'common/icons';
import { useFarm, useOrganization } from 'src/organization';

import { MaterialConsumptionModel } from '../material-consumption.model';
import { AgOperationTypeEnum } from '../operation-status.enum';
import * as Styled from './date-column.style';

interface DateColumnProps {
  materialConsumption: MaterialConsumptionModel;
}

const DateColumn = ({ materialConsumption }: DateColumnProps) => {
  const { farms } = useFarm();
  const urlProtector: string = import.meta.env.VITE_PROTECTOR_URL ?? '';
  const { currentOrganizationId } = useOrganization();

  const selectedFarm = farms.find(
    (currentFarm) => currentFarm.name === materialConsumption.farm,
  );

  const isCreateInBalance =
    materialConsumption.type !== AgOperationTypeEnum.AG_OPERATION ||
    materialConsumption.source === PLATFORM_DATA_CONFIG.APP_ID;

  const urlTimeline = `${urlProtector}/company/${currentOrganizationId}/property/${selectedFarm?.externalId}/timeline?selected=${materialConsumption.locationId}&start=${materialConsumption.postingDate}`;

  const handleOpenTimeline = () => {
    if (
      !selectedFarm?.externalId ||
      !materialConsumption.locationId ||
      isCreateInBalance
    ) {
      return;
    }
    window.open(urlTimeline, '_blank');
  };

  return (
    <Styled.Container>
      <Styled.IconBox
        onClick={handleOpenTimeline}
        data-testid="icon-box-date-column"
        isPointer={
          !!selectedFarm?.externalId &&
          !!materialConsumption.locationId &&
          !isCreateInBalance
        }
      >
        {isCreateInBalance ? <BalanceIcon /> : <ProtectorIcon />}
      </Styled.IconBox>
      <div>
        <div>
          <Styled.ExternalId>
            {materialConsumption.externalId2}
          </Styled.ExternalId>
        </div>
        <FormattedDate date={materialConsumption.postingDate} />
      </div>
    </Styled.Container>
  );
};

export { DateColumn };
