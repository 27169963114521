enum StatusEnum {
  Draft = 'Draft',
  Return = 'Return',
  DebitNoteIssued = 'Debit Note Issued',
  Submitted = 'Submitted',
  Paid = 'Paid',
  PartlyPaid = 'Partly Paid',
  Unpaid = 'Unpaid',
  Overdue = 'Overdue',
  Cancelled = 'Cancelled',
  InternalTransfer = 'Internal Transfer',
}

const StatusTranslationEnum = {
  [StatusEnum.Draft]: 'draft',
  [StatusEnum.Return]: 'return',
  [StatusEnum.DebitNoteIssued]: 'debit_note_issued',
  [StatusEnum.Submitted]: 'submitted',
  [StatusEnum.Paid]: 'paid',
  [StatusEnum.PartlyPaid]: 'partly_paid',
  [StatusEnum.Unpaid]: 'unpaid',
  [StatusEnum.Overdue]: 'overdue',
  [StatusEnum.Cancelled]: 'cancelled',
  [StatusEnum.InternalTransfer]: 'internal_transfer',
};

const statusColor = {
  [StatusEnum.Draft]: 'orange',
  [StatusEnum.Return]: 'purple',
  [StatusEnum.DebitNoteIssued]: 'cyan',
  [StatusEnum.Submitted]: 'blue',
  [StatusEnum.Paid]: 'green',
  [StatusEnum.PartlyPaid]: 'green',
  [StatusEnum.Unpaid]: 'red',
  [StatusEnum.Overdue]: 'red',
  [StatusEnum.Cancelled]: 'default',
  [StatusEnum.InternalTransfer]: 'blue',
};

export { statusColor, StatusEnum, StatusTranslationEnum };
