import { useDrawerForm } from '@refinedev/antd';
import { HttpError, useDelete } from '@refinedev/core';
import { DrawerProps, FormInstance, FormProps } from 'antd';
import { useWatch } from 'antd/es/form/Form';
import { useRedirect } from 'common/hooks';
import { useTracking } from 'common/utils';
import dayjs from 'dayjs';
import { useEffect, useMemo } from 'react';
import { useOrganization } from 'src/organization';
import EntitlementEnum from 'src/organization/entitlements.enum';
import { useBankAccountCreateDrawer } from 'src/resources/bank-account/use-bank-account-create-drawer.hook';
import { useCreateItemDrawer } from 'src/resources/item/use-item-create-drawer.hook';
import { useSupplierCreateDrawer } from 'src/resources/supplier/use-supplier-create-drawer.hook';

import {
  PurchaseDetailModel,
  PurchaseFormModel,
  PurchaseItemRequestModel,
  PurchaseRequestModel,
} from '../purchase.model';
import { usePurchaseCreateStore } from './purchase-create.store';

const usePurchaseDrawer = <T>(action: 'edit' | 'create', key?: string) => {
  const { currentOrganizationId } = useOrganization();

  const purchaseDrawer = useDrawerForm<PurchaseDetailModel, HttpError, T>({
    action,
    resource: 'purchase-order',
    redirect: false,
    meta: {
      organizationId: currentOrganizationId,
    },
    syncWithLocation: key
      ? {
          key,
        }
      : undefined,
  });

  return {
    purchaseDrawer,
  };
};

const usePurchaseCreate = (
  formInstance: FormInstance,
  formProps: FormProps,
  drawerProps: DrawerProps,
) => {
  const { entitlements } = useOrganization();
  const { initialValuesState } = useRedirect<PurchaseRequestModel>();
  const { track } = useTracking();
  const { mutate: mutateDelete } = useDelete();
  const { currency, setShowItems, setDisableSave } = usePurchaseCreateStore();

  const formValues = useWatch([], formInstance);

  useEffect(() => {
    if (!drawerProps.open) {
      setDisableSave(true);
      setShowItems(false);
      formInstance.resetFields();
    }
  }, [
    drawerProps.open,
    formInstance.resetFields,
    setDisableSave,
    setShowItems,
  ]);

  const initialValues: PurchaseFormModel = useMemo(() => {
    if (initialValuesState) {
      return {
        id: formProps.initialValues?.id as string,
        transactionDate: dayjs(initialValuesState.transactionDate),
        scheduleDate: dayjs(initialValuesState.scheduleDate),
        farmName: initialValuesState.farmName,
        supplier: initialValuesState.supplier,
        costCenter: initialValuesState.costCenter as string,
        status: initialValuesState.status,
        purchaseNumber: initialValuesState.purchaseNumber,
        isPaid: initialValuesState.isPaid,
        isReceived: initialValuesState.isReceived,
        items: initialValuesState.items?.map(
          (item: PurchaseItemRequestModel) => ({
            itemCode: item?.itemCode,
            qty: item?.qty,
            rate: item?.rate,
            receivedQty: item?.receivedQty,
            warehouse: item?.warehouse,
            totalValue: item.amount,
          }),
        ),
      } as PurchaseFormModel;
    }
    return {
      id: formProps.initialValues?.id as string,
      transactionDate:
        dayjs(formProps.initialValues?.transactionDate) ?? dayjs(),
      scheduleDate: dayjs(formProps.initialValues?.scheduleDate) ?? dayjs(),
      farmName: formProps.initialValues?.farmName as string,
      purchaseNumber: formProps.initialValues?.purchaseNumber as string,
      supplier: formProps.initialValues?.supplier as string,
      costCenter: formProps.initialValues?.costCenter as string,
      status: formProps.initialValues?.status as string,
      items: formProps.initialValues?.items?.map(
        (item: PurchaseItemRequestModel) => ({
          itemCode: item.itemCode,
          qty: item.qty,
          rate: item.rate,
          warehouse: item.warehouse,
          receivedQty: item?.receivedQty,
          totalValue: item.amount,
        }),
      ),
    };
  }, [formProps.initialValues, initialValuesState]);

  const disableItemsTab = useMemo(
    () =>
      !formValues?.scheduleDate ||
      !formValues?.transactionDate ||
      !formValues?.farmName ||
      !formValues?.supplier ||
      (!formValues?.dueDate &&
        entitlements?.includes(EntitlementEnum.INVOICES)),
    [
      formValues?.farmName,
      formValues?.scheduleDate,
      formValues?.supplier,
      formValues?.transactionDate,
      formValues?.dueDate,
    ],
  );

  useEffect(() => {
    formInstance
      .validateFields({ validateOnly: true })
      .then(() => {
        setDisableSave(false);
      })
      .catch(() => {
        setDisableSave(true);
      });
  }, [formValues, formInstance, setDisableSave]);

  const handleOnFinish = (values: PurchaseRequestModel) => {
    track('End Purchase');
    if (formProps.onFinish) {
      const data = {
        ...values,
        currency,
        transactionDate: dayjs(values.transactionDate).format('YYYY-MM-DD'),
        scheduleDate: dayjs(values.scheduleDate).format('YYYY-MM-DD'),
        dueDate: dayjs(values.dueDate).format('YYYY-MM-DD'),
        items: values.items?.map((item) => ({
          ...item,
        })),
      };
      formProps.onFinish(data);
    }
  };

  const handleOnClickDelete = (id: string) => {
    mutateDelete({
      resource: 'purchase-order',
      id,
    });
  };

  const { createSupplierDrawer, handleOpenSupplierDrawer } =
    useSupplierCreateDrawer();

  const { createBankAccountDrawer, handleOpenBankAccountDrawer } =
    useBankAccountCreateDrawer();

  const {
    createItemDrawer,
    handleOpenItemDrawer,
    setResourceCreate: setResourceCreateItem,
  } = useCreateItemDrawer();

  return {
    currency,
    initialValues,
    disableItemsTab,
    itemLength: formValues?.items?.length ?? 0,
    handleOnFinish,
    handleOnClickDelete,
    percentBilled: formProps.initialValues?.percentBilled,
    percentReceived: formProps.initialValues?.percentReceived,
    grandTotal: formProps.initialValues?.grandTotal,
    createSupplierDrawer,
    handleOpenSupplierDrawer,
    createItemDrawer,
    handleOpenItemDrawer,
    setResourceCreateItem,
    createBankAccountDrawer,
    handleOpenBankAccountDrawer,
  };
};

export { usePurchaseCreate, usePurchaseDrawer };
