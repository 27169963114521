import styled from 'styled-components';

const Trigger = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;

  svg {
    fill: currentColor;
  }

  span {
    font-size: 8px;
    text-transform: uppercase;
    line-height: 1;
  }
`;

export { Trigger };
