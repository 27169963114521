import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useOrganization } from 'src/organization';

const useAgOperationCreate = () => {
  const { apiNotification, currentOrganizationId } = useOrganization();
  const { i18n, t } = useTranslation();
  const userLanguage = i18n.language;
  const navigate = useNavigate();

  const handleOnBack = () => {
    navigate(-1);
  };

  const handleOnFinish = () => {
    apiNotification?.success({
      message: t('ag_operation.messages.title'),
      description: t('ag_operation.messages.description'),
    });
    handleOnBack();
  };

  return {
    currentOrganizationId,
    userLanguage,
    handleOnFinish,
    handleOnBack,
  };
};

export { useAgOperationCreate };
