import { Typography } from 'antd';
import { AddIcon } from 'common/icons';
import { EmptyStateOrganizationImage } from 'common/images';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { deepAction } from 'src/common/utils';

import { useAuth } from '../../auth';
import * as Styled from './organization-empty-state.style';

const OrganizationEmptyStateComponent = () => {
  const { t } = useTranslation();
  const { user } = useAuth();
  const callback_uri = window.location.href;

  const handleCreateOrganization = useCallback(() => {
    return deepAction.createOrganization(
      callback_uri,
      user?.default_workspace_id,
    );
  }, [user, callback_uri]);

  return (
    <Styled.EmptyState>
      <Typography.Title level={4}>{t('organizations.empty')}</Typography.Title>
      <EmptyStateOrganizationImage />
      <Styled.NewOrganizationButton
        type="primary"
        size="large"
        href={handleCreateOrganization()}
      >
        <AddIcon /> {t('organizations.new')}
      </Styled.NewOrganizationButton>
    </Styled.EmptyState>
  );
};

export {
  OrganizationEmptyStateComponent as default,
  OrganizationEmptyStateComponent,
};
