enum ElectronicInvoiceEnum {
  FAILED = 'Failed',
  PROCESSED = 'Processed',
  PROCESSING = 'Processing',
  TRANSFORMED_WITH_MATCH = 'Transformed_With_Match',
  TRANSFORMED_WITHOUT_MATCH = 'Transformed_Without_Match',
  IMPORTED = 'Imported',
  ARCHIVED = 'Archived',
}

enum ElectronicInvoiceStepEnum {
  SELECT_PURCHASE_ORDER = 0,
  LINK_ITEMS = 1,
  PAID_RECEIVED = 2,
  CONFIRM = 3,
  FINISH = 4,
}

export { ElectronicInvoiceEnum, ElectronicInvoiceStepEnum };
