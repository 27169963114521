import { Tag as TagAntd } from 'antd';
import styled from 'styled-components';

interface TagProps {
  textColor?: string;
  backgroundColor?: string;
  isLink?: boolean;
}

const Tag = styled(TagAntd)<TagProps>`
  border-color: ${(props) => props.backgroundColor};
  color: ${(props) => props.textColor};
  background-color: ${(props) => props.backgroundColor};
  display: flex;
  align-items: center;
  width: max-content;
  cursor: ${(props) => (props.isLink ? 'pointer' : 'auto')};
  font-weight: 600;

  svg {
    margin-right: 4px;
    width: 16px;
    fill: ${(props) => props.textColor};
  }
`;

export { Tag };
