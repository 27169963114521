import 'antd/dist/reset.css';
import './i18n.config';

import { AccessGuardian } from 'common/components';
import { ROUTES } from 'common/constants';
import { ActionRbacEnum } from 'common/utils/auth-provider.util';
import { useMemo } from 'react';
import { Route, Routes } from 'react-router-dom';

import { AgOperationCreateContainer } from './ag-operation-create/ag-operation-create.container';
import { AuthContainer } from './auth';
import DashboardContainer from './dashboard';
import {
  DataImportContainer,
  DataImportDetailsContainer,
  DataImportListContainer,
  DataImportStartContainer,
} from './data-import';
import { ElectronicInvoiceLinkContainer } from './electronic-invoice';
import { FinancialProductionContainer } from './financial';
import { FinancialContainer } from './financial/financial-overview';
import HelpCenterContainer from './help-center';
import HomeContainer from './home';
import { MaintenanceContainer } from './maintenance';
import { MaterialConsumptionListContainer } from './material-consumption';
import { MigrationContainer } from './migration';
import {
  InvoiceListContainer,
  PurchaseListContainer,
} from './orders-and-payments';
import { AccountsPayableContainer } from './orders-and-payments/accounts-payable';
import {
  FarmContainer,
  NoOrganization,
  OrganizationContainer,
  RequireOrganization,
} from './organization';
import EntitlementEnum from './organization/entitlements.enum';
import { NoPermission } from './organization/no-permission/no-permission.component';
import { NoPermissionBalance } from './organization/no-permission-balance/no-permission-balance.component';
import ReportGeneralLedger from './report-general-ledger';
import { ItemCategoryContainer, SupplierList } from './resources';
import BankAccountListContainer from './resources/bank-account';
import ItemListContainer from './resources/item';
import SettingsContainer from './settings';
import { MovementsContainer } from './stock/movements';
import StockSummaryContainer from './stock/stock-summary';
import WarehouseListContainer from './stock/warehouse-list';
import {
  StructureTemplate,
  StructureTemplateMobile,
} from './structure-template';

interface RoutesProps {
  path: string;
  element: JSX.Element;
  requireOrganization: boolean;
  children?: RoutesProps[];
  permissionScreen?: ActionRbacEnum;
  entitlementScreen?: EntitlementEnum;
}

const RoutesBalance = () => {
  const routes: RoutesProps[] = useMemo(
    () => [
      {
        path: ROUTES.ROOT,
        element: <HomeContainer />,
        requireOrganization: true,
      },
      {
        path: ROUTES.DASHBOARD,
        element: <DashboardContainer />,
        requireOrganization: true,
        entitlementScreen: EntitlementEnum.LANDING_PAGE,
      },
      {
        path: ROUTES.ITEM,
        element: <ItemListContainer />,
        requireOrganization: true,
      },
      {
        path: ROUTES.ELECTRONIC_INVOICE_LINK,
        element: <ElectronicInvoiceLinkContainer />,
        requireOrganization: true,
      },
      {
        path: ROUTES.AG_OPERATION_CREATE,
        element: <AgOperationCreateContainer />,
        requireOrganization: true,
      },
      {
        path: ROUTES.MIGRATION,
        element: <MigrationContainer />,
        requireOrganization: true,
      },
      {
        path: ROUTES.DATA_IMPORT,
        element: <DataImportContainer />,
        requireOrganization: true,
        entitlementScreen: EntitlementEnum.DATA_SHARING_IMPORT_EXPORT,
        children: [
          {
            path: ROUTES.DATA_IMPORT_START,
            element: <DataImportStartContainer />,
            requireOrganization: true,
          },
          {
            path: ROUTES.DATA_IMPORT_LIST,
            element: <DataImportListContainer />,
            requireOrganization: true,
          },
          {
            path: ROUTES.DATA_IMPORT_DETAILS,
            element: <DataImportDetailsContainer />,
            requireOrganization: true,
          },
        ],
      },
      {
        path: ROUTES.REPORT_GENERAL_LEDGER,
        element: <ReportGeneralLedger />,
        requireOrganization: true,
      },
      {
        path: ROUTES.REPORT_GENERAL_LEDGER,
        element: <ReportGeneralLedger />,
        requireOrganization: true,
        entitlementScreen: EntitlementEnum.REPORT_GENERAL_LEDGER,
      },
      {
        path: ROUTES.ITEM_CATEGORY,
        element: <ItemCategoryContainer />,
        requireOrganization: true,
      },
      {
        path: ROUTES.PURCHASE,
        element: <PurchaseListContainer />,
        requireOrganization: true,
      },
      {
        path: ROUTES.ACCOUNTS_PAYABLE,
        element: <AccountsPayableContainer />,
        requireOrganization: true,
        entitlementScreen: EntitlementEnum.ACCOUNTS_PLAYABLE,
      },
      {
        path: ROUTES.INVOICE,
        element: <InvoiceListContainer />,
        requireOrganization: true,
        entitlementScreen: EntitlementEnum.INVOICES,
      },
      {
        path: ROUTES.MATERIAL_CONSUMPTION,
        element: <MaterialConsumptionListContainer />,
        requireOrganization: true,
      },
      {
        path: ROUTES.SUPPLIER,
        element: <SupplierList />,
        requireOrganization: true,
      },
      {
        path: ROUTES.BANK_ACCOUNT,
        element: <BankAccountListContainer />,
        requireOrganization: true,
        entitlementScreen: EntitlementEnum.INVOICES,
      },
      {
        path: ROUTES.HELP_CENTER,
        element: <HelpCenterContainer />,
        requireOrganization: true,
      },
      {
        path: ROUTES.STOCK,
        element: <WarehouseListContainer />,
        permissionScreen: ActionRbacEnum.STOCK_BALANCE_READ,
        requireOrganization: true,
        children: [
          {
            path: ROUTES.STOCK_SUMMARY,
            element: <StockSummaryContainer />,
            requireOrganization: true,
          },
          {
            path: ROUTES.FARM_STOCK_SUMMARY,
            element: <StockSummaryContainer />,
            requireOrganization: true,
          },
          {
            path: ROUTES.WAREHOUSE_STOCK,
            element: <StockSummaryContainer />,
            requireOrganization: true,
          },
          {
            path: ROUTES.MOVEMENTS_STOCK,
            element: <MovementsContainer />,
            requireOrganization: true,
          },
        ],
      },
      {
        path: ROUTES.FINANCIAL,
        element: <FinancialContainer />,
        permissionScreen: ActionRbacEnum.FINANCIAL_BALANCE_READ,
        requireOrganization: true,
      },
      {
        path: ROUTES.FINANCIAL_PRODUCTION,
        element: <FinancialProductionContainer />,
        permissionScreen: ActionRbacEnum.FINANCIAL_BALANCE_READ,
        requireOrganization: true,
        entitlementScreen: EntitlementEnum.PRODUCTION_YIELD_DATA,
      },
      {
        path: ROUTES.SETTINGS,
        element: <SettingsContainer />,
        requireOrganization: true,
      },
      {
        path: ROUTES.FARM,
        element: <FarmContainer />,
        requireOrganization: true,
      },
      {
        path: ROUTES.NO_ORGANIZATION,
        element: <NoOrganization />,
        requireOrganization: false,
      },
      {
        path: ROUTES.MAINTENANCE,
        element: <MaintenanceContainer />,
        requireOrganization: false,
      },
      {
        path: ROUTES.ORGANIZATION,
        element: <OrganizationContainer />,
        requireOrganization: false,
      },
      {
        path: ROUTES.NO_PERMISSION,
        element: <NoPermission />,
        requireOrganization: false,
      },
      {
        path: ROUTES.NO_PERMISSION_BALANCE,
        element: <NoPermissionBalance />,
        requireOrganization: false,
      },
    ],
    [],
  );

  return (
    <Routes>
      <Route element={<StructureTemplate />}>
        <Route path={ROUTES.AUTHENTICATE} element={<AuthContainer />} />
        <Route element={<StructureTemplateMobile />}>
          {routes.map((route) => (
            <Route path={route.path} key={route.path}>
              <Route
                index
                element={
                  <AccessGuardian>
                    {route.requireOrganization ? (
                      <RequireOrganization
                        permissionScreen={route.permissionScreen}
                        entitlementScreen={route.entitlementScreen}
                      >
                        {route.element}
                      </RequireOrganization>
                    ) : (
                      route.element
                    )}
                  </AccessGuardian>
                }
              />
              {route.children?.map((routeChildren) => (
                <Route
                  key={routeChildren.path}
                  path={routeChildren.path}
                  element={
                    <AccessGuardian>
                      {route.requireOrganization ? (
                        <RequireOrganization>
                          {routeChildren.element}
                        </RequireOrganization>
                      ) : (
                        routeChildren.element
                      )}
                    </AccessGuardian>
                  }
                />
              ))}
            </Route>
          ))}
        </Route>
      </Route>
    </Routes>
  );
};

export { RoutesBalance };
