import 'dayjs/locale/pt-br';

import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

interface FormattedDateProps {
  date: string;
}

export const FormattedDate = ({ date }: FormattedDateProps) => {
  const { i18n } = useTranslation();
  const userLanguage = i18n.language.toLocaleLowerCase();

  return <>{dayjs(date).locale(userLanguage).format('DD/MM/YYYY')}</>;
};

export default FormattedDate;
