export const PurchasePaymentTestId = {
  Container: 'purchase-payment-container',
  SKELETON: 'purchase-payment-skeleton',
};

export const PurchasePaymentDateTestId = {
  PURCHASE_PAYMENT_DATE_CONTAINER: 'purchase-payment-date-container',
  PURCHASE_PAYMENT_DATE_DATE_PICKER: 'purchase-payment-date-date-picker',
  PURCHASE_PAYMENT_DATE_TIME_PICKER: 'purchase-payment-date-time-picker',
};

export const PurchasePaymentInfoTestId = {
  PURCHASE_PAYMENT_INFO_CONTAINER: 'purchase-payment-info-container',
  PURCHASE_PAYMENT_INFO_ACCOUNT: 'purchase-payment-info-account',
};

export const PurchasePaymentDetailTestId = {
  PURCHASE_PAYMENT_DETAIL_CONTAINER: 'purchase-payment-detail-container',
  PURCHASE_PAYMENT_DETAIL_OUTSTANDING_TOTAL:
    'purchase-payment-detail-outstanding-total',
  PURCHASE_PAYMENT_DETAIL_PAID_TOTAL: 'purchase-payment-detail-paid-total',
  PURCHASE_PAYMENT_DETAIL_PAID_AMOUNT: 'purchase-payment-detail-paid-amount',
};
