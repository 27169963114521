import { DocTypeEnum } from './data-import.enum';

export enum DataImportTestId {
  DATA_IMPORT_MODAL = 'data-import-modal',
  DATA_IMPORT_CARD = 'data-import-card',
  DATA_IMPORT_CARD_START = 'data-import-card-start',
  DATA_IMPORT_MODAL_DATA = 'data-import-modal-data',
  DATA_IMPORT_BUTTON_RESOURCE = 'data-import-button-resource',
}

export enum DataImportButtonResourceTestId {
  DATA_IMPORT_BUTTON_RESOURCE_CONTAINER = 'data-import-button-resource-container',
}

export const ExportFields = {
  [DocTypeEnum.ITEM]: `{"Item":["item_code","item_group","stock_uom","item_name","is_stock_item","allow_negative_stock","description"]}`,
  [DocTypeEnum.ITEM_GROUP]: `{"Item Group":["item_group_name","parent_item_group","is_group"]}`,
  [DocTypeEnum.PURCHASE_ORDER]: `{"Purchase Order":["title","external_id","transaction_date","company","supplier","currency","status"],"items":["item_code","item_name","schedule_date","qty","rate","warehouse"]}`,
  [DocTypeEnum.SUPPLIER]: `{"Supplier":["supplier_name","supplier_group","supplier_type"]}`,
  [DocTypeEnum.PURCHASE_INVOICE]: `{"Purchase Invoice":["name","posting_date","company","supplier","cash_bank_account","due_date","status","season_id","project","paid_amount","base_grand_total"],"items":["item_code","item_name","qty","rate","warehouse","purchase_order"]}`,
};
