import { extraErrorDataIntegration } from '@sentry/integrations';
import * as Sentry from '@sentry/react';
import { AxiosError, isAxiosError } from 'axios';
import { APP_ENVIRONMENT, APP_VERSION } from 'common/constants';

const handleSentryBeforeSend = (
  event: Sentry.Event,
  hint: Sentry.EventHint,
) => {
  addAxiosContextRecursive(event, hint?.originalException);
};

function addAxiosContextRecursive(event: Sentry.Event, error: unknown) {
  if (isAxiosError(error)) {
    addAxiosContext(event, error);
  } else if (error instanceof Error && error.stack) {
    addAxiosContextRecursive(event, error.stack);
  }
}

function addAxiosContext(event: Sentry.Event, error: AxiosError) {
  if (error.response) {
    const contexts = { ...event.contexts };
    contexts.Axios = { Response: error.response };
    event.contexts = contexts;
  }
}

const SENTRY_KEY = import.meta.env.VITE_SENTRY_DSN ?? '';

const SENTRY_CONFIG: Sentry.BrowserOptions = {
  dsn: SENTRY_KEY,
  integrations: [
    Sentry.browserTracingIntegration(),
    extraErrorDataIntegration(),
  ],
  tracesSampleRate: 1.0,
  release: `v${APP_VERSION}`,
  environment: APP_ENVIRONMENT,
  denyUrls: [
    /extensions\//i,
    /^chrome:\/\//i,
    /^chrome-extension:\/\//i,
    /127\.0\.0\.1:4001\/isrunning/i,
    /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
  ],
  beforeBreadcrumb(breadcrumb, hint) {
    if (breadcrumb?.category?.startsWith('ui')) {
      const target = hint?.event.target;
      const dataTestId = target.dataset.testid;
      const customMessage = `[data-testid="${dataTestId}"]${breadcrumb.message}`;
      breadcrumb.message = dataTestId ? customMessage : breadcrumb.message;
    }
    return breadcrumb;
  },
  beforeSend: (event, hint) => {
    handleSentryBeforeSend(event, hint);
    return event;
  },
};

export {
  SENTRY_CONFIG as default,
  handleSentryBeforeSend,
  SENTRY_CONFIG,
  SENTRY_KEY,
};
