import { DeleteTimeIcon } from 'common/icons';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

import * as Styled from './cancelled-by.style';

interface CancelledByProps {
  cancelledBy: string;
  cancelledAt: string;
}

const CancelledBy = ({ cancelledBy, cancelledAt }: CancelledByProps) => {
  const { t } = useTranslation();

  return (
    <Styled.CancelBox>
      <DeleteTimeIcon />
      <div>
        {t('common.cancel_message', {
          userName: cancelledBy,
          date: dayjs(cancelledAt).format('DD/MM/YYYY'),
        })}
      </div>
    </Styled.CancelBox>
  );
};

export { CancelledBy, CancelledBy as Default };
