import { useList } from '@refinedev/core';
import {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';

import { useOrganization } from '../organization.provider';
import { Farm } from './farm.model';

interface FarmContextProps {
  farms: Farm[];
  currentFarm: Farm | null;
  currentFarmId: string | null;
  setCurrentFarmId: (farmId: string | null) => void;
  getCurrencyByFarm: (farmId: string) => string | undefined;
  isLoading: boolean;
  isFetched: boolean;
  error: Error | null | unknown;
}

const FarmsContext = createContext<FarmContextProps>({
  farms: [],
  currentFarm: null,
  currentFarmId: null,
  setCurrentFarmId: () => null,
  getCurrencyByFarm: () => undefined,
  isLoading: false,
  isFetched: false,
  error: null,
});

const FarmProvider = ({ children }: PropsWithChildren) => {
  const { currentOrganizationId } = useOrganization();
  const [currentFarmId, setCurrentFarmId] = useState<string | null>(null);
  const { data, error, isLoading, isFetching, isFetched } = useList<Farm>({
    resource: 'farm',
    meta: {
      organizationId: currentOrganizationId,
    },
    queryOptions: {
      enabled: !!currentOrganizationId,
    },
    pagination: {
      pageSize: 1000,
    },
    errorNotification: false,
  });

  const farms = useMemo(() => data?.data ?? [], [data]);

  const currentFarm = useMemo(
    () => farms.find((farm) => farm.id === currentFarmId) ?? null,
    [farms, currentFarmId],
  );

  const getCurrencyByFarm = useCallback(
    (farmId: string) => {
      const farm = farms.find((farm) => farm.id === farmId);
      return farm?.currency;
    },
    [farms],
  );

  const value = useMemo(
    () => ({
      farms,
      currentFarmId,
      currentFarm,
      setCurrentFarmId,
      getCurrencyByFarm,
      isLoading: isFetching ?? isLoading,
      isFetched,
      error,
    }),
    [
      farms,
      currentFarmId,
      currentFarm,
      getCurrencyByFarm,
      isFetching,
      isLoading,
      isFetched,
      error,
    ],
  );

  return (
    <FarmsContext.Provider value={value}>{children}</FarmsContext.Provider>
  );
};

const useFarm = () => {
  const context = useContext(FarmsContext);
  if (context === undefined) {
    throw new Error('useFarm must be used within a FarmProvider');
  }
  return context;
};

export { FarmProvider as default, FarmProvider, useFarm };
