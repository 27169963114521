import { BarTooltipProps } from '@nivo/bar';
import { Typography } from 'antd';
import { FC } from 'react';

import * as Styled from './chart-custom-tooltip.style';

interface ChartCustomTooltipProps extends BarTooltipProps<{ id: string }> {
  valueFormat: (value: number) => string;
}

const ChartCustomTooltip: FC<ChartCustomTooltipProps> = ({
  value,
  color,
  label,
  valueFormat,
}) => {
  return (
    <Styled.Tooltip>
      {color && <Styled.TooltipColor color={color} />}
      <Typography.Text>{label}:</Typography.Text>
      <br />
      <Typography.Text strong>{valueFormat(value)}</Typography.Text>
    </Styled.Tooltip>
  );
};

export { ChartCustomTooltip, ChartCustomTooltip as default };
