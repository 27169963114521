import { create } from 'zustand';

type OpeningStockState = {
  disableSave: boolean;
  showItems: boolean;
};

type OpeningStockActions = {
  setDisableSave: (value: boolean) => void;
  setShowItems: (value: boolean) => void;
};

const useOpeningStockStore = create<OpeningStockState & OpeningStockActions>(
  (set) => ({
    disableSave: false,
    setDisableSave: (value: boolean) => set(() => ({ disableSave: value })),
    showItems: false,
    setShowItems: (value: boolean) => set(() => ({ showItems: value })),
  }),
);

export { useOpeningStockStore };
