import { ResponsiveLine, Serie } from '@nivo/line';
import { Skeleton, Typography } from 'antd';
import { InvoiceIcon } from 'common/icons';
import { useTranslation } from 'react-i18next';
import { GreenColors, NeutralColors } from 'theme';

import * as StyledDashboard from '../../dashboard.style';
import { mockTimeSavingData } from './time-saving.constants';
import { useTimeSaving } from './time-saving.hook';
import * as Styled from './time-saving.style';

interface TimeSavingProps {
  farm?: string;
}

const TimeSaving = ({ farm }: TimeSavingProps) => {
  const { t } = useTranslation();
  const { dataChartLine, amountLines, hasChart, isFetching } =
    useTimeSaving(farm);

  return (
    <StyledDashboard.BorderedContainer>
      <StyledDashboard.BoxTitle>
        <InvoiceIcon />
        <span>{t('dashboard.time_saving')}</span>
      </StyledDashboard.BoxTitle>
      {isFetching ? (
        <Skeleton active />
      ) : (
        <>
          {hasChart ? (
            <Styled.ContainerText>
              <Typography.Text>
                {t('dashboard.time_saving_message')}
              </Typography.Text>
              <Styled.AmountLines>
                <span>{amountLines}</span> {t('dashboard.spreadsheet_rows')}
              </Styled.AmountLines>
            </Styled.ContainerText>
          ) : (
            <Typography.Text>
              {t('dashboard.time_saving_empty')}
            </Typography.Text>
          )}

          <Styled.WidgetContainer>
            <ResponsiveLine
              data={(hasChart ? dataChartLine : mockTimeSavingData) as Serie[]}
              margin={{ bottom: 50, top: 10 }}
              legends={
                hasChart
                  ? [
                      {
                        anchor: 'bottom',
                        direction: 'row',
                        translateY: 30,
                        translateX: 0,
                        itemDirection: 'left-to-right',
                        itemWidth: 120,
                        itemHeight: 20,
                        symbolSize: 12,
                        symbolShape: 'circle',
                      },
                    ]
                  : undefined
              }
              axisBottom={null}
              colors={
                hasChart
                  ? [GreenColors[40], NeutralColors[30]]
                  : [NeutralColors[40], NeutralColors[30]]
              }
              enableTouchCrosshair={true}
              enableGridX={false}
              enableGridY={false}
              enablePoints={false}
              curve="monotoneX"
            />
          </Styled.WidgetContainer>
        </>
      )}
    </StyledDashboard.BorderedContainer>
  );
};

export { TimeSaving };
