import styled from 'styled-components';
import { BlueColors } from 'theme';

const NoOrganizationWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 16px;

  svg {
    width: fit-content;
  }

  .fms-typography {
    color: ${BlueColors[80]};
  }
`;

export { NoOrganizationWrapper };
