import { useOne } from '@refinedev/core';

import { useOrganization } from '../../../organization';
import { MovementsModel } from '../movements.model';

interface MovementsDetailsProps {
  voucherNo: string;
}

const useMovementsDetailsDrawer = ({ voucherNo }: MovementsDetailsProps) => {
  const { currentOrganizationId } = useOrganization();

  const {
    data: movementsDetailsData,
    isLoading: isLoadingMovementsDetails,
    isFetching: isFetchingMovementsDetails,
  } = useOne<MovementsModel[]>({
    resource: 'movement/details',
    id: voucherNo,
    meta: {
      organizationId: currentOrganizationId,
    },
    queryOptions: {
      enabled: !!voucherNo && !!currentOrganizationId,
    },
  });

  return {
    movementsDetailsData,
    isLoadingMovementsDetails:
      isLoadingMovementsDetails || isFetchingMovementsDetails,
  };
};

export { useMovementsDetailsDrawer as Default, useMovementsDetailsDrawer };
