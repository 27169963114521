const insertMaskInCpf = (cpf: string) => {
  const strCPF = cpf.replace(/\D/g, '');

  if (strCPF.length > 9) {
    return strCPF.replace(/(\d{3})(\d{3})(\d{3})(\d{1,3})/, '$1.$2.$3-$4');
  }

  if (strCPF.length > 6) {
    return strCPF.replace(/(\d{3})(\d{3})(\d{1,3})/, '$1.$2.$3');
  }

  if (strCPF.length > 3) {
    return strCPF.replace(/(\d{3})(\d{1,3})/, '$1.$2');
  }

  return cpf;
};

const insertMaskInCnpj = (cnpj: string) => {
  const strCnpj = cnpj.replace(/\D/g, '');

  if (strCnpj.length > 14) {
    return strCnpj.replace(
      /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
      '$1.$2.$3/$4-$5',
    );
  }

  if (strCnpj.length > 12) {
    return strCnpj.replace(
      /(\d{2})(\d{3})(\d{3})(\d{4})(\d{1,2})/,
      '$1.$2.$3/$4-$5',
    );
  }

  if (strCnpj.length > 8) {
    return strCnpj.replace(/(\d{2})(\d{3})(\d{3})(\d{1,4})/, '$1.$2.$3/$4');
  }

  if (strCnpj.length > 5) {
    return strCnpj.replace(/(\d{2})(\d{3})(\d{1,3})/, '$1.$2.$3');
  }

  if (strCnpj.length > 2) {
    return strCnpj.replace(/(\d{2})(\d{1,3})/, '$1.$2');
  }

  return cnpj;
};

const insertMaskInCnpjOrCpf = (cnpjOrCpf: string) => {
  const strCnpjCpf = cnpjOrCpf.replace(/\D/g, '');

  if (strCnpjCpf.length > 11) {
    return insertMaskInCnpj(strCnpjCpf);
  }

  return insertMaskInCpf(strCnpjCpf);
};

const validateCpf = (cpf: string): boolean => {
  const strCPF = cpf.replace(/\D/g, '');
  let sum;
  let rest;
  sum = 0;

  if (strCPF.length !== 11) {
    return false;
  }

  if (strCPF === '00000000000') {
    return false;
  }

  for (let i = 1; i <= 9; i += 1) {
    sum += parseInt(strCPF.substring(i - 1, i), 10) * (11 - i);
  }
  rest = (sum * 10) % 11;

  if (rest === 10 || rest === 11) {
    rest = 0;
  }
  if (rest !== parseInt(strCPF.substring(9, 10), 10)) {
    return false;
  }

  sum = 0;
  for (let i = 1; i <= 10; i += 1) {
    sum += parseInt(strCPF.substring(i - 1, i), 10) * (12 - i);
  }

  rest = (sum * 10) % 11;

  if (rest === 10 || rest === 11) {
    rest = 0;
  }

  return rest === parseInt(strCPF.substring(10, 11), 10);
};

const validateCnpj = (cnpj: string): boolean => {
  const newCnpj = cnpj.replace(/[^\d]/g, '');

  if (newCnpj.length !== 14) {
    return false;
  }

  if (/^(\d)\1{13}$/.test(newCnpj)) {
    return false;
  }

  let sum = 0;
  let weight = 5;
  for (let i = 0; i < 12; i++) {
    sum += parseInt(newCnpj.charAt(i)) * weight;
    weight = weight === 2 ? 9 : weight - 1;
  }
  const digit1 = sum % 11 < 2 ? 0 : 11 - (sum % 11);

  if (digit1 !== parseInt(newCnpj.charAt(12))) {
    return false;
  }

  sum = 0;
  weight = 6;
  for (let i = 0; i < 13; i++) {
    sum += parseInt(newCnpj.charAt(i)) * weight;
    weight = weight === 2 ? 9 : weight - 1;
  }
  const digit2 = sum % 11 < 2 ? 0 : 11 - (sum % 11);

  if (digit2 !== parseInt(newCnpj.charAt(13))) {
    return false;
  }

  return true;
};

const validateCnpjOrCpf = (cnpjOrCpf: string): boolean => {
  return validateCpf(cnpjOrCpf) || validateCnpj(cnpjOrCpf);
};

export {
  insertMaskInCnpj,
  insertMaskInCnpjOrCpf,
  insertMaskInCpf,
  validateCnpj,
  validateCnpjOrCpf,
  validateCpf,
};
