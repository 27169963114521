import { Alert as AlertAntd, Button as ButtonAntd } from 'antd';
import styled from 'styled-components';

const Alert = styled(AlertAntd)`
  .fms-alert-message {
    font-weight: bold;
  }
`;

const ContainerButton = styled.div`
  display: flex;
  justify-content: end;
`;

const Button = styled(ButtonAntd)`
  display: flex;
  align-items: center;
  margin-top: 8px;

  svg {
    margin-left: 4px;
    width: 20px;
  }
`;

export { Alert, Button, ContainerButton };
