export const downloadFile = (data: Blob, fileName: string): void => {
  const url = window.URL.createObjectURL(new Blob([data]));

  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', fileName);
  document.body.appendChild(link);
  link.click();

  window.URL.revokeObjectURL(url);
  if (document?.body?.removeChild) {
    document.body.removeChild(link);
  }
};
