import { Tag as TagAntd, Typography } from 'antd';
import { NeutralColors } from 'src/theme/colors';
import styled from 'styled-components';
import { MediaQuery } from 'theme';
interface TagProps {
  textColor?: string;
  backgroundColor?: string;
  isLink?: boolean;
  margin?: boolean;
}

interface BigTextProps {
  textColor?: string;
}

const Container = styled.div`
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100%;
`;

const WidgetContainer = styled.div`
  flex: 1;
  margin: 24px 0px;
  height: 200px;
`;

const FlexContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
  gap: 24px;

  rect {
    rx: 5;
    ry: 5;
  }
`;

const Box = styled.div`
  display: flex;
  justify-content: center;
`;

const BorderedContainer = styled.div`
  border: 1px solid ${NeutralColors[20]};
  border-radius: 12px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 100%;

  @media (min-width: ${MediaQuery.screenSizesCss.tablet}) {
    width: calc(33% - 16px);
  }
`;

const VerticalBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: flex-start;
  gap: 8px;
`;

const BigText = styled(Typography.Text)<BigTextProps>`
  font-size: 20px;
  font-weight: 600;
  color: ${({ textColor }) => textColor};
`;

const SmallText = styled.span`
  font-size: 10px;
`;

const BoxTitle = styled.span`
  display: flex;
  align-items: center;
  cursor: pointer;

  span {
    margin: 0 4px;
  }

  svg {
    fill: ${NeutralColors[60]} !important;
    width: 18px;
  }
`;

const StyleTagOutlined = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  background-color: ${NeutralColors[10]};
  margin-right: 10px;

  svg {
    margin: 0 8px;
    width: 14px;
  }
`;

const Tag = styled(TagAntd)<TagProps>`
  background-color: ${(props) => props.backgroundColor || NeutralColors[0]};
  border-color: ${(props) => props.backgroundColor};
  color: ${(props) => props.textColor};
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: fit-content;
  font-weight: 600;
  font-size: 12px;
  padding: 0 4px;
`;

export {
  BigText,
  BorderedContainer,
  Box,
  BoxTitle,
  Container,
  FlexContainer,
  SmallText,
  StyleTagOutlined,
  Tag,
  VerticalBox,
  WidgetContainer,
};
