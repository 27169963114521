import { Tooltip } from 'antd';
import { ReactNode, useMemo } from 'react';

import * as Styled from './excel-table.style';

interface Columns {
  data: ReactNode;
  hasError?: boolean;
}

interface Lines {
  columns: Columns[];
  hasError?: boolean;
  errorMessage?: string | ReactNode;
}

interface ExcelTableProps {
  data: Lines[];
}

const ExcelTable = ({ data }: ExcelTableProps) => {
  const columnSize = useMemo(() => data[0]?.columns?.length ?? 0, [data]);

  return (
    <Styled.Table>
      <Styled.Line>
        <Styled.FirstColumn />
        {Array.from({ length: columnSize }).map((_, index) => (
          <Styled.ColumnTH key={`th_${index}`}>
            {String.fromCharCode(65 + index)}
          </Styled.ColumnTH>
        ))}
      </Styled.Line>
      {data.map((line, index) => (
        <Tooltip
          key={`line_${index}`}
          title={line.hasError ? line.errorMessage : undefined}
        >
          <Styled.Line hasError={line.hasError} isFirstLine={index === 0}>
            <Styled.FirstColumn>{index + 1}</Styled.FirstColumn>
            {line.columns.map((column, indexColumn) => (
              <Styled.Column
                hasError={column.hasError}
                key={`column_${index}_${indexColumn}`}
              >
                {column.data}
              </Styled.Column>
            ))}
          </Styled.Line>
        </Tooltip>
      ))}
    </Styled.Table>
  );
};

export { ExcelTable, ExcelTableProps };
