import { DatePicker, Select } from 'antd';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { useOpeningStockStore } from '../opening-stock.store';
import { useOpeningStockDetails } from './opening-stock-details.hook';
import * as Styled from './opening-stock-details.style';

interface OpeningStockDetailsProps {
  farmId?: string;
}

const OpeningStockDetails: FC<OpeningStockDetailsProps> = ({ farmId }) => {
  const { t } = useTranslation();
  const { warehouseSelectProps } = useOpeningStockDetails(farmId);
  const { showItems } = useOpeningStockStore();

  return (
    <Styled.Box show={!showItems}>
      <Styled.ContainerDates>
        <Styled.FormItem
          label={t('opening_stock.details.date')}
          name={['postingDate']}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <DatePicker format={'DD/MM/YYYY'} />
        </Styled.FormItem>
        <Styled.FormItem
          label={t('opening_stock.details.time')}
          name={['postingTime']}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <DatePicker picker={'time'} format={'HH:mm:ss'} />
        </Styled.FormItem>
      </Styled.ContainerDates>
      {farmId && (
        <Styled.FormItem
          label={t('opening_stock.details.warehouse')}
          name={['selectedWarehouse']}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select
            {...warehouseSelectProps}
            placeholder={t('common.business.warehouse_placeholder')}
          />
        </Styled.FormItem>
      )}
    </Styled.Box>
  );
};

export { OpeningStockDetails as default, OpeningStockDetails };
