import { Table as TableAntd } from 'antd';
import styled from 'styled-components';
import { BlueColors } from 'theme';

import { StockComparisonModel } from './migration.model';

const Table = styled(TableAntd<StockComparisonModel>)`
  min-width: 1160px;

  .fms-table {
    margin: 0 !important;
  }

  .fms-table-expanded-row {
    > .fms-table-cell {
      background-color: unset !important;
      padding: 0 !important;
    }
  }

  thead {
    th {
      padding: 0 8px !important;
    }
  }
`;

const HeaderBlueColumn = styled.div`
  min-height: 62px;
  background-color: ${BlueColors[10]};
  border-radius: 16px;
  padding: 8px;
  border: 1px solid ${BlueColors[30]};
  display: flex;
  align-items: center;

  p {
    margin: 0;
  }

  svg {
    width: 40px;
    margin-right: 8px;
  }
`;

export { HeaderBlueColumn, Table };
