const ROUTES = {
  ROOT: '/',
  AUTHENTICATE: '/auth',
  FARM: '/farm',
  NO_ORGANIZATION: '/no-organization',
  MAINTENANCE: '/maintenance',
  ORGANIZATION: '/organization',
  NO_PERMISSION: '/no-permission',
  NO_PERMISSION_BALANCE: '/no-permission-balance',
  ORGANIZATION_DETAILS: '/organization/:id',
  ITEM: '/item',
  DATA_IMPORT: '/data-import',
  DATA_IMPORT_START: 'start-import',
  DATA_IMPORT_LIST_ROOT: 'list',
  DATA_IMPORT_LIST: 'list/:docType',
  DATA_IMPORT_DETAILS_ROOT: '/data-import/details',
  DATA_IMPORT_DETAILS: 'details/:idDataImport',
  DASHBOARD: '/dashboard',
  REPORT_GENERAL_LEDGER: '/report-general-ledger',
  ITEM_CATEGORY: '/item-category',
  AG_OPERATION_CREATE: '/ag-operation-create',
  PURCHASE: '/purchase',
  ACCOUNTS_PAYABLE: '/accounts-payable',
  INVOICE: '/invoice',
  CREATE: 'create',
  EDIT: 'edit/:id',
  SHOW: 'show/:id',
  MATERIAL_CONSUMPTION: '/material-consumption',
  HELP_CENTER: '/help-center',
  SUPPLIER: '/supplier',
  STOCK: '/stock',
  STOCK_SUMMARY: 'summary',
  FARM_STOCK_SUMMARY_ROOT: 'farm',
  FARM_STOCK_SUMMARY: 'farm/:farmName',
  WAREHOUSE_STOCK_ROOT: 'warehouse',
  WAREHOUSE_STOCK: 'warehouse/:warehouseId',
  MOVEMENTS_STOCK: 'movements',
  SETTINGS: '/settings',
  FINANCIAL: '/financial',
  FINANCIAL_PRODUCTION: '/financial-production',
  MIGRATION: '/migration',
  ELECTRONIC_INVOICE_LINK: '/electronic-invoice-link',
  BANK_ACCOUNT: '/bank-account',
};

export { ROUTES as default, ROUTES };
