import { track } from '@amplitude/analytics-browser';
import { useUpdate } from '@refinedev/core';
import { FormInstance, useWatch } from 'antd/lib/form/Form';
import { useMemo } from 'react';

import { ReferenceValueRequest } from './reference-value-form.model';

interface ReferenceValueFormDetailProps {
  close: () => void;
  form?: FormInstance;
  finalProductRefetch: () => void;
  chartRefetch?: () => void;
  season?: string;
}

const useReferenceValueForm = ({
  close,
  form,
  finalProductRefetch,
  chartRefetch,
  season,
}: ReferenceValueFormDetailProps) => {
  const productId = useWatch(['productId'], form);
  const { mutate } = useUpdate();

  const handleOnClose = () => {
    close();
    form?.resetFields();
  };

  const initialValues = useMemo(() => {
    return { seasonId: season };
  }, [season]);

  const handleOnFinish = (values: ReferenceValueRequest) => {
    mutate(
      {
        resource: 'price/product-final',
        id: productId,
        values: {
          valueReference: values.referenceValue,
        },
      },
      {
        onSuccess: () => {
          handleOnClose();
          finalProductRefetch();
          chartRefetch?.();
          track('Reference Value - Update Price Finish');
        },
      },
    );
  };

  return {
    handleOnClose,
    handleOnFinish,
    initialValues,
  };
};

export { useReferenceValueForm };
