import styled from 'styled-components';
import { NeutralColors } from 'theme';

const SpanComplementaryLabel = styled.span`
  margin-left: 6px;
  font-size: 10px;
  color: ${NeutralColors[40]};
`;

export { SpanComplementaryLabel };
