import { Typography } from 'antd';

import { Farm } from '../farm.model';
import * as Styled from './farm-card.style';

export interface FarmCardType {
  farm: Farm;
  onClick?: () => void;
}

const FarmCardComponent = ({ farm, onClick }: FarmCardType) => (
  <Styled.Card onClick={onClick} data-testid="farm-card">
    <Typography.Title level={5}>{farm.name}</Typography.Title>
  </Styled.Card>
);

export { FarmCardComponent as default, FarmCardComponent };
