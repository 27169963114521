import { useList } from '@refinedev/core';
import { useWatch } from 'antd/es/form/Form';
import useFormInstance from 'antd/es/form/hooks/useFormInstance';
import { useEffect, useMemo } from 'react';
import { FinalProduct } from 'src/financial/reference-value/reference-value.model';
import { useOrganization } from 'src/organization';

interface ReferenceValueFormItemProps {
  isOpenDrawer?: boolean;
}

const useReferenceValueFormItem = ({
  isOpenDrawer,
}: ReferenceValueFormItemProps) => {
  const cropId = useWatch(['cropId']);
  const { currentOrganizationId } = useOrganization();
  const form = useFormInstance();

  const metadata = useMemo(
    () => ({
      organizationId: currentOrganizationId,
    }),
    [currentOrganizationId],
  );

  const {
    data: finalProductData,
    isFetching: finalProductIsFetching,
    refetch: refetchFinalProduct,
  } = useList<FinalProduct>({
    resource: 'item/product-final/search',
    filters: [
      {
        field: 'cropId',
        operator: 'eq',
        value: cropId,
      },
    ],
    queryOptions: {
      enabled: !!cropId,
    },
    meta: metadata,
  });

  const finalProduct = finalProductData?.data?.[0];

  useEffect(() => {
    if (finalProduct) {
      form.setFieldValue('productId', finalProduct.itemCode);
      form.setFieldValue('referenceValue', finalProduct.valueReference);
    }
  }, [finalProduct, form, isOpenDrawer]);

  return {
    cropId,
    finalProduct: finalProduct,
    finalProductIsFetching,
    refetchFinalProduct,
  };
};

export { useReferenceValueFormItem };
