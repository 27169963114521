import { useDrawerForm } from '@refinedev/antd';
import { useOne } from '@refinedev/core';
import { TablePaginationConfig } from 'antd';
import { useRefineTableFilters } from 'common/refine-table-filters';
import { useTracking } from 'common/utils';
import dayjs from 'dayjs';
import { useEffect, useMemo, useState } from 'react';
import api from 'src/api.config';
import { PurchaseDetailModel } from 'src/orders-and-payments/purchase/purchase.model';
import { StatusEnum } from 'src/orders-and-payments/purchase/status.enum';
import { useOrganization } from 'src/organization';

import { ElectronicInvoiceEnum } from './electronic-invoice.enum';
import { ElectronicInvoiceModel } from './electronic-invoice.model';

enum TypeFilter {
  Pending = 'Pending',
  Archived = 'Archived',
  Imported = 'Imported',
}

let lastRefetch = dayjs();
const AWAIT_TIME_REFETCH = 3000;
const MAX_AUTO_REFETCH = 5;
let totalAutoRefetch = 0;

const useElectronicInvoice = (
  referenceType?: string,
  referenceId?: string,
  setIdsElectronicInvoice?: (x: string[]) => void,
  setBlockImport?: (x: boolean) => void,
) => {
  const { track } = useTracking();
  const [currentFilter, setCurrentFilter] = useState(TypeFilter.Pending);
  const [files, setFiles] = useState<string[]>([]);
  const [finishFiles, setFinishFiles] = useState<string[]>([]);
  const { currentOrganizationId } = useOrganization();

  const metadata = useMemo(
    () => ({
      organizationId: currentOrganizationId,
    }),
    [currentOrganizationId],
  );

  const drawerForm = useDrawerForm({
    action: 'edit',
    resource: 'electronic-invoice',
    redirect: false,
    syncWithLocation: {
      key: 'edit',
    },
  });

  const {
    tableProps: tablePropsProcessing,
    tableQueryResult: tableQueryResultProcessing,
  } = useRefineTableFilters<ElectronicInvoiceModel>({
    listFilters: [],
    resource: 'electronic-invoice',
    meta: {
      ...metadata,
    },
    filters: {
      permanent: [
        {
          field: 'status',
          operator: 'eq',
          value: [
            ElectronicInvoiceEnum.PROCESSING,
            ElectronicInvoiceEnum.PROCESSED,
          ],
        },
        {
          field: 'referenceType',
          operator: 'eq',
          value: referenceType,
        },
        {
          field: 'referenceId',
          operator: 'eq',
          value: referenceId,
        },
      ],
    },
  });

  const {
    tableProps: tablePropsArchived,
    tableQueryResult: tableQueryResultArchived,
  } = useRefineTableFilters<ElectronicInvoiceModel>({
    listFilters: [],
    resource: 'electronic-invoice',
    meta: {
      ...metadata,
    },
    filters: {
      permanent: [
        {
          field: 'status',
          operator: 'eq',
          value: ElectronicInvoiceEnum.ARCHIVED,
        },
        {
          field: 'referenceType',
          operator: 'eq',
          value: referenceType,
        },
        {
          field: 'referenceId',
          operator: 'eq',
          value: referenceId,
        },
      ],
    },
  });

  const { tableProps: tablePropsImported } =
    useRefineTableFilters<ElectronicInvoiceModel>({
      listFilters: [],
      resource: 'electronic-invoice',
      meta: {
        ...metadata,
      },
      filters: {
        permanent: [
          {
            field: 'status',
            operator: 'eq',
            value: ElectronicInvoiceEnum.IMPORTED,
          },
          {
            field: 'referenceType',
            operator: 'eq',
            value: referenceType,
          },
          {
            field: 'referenceId',
            operator: 'eq',
            value: referenceId,
          },
        ],
      },
    });

  const {
    tableProps: tablePropsPending,
    tableQueryResult: tableQueryResultPending,
  } = useRefineTableFilters<ElectronicInvoiceModel>({
    listFilters: [],
    resource: 'electronic-invoice',
    pagination: {
      pageSize: 99999999,
    },
    meta: {
      ...metadata,
    },
    filters: {
      permanent: [
        {
          field: 'status',
          operator: 'eq',
          value: [
            ElectronicInvoiceEnum.FAILED,
            ElectronicInvoiceEnum.TRANSFORMED_WITHOUT_MATCH,
            ElectronicInvoiceEnum.TRANSFORMED_WITH_MATCH,
          ],
        },
        {
          field: 'referenceType',
          operator: 'eq',
          value: referenceType,
        },
        {
          field: 'referenceId',
          operator: 'eq',
          value: referenceId,
        },
      ],
    },
  });

  const refetch = (refetchNow?: boolean) => {
    if (refetchNow) {
      totalAutoRefetch = 0;
    }
    if (
      (dayjs().diff(lastRefetch) > AWAIT_TIME_REFETCH &&
        totalAutoRefetch < MAX_AUTO_REFETCH) ||
      refetchNow
    ) {
      lastRefetch = dayjs();
      tableQueryResultProcessing.refetch();
      tableQueryResultPending.refetch();
      tableQueryResultArchived.refetch();
      totalAutoRefetch += 1;
    }
  };

  useEffect(() => {
    setTimeout(refetch, AWAIT_TIME_REFETCH);
  }, [tablePropsPending, tablePropsProcessing]);

  useEffect(() => {
    if (finishFiles.length > 0 && finishFiles.length === files.length) {
      refetch(true);
    }
  }, [finishFiles]);

  useEffect(() => {
    if (setIdsElectronicInvoice && tablePropsPending.dataSource) {
      setIdsElectronicInvoice(
        tablePropsPending.dataSource
          .filter(
            (electronicInvoice) =>
              electronicInvoice.status !== ElectronicInvoiceEnum.FAILED,
          )
          .map((electronicInvoice) => electronicInvoice.id),
      );
    }
  }, [tablePropsPending.dataSource]);

  const sendFile = async (e: any) => {
    files.push(e?.file?.uid);

    setFiles([...files]);
    const formData = new FormData();
    formData.append('file', e.file);
    formData.append('invoiceType', 'NFE');
    if (referenceType && referenceId) {
      formData.append('referenceType', referenceType);
      formData.append('referenceId', referenceId);
    }

    await api
      .post('/v1/electronic-invoice/file', formData, {
        params: { ...metadata },
      })
      .finally(() => {
        finishFiles.push(e?.file?.uid);

        setFinishFiles([...finishFiles]);
      });
  };

  const { data: purchaseOrder } = useOne<PurchaseDetailModel>({
    resource: 'purchase-order',
    id: referenceId,
    queryOptions: {
      enabled: !!referenceId,
    },
  });

  const tableProps = useMemo(() => {
    switch (currentFilter) {
      case TypeFilter.Imported:
        return tablePropsImported;
      case TypeFilter.Archived:
        return tablePropsArchived;
      case TypeFilter.Pending:
      default:
        return tablePropsPending;
    }
  }, [
    currentFilter,
    tablePropsPending,
    tablePropsImported,
    tablePropsArchived,
  ]);

  const handleChangeTypeFilter = (filter: TypeFilter) => {
    switch (filter) {
      case TypeFilter.Archived:
        track('Electronic Invoice - View Archived');
        break;
      case TypeFilter.Imported:
        track('Electronic Invoice - View Imported');
        break;
      case TypeFilter.Pending:
      default:
        track('Electronic Invoice - View Pending');
        break;
    }
    setCurrentFilter(filter);
  };

  const blockImport: boolean = useMemo(
    () =>
      !!referenceId &&
      (purchaseOrder?.data.status === StatusEnum.Completed ||
        purchaseOrder?.data.status === StatusEnum.Cancelled ||
        purchaseOrder?.data.status === StatusEnum.ToReceive ||
        purchaseOrder?.data.status === StatusEnum.Draft),
    [referenceId, purchaseOrder],
  );

  useEffect(() => {
    if (setBlockImport) {
      setBlockImport(blockImport);
    }
  }, [blockImport, setBlockImport]);

  return {
    blockImport,
    currentFilter,
    sendFile,
    handleChangeTypeFilter,
    tableProps,
    drawerForm,
    amountProcessing:
      (tablePropsProcessing.pagination as TablePaginationConfig)?.total ?? 0,
    refetch: () => refetch(true),
    isFetching:
      tableQueryResultPending.isFetching ||
      tableQueryResultProcessing.isFetching,
    amountPending:
      (tablePropsPending.pagination as TablePaginationConfig)?.total ?? 0,
    amountImported:
      (tablePropsImported.pagination as TablePaginationConfig)?.total ?? 0,
    amountArchived:
      (tablePropsArchived.pagination as TablePaginationConfig)?.total ?? 0,
    isSendFetching: files.length !== finishFiles.length,
  };
};

export { TypeFilter, useElectronicInvoice };
