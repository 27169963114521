import styled from 'styled-components';
import { YellowColors } from 'theme';

const EmptyCostCenter = styled.span`
  display: flex;
  align-items: center;
  cursor: pointer;
  color: ${YellowColors[40]};

  span {
    margin-left: 8px;
    text-decoration: underline;
  }
`;

export { EmptyCostCenter };
