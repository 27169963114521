interface BorderRadiusType {
  Default: number;
  Small: number;
  XSmall: number;
}

const BorderRadius: BorderRadiusType = {
  Default: 12,
  Small: 8,
  XSmall: 4,
};

export { BorderRadius };
