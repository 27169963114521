import { CurrencyValue } from 'common/components';
import { useTranslation } from 'react-i18next';

import * as Styled from './info-card-custom-value.style';

interface InfoCardCustomValueProps {
  value: number;
  currency?: string;
  customUom?: string;
  toggleCustomUom?: boolean;
  bold?: boolean;
}

const CustomValue = ({
  value,
  currency,
  customUom,
  toggleCustomUom,
  bold,
}: InfoCardCustomValueProps) => {
  const { i18n } = useTranslation();

  return (
    <Styled.Value bold={bold}>
      {toggleCustomUom ? (
        `${value.toLocaleString(i18n.language, {
          maximumFractionDigits: 2,
        })} ${customUom}`
      ) : (
        <CurrencyValue currency={currency} value={value} />
      )}
    </Styled.Value>
  );
};

export { CustomValue, CustomValue as default };
