import { PageTitle } from 'common/components';
import { ROUTES } from 'common/constants';
import { useNavigateWithOrgAndFarm } from 'common/utils';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { useOrganization } from 'src/organization';
import EntitlementEnum from 'src/organization/entitlements.enum';

import { ResourcesEnum } from './resources.enum';

interface ResourcesTitleProps {
  buttons?: ReactNode;
  selectedTab: ResourcesEnum;
}

const ResourcesTitle = ({ buttons, selectedTab }: ResourcesTitleProps) => {
  const { t } = useTranslation();
  const navigate = useNavigateWithOrgAndFarm();
  const { entitlements } = useOrganization();

  const handleGoToTab = (route: string) => {
    navigate(route);
  };

  const tabs = [
    {
      key: ResourcesEnum.ITEM,
      label: t('item.title') ?? '',
      onClick: () => handleGoToTab(ROUTES.ITEM),
    },
    {
      key: ResourcesEnum.ITEM_CATEGORY,
      label: t('item_category.title') ?? '',
      onClick: () => handleGoToTab(ROUTES.ITEM_CATEGORY),
    },
    {
      key: ResourcesEnum.SUPPLIER,
      label: t('supplier.title') ?? '',
      onClick: () => handleGoToTab(ROUTES.SUPPLIER),
    },
    {
      key: ResourcesEnum.BANK_ACCOUNT,
      label: t('bank_account.title') ?? '',
      onClick: () => handleGoToTab(ROUTES.BANK_ACCOUNT),
      entitlement: EntitlementEnum.INVOICES,
    },
  ];

  return (
    <PageTitle
      title={t('resources.title')}
      tabs={
        tabs.filter(
          (tab) => !tab.entitlement || entitlements.includes(tab.entitlement),
        ) ?? []
      }
      tabsDefaultActiveKey={selectedTab}
      buttons={buttons}
    />
  );
};

export { ResourcesTitle };
