import { useWatch } from 'antd/es/form/Form';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useMaterialConsumptionFormStore } from '../material-consumption-form.store';
import * as Styled from './material-consumption-header.style';

const MaterialConsumptionHeader = () => {
  const { t } = useTranslation();
  const { showItems, setShowItems } = useMaterialConsumptionFormStore();
  const formValues = useWatch([]);

  const itemsLength = formValues?.items?.length ?? 0;

  const disableItemsTab = useMemo(
    () =>
      !formValues?.postingDate || !formValues?.farm || !formValues?.costCenter,
    [formValues?.farm, formValues?.postingDate, formValues?.costCenter],
  );

  return (
    <Styled.BoxButtons>
      <Styled.ButtonSelected
        onClick={() => setShowItems(false)}
        type={showItems ? undefined : 'primary'}
        disabled={false}
      >
        {t('material_consumption_form.details.title')}
      </Styled.ButtonSelected>
      <Styled.ButtonSelected
        onClick={() => setShowItems(true)}
        type={showItems ? 'primary' : undefined}
        disabled={disableItemsTab}
      >
        {t('material_consumption_form.items.title')}
        <Styled.BoxAmount isSelected={showItems}>
          {itemsLength}
        </Styled.BoxAmount>
      </Styled.ButtonSelected>
    </Styled.BoxButtons>
  );
};

export { MaterialConsumptionHeader };
