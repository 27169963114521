import { Alert, Button } from 'antd';
import { ErrorCircleOutlined } from 'common/icons';
import styled from 'styled-components';

const DeleteInfoAlert = styled(Alert)`
  display: flex;
  align-items: center;
  font-size: 16px;
  gap: 13px;
  margin-top: 16px;
  margin-bottom: 16px;
  border-radius: 5px;
`;

const InfoCircleIcon = styled(ErrorCircleOutlined)`
  font-size: 18px;
`;

const UndoButton = styled(Button)`
  align-self: end;
  width: min-content;
`;

const AlertWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export { AlertWrapper, DeleteInfoAlert, InfoCircleIcon, UndoButton };
