import { Form } from 'antd';
import styled from 'styled-components';
interface ContainerProps {
  show: boolean;
}

const FormItem = styled(Form.Item)`
  margin-top: 24px;
`;

const Container = styled.div<ContainerProps>`
  display: ${(props) => (props.show ? 'block' : 'none')};
  position: relative;
  height: 100%;
  padding-bottom: 48px;
`;

const ContainerButton = styled.div`
  position: fixed;
  bottom: 48px;
  width: calc(100% - 48px);
  max-width: 352px;
`;

export { Container, ContainerButton, FormItem };
