import { Button, Form } from 'antd';
import { useWatch } from 'antd/es/form/Form';
import { ElectronicInvoiceColoredIcon, HelpIcon } from 'common/icons';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ElectronicInvoiceStepEnum } from 'src/electronic-invoice/electronic-invoice.enum';
import { ElectronicInvoicesWithOrderRequest } from 'src/electronic-invoice/electronic-invoice.model';
import { PurchaseDetailModel } from 'src/orders-and-payments/purchase';

import { ElectronicInvoiceItem } from './electronic-invoice-item/electronic-invoice-item.component';
import * as Styled from './electronic-invoice-items.style';

interface ElectronicInvoiceItemsProps {
  openCreateItem: () => void;
  purchaseOrder?: PurchaseDetailModel;
  currentStep: ElectronicInvoiceStepEnum;
  setCurrentStep: (e: ElectronicInvoiceStepEnum) => void;
  setIsLoading: (e: boolean) => void;
  isLoading: boolean;
}

const ElectronicInvoiceItems = ({
  currentStep,
  purchaseOrder,
  isLoading,
  setCurrentStep,
  openCreateItem,
  setIsLoading,
}: ElectronicInvoiceItemsProps) => {
  const { t } = useTranslation();
  const allData = useWatch<ElectronicInvoicesWithOrderRequest>([]);

  const amountItems = allData?.electronicInvoices?.reduce(
    (previousValue, currentValue) => previousValue + currentValue.items.length,
    0,
  );

  const amountITemsVerified = allData?.electronicInvoices?.reduce(
    (previousValue, currentValue) =>
      previousValue +
      currentValue.items.reduce(
        (previousValueItem, item) =>
          item.suggestedItemCode ? previousValueItem + 1 : previousValueItem,
        0,
      ),
    0,
  );

  const isCurrentStep = useMemo(
    () => currentStep === ElectronicInvoiceStepEnum.LINK_ITEMS && !isLoading,
    [currentStep, isLoading],
  );

  const handleGoToStep = () => {
    setCurrentStep(ElectronicInvoiceStepEnum.LINK_ITEMS);
  };

  const handleGoToNextStep = () => {
    setIsLoading(true);

    setTimeout(() => {
      setIsLoading(false);
      setCurrentStep(ElectronicInvoiceStepEnum.PAID_RECEIVED);
    }, 1000);
  };

  return (
    <>
      <Styled.ContainerQuestion isQuestion={isCurrentStep}>
        <ElectronicInvoiceColoredIcon />
        <Styled.BoxQuestion isQuestion={isCurrentStep} onClick={handleGoToStep}>
          {isCurrentStep ? (
            <>
              <Styled.TitleQuestion level={4}>
                {t('electronic_invoice.items.title_question')}
              </Styled.TitleQuestion>
              <Styled.Question>
                {`${t('electronic_invoice.items.amount', {
                  amountITemsVerified,
                  amountItems,
                })} - `}
                <span>{t('electronic_invoice.items.question')}</span>
                <HelpIcon />
              </Styled.Question>
            </>
          ) : (
            <>
              {`${t('electronic_invoice.items.finish_items')} `}
              <span>{amountItems}</span>
              {` ${t('item.title')}`}
            </>
          )}
        </Styled.BoxQuestion>
      </Styled.ContainerQuestion>
      {isCurrentStep && (
        <>
          <Styled.ContainerOptions>
            <Form.List name="electronicInvoices">
              {(electronicInvoices) => (
                <>
                  {electronicInvoices.map((electronicInvoice) => (
                    <ElectronicInvoiceItem
                      itemFieldData={electronicInvoice}
                      key={electronicInvoice.key}
                      openCreateItem={openCreateItem}
                      purchaseOrder={purchaseOrder}
                    />
                  ))}
                </>
              )}
            </Form.List>
          </Styled.ContainerOptions>
          {amountITemsVerified === amountItems && (
            <Styled.FormItemSubmit>
              <Button type="primary" onClick={handleGoToNextStep}>
                {t('buttons.continue')}
              </Button>
            </Styled.FormItemSubmit>
          )}
        </>
      )}
    </>
  );
};

export { ElectronicInvoiceItems };
