import { NotificationProvider } from '@refinedev/core';
import { notification } from 'antd';
import { CheckCircleIcon, CloseIcon, InfoIcon } from 'common/icons';

const NotificationIcon = {
  success: <CheckCircleIcon />,
  error: <InfoIcon />,
  progress: <InfoIcon />,
};

const notificationProvider: NotificationProvider = {
  open: ({ key, message, description, type }) => {
    notification.open({
      key,
      description: message,
      message: description ?? null,
      type: type === 'progress' ? undefined : type,
      className: 'fms-custom-notification',
      icon: NotificationIcon[type],
      closeIcon: <CloseIcon />,
    });
  },
  close: (key) => notification.destroy(key),
};

export { notificationProvider as default, notificationProvider };
