import { useOne } from '@refinedev/core';
import { downloadFile } from 'common/utils/download.util';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useOrganization } from 'src/organization';

import { ExportFields } from '../data-import.constant';
import { DocTypeEnum, FileTypeEnum } from '../data-import.enum';

export const useDataExport = () => {
  const VALUE_ALL_DATA = 'VALUE_ALL_DATA';
  const { i18n } = useTranslation();
  const { t } = useTranslation();
  const [isDownload, setIsDownload] = useState<boolean>(false);
  const [open, setOpen] = useState(false);
  const [docType, setDocType] = useState<DocTypeEnum>();
  const [fileType, setFileType] = useState<FileTypeEnum>();
  const [nextDownload, setNextDownload] = useState<DocTypeEnum | undefined>();
  const userLanguage = i18n.language.toLocaleLowerCase();
  const { currentOrganizationId } = useOrganization();

  const metadata = useMemo(
    () => ({
      organizationId: currentOrganizationId,
    }),
    [currentOrganizationId],
  );

  const showModal = () => {
    setOpen(true);
    setFileType(undefined);
    setDocType(undefined);
  };

  const hideModal = () => {
    setOpen(false);
  };

  const {
    data: dataCsv,
    refetch,
    remove,
    isFetching,
  } = useOne({
    resource: 'export',
    id: isDownload && docType ? docType : undefined,
    meta: {
      params: {
        fileType,
        exportRecords: 'all',
        exportFields: docType ? ExportFields[docType] : [],
        language: userLanguage,
        ...metadata,
      },
      responseType: 'arraybuffer',
    },
    queryOptions: {
      enabled: isDownload && !!docType,
    },
  });

  useEffect(() => {
    if (dataCsv) {
      downloadFile(
        dataCsv.data as Blob,
        `${t(`data_import.doc_type.${docType}`)}.${
          fileType === FileTypeEnum.CSV ? 'csv' : 'xlsx'
        }`,
      );
    }
    setIsDownload(false);
  }, [dataCsv]);

  useEffect(() => {
    if (!isFetching && nextDownload) {
      let currentNextDownload;
      Object.values(DocTypeEnum).forEach((key, index) => {
        if (key === nextDownload) {
          currentNextDownload = Object.values(DocTypeEnum)[index + 1];
        }
      });

      setDocType(nextDownload);
      setNextDownload(currentNextDownload);
      setIsDownload(true);
      if (!currentNextDownload) {
        hideModal();
      }
    }
  }, [isFetching, nextDownload]);

  const handleOnClickDownload = () => {
    if ((docType as string) === VALUE_ALL_DATA) {
      handleDownloadAll();
    } else {
      if (dataCsv) {
        remove();
        refetch();
      }
      setIsDownload(true);
    }
  };

  const handleOnChangeSelect = (value: DocTypeEnum) => {
    setDocType(value);
  };

  const handleDownloadAll = () => {
    setDocType(Object.values(DocTypeEnum)[0]);
    setNextDownload(Object.values(DocTypeEnum)[1]);
    setIsDownload(true);
  };

  return {
    VALUE_ALL_DATA,
    open,
    docType,
    fileType,
    isFetching,
    setFileType,
    showModal,
    hideModal,
    setDocType,
    handleOnClickDownload,
    handleOnChangeSelect,
    handleDownloadAll,
  };
};
