import { Button as ButtonAntd } from 'antd';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  gap: 8px;
  justify-content: flex-end;
`;

const Button = styled(ButtonAntd)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  border-radius: 16px;
  padding: 0;
`;

export { Button, Container };
