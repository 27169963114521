import { Button, Form, Grid, Input, Select, Skeleton, Typography } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import { DrawerWithButtons } from 'common/components';
import { NewTabIcon, WarehouseColoredIcon } from 'common/icons';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

import { StockItemModel } from '../stock-summary/stock-summary.model';
import { useAdjustmentStock } from './adjustment-stock.hook';
import * as Styled from './adjustment-stock.style';

interface AdjustmentStockProps {
  item: StockItemModel | null;
  setItem: (x: StockItemModel | null) => void;
  refetch: () => void;
}

const AdjustmentStock = ({ item, setItem, refetch }: AdjustmentStockProps) => {
  const { t } = useTranslation();
  const breakpoint = Grid.useBreakpoint();

  const {
    createDrawerProps,
    formProps,
    formValues,
    isFetching,
    isMinUnitValue,
    itemStockComparison,
    saveButtonProps,
    saveLoading,
    seasonSelectProps,
    handleOnClose,
    handleOnClearStock,
    handleOnFinish,
    handleGoToPurchase,
  } = useAdjustmentStock({ item, setItem, refetch });

  return (
    <DrawerWithButtons
      {...createDrawerProps}
      buttonSaveProps={saveButtonProps}
      disabledSave={isMinUnitValue}
      isLoading={saveLoading}
      onClose={handleOnClose}
      width={breakpoint.sm ? '400px' : '100%'}
      title={t('stock.adjustment_stock.title')}
    >
      {isFetching ? (
        <Skeleton active />
      ) : (
        <Form {...formProps} onFinish={handleOnFinish} layout="vertical">
          {isMinUnitValue && (
            <Styled.Alert
              message={t('stock.adjustment_stock.title_warning_message')}
              description={t('stock.adjustment_stock.warning_message')}
              type="warning"
              showIcon
              buttonProps={{
                children: t('stock.adjustment_stock.go_to_purchase'),
                onClick: handleGoToPurchase,
              }}
              rightIconButton={<NewTabIcon />}
            />
          )}
          <Styled.AdjustedOn>
            <WarehouseColoredIcon />
            {t('stock.adjustment_stock.adjusted_on', {
              date: dayjs().format('DD/MM/YYYY'),
            })}
          </Styled.AdjustedOn>
          <Styled.Box>
            <Styled.ContainerFlex>
              <Typography.Title level={3}>
                {itemStockComparison?.item}
              </Typography.Title>
              <Button onClick={handleOnClearStock}>
                {t('stock.adjustment_stock.clear_stock')}
              </Button>
            </Styled.ContainerFlex>
            <Form.Item
              label={t('stock.adjustment_stock.current_quantity')}
              name={['newQty']}
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Styled.InputQty decimalSeparator={','} />
            </Form.Item>
            <Styled.ContainerFlex>
              <Form.Item label={t('stock.adjustment_stock.value_rate')}>
                <Input
                  disabled
                  value={itemStockComparison?.fmsUnitValue ?? 0}
                />
              </Form.Item>
              <Form.Item label={t('stock.adjustment_stock.total_value')}>
                <Input
                  disabled
                  value={
                    formValues?.newQty *
                    (itemStockComparison?.fmsUnitValue ?? 0)
                  }
                />
              </Form.Item>
            </Styled.ContainerFlex>
            <Form.Item label={t('common.business.season')} name={['seasonId']}>
              <Select
                {...seasonSelectProps}
                placeholder={t('common.business.season_placeholder')}
              />
            </Form.Item>
            <Form.Item
              label={t('common.business.description')}
              name={['description']}
              rules={[{}]}
            >
              <TextArea
                placeholder={
                  t('stock.adjustment_stock.description_placeholder') ?? ''
                }
                count={{ show: true, max: 100 }}
              />
            </Form.Item>
          </Styled.Box>
          <p>{t('stock.adjustment_stock.message')}</p>
        </Form>
      )}
    </DrawerWithButtons>
  );
};

export { AdjustmentStock };
