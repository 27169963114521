import { ArrowIcon } from 'common/icons';
import styled from 'styled-components';

interface ArrowIconProps {
  arrowUp: boolean;
  fillColor: string;
  hidden?: boolean;
}

const CustomArrowIcon = styled(ArrowIcon)<ArrowIconProps>`
  display: ${({ hidden }) => (hidden ? 'none' : 'block')};
  transform: ${({ arrowUp }) => (arrowUp ? 'rotate(180deg)' : 'rotate(0deg)')};
  fill: ${({ fillColor }) => fillColor};
`;

export { CustomArrowIcon };
