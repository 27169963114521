import { Button } from 'antd';
import styled from 'styled-components';
import { NeutralColors } from 'theme';

const TrashButton = styled(Button)`
  display: flex;
  width: 24px;
  align-items: center;
  justify-content: center;
  border: 1px solid ${NeutralColors[70]};
  cursor: pointer;
`;

const CreateButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    display: inline-block;
  }
`;

const ViewButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    display: inline-block;
  }
`;

export { CreateButton, TrashButton, ViewButton };
