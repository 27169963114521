import { DrawerProps, Form, FormProps, Grid, Tabs } from 'antd';
import { ButtonProps } from 'antd/lib/button';
import { FormInstance } from 'antd/lib/form/Form';
import { DrawerWithButtons } from 'common/components';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { ResultItem } from 'src/financial/financial-overview/financial-overview.model';

import ObjectiveFormItems from './items/objective-form-items.component';
import { useObjectiveForm } from './objective-form.hook';
import { ObjectiveModel } from './objective-form.model';
import * as Styled from './objective-form.style';

interface ObjectiveFormProps {
  drawerProps: DrawerProps;
  close: () => void;
  form: FormInstance<ObjectiveModel>;
  formProps: FormProps;
  saveButtonProps: ButtonProps;
  formLoading: boolean;
  farm?: string;
  season?: string;
  resultItemList?: ResultItem[];
  openKey?: string;
  refetchChart: () => void;
}

const ObjectiveForm: FC<ObjectiveFormProps> = ({
  drawerProps,
  close,
  form,
  formProps,
  saveButtonProps,
  formLoading,
  farm,
  season,
  resultItemList,
  openKey,
  refetchChart,
}) => {
  const { t } = useTranslation();
  const breakpoint = Grid.useBreakpoint();

  const { handleOnClose, handleOnFinish, activeKey, setActiveKey } =
    useObjectiveForm({
      isOpen: drawerProps.open ?? false,
      close: close,
      farm,
      openKey: openKey,
      form,
      season,
      resultItemList,
      refetchChart,
    });

  return (
    <DrawerWithButtons
      {...drawerProps}
      buttonSaveProps={saveButtonProps}
      width={breakpoint.sm ? '400px' : '100%'}
      title={t('financial.objective.form.title')}
      onClose={handleOnClose}
      isLoading={formLoading}
      destroyOnClose
    >
      <Form {...formProps} layout="vertical" onFinish={handleOnFinish}>
        <Tabs animated centered activeKey={activeKey} onChange={setActiveKey}>
          <Tabs.TabPane
            key="cost"
            tab={
              <Styled.ObjectiveTabLabel>
                {t('financial.objective.form.cost')}
              </Styled.ObjectiveTabLabel>
            }
          >
            <ObjectiveFormItems objectiveType="cost" farm={farm} />
          </Tabs.TabPane>
          <Tabs.TabPane
            key="productivity"
            tab={
              <Styled.ObjectiveTabLabel>
                {t('financial.objective.form.productivity')}
              </Styled.ObjectiveTabLabel>
            }
          >
            <ObjectiveFormItems objectiveType="productivity" farm={farm} />
          </Tabs.TabPane>
        </Tabs>
      </Form>
    </DrawerWithButtons>
  );
};

export { ObjectiveForm as default, ObjectiveForm };
