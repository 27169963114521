import { Skeleton } from 'antd';
import { ROUTES } from 'common/constants';
import { usePermissionsBalance } from 'common/hooks/use-permissions-balance.hook';
import { ActionRbacEnum } from 'common/utils/auth-provider.util';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useEffect, useMemo } from 'react';
import { Navigate, useLocation, useSearchParams } from 'react-router-dom';

import EntitlementEnum from './entitlements.enum';
import { useFarm } from './farm/farm.provider';
import { useOrganization } from './organization.provider';

interface RequireOrganizationProps {
  children: JSX.Element;
  permissionScreen?: ActionRbacEnum;
  entitlementScreen?: EntitlementEnum;
}

const RequireOrganization = ({
  children,
  permissionScreen,
  entitlementScreen,
}: RequireOrganizationProps) => {
  const {
    organizations,
    entitlements,
    currentOrganization,
    setCurrentOrganizationId,
    isLoading: isLoadingOrganization,
    isFetched: isFetchedOrganization,
  } = useOrganization();
  const { blockFmsMaintenance } = useFlags<{ blockFmsMaintenance: string }>();
  const { setCurrentFarmId, isLoading: isFarmLoading } = useFarm();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const searchParamsOrganizationId = searchParams.get('organizationId');
  const searchParamsFarmId = searchParams.get('farmId');
  const { permissions } = usePermissionsBalance();
  const isLoading = useMemo(
    () => isLoadingOrganization || isFarmLoading,
    [isLoadingOrganization, isFarmLoading],
  );
  const isFetched = useMemo(
    () => isFetchedOrganization && !isLoading,
    [isFetchedOrganization, isLoading],
  );
  const isOrganizationsMissing = useMemo(
    () => isFetched && !organizations.length,
    [isFetched, organizations.length],
  );

  const isSearchParamsOrganizationMissing = useMemo(
    () =>
      searchParamsOrganizationId &&
      isFetched &&
      !organizations.find(
        (organization) => organization.id === searchParamsOrganizationId,
      ),
    [organizations, searchParamsOrganizationId, isFetchedOrganization],
  );

  const isCurrentOrganizationMissing = useMemo(
    () => isFetched && !currentOrganization && !searchParamsOrganizationId,
    [isLoading, currentOrganization, searchParamsOrganizationId],
  );

  useEffect(() => {
    if (searchParamsOrganizationId && !isSearchParamsOrganizationMissing) {
      setCurrentOrganizationId(searchParamsOrganizationId);
    }
  }, [
    searchParamsOrganizationId,
    isSearchParamsOrganizationMissing,
    setCurrentOrganizationId,
  ]);

  useEffect(() => {
    if (searchParamsFarmId) {
      setCurrentFarmId(searchParamsFarmId);
    }
  }, [searchParamsFarmId, setCurrentFarmId]);

  if (blockFmsMaintenance) {
    return (
      <Navigate to={ROUTES.MAINTENANCE} state={{ from: location }} replace />
    );
  }

  if (isOrganizationsMissing) {
    return (
      <Navigate
        to={ROUTES.NO_ORGANIZATION}
        state={{ from: location }}
        replace
      />
    );
  }

  if (isFetched) {
    if (isCurrentOrganizationMissing) {
      return (
        <Navigate to={ROUTES.ORGANIZATION} state={{ from: location }} replace />
      );
    }

    if (isSearchParamsOrganizationMissing) {
      return (
        <Navigate to={ROUTES.ORGANIZATION} state={{ from: location }} replace />
      );
    }
  }

  if (
    (permissionScreen &&
      permissions.length > 0 &&
      !permissions.includes(permissionScreen)) ||
    (entitlementScreen && !entitlements.includes(entitlementScreen))
  ) {
    return (
      <Navigate to={ROUTES.NO_PERMISSION} state={{ from: location }} replace />
    );
  }

  if (
    permissions.length > 0 &&
    !permissions.includes(ActionRbacEnum.ACCESS_BALANCE)
  ) {
    return (
      <Navigate
        to={ROUTES.NO_PERMISSION_BALANCE}
        state={{ from: location }}
        replace
      />
    );
  }

  return isLoading || permissions === undefined ? (
    <Skeleton active />
  ) : (
    children
  );
};

export { RequireOrganization as default, RequireOrganization };
