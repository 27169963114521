import { Typography } from 'antd';
import { CheckIcon, FinishColoredIcon } from 'common/icons';
import { useTranslation } from 'react-i18next';

import * as Styled from './electronic-invoice-confirm.style';

interface ElectronicInvoiceConfirmProps {
  isLoading: boolean;
}

const ElectronicInvoiceConfirm = ({
  isLoading,
}: ElectronicInvoiceConfirmProps) => {
  const { t } = useTranslation();

  return (
    <>
      {!isLoading && (
        <Styled.ContainerQuestion>
          <FinishColoredIcon />
          <Styled.BoxQuestion>
            <Typography.Title level={4}>
              {t('electronic_invoice.confirm.text')}
            </Typography.Title>
            <Styled.Description>
              {t('electronic_invoice.confirm.description')}
            </Styled.Description>
          </Styled.BoxQuestion>
        </Styled.ContainerQuestion>
      )}

      <Styled.ContainerButtons>
        <Styled.Button type="primary" htmlType="submit" loading={isLoading}>
          {t('buttons.finish')}
          <CheckIcon />
        </Styled.Button>
      </Styled.ContainerButtons>
    </>
  );
};

export { ElectronicInvoiceConfirm };
