import { Table as TableAntd } from 'antd';
import styled from 'styled-components';
import { BlueColors, NeutralColors } from 'theme';

const Container = styled.div`
  border-bottom: 4px solid ${NeutralColors[10]};
`;

const Table = styled(TableAntd)`
  padding-left: 56px;
  border-bottom: 1px solid ${NeutralColors[30]};

  .blue-column {
    background-color: ${BlueColors[10]};
  }
`;

export { Container, Table };
