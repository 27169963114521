import { Button, Dropdown, Form, Typography } from 'antd';
import { ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { FormPropsStore } from './refine-table-filters.hooks';
import { ListFiltersModel } from './refine-table-filters.model';
import { useRefineTableFiltersStore } from './refine-table-filters.store';
import * as Styled from './refine-table-filters.style';
import { RefineTableFiltersItem } from './refine-table-filters-item/refine-table-filters-item.component';

const { Text } = Typography;

interface TableFiltersProps {
  searchFormProps: FormPropsStore;
  initialListFilters: ListFiltersModel[];
  leftItems?: ReactNode;
}

export const RefineTableFilters = ({
  searchFormProps,
  initialListFilters,
  leftItems,
}: TableFiltersProps) => {
  const [isEmpty, setIsEmpty] = useState(true);
  const [listFilters, setListFilters] =
    useState<ListFiltersModel[]>(initialListFilters);
  const { selectedStore } = useRefineTableFiltersStore(
    searchFormProps?.storeName,
  );
  const { t } = useTranslation();

  const listFiltersShow = useMemo(() => {
    return listFilters.filter((filter) => !!filter.show);
  }, [listFilters]);

  const listFiltersHide = useMemo(() => {
    return listFilters.filter((filter) => !filter.show);
  }, [listFilters]);

  useEffect(() => {
    const allEmpty = listFilters.every((filter) => {
      const value = searchFormProps?.form?.getFieldValue(filter.field);
      return !value;
    });

    setIsEmpty(allEmpty);
  }, [searchFormProps, listFilters]);

  const initialValues = useMemo(
    () =>
      selectedStore
        ? selectedStore.filter
        : listFilters
            .filter((filter) => !!filter.initialValue)
            .reduce((acc, filter) => {
              return {
                [filter.field]: filter.initialValue,
                ...acc,
              };
            }, {}),
    [listFilters, selectedStore],
  );

  const handleInsertFilter = (field: string) => {
    setListFilters((prev) => {
      return prev.map((filter) => {
        if (filter.field === field) {
          return { ...filter, show: true };
        }
        return filter;
      });
    });
  };

  const handleOnClickReset = useCallback(() => {
    listFiltersShow.forEach((filter) => {
      searchFormProps.form?.setFieldsValue({ [filter.field]: undefined });
    });
    searchFormProps.form?.submit();
  }, [searchFormProps, listFiltersShow]);

  const handleOnClickClearField = (fieldId: string) => {
    const value = searchFormProps?.form?.getFieldValue(fieldId);
    const initialFilterShow = initialListFilters.find(
      (filter) => filter.field === fieldId,
    )?.show;
    setListFilters((prev) => {
      return prev.map((filter) => {
        if (filter.field === fieldId && !initialFilterShow && !value) {
          return { ...filter, show: false };
        }
        return filter;
      });
    });
  };

  return (
    <Styled.Container>
      <Form
        layout="inline"
        {...searchFormProps}
        initialValues={initialValues}
        onValuesChange={() => {
          searchFormProps.form?.submit();
        }}
      >
        {leftItems}
        {listFiltersShow.map((filter) => (
          <RefineTableFiltersItem
            searchFormProps={searchFormProps}
            key={`list_filter_${filter.field}`}
            filter={filter}
            onClickClearIfEmpty={handleOnClickClearField}
            storeName={searchFormProps?.storeName}
          />
        ))}
        {listFiltersHide.map((filter) => (
          <RefineTableFiltersItem
            searchFormProps={searchFormProps}
            key={`list_filter_${filter.field}`}
            filter={filter}
            onClickClearIfEmpty={handleOnClickClearField}
            isHidden
          />
        ))}
        {listFiltersHide.length > 0 && (
          <Form.Item>
            <Dropdown
              menu={{
                items: listFiltersHide.map((filter) => ({
                  label: filter.label,
                  key: filter.field,
                  icon: filter.icon,
                  onClick: () => handleInsertFilter(filter.field),
                })),
              }}
              trigger={['click']}
            >
              <Button
                icon={<Styled.IconPlus rev={undefined} />}
                type="text"
                onClick={(e) => e.preventDefault()}
              >
                <Text strong>{t('buttons.more_filter')}</Text>
              </Button>
            </Dropdown>
          </Form.Item>
        )}
        {!isEmpty && (
          <Form.Item>
            <Button
              icon={<Styled.IconClose rev={undefined} />}
              type="text"
              onClick={handleOnClickReset}
            >
              <Text strong>{t('buttons.clear_filter')}</Text>
            </Button>
          </Form.Item>
        )}
      </Form>
    </Styled.Container>
  );
};
