import styled from 'styled-components';
import { RedColors } from 'theme';

const CancelBox = styled.div`
  display: flex;
  align-items: center;
  padding: 12px;
  border: 1px solid ${RedColors[30]};
  background-color: ${RedColors[10]};
  border-radius: 4px;
  margin-top: 16px;

  svg {
    width: 40px;
    height: 40px;
    fill: ${RedColors[60]};
    margin-right: 16px;
  }
`;

export { CancelBox };
