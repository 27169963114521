import { ButtonProps, Typography } from 'antd';
import { ReactNode } from 'react';

import * as Styled from './selectable-vertical-button.style';

interface SelectableVerticalButtonProps extends ButtonProps {
  icon: ReactNode;
  title: string;
  isSelected?: boolean;
}

const SelectableVerticalButton = ({
  icon,
  title,
  isSelected,
  ...props
}: SelectableVerticalButtonProps) => {
  return (
    <Styled.Button
      type={isSelected ? 'primary' : 'default'}
      isSelected={isSelected}
      {...props}
    >
      {icon}
      <Typography.Text>{title}</Typography.Text>
    </Styled.Button>
  );
};

export { SelectableVerticalButton, SelectableVerticalButtonProps };
