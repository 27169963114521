import { Button } from 'antd';
import styled from 'styled-components';
import { GreenColors, NeutralColors } from 'theme';

const ButtonInsertPrimary = styled(Button)`
  display: flex;
  align-items: center;

  span {
    display: inline-block;
    font-size: 14px;
  }

  svg {
    width: 18px;
  }
`;

const ButtonInsertProduction = styled(ButtonInsertPrimary)`
  :hover {
    svg {
      fill: ${GreenColors[60]} !important;
    }
  }

  svg {
    fill: ${NeutralColors[60]} !important;
  }
`;

const ButtonView = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 32px;
`;

export { ButtonInsertPrimary, ButtonInsertProduction, ButtonView };
