import { Grid, Skeleton, Typography } from 'antd';
import { ChartTitle, DonutChart } from 'common/charts';
import { EmptyState } from 'common/components';
import {
  DollarCircleIcon,
  FieldIcon,
  PieChartIcon,
  SavingsIcon,
  TrendingUpIcon,
} from 'common/icons';
import { EmptyFilterImage } from 'common/images';
import { RefineTableFilters } from 'common/refine-table-filters';
import { useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import EntitlementEnum from 'src/organization/entitlements.enum';

import { FinancialEnum } from '../financial.enum';
import { InsertProduction } from '../financial-production/insert-production/insert-production.component';
import { FinancialTitle } from '../financial-title.component';
import ObjectiveForm from '../objective/form/objective-form.component';
import ReferenceValueButton from '../reference-value/button/reference-value-button.component';
import ReferenceValueForm from '../reference-value/form/reference-value-form.component';
import ReferenceValueToggle from '../reference-value/toggle/reference-value-toggle.component';
import { CropCostChart } from './crop-cost-chart/crop-cost-chart.component';
import { useFinancialOverview } from './financial-overview.hook';
import * as Styled from './financial-overview.style';
import HelpTourComponent from './help-tour/help-tour.component';
import { InfoCard } from './info-card/info-card.component';

const FinancialContainer = () => {
  const breakpoint = Grid.useBreakpoint();
  const isMobile = !breakpoint.md;
  const { t } = useTranslation();
  const [isHelpTourOpen, setIsHelpTourOpen] = useState(false);
  const refChart = useRef(null);
  const refButton = useRef(null);

  const {
    entitlements,
    finalProduct,
    finalProductRefetch,
    referenceValueDrawerProps,
    toggleReferenceValue,
    handleOnReferenceValueClick,
    filterValues,
    listFilters,
    handleCategoryClick,
    searchFormProps,
    chartData,
    isChartLoading,
    chartByCategoryData,
    needInitialFilters,
    isChartByCategoryLoadingData,
    currency,
    currencySymbol,
    insertProductionDrawerForm,
    objectiveDrawerForm,
    objectiveDrawerOpenKey,
    chartDataRefetch,
    handleOpenInsertProductionDrawer,
    handleOpenObjectiveDrawer,
  } = useFinancialOverview();

  const isEmpty: boolean = useMemo(
    () => chartData?.data.total === 0 && !needInitialFilters && !isChartLoading,
    [chartData?.data.total, isChartLoading, needInitialFilters],
  );

  const renderCropCostChart = useMemo(
    () => (
      <CropCostChart
        data={chartData}
        isFetching={isChartLoading}
        currency={currency}
        finalProduct={finalProduct}
        toggleReferenceValue={toggleReferenceValue}
        categoryOrder={
          chartByCategoryData?.data.result.map((item) => item.id) ?? []
        }
        ref={refChart}
        isEmpty={isEmpty}
        entitlements={entitlements}
      />
    ),
    [
      chartData,
      isChartLoading,
      currency,
      finalProduct,
      toggleReferenceValue,
      chartByCategoryData?.data.result,
    ],
  );

  const handleOnClickHelp = () => {
    setIsHelpTourOpen(true);
  };

  return (
    <>
      <HelpTourComponent
        open={isHelpTourOpen}
        onClose={() => setIsHelpTourOpen(false)}
        refList={[refChart, refButton]}
      />
      <ReferenceValueForm
        drawerProps={referenceValueDrawerProps.drawerProps}
        formProps={referenceValueDrawerProps.formProps}
        saveButtonProps={referenceValueDrawerProps.saveButtonProps}
        formLoading={referenceValueDrawerProps.formLoading}
        close={referenceValueDrawerProps.close}
        finalProductRefetch={finalProductRefetch}
        farm={filterValues?.farm}
        season={filterValues?.seasonId}
        chartRefetch={chartDataRefetch}
      />
      <InsertProduction
        drawerForm={insertProductionDrawerForm}
        farm={[filterValues?.farm]}
        seasonId={filterValues?.seasonId}
        project={filterValues?.project}
      />
      <ObjectiveForm
        drawerProps={objectiveDrawerForm.drawerProps}
        close={objectiveDrawerForm.close}
        saveButtonProps={objectiveDrawerForm.saveButtonProps}
        formProps={objectiveDrawerForm.formProps}
        form={objectiveDrawerForm.form}
        formLoading={objectiveDrawerForm.formLoading}
        refetchChart={chartDataRefetch}
        farm={filterValues?.farm}
        season={filterValues?.seasonId}
        resultItemList={chartData?.data?.result}
        openKey={objectiveDrawerOpenKey}
      />
      <FinancialTitle
        selectedTab={FinancialEnum.OVERVIEW}
        buttons={
          entitlements.includes(EntitlementEnum.PRODUCTION_YIELD_DATA) && (
            <>
              <ReferenceValueButton
                onClick={handleOnReferenceValueClick}
                seasonId={filterValues?.seasonId}
                farm={filterValues?.farm}
                finalProduct={finalProduct}
                ref={refButton}
              />
              <ReferenceValueToggle />
            </>
          )
        }
      />
      <RefineTableFilters
        searchFormProps={searchFormProps}
        initialListFilters={listFilters}
      />
      {needInitialFilters && (
        <EmptyState
          image={<EmptyFilterImage />}
          title={t('financial.empty_state.title')}
          description={`${t('financial.empty_state.description')}`}
        />
      )}
      {isChartByCategoryLoadingData && <Skeleton active />}
      {!isChartByCategoryLoadingData && !needInitialFilters && (
        <Styled.DashboardWrapper>
          {isMobile && renderCropCostChart}
          <Styled.Category>
            <ChartTitle
              icon={<PieChartIcon />}
              text={t('financial.total_by_item_category')}
            />
            {isEmpty && (
              <Styled.EmptyContainer>
                <Styled.EmptyImage />
                <Styled.EmptyTextWrapper>
                  <Typography.Title level={5}>
                    {t('financial.no_data.category_chart.title')}
                  </Typography.Title>
                  <Typography.Paragraph>
                    {t('financial.no_data.category_chart.description_1')}
                  </Typography.Paragraph>
                </Styled.EmptyTextWrapper>
              </Styled.EmptyContainer>
            )}
            {chartByCategoryData?.data && !isEmpty && (
              <DonutChart
                data={chartByCategoryData?.data?.result}
                currency={currency}
                total={chartByCategoryData?.data?.total}
                toggleCustomUom={toggleReferenceValue}
                customUom={finalProduct?.uom}
                onClick={(category) =>
                  !filterValues?.itemCategoryId && !category.data.isAdjust
                    ? handleCategoryClick(category)
                    : undefined
                }
              />
            )}
          </Styled.Category>
          <Styled.Info>
            <InfoCard
              name={t('financial.total_cost_label', {
                currency: currencySymbol,
              })}
              value={chartData?.data?.total ?? 0}
              currency={currency}
              icon={<DollarCircleIcon />}
              toggleCustomUom={toggleReferenceValue}
              customUom={finalProduct?.uom}
              objectivePercentageDescription={
                t('financial.objective_cost_percentage') ?? ''
              }
              objectivePercentage={
                (chartData?.data?.total ?? 0) /
                (chartData?.data?.totalTarget ?? 1)
              }
              objectiveValue={chartData?.data?.totalTarget ?? 0}
              objectiveEmptyDescription={
                t('financial.objective_cost_empty') ?? ''
              }
              objectiveOnEdit={() => handleOpenObjectiveDrawer('cost')}
              disableKpi={
                !entitlements?.includes(EntitlementEnum.PRODUCTION_YIELD_DATA)
              }
            />
            <InfoCard
              name={t('financial.total_cost_hectare_label')}
              value={chartData?.data?.totalPerHectare ?? 0}
              currency={currency}
              icon={<FieldIcon />}
              toggleCustomUom={toggleReferenceValue}
              customUom={finalProduct?.uom}
              objectivePercentageDescription={
                t('financial.objective_cost_percentage') ?? ''
              }
              objectivePercentage={
                (chartData?.data?.totalPerHectare ?? 0) /
                (chartData?.data?.totalPerHectareTarget ?? 1)
              }
              objectiveValue={chartData?.data?.totalPerHectareTarget ?? 0}
              objectiveEmptyDescription={
                t('financial.objective_cost_empty') ?? ''
              }
              objectiveOnEdit={() => handleOpenObjectiveDrawer('cost')}
              disableKpi={
                !entitlements?.includes(EntitlementEnum.PRODUCTION_YIELD_DATA)
              }
            />
            {entitlements?.includes(EntitlementEnum.PRODUCTION_YIELD_DATA) && (
              <>
                <InfoCard
                  name={t('financial.avarage_productivity')}
                  value={chartData?.data?.productionAverage ?? 0}
                  currency={currency}
                  icon={<TrendingUpIcon />}
                  toggleCustomUom={true}
                  customUom={`${finalProduct?.uom}/ha`}
                  onHelpClick={handleOnClickHelp}
                  showHelp={
                    !(finalProduct && chartData?.data?.productionAverage)
                  }
                  helpDescription={
                    t('financial.avarage_productivity_help') ?? ''
                  }
                  helpDropdownOptions={[
                    {
                      title: t('financial.objective_productivity'),
                      onClick: () => handleOpenObjectiveDrawer('productivity'),
                    },
                    {
                      title: t('financial.productivity'),
                      onClick: handleOpenInsertProductionDrawer,
                    },
                  ]}
                  objectivePercentage={
                    (chartData?.data?.productionAverage ?? 0) /
                    (chartData?.data?.productionAverageTarget ?? 1)
                  }
                  objectiveValue={chartData?.data?.productionAverageTarget ?? 0}
                  objectivePercentageDescription={
                    t('financial.objective_productivity_percentage') ?? ''
                  }
                  objectiveEmptyDescription={
                    t('financial.objective_productivity_empty') ?? ''
                  }
                  objectiveOnEdit={() =>
                    handleOpenObjectiveDrawer('productivity')
                  }
                />
                <InfoCard
                  name={t('financial.potential_revenue')}
                  value={chartData?.data?.potentialRevenue ?? 0}
                  currency={currency}
                  icon={<SavingsIcon />}
                  onHelpClick={handleOnClickHelp}
                  showHelp={
                    !(finalProduct && chartData?.data?.productionAverage)
                  }
                  helpDescription={t('financial.potential_revenue_help') ?? ''}
                  objectivePercentageDescription={
                    t('financial.objective_productivity_percentage') ?? ''
                  }
                  objectivePercentage={
                    (chartData?.data?.potentialRevenue ?? 0) /
                    (chartData?.data?.potentialRevenueTarget ?? 1)
                  }
                  objectiveValue={chartData?.data?.potentialRevenueTarget ?? 0}
                  objectiveEmptyDescription={
                    t('financial.objective_productivity_empty') ?? ''
                  }
                />
              </>
            )}
            {!isMobile && renderCropCostChart}
          </Styled.Info>
        </Styled.DashboardWrapper>
      )}
    </>
  );
};

export { FinancialContainer as default, FinancialContainer };
