import { useOne } from '@refinedev/core';
import { FormListFieldData, InputNumber, Select, Skeleton } from 'antd';
import { useWatch } from 'antd/es/form/Form';
import useFormInstance from 'antd/es/form/hooks/useFormInstance';
import { DeleteIcon } from 'common/icons';
import { getCurrencySymbol } from 'common/utils';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useOrganization } from 'src/organization';
import { ItemModel } from 'src/resources/item';

import { OpeningStockModel } from '../../opening-stock.model';
import useOpeningStockItems from './opening-stock-item.hook';
import * as Styled from './opening-stock-item.style';

interface OpeningStockItemContainerProps {
  openingStock: FormListFieldData;
  amountItems: number;
  index: number;
  removeItem: () => void;
  currency?: string;
}

const OpeningStockItemContainer = ({
  openingStock,
  removeItem,
  amountItems,
  index,
  currency,
}: OpeningStockItemContainerProps) => {
  const { itemSelectProps } = useOpeningStockItems();
  const formInstance = useFormInstance<OpeningStockModel>();
  const itemCode = useWatch(['items', openingStock.name, 'itemCode']);
  const itemQty = useWatch(['items', openingStock.name, 'qty']);
  const itemValuationRate = useWatch([
    'items',
    openingStock.name,
    'valuationRate',
  ]);

  const { currentOrganizationId } = useOrganization();
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(true);

  const { data, isFetching } = useOne<ItemModel>({
    resource: 'item',
    id: itemCode,
    meta: {
      organizationId: currentOrganizationId,
    },
  });

  const item = data?.data;

  useEffect(() => {
    setIsOpen(amountItems <= index + 1);
  }, [amountItems, index]);

  const handleOnChangeTotalPrice = (value: number | null) => {
    if (value && itemQty) {
      formInstance.setFieldValue(
        ['items', openingStock.name, 'valuationRate'],
        value / itemQty,
      );
    }
  };

  const handleOnChangeQuantity = (value: number | null) => {
    if (value && itemValuationRate) {
      formInstance.setFieldValue(
        ['items', openingStock.name, 'totalPrice'],
        value * itemValuationRate,
      );
    }
  };

  const handleOnChangeUnitPrice = (value: number | null) => {
    if (value && itemQty) {
      formInstance.setFieldValue(
        ['items', openingStock.name, 'totalPrice'],
        value * itemQty,
      );
    }
  };

  if (!item) {
    return (
      <Styled.Box data-testid="opening-stock-item-wrapper">
        <Styled.FormItem
          label={t('opening_stock.items.name_item')}
          name={[openingStock.name, 'itemCode']}
          data-testid="opening-stock-item-code"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select
            {...itemSelectProps}
            placeholder={t('common.business.item_placeholder')}
          />
        </Styled.FormItem>
        {isFetching && <Skeleton active />}
      </Styled.Box>
    );
  }

  return (
    <Styled.Box data-testid="opening-stock-items-wrapper">
      <Styled.ContainerTitle isOpen={isOpen} onClick={() => setIsOpen(!isOpen)}>
        <Styled.Flex>
          <Styled.Chevron isOpen={isOpen} />
          <strong>{item.name}</strong>
        </Styled.Flex>
        <Styled.ContainerCenter>
          <strong>{`${itemQty ?? 0} ${item.uom}`}</strong>
          <Styled.TrashButton onClick={removeItem}>
            <DeleteIcon />
          </Styled.TrashButton>
        </Styled.ContainerCenter>
      </Styled.ContainerTitle>
      <Styled.FormItem
        label={t('opening_stock.items.amount')}
        name={[openingStock.name, 'qty']}
        data-testid="opening-stock-qty"
        rules={[
          {
            required: true,
          },
        ]}
        hidden={!isOpen}
      >
        <InputNumber
          onChange={handleOnChangeQuantity}
          addonAfter={item.uom}
          decimalSeparator=","
        />
      </Styled.FormItem>
      <Styled.ContainerValues>
        <Styled.FormItemValue
          label={t('opening_stock.items.unit_price')}
          name={[openingStock.name, 'valuationRate']}
          data-testid="opening-stock-unit-price"
          rules={[
            {
              required: true,
            },
          ]}
          hidden={!isOpen}
        >
          <InputNumber
            onChange={handleOnChangeUnitPrice}
            addonBefore={getCurrencySymbol(currency)}
            maxLength={12}
            precision={2}
            decimalSeparator={','}
          />
        </Styled.FormItemValue>
        <Styled.FormItemValue
          label={t('opening_stock.items.total_price')}
          name={[openingStock.name, 'totalPrice']}
          data-testid="opening-stock-total-price"
          rules={[
            {
              required: true,
            },
          ]}
          hidden={!isOpen}
        >
          <InputNumber
            onChange={handleOnChangeTotalPrice}
            addonBefore={getCurrencySymbol(currency)}
            maxLength={12}
            precision={2}
            decimalSeparator={','}
          />
        </Styled.FormItemValue>
      </Styled.ContainerValues>
    </Styled.Box>
  );
};

export { OpeningStockItemContainer as default, OpeningStockItemContainer };
