import { Button as ButtonAntd, Typography } from 'antd';
import styled from 'styled-components';
import { BlueColors, NeutralColors } from 'theme';

const ContainerButtons = styled.div`
  display: flex;
  gap: 16px;
  position: fixed;
  right: 48px;
  bottom: 24px;
`;

const Button = styled(ButtonAntd)`
  display: flex;
  align-items: center;
  gap: 8px;
  height: 56px;
  border-radius: 12px 0 12px 12px;

  svg {
    fill: ${(props) =>
      props.type === 'primary' ? NeutralColors[0] : NeutralColors[80]};
  }
`;

const ContainerQuestion = styled.div`
  transition: 1s;
  margin-top: 16px;
  display: flex;
  gap: 8px;

  svg {
    width: 32px;
    height: 32px;
  }
`;

const BoxQuestion = styled.div`
  transition: 1s;
  border-radius: 0 12px 12px 12px;
  padding: 20px;
  background-color: ${BlueColors[10]};
  border: 1px solid ${BlueColors[20]};
`;

const Description = styled(Typography.Paragraph)`
  margin: 0 !important;
  color: ${NeutralColors[60]};
`;

export {
  BoxQuestion,
  Button,
  ContainerButtons,
  ContainerQuestion,
  Description,
};
