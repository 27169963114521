interface ShadowsType {
  Elevation1: string;
  Elevation2: string;
  ElevationLeft1: string;
  ElevationRight1: string;
  ElevationLeft2: string;
  ElevationRight2: string;
  ElevationTop1: string;
  ElevationTop2: string;
}

const Shadows: ShadowsType = {
  Elevation1: '0px 2px 8px 0px rgba(0, 0, 0, 0.15)',
  Elevation2: '0px 4px 12px 0px rgba(0, 0, 0, 0.15)',
  ElevationLeft1: '-2px 0px 8px 0px rgba(0, 0, 0, 0.15)',
  ElevationLeft2: '2px 0px 8px 0px rgba(0, 0, 0, 0.15)',
  ElevationRight1: '-4px 0px 12px 0px rgba(0, 0, 0, 0.15)',
  ElevationRight2: '4px 0px 12px 0px rgba(0, 0, 0, 0.15)',
  ElevationTop1: '0px -4px 8px 0px rgba(0, 0, 0, 0.15)',
  ElevationTop2: '0px -4px 12px 0px rgba(0, 0, 0, 0.25)',
};

export { Shadows };
