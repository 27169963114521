import { Divider as AntdDivider, Form } from 'antd';
import styled from 'styled-components';
import { BlueColors, GreenColors, NeutralColors, RedColors } from 'theme';

interface BoxProps {
  isShow: boolean;
  isOpen?: boolean;
}

const Box = styled.div<BoxProps>`
  display: ${(props) => (props.isShow ? 'flex' : 'none')};
  margin-top: 24px;
  width: 100%;
  flex-direction: column;
  gap: 16px;
  padding: 16px;
  border-radius: 8px;
  border: 1px solid ${NeutralColors[20]};
`;

const ContainerDates = styled.div`
  display: flex;
  gap: 16px;
`;

const FormItem = styled(Form.Item)`
  width: 100%;
  margin: 0;

  .fms-picker {
    width: 100%;
  }
`;

const PaymentBox = styled(Box)`
  display: ${(props) => (props.isShow ? 'block' : 'none')};
  padding: 16px;
  margin-top: 24px;
  border-radius: 12px;
  border: 1px solid ${(props) => (props.isOpen ? BlueColors[20] : 'current')};
  background-color: ${(props) => (props.isOpen ? BlueColors[10] : 'current')};
`;

const PaymentInputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const SwitchBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;

  .fms-switch-checked {
    background-color: ${BlueColors[60]};
    :hover {
      background-color: ${BlueColors[60]} !important;
    }
  }
`;

const SwitchForm = styled(Form.Item)`
  margin: 0;
`;

const CancelBox = styled.div`
  display: flex;
  align-items: center;
  padding: 12px;
  border: 1px solid ${RedColors[30]};
  background-color: ${RedColors[10]};
  border-radius: 4px;
  margin-top: 16px;

  svg {
    width: 40px;
    height: 40px;
    fill: ${RedColors[60]};
    margin-right: 16px;
  }
`;

const Divider = styled(AntdDivider)`
  margin: 8px 0;
`;

const GreenBox = styled(Box)`
  display: ${(props) => (props.isShow ? 'block' : 'none')};
  padding: 16px;
  margin-top: 24px;
  border-radius: 12px;
  border: 1px solid ${GreenColors[60]};
`;

export {
  Box,
  CancelBox,
  ContainerDates,
  Divider,
  FormItem,
  GreenBox,
  PaymentBox,
  PaymentInputWrapper,
  SwitchBox,
  SwitchForm,
};
