enum EnvironmentEnum {
  Production = 'production',
  Staging = 'staging',
  Development = 'development',
}

const isProduction =
  import.meta.env.VITE_APP_ENV === EnvironmentEnum.Production;
const hasSmartlookKey = import.meta.env.VITE_SMARTLOOK_KEY ?? false;

export { EnvironmentEnum, hasSmartlookKey, isProduction };
