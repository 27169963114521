import { PageTitle } from 'common/components';
import { FarmIcon } from 'common/icons';
import {
  ListFiltersModel,
  RefineTableFilters,
  RefineTableFiltersEnum,
  useRefineTableFilters,
} from 'common/refine-table-filters';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useFarm, useOrganization } from 'src/organization';

import * as Styled from './dashboard.style';
import { PurchaseInvoiceBox } from './widgets/boxes/purchase-invoice-box/purchase-invoice-box.component';
import { PurchaseOrderBox } from './widgets/boxes/purchase-order-box/purchase-order-box.component';
import { WarehouseStockBox } from './widgets/boxes/warehouse-stock-box/warehouse-stock-box.component';
import { ConsumptionCalendar } from './widgets/consumption-calendar/consumption-calendar.component';
import { TimeSaving } from './widgets/time-saving/time-saving.component';

const DashboardContainer = () => {
  const { currentOrganizationId } = useOrganization();
  const { t } = useTranslation();
  const { currentFarm } = useFarm();

  const listFilters: ListFiltersModel[] = useMemo(
    () => [
      {
        label: t('purchase.farm'),
        field: 'farms',
        type: RefineTableFiltersEnum.SELECT,
        resource: 'farm',
        show: true,
        icon: <FarmIcon />,
        initialValue: currentFarm?.name,
      },
    ],
    [t],
  );

  const { searchFormProps } = useRefineTableFilters({
    listFilters,
    queryOptions: {
      enabled: false,
    },
  });

  const farm = searchFormProps.form?.getFieldValue('farms');

  if (!currentOrganizationId) {
    return null;
  }

  return (
    <Styled.Container>
      <PageTitle title={t('dashboard.title')} />
      <RefineTableFilters
        searchFormProps={searchFormProps}
        initialListFilters={listFilters}
      />
      <Styled.FlexContainer style={{ paddingBottom: 100 }}>
        <PurchaseOrderBox farm={farm} />
        <PurchaseInvoiceBox farm={farm} />
        <WarehouseStockBox farm={farm} />
        <ConsumptionCalendar farm={farm} />
        <TimeSaving farm={farm} />
      </Styled.FlexContainer>
    </Styled.Container>
  );
};

export { DashboardContainer, DashboardContainer as default };
