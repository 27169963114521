import { MoreOutlined } from '@ant-design/icons';
import { useDrawerForm } from '@refinedev/antd';
import { useUpdate } from '@refinedev/core';
import { Button, Dropdown, MenuProps, Table } from 'antd';
import { ROUTES } from 'common/constants';
import { useNavigateWithOrgAndFarm, useTracking } from 'common/utils';
import { FC, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { WarehouseCreateContainer } from 'src/stock/warehouse-create';

import { GroupedWarehouseModel, WarehouseModel } from '../warehouse-list.model';
import { WarehouseListCardsButtonsTestId } from './warehouse-list-cards.constants';

interface WarehouseListCardsComponentProps {
  warehouses: GroupedWarehouseModel;
  farm: string;
}

const WarehouseListCardsContainer: FC<WarehouseListCardsComponentProps> = ({
  warehouses,
  farm,
}) => {
  const { track } = useTracking();
  const { t } = useTranslation();
  const navigate = useNavigateWithOrgAndFarm();
  const [id, setId] = useState<string>();

  const { mutate } = useUpdate();

  const {
    drawerProps: warehouseEditDrawerProps,
    formProps: warehouseEditFormProps,
    saveButtonProps: warehouseEditSaveButtonProps,
    close: warehouseEditClose,
    show: warehouseEditShow,
  } = useDrawerForm<WarehouseModel>({
    action: 'edit',
    resource: 'warehouse',
    id: id,
    redirect: false,
    syncWithLocation: {
      key: 'edit',
      syncId: true,
    },
  });

  const handleOnClickWarehouseEdit = useCallback(() => {
    track('Start Warehouse Edition');
    warehouseEditShow(id);
  }, [id, track, warehouseEditShow]);

  const handleCloseWarehouseEdit = () => {
    warehouseEditClose();
  };

  const goToWarehouseStock = useCallback(
    (warehouseId: string) => {
      const encodedWarehouse = encodeURIComponent(warehouseId);
      navigate(`${ROUTES.WAREHOUSE_STOCK_ROOT}/${encodedWarehouse}`);
    },
    [navigate],
  );

  const handleOnCellClick = useCallback(
    (record: WarehouseModel) => ({
      onClick: () => {
        goToWarehouseStock(record.id);
      },
    }),
    [goToWarehouseStock],
  );

  const handleDisableWarehouse = useCallback(() => {
    mutate({
      resource: 'warehouse',
      values: {
        disabled: true,
      },
      id: id ?? '',
    });
  }, [id, mutate]);

  const handleItems = useCallback(
    (warehouse: WarehouseModel) => {
      const items: MenuProps['items'] = [
        {
          label: t('stock.card.actions.see_stock'),
          key: '1',
          onClick: () => {
            track('Warehouse Row More Action', {
              'Action Complement': 'See stock',
            });
            goToWarehouseStock(id ?? '');
          },
        },
      ];

      if (!warehouse.disabled) {
        items.push({
          label: t('stock.card.actions.edit_warehouse'),
          key: '2',
          onClick: () => {
            track('Warehouse Row More Action', {
              'Action Complement': 'Edit',
            });
            handleOnClickWarehouseEdit();
          },
        });
        items.push({
          label: t('stock.card.actions.disable_warehouse'),
          key: '0',
          onClick: () => {
            track('Warehouse Row More Action', {
              'Action Complement': 'Disable',
            });
            handleDisableWarehouse();
          },
        });
      }
      return items;
    },
    [
      t,
      track,
      goToWarehouseStock,
      id,
      handleOnClickWarehouseEdit,
      handleDisableWarehouse,
    ],
  );

  return (
    <>
      <Table
        dataSource={warehouses[farm]}
        showHeader={false}
        pagination={false}
        rowKey="id"
      >
        <Table.Column
          dataIndex="name"
          className="warehouse"
          onCell={handleOnCellClick}
        />
        <Table.Column
          render={(record: WarehouseModel) => (
            <Dropdown menu={{ items: handleItems(record) }} trigger={['click']}>
              <Button
                type="text"
                data-testid={WarehouseListCardsButtonsTestId.MORE_BUTTON}
                icon={<MoreOutlined rev={undefined} />}
                onClick={() => setId(record.id)}
              />
            </Dropdown>
          )}
          width={20}
        />
      </Table>
      <WarehouseCreateContainer
        drawerProps={warehouseEditDrawerProps}
        formProps={warehouseEditFormProps}
        onClose={handleCloseWarehouseEdit}
        onWarehouseEdit={warehouseEditSaveButtonProps}
      />
    </>
  );
};

export { WarehouseListCardsContainer as default, WarehouseListCardsContainer };
