import { Button } from 'antd';
import styled from 'styled-components';
import { BlueColors } from 'theme';

const EmptyState = styled.div`
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-rows: fit-content(100%) fit-content(100%);
  align-items: center;
  justify-items: center;
  gap: 16px;

  svg {
    width: inherit;
  }

  .fms-typography {
    color: ${BlueColors[80]};
  }
`;

const NewOrganizationButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  border-radius: 4px;

  svg {
    fill: currentColor;
  }
`;

export { EmptyState, NewOrganizationButton };
