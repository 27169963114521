import { DeleteOutlined } from '@ant-design/icons';
import { UseDrawerFormReturnType, useSelect } from '@refinedev/antd';
import { HttpError, useDelete } from '@refinedev/core';
import { Button, Form, Grid, Input, Popconfirm, Select, Skeleton } from 'antd';
import { DrawerWithButtons } from 'common/components';
import { useTracking, validateCnpjOrCpf } from 'common/utils';
import { insertMaskInCnpjOrCpf } from 'common/utils/cnpj-cpf.util';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useOrganization } from 'src/organization';

import { SupplierRequestModel } from '../supplier.model';

interface SupplierCreateContainerProps {
  supplierDrawerForm: UseDrawerFormReturnType<
    SupplierRequestModel,
    HttpError,
    SupplierRequestModel
  >;
  isReadonly?: boolean;
  breadcrumbList?: string[];
}

const COMPANIES_TYPE = ['Company', 'Individual'];

export const SupplierCreateContainer = ({
  supplierDrawerForm,
  isReadonly,
  breadcrumbList,
}: SupplierCreateContainerProps) => {
  const breakpoint = Grid.useBreakpoint();
  const { track } = useTracking();
  const { currentOrganization } = useOrganization();
  const { t } = useTranslation();
  const { mutate: mutateDelete } = useDelete();

  const {
    drawerProps,
    formProps,
    close: onClose,
    saveButtonProps: onCreate,
    formLoading: isLoading,
  } = supplierDrawerForm;

  const metadata = useMemo(
    () => ({
      organizationId: currentOrganization?.id,
    }),
    [currentOrganization?.id],
  );

  const { selectProps: supplierGroupSelectProps } = useSelect({
    resource: 'supplier-group',
    pagination: {
      pageSize: 100,
    },
    onSearch: (value) => [
      {
        field: 'name',
        operator: 'eq',
        value: value,
      },
    ],
    queryOptions: {
      enabled: !!metadata.organizationId,
    },
    meta: metadata,
  });

  const handleOnFinish = (values: SupplierRequestModel) => {
    if (formProps.onFinish) {
      formProps.onFinish({
        ...values,
        cnpjCpf: values.cnpjCpf?.replace(/\D/g, ''),
      });
    }
  };

  const handleOnClickDelete = () => {
    track('Delete Supplier');
    mutateDelete({
      resource: 'supplier',
      id: formProps?.initialValues?.id,
    });
    onClose();
  };

  return (
    <DrawerWithButtons
      {...drawerProps}
      width={breakpoint.sm ? '400px' : '100%'}
      zIndex={1001}
      title={t(isReadonly ? 'supplier.create.edit' : 'supplier.create.title')}
      buttonSaveProps={{
        ...onCreate,
        enableReturnRouter: true,
      }}
      moreButtons={
        isLoading
          ? []
          : [
              <Popconfirm
                key={'supplier-disable-confirmation'}
                title={t('supplier.table.delete_title')}
                description={t('supplier.table.delete_description')}
                onConfirm={handleOnClickDelete}
                okText={t('buttons.yes')}
                cancelText={t('buttons.no')}
              >
                <Button danger icon={<DeleteOutlined rev={undefined} />}>
                  {t('supplier.table.delete')}
                </Button>
              </Popconfirm>,
            ]
      }
      onClose={onClose}
      tagTrackClose="Supplier Creation"
      breadcrumbList={breadcrumbList}
    >
      {isLoading ? (
        <Skeleton active />
      ) : (
        <Form
          layout="vertical"
          {...formProps}
          initialValues={{
            ...formProps?.initialValues,
            cnpjCpf: insertMaskInCnpjOrCpf(
              formProps?.initialValues?.cnpjCpf ?? '',
            ),
          }}
          onFinish={handleOnFinish}
        >
          <Form.Item
            label={t('supplier.create.name')}
            name={['name']}
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input disabled={isReadonly} />
          </Form.Item>
          <Form.Item
            label={t('supplier.create.group')}
            name={['group']}
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Select {...supplierGroupSelectProps} />
          </Form.Item>
          <Form.Item
            label={t('supplier.create.type')}
            name={['type']}
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Select
              options={COMPANIES_TYPE.map((type) => ({
                value: type,
                label: t(`supplier.create.${type}`),
              }))}
            />
          </Form.Item>
          <Form.Item
            label={t('supplier.create.cnpj_cpf')}
            name={['cnpjCpf']}
            normalize={(value) => insertMaskInCnpjOrCpf(value)}
            rules={[
              {
                validator: (_, value) => {
                  if (!validateCnpjOrCpf(value) && value !== '') {
                    return Promise.reject(t('supplier.create.error_cnpj_cpf'));
                  }
                  return Promise.resolve();
                },
                transform: (value) => {
                  return value.replace(/\D/g, '');
                },
              },
            ]}
          >
            <Input />
          </Form.Item>
        </Form>
      )}
    </DrawerWithButtons>
  );
};
