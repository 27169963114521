import { Skeleton } from 'antd';
import { ROUTES } from 'common/constants';
import { useNavigateWithOrgAndFarm } from 'common/utils';
import { useEffect } from 'react';

const HomeContainer = () => {
  const navigate = useNavigateWithOrgAndFarm();

  useEffect(() => {
    navigate(ROUTES.PURCHASE);
  }, [navigate]);

  return <Skeleton active />;
};

export { HomeContainer as default, HomeContainer };
