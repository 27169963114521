import { useDrawerForm } from '@refinedev/antd';
import { HttpError, IResourceComponentsProps } from '@refinedev/core';
import { Table } from 'antd';
import { AddIcon, CategoryIcon, ItemsIcon } from 'common/icons';
import {
  ListFiltersModel,
  RefineTableFilters,
  RefineTableFiltersEnum,
  useRefineTableFilters,
} from 'common/refine-table-filters';
import { useTracking } from 'common/utils';
import { FC, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ResourcesEnum } from 'src/resources/resources.enum';
import { ResourcesTitle } from 'src/resources/resources-title.component';

import { ItemModel, SimpleItemModel } from './item.model';
import * as Styled from './item.style';
import { ItemDrawer } from './item-drawer/item-drawer.component';
import { useCreateItemDrawer } from './use-item-create-drawer.hook';

interface FilterFormModel {
  name: string;
  category: string;
}

const ItemListContainer: FC<IResourceComponentsProps> = () => {
  const { t } = useTranslation();
  const { track } = useTracking();

  const listFilters: ListFiltersModel[] = useMemo(
    () => [
      {
        label: t('filter.name'),
        field: 'name',
        type: RefineTableFiltersEnum.INPUT,
        show: true,
        icon: <ItemsIcon />,
      },
      {
        label: t('filter.category'),
        field: 'category',
        type: RefineTableFiltersEnum.SELECT,
        resource: 'item-category',
        show: true,
        icon: <CategoryIcon />,
      },
    ],
    [t],
  );

  const { tableProps, searchFormProps } = useRefineTableFilters<
    SimpleItemModel,
    HttpError,
    FilterFormModel
  >({
    listFilters,
    resource: 'item',
  });

  const { createItemDrawer, setResourceCreate, handleOpenItemDrawer } =
    useCreateItemDrawer('create');

  const editUseDrawerForm = useDrawerForm<ItemModel, HttpError, ItemModel>({
    action: 'edit',
    resource: 'item',
    redirect: false,
    syncWithLocation: {
      key: 'edit',
      syncId: true,
    },
  });

  const onRow = useCallback(
    (data: SimpleItemModel, _?: number) => ({
      onClick: () => {
        track('Start Item Edition');
        editUseDrawerForm.show(data.id);
      },
    }),
    [editUseDrawerForm, track],
  );

  return (
    <>
      <ResourcesTitle
        buttons={
          <Styled.CreateButton
            onClick={handleOpenItemDrawer}
            icon={<AddIcon />}
            type="primary"
          >
            {t('buttons.create')}
          </Styled.CreateButton>
        }
        selectedTab={ResourcesEnum.ITEM}
      />
      <RefineTableFilters
        searchFormProps={searchFormProps}
        initialListFilters={listFilters}
      />
      <Table
        {...tableProps}
        rowKey="id"
        onRow={onRow}
        pagination={{
          ...tableProps.pagination,
          showSizeChanger: true,
          pageSizeOptions: [10, 20, 50, 100],
        }}
      >
        <Table.Column dataIndex="name" title={t('item.table.name')} />
        <Table.Column
          dataIndex={['category']}
          title={t('item.table.category')}
        />
      </Table>
      <ItemDrawer
        useDrawerForm={createItemDrawer}
        setResourceCreate={setResourceCreate}
      />
      <ItemDrawer
        useDrawerForm={editUseDrawerForm}
        setResourceCreate={setResourceCreate}
        isEdit
      />
    </>
  );
};

export { ItemListContainer as default, ItemListContainer };
