import styled from 'styled-components';

const RefWrapper = styled.div`
  position: fixed;
  display: none;
  height: fit-content;
  top: 0px;
  left: 0px;
  pointer-events: none;
  z-index: 2000;
`;

export { RefWrapper };
