import { FormListFieldData, Skeleton } from 'antd';
import { SelectWithComplementaryLabel } from 'common/components';
import { DeleteIcon } from 'common/icons';
import { useTranslation } from 'react-i18next';

import { PurchaseCreateItemTestId } from '../../../purchase-test-id.constants';
import usePurchaseCreateItem from './purchase-create-item.hook';
import * as Styled from './purchase-create-item.style';
import { PurchaseCreateItemDetails } from './purchase-create-item-details/purchase-create-item-details.component';

interface PurchaseCreateItemProps {
  itemFieldData: FormListFieldData;
  amountItems: number;
  index: number;
  removeItem: () => void;
  isReadonly?: boolean;
  handleOpenItemDrawer: () => void;
}

export const PurchaseCreateItem = ({
  itemFieldData,
  amountItems,
  index,
  removeItem,
  isReadonly,
  handleOpenItemDrawer,
}: PurchaseCreateItemProps) => {
  const { t } = useTranslation();

  const {
    item,
    itemQty,
    itemWarehouse,
    receivedQty,
    isFetchingItem,
    itemSelectProps,
    setIsOpen,
    isOpen,
    warehouseSelectProps,
  } = usePurchaseCreateItem(itemFieldData, amountItems, index);

  if (!item) {
    return (
      <Styled.Box
        data-testid={PurchaseCreateItemTestId.PURCHASE_CREATE_ITEM_BOX}
      >
        <Styled.TrashButtonAbsolute onClick={removeItem}>
          <DeleteIcon />
        </Styled.TrashButtonAbsolute>
        <Styled.FormItem
          label={t('purchase_create.items.name_item')}
          name={[itemFieldData.name, 'itemCode']}
          data-testid={PurchaseCreateItemTestId.PURCHASE_CREATE_ITEM_CODE}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <SelectWithComplementaryLabel
            {...itemSelectProps}
            placeholder={t('common.business.item_placeholder')}
            buttonProps={{
              onClick: handleOpenItemDrawer,
              children: t('common.create_new'),
            }}
          />
        </Styled.FormItem>
        {isFetchingItem && <Skeleton active />}
      </Styled.Box>
    );
  }

  return (
    <Styled.Box data-testid={PurchaseCreateItemTestId.PURCHASE_CREATE_ITEM_BOX}>
      <Styled.ContainerTitle
        isOpen={isOpen}
        onClick={() => setIsOpen(!isOpen)}
        data-testid={PurchaseCreateItemTestId.PURCHASE_CREATE_ITEM_BOX_OPEN}
      >
        <Styled.Flex>
          <Styled.Chevron isOpen={isOpen} />
          <strong>{item.itemName}</strong>
        </Styled.Flex>

        <Styled.ContainerCenter>
          <strong>{`${itemQty ?? item?.qty} ${item.stockUom}`}</strong>
          {!isReadonly && (
            <Styled.TrashButton onClick={removeItem}>
              <DeleteIcon />
            </Styled.TrashButton>
          )}
        </Styled.ContainerCenter>
      </Styled.ContainerTitle>
      <PurchaseCreateItemDetails
        itemFieldData={itemFieldData}
        item={item}
        receivedQty={receivedQty}
        itemQty={itemQty}
        warehouseSelectProps={warehouseSelectProps}
        itemWarehouse={itemWarehouse}
        isReadonly={!!isReadonly}
        isOpen={isOpen}
      />
    </Styled.Box>
  );
};
