import { Popconfirm, Typography } from 'antd';
import FormList from 'antd/es/form/FormList';
import { CheckIcon, SearchIcon } from 'common/icons';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import ObjectiveFormCost from './item/objective-form-item.component';
import { useObjectiveFormItems } from './objective-form-items.hook';
import * as Styled from './objective-form-items.style';

interface ObjectiveFormItemsProps {
  objectiveType: 'productivity' | 'cost';
  farm?: string;
}

const ObjectiveFormItems: FC<ObjectiveFormItemsProps> = ({
  objectiveType,
  farm,
}) => {
  const { t } = useTranslation();
  const {
    handleFilterSearchObjectives,
    handleOnChangeUpdateAllValue,
    handleUpdateAllObjectiveValues,
    getUpdateAllValuePlaceholder,
  } = useObjectiveFormItems({
    objectiveType,
    farm,
  });

  return (
    <>
      <Styled.SearchInput
        size="large"
        placeholder={t('financial.objective.form.search') ?? ''}
        suffix={<SearchIcon />}
        onChange={(e) => handleFilterSearchObjectives(e.currentTarget.value)}
      />
      <Styled.UpdateAllWrapper>
        <Typography.Text>
          {t('financial.objective.form.update_all')}
        </Typography.Text>
        <Styled.UpdateAllInputWrapper>
          <Styled.UpdateAllInputNumber
            size="large"
            onChange={handleOnChangeUpdateAllValue}
            placeholder={getUpdateAllValuePlaceholder()}
          />
          <Popconfirm
            title={t('financial.objective.form.update_all_confirm')}
            style={{ width: 100 }}
            onConfirm={handleUpdateAllObjectiveValues}
          >
            <Styled.UpdateAllButton
              size="large"
              icon={<CheckIcon />}
              shape="circle"
            />
          </Popconfirm>
        </Styled.UpdateAllInputWrapper>
      </Styled.UpdateAllWrapper>
      <Styled.ItemsListWrapper>
        <FormList name="items">
          {(items) => (
            <>
              {items.map((item, index) => (
                <ObjectiveFormCost
                  key={item.name}
                  item={item}
                  index={index}
                  objectiveType={objectiveType}
                  farm={farm}
                />
              ))}
            </>
          )}
        </FormList>
      </Styled.ItemsListWrapper>
    </>
  );
};

export { ObjectiveFormItems as default, ObjectiveFormItems };
