import { CardProps } from 'antd';
import { ReactNode } from 'react';

import * as Styled from './card-button.style';

interface CardButtonProps extends CardProps {
  icon: ReactNode;
  status?: ReactNode;
  button?: ReactNode;
  title?: string;
  description: string;
}

export const CardButton = ({
  icon,
  status,
  button,
  description,
  title,
  ...props
}: CardButtonProps) => {
  return (
    <Styled.Card size="small" {...props} title={undefined}>
      <Styled.ContainerButton>
        <Styled.CardIcon>{icon}</Styled.CardIcon>
        {status}
      </Styled.ContainerButton>
      <Styled.Title>
        <strong>{title}</strong>
      </Styled.Title>
      <Styled.Description>{description}</Styled.Description>
      {button}
    </Styled.Card>
  );
};
