import { Alert, Button, Flex, Form, Typography } from 'antd';
import { ErrorCircleOutlined } from 'common/icons';
import styled from 'styled-components';
import { NeutralColors } from 'theme';

const AlertError = styled(Alert)``;

const AlertContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const DescriptionText = styled(Typography.Text)`
  font-size: 14;
  color: ${NeutralColors[60]};
`;

const BoxReceived = styled.div`
  display: flex;
  flex-direction: column;
  align-items: inherit;
  justify-content: center;
  margin-block: 4px;
  border-radius: 5px;
  padding-inline: 0px;
  div {
    margin-block: 4px;
    span {
      font-weight: bold;
    }
  }
`;

const FormItem = styled(Form.Item)`
  margin-bottom: 16px;
  width: 100%;
`;

const FormItemValue = styled(FormItem)`
  max-width: 48%;
`;

const ContainerValues = styled(Flex)`
  justify-content: space-between;
  align-items: flex-start;
`;

const InfoCircleIcon = styled(ErrorCircleOutlined)`
  font-size: 18px;
`;

const UpdateButton = styled(Button)`
  margin-left: auto;
  width: fit-content;
`;

export {
  AlertContainer,
  AlertError,
  BoxReceived,
  ContainerValues,
  DescriptionText,
  FormItem,
  FormItemValue,
  InfoCircleIcon,
  UpdateButton,
};
