import { useList } from '@refinedev/core';
import { SelectProps } from 'antd';
import { useMemo } from 'react';
import { useOrganization } from 'src/organization';
import { IsGroupWarehouseEnum, WarehouseModel } from 'src/stock/warehouse-list';

interface CostCenterModel {
  id: string;
  farm: string;
}

interface ExpenseAccountModel {
  id: string;
  farm: string;
}

const useItemCategoryFormFarms = () => {
  const { currentOrganizationId } = useOrganization();

  const metadata = useMemo(
    () => ({
      organizationId: currentOrganizationId,
    }),
    [currentOrganizationId],
  );

  const {
    data: warehouseList,
    isLoading: warehouseIsLoading,
    isFetching: warehouseIsFetching,
  } = useList<WarehouseModel>({
    resource: 'warehouse',
    meta: metadata,
    filters: [
      {
        field: 'isGroup',
        operator: 'eq',
        value: IsGroupWarehouseEnum.NO,
      },
    ],
    queryOptions: {
      enabled: !!currentOrganizationId,
    },
    pagination: {
      pageSize: 500,
    },
  });

  const {
    data: expenseAccountList,
    isLoading: expenseAccountIsLoading,
    isFetching: expenseAccountIsFetching,
  } = useList<CostCenterModel>({
    resource: 'account',
    meta: metadata,
    filters: [
      {
        field: 'rootType',
        operator: 'eq',
        value: 'expense',
      },
    ],
    queryOptions: {
      enabled: !!currentOrganizationId,
    },
    pagination: {
      pageSize: 500,
    },
  });

  const {
    data: costCenterList,
    isLoading: costCenterIsLoading,
    isFetching: costCenterIsFetching,
  } = useList<ExpenseAccountModel>({
    resource: 'cost-center',
    meta: metadata,
    queryOptions: {
      enabled: !!currentOrganizationId,
    },
    pagination: {
      pageSize: 500,
    },
  });

  const warehouseSelectGroupByFarm: Record<string, SelectProps['options']> =
    useMemo(() => {
      if (!warehouseList?.data) {
        return {};
      }
      return warehouseList.data.reduce(
        (
          acc: Record<string, SelectProps['options']>,
          warehouse: WarehouseModel,
        ) => {
          if (!acc[warehouse.farm]) {
            acc[warehouse.farm] = [];
          }
          acc[warehouse.farm]?.push({
            label: warehouse.name,
            value: warehouse.id,
          });
          return acc;
        },
        {} as Record<string, SelectProps['options']>,
      );
    }, [warehouseList]);

  const expenseAccountSelectGroupByFarm: Record<
    string,
    SelectProps['options']
  > = useMemo(() => {
    if (!expenseAccountList?.data) {
      return {};
    }
    return expenseAccountList.data.reduce(
      (
        acc: Record<string, SelectProps['options']>,
        expenseAccount: ExpenseAccountModel,
      ) => {
        if (!acc[expenseAccount.farm]) {
          acc[expenseAccount.farm] = [];
        }
        acc[expenseAccount.farm]?.push({
          label: expenseAccount.id,
          value: expenseAccount.id,
        });
        return acc;
      },
      {} as Record<string, SelectProps['options']>,
    );
  }, [expenseAccountList]);

  const costCenterSelectGroupByFarm: Record<string, SelectProps['options']> =
    useMemo(() => {
      if (!costCenterList?.data) {
        return {};
      }
      return costCenterList.data.reduce(
        (
          acc: Record<string, SelectProps['options']>,
          costCenter: CostCenterModel,
        ) => {
          if (!acc[costCenter.farm]) {
            acc[costCenter.farm] = [];
          }
          acc[costCenter.farm]?.push({
            label: costCenter.id,
            value: costCenter.id,
          });
          return acc;
        },
        {} as Record<string, SelectProps['options']>,
      );
    }, [costCenterList]);

  return {
    warehouseSelectGroupByFarm,
    warehouseListLoading: warehouseIsLoading || warehouseIsFetching,
    expenseAccountSelectGroupByFarm,
    expenseAccountListLoading:
      expenseAccountIsLoading || expenseAccountIsFetching,
    costCenterSelectGroupByFarm,
    costCenterListLoading: costCenterIsLoading || costCenterIsFetching,
  };
};

export { useItemCategoryFormFarms as default, useItemCategoryFormFarms };
