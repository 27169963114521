import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { useUpdate } from '@refinedev/core';
import { Popconfirm, Switch } from 'antd';
import { useTracking } from 'common/utils';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

interface WarehouseDisableColumnProps {
  setUpdateTable: (b: boolean) => void;
  warehouseId: string;
  warehouseDisabled: number;
}

const WarehouseDisableColumn = ({
  warehouseId,
  warehouseDisabled,
  setUpdateTable,
}: WarehouseDisableColumnProps) => {
  const { t } = useTranslation();
  const { mutate, isSuccess } = useUpdate();
  const { track } = useTracking();

  const handleDisableWarehouse = (id: string) => {
    track('Disabled warehouse in review stock');
    mutate({
      resource: 'warehouse',
      values: {
        disabled: true,
      },
      id,
    });
  };

  useEffect(() => {
    if (isSuccess) {
      setUpdateTable(true);
    }
  }, [isSuccess]);

  return (
    <Popconfirm
      title={t('migration.table.disable_warehouse_title')}
      description={t('migration.table.disable_warehouse_description')}
      onConfirm={() => handleDisableWarehouse(warehouseId)}
      disabled={!!warehouseDisabled}
      okText={t('buttons.disable')}
      cancelText={t('buttons.cancel')}
      okType="danger"
    >
      <Switch
        data-testid="switch-button"
        checked={!warehouseDisabled}
        checkedChildren={<CheckOutlined />}
        unCheckedChildren={<CloseOutlined />}
      />
    </Popconfirm>
  );
};

export { WarehouseDisableColumn };
