import { Button, Typography } from 'antd';
import styled from 'styled-components';
import { NeutralColors } from 'theme';

const ContainerEmpty = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  svg {
    width: 124px;
    margin: -24px;
  }
`;

const CreateDescription = styled(Typography.Paragraph)`
  margin: 0 !important;
  color: ${NeutralColors[100]};
`;

const CreateExample = styled(Typography.Paragraph)`
  color: ${NeutralColors[60]};
`;

const CreateButton = styled(Button)`
  display: flex;
  align-items: center;

  svg {
    width: 80px;
  }
`;

export { ContainerEmpty, CreateButton, CreateDescription, CreateExample };
