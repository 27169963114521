import { FormListFieldData } from 'antd';
import { useWatch } from 'antd/es/form/Form';
import { CurrencyValue } from 'common/components';
import { CheckIcon, EditIcon, HelpIcon } from 'common/icons';
import { useState } from 'react';
import { verifyWarningUom } from 'src/electronic-invoice/electronic-invoice.util';
import { ItemLinkTestId } from 'src/electronic-invoice/electronic-invoice-test-id.constants';
import { PurchaseDetailModel } from 'src/orders-and-payments/purchase';

import * as Styled from './item-link.style';
import { ItemLinkSelect } from './item-link-select/item-link-select.component';

interface ItemLinkProps {
  itemFieldData: FormListFieldData;
  fatherItemFieldData: FormListFieldData;
  isEditing: boolean;
  setIsEditing: (b: boolean) => void;
  openCreateItem: () => void;
  purchaseOrder?: PurchaseDetailModel;
}

const ItemLink = ({
  itemFieldData,
  fatherItemFieldData,
  isEditing,
  purchaseOrder,
  openCreateItem,
  setIsEditing,
}: ItemLinkProps) => {
  const [startLink, setStartLink] = useState(false);
  const item = useWatch([
    'electronicInvoices',
    fatherItemFieldData.name,
    'items',
    itemFieldData.name,
  ]);

  const handleStartLink = () => {
    if (!isEditing) {
      setIsEditing(true);
      setStartLink(!startLink);
    }
  };

  if (!item) {
    return null;
  }

  if (startLink) {
    return (
      <ItemLinkSelect
        item={item}
        fatherItemFieldData={fatherItemFieldData}
        itemFieldData={itemFieldData}
        setIsEditing={setIsEditing}
        setStartLink={setStartLink}
        openCreateItem={openCreateItem}
        purchaseOrder={purchaseOrder}
      />
    );
  }

  return (
    <Styled.Line isEditing={isEditing}>
      <Styled.Item
        isSuccess={!!item.suggestedItemCode}
        width="40%"
        onClick={handleStartLink}
      >
        {item.suggestedItemCode ? <CheckIcon /> : <HelpIcon />}
        {item.suggestedItemName ?? item.itemName}
      </Styled.Item>
      <Styled.Item
        width="20%"
        isSuccess={
          item.uomVerified ?? verifyWarningUom(item.uom, item.suggestedItemUom)
        }
      >
        {item.uomVerified ??
        verifyWarningUom(item.uom, item.suggestedItemUom) ? (
          <CheckIcon />
        ) : (
          <HelpIcon />
        )}
        {`${item.qtyInvoice} ${item.suggestedItemUom ?? item.uom}`}
      </Styled.Item>
      <Styled.DetailsContainer>
        <CurrencyValue value={item.totalValue / item.qtyInvoice} />
        {` / ${item.suggestedItemUom ?? item.uom}`}
      </Styled.DetailsContainer>
      <Styled.DetailsContainer>
        <CurrencyValue value={item.totalValue} />
      </Styled.DetailsContainer>
      <Styled.ButtonLink
        onClick={handleStartLink}
        data-testid={ItemLinkTestId.ITEM_LINK_BUTTON}
      >
        <EditIcon />
      </Styled.ButtonLink>
    </Styled.Line>
  );
};

export { ItemLink };
