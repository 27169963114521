import styled from 'styled-components';
import { NeutralColors } from 'theme';

interface ContainerSpaceBetweenProps {
  isBorderBottom?: boolean;
}

interface TextProps {
  isBold?: boolean;
}

const Container = styled.div`
  padding: 0 16px;
  border: 1px solid ${NeutralColors[20]};
  border-radius: 8px;
  margin: 16px 0;
`;

const ContainerSpaceBetween = styled.div<ContainerSpaceBetweenProps>`
  display: flex;
  justify-content: space-between;
  padding: 16px 0;
  ${(props) =>
    props.isBorderBottom
      ? `border-bottom: 1px solid ${NeutralColors[20]};`
      : ''}
`;

const Text = styled.p<TextProps>`
  font-size: 16px;
  margin: 0;
  color: ${NeutralColors[100]};
  font-weight: ${(props) => (props.isBold ? 'bold' : '400')};

  span {
    color: ${NeutralColors[60]};
    margin: 0 4px;
  }
`;

export { Container, ContainerSpaceBetween, Text };
