import { BorderRadius } from 'src/theme/border-radius';
import styled from 'styled-components';
import { NeutralColors } from 'theme';

interface PercentageProps {
  color: string;
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  cursor: pointer;
`;

const Percentage = styled.div<PercentageProps>`
  background-color: ${(props) => props.color};
  border-radius: ${BorderRadius.Small}px;
  padding: 8px;
  color: ${NeutralColors[0]};
  min-width: 52px;
`;

const Value = styled.span`
  font-weight: 600;
  font-size: 16px;
`;

const Label = styled.span`
  font-size: 10px;
`;

export { Label, Percentage, Value, Wrapper };
