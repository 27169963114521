export const PurchaseStatusTestId = {
  PURCHASE_STATUS_CONTAINER: 'purchase-status-container',
};

export const PurchaseCreateItemTestId = {
  PURCHASE_CREATE_ITEM_BOX: 'purchase-create-item-box',
  PURCHASE_CREATE_ITEM_BOX_OPEN: 'purchase-create-item-box-open',
  PURCHASE_CREATE_ITEM_CODE: 'purchase-create-item-code',
  PURCHASE_CREATE_ITEM_QTY: 'purchase-create-item-qty',
  PURCHASE_CREATE_ITEM_RATE: 'purchase-create-item-rate',
  PURCHASE_CREATE_ITEM_WAREHOUSE: 'purchase-create-item-warehouse',
  PURCHASE_CREATE_ISPAID_SWITCHBOX: 'purchase-create-is-paid-switchbox',
  PURCHASE_CREATE_ITEMS_BUTTON: 'purchase-create-items-button',
  PURCHASE_CREATE_ITEMS_RECEIVED: 'purchase-create-items-received',
};

export const PurchaseCreateTestId = {
  PURCHASE_CREATE_BUTTONS: 'purchase-create-buttons',
  PURCHASE_CREATE_FORM: 'purchase-create-form',
  PURCHASE_CANCEL_BUTTON: 'purchase-cancel-btn',
  PURCHASE_CANCEL_ALERT_INFO: 'purchase-cancel-info',
};

export const PurchaseElectronicInvoicesTestId = {
  ELECTRONIC_INVOICES_BUTTON: 'electronic-invoices-button',
};
