import { UseDrawerFormReturnType } from '@refinedev/antd';
import { GetOneResponse, HttpError } from '@refinedev/core';
import { QueryObserverResult } from '@tanstack/react-query';
import { Form, Grid, Skeleton } from 'antd';
import { DrawerWithButtons } from 'common/components/drawer-with-buttons/drawer-with-buttons.component';
import { useTranslation } from 'react-i18next';
import { BankAccountCreateContainer } from 'src/resources/bank-account/bank-account-form/bank-account-form.container';

import { InvoiceDetailModel } from '../invoice.model';
import InvoicePaymentFormDetail from './detail/invoice-payment-form-detail.component';
import { InvoicePaymentFormInfo } from './info/invoice-payment-form-info.container';
import { InvoicePaymentFormTestId } from './invoice-payment-form.constants';
import { useInvoicePaymentForm } from './invoice-payment-form.hook';
import { InvoicePaymentFormModel } from './invoice-payment-form.model';

interface InvoicePaymentFormProps {
  drawerForm: UseDrawerFormReturnType<
    InvoiceDetailModel,
    HttpError,
    InvoicePaymentFormModel,
    InvoiceDetailModel,
    InvoiceDetailModel,
    HttpError
  >;
  refetchTable: () => void;
  paymentQueryResult?: QueryObserverResult<GetOneResponse<InvoiceDetailModel>>;
}

const InvoicePaymentForm = ({
  drawerForm,
  refetchTable,
  paymentQueryResult,
}: InvoicePaymentFormProps) => {
  const {
    close: onClose,
    form: formInstance,
    drawerProps,
    formProps,
    saveButtonProps: onCreate,
  } = drawerForm ?? {};
  const {
    paidTotal,
    outstandingTotal,
    initialValues,
    isDisabledSave,
    isLoading,
    handleOnFinish,
    createBankAccountDrawer,
    handleOpenBankAccountDrawer,
  } = useInvoicePaymentForm(formProps, formInstance, onClose, refetchTable);

  const isStartLoading = !!paymentQueryResult?.isFetching;

  const breakpoint = Grid.useBreakpoint();
  const { t } = useTranslation();

  const renderDrawerChildren = () => {
    if (isStartLoading) {
      return (
        <Skeleton active data-testid={InvoicePaymentFormTestId.SKELETON} />
      );
    }

    return (
      <>
        <InvoicePaymentFormInfo
          handleOpenBankAccountDrawer={handleOpenBankAccountDrawer}
        />
        <InvoicePaymentFormDetail
          paidTotal={paidTotal}
          outstandingTotal={outstandingTotal}
        />
      </>
    );
  };

  return (
    <DrawerWithButtons
      {...drawerProps}
      width={breakpoint.sm ? '400px' : '100%'}
      zIndex={1001}
      title={t('invoice.payment.form.title')}
      disabledSave={isDisabledSave}
      isLoading={isLoading}
      tagTrackClose="Invoice Payment"
      buttonSaveProps={onCreate}
      data-testid={InvoicePaymentFormTestId.Container}
    >
      <BankAccountCreateContainer
        bankAccountDrawerForm={createBankAccountDrawer}
        breadcrumbList={[t('purchase_create.breadcrumb')]}
      />
      <Form
        layout="vertical"
        {...formProps}
        onFinish={handleOnFinish}
        initialValues={initialValues}
      >
        {renderDrawerChildren()}
      </Form>
    </DrawerWithButtons>
  );
};

export { InvoicePaymentForm as default, InvoicePaymentForm };
