import { useSelect } from '@refinedev/antd';
import { useMemo } from 'react';
import { useOrganization } from 'src/organization';
import { IsGroupWarehouseEnum } from 'src/stock/warehouse-list';

const useOpeningStockDetails = (farmId?: string) => {
  const { currentOrganizationId, isLoading: isOrganizationLoading } =
    useOrganization();

  const metadata = useMemo(
    () => ({
      organizationId: currentOrganizationId,
    }),
    [currentOrganizationId],
  );

  const loadWarehousesList = useMemo(
    () => Boolean(!!currentOrganizationId && !isOrganizationLoading && farmId),
    [currentOrganizationId, farmId, isOrganizationLoading],
  );

  const { selectProps: warehouseSelectProps } = useSelect({
    resource: 'warehouse',
    filters: [
      {
        field: 'farm',
        operator: 'eq',
        value: farmId,
      },
      {
        field: 'isGroup',
        operator: 'eq',
        value: IsGroupWarehouseEnum.NO,
      },
    ],
    onSearch: (value) => [
      {
        field: 'name',
        operator: 'eq',
        value: value,
      },
    ],
    meta: metadata,
    queryOptions: {
      enabled: loadWarehousesList,
    },
  });

  return {
    warehouseSelectProps,
  };
};

export { useOpeningStockDetails as default, useOpeningStockDetails };
