import { Tour, TourProps } from 'antd';
import { FC, MutableRefObject } from 'react';
import { useTranslation } from 'react-i18next';
import { FinancialEnum } from 'src/financial/financial.enum';

interface HelpTourComponentProps {
  open: boolean;
  onClose: () => void;
  refList: MutableRefObject<null>[];
}

const HelpTourComponent: FC<HelpTourComponentProps> = ({
  open,
  onClose,
  refList,
}) => {
  const { t } = useTranslation();

  const steps: TourProps['steps'] = [
    {
      cover: <img src="/assets/field-chart.png" alt="Field chart" />,
      target: refList[0].current,
      title: t('financial.overview.help-tour.step-1.title'),
      description: t('financial.overview.help-tour.step-1.description'),
    },
    {
      cover: <img src="/assets/reference-value.png" alt="Reference value" />,
      target: refList[1].current,
      title: t('financial.overview.help-tour.step-2.title'),
      description: t('financial.overview.help-tour.step-2.description'),
    },
    {
      cover: <img src="/assets/productivity.png" alt="Productivity" />,
      target: document.getElementById(FinancialEnum.PRODUCTION),
      title: t('financial.overview.help-tour.step-3.title'),
      description: t('financial.overview.help-tour.step-3.description'),
    },
  ];

  return <Tour steps={steps} open={open} onClose={onClose} />;
};

export { HelpTourComponent as default, HelpTourComponent };
