import { Card as CardAntD } from 'antd';
import styled from 'styled-components';

const Card = styled(CardAntD)`
  .fms-card-body {
    padding-bottom: 0;
  }
`;

export { Card };
