import { GetOneResponse } from '@refinedev/core';
import { QueryObserverResult } from '@tanstack/react-query';
import { DrawerProps, Form, FormProps, Grid, Skeleton, Typography } from 'antd';
import { ButtonProps } from 'antd/lib';
import { FormInstance } from 'antd/lib/form/Form';
import { DrawerWithButtons } from 'common/components/drawer-with-buttons/drawer-with-buttons.component';
import { useTranslation } from 'react-i18next';

import { PurchaseDetailModel } from '../purchase.model';
import { PurchaseReceiveAlertTestId } from './purchase-receive.constants';
import { usePurchaseReceive } from './purchase-receive.hook';
import * as Styled from './purchase-receive.style';
import { PurchaseReceiveDate } from './purchase-receive-date/purchase-receive-date.container';
import { PurchaseReceiveItem } from './purchase-receive-item/purchase-receive-item.container';

interface PurchaseReceiveProps {
  drawerProps: DrawerProps;
  formInstance: FormInstance;
  formProps: FormProps;
  onClose: () => void;
  receiveQueryResult?: QueryObserverResult<GetOneResponse<PurchaseDetailModel>>;
  formLoading?: boolean;
  onCreate: ButtonProps;
}

const PurchaseReceive = ({
  onClose,
  formInstance,
  drawerProps,
  formProps,
  receiveQueryResult,
  formLoading,
  onCreate,
}: PurchaseReceiveProps) => {
  const {
    initialValues,
    isDisabledSave,
    isLoading,
    handleOnFinish,
    showUnitValueAlert,
    handleUndoUnitValueEdit,
    hasEditedUnitValue,
  } = usePurchaseReceive(formProps, formInstance, onClose);

  const isStartLoading = !!receiveQueryResult?.isFetching;

  const breakpoint = Grid.useBreakpoint();
  const { t } = useTranslation();

  const renderDrawerChildren = () => {
    if (isStartLoading) {
      return <Skeleton active />;
    }

    return (
      <>
        <PurchaseReceiveDate />
        {showUnitValueAlert && (
          <Styled.DeleteInfoAlert
            message={t('purchase_receive.unit_value_error_title')}
            description={
              <Styled.AlertWrapper>
                <Typography.Text>
                  {t('purchase_receive.unit_value_error_description')}
                </Typography.Text>
                <br />
                <Styled.UndoButton
                  size="small"
                  type="default"
                  onClick={handleUndoUnitValueEdit}
                >
                  {t('buttons.undo')}
                </Styled.UndoButton>
              </Styled.AlertWrapper>
            }
            showIcon
            icon={<Styled.InfoCircleIcon />}
            type="error"
            data-testid={
              PurchaseReceiveAlertTestId.PURCHASE_RECEIVE_UNIT_VALUE_ALERT
            }
          />
        )}
        <Form.List
          name="items"
          rules={[
            {
              validator: (_, names) => {
                if (!names || names.length < 1) {
                  return Promise.reject(new Error());
                }
                return Promise.resolve();
              },
            },
          ]}
        >
          {(items) => (
            <>
              {items.map((item) => (
                <PurchaseReceiveItem
                  key={item.key}
                  itemFieldData={item}
                  showUnitValueAlert={showUnitValueAlert}
                />
              ))}
            </>
          )}
        </Form.List>
      </>
    );
  };

  return (
    <DrawerWithButtons
      {...drawerProps}
      width={breakpoint.sm ? '400px' : '100%'}
      zIndex={1001}
      title={t('purchase_receive.title')}
      disabledSave={isDisabledSave}
      isLoading={isLoading || formLoading}
      tagTrackClose="Purchase Receive"
      popConfirmSave={
        hasEditedUnitValue && {
          title: t('purchase_receive.unit_value_alert_title'),
          description: t('purchase_receive.unit_value_alert_description'),
          onCancel: handleUndoUnitValueEdit,
          cancelText: t('buttons.undo'),
        }
      }
      buttonSaveProps={onCreate}
      onClose={() => {
        onClose();
      }}
    >
      <Form
        layout="vertical"
        {...formProps}
        onFinish={handleOnFinish}
        initialValues={initialValues}
      >
        {renderDrawerChildren()}
      </Form>
    </DrawerWithButtons>
  );
};

export default PurchaseReceive;
