import { AlertProps, ButtonProps } from 'antd';
import { ReactNode } from 'react';

import * as Styled from './alert-with-button.style';

interface AlertWithButtonProps extends AlertProps {
  buttonProps: ButtonProps;
  rightIconButton?: ReactNode;
}

const AlertWithButton = ({
  buttonProps,
  rightIconButton,
  ...props
}: AlertWithButtonProps) => {
  return (
    <Styled.Alert
      {...props}
      description={
        <div>
          <div>{props.description}</div>
          <Styled.ContainerButton>
            <Styled.Button {...buttonProps}>
              {buttonProps.children}
              {rightIconButton}
            </Styled.Button>
          </Styled.ContainerButton>
        </div>
      }
    />
  );
};

export { AlertWithButton };
