import { Button } from 'antd';
import styled from 'styled-components';

const StockSummaryWrapper = styled.div`
  padding-bottom: 20px;
`;

const CrossingArrowsButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export { CrossingArrowsButton, StockSummaryWrapper };
