import { track } from '@amplitude/analytics-browser';
import { useDrawerForm } from '@refinedev/antd';
import { useList, useOne } from '@refinedev/core';
import { useEffect } from 'react';

import { FinalProduct } from './reference-value.model';
import { useReferenceValueStore } from './reference-value.store';

interface ReferenceValueProps {
  seasonId?: string;
  farm?: string;
}

const useReferenceValue = ({ seasonId, farm }: ReferenceValueProps) => {
  const { setFinalProduct } = useReferenceValueStore();
  const { data: seasonData } = useOne({
    resource: 'season',
    id: seasonId,
    meta: {
      params: {
        farm,
      },
    },
  });
  const {
    data: finalProductData,
    refetch: finalProductRefetch,
    isFetched: isFinalProductFetched,
  } = useList<FinalProduct>({
    resource: 'item/product-final/search',
    filters: [
      {
        field: 'cropId',
        operator: 'eq',
        value: seasonData?.data?.crop,
      },
    ],
    queryOptions: {
      enabled: !!seasonData?.data?.crop,
    },
  });

  const referenceValueDrawerProps = useDrawerForm({
    resource: 'price/product-final',
    action: 'create',
    redirect: false,
    syncWithLocation: {
      key: 'price',
    },
    warnWhenUnsavedChanges: false,
  });

  const handleOnReferenceValueClick = () => {
    referenceValueDrawerProps.show();
    track('Reference Value - Update Price Start');
  };

  useEffect(() => {
    if (isFinalProductFetched) {
      setFinalProduct(finalProductData?.data?.[0]);
    }
  }, [finalProductData, isFinalProductFetched, setFinalProduct]);

  return {
    handleOnReferenceValueClick,
    finalProductRefetch,
    referenceValueDrawerProps,
  };
};

export { useReferenceValue };
