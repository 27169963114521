import { Typography } from 'antd';
import { AddIcon } from 'common/icons';
import { NoItemsFoundImage } from 'common/images';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { ItemDrawer } from 'src/resources/item';

import { useNoFinalProduct } from './no-final-product.hook';
import * as Styled from './no-final-product.style';

interface NoFinalProductProps {
  refetchFinalProduct?: () => void;
}

const NoFinalProduct: FC<NoFinalProductProps> = ({ refetchFinalProduct }) => {
  const { t } = useTranslation();
  const { createItemDrawer, setResourceCreateItem, handleOpenItemDrawer } =
    useNoFinalProduct(refetchFinalProduct);

  return (
    <Styled.ContainerEmpty>
      <ItemDrawer
        useDrawerForm={createItemDrawer}
        setResourceCreate={setResourceCreateItem}
      />
      <NoItemsFoundImage />
      <Typography.Title level={5}>
        {t('financial.no_final_product.create_item_title')}
      </Typography.Title>
      <Styled.CreateDescription>
        {t('financial.no_final_product.create_item_description')}
      </Styled.CreateDescription>
      <Styled.CreateExample>
        {t('financial.no_final_product.create_item_example')}
      </Styled.CreateExample>
      <Styled.CreateButton onClick={handleOpenItemDrawer} type="primary">
        <AddIcon />
        {t('financial.no_final_product.create_button')}
      </Styled.CreateButton>
    </Styled.ContainerEmpty>
  );
};

export { NoFinalProduct as default, NoFinalProduct };
