import { ElectronicInvoiceItemRequest } from './electronic-invoice.model';

const generateExternalIdMask = (externalId?: string) => {
  if (!externalId) {
    return '';
  }

  return `#${externalId.substring(0, 5)}...${externalId.substring(
    externalId.length - 4,
  )}`;
};

const verifyWarningUom = (uom: string, suggestedItemUom: string) => {
  return uom?.toUpperCase() === suggestedItemUom?.toUpperCase();
};

const replaceEqualItemCode = (
  item: ElectronicInvoiceItemRequest,
  newItem: ElectronicInvoiceItemRequest,
): ElectronicInvoiceItemRequest => {
  if (item.itemCode !== newItem.itemCode) {
    return {
      ...item,
    };
  }

  return {
    ...item,
    suggestedItemCode: newItem.suggestedItemCode,
    suggestedItemName: newItem.suggestedItemName,
    suggestedItemUom: newItem.suggestedItemUom,
    valuationRateInvoice: newItem.totalValue / newItem.qtyInvoice,
  };
};

export { generateExternalIdMask, replaceEqualItemCode, verifyWarningUom };
