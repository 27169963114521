import { DatePicker, Form, Select, Switch } from 'antd';
import { AutoSingleSelect } from 'common/components';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useStockTransferStore } from '../stock-transfer.store';
import useStockTransferDetails from './stock-transfer-details.hook';
import * as Styled from './stock-transfer-details.style';

const StockTransferDetails: FC = () => {
  const { t } = useTranslation();
  const {
    sourceWarehouseSelectProps,
    isLoadingSourceWarehouse,
    targetWarehouseSelectProps,
    isLoadingTargetWarehouse,
    sourceFarm,
    targetFarm,
    transferBetweenFarms,
    farmSelectProps,
    isLoadingFarm,
  } = useStockTransferDetails();

  const { showItems } = useStockTransferStore();

  const [sourceWarehouse, setSourceWarehouse] = useState<string | undefined>();
  const [targetWarehouse, setTargetWarehouse] = useState<string | undefined>();

  const filteredSourceFarm = farmSelectProps.options?.filter(
    (option) => option.value !== targetFarm,
  );

  const filteredTargetFarm = farmSelectProps.options?.filter(
    (option) => option.value !== sourceFarm,
  );

  const filteredSourceWarehouse = sourceWarehouseSelectProps.options?.filter(
    (option) => option.value !== targetWarehouse,
  );

  const filteredTargetWarehouse = targetWarehouseSelectProps.options?.filter(
    (option) => option.value !== sourceWarehouse,
  );

  return (
    <Styled.Container show={!showItems}>
      <Styled.GreenBox>
        <Styled.SwitchWrapper>
          {t('stock_transfer.details.transfer_between_farms')}
          <Form.Item name={['transferBetweenFarms']} valuePropName={'checked'}>
            <Switch
              checkedChildren={t('buttons.yes')}
              unCheckedChildren={t('buttons.no')}
            />
          </Form.Item>
        </Styled.SwitchWrapper>
      </Styled.GreenBox>
      <Styled.Box>
        <Styled.ContainerDates>
          <Styled.FormItem
            label={t('stock_transfer.details.date')}
            name={['postingDate']}
            rules={[
              {
                required: true,
              },
            ]}
          >
            <DatePicker format={'DD/MM/YYYY'} />
          </Styled.FormItem>
          <Styled.FormItem
            label={t('stock_transfer.details.time')}
            name={['postingTime']}
            rules={[
              {
                required: true,
              },
            ]}
          >
            <DatePicker picker={'time'} format={'HH:mm:ss'} />
          </Styled.FormItem>
        </Styled.ContainerDates>
        <Styled.FormItem
          label={t(
            transferBetweenFarms
              ? 'stock_transfer.details.source_farm'
              : 'stock_transfer.details.farm',
          )}
          name={['sourceFarm']}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <AutoSingleSelect
            {...farmSelectProps}
            loading={isLoadingFarm}
            options={filteredSourceFarm}
            placeholder={t('common.business.farm_placeholder')}
          />
        </Styled.FormItem>
        {sourceFarm && (
          <Styled.FormItem
            label={t('stock_transfer.details.source_warehouse')}
            name={['sourceWarehouse']}
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Select
              {...sourceWarehouseSelectProps}
              options={filteredSourceWarehouse}
              onSelect={(value: unknown) => {
                setSourceWarehouse(value as string);
              }}
              loading={isLoadingSourceWarehouse}
              placeholder={t('common.business.warehouse_placeholder')}
            />
          </Styled.FormItem>
        )}
        {transferBetweenFarms && (
          <Styled.FormItem
            label={t('stock_transfer.details.target_farm')}
            name={['targetFarm']}
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Select
              {...farmSelectProps}
              loading={isLoadingFarm}
              options={filteredTargetFarm}
              placeholder={t('common.business.farm_placeholder')}
            />
          </Styled.FormItem>
        )}
        {(sourceFarm || targetFarm) && (
          <Styled.FormItem
            label={t('stock_transfer.details.target_warehouse')}
            name={['targetWarehouse']}
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Select
              {...targetWarehouseSelectProps}
              options={filteredTargetWarehouse}
              onChange={(value: { value: string; label: string }) =>
                setTargetWarehouse(value.value)
              }
              loading={isLoadingTargetWarehouse}
              placeholder={t('common.business.warehouse_placeholder')}
            />
          </Styled.FormItem>
        )}
      </Styled.Box>
    </Styled.Container>
  );
};

export { StockTransferDetails as default, StockTransferDetails };
