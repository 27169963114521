import { setToken, setupAxiosInterceptor } from '@cw-elements/config';
import { DeckOfApps } from '@cw-elements/deck-of-apps';
import { ProfileDropdown } from '@cw-elements/profile-dropdown';
import { AvailableLanguages } from '@cw-elements/profile-dropdown/utils/contants';
import { Divider } from 'antd';
import { ROUTES, USER_OPTIONS } from 'common/constants';
import { usePermissionsBalance } from 'common/hooks/use-permissions-balance.hook';
import { useRefineTableFiltersStore } from 'common/refine-table-filters/refine-table-filters.store';
import {
  getLocalData,
  StorageKeyOptionsEnum,
  useNavigateWithOrgAndFarm,
} from 'common/utils';
import { ActionRbacEnum } from 'common/utils/auth-provider.util';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { api } from 'src/api.config';
import { useAuth } from 'src/auth';
import { languageStored } from 'src/i18n.config';
import { useFarm, useOrganization } from 'src/organization';
import { SettingsButtonContainer } from 'src/settings';
import { NeutralColors } from 'theme';

import * as Styled from './header.style';
import HelpCenterButton from './help-center-button/help-center-button.component';
import { MigrationButton } from './migration-button/migration-button.component';

const Header = () => {
  const { t, i18n } = useTranslation();
  const {
    currentOrganization,
    currentWorkspaceId,
    setCurrentOrganizationId,
    organizations,
  } = useOrganization();
  const { currentFarm, setCurrentFarmId, farms } = useFarm();
  const { user, signout } = useAuth();
  const navigate = useNavigateWithOrgAndFarm();
  const [searchParams, setSearchParams] = useSearchParams();
  const { permissions } = usePermissionsBalance();
  const { helpCenterDisableMenuAccess } = useFlags();
  const { handleInvalidateFiltersStore } = useRefineTableFiltersStore();

  useEffect(() => {
    const accessData = getLocalData(StorageKeyOptionsEnum.AccessData);

    setupAxiosInterceptor(api);

    if (accessData?.access_token) {
      setToken(accessData.access_token);
    }
  }, [user]);

  const organizationOptions = useMemo(() => {
    return [
      ...Object.values(organizations).map((organization) => ({
        value: organization.id,
        label: organization.name,
      })),
    ];
  }, [organizations]);

  const onSelectOrganization = useCallback(
    (organizationId: string) => {
      setCurrentOrganizationId(organizationId);
      setCurrentFarmId(null);
      searchParams.set('organizationId', organizationId);
      searchParams.set('farmId', '');
      setSearchParams(searchParams);
    },
    [setCurrentOrganizationId, setCurrentFarmId, searchParams, setSearchParams],
  );

  const onClickAllOrganization = useCallback(() => {
    setCurrentOrganizationId(null);
    setCurrentFarmId(null);
    navigate(ROUTES.ORGANIZATION, null, null);
  }, [setCurrentOrganizationId, setCurrentFarmId, navigate]);

  const farmOptions = useMemo(() => {
    return [
      ...Object.values(farms).map((farm) => ({
        value: farm.id,
        label: farm.name,
      })),
    ];
  }, [farms]);

  const onSelectFarm = useCallback(
    (farmId: string) => {
      setCurrentFarmId(farmId);
      searchParams.set('farmId', farmId);
      setSearchParams(searchParams);
      handleInvalidateFiltersStore();
    },
    [setCurrentFarmId, searchParams, setSearchParams],
  );

  const onClickAllFarm = useCallback(() => {
    setCurrentFarmId(null);
    searchParams.set('farmId', '');
    if (currentOrganization?.id) {
      navigate(ROUTES.FARM, currentOrganization?.id, null);
    }
  }, [setCurrentFarmId, searchParams, navigate, currentOrganization?.id]);

  return (
    <Styled.HeaderLayout>
      {user && (
        <DeckOfApps
          headerSelector="#header"
          workspaceId={currentWorkspaceId ?? undefined}
          orgId={currentOrganization?.id}
          propertyId={currentFarm?.externalId}
          onLogout={signout}
        />
      )}
      <Styled.Logo />
      <Styled.OrganizationsWrapper>
        <Styled.DropdownButton>
          <Styled.DropdownDescription>
            {t('header.organization')}
          </Styled.DropdownDescription>
          <Styled.Select
            buttonProps={{
              type: 'text',
              onClick: onClickAllOrganization,
              children: t('header.all_organizations'),
              style: { borderTop: `1px solid ${NeutralColors[10]}` },
            }}
            value={currentOrganization?.id}
            defaultValue={currentOrganization?.id}
            options={organizationOptions}
            onSelect={onSelectOrganization}
            style={{ minWidth: '200px' }}
            placeholder={
              <Styled.DropdownSelected strong>
                {t('header.all_organizations')}
              </Styled.DropdownSelected>
            }
          />
        </Styled.DropdownButton>
        {currentOrganization && (
          <Styled.DropdownButton>
            <Styled.DropdownDescription>
              {t('header.farm')}
            </Styled.DropdownDescription>
            <Styled.Select
              buttonProps={{
                type: 'text',
                onClick: onClickAllFarm,
                children: t('header.all_farms'),
              }}
              value={currentFarm?.id}
              defaultValue={currentFarm?.id}
              style={{ minWidth: '200px' }}
              options={farmOptions}
              onSelect={onSelectFarm}
              popupMatchSelectWidth={false}
              placeholder={
                <Styled.DropdownSelected strong>
                  {t('header.all_farms')}
                </Styled.DropdownSelected>
              }
            />
          </Styled.DropdownButton>
        )}
      </Styled.OrganizationsWrapper>
      <Styled.OptionsWrapper>
        {permissions.length > 0 &&
          permissions?.includes(ActionRbacEnum.FINANCIAL_BALANCE_WRITE) &&
          permissions?.includes(ActionRbacEnum.STOCK_BALANCE_WRITE) && (
            <MigrationButton />
          )}
        {!helpCenterDisableMenuAccess && <HelpCenterButton />}
        {currentOrganization &&
          permissions?.includes(ActionRbacEnum.FINANCIAL_BALANCE_WRITE) && (
            <SettingsButtonContainer />
          )}
      </Styled.OptionsWrapper>
      {user && languageStored && (
        <Styled.ProfileWrapper>
          <Divider type="vertical" />
          <ProfileDropdown
            onLogout={signout}
            defaultLanguage={i18n.language as AvailableLanguages}
            availableLanguages={USER_OPTIONS.AVAILABLE_LANGUAGES}
            onLanguageChange={(language) => i18n.changeLanguage(language)}
          />
        </Styled.ProfileWrapper>
      )}
    </Styled.HeaderLayout>
  );
};

export { Header as default, Header };
