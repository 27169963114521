import { useCreate } from '@refinedev/core';
import { FormProps } from 'antd';
import { useWatch } from 'antd/es/form/Form';
import { FormInstance } from 'antd/lib/form/Form';
import { useTracking } from 'common/utils';
import dayjs from 'dayjs';
import { useEffect, useMemo, useState } from 'react';
import { useOrganization } from 'src/organization';
import { useBankAccountCreateDrawer } from 'src/resources/bank-account/use-bank-account-create-drawer.hook';

import { useInvalidateTableData } from '../use-invalidate-table-data.hook';
import { PurchasePaymentRequestModel } from './purchase-payment.model';

export const usePurchasePayment = (
  formProps: FormProps,
  formInstance: FormInstance,
  onClose: () => void,
) => {
  const { track } = useTracking();
  const { currentOrganizationId } = useOrganization();
  const [isDisabledSave, setIsDisabledSave] = useState(false);
  const { mutate, isLoading } = useCreate();

  const formValues = useWatch([], formProps?.form);

  const { refetchTableAndInvalidate } = useInvalidateTableData();

  const initialValues: PurchasePaymentRequestModel = useMemo(() => {
    return {
      postingDate: dayjs(),
      postingTime: dayjs(),
      id: formProps?.initialValues?.id,
      farm: formProps?.initialValues?.farmName,
      paymentAccount: undefined,
      paymentType: '',
      amount:
        formProps?.initialValues?.grandTotal -
        formProps?.initialValues?.paidAmount,
    };
  }, [formProps?.initialValues]);

  const paidTotal = useMemo(() => {
    return formProps?.initialValues?.paidAmount ?? 0;
  }, [formProps?.initialValues?.paidAmount]);

  const outstandingTotal = useMemo(() => {
    return (
      formProps?.initialValues?.grandTotal -
      (formProps?.initialValues?.paidAmount ?? 0)
    );
  }, [
    formProps?.initialValues?.paidAmount,
    formProps?.initialValues?.grandTotal,
  ]);

  useEffect(() => {
    formInstance
      ?.validateFields({ validateOnly: true })
      .then(() => {
        setIsDisabledSave(false);
      })
      .catch(() => {
        setIsDisabledSave(true);
      });
  }, [formValues, formInstance, setIsDisabledSave]);

  const handleOnFinish = (values: PurchasePaymentRequestModel) => {
    mutate(
      {
        resource: `purchase-order/${values.id}/payment`,
        meta: {
          organizationId: currentOrganizationId,
        },
        values: {
          ...values,
          postingDate: dayjs(values.postingDate).format('YYYY-MM-DD'),
        },
      },
      {
        onSuccess: () => {
          track('End Purchase Payment');
          onClose();
          refetchTableAndInvalidate();
        },
      },
    );
  };

  const { createBankAccountDrawer, handleOpenBankAccountDrawer } =
    useBankAccountCreateDrawer();

  return {
    initialValues,
    paidTotal,
    outstandingTotal,
    isDisabledSave,
    isLoading,
    handleOnFinish,
    createBankAccountDrawer,
    handleOpenBankAccountDrawer,
  };
};
