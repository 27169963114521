import { Avatar, Typography } from 'antd';

import { Organization } from '../organization.model';
import * as Styled from './organization-card.style';

export interface OrganizationCardProps {
  organization: Organization;
  onClick?: () => void;
}

const OrganizationCard = ({ organization, onClick }: OrganizationCardProps) => {
  return (
    <Styled.Card onClick={onClick} data-testid="organization-card">
      <Avatar shape="square" size={32} src={organization.picture_url} />
      <Typography.Title level={5}>{organization.name}</Typography.Title>
    </Styled.Card>
  );
};

export { OrganizationCard as default, OrganizationCard };
