import { useTable } from '@refinedev/antd';
import { Table, Tag } from 'antd';
import { FormattedDate, PageTitle } from 'common/components';
import { ROUTES } from 'common/constants';
import { useNavigateWithOrgAndFarm } from 'common/utils';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useOrganization } from 'src/organization';

import { DataImportModel } from '../data-import.model';

const DataImportListContainer = () => {
  const { t } = useTranslation();
  const navigate = useNavigateWithOrgAndFarm();
  const { docType } = useParams();
  const { currentOrganizationId } = useOrganization();
  const metadata = useMemo(
    () => ({
      organizationId: currentOrganizationId,
    }),
    [currentOrganizationId],
  );

  const { tableProps } = useTable<DataImportModel>({
    resource: `data-import/${docType}`,
    syncWithLocation: true,
    queryOptions: {
      enabled: !!currentOrganizationId && !!docType,
    },
    meta: metadata,
    filters: {
      initial: [
        {
          field: 'status',
          operator: 'eq',
          value: ['Success', 'Partial Success'],
        },
      ],
    },
  });

  const onRow = (data: DataImportModel) => ({
    onClick: () => {
      navigate(
        `${ROUTES.DATA_IMPORT_DETAILS_ROOT}/${encodeURIComponent(data.id)}`,
      );
    },
  });

  return (
    <>
      <PageTitle
        title={t('data_import.data_import_list.title', {
          docType: t(`data_import.doc_type.${docType}`),
        })}
        hasBackButton
      />
      <Table {...tableProps} onRow={onRow}>
        <Table.Column
          render={(_, { creation }: DataImportModel) => (
            <FormattedDate date={creation} />
          )}
          title={t('common.date')}
        />
        <Table.Column
          dataIndex="status"
          title={t('common.status')}
          render={(_, { status }: DataImportModel) => (
            <Tag color={status === 'Success' ? 'success' : 'default'}>
              {status}
            </Tag>
          )}
        />
      </Table>
    </>
  );
};

export { DataImportListContainer };
