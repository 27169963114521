import { create } from 'zustand';

import { MaterialConsumptionRequestModel } from '../material-consumption.model';

type MaterialConsumptionFormState = {
  persistState: MaterialConsumptionRequestModel | null;
  disableSave: boolean;
  showItems: boolean;
};

type MaterialConsumptionFormActions = {
  setPersistState: (value: MaterialConsumptionRequestModel | null) => void;
  setDisableSave: (value: boolean) => void;
  setShowItems: (value: boolean) => void;
};

const useMaterialConsumptionFormStore = create<
  MaterialConsumptionFormState & MaterialConsumptionFormActions
>((set) => ({
  persistState: null,
  setPersistState: (value: MaterialConsumptionRequestModel | null) =>
    set(() => ({ persistState: value })),
  disableSave: false,
  setDisableSave: (value: boolean) => set(() => ({ disableSave: value })),
  showItems: false,
  setShowItems: (value: boolean) => set(() => ({ showItems: value })),
}));

export { useMaterialConsumptionFormStore };
