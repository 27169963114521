import { Select, SelectProps } from 'antd';
import { useEffect } from 'react';

type AutoSingleSelectProps = SelectProps;

const AutoSingleSelect = ({
  onChange,
  options,
  ...props
}: AutoSingleSelectProps) => {
  useEffect(() => {
    if (options?.length === 1) {
      onChange?.(options[0].value, options[0]);
    }
  }, [options]);

  return <Select options={options} onChange={onChange} {...props} />;
};

export { AutoSingleSelect };
