import { CheckOutlined } from '@ant-design/icons';
import { Button, Table } from 'antd';
import { CurrencyValue, PageTitle } from 'common/components';
import {
  BalanceProtectorIcon,
  BalanceSmallIcon,
  ChevronBottomIcon,
  ChevronTopIcon,
  ItemsIcon,
  ProtectorSmallIcon,
  WarehouseIcon,
} from 'common/icons';
import {
  ListFiltersModel,
  RefineTableFilters,
  RefineTableFiltersEnum,
  useRefineTableFilters,
} from 'common/refine-table-filters';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useOrganization } from 'src/organization';

import { FinishModal } from './finish-modal/finish-modal.component';
import { StockComparisonModel } from './migration.model';
import * as Styled from './migration.style';
import { MigrationItems } from './migration-items/migration-items.component';
import { WarehouseColumn } from './warehouse-column/warehouse-column.component';
import { WarehouseDisableColumn } from './warehouse-disable-column/warehouse-disable-column.component';

const MigrationContainer = () => {
  const [openFinish, setOpenFinish] = useState(false);
  const { t } = useTranslation();
  const { currentOrganizationId } = useOrganization();

  const [updateTable, setUpdateTable] = useState(false);

  const metadata = useMemo(
    () => ({
      organizationId: currentOrganizationId,
    }),
    [currentOrganizationId],
  );

  const listFilters: ListFiltersModel[] = useMemo(
    () => [
      {
        label: t('filter.item'),
        type: RefineTableFiltersEnum.SELECT,
        field: 'itemCode',
        optionLabel: 'name',
        resource: 'item',
        show: true,
        icon: <ItemsIcon />,
      },
      {
        label: t('filter.warehouse'),
        field: 'warehouse',
        type: RefineTableFiltersEnum.SELECT,
        resource: 'warehouse',
        show: true,
        icon: <WarehouseIcon />,
      },
    ],
    [t],
  );

  const { tableProps, searchFormProps, tableQueryResult } =
    useRefineTableFilters<StockComparisonModel>({
      listFilters,
      resource: 'stock-comparison',
      meta: metadata,
    });

  const handleRefetch = () => {
    void tableQueryResult.refetch();
  };

  const handleRenderExpandable = (record: StockComparisonModel) => (
    <MigrationItems
      items={record.items}
      warehouse={record.warehouseId}
      refetch={handleRefetch}
    />
  );

  const handleRenderExpandIcon = ({ expanded, onExpand, record }: any) => {
    return expanded ? (
      <ChevronTopIcon onClick={(e: unknown) => onExpand(record, e)} />
    ) : (
      <ChevronBottomIcon onClick={(e: unknown) => onExpand(record, e)} />
    );
  };

  const handleOpenFinishModal = () => {
    setOpenFinish(true);
  };

  useEffect(() => {
    if (updateTable) {
      handleRefetch();

      setUpdateTable(false);
    }
  }, [updateTable]);

  return (
    <>
      <FinishModal open={openFinish} setOpen={setOpenFinish} />
      <PageTitle
        title={t('migration.title')}
        leftIcon={<BalanceProtectorIcon />}
        buttons={
          <Button
            onClick={handleOpenFinishModal}
            icon={<CheckOutlined ref={undefined} />}
            type="primary"
          >
            {t('migration.finish_button')}
          </Button>
        }
      />
      <RefineTableFilters
        searchFormProps={searchFormProps}
        initialListFilters={listFilters}
      />
      <Styled.Table
        {...tableProps}
        expandable={{
          expandIcon: handleRenderExpandIcon,
          expandedRowRender: handleRenderExpandable,
        }}
        rowKey={'warehouseId'}
        pagination={false}
        scroll={{ y: 520, x: 870 }}
      >
        <Table.Column
          dataIndex="warehouse"
          title={t('migration.table.item')}
          render={(warehouse) => <WarehouseColumn warehouse={warehouse} />}
        />
        <Table.Column
          width={140}
          dataIndex="amountProtector"
          title={
            <Styled.HeaderBlueColumn>
              <ProtectorSmallIcon />
              <p>{t('migration.table.amount_protector')}</p>
            </Styled.HeaderBlueColumn>
          }
        />
        <Table.Column
          width={120}
          dataIndex="unitValueProtector"
          title={t('migration.table.unit_value')}
        />
        <Table.Column
          width={120}
          dataIndex="legacyBalanceValue"
          title={t('migration.table.total_value')}
          render={(fmsBalanceValue) => (
            <CurrencyValue value={fmsBalanceValue} />
          )}
        />
        <Table.Column
          width={200}
          dataIndex="farmName"
          title={
            <Styled.HeaderBlueColumn>
              <BalanceSmallIcon />
              <p>{t('migration.table.amount_balance')}</p>
            </Styled.HeaderBlueColumn>
          }
        />
        <Table.Column width={120} title={t('migration.table.unit_value')} />
        <Table.Column
          width={120}
          dataIndex="fmsBalanceValue"
          title={t('migration.table.total_value')}
          render={(fmsBalanceValue) => (
            <CurrencyValue value={fmsBalanceValue} />
          )}
        />
        <Table.Column
          width={120}
          dataIndex="warehouseDisabled"
          title={t('migration.table.active_warehouse')}
          render={(
            _,
            { warehouseDisabled, warehouseId }: StockComparisonModel,
          ) => (
            <WarehouseDisableColumn
              setUpdateTable={setUpdateTable}
              warehouseDisabled={warehouseDisabled}
              warehouseId={warehouseId}
            />
          )}
        />
      </Styled.Table>
    </>
  );
};

export { MigrationContainer };
