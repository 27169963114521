import { Button } from 'antd';
import styled from 'styled-components';
import { GreenColors, NeutralColors } from 'theme';

const WarehouseListWrapper = styled.div`
  .fms-table-tbody {
    svg {
      fill: ${NeutralColors[60]};
    }

    .fms-table-row-expand-icon-cell,
    .fms-table-cell {
      cursor: pointer;
    }
  }

  .warehouse {
    &:hover {
      &::after {
        content: ' ';
        background-image: url('data:image/svg+xml,<svg aria-label="chevron right" width="24" height="24" xmlns="http://www.w3.org/2000/svg"><path d="M9.705 6l-1.41 1.41 4.58 4.59-4.58 4.59L9.705 18l6-6-6-6z" fill="%23696F88"/></svg>');
        background-repeat: no-repeat;
        background-position: 50%;
        display: inline-block;
        width: 18px;
        height: 18px;
        margin-left: 8px;
        vertical-align: sub;
      }
    }
  }

  .fms-table-expanded-row {
    > .fms-table-cell {
      padding-left: 0 !important;
      padding-right: 0 !important;

      &,
      &:hover {
        background-color: transparent;
      }
    }

    .fms-table {
      margin-inline: 0 !important;
      border-radius: 0;

      .fms-table-cell:first-child {
        padding-left: 55px;
      }
    }
  }
`;

const TitleMoreButton = styled(Button)`
  color: ${GreenColors[60]};
`;

export { TitleMoreButton, WarehouseListWrapper };
