import { useDrawerForm } from '@refinedev/antd';
import { HttpError } from '@refinedev/core';
import { useTracking } from 'common/utils';

import { ItemCategory } from './item-category.model';

const useItemCategoryCreateDrawer = (
  syncWithLocationKey?: string,
  onSuccessCallback?: () => void,
) => {
  const { track } = useTracking();

  const createItemCategoryDrawer = useDrawerForm<
    ItemCategory,
    HttpError,
    ItemCategory
  >({
    onMutationSuccess: () => {
      onSuccessCallback && onSuccessCallback();
    },
    action: 'create',
    resource: 'item-category',
    redirect: false,
    syncWithLocation: syncWithLocationKey
      ? { key: syncWithLocationKey }
      : false,
  });

  const handleOpenItemCategoryDrawer = () => {
    track('Start ItemCategory Creation');
    createItemCategoryDrawer.show();
  };

  const handleCloseItemCategoryDrawer = () => {
    track('End ItemCategory Creation');
    createItemCategoryDrawer.close();
  };

  return {
    createItemCategoryDrawer: {
      ...createItemCategoryDrawer,
      close: handleCloseItemCategoryDrawer,
    },
    handleOpenItemCategoryDrawer,
  };
};

export { useItemCategoryCreateDrawer };
