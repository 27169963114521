import { Button, Table as AntdTable } from 'antd';
import styled from 'styled-components';
import { NeutralColors, RedColors } from 'theme';

import { InvoiceReceiptTableModel } from './invoice-receipt-table.model';

const Table = styled(AntdTable<InvoiceReceiptTableModel>)`
  .fms-table {
    margin: -16px !important;
    border: 1px solid ${NeutralColors[20]} !important;
    border-radius: 12px !important;
    overflow: hidden;

    .fms-table-thead {
      background-color: ${NeutralColors[10]};
      border-radius: 12px !important;
    }

    .fms-table-cell {
      border-radius: unset !important;
      overflow: hidden;
    }

    .fms-table-cell-row-hover {
      background-color: ${NeutralColors[10]} !important;
    }
  }

  .fms-spin-blur {
    opacity: 0;
  }
`;

const CustomDeleteButton = styled(Button)`
  display: block;
  fill: ${RedColors[60]} !important;

  &:hover {
    fill: ${RedColors[40]} !important;
  }
`;

export { CustomDeleteButton, Table };
