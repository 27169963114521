import { useDrawerForm } from '@refinedev/antd';
import { BaseKey, HttpError, IResourceComponentsProps } from '@refinedev/core';
import { Space, Table } from 'antd';
import { AddIcon, EyeOutlinedIcon } from 'common/icons';
import {
  ListFiltersModel,
  RefineTableFilters,
  RefineTableFiltersEnum,
  useRefineTableFilters,
} from 'common/refine-table-filters';
import { useTracking } from 'common/utils';
import { FC, MouseEvent, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { ResourcesEnum } from '../resources.enum';
import { ResourcesTitle } from '../resources-title.component';
import { SupplierModel, SupplierRequestModel } from './supplier.model';
import { SupplierCreateContainer } from './supplier-create/supplier-create.container';
import * as Styled from './supplier-list.style';
import { useSupplierCreateDrawer } from './use-supplier-create-drawer.hook';

export const SupplierListContainer: FC<IResourceComponentsProps> = () => {
  const { t } = useTranslation();
  const { track } = useTracking();

  const listFilters: ListFiltersModel[] = useMemo(
    () => [
      {
        label: t('supplier.table.name'),
        field: 'name',
        type: RefineTableFiltersEnum.INPUT,
        show: true,
      },
    ],
    [],
  );

  const { tableProps, searchFormProps } = useRefineTableFilters<
    SupplierModel,
    HttpError
  >({
    resource: 'supplier',
    listFilters,
  });

  const editDrawer = useDrawerForm<
    SupplierRequestModel,
    HttpError,
    SupplierRequestModel
  >({
    action: 'edit',
    resource: 'supplier',
    redirect: false,
    syncWithLocation: {
      key: 'edit',
    },
  });

  const { createSupplierDrawer, handleOpenSupplierDrawer } =
    useSupplierCreateDrawer('create');

  const handleOnClickEditSupplier = (id: BaseKey) => {
    if (id) {
      track('Edit Invoice');
      editDrawer.show(id);
    }
  };

  const onRow = (data: SupplierModel) => ({
    onClick: (event: MouseEvent<HTMLElement>) => {
      const target = event.target as HTMLElement;
      if (target.tagName !== 'TD') {
        event.stopPropagation();
        return;
      }

      handleOnClickEditSupplier(data.id);
    },
  });

  return (
    <>
      <SupplierCreateContainer supplierDrawerForm={createSupplierDrawer} />
      <SupplierCreateContainer supplierDrawerForm={editDrawer} isReadonly />
      <ResourcesTitle
        buttons={
          <Styled.CreateButton
            onClick={handleOpenSupplierDrawer}
            icon={<AddIcon />}
            type="primary"
          >
            {t('buttons.create')}
          </Styled.CreateButton>
        }
        selectedTab={ResourcesEnum.SUPPLIER}
      />
      <RefineTableFilters
        initialListFilters={listFilters}
        searchFormProps={searchFormProps}
      />
      <Table
        {...tableProps}
        onRow={onRow}
        rowKey="id"
        pagination={{
          ...tableProps.pagination,
          showSizeChanger: true,
          pageSizeOptions: [10, 20, 50, 100],
        }}
      >
        <Table.Column dataIndex="id" title={t('supplier.table.name')} />
        <Table.Column dataIndex="group" title={t('supplier.table.group')} />
        <Table.Column
          width={120}
          title={t('supplier.table.actions')}
          render={({ id }: SupplierModel) => (
            <Space>
              <Styled.ViewButton
                icon={<EyeOutlinedIcon />}
                onClick={() => handleOnClickEditSupplier(id)}
              />
            </Space>
          )}
        />
      </Table>
    </>
  );
};

export default SupplierListContainer;
