import { DatePicker, Form, Select, Switch } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import { AutoSingleSelect, SelectWithButton } from 'common/components';
import dayjs from 'dayjs';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { useInvoiceFormStore } from '../invoice-form.store';
import useInvoiceFormDetails from './invoice-form-details.hook';
import * as Styled from './invoice-form-details.style';

interface InvoiceFormDetailsProps {
  handleOpenSupplierDrawer: () => void;
  isEdit: boolean;
}

const InvoiceFormDetails: FC<InvoiceFormDetailsProps> = ({
  handleOpenSupplierDrawer,
  isEdit,
}) => {
  const { t } = useTranslation();
  const { showItems } = useInvoiceFormStore();

  const {
    farm,
    farmSelectProps,
    seasonSelectProps,
    supplierSelectProps,
    projectSelectProps,
    onChangeFarm,
    dueDate,
    isCropZone,
    isPaid,
    isLoadingFarm,
    isLoadingSupplier,
    isLoadingProject,
    isLoadingSeason,
    selectPropsAccount,
  } = useInvoiceFormDetails();

  return (
    <Styled.Container show={!showItems}>
      <Styled.Box>
        <Styled.ContainerDates>
          <Styled.FormItem
            label={t('invoice.form.details.date')}
            name={['postingDate']}
            rules={[
              {
                required: true,
              },
              {
                validator: (_, value) => {
                  if (value > dueDate) {
                    return Promise.reject(
                      new Error(t('invoice.form.details.date_error') ?? ''),
                    );
                  }
                  return Promise.resolve();
                },
              },
            ]}
          >
            <DatePicker value={dayjs()} format={'DD/MM/YYYY'} />
          </Styled.FormItem>
          <Styled.FormItem
            label={t('invoice.form.details.due_date')}
            name={['dueDate']}
            rules={[
              {
                required: true,
              },
            ]}
          >
            <DatePicker value={dayjs()} format={'DD/MM/YYYY'} />
          </Styled.FormItem>
        </Styled.ContainerDates>
        <Styled.FormItem
          label={t('invoice.form.details.farm')}
          name={['farm']}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <AutoSingleSelect
            {...farmSelectProps}
            loading={isLoadingFarm}
            onChange={onChangeFarm}
            placeholder={t('common.business.farm_placeholder')}
          />
        </Styled.FormItem>
        <Styled.FormItem
          label={t('invoice.form.details.supplier')}
          name={['supplier']}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <SelectWithButton
            {...supplierSelectProps}
            loading={isLoadingSupplier}
            placeholder={t('common.business.supplier_placeholder')}
            buttonProps={{
              onClick: handleOpenSupplierDrawer,
              children: t('common.create_new'),
            }}
          />
        </Styled.FormItem>
        <Styled.FormItem
          label={t('common.business.season')}
          name={['seasonId']}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select
            {...seasonSelectProps}
            loading={isLoadingSeason}
            placeholder={t('common.business.season_placeholder')}
          />
        </Styled.FormItem>
        <Styled.FormItem
          label={t('common.business.description')}
          name={['description']}
          rules={[{}]}
        >
          <TextArea
            placeholder={
              t('invoice.form.details.description_placeholder') ?? ''
            }
            count={{ show: true, max: 100 }}
          />
        </Styled.FormItem>
        <Styled.FormItem name={['costCenter']} hidden />
        <Styled.FormItem name={['currency']} hidden />
      </Styled.Box>
      {!isEdit && (
        <Styled.BlueBox isBlue={isPaid}>
          <Styled.SwitchWrapper>
            <div>{t('invoice.form.details.is_paid')}</div>
            <Form.Item name={['isPaid']} valuePropName={'checked'}>
              <Switch
                checkedChildren={t('buttons.yes')}
                unCheckedChildren={t('buttons.no')}
              />
            </Form.Item>
          </Styled.SwitchWrapper>
          {isPaid && (
            <Styled.FormItem
              label={t('common.business.bank_account')}
              name={['paymentAccount']}
              rules={[
                {
                  required: true,
                },
              ]}
              extra={!farm && t(`invoice.form.details.payment_method_extra`)}
            >
              <Select {...selectPropsAccount} disabled={!farm} />
            </Styled.FormItem>
          )}
        </Styled.BlueBox>
      )}
      <Styled.BlueBox isBlue={isCropZone}>
        <Styled.SwitchWrapper>
          {t('invoice.form.details.is_crop_zone')}
          <Form.Item name={['isCropZone']} valuePropName={'checked'}>
            <Switch
              checked={isCropZone}
              checkedChildren={t('buttons.yes')}
              unCheckedChildren={t('buttons.no')}
            />
          </Form.Item>
        </Styled.SwitchWrapper>
        {isCropZone && (
          <Styled.FormItem
            label={t('invoice.form.details.crop_zone')}
            name={['project']}
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Select
              {...projectSelectProps}
              mode="multiple"
              loading={isLoadingProject}
              placeholder={t('common.business.crop_zone_placeholder')}
            />
          </Styled.FormItem>
        )}
      </Styled.BlueBox>
    </Styled.Container>
  );
};

export { InvoiceFormDetails as default, InvoiceFormDetails };
