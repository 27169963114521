import { Button as ButtonAntd } from 'antd';
import {
  AddIcon as AddIconCommon,
  EditIcon as EditIconCommon,
} from 'common/icons';
import styled from 'styled-components';
import { BlueColors, NeutralColors } from 'theme';

interface ReferenceValueButtonProps {
  empty?: boolean;
}

const Button = styled(ButtonAntd)<ReferenceValueButtonProps>`
  display: flex;
  align-items: center;
  border-color: ${({ empty }) => (empty ? BlueColors[20] : 'default')};
  background-color: ${({ empty }) => (empty ? BlueColors[10] : 'default')};
  color: ${NeutralColors[60]} !important;
  padding: 8px 8px;

  :hover {
    border-color: ${({ empty }) =>
      empty
        ? `${BlueColors[30]} !important`
        : `${NeutralColors[20]} !important`};
    background-color: ${({ empty }) =>
      empty
        ? `${BlueColors[20]} !important`
        : `${NeutralColors[10]} !important`};
    color: ${NeutralColors[60]} !important;
  }

  span {
    display: inline-block;
    font-size: 14px;
  }

  h5 {
    display: contents;
  }

  svg {
    margin: 0 4px;
  }
`;

const EditIcon = styled(EditIconCommon)`
  padding: 3px;
  margin-left: 8px !important;
`;

const AddIcon = styled(AddIconCommon)`
  margin-left: 8px !important;
`;

export { AddIcon, Button, EditIcon };
