import {
  BlueColors,
  GreenColors,
  NeutralColors,
  RedColors,
  YellowColors,
} from 'src/theme';
import { createGlobalStyle, css } from 'styled-components';

const notificationCommonStyles = css`
  &,
  .fms-notification-notice-message,
  .ant-notification-notice-message,
  .fms-notification-notice-close,
  .ant-notification-notice-close,
  .ant-notification-notice-description,
  .fms-notification-notice-icon {
    color: ${NeutralColors[0]} !important;
  }
`;

const GlobalStyle = createGlobalStyle`
  // Notification
  .fms-notification-notice-info,
  .ant-notification-notice-info {
    ${notificationCommonStyles}
    background-color: ${BlueColors[60]} !important;
  }
  .fms-notification-notice-warning,
  .ant-notification-notice-warning {
    ${notificationCommonStyles}
    background-color: ${YellowColors[40]} !important;
  }
  .fms-notification-notice-error,
  .ant-notification-notice-error {
    ${notificationCommonStyles}
    background-color: ${RedColors[60]} !important;
  }
  .fms-notification-notice-success,
  .ant-notification-notice-success {
    ${notificationCommonStyles}
    background-color: ${GreenColors[60]} !important;
  }

  // Submenu
  .fms-menu-submenu-popup {
    .fms-menu-sub{
      border-radius: 4px;

      .fms-menu-item-group-title {
        color: ${NeutralColors[100]};
        font-weight: 600;
      }

      .fms-menu-item {
        margin: 0 !important;
        width: 100%;
        border-radius: 0 !important;
        display: flex;
        align-items: center;
        color: ${NeutralColors[80]};

        :hover,
        :active {
          background-color: ${NeutralColors[10]} !important;
        }

          svg {
          flex-grow: 0;
          flex-shrink: 0;
          fill: currentColor !important;
        }

        &-selected {
          background-color: ${NeutralColors[10]};
          color: ${GreenColors[60]};

          ::before {
            content: '';
            background-color: ${GreenColors[60]};
            border-radius: 0 4px 4px 0;
            width: 4px;
            display: block;
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            height: calc(100% - 8px);
          }
        }
      }
    }
  }
`;

export { GlobalStyle as default, GlobalStyle };
