import { CloseOutlined } from '@ant-design/icons';
import { Button as ButtonAntd, Tag as TagAntd } from 'antd';
import styled from 'styled-components';
import { BlueColors, NeutralColors } from 'theme';

const IconClear = styled(CloseOutlined)`
  color: ${NeutralColors[60]};
`;

const ContainerIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-left: 3px solid ${NeutralColors[0]};
  position: absolute;
  right: -12px;
  height: calc(100% + 6px);
  padding-left: 4px;
`;

const Container = styled.div`
  display: flex;
`;

const Tag = styled(TagAntd)`
  display: flex;
  align-items: center;
  background-color: ${NeutralColors[10]};
  border: none;
  color: ${BlueColors[60]};
  font-weight: 600;
  height: 22px;
  padding-right: 0px;
`;

const Button = styled(ButtonAntd)`
  display: flex;
  align-items: center;
  height: 100%;
  padding: 0px;
`;

const BoxButton = styled.div`
  border-radius: 0px 6px 6px 0px;
  margin-left: 1px;
`;

const BoxSelect = styled.div`
  padding-right: 8px;
  margin-left: -8px;

  span {
    color: ${BlueColors[60]} !important;
    font-weight: 600 !important;
  }
`;

const SpanMoreLabel = styled.span`
  margin-left: 6px;
  font-size: 10px;
  color: ${NeutralColors[40]};
`;

export {
  BoxButton,
  BoxSelect,
  Button,
  Container,
  ContainerIcon,
  IconClear,
  SpanMoreLabel,
  Tag,
};
