import { InputNumber, InputNumberProps } from 'antd';
import { valueType } from 'antd/es/statistic/utils';
import { useEffect, useState } from 'react';

interface InputMoneyProps extends InputNumberProps {
  value?: string | number;
  addonBefore?: string;
}

const DECIMAL_SIZE = 2;

const InputMoney = ({ value, onChange, ...props }: InputMoneyProps) => {
  const [currentValue, setCurrentValue] = useState<string>(`${value ?? 0}`);

  useEffect(() => {
    const valueString = `${value}`;
    const valueNumber = Number(value);

    if (!/\D/.test(valueString.replace('.', ''))) {
      setCurrentValue(
        valueNumber.toFixed(DECIMAL_SIZE).toString().replace('.', ','),
      );
    }
  }, [value]);

  const handleOnChange = (currentValueChange: valueType | null) => {
    if (!onChange) {
      return;
    }

    let valueRemoved = `${currentValueChange}`.replace(/,/g, '');
    valueRemoved = `00${valueRemoved.replace('.', '')}`;

    const sizeSlice = valueRemoved.length - DECIMAL_SIZE;
    const newValue = [
      valueRemoved.slice(0, sizeSlice),
      '.',
      valueRemoved.slice(sizeSlice),
    ].join('');

    onChange(Number(newValue));
  };

  return (
    <InputNumber
      data-testid="INPUT"
      value={currentValue}
      onChange={handleOnChange}
      {...props}
    />
  );
};

export { InputMoney };
