import { ItemCategoryTreeNode } from './item-category.model';

export const getListIdItemCategory = (
  itemsCategory: ItemCategoryTreeNode[],
): string[] => {
  const returnList: string[] = [];
  itemsCategory.forEach((itemCategory) => {
    if (!itemCategory.isGroup) {
      returnList.push(itemCategory.id);
    }
    if (itemCategory.children) {
      const idsChildren = getListIdItemCategory(itemCategory.children);
      idsChildren.forEach((idChildren) => {
        returnList.push(idChildren);
      });
    }
  });
  return returnList;
};
