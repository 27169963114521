import { GetOneResponse } from '@refinedev/core';
import { ChartBarDataModel } from 'common/charts';
import { MarkerType } from 'common/charts/charts.model';
import { getCurrencySymbol } from 'common/utils';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FinalProduct } from 'src/financial/reference-value/reference-value.model';
import EntitlementEnum from 'src/organization/entitlements.enum';

import { GraphicShowEnum } from '../financial-overview.enum';
import { DashboardField, ResultItem } from '../financial-overview.model';
import {
  getAreaValue,
  getBudgetTargetValue,
  getLineInfoLabel,
  getLineInfoValue,
  getLineObjectivePercentage,
  getProductivityColor,
  getProductivityLabel,
  getProductivityMarkerLabel,
  getProductivityMarkerLabelValue,
  getProductivityMarkerType,
  getProductivityMarkerValue,
  getProductivityValue,
  validateExcpectedOrPotentialProduction,
  validateMarker,
  validatePotentialProductivity,
} from './crop-cost-chart.utils';

interface CropCostChartProps {
  data?: GetOneResponse<DashboardField>;
  isFetching: boolean;
  categoryOrder: string[];
  currency: string;
  toggleReferenceValue: boolean;
  finalProduct?: FinalProduct;
  entitlements: string[];
}

const useCropCostChart = ({
  currency,
  toggleReferenceValue,
  finalProduct,
  data,
  categoryOrder,
  entitlements,
}: CropCostChartProps) => {
  const { t, i18n } = useTranslation();
  const [typeGraphic, setTypeGraphic] = useState<GraphicShowEnum>(
    GraphicShowEnum.VALUE_PER_HECTARE,
  );

  const currencySymbol: string = useMemo(
    () => getCurrencySymbol(currency),
    [currency],
  );

  const formatLineInfo = useCallback(
    (value: number) => {
      return toggleReferenceValue
        ? `${value.toLocaleString(i18n.language, {
            maximumFractionDigits: 2,
          })} ${finalProduct?.uom}`
        : value.toLocaleString(i18n.language, {
            style: 'currency',
            currency,
          });
    },
    [toggleReferenceValue, finalProduct?.uom, i18n.language, currency],
  );

  const getMoreLabel = useCallback(
    (item: ResultItem) =>
      `${Number(item.declaredArea).toFixed(2)}${t('common.business.hectare')}`,
    [t],
  );

  const dataBar: ChartBarDataModel[] | undefined = useMemo(
    () =>
      data?.data.result.map((item) => ({
        label: item.field,
        moreLabel: getMoreLabel(item),
        lineInfo: `${t(getLineInfoLabel(typeGraphic))}: ${formatLineInfo(
          getLineInfoValue(typeGraphic, item),
        )} ${getLineObjectivePercentage(t, typeGraphic, item)}`,
        groups: {
          ...(entitlements.includes(EntitlementEnum.PRODUCTION_YIELD_DATA) && {
            productivity: {
              ...(validateExcpectedOrPotentialProduction(item) && {
                values: [
                  {
                    label: t(getProductivityLabel(item)),
                    color: getProductivityColor(item),
                    dashed: !validatePotentialProductivity(item),
                    value: getProductivityValue(typeGraphic, item),
                  },
                ],
                markers: [
                  {
                    label: t(getProductivityMarkerLabel(item)),
                    type: getProductivityMarkerType(item),
                    value: getProductivityMarkerValue(typeGraphic, item),
                    labelValue: getProductivityMarkerLabelValue(
                      typeGraphic,
                      item,
                    ),
                  },
                ],
              }),
            },
          }),
          area: {
            values: categoryOrder.map((category) => ({
              label: category,
              value: getAreaValue(typeGraphic, item, category),
            })),
            ...(validateMarker(item) && {
              markers: [
                {
                  label: t('financial.expected_cost'),
                  value: getBudgetTargetValue(typeGraphic, item),
                  type: MarkerType.HOURGLASS,
                },
              ],
            }),
          },
        },
      })),
    [
      data?.data.result,
      getMoreLabel,
      t,
      typeGraphic,
      formatLineInfo,
      categoryOrder,
    ],
  );

  const handleOnChangeSelect = (value: unknown) => {
    setTypeGraphic(value as GraphicShowEnum);
  };

  const customUom = useMemo(() => {
    const referenceValueUom = toggleReferenceValue ? finalProduct?.uom : '';
    const additionalUom =
      typeGraphic === GraphicShowEnum.VALUE_PER_HECTARE
        ? `/${t('common.business.hectare')}`
        : '';
    return `${referenceValueUom}${additionalUom}`;
  }, [finalProduct?.uom, t, typeGraphic]);

  return {
    typeGraphic,
    dataBar,
    handleOnChangeSelect,
    currencySymbol: toggleReferenceValue ? '' : currencySymbol,
    customUom,
  };
};

export { useCropCostChart };
