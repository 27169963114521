import { create } from 'zustand';

import { FinalProduct } from './reference-value.model';

type ReferenceValueState = {
  toggleReferenceValue: boolean;
  finalProduct: FinalProduct | undefined;
};

type ReferenceValueActions = {
  setToggleReferenceValue: (value: boolean) => void;
  setFinalProduct: (finalProduct: FinalProduct | undefined) => void;
};

const useReferenceValueStore = create<
  ReferenceValueState & ReferenceValueActions
>((set) => {
  return {
    toggleReferenceValue: false,
    setToggleReferenceValue: (value) =>
      set(() => ({ toggleReferenceValue: value })),
    finalProduct: undefined,
    setFinalProduct: (finalProduct) => set(() => ({ finalProduct })),
  };
});

export { useReferenceValueStore };
