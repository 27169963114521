import { Form } from 'antd';
import { ChevronIcon } from 'common/icons';
import styled from 'styled-components';
import { NeutralColors } from 'theme';

interface IsOpenProps {
  isOpen: boolean;
}

const Box = styled.div`
  display: block;
  width: 100%;
  padding: 16px;
  border-radius: 8px;
  border: 1px solid ${NeutralColors[20]};
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
`;

const FormItem = styled(Form.Item)`
  width: 100%;
  margin-bottom: 16px;

  .fms-picker {
    width: 100%;
  }
`;

const Flex = styled.div`
  display: flex;
`;

const ContainerCenter = styled(Flex)`
  justify-content: space-between;
  align-items: center;
`;

const ContainerTitle = styled(ContainerCenter)<IsOpenProps>`
  margin-bottom: ${(props) => (props.isOpen ? '16px' : '0')};
`;

const ContainerValues = styled(Flex)`
  justify-content: space-between;
  align-items: flex-start;
`;

const TrashButton = styled.div`
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  border: 1px solid ${NeutralColors[10]};
  cursor: pointer;
  margin-left: 8px;

  svg {
    width: 18px;
    fill: ${NeutralColors[70]};
  }
`;

const Chevron = styled(ChevronIcon)<IsOpenProps>`
  transform: ${(props) => (props.isOpen ? 'rotate(-90deg)' : 'rotate(90deg)')};
  cursor: pointer;
  margin-right: 8px;
`;

const FormItemValue = styled(FormItem)`
  max-width: 48%;
`;

export {
  Box,
  Chevron,
  ContainerCenter,
  ContainerTitle,
  ContainerValues,
  Flex,
  FormItem,
  FormItemValue,
  TrashButton,
};
