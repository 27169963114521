import { useOne } from '@refinedev/core';
import { Typography } from 'antd';
import CollapsePanel from 'antd/es/collapse/CollapsePanel';
import { Collapse, Modal } from 'common/components';
import { ROUTES } from 'common/constants';
import { BalanceProtectorIcon } from 'common/icons';
import { useNavigateWithOrgAndFarm, useTracking } from 'common/utils';
import { useLinkWithOrgAndFarm } from 'common/utils/use-link-with-org-and-farm.hook';
import dayjs from 'dayjs';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useOrganization } from 'src/organization';

import { ExampleChildren } from './example-children/example-children.component';
import * as Styled from './migration-button.style';

const DATA_SHOW_MIGRATION = 'dateShowMigration';

interface MigrationButtonProps {
  isMobile?: boolean;
}

const MigrationButton = ({ isMobile }: MigrationButtonProps) => {
  const navigate = useNavigateWithOrgAndFarm();
  const { goToLink } = useLinkWithOrgAndFarm();
  const [openModal, setOpenModal] = useState(false);
  const { migrationFms936ButtonMigration } = useFlags();
  const { t } = useTranslation();
  const { currentOrganizationId } = useOrganization();
  const { track } = useTracking();

  const metadata = useMemo(
    () => ({
      organizationId: currentOrganizationId,
    }),
    [currentOrganizationId],
  );

  const { data, isFetched } = useOne({
    id: 'is-finished-stock-adjustments',
    resource: 'stock-comparison',
    meta: metadata,
  });

  const handleCloseModal = () => {
    track('Skip review stock');
    setOpenModal(false);
  };

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleGoToMigration = () => {
    track('Start review stock');
    isMobile ? goToLink(ROUTES.MIGRATION) : navigate(ROUTES.MIGRATION);
    handleCloseModal();
  };

  useEffect(() => {
    const lastDate = localStorage.getItem(DATA_SHOW_MIGRATION);

    if (
      (!lastDate || dayjs().diff(dayjs(lastDate), 'hour') >= 12) &&
      !data?.data &&
      migrationFms936ButtonMigration &&
      currentOrganizationId
    ) {
      setOpenModal(true);
      localStorage.setItem(DATA_SHOW_MIGRATION, dayjs().format());
    }
  }, [data, currentOrganizationId]);

  if (
    !migrationFms936ButtonMigration ||
    data?.data ||
    !currentOrganizationId ||
    !isFetched
  ) {
    return null;
  }

  return (
    <>
      <Modal
        open={openModal}
        onCancel={handleCloseModal}
        okText={t('header.migration.migration_button')}
        cancelText={t('common.later')}
        onOk={handleGoToMigration}
        isDefaultFooter
      >
        <BalanceProtectorIcon />
        <Typography.Title level={4}>
          {t('header.migration.title')}
        </Typography.Title>
        <Typography.Paragraph>
          {t('header.migration.message')}
        </Typography.Paragraph>
        <Collapse>
          <CollapsePanel header={t('header.migration.what.title')} key="1">
            <p>{t('header.migration.what.description')}</p>
          </CollapsePanel>
          <CollapsePanel header={t('header.migration.example.title')} key="2">
            <ExampleChildren />
          </CollapsePanel>
        </Collapse>
      </Modal>
      <Styled.Button onClick={handleOpenModal} isMobile={isMobile}>
        <BalanceProtectorIcon />
        {t('header.migration.migration_button')}
      </Styled.Button>
    </>
  );
};

export { MigrationButton };
