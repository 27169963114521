import 'antd/dist/reset.css';
import './i18n.config';

import { Refine } from '@refinedev/core';
import routerBindings from '@refinedev/react-router-v6';
import * as Sentry from '@sentry/react';
import { QueryClientProvider } from '@tanstack/react-query';
import { ConfigProvider, Skeleton } from 'antd';
import antPtBr from 'antd/locale/pt_BR';
import { USER_OPTIONS } from 'common/constants';
import authProvider from 'common/utils/auth-provider.util';
import { withLDProvider } from 'launchdarkly-react-client-sdk';
import { ComponentType, Suspense, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { BrowserRouter } from 'react-router-dom';
import { theme } from 'theme';

import { AuthProvider } from './auth';
import { GlobalStyle } from './global.style';
import { LAUNCH_DARKLY_CONFIG } from './launch-darkly.config';
import { OrganizationProvider } from './organization';
import { QUERY_CLIENT_CONFIG } from './query-client.config';
import { refineConfig } from './refine.config';
import notificationProvider from './refine-notification.provider';
import { RoutesBalance } from './routes-balance.component';

const AppContainer = () => {
  const { t, i18n } = useTranslation();

  const i18nProvider = {
    translate: (key: string, params: object) => t(key, params),
    changeLocale: (lang: string) => i18n.changeLanguage(lang),
    getLocale: () => i18n.language,
  };

  const themeLanguage = useMemo(() => {
    return i18n.language === USER_OPTIONS.LanguagesEnum.PtBr
      ? antPtBr
      : undefined;
  }, [i18n.language]);

  return (
    <>
      <GlobalStyle />
      <Suspense fallback={<Skeleton active />}>
        <QueryClientProvider client={QUERY_CLIENT_CONFIG}>
          <ConfigProvider theme={theme} prefixCls="fms" locale={themeLanguage}>
            <AuthProvider>
              <BrowserRouter>
                <Refine
                  dataProvider={refineConfig.dataProvider}
                  notificationProvider={notificationProvider}
                  routerProvider={routerBindings}
                  options={refineConfig.options}
                  i18nProvider={i18nProvider}
                  authProvider={authProvider}
                >
                  <OrganizationProvider>
                    <RoutesBalance />
                  </OrganizationProvider>
                </Refine>
              </BrowserRouter>
            </AuthProvider>
          </ConfigProvider>
        </QueryClientProvider>
      </Suspense>
    </>
  );
};

export default withLDProvider(LAUNCH_DARKLY_CONFIG)(
  Sentry.withProfiler(AppContainer as ComponentType<Record<string, string>>),
);
