import { Button, Tooltip, Typography } from 'antd';
import { CardButton, PageTitle } from 'common/components';
import { ROUTES } from 'common/constants';
import { AddIcon, TestingIcon } from 'common/icons';
import { useNavigateWithOrgAndFarm } from 'common/utils';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useTranslation } from 'react-i18next';
import { BlueColors } from 'theme';

import { ButtonResource } from './button-resource/button-resource.container';
import { CardTesting } from './card-testing/card-testing.component';
import { DataExport } from './data-export/data-export.component';
import { DataImportTestId } from './data-import.constant';
import { DocTypeEnum } from './data-import.enum';
import * as Styled from './data-import.style';

const { Title } = Typography;

const DataImportContainer = () => {
  const { t } = useTranslation();
  const { dataImportFms633StartImport, dataImportFms636ListResources } =
    useFlags();
  const navigate = useNavigateWithOrgAndFarm();

  const handleGoToDataImportStart = () => navigate(ROUTES.DATA_IMPORT_START);

  return (
    <>
      <PageTitle
        title={t('data_import.title')}
        description={t('data_import.description_page') ?? ''}
      />
      <Styled.ContainerFlex>
        {dataImportFms633StartImport && (
          <Tooltip
            placement="bottom"
            color={BlueColors[10]}
            title={<CardTesting />}
          >
            <CardButton
              icon={<AddIcon />}
              status={
                <Styled.TestingTag
                  icon={<TestingIcon />}
                  color={BlueColors[60]}
                >
                  {t('common.testing').toUpperCase()}
                </Styled.TestingTag>
              }
              data-testid={DataImportTestId.DATA_IMPORT_CARD_START}
              description={t('data_import.import_button.description')}
              onClick={handleGoToDataImportStart}
              button={
                <Button onClick={handleGoToDataImportStart} type="primary">
                  {t('data_import.import_button.upload_button')}
                </Button>
              }
            />
          </Tooltip>
        )}
        <DataExport />
      </Styled.ContainerFlex>
      {dataImportFms636ListResources && (
        <>
          <Styled.Separator />
          <Title level={4}>{t('data_import.imported_data.title')}</Title>
          <Styled.ContainerFlex
            data-testid={DataImportTestId.DATA_IMPORT_BUTTON_RESOURCE}
          >
            {Object.values(DocTypeEnum).map((value) => (
              <ButtonResource
                key={`button-resource-${value}`}
                docType={value}
              />
            ))}
          </Styled.ContainerFlex>
        </>
      )}
    </>
  );
};

export default DataImportContainer;
