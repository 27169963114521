import { IResourceComponentsProps } from '@refinedev/core';
import { Space, Table } from 'antd';
import { AddIcon, EditIcon } from 'common/icons';
import { RefineTableFilters } from 'common/refine-table-filters';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { ResourcesEnum } from '../resources.enum';
import { ResourcesTitle } from '../resources-title.component';
import { BankAccountModel } from './bank-account.model';
import { BankAccountCreateContainer } from './bank-account-form/bank-account-form.container';
import useBankAccountList from './bank-account-list.hook';
import * as Styled from './bank-account-list.style';

export const BankAccountListContainer: FC<IResourceComponentsProps> = () => {
  const { t } = useTranslation();

  const {
    createBankAccountDrawer,
    editDrawer,
    handleOpenBankAccountDrawer,
    listFilters,
    searchFormProps,
    tableProps,
    onRow,
    handleOnClickEditBankAccount,
    tableQueryResult,
  } = useBankAccountList();

  return (
    <>
      <BankAccountCreateContainer
        bankAccountDrawerForm={createBankAccountDrawer}
      />
      <BankAccountCreateContainer
        bankAccountDrawerForm={editDrawer}
        isEdit
        refetch={tableQueryResult.refetch}
      />
      <ResourcesTitle
        buttons={
          <Styled.CreateButton
            onClick={handleOpenBankAccountDrawer}
            icon={<AddIcon />}
            type="primary"
          >
            {t('buttons.create')}
          </Styled.CreateButton>
        }
        selectedTab={ResourcesEnum.BANK_ACCOUNT}
      />
      <RefineTableFilters
        initialListFilters={listFilters}
        searchFormProps={searchFormProps}
      />
      <Table
        {...tableProps}
        onRow={onRow}
        rowKey="id"
        pagination={{
          ...tableProps.pagination,
          showSizeChanger: true,
          pageSizeOptions: [10, 20, 50, 100],
        }}
      >
        <Table.Column dataIndex="farm" title={t('bank_account.table.farm')} />
        <Table.Column
          dataIndex="name"
          title={t('bank_account.table.name')}
          width="30%"
        />
        <Table.Column
          dataIndex="bankName"
          title={t('bank_account.table.bank')}
          width="30%"
        />
        <Table.Column
          width={120}
          title={t('bank_account.table.actions')}
          render={({ id }: BankAccountModel) => (
            <Space>
              <Styled.ViewButton
                shape="circle"
                icon={<EditIcon />}
                onClick={() => handleOnClickEditBankAccount(id)}
              />
            </Space>
          )}
        />
      </Table>
    </>
  );
};

export default BankAccountListContainer;
