import { Button, Typography } from 'antd';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 160px);

  svg {
    max-width: 100%;
    margin-bottom: 32px;
  }
`;

const Title = styled(Typography.Title)`
  text-align: center;
`;

const ButtonCropwise = styled(Button)`
  display: flex;
  margin-top: 24px;
  align-items: center;

  svg {
    margin: 0;
  }
`;

export { ButtonCropwise, Container, Title };
