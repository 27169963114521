import { Tag } from 'antd';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useOrganization } from 'src/organization';
import EntitlementEnum from 'src/organization/entitlements.enum';

import { PurchaseStatusTestId } from '../purchase-test-id.constants';
import { StatusEnum, StatusTranslationEnum } from '../status.enum';

interface PurchaseStatusProps {
  status: StatusEnum;
}

const statusColor = {
  [StatusEnum.Draft]: 'orange',
  [StatusEnum.ToBill]: 'red',
  [StatusEnum.ToReceive]: 'blue',
  [StatusEnum.ToReceiveAndBill]: 'purple',
  [StatusEnum.Cancelled]: 'default',
  [StatusEnum.Completed]: 'green',
};

export const PurchaseStatus = ({ status }: PurchaseStatusProps) => {
  const { t } = useTranslation();
  const { entitlements } = useOrganization();

  const actualStatus = useMemo(() => {
    if (!entitlements.includes(EntitlementEnum.INVOICES)) {
      switch (status) {
        case StatusEnum.ToBill:
          return StatusEnum.Completed;
        case StatusEnum.ToReceiveAndBill:
          return StatusEnum.ToReceive;
        default:
          return status;
      }
    }
    return status;
  }, [entitlements, status]);

  return (
    <Tag
      data-testid={PurchaseStatusTestId.PURCHASE_STATUS_CONTAINER}
      color={statusColor[actualStatus]}
    >
      {t(`status.${StatusTranslationEnum[actualStatus]}`)}
    </Tag>
  );
};
