import { create } from 'zustand';

type PurchaseCreateState = {
  disableSave: boolean;
  showItems: boolean;
  currency: string;
};

type PurchaseCreateActions = {
  setDisableSave: (value: boolean) => void;
  setShowItems: (value: boolean) => void;
  setCurrency: (value: string) => void;
};

const usePurchaseCreateStore = create<
  PurchaseCreateState & PurchaseCreateActions
>((set) => ({
  disableSave: false,
  setDisableSave: (value: boolean) => set(() => ({ disableSave: value })),
  showItems: false,
  setShowItems: (value: boolean) => set(() => ({ showItems: value })),
  currency: '',
  setCurrency: (value: string) => set(() => ({ currency: value })),
}));

export { usePurchaseCreateStore };
