import { ButtonProps } from 'antd';
import { ReactNode } from 'react';

import * as Styled from './selectable-button.style';

interface ButtonSelectProps extends ButtonProps {
  icon?: ReactNode;
  title: string;
  description: string;
  isSelected?: boolean;
}

export const SelectableButton = ({
  icon,
  title,
  description,
  isSelected,
  ...props
}: ButtonSelectProps) => {
  return (
    <Styled.Button
      isSelected={isSelected}
      type={isSelected ? 'primary' : 'default'}
      {...props}
    >
      {icon}
      <div>
        <Styled.Text>
          <strong>{title}</strong>
        </Styled.Text>
        <Styled.Text>{description}</Styled.Text>
      </div>
    </Styled.Button>
  );
};
