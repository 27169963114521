import { Typography } from 'antd';
import styled from 'styled-components';

const Title = styled(Typography.Text)`
  font-weight: 600;
  display: flex !important;
  align-items: center;
  margin-right: 16px;
  flex-grow: 1;
  gap: 6px;
`;

export { Title };
