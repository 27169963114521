import { Typography } from 'antd';
import styled from 'styled-components';
import { CropwiseGreen, NeutralColors } from 'theme';

interface IconBoxProps {
  isPointer: boolean;
}

const Container = styled.div`
  align-items: center;
  gap: 8px;
  display: flex;
`;

const IconBox = styled.div<IconBoxProps>`
  ${(props) => (props.isPointer ? 'cursor: pointer;' : '')}
  height: 26px;
  width: 26px;
  border: 1px solid ${NeutralColors[10]};
  border-radius: 14px;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 18px;
    fill: ${CropwiseGreen};
  }
`;

const ExternalId = styled(Typography.Text)`
  font-size: 12px;
  color: ${NeutralColors[70]};
`;

export { Container, ExternalId, IconBox };
