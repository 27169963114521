import { Button, Typography } from 'antd';
import { ChevronIcon } from 'common/icons';
import { ReactNode, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import * as Styled from './page-title.style';

interface TabsProps {
  id?: string;
  key: string;
  label: string;
  onClick?: () => void;
}

interface PageTitleProps {
  title: string;
  subTitle?: string;
  hasBackButton?: boolean;
  onBack?: () => void;
  buttons?: ReactNode;
  description?: string;
  leftIcon?: ReactNode;
  tabs?: TabsProps[];
  tabsDefaultActiveKey?: string;
}

const PageTitle = ({
  subTitle,
  title,
  buttons,
  hasBackButton,
  onBack,
  description,
  leftIcon,
  tabs,
  tabsDefaultActiveKey = '1',
}: PageTitleProps) => {
  const [selectedTab, setSelectedTab] = useState(tabsDefaultActiveKey);
  const navigate = useNavigate();

  const handleBackButton = () => {
    onBack ? onBack() : navigate(-1);
  };

  const handleClickTab = (key: string, onClick?: () => void) => {
    setSelectedTab(key);
    if (onClick) {
      onClick();
    }
  };

  return (
    <>
      <Styled.PageTitleWrapper hasBorderBottom={!!tabs}>
        <Styled.TitleWrapper>
          {leftIcon && (
            <Styled.ContainerLeftIcon>{leftIcon}</Styled.ContainerLeftIcon>
          )}
          {hasBackButton && (
            <Button
              type="text"
              shape="circle"
              size="large"
              data-testid="title-back-button"
              icon={<ChevronIcon />}
              onClick={handleBackButton}
            />
          )}
          <Typography.Title level={2}>
            {title}
            {subTitle && (
              <Styled.ComplementaryTitle>{subTitle}</Styled.ComplementaryTitle>
            )}
          </Typography.Title>
          {tabs &&
            tabs.map((tab) => (
              <Styled.ButtonTab
                id={tab.id}
                key={tab.key}
                onClick={() => handleClickTab(tab.key, tab.onClick)}
                selected={tab.key === selectedTab}
              >
                {tab.label}
              </Styled.ButtonTab>
            ))}
        </Styled.TitleWrapper>

        {buttons && <Styled.ButtonsWrapper>{buttons}</Styled.ButtonsWrapper>}
      </Styled.PageTitleWrapper>
      {description && <Styled.Description>{description}</Styled.Description>}
    </>
  );
};

export { PageTitle as default, PageTitle, TabsProps };
