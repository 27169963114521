import { Button, Select } from 'antd';
import { CardButton, Modal } from 'common/components';
import { CsvIcon, DownloadIcon, XlsxIcon } from 'common/icons';
import { useTranslation } from 'react-i18next';

import { DataImportTestId } from '../data-import.constant';
import { DocTypeEnum, FileTypeEnum } from '../data-import.enum';
import { useDataExport } from './data-export.hook';
import * as Styled from './data-export.style';

export const DataExport = () => {
  const {
    VALUE_ALL_DATA,
    docType,
    open,
    isFetching,
    fileType,
    showModal,
    hideModal,
    setFileType,
    handleOnClickDownload,
    handleOnChangeSelect,
  } = useDataExport();
  const { t } = useTranslation();

  return (
    <>
      <Modal
        open={open}
        title={t('data_import.export_button.modal.title')}
        onCancel={hideModal}
        cancelText={t('buttons.cancel')}
        onOk={handleOnClickDownload}
        okText={t('data_import.export_button.modal.ok_button')}
        width={692}
        disableOkButton={!fileType || !docType}
        data-testid={DataImportTestId.DATA_IMPORT_MODAL}
        isLoading={isFetching}
      >
        <Styled.Title>
          {t('data_import.export_button.modal.doc_type_title')}
        </Styled.Title>
        <Styled.ContainerFlex>
          <Select
            style={{ width: 220 }}
            onChange={handleOnChangeSelect}
            value={docType}
            options={[
              ...Object.values(DocTypeEnum).map((value) => ({
                label: t(`data_import.doc_type.${value}`),
                value,
              })),
              {
                label: t('data_import.export_button.all_data'),
                value: VALUE_ALL_DATA,
              },
            ]}
          />
        </Styled.ContainerFlex>
        <Styled.Title data-testid={DataImportTestId.DATA_IMPORT_MODAL_DATA}>
          {t('data_import.export_button.modal.select_title')}
        </Styled.Title>
        <Styled.ContainerFlexBetween>
          <Styled.ButtonSelectWidth
            icon={<CsvIcon />}
            title={t('data_import.export_button.modal.csv_title')}
            description={t('data_import.export_button.modal.csv_description')}
            onClick={() => setFileType(FileTypeEnum.CSV)}
            isSelected={fileType === FileTypeEnum.CSV}
          />
          <Styled.ButtonSelectWidth
            icon={<XlsxIcon />}
            title={t('data_import.export_button.modal.xlsx_title')}
            description={t('data_import.export_button.modal.xlsx_description')}
            onClick={() => setFileType(FileTypeEnum.XLSX)}
            isSelected={fileType === FileTypeEnum.XLSX}
          />
        </Styled.ContainerFlexBetween>
      </Modal>
      <CardButton
        icon={<DownloadIcon />}
        description={t('data_import.export_button.description')}
        onClick={showModal}
        data-testid={DataImportTestId.DATA_IMPORT_CARD}
        button={
          <Button onClick={showModal} type="primary">
            {t('data_import.export_button.download_button')}
          </Button>
        }
      />
    </>
  );
};
