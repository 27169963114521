import { DunutChartEmptyIcon } from 'common/icons';
import { BorderRadius } from 'src/theme/border-radius';
import styled from 'styled-components';
import { MediaQuery, Shadows } from 'theme';

const DashboardWrapper = styled.div`
  display: flex;
  gap: 16px;
  padding-bottom: 44px;
  flex-wrap: wrap-reverse;

  @media (min-width: ${MediaQuery.screenSizesCss.tablet}) {
    flex-wrap: wrap;
  }
`;

const Info = styled.div`
  width: 100%;
  flex-wrap: wrap;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 16px;
  height: fit-content;

  @media (min-width: ${MediaQuery.screenSizesCss.tablet}) {
    width: calc(100% - 336px);
  }
`;

const TotalPerHectare = styled.div`
  min-height: 100%;
`;

const ChartCard = styled.div`
  box-shadow: ${Shadows.Elevation1};
  border-radius: ${BorderRadius.Default}px;
  padding: 12px;
`;

const Category = styled(ChartCard)`
  width: 100%;

  @media (min-width: ${MediaQuery.screenSizesCss.tablet}) {
    width: 320px;
  }
`;

const CategoryTotal = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 12px;
  margin-top: 16px;
  gap: 2px;
`;

const ChartTotalValue = styled.div`
  font-size: 20px;
  font-weight: 600;
`;

const EmptyContainer = styled.div`
  margin-top: 24px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 16px;
`;

const EmptyImage = styled(DunutChartEmptyIcon)``;

const EmptyTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export {
  Category,
  CategoryTotal,
  ChartTotalValue,
  DashboardWrapper,
  EmptyContainer,
  EmptyImage,
  EmptyTextWrapper,
  Info,
  TotalPerHectare,
};
