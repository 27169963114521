import { useSelect } from '@refinedev/antd';
import { useWatch } from 'antd/es/form/Form';
import { SelectWithButton } from 'common/components';
import { CheckIcon, EditIcon, HelpIcon } from 'common/icons';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReceivedPaidWarehouseTestId } from 'src/electronic-invoice/electronic-invoice-test-id.constants';
import { PurchaseModel } from 'src/orders-and-payments/purchase';
import { useOrganization } from 'src/organization';
import { IsGroupWarehouseEnum } from 'src/stock/warehouse-list';

import * as Styled from './received-paid-warehouse.style';

interface ReceivedPaidWarehouseProps {
  purchaseOrder?: PurchaseModel;
}

const ReceivedPaidWarehouse = ({
  purchaseOrder,
}: ReceivedPaidWarehouseProps) => {
  const warehouse = useWatch(['warehouse']);
  const [openEdit, setOpenEdit] = useState(false);
  const { t } = useTranslation();
  const { currentOrganizationId } = useOrganization();
  const metadata = useMemo(
    () => ({
      organizationId: currentOrganizationId,
    }),
    [currentOrganizationId],
  );

  const { selectProps: warehouseSelectProps } = useSelect({
    optionLabel: 'name',
    optionValue: 'id',
    resource: 'warehouse',
    filters: [
      {
        field: 'farm',
        operator: 'eq',
        value: purchaseOrder?.farmName,
      },
      {
        field: 'isGroup',
        operator: 'eq',
        value: IsGroupWarehouseEnum.NO,
      },
    ],
    onSearch: (value) => [
      {
        field: 'name',
        operator: 'eq',
        value: value,
      },
    ],
    meta: metadata,
    queryOptions: {
      enabled: !!metadata.organizationId,
    },
  });

  const handleChangeOpenEdit = () => {
    setOpenEdit(!openEdit);
  };

  return (
    <Styled.WarehouseBox>
      {t('electronic_invoice.received_paid.question_warehouse')}
      <Styled.FormItem
        name={['warehouse']}
        rules={[
          {
            required: true,
          },
        ]}
      >
        {openEdit ? (
          <SelectWithButton
            {...warehouseSelectProps}
            style={{ width: 360 }}
            defaultVisible
            data-testid={
              ReceivedPaidWarehouseTestId.RECEIVED_PAID_WAREHOUSE_SELECT
            }
          />
        ) : (
          <Styled.BoxWarehouse
            isSuccess={!!warehouse}
            onClick={handleChangeOpenEdit}
          >
            {warehouse ? (
              <>
                <CheckIcon />
                {warehouse}
              </>
            ) : (
              <>
                <HelpIcon />
                {t('electronic_invoice.received_paid.click_warehouse')}
              </>
            )}
          </Styled.BoxWarehouse>
        )}
      </Styled.FormItem>
      <Styled.EditButton
        data-testid={ReceivedPaidWarehouseTestId.RECEIVED_PAID_WAREHOUSE_BUTTON}
        onClick={handleChangeOpenEdit}
      >
        {openEdit ? <CheckIcon /> : <EditIcon />}
      </Styled.EditButton>
    </Styled.WarehouseBox>
  );
};

export { ReceivedPaidWarehouse };
