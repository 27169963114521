import { useOne } from '@refinedev/core';
import { ROUTES } from 'common/constants';
import { useNavigateWithOrgAndFarm } from 'common/utils';
import dayjs from 'dayjs';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useOrganization } from 'src/organization';

const useConsumptionCalendar = (farm?: string) => {
  const { currentOrganizationId } = useOrganization();
  const navigate = useNavigateWithOrgAndFarm();
  const {
    landingMaterialConsumptionCalendar,
    landingMaterialConsumptionWithNoCost,
  } = useFlags();

  const { data: requestCalendarData, isFetching: isFetchingCalendar } = useOne({
    resource: 'report',
    id: '',
    meta: {
      organizationId: currentOrganizationId,
      params: {
        reportName: landingMaterialConsumptionCalendar,
        farm,
      },
    },
  });

  const { data: requestNoCostData, isFetching: isFetchingNoCost } = useOne({
    resource: 'report',
    id: '',
    meta: {
      organizationId: currentOrganizationId,
      params: {
        farm,
        reportName: landingMaterialConsumptionWithNoCost,
      },
    },
  });

  const toDate = dayjs().format('YYYY-MM-DD');
  const fromDate = dayjs().subtract(7, 'months').format('YYYY-MM-DD');
  const amountYears = dayjs(toDate).year() - dayjs(fromDate).year() + 1;

  const handleGoToConsumption = () => {
    navigate(ROUTES.MATERIAL_CONSUMPTION);
  };

  return {
    toDate,
    fromDate,
    requestNoCostData,
    requestCalendarData,
    isFetching: isFetchingNoCost || isFetchingCalendar,
    handleGoToConsumption,
    amountYears,
  };
};

export { useConsumptionCalendar };
