import { Button, Input } from 'antd';
import styled from 'styled-components';
import { MediaQuery, NeutralColors } from 'theme';

const Header = styled.div`
  background-color: ${NeutralColors[80]};
  height: 64px;
  padding: 16px;
  margin: -16px -16px 0;
  display: grid;
  grid-template-areas: 'return title add-on';
  grid-template-columns: 40px max-content 40px;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;

  @media (min-width: ${MediaQuery.screenSizesCss.desktop}) {
    background-color: inherit;
    display: flex;
    flex-direction: row;
    width: 500px;
  }

  .fms-typography {
    color: ${NeutralColors[0]} !important;
    margin-bottom: 0 !important;
    grid-area: title;
    justify-content: center;

    @media (min-width: ${MediaQuery.screenSizesCss.desktop}) {
      color: inherit !important;
    }
  }
`;

const Body = styled.div`
  padding: 16px;
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
`;

const SearchButton = styled(Button)`
  color: ${NeutralColors[0]};
  font-size: 16px;
  justify-self: start;
  grid-area: add-on;
  background-color: ${NeutralColors[80]};
  border-color: ${NeutralColors[80]};
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    height: 24px;
    width: 24px;
    fill: currentColor;
  }

  &:hover {
    color: ${NeutralColors[0]} !important;
    border-color: transparent !important;

    @media (min-width: ${MediaQuery.screenSizesCss.desktop}) {
      color: ${NeutralColors[80]} !important;
    }
  }
`;

const ReturnButton = styled(Button)`
  color: ${NeutralColors[0]};
  font-size: 16px;
  justify-self: start;
  grid-area: return;
  background-color: ${NeutralColors[80]};
  border-color: ${NeutralColors[80]};
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    height: 24px;
    width: 24px;
    fill: currentColor;
  }

  @media (min-width: ${MediaQuery.screenSizesCss.desktop}) {
    color: ${NeutralColors[60]};
    background-color: transparent;
    border-color: transparent;
    box-shadow: none;
  }

  &:hover {
    color: ${NeutralColors[0]} !important;
    border-color: transparent !important;

    @media (min-width: ${MediaQuery.screenSizesCss.desktop}) {
      color: ${NeutralColors[80]} !important;
    }
  }
`;

const Search = styled(Input)`
  width: calc(100% - 80px);
  position: absolute;
  z-index: 1;
  grid-area: add-on;
  justify-self: end;

  @media (min-width: ${MediaQuery.screenSizesCss.desktop}) {
    position: inherit;
    width: 300px;
    z-index: 0;
    grid-area: search;
    justify-self: end;
  }
`;

export { Body, Header, ReturnButton, Search, SearchButton };
