export const PurchaseReceiveItemTestId = {
  PURCHASE_RECEIVE_ITEM_CONTAINER: 'purchase-receive-item-container',
  PURCHASE_RECEIVE_ITEM_TRASH: 'purchase-receive-item-trash',
  PURCHASE_RECEIVE_ITEM_INPUT: 'purchase-receive-item-input',
  PURCHASE_RECEIVE_ITEM_UNIT_VALUE: 'purchase-receive-item-unit-value',
};

export const PurchaseReceiveDateTestId = {
  PURCHASE_RECEIVE_DATE_CONTAINER: 'purchase-receive-date-container',
  PURCHASE_RECEIVE_DATE_DATE_PICKER: 'purchase-receive-date-date-picker',
  PURCHASE_RECEIVE_DATE_TIME_PICKER: 'purchase-receive-date-time-picker',
};

export const PurchaseReceiveAlertTestId = {
  PURCHASE_RECEIVE_LOCKED_QTY_ALERT: 'purchase-receive-locked-qty-alert',
  PURCHASE_RECEIVE_UNIT_VALUE_ALERT: 'purchase-receive-unit-value-alert',
};

export const PurchaseReceiveItemUnitValueTestId = {
  PURCHASE_RECEIVE_ITEM_UNIT_VALUE_ENALBE_BUTTON:
    'purchase-receive-item-unit-value-enable-button',
  PURCHASE_RECEIVE_ITEM_UNIT_VALUE_EDIT_BUTTON:
    'purchase-receive-item-unit-value-edit-button',
};
