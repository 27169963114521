import { FormListFieldData, Typography } from 'antd';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { useObjectiveFormItem } from './objective-form-item.hook';
import * as Styled from './objective-form-item.style';

interface ObjectiveFormItemProps {
  item: FormListFieldData;
  index: number;
  objectiveType: 'productivity' | 'cost';
  farm?: string;
}

const ObjectiveFormItem: FC<ObjectiveFormItemProps> = ({
  item,
  index,
  objectiveType,
  farm,
}) => {
  const { t } = useTranslation();
  const { project, currentValue, uom, formatExtraValue, isFiltered } =
    useObjectiveFormItem({
      item,
      objectiveType,
      farm,
    });

  return (
    <Styled.CustomFormItem
      show={isFiltered}
      key={index}
      name={[item.name, objectiveType]}
      label={
        <Styled.LabelWrapper>
          <Typography.Text strong>{project?.projectName}</Typography.Text>{' '}
          <Typography.Text type="secondary" style={{ whiteSpace: 'nowrap' }}>
            {project?.declaredArea} ha
          </Typography.Text>
        </Styled.LabelWrapper>
      }
      extra={t(`financial.objective.form.item.extra.${objectiveType}`, {
        [objectiveType]: formatExtraValue(currentValue),
      })}
    >
      <Styled.CustomInputNumber
        size="large"
        min={0}
        decimalSeparator=","
        addonAfter={objectiveType === 'productivity' ? ` ${uom}/ha` : '/ha'}
      />
    </Styled.CustomFormItem>
  );
};

export { ObjectiveFormItem as default, ObjectiveFormItem };
