import { PlusOutlined } from '@ant-design/icons';
import { Button, Form } from 'antd';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { useInvoiceFormStore } from '../invoice-form.store';
import * as Styled from './invoice-form-items.style';
import InvoiceFormItem from './item/invoice-form-item.component';

interface InvoiceFormItemsProps {
  handleOpenItemDrawer: () => void;
}

const InvoiceFormItems: FC<InvoiceFormItemsProps> = ({
  handleOpenItemDrawer,
}) => {
  const { showItems } = useInvoiceFormStore();
  const { t } = useTranslation();

  return (
    <Styled.Container isShow={showItems}>
      <Form.List
        name="items"
        rules={[
          {
            validator: (_, names) => {
              if (!names || names.length < 1) {
                return Promise.reject(
                  new Error(
                    t('invoice.form.items.error_message_empty_items') ?? '',
                  ),
                );
              }
              return Promise.resolve();
            },
          },
        ]}
      >
        {(items, { add, remove }) => (
          <>
            {items.map((item, index) => (
              <InvoiceFormItem
                key={item.key}
                item={item}
                removeItem={() => {
                  remove(index);
                }}
                amountItems={items.length}
                index={index}
                handleOpenItemDrawer={handleOpenItemDrawer}
              />
            ))}
            <Styled.ContainerButton>
              <Styled.FormItem>
                <Button
                  type="dashed"
                  onClick={() => {
                    add();
                  }}
                  block
                  icon={<PlusOutlined />}
                >
                  {t('invoice.form.items.insert_item')}
                </Button>
              </Styled.FormItem>
            </Styled.ContainerButton>
          </>
        )}
      </Form.List>
    </Styled.Container>
  );
};

export { InvoiceFormItems as default, InvoiceFormItems };
