import { ROUTES } from 'common/constants';
import { ItemsIcon, MovementsIcon } from 'common/icons';
import { useNavigateWithOrgAndFarm } from 'common/utils';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { SelectItemsMovementsTestId } from '../stock-summary/stock-summary.constant';
import * as Styled from './select-items-movements.style';

interface SelectItemsMovementsProps {
  pageName?: string;
  isMovements?: boolean;
  farm?: string;
  warehouse?: string;
}

const SelectItemsMovements = ({
  pageName,
  isMovements,
  farm,
  warehouse,
}: SelectItemsMovementsProps) => {
  const { t } = useTranslation();
  const navigateWithOrgAndFarm = useNavigateWithOrgAndFarm();
  const navigate = useNavigate();
  const { stockFms770ShowMovements } = useFlags();

  const handleGoToMovements = () => {
    if (isMovements) {
      return;
    }
    navigateWithOrgAndFarm(
      `${ROUTES.STOCK}/${ROUTES.MOVEMENTS_STOCK}`,
      undefined,
      undefined,
      undefined,
      { pageName, farm, warehouse },
    );
  };

  const handleGoToItems = () => {
    if (!isMovements) {
      return;
    }
    navigate(-1);
  };

  if (!stockFms770ShowMovements) {
    return null;
  }

  return (
    <Styled.FormItem>
      <Styled.ButtonIcon
        isFirst
        value="large"
        icon={<ItemsIcon />}
        type={isMovements ? undefined : 'primary'}
        size="large"
        onClick={handleGoToItems}
        data-testid={
          SelectItemsMovementsTestId.SELECT_ITEMS_MOVEMENTS_ITEM_BUTTON
        }
      >
        {t('stock.select_items_movements.button_items')}
      </Styled.ButtonIcon>
      <Styled.ButtonIcon
        icon={<MovementsIcon />}
        isLast
        value="default"
        size="large"
        type={isMovements ? 'primary' : undefined}
        onClick={handleGoToMovements}
        data-testid={
          SelectItemsMovementsTestId.SELECT_ITEMS_MOVEMENTS_MOVEMENTS_BUTTON
        }
      >
        {t('stock.select_items_movements.button_movements')}
      </Styled.ButtonIcon>
    </Styled.FormItem>
  );
};

export { SelectItemsMovements };
