import { track } from '@amplitude/analytics-browser';
import { useDrawerForm } from '@refinedev/antd';
import { HttpError, IResourceComponentsProps } from '@refinedev/core';
import { Space, Table } from 'antd';
import { AddIcon, EditIcon } from 'common/icons';
import { FC, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { ResourcesEnum } from '../resources.enum';
import { ResourcesTitle } from '../resources-title.component';
import { ItemCategoryFormContainer } from './form';
import { useItemCategoryTreeNodes } from './item-category.hooks';
import { ItemCategory, ItemCategoryTreeNode } from './item-category.model';
import * as Styled from './item-category.style';
import CategoryName from './name/item-category-name.component';
import { useItemCategoryCreateDrawer } from './use-item-category-create-drawer.hook';

const ItemCategoryContainer: FC<IResourceComponentsProps> = () => {
  const { t } = useTranslation();
  const {
    data: tableData,
    isLoading: isLoadingTableData,
    refetch: itemCategoryRefetch,
    isFetchingValidate,
    listValidate,
  } = useItemCategoryTreeNodes();

  const hasData = useMemo(() => tableData.length > 0, [tableData]);

  const { createItemCategoryDrawer, handleOpenItemCategoryDrawer: createShow } =
    useItemCategoryCreateDrawer('item-category', itemCategoryRefetch);

  const editItemCategoryDrawer = useDrawerForm<
    ItemCategory,
    HttpError,
    ItemCategory
  >({
    onMutationSuccess: () => {
      itemCategoryRefetch();
    },
    action: 'edit',
    resource: 'item-category',
    redirect: false,
  });

  const handleOnClickCreateItemCategory = () => {
    track('Start Item Category Creation');
    createShow();
  };

  const handleOnClickEditItemCategory = useCallback(
    (id: string) => {
      track('Start Item Category Edition');
      editItemCategoryDrawer.show(id);
    },
    [editItemCategoryDrawer.show],
  );

  return (
    <Styled.Wrapper>
      <ResourcesTitle
        buttons={
          <Styled.CreateButton
            onClick={handleOnClickCreateItemCategory}
            icon={<AddIcon />}
            type="primary"
          >
            {t('buttons.create')}
          </Styled.CreateButton>
        }
        selectedTab={ResourcesEnum.ITEM_CATEGORY}
      />
      <Table
        dataSource={tableData}
        expandable={{ defaultExpandAllRows: true }}
        rowKey="id"
        pagination={false}
        loading={!hasData && isLoadingTableData}
      >
        <Table.Column
          title={t('item_category.table.name')}
          render={(data: ItemCategoryTreeNode) => (
            <CategoryName
              itemCategory={data}
              isFetching={isFetchingValidate}
              dataValidate={listValidate?.find(
                (validate) => validate.id === data.id,
              )}
            />
          )}
        />
        <Table.Column
          title={t('item_category.table.actions')}
          render={({ id }) => (
            <Space>
              <Styled.EditButton
                onClick={() => handleOnClickEditItemCategory(id)}
                icon={<EditIcon />}
              >
                {t('item_category.table.edit')}
              </Styled.EditButton>
            </Space>
          )}
        />
      </Table>
      <ItemCategoryFormContainer
        itemCategoryDrawerForm={createItemCategoryDrawer}
      />
      <ItemCategoryFormContainer
        itemCategoryDrawerForm={editItemCategoryDrawer}
      />
    </Styled.Wrapper>
  );
};

export { ItemCategoryContainer as default, ItemCategoryContainer };
