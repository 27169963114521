import { useDrawerForm } from '@refinedev/antd';
import { HttpError, useCreate, useOne } from '@refinedev/core';
import { useTracking } from 'common/utils';
import { useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { PurchaseDetailModel } from 'src/orders-and-payments/purchase';
import { useOrganization } from 'src/organization';
import { ItemModel } from 'src/resources/item';

import { ElectronicInvoiceStepEnum } from '../electronic-invoice.enum';
import {
  ElectronicInvoiceModel,
  ElectronicInvoicesWithOrderRequest,
} from '../electronic-invoice.model';

const useElectronicInvoiceLink = () => {
  const { track } = useTracking();
  const [currentStep, setCurrentStep] = useState<ElectronicInvoiceStepEnum>(
    ElectronicInvoiceStepEnum.LINK_ITEMS,
  );
  const location = useLocation();
  const {
    mutate: mutateCreate,
    isLoading: isLoadingCreate,
    data: dataCreate,
  } = useCreate();
  const [isLoading, setIsLoading] = useState(false);
  const [initialValues, setInitialValues] =
    useState<ElectronicInvoicesWithOrderRequest>();
  const { currentOrganizationId } = useOrganization();
  const [idsElectronicInvoice, setIdsElectronicInvoice] = useState<string[]>(
    [],
  );
  const [purchaseOrderId, setPurchaseOrderId] = useState<string>();
  const [resourceCreate, setResourceCreate] = useState('item');

  const {
    data: electronicInvoices,
    mutate,
    isLoading: isFetching,
  } = useCreate<ElectronicInvoiceModel[]>();

  const createUseDrawerForm = useDrawerForm<ItemModel, HttpError, ItemModel>({
    action: 'create',
    resource: resourceCreate,
    redirect: false,
    dataProviderName: resourceCreate === 'item' ? 'default' : 'defaultV2',
    syncWithLocation: {
      key: 'create',
    },
  });

  const { data: purchaseOrder } = useOne<PurchaseDetailModel>({
    resource: 'purchase-order',
    id: purchaseOrderId,
    queryOptions: {
      enabled: !!purchaseOrderId,
    },
  });

  useEffect(() => {
    if (location?.state?.idsElectronicInvoice) {
      setIdsElectronicInvoice(location?.state?.idsElectronicInvoice);
    }
    if (location?.state?.purchaseOrderId) {
      setPurchaseOrderId(location?.state?.purchaseOrderId);
    }
  }, [location?.state]);

  useEffect(() => {
    track('Electronic Invoice - Start Import');
    mutate({
      values: {
        ids: location?.state?.idsElectronicInvoice ?? idsElectronicInvoice,
      },
      resource: `electronic-invoice/find-by-ids`,
      meta: {
        params: {
          ...metadata,
        },
      },
      successNotification: false,
    });
  }, []);

  useEffect(() => {
    if (dataCreate) {
      setCurrentStep(ElectronicInvoiceStepEnum.FINISH);
    }
  }, [dataCreate]);

  const metadata = useMemo(
    () => ({
      organizationId: currentOrganizationId,
    }),
    [currentOrganizationId],
  );

  useEffect(() => {
    setIsLoading(isFetching);
  }, [isFetching]);

  useEffect(() => {
    setIsLoading(isLoadingCreate);
  }, [isLoadingCreate]);

  const totalValue = useMemo(() => {
    return electronicInvoices?.data?.reduce(
      (previousValue: number, currentValue) => {
        return previousValue + currentValue.totalValue;
      },
      0,
    );
  }, [electronicInvoices]);

  useEffect(() => {
    if (electronicInvoices && purchaseOrder) {
      const currentInitialValues: ElectronicInvoicesWithOrderRequest = {
        orderId: purchaseOrder.data.id,
        sourceCnpjCpf: electronicInvoices.data[0]?.sourceCnpjCpf ?? '',
        supplier: purchaseOrder.data.supplier,
        electronicInvoices: electronicInvoices.data.map(
          (electronicInvoice) => ({
            ...electronicInvoice,
            isPaid: false,
            isReceived: true,
            name: electronicInvoice.id,
            items:
              electronicInvoice.items?.map((item) => ({
                ...item,
                valuationRateInvoice: item.valuationRate,
                qtyInvoice: item.qty,
              })) ?? [],
          }),
        ),
      };

      setInitialValues(currentInitialValues);
    }
  }, [electronicInvoices, purchaseOrder]);

  const onFinish = (values: ElectronicInvoicesWithOrderRequest) => {
    track('Electronic Invoice - Finish Import');
    mutateCreate({
      resource: 'electronic-invoice/match/with-order',
      values: {
        ...values,
        orderId: purchaseOrder?.data?.id,
        sourceCnpjCpf: electronicInvoices?.data[0]?.sourceCnpjCpf ?? '',
        supplier: purchaseOrder?.data?.supplier,
        electronicInvoices: values.electronicInvoices.map(
          (currentElectronicInvoice) => ({
            ...currentElectronicInvoice,
            warehouse: values.warehouse,
          }),
        ),
      },
      meta: metadata,
      successNotification: false,
    });
  };

  const handleOpenCreateItem = () => {
    createUseDrawerForm.show();
  };

  return {
    currentStep,
    createUseDrawerForm,
    initialValues,
    amountItems: electronicInvoices?.data?.length,
    totalValue,
    isLoading,
    purchaseOrder: purchaseOrder?.data,
    purchaseOrderId,
    electronicInvoices: electronicInvoices?.data,
    onFinish,
    setIsLoading,
    setResourceCreate,
    handleOpenCreateItem,
    setCurrentStep,
  };
};

export { useElectronicInvoiceLink };
