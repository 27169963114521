import { forwardRef, useImperativeHandle, useRef, useState } from 'react';

import ChartCustomTooltip from '../chart-custom-tooltip/chart-custom-tooltip.component';
import * as Styled from './chart-custom-tooltip-ref.style';

interface TooltipData {
  label: string;
  value: number;
  color: string;
  valueFormat: (value: number) => string;
}

export interface ChartCustomTooltipRefProps {
  setTooltipData: (data: TooltipData) => void;
  transform: (xPos: number, yPos: number) => void;
  display: () => void;
  hide: () => void;
}

const ChartCustomTooltipRef = forwardRef<ChartCustomTooltipRefProps>(
  (props, ref) => {
    const [tooltipData, setTooltipData] = useState<TooltipData | null>(null);
    const refWrapper = useRef<HTMLDivElement>(null);

    useImperativeHandle(ref, () => ({
      setTooltipData: (data: TooltipData) => {
        setTooltipData(data);
      },
      transform: (xPos, yPos) => {
        if (refWrapper.current)
          refWrapper.current.style.transform = `translate(${xPos}px, ${yPos}px)`;
      },
      display: () => {
        if (refWrapper.current) refWrapper.current.style.display = 'block';
      },
      hide: () => {
        if (refWrapper.current) refWrapper.current.style.display = 'none';
      },
    }));

    const { label, value, color, valueFormat } = tooltipData ?? {};

    return (
      <Styled.RefWrapper ref={refWrapper}>
        <ChartCustomTooltip
          id={label ?? ''}
          formattedValue={valueFormat && value ? valueFormat(value) : ''}
          index={0}
          indexValue={0}
          data={{ id: label ?? '' }}
          hidden={false}
          value={value ?? 0}
          color={color ?? ''}
          label={label ?? ''}
          valueFormat={valueFormat ?? (() => '')}
        />
      </Styled.RefWrapper>
    );
  },
);

ChartCustomTooltipRef.displayName = 'ChartCustomTooltipRef';

export { ChartCustomTooltipRef, ChartCustomTooltipRef as default };
