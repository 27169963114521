import { GroupedWarehouseModel, WarehouseModel } from './warehouse-list.model';

const groupByFarm = (arr: WarehouseModel[]): GroupedWarehouseModel => {
  return arr.reduce((result: GroupedWarehouseModel, item: WarehouseModel) => {
    const { farm } = item;
    if (!result[farm]) {
      result[farm] = [];
    }
    result[farm].push(item);
    return result;
  }, {});
};

const moveToFirst = (
  groupedData: GroupedWarehouseModel,
  groupName: string,
): GroupedWarehouseModel => {
  if (groupedData[groupName]) {
    const groupData = groupedData[groupName];
    delete groupedData[groupName];
    groupedData = { [groupName]: groupData, ...groupedData };
  }

  return groupedData;
};

export { groupByFarm, moveToFirst };
