const valueToPercent = (value: number, total: number, language = 'pt-BR') => {
  const percentage = value / total;
  const localeOptions = {
    style: 'percent',
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
    useGrouping: false,
  };

  return `${percentage.toLocaleString(language, localeOptions)}`;
};

export { valueToPercent };
