import { Space } from 'antd';
import { FormItem } from 'common/components';
import { SelectAccount } from 'common/components/selects/select-account/select-account.container';
import { CalendarIcon } from 'common/icons';
import { useTranslation } from 'react-i18next';

import { PurchasePaymentDateTestId } from '../purchase-payment.constants';
import * as Styled from './purchase-payment-info.style';

interface PurchasePaymentDateProps {
  handleOpenBankAccountDrawer: () => void;
}

const DATE_FORMAT = 'DD/MM/YYYY';

export const PurchasePaymentInfo = ({
  handleOpenBankAccountDrawer,
}: PurchasePaymentDateProps) => {
  const { t } = useTranslation();

  return (
    <Space direction="vertical" style={{ width: '100%' }}>
      <Styled.Space
        size={4}
        data-testid={PurchasePaymentDateTestId.PURCHASE_PAYMENT_DATE_CONTAINER}
      >
        <Styled.IconWrapper>
          <CalendarIcon />
        </Styled.IconWrapper>
        {t('purchase_payment.date.paid_on')}
        <Styled.FormItem name={['postingDate']}>
          <Styled.DatePicker
            format={DATE_FORMAT}
            suffixIcon
            data-testid={
              PurchasePaymentDateTestId.PURCHASE_PAYMENT_DATE_DATE_PICKER
            }
          />
        </Styled.FormItem>
        {t('purchase_payment.date.at')}
        <Styled.FormItem name={['postingTime']}>
          <Styled.TimePicker
            suffixIcon
            data-testid={
              PurchasePaymentDateTestId.PURCHASE_PAYMENT_DATE_TIME_PICKER
            }
          />
        </Styled.FormItem>
      </Styled.Space>
      <SelectAccount
        handleOpenBankAccountDrawer={handleOpenBankAccountDrawer}
      />
      <FormItem name={['id']} hidden />
      <FormItem name={['farm']} hidden />
      <FormItem name={['paymentAccountType']} hidden />
    </Space>
  );
};
