import styled from 'styled-components';

const ContainerFlex = styled.div`
  display: flex;
  gap: 16px;

  div {
    width: 100%;
  }
`;

export { ContainerFlex };
