import { Typography } from 'antd';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: calc(100vh - 180px);
`;

const CentralizedContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  max-width: 480px;
  text-align: center;

  svg {
    margin-bottom: 16px;
  }
`;

const Subtitle = styled(Typography.Paragraph)`
  font-size: 16px;
  margin: 0;
`;

export { CentralizedContainer, Container, Subtitle };
