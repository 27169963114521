import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

import * as Styled from './date-column.style';

interface DateColumnProps {
  date?: string;
}

const DateColumn = ({ date }: DateColumnProps) => {
  const { i18n } = useTranslation();
  if (!date) {
    return null;
  }

  return (
    <Styled.Date>
      <Styled.Day>{dayjs(date)?.format('DD')}</Styled.Day>
      {` ${dayjs(date).locale(i18n.language).format('MMM')}, ${dayjs(
        date,
      )?.format('YY')}`}
    </Styled.Date>
  );
};

export { DateColumn };
