/* eslint-disable @typescript-eslint/no-explicit-any */
import { Rule } from 'antd/es/form';
import { ErrorCircleOutlined } from 'common/icons';

import { SelectWithComplementaryLabelProps } from '../select-with-complementary-label';
import { SelectTagTestId } from './select-tag.contants';
import * as Styled from './select-tag.style';

interface SelectTagProps extends SelectWithComplementaryLabelProps {
  Icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  children: string;
  labelSelect?: string;
  nameSelect?: Array<number | string>;
  isOpenSelect?: boolean;
  errorMessage?: string;
  hidden?: boolean;
  rules?: Rule[];
}

interface BaseRule {
  required?: boolean;
}

export const SelectTag = ({
  Icon,
  children,
  isOpenSelect,
  nameSelect,
  labelSelect,
  errorMessage,
  rules,
  hidden,
  ...props
}: SelectTagProps) => {
  const required = rules ? (rules[0] as BaseRule).required : false;

  return (
    <div>
      <Styled.Label hidden={hidden}>
        {required && <Styled.TextRequired>{'* '}</Styled.TextRequired>}
        {labelSelect}
      </Styled.Label>
      <Styled.Container
        isOpenSelect={isOpenSelect}
        data-testid={SelectTagTestId.SELECT_TAG_CONTAINER}
        hidden={hidden}
      >
        <Icon style={{ display: hidden ? 'none' : 'block' }} />
        <Styled.FormItem
          name={nameSelect}
          data-testid={SelectTagTestId.SELECT_TAG_FORM_ITEM}
          rules={rules}
          hidden={hidden}
        >
          <Styled.Select {...(props as any)} isError={!children} />
        </Styled.FormItem>
      </Styled.Container>

      {errorMessage && !children && !hidden && (
        <Styled.ContainerError>
          <ErrorCircleOutlined />
          {errorMessage}
        </Styled.ContainerError>
      )}
    </div>
  );
};
