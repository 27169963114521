import { LoadingOutlined } from '@ant-design/icons';
import { Tooltip, Typography } from 'antd';
import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import {
  ItemCategoryTreeNode,
  ItemCategoryValidation,
} from '../item-category.model';
import * as Styled from './item-category-name.style';

interface ItemCategoryTitleProps {
  isFetching: boolean;
  dataValidate?: ItemCategoryValidation;
  itemCategory: ItemCategoryTreeNode;
}

const ItemCategoryName: FC<ItemCategoryTitleProps> = ({
  itemCategory,
  isFetching,
  dataValidate,
}) => {
  const { t } = useTranslation();

  const ValidateIcon = useMemo(() => {
    if (isFetching) {
      return <LoadingOutlined style={{ fontSize: 16 }} spin rev={undefined} />;
    }
    if (!dataValidate) {
      return;
    }

    if (dataValidate.isValid) {
      return <Styled.ItemCategoryCheckIcon />;
    }

    return (
      <Tooltip title={t('item_category.alert_tooltip')}>
        <Styled.ItemCategoryAlertIcon />
      </Tooltip>
    );
  }, [dataValidate, isFetching, t]);

  return (
    <Styled.ItemCategoryNameContainer>
      {ValidateIcon}
      <Typography>{itemCategory.name}</Typography>
    </Styled.ItemCategoryNameContainer>
  );
};

export { ItemCategoryName as default, ItemCategoryName };
