import { Tag } from 'antd';
import styled from 'styled-components';
import { NeutralColors } from 'theme';

const Container = styled.div`
  padding: 16px;
`;

const TestingTag = styled(Tag)`
  display: flex;
  align-items: center;
  padding: 0px 8px;
  height: 24px;
  font-weight: bold;
  margin: 0;
  width: min-content;
  margin-bottom: 8px;

  svg {
    margin-right: 4px;
    fill: ${NeutralColors[0]};
  }
`;

export { Container, TestingTag };
