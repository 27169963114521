import { Button as ButtonAntd } from 'antd';
import styled from 'styled-components';
import { BlueColors } from 'theme';

interface ButtonProps {
  isMobile?: boolean;
}

const Button = styled(ButtonAntd)<ButtonProps>`
  display: flex;
  align-items: center;
  background-color: ${BlueColors[10]};
  border: 1px solid ${BlueColors[30]};
  height: 40px;
  margin: ${(props) => (props.isMobile ? '16px auto' : '0')};

  svg {
    margin-right: 8px;
  }
`;

export { Button };
