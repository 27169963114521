import styled from 'styled-components';
import { BlueColors, MediaQuery, NeutralColors } from 'theme';

interface ButtonTabProps {
  selected?: boolean;
}

interface PageTitleWrapperProps {
  hasBorderBottom: boolean;
}

const PageTitleWrapper = styled.div<PageTitleWrapperProps>`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 16px;
  padding: 8px 0;
  margin-bottom: 8px;
  border-bottom: ${(props) =>
    props.hasBorderBottom ? `1px solid ${NeutralColors[20]}` : 'none'};

  @media (min-width: ${MediaQuery.screenSizesCss.tablet}) {
    gap: 24px;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 16px;
  }
`;

const TitleWrapper = styled.div`
  flex-grow: 1;
  display: flex;
  gap: 16px;
  align-items: center;
  flex-wrap: wrap;

  h2 {
    width: 100%;
    margin: 0 16px 0 0;
    display: flex;
    flex-wrap: wrap;

    @media (min-width: ${MediaQuery.screenSizesCss.tablet}) {
      width: auto;
    }
  }
`;

const ComplementaryTitle = styled.span`
  color: ${NeutralColors[60]};
  margin-left: 20px;
  display: flex;
  align-items: center;
`;

const ButtonsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  max-width: 100%;
  flex-wrap: wrap-reverse;
  justify-content: flex-end;
  gap: 8px;
  flex-grow: 1;

  @media (min-width: ${MediaQuery.screenSizesCss.tablet}) {
    justify-self: flex-end;
    flex-wrap: nowrap;
  }
`;

const Description = styled.h4`
  margin-top: -16px;
  margin-bottom: 16px;
`;

const ContainerLeftIcon = styled.div`
  margin-right: 8px;
`;

const ButtonTab = styled.p<ButtonTabProps>`
  color: ${(props) => (props.selected ? BlueColors[60] : NeutralColors[80])};
  border-bottom: ${(props) =>
    props.selected ? `2px solid ${BlueColors[60]}` : `2px solid transparent`};
  cursor: pointer;
  font-size: 16px;
  margin: 16px 8px 0 0 !important;
  padding: 0 0 8px 0;
  font-weight: ${(props) => (props.selected ? '600' : '400')};

  :hover {
    color: ${BlueColors[60]};
  }

  @media (min-width: ${MediaQuery.screenSizesCss.tablet}) {
    margin: 0 0 -20px 0 !important;
    padding: 0 8px 16px 8px;
  }
`;

export {
  ButtonsWrapper,
  ButtonTab,
  ComplementaryTitle,
  ContainerLeftIcon,
  Description,
  PageTitleWrapper,
  TitleWrapper,
};
