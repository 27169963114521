import { DatePicker, Form, Select, Skeleton, Switch } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import { AutoSingleSelect } from 'common/components';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useMaterialConsumptionFormStore } from '../material-consumption-form.store';
import useMaterialConsumptionFormDetail from './material-consumption-form-detail.hook';
import * as Styled from './material-consumption-form-detail.style';

interface MaterialConsumptionFormDetailProps {
  drawerOpen: boolean;
}

const MaterialConsumptionFormDetail = ({
  drawerOpen,
}: MaterialConsumptionFormDetailProps) => {
  const { t } = useTranslation();
  const {
    handleOnChangeFarm,
    isCropZone,
    farmSelectProps,
    projectSelectProps,
    projectQueryResult,
    seasonSelectProps,
    isLoadingSeason,
  } = useMaterialConsumptionFormDetail(drawerOpen);
  const { showItems } = useMaterialConsumptionFormStore();
  const [firstFetchProjectSelect, setFirstFetchProjectSelect] = useState(true);

  useEffect(() => {
    if (projectQueryResult?.isFetched) {
      setFirstFetchProjectSelect(false);
    }
  }, [projectQueryResult?.isFetched]);

  return (
    <Styled.Box show={!showItems}>
      <Form.Item name={['costCenter']} hidden />
      <Form.Item
        label={t('common.date')}
        name={['postingDate']}
        rules={[
          {
            required: true,
          },
        ]}
      >
        <DatePicker format={'DD/MM/YYYY'} />
      </Form.Item>
      <Form.Item
        label={t('common.business.farm')}
        name={['farm']}
        rules={[
          {
            required: true,
          },
        ]}
      >
        <AutoSingleSelect
          {...farmSelectProps}
          onChange={handleOnChangeFarm}
          placeholder={t('common.business.farm_placeholder')}
        />
      </Form.Item>
      <Form.Item
        label={t('common.business.season')}
        name={['seasonId']}
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Select
          {...seasonSelectProps}
          loading={isLoadingSeason}
          placeholder={t('common.business.season_placeholder')}
        />
      </Form.Item>
      <Form.Item
        label={t('common.business.description')}
        name={['description']}
        rules={[{}]}
      >
        <TextArea
          placeholder={
            t('material_consumption_form.details.description_placeholder') ?? ''
          }
          count={{ show: true, max: 100 }}
        />
      </Form.Item>
      <Styled.DashedBox>
        <Styled.SwitchWrapper>
          {t('invoice.form.details.is_crop_zone')}
          <Form.Item name={['isCropZone']} valuePropName={'checked'}>
            <Switch
              checked={isCropZone}
              checkedChildren={t('buttons.yes')}
              unCheckedChildren={t('buttons.no')}
            />
          </Form.Item>
        </Styled.SwitchWrapper>
        {isCropZone && (
          <Styled.FormItem
            label={t('common.business.crop_zone')}
            name={['project']}
            rules={[
              {
                required: true,
              },
            ]}
          >
            {firstFetchProjectSelect ? (
              <Skeleton.Input style={{ width: 200 }} active />
            ) : (
              <Select
                {...projectSelectProps}
                allowClear
                mode="multiple"
                loading={projectQueryResult?.isLoading}
                placeholder={t('common.business.crop_zone_placeholder')}
              />
            )}
          </Styled.FormItem>
        )}
      </Styled.DashedBox>
    </Styled.Box>
  );
};

export { MaterialConsumptionFormDetail };
