import { ShoppingCartColoredIcon } from 'common/icons';
import { ElectronicInvoiceLoadingTestId } from 'src/electronic-invoice/electronic-invoice-test-id.constants';

import * as Styled from './electronic-invoice-loading.style';

const ElectronicInvoiceLoading = () => {
  return (
    <Styled.Container
      data-testid={
        ElectronicInvoiceLoadingTestId.ELECTRONIC_INVOICE_LOADING_CONTAINER
      }
    >
      <ShoppingCartColoredIcon />
      <Styled.BoxLoading>
        <img src="./loading.gif" alt="" />
      </Styled.BoxLoading>
    </Styled.Container>
  );
};

export { ElectronicInvoiceLoading };
