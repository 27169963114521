import styled from 'styled-components';
import { MediaQuery, NeutralColors } from 'theme';

const Card = styled.div`
  display: grid;
  grid-template-columns: fit-content(100%) fit-content(100%);
  align-items: center;
  padding: 8px 16px;
  gap: 10px;
  height: 64px;
  background: ${NeutralColors[0]};
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  cursor: pointer;
  width: 100%;

  @media (min-width: ${MediaQuery.screenSizesCss.tablet}) {
    max-width: 300px;
  }

  & .fms-typography {
    margin-bottom: 0 !important;
  }
`;

export { Card };
