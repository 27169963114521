import { ReactNode } from 'react';

import * as Styled from './chart-title.style';

interface ChartTitleProps {
  icon: ReactNode;
  text: string;
}

const ChartTitle = ({ icon, text }: ChartTitleProps) => {
  return (
    <Styled.Title ellipsis={true}>
      {icon} {text}
    </Styled.Title>
  );
};

export { ChartTitle, type ChartTitleProps, ChartTitle as default };
