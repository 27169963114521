import { ROUTES } from 'common/constants';
import {
  BankAccountIcon,
  BarcodeIcon,
  BoxIcon,
  CalendarClockIcon,
  CategoryIcon,
  DashboardIcon,
  DataImportIcon,
  FinancialIcon,
  FinancialOverviewIcon,
  FinancialProductionIcon,
  HelpCenterIcon,
  ItemsIcon,
  MaterialConsumptionIcon,
  PurchaseIcon,
  ReportIcon,
  ResourcesIcon,
  StockIcon,
  SupplierIcon,
} from 'common/icons';
import { ActionRbacEnum } from 'common/utils/auth-provider.util';
import EntitlementEnum from 'src/organization/entitlements.enum';

import { FeatureItemModel } from './menu-item.model';

enum FeatureItemKeys {
  DASHBOARD = 'dashboard',
  ORDERS_AND_PAYMENTS = 'orders_and_payments',
  STOCK = 'stock',
  MATERIAL_CONSUMPTION = 'material_consumption',
  REPORT_GENERAL_LEDGER = 'report_general_ledger',
  FINANCIAL = 'financial',
  RESOURCES = 'resources',
  DATA_IMPORT = 'data_import',
  HELP_CENTER = 'help_center',
}

enum ResourcesItemKeys {
  ITEM = 'item',
  ITEM_CATEGORY = 'item_category',
  SUPPLIER = 'supplier',
  BANK_ACCOUNT = 'bank_account',
}

enum FinancialItemKeys {
  OVERVIEW = 'overview',
  PRODUCTION = 'production',
}

enum OrdersAndPaymentsItemKeys {
  PURCHASE = 'purchase',
  INVOICE = 'invoice',
  ACCOUNTS_PAYABLE = 'accounts_payable',
}

const featureListData: Record<FeatureItemKeys, FeatureItemModel> = {
  [FeatureItemKeys.DASHBOARD]: {
    key: FeatureItemKeys.DASHBOARD,
    title: 'dashboard.title',
    description: 'data_import.description',
    icon: <DashboardIcon />,
    navigate_route: ROUTES.DASHBOARD,
    feature_flag_disable: 'dashboardGuerillaFms',
    hide_on_mobile: false,
    entitlement: EntitlementEnum.LANDING_PAGE,
  },
  [FeatureItemKeys.ORDERS_AND_PAYMENTS]: {
    key: FeatureItemKeys.ORDERS_AND_PAYMENTS,
    title: 'orders_and_payments.title',
    description: 'orders_and_payments.description',
    icon: <PurchaseIcon />,
    feature_flag_disable: 'ordersAndPaymentsDisableMenuAccess',
    children: [
      {
        key: OrdersAndPaymentsItemKeys.ACCOUNTS_PAYABLE,
        title: 'accounts_payable.title',
        description: 'accounts_payable.description',
        icon: <CalendarClockIcon />,
        navigate_route: ROUTES.ACCOUNTS_PAYABLE,
        feature_flag_disable: 'accountsPayableDisableMenuAccess',
        entitlement: EntitlementEnum.ACCOUNTS_PLAYABLE,
      },
      {
        key: OrdersAndPaymentsItemKeys.PURCHASE,
        title: 'purchase.title',
        description: 'purchase.description',
        icon: <BoxIcon />,
        navigate_route: ROUTES.PURCHASE,
        feature_flag_disable: 'purchaseDisableMenuAccess',
      },
      {
        key: OrdersAndPaymentsItemKeys.INVOICE,
        title: 'invoice.title',
        description: 'invoice.description',
        icon: <BarcodeIcon />,
        navigate_route: ROUTES.INVOICE,
        feature_flag_disable: 'invoiceDisableMenuAccess',
        entitlement: EntitlementEnum.INVOICES,
      },
    ],
  },
  [FeatureItemKeys.STOCK]: {
    key: FeatureItemKeys.STOCK,
    title: 'stock.title',
    description: 'stock.description',
    icon: <StockIcon />,
    navigate_route: ROUTES.STOCK,
    feature_flag_disable: 'stockDisableMenuAccess',
    permission: ActionRbacEnum.STOCK_BALANCE_READ,
  },
  [FeatureItemKeys.REPORT_GENERAL_LEDGER]: {
    key: FeatureItemKeys.REPORT_GENERAL_LEDGER,
    title: 'report_general_ledger.title',
    description: 'report_general_ledger.description',
    icon: <ReportIcon />,
    navigate_route: ROUTES.REPORT_GENERAL_LEDGER,
    feature_flag_disable: 'generalLedgerDisableMenuAccess',
    permission: ActionRbacEnum.FINANCIAL_BALANCE_READ,
    entitlement: EntitlementEnum.REPORT_GENERAL_LEDGER,
  },
  [FeatureItemKeys.HELP_CENTER]: {
    key: FeatureItemKeys.HELP_CENTER,
    title: 'help_center.title',
    description: 'help_center.description',
    icon: <HelpCenterIcon />,
    navigate_route: ROUTES.HELP_CENTER,
    feature_flag_disable: 'helpCenterDisableMenuAccess',
    hide_on_desktop: true,
  },
  [FeatureItemKeys.MATERIAL_CONSUMPTION]: {
    key: FeatureItemKeys.MATERIAL_CONSUMPTION,
    title: 'material_consumption.title',
    description: 'material_consumption.description',
    icon: <MaterialConsumptionIcon />,
    navigate_route: ROUTES.MATERIAL_CONSUMPTION,
    feature_flag_disable: 'materialConsumptionDisableMenuAccess',
  },
  [FeatureItemKeys.FINANCIAL]: {
    key: FeatureItemKeys.FINANCIAL,
    title: 'financial.title',
    description: 'financial.description',
    icon: <FinancialIcon />,
    feature_flag_disable: 'financialDisableMenuAccess',
    permission: ActionRbacEnum.FINANCIAL_BALANCE_READ,
    children: [
      {
        key: FinancialItemKeys.OVERVIEW,
        title: 'financial.overview.title',
        description: 'financial.description',
        icon: <FinancialOverviewIcon />,
        navigate_route: ROUTES.FINANCIAL,
        feature_flag_disable: 'financialDisableMenuAccess',
        permission: ActionRbacEnum.FINANCIAL_BALANCE_READ,
      },
      {
        key: FinancialItemKeys.PRODUCTION,
        title: 'financial.production',
        description: 'financial.description',
        icon: <FinancialProductionIcon />,
        navigate_route: ROUTES.FINANCIAL_PRODUCTION,
        feature_flag_disable: 'financialProductionDisableMenuAccess',
        permission: ActionRbacEnum.FINANCIAL_BALANCE_READ,
        entitlement: EntitlementEnum.PRODUCTION_YIELD_DATA,
      },
    ],
  },
  [FeatureItemKeys.RESOURCES]: {
    key: FeatureItemKeys.RESOURCES,
    title: 'resources.title',
    description: 'resources.description',
    icon: <ResourcesIcon />,
    feature_flag_disable: 'resourcesDisableMenuAccess',
    children: [
      {
        key: ResourcesItemKeys.ITEM,
        title: 'item.title',
        description: 'item.description',
        icon: <ItemsIcon />,
        navigate_route: ROUTES.ITEM,
        feature_flag_disable: 'itemDisableMenuAccess',
      },
      {
        key: ResourcesItemKeys.ITEM_CATEGORY,
        title: 'item_category.title',
        description: 'item_category.description',
        icon: <CategoryIcon />,
        navigate_route: ROUTES.ITEM_CATEGORY,
        feature_flag_disable: 'itemCategoryDisableMenuAccess',
      },
      {
        key: ResourcesItemKeys.SUPPLIER,
        title: 'supplier.title',
        description: 'supplier.description',
        icon: <SupplierIcon />,
        navigate_route: ROUTES.SUPPLIER,
        feature_flag_disable: 'supplierDisableMenuAccess',
      },
      {
        key: ResourcesItemKeys.BANK_ACCOUNT,
        title: 'bank_account.title',
        description: 'bank_account.description',
        icon: <BankAccountIcon />,
        navigate_route: ROUTES.BANK_ACCOUNT,
        feature_flag_disable: 'bankAccountsDisableMenuAccess',
        entitlement: EntitlementEnum.INVOICES,
      },
    ],
  },
  [FeatureItemKeys.DATA_IMPORT]: {
    key: FeatureItemKeys.DATA_IMPORT,
    title: 'data_import.title',
    description: 'data_import.description',
    icon: <DataImportIcon />,
    navigate_route: ROUTES.DATA_IMPORT,
    feature_flag_disable: 'dataImportDisableMenuAccess',
    entitlement: EntitlementEnum.DATA_SHARING_IMPORT_EXPORT,
    hide_on_mobile: true,
  },
};

export {
  FeatureItemKeys,
  featureListData,
  FinancialItemKeys,
  OrdersAndPaymentsItemKeys,
  ResourcesItemKeys,
};
