import { useNavigateWithOrgAndFarm } from 'common/utils';
import { useState } from 'react';
import { useLocation } from 'react-router-dom';

interface ReturnObject {
  router: string;
  data: any;
  otherParams?: any;
}

const useRedirect = <T = ReturnObject>() => {
  const location = useLocation();
  const [returnObject] = useState<T>(location.state);
  const navigate = useNavigateWithOrgAndFarm();

  const goToRouter = (
    nextRouter: string,
    data: ReturnObject,
    otherParams = { 'create[open]': 'true' },
  ) => {
    navigate(nextRouter, undefined, undefined, otherParams, data);
  };

  const returnRouter = () => {
    const returnObjectType = returnObject as ReturnObject;
    if (returnObjectType?.router && returnObjectType?.data) {
      navigate(
        returnObjectType.router,
        undefined,
        undefined,
        returnObjectType.otherParams,
        returnObjectType.data,
      );
    }
  };

  return {
    goToRouter,
    returnRouter,
    initialValuesState: returnObject,
  };
};

export { useRedirect };
