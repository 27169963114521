import { CrudFilters } from '@refinedev/core';
import { useWatch } from 'antd/es/form/Form';
import { useEffect, useState } from 'react';
import { useFarm } from 'src/organization';
import { create } from 'zustand';

type RefineTableFiltersState = {
  filtersStore: { storeName: string; filter: Record<string, any> }[];
};

type RefineTableFiltersActions = {
  setFiltersStore: (value: any[]) => void;
  invalidateFiltersStore: () => void;
};

const useZustandStore = create<
  RefineTableFiltersState & RefineTableFiltersActions
>((set) => {
  return {
    filtersStore: [],
    setFiltersStore: (value: any[]) => set(() => ({ filtersStore: value })),
    invalidateFiltersStore: () => set(() => ({ filtersStore: [] })),
  };
});

const useRefineTableFiltersStore = (
  name?: string,
): {
  filterStore: CrudFilters | undefined;
  handleInvalidateFiltersStore: () => void;
  selectedStore?: Record<string, any>;
} => {
  const [isFirstRender, setIsFirstRender] = useState(true);
  const allFilters = useWatch([]);
  const { filtersStore, setFiltersStore, invalidateFiltersStore } =
    useZustandStore();

  useEffect(() => {
    if (allFilters && name && !isFirstRender) {
      const filtersWithoutCurrent = filtersStore.filter(
        (filter) => filter.storeName !== name,
      );
      setFiltersStore([
        ...filtersWithoutCurrent,
        {
          storeName: name,
          filter: allFilters,
        },
      ]);
    }

    return () => setIsFirstRender(false);
  }, [allFilters]);

  const handleInvalidateFiltersStore = () => {
    invalidateFiltersStore();
  };

  if (!name) {
    return {
      filterStore: [],
      handleInvalidateFiltersStore,
    };
  }

  const selectedStore = filtersStore.find(
    (filters) => filters.storeName === name,
  );

  if (selectedStore) {
    return {
      filterStore: Object.keys(selectedStore.filter).map((key) => ({
        field: key,
        operator: 'eq',
        value: selectedStore.filter[key],
      })),
      selectedStore,
      handleInvalidateFiltersStore,
    };
  }

  return {
    filterStore: [],
    handleInvalidateFiltersStore,
  };
};

export { useRefineTableFiltersStore };
