import {
  DiamondMarkerIcon,
  DiamondOutlineMarkerIcon,
  HourGlassMarkerIcon,
} from 'common/icons';
import { useCallback } from 'react';

import { ChartCustomTooltipRefProps } from '../chart-custom-tooltip-ref/chart-custom-tooltip-ref.component';
import { MarkerType } from '../charts.model';

interface ChartHorizontalGroupedBarHookProps {
  tooltipRef: React.RefObject<ChartCustomTooltipRefProps>;
  valueFormat: (value: number) => string;
}

const useChartHorizontalGroupedBar = ({
  tooltipRef,
  valueFormat,
}: ChartHorizontalGroupedBarHookProps) => {
  const onEventHandler = useCallback(
    (
      action: string,
      xPos: number,
      yPos: number,
      label: string,
      value: number,
      color: string,
    ) => {
      const xPosCenter = xPos;
      const yPosCenter = yPos;

      if (!tooltipRef.current) {
        return;
      }

      tooltipRef.current.setTooltipData({
        label,
        value,
        color,
        valueFormat,
      });

      tooltipRef.current.transform(xPosCenter, yPosCenter);

      if (action === 'move') {
        return;
      }

      tooltipRef.current.display();
    },
    [tooltipRef, valueFormat],
  );

  const onMouseHoverHandler = useCallback(
    (
      event: React.MouseEvent<SVGElement, MouseEvent>,
      action: string,
      value: number,
      color: string,
      label: string,
    ) => {
      const xPos = event.pageX;
      const yPos = event.pageY;

      onEventHandler(action, xPos, yPos, label, value, color);
    },
    [onEventHandler],
  );

  const onTouchStartHandler = useCallback(
    (
      event: React.TouchEvent<SVGElement>,
      action: string,
      value: number,
      color: string,
      label: string,
    ) => {
      const touch = event.touches[0];
      const xPos = touch.pageX;
      const yPos = touch.pageY;

      onEventHandler(action, xPos, yPos, label, value, color);
    },
    [onEventHandler],
  );

  const onMouseLeaveHandler = useCallback(() => {
    if (tooltipRef.current) {
      tooltipRef.current.hide();
    }
  }, [tooltipRef]);

  const onTouchEndHandler = useCallback(() => {
    if (tooltipRef.current) {
      tooltipRef.current.hide();
    }
  }, [tooltipRef]);

  const getMarkerIcon = (type: MarkerType) => {
    switch (type) {
      case MarkerType.DIAMOND:
        return <DiamondMarkerIcon />;
      case MarkerType.HOURGLASS:
        return <HourGlassMarkerIcon />;
      case MarkerType.DIAMOND_OUTLINE:
        return <DiamondOutlineMarkerIcon />;
      default:
        return null;
    }
  };

  return {
    onMouseHoverHandler,
    onMouseLeaveHandler,
    onTouchEndHandler,
    onTouchStartHandler,
    getMarkerIcon,
  };
};

export { useChartHorizontalGroupedBar };
