import { AlertTriangleIcon } from 'common/icons';

import * as Styled from './failed-column.style';

interface FailedColumnProps {
  failedMessage: string;
}

const FailedColumn = ({ failedMessage }: FailedColumnProps) => {
  return (
    <Styled.Container>
      <AlertTriangleIcon />
      {failedMessage}
    </Styled.Container>
  );
};

export { FailedColumn };
