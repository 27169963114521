import { useOne } from '@refinedev/core';
import { DrawerProps, FormProps, Grid } from 'antd';
import { useWatch } from 'antd/es/form/Form';
import { ButtonType } from 'antd/lib/button';
import { StockEntryEnum } from 'common/constants';
import { FarmModel } from 'common/models';
import { useTracking } from 'common/utils';
import dayjs from 'dayjs';
import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useOrganization } from 'src/organization';

import { WarehouseModel } from '../warehouse-list';
import { OpeningStockFormModel } from './opening-stock.model';
import { useOpeningStockStore } from './opening-stock.store';

const useOpeningStock = (
  formProps: FormProps,
  drawerProps: DrawerProps,
  warehouseId?: string,
  farmId?: string,
) => {
  const { i18n } = useTranslation();
  const { track } = useTracking();
  const breakpoint = Grid.useBreakpoint();
  const userLanguage = i18n.language.toLocaleLowerCase();
  const { currentOrganizationId, isLoading: isOrganizationLoading } =
    useOrganization();
  const { setDisableSave, showItems, setShowItems } = useOpeningStockStore();

  const formValues = useWatch<OpeningStockFormModel>([], formProps.form);

  const formInitialValues = useMemo(
    () => ({
      postingDate: dayjs().locale(userLanguage),
      postingTime: dayjs().locale(userLanguage),
    }),
    [userLanguage],
  );

  const disableItemsTab = useMemo(
    () => !formValues?.postingDate || !formValues?.postingTime,
    [formValues?.postingDate, formValues?.postingTime],
  );

  const detailTabClassname = useMemo<ButtonType | undefined>(
    () => (showItems ? undefined : 'primary'),
    [showItems],
  );
  const itemsTabClassname = useMemo<ButtonType | undefined>(
    () => (showItems ? 'primary' : undefined),
    [showItems],
  );

  const loadWarehouses = useMemo(
    () => !!currentOrganizationId && !isOrganizationLoading && !farmId,
    [currentOrganizationId, farmId, isOrganizationLoading],
  );

  const drawerWidth = useMemo(
    () => (breakpoint.sm ? '400px' : '100%'),
    [breakpoint.sm],
  );

  const { data: warehouseData } = useOne<WarehouseModel>({
    resource: 'warehouse',
    id: warehouseId,
    meta: {
      organizationId: currentOrganizationId,
    },
    queryOptions: {
      enabled: loadWarehouses,
    },
  });

  const { data: farmData } = useOne<FarmModel>({
    resource: 'farm',
    id: farmId,
    meta: {
      organizationId: currentOrganizationId,
    },
    queryOptions: {
      enabled: !!farmId && !!currentOrganizationId,
    },
  });

  useEffect(() => {
    if (!drawerProps.open) {
      setShowItems(false);
      setDisableSave(true);
      formProps.form?.resetFields();
    }
  }, [drawerProps.open, formProps.form, setDisableSave, setShowItems]);

  useEffect(() => {
    formProps.form
      ?.validateFields({ validateOnly: true })
      .then(() => {
        setDisableSave(false);
      })
      .catch(() => {
        setDisableSave(true);
      });
  }, [formValues, formProps.form, setDisableSave]);

  const handleOnFinish = (values: OpeningStockFormModel) => {
    track('End Opening Stock');
    if (formProps.onFinish) {
      const postingDate = dayjs(values.postingDate).format('YYYY-MM-DD');
      const postingTime = dayjs(values.postingTime).format('HH:mm:ss');
      const items = values.items.map((item) => ({
        qty: item.qty,
        itemCode: item.itemCode,
        valuationRate: item.valuationRate,
        warehouse: warehouseId ?? values.selectedWarehouse,
      }));
      const findedFarmId = warehouseData?.data.farm;
      const farm = farmId ?? findedFarmId;
      const purpose = StockEntryEnum.OPENING_STOCK;

      const data = {
        items,
        purpose,
        farm,
        postingDate,
        postingTime,
      };

      return formProps.onFinish(data);
    }
  };

  return {
    showItems,
    detailTabClassname,
    itemsTabClassname,
    disableItemsTab,
    drawerWidth,
    formInitialValues,
    farmData,
    itemsAmount: formValues?.items?.length ?? 0,
    setShowItems,
    handleOnFinish,
  };
};

export { useOpeningStock as default, useOpeningStock };
