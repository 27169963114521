import { Typography } from 'antd';
import { AddIcon } from 'common/icons';
import { EmptyStateFarmImage } from 'common/images';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { deepAction } from 'src/common/utils';
import { useOrganization } from 'src/organization';

import * as Styled from './farm-empty-state.style';

const FarmEmptyState: FC = () => {
  const { t } = useTranslation();
  const { currentOrganizationId } = useOrganization();
  const callback_uri = window.location.href;

  const createFarmUri = deepAction.createFarm(
    callback_uri,
    currentOrganizationId ?? undefined,
  );

  return (
    <Styled.EmptyState>
      <Typography.Title level={4}>{t('farms.empty')}</Typography.Title>
      <EmptyStateFarmImage />
      <Styled.NewFarmButton type="primary" size="large" href={createFarmUri}>
        <AddIcon /> {t('farms.new')}
      </Styled.NewFarmButton>
    </Styled.EmptyState>
  );
};

export { FarmEmptyState as default, FarmEmptyState };
