import { UseDrawerFormReturnType } from '@refinedev/antd';
import { HttpError } from '@refinedev/core';
import { Form, Grid } from 'antd';
import { DrawerWithButtons } from 'common/components';
import { useTranslation } from 'react-i18next';

import { ItemCategory } from '../item-category.model';
import ItemCategoryFormDetails from './details/item-category-form-details.component';
import ItemCategoryFormFarms from './farms/item-category-form-farms.component';
import useItemCategoryForm from './item-category-form.hook';
import * as Styled from './item-category-form.style';

interface ItemCategoryFormContainerProps {
  itemCategoryDrawerForm: UseDrawerFormReturnType<
    ItemCategory,
    HttpError,
    ItemCategory
  >;
  breadcrumbList?: string[];
}

export const ItemCategoryFormContainer = ({
  itemCategoryDrawerForm,
  breadcrumbList,
}: ItemCategoryFormContainerProps) => {
  const breakpoint = Grid.useBreakpoint();
  const { t } = useTranslation();

  const {
    formProps,
    form: formInstance,
    close: onClose,
    saveButtonProps: onSubmitForm,
    formLoading,
  } = itemCategoryDrawerForm;

  const {
    newItemCategory,
    formInitialValues,
    disableSave,
    showFarms,
    detailTabClassname,
    disableFarmsTab,
    farmsTabClassname,
    setShowFarms,
    handleFormValuesChange,
    handleOnFinish,
  } = useItemCategoryForm(
    formProps,
    itemCategoryDrawerForm.drawerProps,
    formInstance,
  );

  return (
    <DrawerWithButtons
      {...itemCategoryDrawerForm.drawerProps}
      width={breakpoint.sm ? '400px' : '100%'}
      title={formProps?.initialValues?.name ?? t('item_category.form.title')}
      buttonSaveProps={{
        ...onSubmitForm,
        onClick: showFarms ? onSubmitForm.onClick : () => setShowFarms(true),
      }}
      disabledSave={showFarms ? disableSave : disableFarmsTab}
      isLoading={formLoading}
      isLoadingChildren={formLoading && !formInitialValues}
      onClose={onClose}
      tagTrackClose="Item Category"
      onBack={showFarms ? () => setShowFarms(false) : undefined}
      textSave={!showFarms ? t('buttons.nextStep') ?? '' : undefined}
      breadcrumbList={breadcrumbList}
    >
      <Styled.BoxButtons data-testid="stock-trasnfer-buttons-wrapper">
        <Styled.ButtonSelected
          onClick={() => setShowFarms(false)}
          type={detailTabClassname}
        >
          {t('item_category.form.details.title')}
        </Styled.ButtonSelected>
        <Styled.ButtonSelected
          onClick={() => setShowFarms(true)}
          type={farmsTabClassname}
          disabled={disableFarmsTab}
        >
          {t('item_category.form.farms.title')}
        </Styled.ButtonSelected>
      </Styled.BoxButtons>
      {formInitialValues && (
        <Form
          layout="vertical"
          {...formProps}
          onValuesChange={handleFormValuesChange}
          onFinish={handleOnFinish}
          data-testid="item-category-form"
          initialValues={formInitialValues}
        >
          <ItemCategoryFormDetails show={!showFarms} />
          <ItemCategoryFormFarms
            newItemCategory={newItemCategory}
            show={showFarms}
          />
        </Form>
      )}
    </DrawerWithButtons>
  );
};
