import { ROUTES } from 'common/constants';
import { CongratulationColoredIcon, PurchaseIcon } from 'common/icons';
import { useNavigateWithOrgAndFarm } from 'common/utils';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import * as Styled from './electronic-invoice-finish.style';

const ElectronicInvoiceFinish = () => {
  const { t } = useTranslation();
  const navigate = useNavigateWithOrgAndFarm();
  const [gifSrc, setGifSrc] = useState('/finish-animation.gif');

  const handleGoToPurchase = () => {
    navigate(ROUTES.PURCHASE);
  };

  useEffect(() => {
    setGifSrc(`/finish-animation.gif?timestamp=${new Date().getTime()}`);
  }, []);

  return (
    <Styled.Container>
      <Styled.ContainerFlex>
        <CongratulationColoredIcon />
        <Styled.Box>
          <Styled.FinishImg src={gifSrc} />
          <Styled.Text level={3}>
            {t('electronic_invoice.finish.text')}
          </Styled.Text>
        </Styled.Box>
      </Styled.ContainerFlex>
      <Styled.ContainerButtons>
        <Styled.Button type="primary" onClick={handleGoToPurchase}>
          {t('common.business.go_to_purchase')}
          <PurchaseIcon />
        </Styled.Button>
      </Styled.ContainerButtons>
    </Styled.Container>
  );
};

export { ElectronicInvoiceFinish };
