interface PlatformDataConfigModel {
  ACCOUNTS_URI: string;
  CLIENT_ID: string;
  APP_ID: string;
}

const PLATFORM_DATA_CONFIG: PlatformDataConfigModel = {
  ACCOUNTS_URI: import.meta.env.VITE_ACCOUNTS_URL ?? '',
  CLIENT_ID: import.meta.env.VITE_CLIENT_ID ?? '',
  APP_ID: import.meta.env.VITE_APP_ID ?? '',
};

export { PLATFORM_DATA_CONFIG as default, PLATFORM_DATA_CONFIG };
