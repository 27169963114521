import {
  ButtonProps,
  DrawerProps,
  Form,
  FormInstance,
  FormProps,
  Grid,
} from 'antd';
import { DrawerWithButtons } from 'common/components';
import { useTranslation } from 'react-i18next';

import { useStockTransfer } from './stock-transfer.hook';
import { useStockTransferStore } from './stock-transfer.store';
import * as Styled from './stock-transfer.style';
import { StockTransferDetails } from './stock-transfer-details/stock-transfer-details.component';
import { StockTransferItems } from './stock-transfer-items/stock-transfer-items.component';

interface StockTransferContainerProps {
  drawerProps: DrawerProps;
  formProps: FormProps;
  formInstance: FormInstance;
  onClose: () => void;
  onStockTransfer: ButtonProps;
}

export const StockTransferContainer = ({
  drawerProps,
  formProps,
  formInstance,
  onClose,
  onStockTransfer,
}: StockTransferContainerProps) => {
  const breakpoint = Grid.useBreakpoint();
  const { t } = useTranslation();

  const {
    detailTabClassname,
    itemsTabClassname,
    disableItemsTab,
    formInitialValues,
    itemsAmount,
    handleOnFinish,
  } = useStockTransfer(formProps, drawerProps, formInstance, onClose);

  const { disableSave, showItems, setShowItems } = useStockTransferStore();

  return (
    <DrawerWithButtons
      {...drawerProps}
      width={breakpoint.sm ? '400px' : '100%'}
      zIndex={1001}
      title={t('stock_transfer.title')}
      buttonSaveProps={{
        ...onStockTransfer,
        onClick: showItems ? onStockTransfer.onClick : () => setShowItems(true),
      }}
      disabledSave={showItems ? disableSave : disableItemsTab}
      onClose={onClose}
      tagTrackClose="Stock Transfer"
      onBack={showItems ? () => setShowItems(false) : undefined}
      textSave={!showItems ? t('buttons.nextStep') ?? '' : undefined}
    >
      <Styled.BoxButtons data-testid="stock-trasnfer-buttons-wrapper">
        <Styled.ButtonSelected
          onClick={() => setShowItems(false)}
          type={detailTabClassname}
        >
          {t('stock_transfer.details.title')}
        </Styled.ButtonSelected>
        <Styled.ButtonSelected
          onClick={() => setShowItems(true)}
          type={itemsTabClassname}
          disabled={disableItemsTab}
        >
          {t('stock_transfer.items.title')}
          <Styled.BoxAmount isSelected={showItems}>
            {itemsAmount}
          </Styled.BoxAmount>
        </Styled.ButtonSelected>
      </Styled.BoxButtons>
      <Form
        layout="vertical"
        {...formProps}
        onFinish={handleOnFinish}
        data-testid="stock-transfer-form"
        initialValues={formInitialValues}
      >
        <StockTransferDetails />
        <StockTransferItems />
      </Form>
    </DrawerWithButtons>
  );
};
