import { Button as ButtonAntd } from 'antd';
import styled from 'styled-components';
import { NeutralColors } from 'theme';

interface ButtonProps {
  isSelected?: boolean;
}

const Button = styled(ButtonAntd)<ButtonProps>`
  text-align: left;
  height: auto;
  display: flex;
  padding: 8px;

  svg {
    fill: ${(props) =>
      props.isSelected ? NeutralColors[0] : NeutralColors[70]};
    margin-right: 8px;
  }
`;

const Text = styled.p`
  margin: 0px;
`;

export { Button, Text };
