import styled from 'styled-components';
import { NeutralColors } from 'theme';

interface TooltipProps {
  color: string;
}

const Tooltip = styled.div`
  background-color: ${NeutralColors[0]};
  padding: 8px;
  border-radius: 4px;
  width: fit-content;
  overflow: hidden;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
  transform: translate(-50%, -100%);
`;

const TooltipColor = styled.div<TooltipProps>`
  width: 8px;
  height: 8px;
  background-color: ${(props) => props.color};
  display: inline-block;
  margin-right: 8px;
`;

export { Tooltip, TooltipColor };
