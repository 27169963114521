import { useState } from 'react';

import {
  SelectableVerticalButton,
  SelectableVerticalButtonProps,
} from '../selectable-vertical-button';
import * as Styled from './form-group-selectable-button.style';

interface FormGroupSelectableButtonProps {
  value?: string;
  onChange?: (x: string) => void;
  list: SelectableVerticalButtonProps[];
  onClick?: () => void;
}

const FormGroupSelectableButton = ({
  list,
  value,
  onClick,
  onChange,
}: FormGroupSelectableButtonProps) => {
  const [selectedValue, setSelectedValue] = useState<string>(value ?? '');

  const handleOnChange = (value: string) => {
    setSelectedValue(value);
    if (onChange) {
      onChange(value);
    }
    if (onClick) {
      onClick();
    }
  };

  return (
    <Styled.ContainerButtons>
      {list.map((button) => (
        <SelectableVerticalButton
          key={`button_${button.title}`}
          icon={button.icon}
          title={button.title}
          isSelected={button.value === selectedValue}
          onClick={() => handleOnChange(button.value as string)}
        />
      ))}
    </Styled.ContainerButtons>
  );
};

export { FormGroupSelectableButton };
