import { ButtonProps, Select, SelectProps } from 'antd';
import { useMemo } from 'react';

import { SelectWithButton } from '../select-with-button';
import { OptionTypeModel } from './select-with-complementary-label.model';
import * as Styled from './select-with-complementary-label.style';

export interface SelectWithComplementaryLabelProps extends SelectProps {
  options?: OptionTypeModel[];
  buttonProps?: ButtonProps;
}

export const SelectWithComplementaryLabel = ({
  options,
  buttonProps,
  ...props
}: SelectWithComplementaryLabelProps) => {
  const renderOptions = useMemo(
    () =>
      options?.map((option) => ({
        ...option,
        label:
          option.complementaryLabel && option.label ? (
            <>
              {option.label}
              <Styled.SpanComplementaryLabel>{`(${option.complementaryLabel})`}</Styled.SpanComplementaryLabel>
            </>
          ) : (
            option.label
          ),
      })),
    [options],
  );

  if (buttonProps) {
    return (
      <SelectWithButton
        {...props}
        options={renderOptions}
        buttonProps={buttonProps}
      />
    );
  }

  return <Select {...props} options={renderOptions} />;
};
