import {
  ComputedDatum,
  LegendDatum,
  PieTooltipProps,
  ResponsivePie,
} from '@nivo/pie';
import { CurrencyValue } from 'common/components';
import { getCurrencySymbol } from 'common/utils';
import { valueToPercent } from 'common/utils/value-to-percent.util';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import ChartLegend from '../chart-legend/chart-legend.component';
import { CHART_COLORS } from '../charts.constant';
import { ChartDataModel } from '../charts.model';
import * as Styled from './donut-chart.style';

interface DonutChartProps {
  data: ChartDataModel[];
  total: number;
  onClick: (item: ComputedDatum<ChartDataModel>) => void;
  currency?: string;
  toggleCustomUom?: boolean;
  customUom?: string;
  sortByValue?: boolean;
}

const DonutChart = ({
  data,
  currency,
  total,
  onClick,
  toggleCustomUom,
  customUom,
  sortByValue = false,
}: DonutChartProps) => {
  const { i18n } = useTranslation();
  const [customLegends, setCustomLegends] = useState<
    LegendDatum<ChartDataModel>[]
  >([]);

  const currencySymbol: string = useMemo(
    () => getCurrencySymbol(currency),
    [currency],
  );

  const valueFormat = useCallback(
    (value: number) =>
      currencySymbol && !toggleCustomUom
        ? `${currencySymbol} ${value.toLocaleString(i18n.language, {
            maximumFractionDigits: 2,
          })}`
        : `${value.toLocaleString(i18n.language, {
            maximumFractionDigits: 2,
          })} ${customUom}`,
    [currencySymbol, customUom, i18n.language, toggleCustomUom],
  );

  const tooltip = useCallback(
    ({ datum: { value } }: PieTooltipProps<ChartDataModel>) => (
      <Styled.Tooltip>
        {toggleCustomUom ? (
          `${value.toLocaleString(i18n.language, {
            maximumFractionDigits: 2,
          })} ${customUom}`
        ) : (
          <CurrencyValue value={value} currency={currency} />
        )}
      </Styled.Tooltip>
    ),
    [currency, customUom, i18n.language, toggleCustomUom],
  );

  const legends = useMemo(
    () =>
      customLegends.map(
        ({ id, label, data, color }: LegendDatum<ChartDataModel>) => (
          <ChartLegend
            onClick={() => onClick(data as ComputedDatum<ChartDataModel>)}
            key={id}
            label={label as string}
            value={data.formattedValue}
            color={color}
            percentage={valueToPercent(data.value, total, i18n.language)}
          />
        ),
      ),
    [customLegends, i18n.language, onClick, total],
  );

  return (
    <>
      <Styled.DonutWrapper>
        <ResponsivePie
          data={data}
          innerRadius={0.5}
          padAngle={1}
          colors={CHART_COLORS}
          enableArcLinkLabels={false}
          enableArcLabels={false}
          margin={{ top: 20, right: 20, bottom: 20, left: 20 }}
          activeOuterRadiusOffset={10}
          borderWidth={1}
          sortByValue={sortByValue}
          borderColor={{
            from: 'color',
            modifiers: [['darker', 0.2]],
          }}
          tooltip={tooltip}
          forwardLegendData={setCustomLegends}
          valueFormat={valueFormat}
          onClick={(category) => onClick(category)}
        />
      </Styled.DonutWrapper>
      <Styled.Legends>{legends}</Styled.Legends>
    </>
  );
};

export { DonutChart as default, DonutChart };
