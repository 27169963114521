import { Button } from 'antd';
import { ButtonProps } from 'antd/lib/button';
import styled from 'styled-components';
import { MediaQuery } from 'theme';

interface ButtonIconProps extends ButtonProps {
  isFirst?: boolean;
  isLast?: boolean;
}

const FormItem = styled.div`
  margin-top: -4px;
  width: 100%;
  display: flex;
  margin-bottom: 8px;

  button {
    :first {
      border-radius: 0px;
    }
  }

  @media (min-width: ${MediaQuery.screenSizesCss.tablet}) {
    width: auto;
    margin-right: 16px;
    margin-bottom: 0px;
  }
`;

const ButtonIcon = styled(Button)<ButtonIconProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  border-top-right-radius: ${(props) =>
    props.isLast ? '12px' : '0'} !important;
  border-top-left-radius: ${(props) =>
    props.isFirst ? '12px' : '0'} !important;
  border-bottom-right-radius: ${(props) =>
    props.isLast ? '12px' : '0'} !important;
  border-bottom-left-radius: ${(props) =>
    props.isFirst ? '12px' : '0'} !important;

  min-width: 50%;

  @media (min-width: ${MediaQuery.screenSizesCss.tablet}) {
    min-width: 140px;
  }

  svg {
    width: 16px;
  }
`;

export { ButtonIcon, FormItem };
