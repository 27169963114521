import { Skeleton } from 'antd';
import { ArrowRightIcon, InvoiceIcon } from 'common/icons';
import { useTranslation } from 'react-i18next';
import { NeutralColors, RedColors } from 'theme';

import * as Styled from '../../../dashboard.style';
import { usePurchaseInvoiceBox } from './purchase-invoice-box.hook';

interface PurchaseInvoiceBoxProps {
  farm?: string;
}

const PurchaseInvoiceBox = ({ farm }: PurchaseInvoiceBoxProps) => {
  const { t } = useTranslation();
  const {
    overdueTotal,
    overdueSevenDays,
    handleGoToPurchaseInvoice,
    isFetching,
  } = usePurchaseInvoiceBox(farm);

  return (
    <Styled.BorderedContainer data-testid="purchase-invoice-box">
      <Styled.BoxTitle onClick={handleGoToPurchaseInvoice}>
        <InvoiceIcon />
        <span>{t('dashboard.invoices')}</span>
        <ArrowRightIcon />
      </Styled.BoxTitle>
      {isFetching ? (
        <Skeleton active />
      ) : (
        <Styled.FlexContainer>
          <Styled.VerticalBox>
            <Styled.Tag
              backgroundColor={overdueTotal ? RedColors[20] : NeutralColors[10]}
              textColor={overdueTotal ? RedColors[70] : NeutralColors[60]}
            >
              {t('dashboard.overdue')}
            </Styled.Tag>
            <Styled.Box>
              {overdueTotal ? (
                <Styled.BigText>
                  {overdueTotal.toLocaleString('pt-br', {
                    style: 'currency',
                    currency: 'BRL',
                  })}
                </Styled.BigText>
              ) : (
                <Styled.BigText textColor={NeutralColors[60]}>
                  {t('dashboard.all_up_to_date')}
                </Styled.BigText>
              )}
            </Styled.Box>
          </Styled.VerticalBox>
          <Styled.VerticalBox>
            <Styled.Tag
              backgroundColor={NeutralColors[10]}
              textColor={NeutralColors[60]}
            >
              {t('dashboard.overdue_seven_days')}
            </Styled.Tag>
            <Styled.Box>
              {overdueSevenDays ? (
                <Styled.BigText>
                  {overdueSevenDays?.toLocaleString('pt-br', {
                    style: 'currency',
                    currency: 'BRL',
                  })}
                </Styled.BigText>
              ) : (
                <Styled.BigText textColor={NeutralColors[60]}>
                  {t('dashboard.nothing_foreseen')}
                </Styled.BigText>
              )}
            </Styled.Box>
          </Styled.VerticalBox>
        </Styled.FlexContainer>
      )}
    </Styled.BorderedContainer>
  );
};

export { PurchaseInvoiceBox as default, PurchaseInvoiceBox };
