import styled from 'styled-components';

const ColumnError = styled.div`
  display: flex;
  align-items: center;

  svg {
    width: 20px;
    margin-left: 8px;
  }
`;

export { ColumnError };
