import { useCreate } from '@refinedev/core';
import { Typography } from 'antd';
import { Modal } from 'common/components';
import { ROUTES } from 'common/constants';
import { BalanceProtectorIcon } from 'common/icons';
import { SuccessAnimation } from 'common/images';
import { useTracking } from 'common/utils';
import { useLinkWithOrgAndFarm } from 'common/utils/use-link-with-org-and-farm.hook';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useOrganization } from 'src/organization';

interface FinishModalProps {
  open: boolean;
  setOpen: (b: boolean) => void;
}

const FinishModal = ({ open, setOpen }: FinishModalProps) => {
  const { t } = useTranslation();
  const { goToLink } = useLinkWithOrgAndFarm();
  const { currentOrganizationId } = useOrganization();
  const { track } = useTracking();

  const metadata = useMemo(
    () => ({
      organizationId: currentOrganizationId,
    }),
    [currentOrganizationId],
  );

  const { mutate, isLoading, isSuccess } = useCreate();

  const handleCloseModal = () => {
    if (isSuccess) {
      goToLink(`${ROUTES.STOCK}/${ROUTES.MOVEMENTS_STOCK}`);
    } else {
      setOpen(false);
    }
  };

  const handleFinishMigration = () => {
    if (!isSuccess) {
      track('Finish review stock');
      mutate({
        resource: 'stock-comparison/finish-stock-adjustments',
        meta: metadata,
        values: {},
      });
    } else {
      goToLink('/');
    }
  };

  return (
    <Modal
      open={open}
      onCancel={handleCloseModal}
      okText={isSuccess ? t('buttons.ok') : t('buttons.finish')}
      cancelText={
        isSuccess
          ? t('migration.finish_modal.see_movements')
          : t('buttons.cancel')
      }
      isDefaultFooter
      onOk={handleFinishMigration}
      confirmLoading={isLoading}
    >
      {!isSuccess ? (
        <>
          <BalanceProtectorIcon />
          <Typography.Title level={5}>
            {t('migration.finish_modal.title')}
          </Typography.Title>
          <Typography.Paragraph>
            {t('migration.finish_modal.description')}
          </Typography.Paragraph>
        </>
      ) : (
        <>
          <SuccessAnimation />
          <Typography.Title level={5}>
            {t('migration.finish_modal.title_finished')}
          </Typography.Title>
          <Typography.Paragraph>
            {t('migration.finish_modal.description_finished')}
          </Typography.Paragraph>
        </>
      )}
    </Modal>
  );
};

export { FinishModal };
