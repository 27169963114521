import { Button } from 'antd';
import styled from 'styled-components';
import { GreenColors, NeutralColors, YellowColors } from 'theme';

interface ItemProps {
  isSuccess?: boolean;
  width: string;
}

interface LineProps {
  isEditing?: boolean;
}

const Line = styled.div<LineProps>`
  height: 52px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 16px;
  gap: 12px;
  opacity: ${(props) => (props.isEditing ? 0.3 : 1)};
  transition: 1s;
`;

const Item = styled.div<ItemProps>`
  display: flex;
  align-items: center;
  padding: 0 8px;
  height: 100%;
  background-color: ${(props) =>
    props.isSuccess ? GreenColors[10] : YellowColors[10]};
  border-radius: 8px;
  width: ${(props) => props.width};

  svg {
    margin-right: 8px;

    path {
      fill: ${(props) =>
        props.isSuccess ? GreenColors[50] : YellowColors[50]};
    }
  }
`;

const NameContainer = styled.div`
  width: 30%;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const DetailsContainer = styled.div`
  width: 15%;
`;

const ButtonLink = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  width: 32px;
  height: 32px;
  border-radius: 16px;

  svg {
    width: 16px;

    fill: ${(props) =>
      props.type === 'primary' ? NeutralColors[0] : NeutralColors[60]};
  }
`;

export { ButtonLink, DetailsContainer, Item, Line, NameContainer };
