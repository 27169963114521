import { PlusOutlined } from '@ant-design/icons';
import { Button, Form } from 'antd';
import { useTranslation } from 'react-i18next';

import { useStockTransferStore } from '../stock-transfer.store';
import { StockTransferItemContainer } from './stock-transfer-item/stock-transfer-item.container';
import * as Styled from './stock-transfer-items.style';

const StockTransferItems = () => {
  const { t } = useTranslation();

  const { showItems } = useStockTransferStore();

  return (
    <Styled.Container show={showItems}>
      <Form.List
        name="items"
        rules={[
          {
            validator: (_, names) => {
              if (!names || names.length < 1) {
                return Promise.reject(
                  t('stock_transfer.form.items.error_message_empty_items'),
                );
              }
              return Promise.resolve();
            },
          },
        ]}
      >
        {(items, { add, remove }) => (
          <>
            {items.map((item, index) => (
              <StockTransferItemContainer
                item={item}
                key={item.key}
                amountItems={items.length}
                index={index}
                removeItem={() => remove(index)}
              />
            ))}
            <Styled.ContainerButton>
              <Styled.FormItem>
                <Button
                  type="dashed"
                  onClick={() => {
                    add();
                  }}
                  block
                  icon={<PlusOutlined rev={undefined} />}
                  data-testid="stock_transfer-add-new-item"
                >
                  {t('stock_transfer.add_item')}
                </Button>
              </Styled.FormItem>
            </Styled.ContainerButton>
          </>
        )}
      </Form.List>
    </Styled.Container>
  );
};

export { StockTransferItems as default, StockTransferItems };
