import { Form, Skeleton } from 'antd';
import { NoItemsFoundImage } from 'common/images';
import { useTranslation } from 'react-i18next';
import NoFinalProduct from 'src/financial/no-final-product/no-final-product.component';

import { InsertProductionItemTestId } from '../../financial-production-test-id.constant';
import { useInsertProductionItem } from './insert-production-item.hook';
import * as Styled from './insert-production-item.style';

interface InsertProductionItemProps {
  isEdit?: boolean;
}

const InsertProductionItem = ({ isEdit }: InsertProductionItemProps) => {
  const { t } = useTranslation();
  const { crop, data, itemsForm, isFetching, refetch } =
    useInsertProductionItem(isEdit);

  if (isFetching) {
    return <Skeleton active />;
  }

  if (!crop && !isEdit) {
    return (
      <Styled.ContainerEmpty>
        <NoItemsFoundImage />
        <Styled.EmptyDescription>
          {t('financial_production.insert_production.select_season')}
        </Styled.EmptyDescription>
      </Styled.ContainerEmpty>
    );
  }

  if (!data?.data || (data.data.length === 0 && !isEdit)) {
    return <NoFinalProduct refetchFinalProduct={refetch} />;
  }

  return (
    <div data-testid={InsertProductionItemTestId.INSERT_PRODUCTION_ITEMS}>
      <Form.List name="items">
        {(items) => (
          <>
            {items.map((item) => (
              <Form.Item
                label={
                  itemsForm?.length > 0 ? itemsForm[item.name]?.itemName : ''
                }
                name={[item.name, 'qty']}
                key={item.name}
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Styled.InputItem
                  addonAfter={
                    itemsForm?.length > 0
                      ? `${itemsForm[item.name]?.uom} / ha`
                      : ''
                  }
                  decimalSeparator=","
                />
              </Form.Item>
            ))}
          </>
        )}
      </Form.List>
    </div>
  );
};

export { InsertProductionItem };
