import { type CWElementsConfig, useConfig } from '@cw-elements/config';
import { loadRemoteModule } from '@cw-elements/mfe-setup/load';
import { PLATFORM_DATA_CONFIG } from 'common/constants';
import { lazy } from 'react';
import { useSearchParams } from 'react-router-dom';

import { AgOperationsTypeEnum } from './ag-operation.enum';
import { useAgOperationCreate } from './ag-operation-create.hook';
import * as Styled from './ag-operation-create.style';

enum UnitSystemType {
  METRIC = 'metric',
  IMPERIAL = 'imperial',
}

interface AgOperationProps {
  getConfigInstance: () => CWElementsConfig;
  userLanguage: string;
  orgId: string;
  source: string;
  onBack: () => void;
  onFinish: () => void;
  unitSystem?: UnitSystemType;
  operationType?: AgOperationsTypeEnum;
}

const AgOperation = lazy(() =>
  loadRemoteModule<AgOperationProps>('ag-operation/index'),
);

const AgOperationCreateContainer = () => {
  const [searchParams] = useSearchParams();
  const { userLanguage, currentOrganizationId, handleOnFinish, handleOnBack } =
    useAgOperationCreate();

  const operationType: AgOperationsTypeEnum = searchParams.get(
    'operationType',
  ) as AgOperationsTypeEnum;

  if (!operationType) {
    return null;
  }

  return (
    <Styled.Container data-testid="ag-operation-container">
      <AgOperation
        getConfigInstance={useConfig}
        orgId={currentOrganizationId ?? ''}
        onBack={handleOnBack}
        onFinish={handleOnFinish}
        source={PLATFORM_DATA_CONFIG.APP_ID}
        userLanguage={userLanguage}
        unitSystem={UnitSystemType.METRIC}
        operationType={operationType}
      />
    </Styled.Container>
  );
};

export { AgOperationCreateContainer };
