import { Button } from 'antd';
import styled from 'styled-components';

const ContainerHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
`;

const EditButton = styled(Button)`
  display: flex;
  align-items: center;

  svg {
    width: 18px;
  }
`;

const Wrapper = styled.div`
  padding-bottom: 40px;
`;

const CreateButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    display: inline-block;
  }
`;

export { ContainerHeader, CreateButton, EditButton, Wrapper };
