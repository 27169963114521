import styled from 'styled-components';

interface EmptyStateWrapperProps {
  marginTop: string;
}

const EmptyStateWrapper = styled.div<EmptyStateWrapperProps>`
  max-width: 500px;
  padding: 12px;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  margin-top: ${(props) => (props.marginTop ? props.marginTop : 0)};
`;

const DescriptionWrapper = styled.div`
  margin-top: 8px;
  width: 100%;

  span {
    display: block;
  }
`;

const ChildrenWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 16px;
`;

export { ChildrenWrapper, DescriptionWrapper, EmptyStateWrapper };
