import { Spin, Table, Tooltip, Upload } from 'antd';
import { CurrencyValue, EmptyState } from 'common/components';
import {
  ArchiveIcon,
  RefreshIcon,
  SearchEmptyColoredIcon,
  UploadIcon,
} from 'common/icons';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

import { ActionsElectronicInvoice } from './actions-electronic-invoice/actions-electronic-invoice.component';
import { ElectronicInvoiceEnum } from './electronic-invoice.enum';
import { TypeFilter, useElectronicInvoice } from './electronic-invoice.hook';
import { ElectronicInvoiceModel } from './electronic-invoice.model';
import * as Styled from './electronic-invoice.style';
import { ElectronicInvoiceDetails } from './electronic-invoice-details/electronic-invoice-details.component';
import { ElectronicInvoiceNumberColumn } from './electronic-invoice-number-column/electronic-invoice-number-column.component';
import { FailedColumn } from './failed-column/failed-column.component';
import { ItemsColumn } from './items-column/items-column.component';

interface ElectronicInvoiceContainerProps {
  referenceType?: string;
  referenceId?: string;
  setIdsElectronicInvoice?: (x: string[]) => void;
  setBlockImport?: (x: boolean) => void;
}

const ElectronicInvoiceContainer = ({
  referenceType,
  referenceId,
  setIdsElectronicInvoice,
  setBlockImport,
}: ElectronicInvoiceContainerProps) => {
  const { t } = useTranslation();
  const {
    blockImport,
    drawerForm,
    currentFilter,
    amountProcessing,
    amountPending,
    amountImported,
    amountArchived,
    tableProps,
    isFetching,
    isSendFetching,
    handleChangeTypeFilter,
    refetch,
    sendFile,
  } = useElectronicInvoice(
    referenceType,
    referenceId,
    setIdsElectronicInvoice,
    setBlockImport,
  );

  return (
    <div>
      <ElectronicInvoiceDetails
        drawerForm={drawerForm}
        referenceId={referenceId}
      />
      <Styled.ContainerBetween>
        <Styled.FilterContainer>
          <Styled.FormItem>
            <Styled.ButtonIcon
              isFirst
              value="large"
              type={
                currentFilter === TypeFilter.Pending ? 'primary' : 'default'
              }
              size="large"
              onClick={() => handleChangeTypeFilter(TypeFilter.Pending)}
            >
              {t('electronic_invoice.pendant_button')}
              <Styled.ButtonSize>{amountPending}</Styled.ButtonSize>
            </Styled.ButtonIcon>
            <Styled.ButtonIcon
              isLast
              type={
                currentFilter === TypeFilter.Imported ? 'primary' : 'default'
              }
              size="large"
              onClick={() => handleChangeTypeFilter(TypeFilter.Imported)}
            >
              {t('electronic_invoice.imported_button')}
              <Styled.ButtonSize>{amountImported}</Styled.ButtonSize>
            </Styled.ButtonIcon>
          </Styled.FormItem>
          <Styled.ArchivedButton
            icon={<ArchiveIcon />}
            isSelected={currentFilter === TypeFilter.Archived}
            onClick={() => handleChangeTypeFilter(TypeFilter.Archived)}
          >
            {t('electronic_invoice.archived_button')}
            <Styled.ButtonSize>{amountArchived}</Styled.ButtonSize>
          </Styled.ArchivedButton>
        </Styled.FilterContainer>
        <Tooltip title={t('buttons.refresh')}>
          <Styled.RefreshButton disabled={isFetching} onClick={refetch}>
            <RefreshIcon />
          </Styled.RefreshButton>
        </Tooltip>
      </Styled.ContainerBetween>
      {currentFilter === TypeFilter.Pending && (
        <>
          <Upload.Dragger
            multiple
            beforeUpload={() => false}
            showUploadList={false}
            onChange={sendFile}
            style={{ marginBottom: 24 }}
          >
            <Styled.CardIcon>
              {isSendFetching ? <Spin /> : <UploadIcon />}
            </Styled.CardIcon>
            <p>{t('electronic_invoice.modal.upload_description')}</p>
          </Upload.Dragger>
          {blockImport && (
            <Styled.BlockAlert
              message={t('electronic_invoice.modal.alert_message')}
              description={t('electronic_invoice.modal.alert_description')}
              type="warning"
              showIcon
            />
          )}
        </>
      )}
      {amountProcessing > 0 && (
        <Styled.TableHeader>
          <Spin size="small" />
          <Styled.TextHeader>
            {t('electronic_invoice.upload_files', { amount: amountProcessing })}
          </Styled.TextHeader>
        </Styled.TableHeader>
      )}

      {(currentFilter !== TypeFilter.Pending || amountPending > 0) && (
        <Styled.Table
          {...tableProps}
          pagination={false}
          showHeader={false}
          hasHeader={amountProcessing > 0}
          scroll={{ y: 300 }}
          onRow={() => null}
          locale={{
            emptyText: (
              <EmptyState
                title={t('electronic_invoice.empty_state.title')}
                image={<SearchEmptyColoredIcon />}
                marginTop="0px"
              />
            ),
          }}
        >
          <Table.Column
            render={({ issuedDate, issuedDateTime, status, failedMessage }) =>
              status === ElectronicInvoiceEnum.FAILED ? (
                <FailedColumn failedMessage={failedMessage} />
              ) : (
                dayjs(`${issuedDate}T${issuedDateTime}`).format('DD/MM/YYYY')
              )
            }
            onCell={({ status }: ElectronicInvoiceModel) => ({
              colSpan: status === ElectronicInvoiceEnum.FAILED ? 4 : 1,
            })}
          />
          <Table.Column
            render={({ itemsQty }) => <ItemsColumn itemsQty={itemsQty} />}
            onCell={({ status }: ElectronicInvoiceModel) => ({
              colSpan: status === ElectronicInvoiceEnum.FAILED ? 0 : 1,
            })}
          />
          <Table.Column
            render={({ externalId }) => (
              <ElectronicInvoiceNumberColumn externalId={externalId} />
            )}
            onCell={({ status }: ElectronicInvoiceModel) => ({
              colSpan: status === ElectronicInvoiceEnum.FAILED ? 0 : 1,
            })}
          />
          <Table.Column
            render={({ totalValue }) => <CurrencyValue value={totalValue} />}
            onCell={({ status }: ElectronicInvoiceModel) => ({
              colSpan: status === ElectronicInvoiceEnum.FAILED ? 0 : 1,
            })}
          />
          <Table.Column
            render={({ id, status }) => (
              <ActionsElectronicInvoice
                id={id}
                blockImport={blockImport}
                status={status}
                showAll={currentFilter === TypeFilter.Pending}
                drawerForm={drawerForm}
                purchaseOrderId={referenceId}
              />
            )}
          />
        </Styled.Table>
      )}
    </div>
  );
};

export { ElectronicInvoiceContainer };
