import { useOne } from '@refinedev/core';
import { ROUTES } from 'common/constants';
import { useNavigateWithOrgAndFarm } from 'common/utils';
import { useFlags } from 'launchdarkly-react-client-sdk';
import {
  DashboardRequest,
  WarehouseStockReport,
} from 'src/dashboard/dashboard.interface';
import { useOrganization } from 'src/organization';

const useWarehouseStockBox = (farm?: string) => {
  const navigate = useNavigateWithOrgAndFarm();
  const { currentOrganizationId } = useOrganization();
  const { landingWarehouse } = useFlags();

  const { data, isFetching } = useOne<DashboardRequest<WarehouseStockReport>>({
    resource: 'report',
    id: '',
    meta: {
      organizationId: currentOrganizationId,
      params: {
        reportName: landingWarehouse,
        farm,
      },
    },
  });

  const handleGoToWarehouse = () => {
    navigate(
      `${ROUTES.STOCK}/${ROUTES.STOCK_SUMMARY}`,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      [
        {
          field: 'ACTUAL_QTY',
          order: 'asc',
        },
      ],
    );
  };

  const value = data?.data?.result?.[0]?.value;

  return {
    handleGoToWarehouse,
    value,
    isFetching,
  };
};

export { useWarehouseStockBox };
