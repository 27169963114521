export enum StatusEnum {
  Draft = 'Draft',
  Completed = 'Completed',
  ToBill = 'To Bill',
  ToReceive = 'To Receive',
  ToReceiveAndBill = 'To Receive and Bill',
  Cancelled = 'Cancelled',
}

export const StatusTranslationEnum = {
  [StatusEnum.Draft]: 'draft',
  [StatusEnum.Completed]: 'completed',
  [StatusEnum.ToBill]: 'to_bill',
  [StatusEnum.ToReceive]: 'to_receive',
  [StatusEnum.ToReceiveAndBill]: 'to_receive_and_bill',
  [StatusEnum.Cancelled]: 'cancelled',
};
