import { useCreateItemDrawer } from 'src/resources/item/use-item-create-drawer.hook';

const useNoFinalProduct = (refetchFinalProduct?: () => void) => {
  const { createItemDrawer, handleOpenItemDrawer, setResourceCreate } =
    useCreateItemDrawer(undefined, refetchFinalProduct);

  return {
    createItemDrawer,
    handleOpenItemDrawer,
    setResourceCreateItem: setResourceCreate,
  };
};

export { useNoFinalProduct };
