import { PlusOutlined } from '@ant-design/icons';
import { Button, Form } from 'antd';
import { useTranslation } from 'react-i18next';

import { useOpeningStockStore } from '../opening-stock.store';
import { OpeningStockItemContainer } from './opening-stock-item/opening-stock-item.container';
import * as Styled from './opening-stock-items.style';

interface OpeningStockItemsProps {
  currency?: string;
}

const OpeningStockItems = ({ currency }: OpeningStockItemsProps) => {
  const { t } = useTranslation();
  const { showItems } = useOpeningStockStore();

  return (
    <Styled.Container show={showItems}>
      <Form.List
        name="items"
        rules={[
          {
            validator: (_, names) => {
              if (!names || names.length < 1) {
                return Promise.reject();
              }
              return Promise.resolve();
            },
          },
        ]}
      >
        {(items, { add, remove }) => (
          <>
            {items.map((item, index, items) => (
              <OpeningStockItemContainer
                openingStock={item}
                key={item.key}
                amountItems={items.length}
                index={index}
                removeItem={() => remove(index)}
                currency={currency}
              />
            ))}
            <Styled.ContainerButton>
              <Styled.FormItem>
                <Button
                  type="dashed"
                  onClick={() => {
                    add();
                  }}
                  block
                  icon={<PlusOutlined rev={undefined} />}
                  data-testid="opening-stock-add-new-item"
                >
                  {t('opening_stock.add_item')}
                </Button>
              </Styled.FormItem>
            </Styled.ContainerButton>
          </>
        )}
      </Form.List>
    </Styled.Container>
  );
};

export { OpeningStockItems as default, OpeningStockItems };
