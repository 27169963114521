import { UseDrawerFormReturnType } from '@refinedev/antd';
import { HttpError } from '@refinedev/core';
import { Form, Grid, Input, Popconfirm, Skeleton } from 'antd';
import { AutoSingleSelect, DrawerWithButtons } from 'common/components';
import { DeleteIcon } from 'common/icons';
import { useTranslation } from 'react-i18next';

import { BankAccountRequestModel } from '../bank-account.model';
import useBankAccountForm from './bank-account-form.hook';
import * as Styled from './bank-account-form.style';

interface BankAccountCreateContainerProps {
  bankAccountDrawerForm: UseDrawerFormReturnType<
    BankAccountRequestModel,
    HttpError,
    BankAccountRequestModel
  >;
  isEdit?: boolean;
  refetch?: () => void;
  breadcrumbList?: string[];
}

export const BankAccountCreateContainer = ({
  bankAccountDrawerForm,
  isEdit,
  breadcrumbList,
  refetch,
}: BankAccountCreateContainerProps) => {
  const breakpoint = Grid.useBreakpoint();
  const { t } = useTranslation();

  const {
    drawerProps,
    onCreate,
    isLoading,
    handleOnClickDelete,
    onClose,
    formProps,
    handleOnFinish,
    farmSelectProps,
  } = useBankAccountForm({
    bankAccountDrawerForm,
    refetch,
  });

  return (
    <DrawerWithButtons
      {...drawerProps}
      width={breakpoint.sm ? '400px' : '100%'}
      zIndex={1001}
      title={t(
        isEdit ? 'bank_account.create.edit' : 'bank_account.create.title',
      )}
      buttonSaveProps={{
        ...onCreate,
        enableReturnRouter: true,
      }}
      moreButtons={
        isLoading || !isEdit
          ? []
          : [
              <Popconfirm
                key={'bank-account-disable-confirmation'}
                title={t('bank_account.table.delete_title')}
                description={t('bank_account.table.delete_description')}
                onConfirm={handleOnClickDelete}
                okText={t('buttons.delete')}
                cancelText={t('buttons.cancel')}
              >
                <Styled.DeleteButton danger icon={<DeleteIcon />}>
                  {t('bank_account.table.delete')}
                </Styled.DeleteButton>
              </Popconfirm>,
            ]
      }
      onClose={onClose}
      tagTrackClose="BankAccount Creation"
      breadcrumbList={breadcrumbList}
    >
      {isLoading ? (
        <Skeleton active />
      ) : (
        <Form
          layout="vertical"
          {...formProps}
          initialValues={{
            ...formProps?.initialValues,
          }}
          onFinish={handleOnFinish}
        >
          <Form.Item name={['id']} hidden />
          <Form.Item
            label={t('bank_account.create.bank')}
            name={['bankName']}
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input
              placeholder={t('bank_account.create.bank_placeholder') ?? ''}
            />
          </Form.Item>
          <Form.Item
            label={t('bank_account.create.name')}
            name={['accountName']}
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input
              placeholder={t('bank_account.create.name_placeholder') ?? ''}
            />
          </Form.Item>
          <Form.Item
            label={t('bank_account.create.farm')}
            name={['farm']}
            rules={[
              {
                required: true,
              },
            ]}
          >
            <AutoSingleSelect
              {...farmSelectProps}
              placeholder={t('common.business.farm_placeholder')}
            />
          </Form.Item>
        </Form>
      )}
    </DrawerWithButtons>
  );
};
