import { ROUTES } from 'common/constants';
import { AddIcon, HomeIcon, HomeOutlinedIcon, MenuIcon } from 'common/icons';
import { useNavigateWithOrgAndFarm } from 'common/utils';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { FC, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { useOrganization } from 'src/organization';

import { useQuickActionList } from '../quick-action-list.hooks';
import * as Styled from './footer.style';
import { QuickAction } from './quick-action/quick-action.component';

interface FooterComponentType {
  isNavbarOpen: boolean;
  onHomeClick: () => void;
  onMenuClick: () => void;
}

const FooterComponent: FC<FooterComponentType> = ({
  isNavbarOpen,
  onHomeClick,
  onMenuClick,
}) => {
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();
  const navigate = useNavigateWithOrgAndFarm();
  const location = useLocation();
  const featureFlags = useFlags();
  const { entitlements } = useOrganization();

  const onClose = () => {
    setOpen(false);
  };

  const { quickActions } = useQuickActionList(onClose);

  const quickActionItems = quickActions
    .filter(
      (value) =>
        !featureFlags[value.feature_flag_disable ?? ''] &&
        (value.entitlement === undefined ||
          entitlements.includes(value.entitlement)),
    )
    .map((item, index) => {
      const itemKey = `action-${index}`;

      return (
        <QuickAction
          key={itemKey}
          icon={item.icon}
          description={item.description}
          onClick={item.onClick}
        />
      );
    });

  const onGoHome = useCallback(() => {
    onHomeClick();
    navigate(ROUTES.ROOT);
  }, [navigate, onHomeClick]);

  const handleHomeIcon = useMemo(
    () =>
      location.pathname === ROUTES.ROOT ? <HomeIcon /> : <HomeOutlinedIcon />,
    [location.pathname],
  );

  return (
    <Styled.FooterLayout>
      <Styled.HomeButton
        type="text"
        shape="round"
        size="large"
        onClick={onGoHome}
        active={location.pathname === ROUTES.ROOT}
      >
        {handleHomeIcon}
        {t('footer.home')}
      </Styled.HomeButton>
      <Styled.QuickActionsButton
        shape="circle"
        type="primary"
        size="large"
        onClick={() => setOpen(true)}
      >
        <AddIcon />
      </Styled.QuickActionsButton>
      <Styled.MenuButton
        type="text"
        shape="round"
        size="large"
        active={isNavbarOpen}
        onClick={onMenuClick}
      >
        <MenuIcon />
        {t('footer.menu')}
      </Styled.MenuButton>
      <Styled.QuickActionsDrawer
        title={t('footer.quick_actions')}
        placement="bottom"
        width="100vw"
        onClose={onClose}
        open={open}
        closable={false}
        height={300}
      >
        {quickActionItems}
      </Styled.QuickActionsDrawer>
    </Styled.FooterLayout>
  );
};

export { FooterComponent as default, FooterComponent };
