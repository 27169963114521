import styled from 'styled-components';
import { NeutralColors } from 'theme';

interface ContainerChildrenProps {
  isShowHeader?: boolean;
}

const Body = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const ContainerButton = styled.div`
  display: flex;
  justify-content: space-between;
  border-top: 1px dashed ${NeutralColors[20]};
  padding-top: 16px;
`;

const ContainerChildren = styled.div<ContainerChildrenProps>`
  max-height: 70vh;
  overflow: auto;
  margin-top: ${(props) => (props.isShowHeader ? '0' : '16px')};
`;

export { Body, ContainerButton, ContainerChildren };
