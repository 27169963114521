import { FormItem } from 'common/components';
import { SelectAccount } from 'common/components/selects/select-account/select-account.container';
import { CalendarIcon } from 'common/icons';
import { useTranslation } from 'react-i18next';

import { InvoicePaymentFormDateTestId } from '../invoice-payment-form.constants';
import * as Styled from './invoice-payment-form-info.style';

interface InvoicePaymentFormDateProps {
  handleOpenBankAccountDrawer: () => void;
}

const DATE_FORMAT = 'DD/MM/YYYY';

export const InvoicePaymentFormInfo = ({
  handleOpenBankAccountDrawer,
}: InvoicePaymentFormDateProps) => {
  const { t } = useTranslation();

  return (
    <Styled.SpaceWrapper direction="vertical">
      <Styled.Space
        size={4}
        data-testid={
          InvoicePaymentFormDateTestId.INVOICE_PAYMENT_FORM_DATE_CONTAINER
        }
      >
        <Styled.IconWrapper>
          <CalendarIcon />
        </Styled.IconWrapper>
        {t('invoice.payment.form.date.paid_on')}
        <Styled.FormItem name={['postingDate']}>
          <Styled.DatePicker
            format={DATE_FORMAT}
            suffixIcon
            data-testid={
              InvoicePaymentFormDateTestId.INVOICE_PAYMENT_FORM_DATE_DATE_PICKER
            }
          />
        </Styled.FormItem>
        {t('invoice.payment.form.date.at')}
        <Styled.FormItem name={['postingTime']}>
          <Styled.TimePicker
            suffixIcon
            data-testid={
              InvoicePaymentFormDateTestId.INVOICE_PAYMENT_FORM_DATE_TIME_PICKER
            }
          />
        </Styled.FormItem>
      </Styled.Space>
      <SelectAccount
        handleOpenBankAccountDrawer={handleOpenBankAccountDrawer}
      />
      <FormItem name={['id']} hidden />
      <FormItem name={['farm']} hidden />
      <FormItem name={['paymentAccountType']} hidden />
    </Styled.SpaceWrapper>
  );
};
