enum LanguagesEnum {
  En = 'en',
  PtBr = 'pt-BR',
  Es = 'es',
}

const AVAILABLE_LANGUAGES = [
  LanguagesEnum.En,
  LanguagesEnum.PtBr,
  LanguagesEnum.Es,
];

export { AVAILABLE_LANGUAGES, LanguagesEnum };
