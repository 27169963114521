import { Typography } from 'antd';
import styled from 'styled-components';
import { NeutralColors } from 'theme';

const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const ExternalId = styled(Typography.Text)`
  color: ${NeutralColors[70]} !important;
`;

const Date = styled(Typography.Text)`
  color: ${NeutralColors[100]};
`;

const ContainerText = styled.div`
  display: flex;
  flex-direction: column;
`;

const BoxIcon = styled.div`
  width: 24px;
  height: 24px;
  border-radius: 12px;
  border: 1px solid ${NeutralColors[10]};
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    fill: ${NeutralColors[60]};
    width: 16px;
  }
`;

export { BoxIcon, Container, ContainerText, Date, ExternalId };
