const isLocalDataAvailable = (key: string) =>
  Boolean(localStorage.getItem(key));

const setLocalData = (key: string, data: unknown) =>
  localStorage.setItem(key, JSON.stringify(data));

const getLocalData = (key: string) => {
  const data = localStorage.getItem(key) ?? null;

  return data ? JSON.parse(data) : null;
};

const clearLocalData = (key: string) => localStorage.removeItem(key);

export { clearLocalData, getLocalData, isLocalDataAvailable, setLocalData };
