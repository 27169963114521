/* eslint-disable react/jsx-no-useless-fragment */
import { t } from 'i18next';
import { useMemo } from 'react';

export enum AccountTranslation {
  OPENING = "'Opening'",
  TOTAL = "'Total'",
  CLOSING = "'Closing (Opening + Total)'",
}

export enum VoucherTypeTranslation {
  STOCK_ENTRY = 'Stock Entry',
  STOCK_RECONCILIATION = 'Stock Reconciliation',
  PURCHASE_RECEIPT = 'Purchase Receipt',
  PURCHASE_INVOICE = 'Purchase Invoice',
  PAYMENT_ENTRY = 'Payment Entry',
}

interface TranslationColumnProps {
  value: string;
  enumType: typeof AccountTranslation | typeof VoucherTypeTranslation;
}

export const TranslationColumn = ({
  value,
  enumType,
}: TranslationColumnProps) => {
  const isTranslation = useMemo(() => {
    let hasTranslation = false;
    Object.values(enumType).forEach((item) => {
      if (item === value) {
        hasTranslation = true;
      }
    });
    return hasTranslation;
  }, [value, enumType]);

  return (
    <>
      {isTranslation ? t(`report_general_ledger.table_body.${value}`) : value}
    </>
  );
};
