import {
  AlertTriangleOutlinedIcon,
  CheckCircleOutlinedIcon,
} from 'common/icons';
import styled from 'styled-components';
import { GreenColors, YellowColors } from 'theme';

const ItemCategoryNameContainer = styled.div`
  display: flex;
  gap: 4px;
  align-items: center;
`;

const ItemCategoryAlertIcon = styled(AlertTriangleOutlinedIcon)`
  width: 20px;
  height: 20px;
  fill: ${YellowColors['40']};
  cursor: pointer;
`;

const ItemCategoryCheckIcon = styled(CheckCircleOutlinedIcon)`
  width: 20px;
  height: 20px;
  fill: ${GreenColors['60']};
`;

export {
  ItemCategoryAlertIcon,
  ItemCategoryCheckIcon,
  ItemCategoryNameContainer,
};
