import { Button as ButtonAntd, Card, Divider as DividerAntd } from 'antd';
import styled from 'styled-components';
import { BlueColors, MediaQuery, NeutralColors } from 'theme';

interface WrapperProps {
  showHelp?: boolean;
}

const Wrapper = styled(Card)<WrapperProps>`
  width: 100%;

  .fms-card-body {
    display: grid;
    align-items: center;
    justify-content: space-between;
    grid-template-columns: 45% 1px 45%;
    gap: 8px 0;
    padding: 12px;
    white-space: nowrap;
    border-radius: 12px;
    border: ${({ showHelp }) =>
      showHelp
        ? `2px solid ${BlueColors[20]}`
        : `1px solid ${NeutralColors[0]}`};
    background-color: ${({ showHelp }) =>
      showHelp ? `${BlueColors[10]}` : `${NeutralColors[0]}`};

    ::after {
      display: none;
    }
    ::before {
      display: none;
    }
  }

  @media (min-width: ${MediaQuery.screenSizesCss.tablet}) {
    width: 100%;
  }

  @media (min-width: ${MediaQuery.screenSizesCss.laptop}) {
    width: calc(50% - 8px);
  }
`;

const EmptyWrapper = styled(Wrapper)`
  .fms-card-body {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const Divider = styled(DividerAntd)`
  height: 80%;
`;

const ObjectiveWrapper = styled.div`
  display: flex;
  justify-content: space-between;

  span {
    white-space: wrap;
    display: flex;
    align-items: center;
  }
`;

const Button = styled(ButtonAntd)`
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: center;
  color: ${NeutralColors[60]};

  svg {
    display: inline-block;
  }
`;

export {
  Button,
  Divider,
  EmptyWrapper,
  ObjectiveWrapper,
  TextWrapper,
  Wrapper,
};
