import { Button } from 'antd';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ItemStockComparisonModel } from 'src/migration/migration.model';
import { AdjustmentStock } from 'src/stock/adjustment-stock/adjustment-stock.container';
import { StockItemModel } from 'src/stock/stock-summary/stock-summary.model';

import * as Styled from './fms-balance-qty-column.style';

interface FmsBalanceQtyColumnProps {
  item: ItemStockComparisonModel;
  warehouse: string;
  refetch: () => void;
}

const FmsBalanceQtyColumn = ({
  item,
  warehouse,
  refetch,
}: FmsBalanceQtyColumnProps) => {
  const [openItem, setOpenItem] = useState<StockItemModel | null>(null);
  const { t } = useTranslation();

  const handleOpenDrawer = () => {
    setOpenItem({
      actualQty: item.fmsBalanceQty,
      itemCode: item.itemCode,
      itemName: item.item,
      waitedQty: 0,
      warehouse,
    });
  };

  const handleCloseDrawer = () => {
    setOpenItem(null);
  };

  return (
    <>
      {openItem && (
        <AdjustmentStock
          item={openItem}
          setItem={handleCloseDrawer}
          refetch={refetch}
        />
      )}
      <Styled.Container>
        {`${item.fmsBalanceQty.toFixed(0)} ${item.itemUom}`}
        <Button onClick={handleOpenDrawer}>{t('buttons.adjust')}</Button>
      </Styled.Container>
    </>
  );
};

export { FmsBalanceQtyColumn };
