import { Button } from 'antd';
import styled from 'styled-components';
import { NeutralColors } from 'theme';

interface LabelProps {
  checked?: boolean;
}

const Input = styled.input`
  display: none;
`;

const Label = styled(Button)<LabelProps>`
  display: flex;
  align-items: center;
  gap: 8px;

  svg {
    fill: ${(props) =>
      props.type === 'primary' ? NeutralColors[0] : NeutralColors[80]};
  }
`;

export { Input, Label };
