import { DatePicker, Form, Input as InputAntd } from 'antd';
import styled from 'styled-components';
import { BlueColors, NeutralColors } from 'theme';

const Input = styled(InputAntd)`
  max-width: 120px;
  border: none;
  background-color: transparent;
`;

const InputDate = styled(DatePicker)`
  height: 32px;
  background-color: ${NeutralColors[10]};
  border: none;
  padding: 0px;
  width: 80px;

  input {
    font-weight: 600;
    color: ${BlueColors[60]} !important;
  }
`;

const FormItem = styled(Form.Item)`
  height: 32px;
  background-color: ${NeutralColors[10]};
  border-radius: 12px;
  padding: 0px 12px;

  label {
    font-weight: 600;
  }

  svg {
    width: 20px;
    margin-right: 4px;
  }
`;

export { FormItem, Input, InputDate };
