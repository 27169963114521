import styled from 'styled-components';
import { NeutralColors } from 'theme';

interface HorizontalBarWrapperProps {
  amountItems: number;
}

interface LineInfoProps {
  AmountLines: number;
}

const Container = styled.div`
  margin-top: 16px;
  display: flex;
`;

const VerticalName = styled.div<HorizontalBarWrapperProps>`
  height: ${(props) => props.amountItems * 80}px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-bottom: 42px;
  width: 0px;
`;

const LineName = styled.div`
  position: relative;
  height: 38px;
  margin-top: 3px;
  margin-left: 5px;
`;

const LineTitle = styled.p`
  position: absolute;
  width: 100%;
  margin-bottom: 0px;
  margin-top: 2px;
  white-space: nowrap;
  font-size: 12px;
  z-index: 2;
  top: -24px;

  span {
    margin-left: 8px;
    color: ${NeutralColors[40]};
  }
`;

const LineMoreLabel = styled.p`
  width: 100%;
  margin: 0px;
  white-space: nowrap;
  font-size: 12px;
  color: ${NeutralColors[40]};
  overflow: hidden;
  text-overflow: ellipsis;
`;

const LineInfo = styled.p<LineInfoProps>`
  z-index: 2;
  position: absolute;
  top: ${({ AmountLines }) => AmountLines * 10 + (AmountLines - 1) * 10}px;
  left: 0px;
  width: max-content;
  font-size: 10px;
`;

const HorizontalBarWrapper = styled.div<HorizontalBarWrapperProps>`
  width: 100%;
  aspect-ratio: 1/1;
  margin-bottom: 20px;
  height: ${(props) => props.amountItems * 80}px;
`;

const ContainerButton = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;

export {
  Container,
  ContainerButton,
  HorizontalBarWrapper,
  LineInfo,
  LineMoreLabel,
  LineName,
  LineTitle,
  VerticalName,
};
