import { Form } from 'antd';
import styled from 'styled-components';
import { GreenColors, NeutralColors } from 'theme';

interface ContainerProps {
  isShow: boolean;
}

const FormItem = styled(Form.Item)`
  margin-top: 24px;
`;

const Container = styled.div<ContainerProps>`
  display: ${(props) => (props.isShow ? 'flex' : 'none')};
  margin-top: 24px;
  margin-bottom: 32px;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
`;

const Box = styled.div`
  display: flex;
  width: 100%;
  padding: 16px;
  border-radius: 8px;
  border: 1px solid ${NeutralColors[20]};
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
`;

const GreenBox = styled(Box)`
  border-radius: 12px;
  border: 1px solid ${GreenColors[60]};
`;

const SwitchWrapper = styled(Form.Item)`
  width: 100%;
  margin: 0;

  .fms-form-item {
    margin-bottom: 0;
  }

  .fms-form-item-control-input-content {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 16px;
  }

  .fms-picker {
    width: 100%;
  }
`;

const ContainerButton = styled.div`
  position: fixed;
  bottom: 48px;
  width: calc(100% - 48px);
  max-width: 352px;
`;

export { Container, ContainerButton, FormItem, GreenBox, SwitchWrapper };
