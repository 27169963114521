import { Button as ButtonAntd } from 'antd';
import styled from 'styled-components';

interface ContainerSelectProps {
  hasButton?: boolean;
}

const ContainerSelect = styled.div<ContainerSelectProps>`
  padding-bottom: ${(props) => (props.hasButton ? '32px' : '0')};
`;

const ContainerButton = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
`;

const Button = styled(ButtonAntd)`
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  width: 100%;
`;

export { Button, ContainerButton, ContainerSelect };
