import { UseDrawerFormReturnType } from '@refinedev/antd';
import { HttpError } from '@refinedev/core';
import { Button } from 'antd';
import { EyeOutlinedIcon } from 'common/icons';
import { useTranslation } from 'react-i18next';
import {
  InvoiceDetailModel,
  InvoiceFormModel,
} from 'src/orders-and-payments/invoice/invoice.model';
import { InvoicePaymentFormModel } from 'src/orders-and-payments/invoice/payment-form/invoice-payment-form.model';
import {
  PurchaseDetailModel,
  PurchaseRequestModel,
} from 'src/orders-and-payments/purchase';
import { PurchasePaymentRequestModel } from 'src/orders-and-payments/purchase/purchase-payment/purchase-payment.model';

import { AccountsPayableEnum } from '../accounts-payable.enum';
import { AccountsPayableModel } from '../accounts-payable.model';
import * as Styled from './accounts-payable-actions.style';

interface AccountsPayableActionsProps {
  accountsPayable: AccountsPayableModel;
  viewInvoiceDrawer: UseDrawerFormReturnType<
    InvoiceDetailModel,
    HttpError,
    InvoiceFormModel
  >;
  viewDrawer: UseDrawerFormReturnType<
    PurchaseDetailModel,
    HttpError,
    PurchaseRequestModel
  >;
  paymentInvoiceDrawer: UseDrawerFormReturnType<
    InvoiceDetailModel,
    HttpError,
    InvoicePaymentFormModel
  >;
  paymentPurchaseDrawer: UseDrawerFormReturnType<
    PurchaseDetailModel,
    HttpError,
    PurchasePaymentRequestModel
  >;
}

const AccountsPayableActions = ({
  accountsPayable,
  viewInvoiceDrawer,
  viewDrawer,
  paymentInvoiceDrawer,
  paymentPurchaseDrawer,
}: AccountsPayableActionsProps) => {
  const { t } = useTranslation();

  const handleOpenViewAccount = () => {
    if (accountsPayable.type === AccountsPayableEnum.PURCHASE_INVOICE) {
      viewInvoiceDrawer.show(accountsPayable.id);
    } else if (accountsPayable.type === AccountsPayableEnum.PURCHASE_ORDER) {
      viewDrawer.show(accountsPayable.id);
    }
  };

  const handleOpenPayment = () => {
    if (accountsPayable.type === AccountsPayableEnum.PURCHASE_INVOICE) {
      paymentInvoiceDrawer.show(accountsPayable.id);
    } else if (accountsPayable.type === AccountsPayableEnum.PURCHASE_ORDER) {
      paymentPurchaseDrawer.show(accountsPayable.id);
    }
  };

  return (
    <>
      <Styled.Container>
        <Button onClick={handleOpenPayment} data-testid="button-open-payment">
          {t('common.pay')}
        </Button>
        <Styled.ViewButton
          onClick={handleOpenViewAccount}
          data-testid="button-open-view"
        >
          <EyeOutlinedIcon />
        </Styled.ViewButton>
      </Styled.Container>
    </>
  );
};

export { AccountsPayableActions };
