import { SelectWithButton } from 'common/components';
import { PaymentsIcon } from 'common/icons';
import { useTranslation } from 'react-i18next';

import { SelectAccountTestId } from './select-account.constant';
import useInvoicePaymentFormDate from './select-account.hook';
import * as Styled from './select-account.style';

interface InvoicePaymentFormDateProps {
  handleOpenBankAccountDrawer: () => void;
}

export const SelectAccount = ({
  handleOpenBankAccountDrawer,
}: InvoicePaymentFormDateProps) => {
  const { t } = useTranslation();

  const { accountSelectProps } = useInvoicePaymentFormDate();

  return (
    <Styled.Space
      size={4}
      align="center"
      data-testid={SelectAccountTestId.SELECT_ACCOUNT_CONTAINER}
    >
      <Styled.IconWrapper>
        <PaymentsIcon />
      </Styled.IconWrapper>
      {t('invoice.payment.form.paid_by')}
      <Styled.FormItem
        name={['paymentAccount']}
        rules={[
          {
            required: true,
          },
        ]}
      >
        <SelectWithButton
          {...accountSelectProps}
          placeholder={t('common.business.account_placeholder')}
          buttonProps={{
            onClick: handleOpenBankAccountDrawer,
            children: t('common.create_new'),
          }}
          data-testid={SelectAccountTestId.SELECT_ACCOUNT_ACCOUNT}
        />
      </Styled.FormItem>
    </Styled.Space>
  );
};
