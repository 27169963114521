const mockTimeSavingData = [
  {
    id: '1',
    data: [
      {
        x: '2021-01',
        y: 10,
      },
      {
        x: '2021-02',
        y: 0,
      },
      {
        x: '2021-03',
        y: 30,
      },
      {
        x: '2021-04',
        y: 15,
      },
      {
        x: '2021-05',
        y: 20,
      },
      {
        x: '2021-06',
        y: 15,
      },
      {
        x: '2021-07',
        y: 20,
      },
    ],
  },
  {
    id: '2',
    data: [
      {
        x: '2021-01',
        y: 25,
      },
      {
        x: '2021-02',
        y: 0,
      },
      {
        x: '2021-03',
        y: 80,
      },
      {
        x: '2021-04',
        y: 30,
      },
      {
        x: '2021-05',
        y: 40,
      },
      {
        x: '2021-06',
        y: 30,
      },
      {
        x: '2021-07',
        y: 40,
      },
    ],
  },
];

export { mockTimeSavingData };
