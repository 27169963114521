import { useSelect } from '@refinedev/antd';
import { useWatch } from 'antd/es/form/Form';
import useFormInstance from 'antd/es/form/hooks/useFormInstance';
import { useEffect, useMemo } from 'react';
import { useOrganization } from 'src/organization';
import { BankAccountModel } from 'src/resources/bank-account/bank-account.model';

const useSelectAccount = () => {
  const { currentOrganizationId } = useOrganization();

  const formInstance = useFormInstance();
  const farm = useWatch('farm');
  const paymentAccount = useWatch('paymentAccount');

  const metadata = useMemo(
    () => ({
      organizationId: currentOrganizationId,
    }),
    [currentOrganizationId],
  );

  const { selectProps: accountSelectProps, queryResult: accountQueryResult } =
    useSelect<BankAccountModel>({
      resource: 'account',
      optionLabel: 'name',
      optionValue: 'id',
      onSearch: (value) => [
        {
          field: 'name',
          operator: 'eq',
          value: value,
        },
      ],
      filters: [
        {
          field: 'farm',
          operator: 'eq',
          value: farm,
        },
        {
          field: 'accountType',
          operator: 'eq',
          value: ['Bank', 'Cash'],
        },
        {
          field: 'reportType',
          operator: 'eq',
          value: 'Balance Sheet',
        },
        {
          field: 'isGroup',
          operator: 'eq',
          value: 0,
        },
        {
          field: 'isDisabled',
          operator: 'eq',
          value: 0,
        },
      ],
      queryOptions: {
        enabled: !!metadata.organizationId && !!farm,
      },
    });

  useEffect(() => {
    if (accountQueryResult?.data?.data) {
      formInstance?.setFieldValue(
        'paymentAccountType',
        accountQueryResult.data.data.find(
          (bankAccount) => bankAccount.id === paymentAccount,
        )?.type,
      );
    }
  }, [formInstance?.setFieldValue, accountQueryResult?.data, paymentAccount]);

  return {
    accountSelectProps,
  };
};

export { useSelectAccount as default, useSelectAccount };
