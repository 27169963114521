enum MediaQueryCssEnum {
  xs = '480px',
  sm = '576px',
  md = '768px',
  lg = '992px',
  xl = '1200px',
  xxl = '1600px',
}

enum MediaQueryValueEnum {
  xs = 480,
  sm = 576,
  md = 768,
  lg = 992,
  xl = 1200,
  xxl = 1600,
}

enum MediaQueryEnum {
  xs = 'xs',
  sm = 'sm',
  md = 'md',
  lg = 'lg',
  xl = 'xl',
  xxl = 'xxl',
}

const MediaQuery = {
  screenSizes: {
    desktop: MediaQueryValueEnum.lg,
    tablet: MediaQueryValueEnum.md,
    phone: MediaQueryValueEnum.sm,
  },
  screenSizesCss: {
    laptop: MediaQueryCssEnum.xl,
    desktop: MediaQueryCssEnum.lg,
    tablet: MediaQueryCssEnum.md,
    phone: MediaQueryCssEnum.sm,
  },
  screenSizesEnum: {
    desktop: MediaQueryEnum.lg,
    tablet: MediaQueryEnum.md,
    phone: MediaQueryEnum.sm,
  },
};

export { MediaQuery, MediaQueryCssEnum, MediaQueryEnum, MediaQueryValueEnum };
