import { Button as antdButton, Input, InputNumber } from 'antd';
import styled from 'styled-components';
import { NeutralColors } from 'theme';

const UpdateAllWrapper = styled.div`
  gap: 8px;
  background-color: ${NeutralColors[10]};
  padding: 12px;
  margin-bottom: 16px;
  border-radius: 8px;
`;

const UpdateAllInputWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
`;

const UpdateAllInputNumber = styled(InputNumber)`
  width: 100%;
`;

const UpdateAllButton = styled(antdButton)`
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${NeutralColors[60]};

  svg {
    display: inline-block;
  }
`;

const SearchInput = styled(Input)`
  margin-bottom: 16px;
  margin-top: 8px;

  svg {
    display: inline-block;
    width: 18px;
  }
`;

const ItemsListWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
  overflow: auto;
  max-height: calc(100vh - 400px);
`;

export {
  ItemsListWrapper,
  SearchInput,
  UpdateAllButton,
  UpdateAllInputNumber,
  UpdateAllInputWrapper,
  UpdateAllWrapper,
};
