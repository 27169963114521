export const ActionsElectronicInvoiceTestId = {
  ACTIONS_ARCHIVE_BUTTON: 'actions-archive-button',
  ACTIONS_VIEW_BUTTON: 'actions-view-button',
  ACTIONS_IMPORT_BUTTON: 'actions-import-button',
};

export const ElectronicInvoiceLoadingTestId = {
  ELECTRONIC_INVOICE_LOADING_CONTAINER: 'electronic-invoice-loading-container',
};

export const ItemLinkSelectTestId = {
  ITEM_LINK_SELECT_BUTTON: 'item-link-select-button',
};

export const ItemLinkTestId = {
  ITEM_LINK_BUTTON: 'item-link-button',
};

export const ReceivedPaidWarehouseTestId = {
  RECEIVED_PAID_WAREHOUSE_BUTTON: 'received-paid-warehouse-button',
  RECEIVED_PAID_WAREHOUSE_SELECT: 'received-paid-warehouse-select',
};
