import styled from 'styled-components';
import { NeutralColors, RedColors } from 'theme';

interface LineProps {
  isFirstLine?: boolean;
  hasError?: boolean;
}

interface ColumnProps {
  hasError?: boolean;
}

const Table = styled.table`
  width: 100%;
`;

const Line = styled.tr<LineProps>`
  font-weight: ${(props) => (props.isFirstLine ? 'bold' : '')};
  background-color: ${(props) => (props.hasError ? RedColors[20] : '')};
`;

const Column = styled.td<ColumnProps>`
  min-width: 80px;
  border: ${(props) => (props.hasError ? '2px' : '1px')} solid
    ${(props) => (props.hasError ? RedColors[50] : NeutralColors[20])};
  color: ${(props) => (props.hasError ? RedColors[50] : NeutralColors[80])};
  font-weight: ${(props) => (props.hasError ? 'bold' : '')};
  padding: 4px 8px;
`;

const ColumnTH = styled.th`
  min-width: 80px;
  border: 1px solid ${NeutralColors[20]};
  background-color: ${NeutralColors[10]};
  padding: 6px 8px;
  height: 48px;
`;

const FirstColumn = styled(Column)`
  width: 40px;
  min-width: 40px;
  background-color: ${NeutralColors[10]};
  font-weight: bold;
  text-align: center;
`;

export { Column, ColumnTH, FirstColumn, Line, Table };
