import { IResourceComponentsProps } from '@refinedev/core';
import { Button, Table } from 'antd';
import { PageTitle } from 'common/components';
import { EditIcon } from 'common/icons';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { CostCenterColumn, CostCenterEdit } from './cost-center';
import { useSettings } from './settings.hook';

const SettingsContainer: FC<IResourceComponentsProps> = () => {
  const { t } = useTranslation();
  const {
    tableProps,
    costCenterEditDrawerProps,
    costCenterEditFormProps,
    costCenterEditId,
    costCenterEditSaveButtonProps,
    costCenterEditClose,
    handleOnClickEditCostCenter,
  } = useSettings();

  return (
    <>
      <PageTitle
        title={t('settings.title')}
        subTitle={t('settings.cost_center') ?? ''}
      />
      <Table {...tableProps} rowKey="id" pagination={false}>
        <Table.Column title={t('common.business.farm')} dataIndex="id" />
        <Table.Column
          title={t('settings.cost_center')}
          render={({ costCenter, id }) => (
            <CostCenterColumn
              costCenter={costCenter}
              onClick={() => handleOnClickEditCostCenter(id)}
            />
          )}
        />
        <Table.Column
          width={100}
          align="center"
          title={t('common.actions')}
          render={({ id }) => (
            <Button
              type="text"
              onClick={() => handleOnClickEditCostCenter(id)}
              icon={<EditIcon />}
            />
          )}
        />
      </Table>
      <CostCenterEdit
        drawerProps={costCenterEditDrawerProps}
        formProps={costCenterEditFormProps}
        onClose={costCenterEditClose}
        onCreate={costCenterEditSaveButtonProps}
        editId={costCenterEditId}
      />
    </>
  );
};

export { SettingsContainer as default, SettingsContainer };
