import {
  AddIcon,
  BuildOutlinedIcon,
  HarvestIcon,
  IrrigationIcon,
  LocalShippingIcon,
  OutboxIcon,
  PlantingIcon,
  SprayIcon,
  TillageIcon,
} from 'common/icons';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { AgOperationsTypeEnum } from 'src/ag-operation-create/ag-operation.enum';
import {
  BlueColors,
  NeutralColors,
  OrangeColors,
  PinkColors,
  RedColors,
  TealColors,
  YellowColors,
} from 'theme';

import { MaterialConsumptionModel } from '../material-consumption.model';
import * as Styled from './type-column.style';

interface TypeColumnProps {
  materialConsumption: MaterialConsumptionModel;
}

const TypeColumn = ({ materialConsumption }: TypeColumnProps) => {
  const { t } = useTranslation();

  const textColor = useMemo(() => {
    switch (materialConsumption.operationType) {
      case AgOperationsTypeEnum.PLANTING:
        return TealColors[70];
      case AgOperationsTypeEnum.CP_TREATMENT:
        return PinkColors[70];
      case AgOperationsTypeEnum.DECREASE:
      case AgOperationsTypeEnum.SPRAYING:
        return RedColors[70];
      case AgOperationsTypeEnum.IRRIGATION:
        return BlueColors[70];
      case AgOperationsTypeEnum.LAND_PREPARATION:
        return YellowColors[70];
      case AgOperationsTypeEnum.HARVESTING:
        return OrangeColors[70];
      case AgOperationsTypeEnum.MAINTENANCE:
      case AgOperationsTypeEnum.TRANSPORTATION:
      default:
        return NeutralColors[60];
    }
  }, [materialConsumption]);

  const backgroundColor = useMemo(() => {
    switch (materialConsumption.operationType) {
      case AgOperationsTypeEnum.PLANTING:
        return TealColors[20];
      case AgOperationsTypeEnum.CP_TREATMENT:
        return PinkColors[20];
      case AgOperationsTypeEnum.DECREASE:
      case AgOperationsTypeEnum.SPRAYING:
        return RedColors[20];
      case AgOperationsTypeEnum.IRRIGATION:
        return BlueColors[20];
      case AgOperationsTypeEnum.LAND_PREPARATION:
        return YellowColors[20];
      case AgOperationsTypeEnum.HARVESTING:
        return OrangeColors[20];
      case AgOperationsTypeEnum.MAINTENANCE:
      case AgOperationsTypeEnum.TRANSPORTATION:
      default:
        return NeutralColors[10];
    }
  }, [materialConsumption]);

  const icon = useMemo(() => {
    switch (materialConsumption.operationType) {
      case AgOperationsTypeEnum.PLANTING:
        return <PlantingIcon />;
      case AgOperationsTypeEnum.CP_TREATMENT:
        return <AddIcon />;
      case AgOperationsTypeEnum.DECREASE:
      case AgOperationsTypeEnum.SPRAYING:
        return <SprayIcon />;
      case AgOperationsTypeEnum.IRRIGATION:
        return <IrrigationIcon />;
      case AgOperationsTypeEnum.LAND_PREPARATION:
        return <TillageIcon />;
      case AgOperationsTypeEnum.HARVESTING:
        return <HarvestIcon />;
      case AgOperationsTypeEnum.MAINTENANCE:
        return <BuildOutlinedIcon />;
      case AgOperationsTypeEnum.TRANSPORTATION:
        return <LocalShippingIcon />;
      default:
        return <OutboxIcon />;
    }
  }, [materialConsumption]);

  return (
    <Styled.Tag
      textColor={textColor}
      backgroundColor={backgroundColor}
      data-testid="tag-type-column"
    >
      {icon}
      {t(
        materialConsumption.operationType
          ? `ag_operation.types.${materialConsumption.operationType}`
          : 'material_consumption.create_buttons.generic',
      )}
    </Styled.Tag>
  );
};

export { TypeColumn };
