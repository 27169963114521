import { useTable, useTableProps } from '@refinedev/antd';
import { BaseRecord, CrudFilters, HttpError } from '@refinedev/core';
import { FormProps } from 'antd';
import { Dayjs } from 'dayjs';
import { useMemo } from 'react';
import { useOrganization } from 'src/organization';

import { RefineTableFiltersEnum } from './refine-table-filters.enum';
import { ListFiltersModel } from './refine-table-filters.model';
import { useRefineTableFiltersStore } from './refine-table-filters.store';

export interface FormPropsStore extends FormProps {
  storeName?: string;
}

interface UseRefineTableFiltersProps<
  TQueryFnData,
  TError,
  TSearchVariables,
  TData,
> extends useTableProps<TQueryFnData, TError, TSearchVariables, TData> {
  listFilters: ListFiltersModel[];
  storeName?: string;
}

export function useRefineTableFilters<
  TQueryFnData extends BaseRecord = BaseRecord,
  TError extends HttpError = HttpError,
  TSearchVariables = unknown,
  TData extends BaseRecord = TQueryFnData,
>({
  listFilters,
  storeName,
  ...props
}: UseRefineTableFiltersProps<TQueryFnData, TError, TSearchVariables, TData>) {
  const { currentOrganizationId } = useOrganization();
  const { filterStore } = useRefineTableFiltersStore(storeName);

  const initialFilter: CrudFilters = useMemo(
    () =>
      filterStore && filterStore.length > 0
        ? filterStore
        : listFilters
            .filter((filter) => !!filter.initialValue)
            .map((filter) => {
              const { initialValue } = filter;
              let stringValue:
                | string
                | number
                | string[]
                | number[]
                | Dayjs
                | undefined;

              if (typeof initialValue === 'object' && 'value' in initialValue) {
                stringValue = initialValue.value;
              } else {
                stringValue = initialValue;
              }

              return {
                field: filter.field,
                operator: 'eq',
                value: stringValue,
              };
            }),
    [listFilters, filterStore],
  );

  const { ...returnProps } = useTable<
    TQueryFnData,
    TError,
    TSearchVariables,
    TData
  >({
    syncWithLocation: true,
    queryOptions: {
      enabled: !!currentOrganizationId,
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onSearch: (params: any) => {
      const filters: CrudFilters = [];

      listFilters.forEach((filter) => {
        let fieldValue;
        if (params[filter.field]?.value) {
          fieldValue = params[filter.field]?.value;
        } else {
          fieldValue = params[filter.field];
        }
        const value =
          filter.type === RefineTableFiltersEnum.DATE
            ? (fieldValue as Dayjs)?.format('YYYY-MM-DD')
            : fieldValue;
        filters.push({
          field: filter.field,
          operator: 'eq',
          value,
        });
      });

      return filters;
    },
    ...props,
    filters: {
      ...props.filters,
      initial: [...(props.filters?.initial ?? []), ...initialFilter],
    },
    meta: {
      organizationId: currentOrganizationId,
      ...props.meta,
    },
  });

  return {
    ...returnProps,
    searchFormProps: {
      storeName,
      ...returnProps.searchFormProps,
    },
  };
}
