import { useSelect } from '@refinedev/antd';
import { CrudFilters } from '@refinedev/core';
import { FormProps } from 'antd';
import {
  convertSelectPropsToOptionType,
  FormItem,
  SelectWithClear,
} from 'common/components';
import { ListFiltersModel } from 'common/refine-table-filters';
import { useMemo } from 'react';
import { useOrganization } from 'src/organization';

import { RefineTableFiltersSelectTestId } from '../refine-table-filters.constants';

interface RefineTableFiltersSelectProps {
  filter: ListFiltersModel;
  searchFormProps?: FormProps;
  isMultiple?: boolean;
  onClickClearIfEmpty?: () => void;
  isHidden?: boolean;
}

export const RefineTableFiltersSelect = ({
  filter,
  isMultiple,
  searchFormProps,
  onClickClearIfEmpty,
  isHidden,
}: RefineTableFiltersSelectProps) => {
  const parentFilter: CrudFilters | undefined = filter.parentsField?.length
    ? filter.parentsField.map((parentField) => ({
        field:
          typeof parentField === 'string' ? parentField : parentField.field,
        operator: 'eq',
        value: searchFormProps?.form?.getFieldValue(
          typeof parentField === 'string'
            ? parentField
            : parentField.parentValue,
        ),
      }))
    : undefined;
  const { currentOrganization } = useOrganization();
  const metadata = useMemo(
    () => ({
      organizationId: currentOrganization?.id,
    }),
    [currentOrganization?.id],
  );

  const { selectProps, queryResult } = useSelect({
    resource: filter.resource ?? '',
    optionLabel: filter.optionLabel as never,
    optionValue: filter.optionValue as never,
    onSearch: (value) => [
      {
        field: 'name',
        operator: 'eq',
        value: value,
      },
    ],
    meta: metadata,
    filters: [...(parentFilter ?? []), ...(filter.filters ?? [])],
    errorNotification: false,
    pagination: {
      pageSize: 50,
    },
  });

  const options = useMemo(
    () =>
      convertSelectPropsToOptionType(
        selectProps,
        queryResult,
        filter.optionComplementaryLabel,
        filter.optionValue,
      ),
    [selectProps, queryResult, filter],
  );

  return (
    <FormItem
      icon={filter.icon}
      label={filter.label}
      name={filter.field}
      hidden={isHidden}
      normalize={(value) => (value?.length > 0 ? value : undefined)}
    >
      <SelectWithClear
        isSendFormAfterClear
        formProps={searchFormProps}
        name={filter.field}
        mode="multiple"
        maxCount={isMultiple ? undefined : 1}
        onClickClearIfEmpty={onClickClearIfEmpty}
        {...selectProps}
        onChange={(value) => {
          if (!isMultiple) {
            searchFormProps?.form?.setFieldValue(filter.field, value[0]);
          }
        }}
        options={options}
        data-testid={RefineTableFiltersSelectTestId.REFINE_TABLE_FILTERS_SELECT}
      />
    </FormItem>
  );
};
