import { Tag, Typography } from 'antd';
import styled from 'styled-components';
import { GreenColors, NeutralColors, RedColors } from 'theme';

const NegativeTag = styled(Tag)`
  background-color: ${RedColors[20]};
  color: ${RedColors[60]};
  border: 1px solid ${RedColors[20]};
`;

const PositiveTag = styled(Tag)`
  background-color: ${GreenColors[20]};
  color: ${GreenColors[60]};
  border: 1px solid ${GreenColors[20]};
`;

const DefaultTag = styled(Tag)`
  background-color: ${NeutralColors[10]};
  color: #000;
  border: 1px solid ${NeutralColors[10]};
`;

const Title = styled(Typography.Title)`
  color: ${NeutralColors[60]} !important;
  margin: 0 !important;
`;

export { DefaultTag, NegativeTag, PositiveTag, Title };
