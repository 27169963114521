import { Tag as TagAntd } from 'antd';
import styled from 'styled-components';
import { NeutralColors } from 'theme';

interface TagProps {
  textColor: string;
}

const Tag = styled(TagAntd)<TagProps>`
  background-color: ${NeutralColors[0]};
  border-color: ${(props) => props.textColor};
  color: ${(props) => props.textColor};
`;

export { Tag };
