import { Button, Drawer as DrawerAntd } from 'antd';
import styled from 'styled-components';
import { NeutralColors } from 'theme';

const Drawer = styled(DrawerAntd)`
  .fms-drawer-header-title {
    flex-direction: row-reverse;
  }

  .fms-drawer-close {
    margin-right: -10px;
  }
`;

const ContainerChildren = styled.div`
  height: calc(100% - 56px);
  overflow: auto;
  margin-bottom: 16px;
`;

const ContainerButtons = styled.div`
  display: flex;
  justify-content: space-between;
  height: 40px;
  border-top: 1px solid ${NeutralColors[20]};
  padding: 16px 0px;
`;

const SaveButton = styled(Button)`
  margin-left: 16px;
`;

const ContainerSaveButton = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export {
  ContainerButtons,
  ContainerChildren,
  ContainerSaveButton,
  Drawer,
  SaveButton,
};
